 import { axios } from "..";
import { IJurisdictionGroup } from "../interfaces";
 
export const getJurisdictionGroups = async (): Promise<IJurisdictionGroup[]> => {
    try {
        const jurisdictionGroups = await axios.Get('./JurisdictionGroup');
        const data = jurisdictionGroups.data;
        if (data && data != null) {   
            return jurisdictionGroups.data.map((jurisdictionGroup: IJurisdictionGroup) => ({ id: jurisdictionGroup.id, name: jurisdictionGroup.name,  UpdatedDate: jurisdictionGroup._ts }));
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return [];
}

export const getJurisdictionList = async () => {
  try {
    const response = await axios.Get('/JurisdictionGroup');
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}

export const getJurisdictionGroupById = async (id:string) => {
    const jurisdictionGroup = await axios.Get('JurisdictionGroup/?id='+id);
    return jurisdictionGroup.data;
}

export const createJurisdictionGroup = async (jurisdictionGroup:IJurisdictionGroup) => {
    const response = await axios.Post('JurisdictionGroup', jurisdictionGroup);
    return response.data;
}

export const updateJurisdictionGroup = async (jurisdictionGroup:IJurisdictionGroup) => {
    const response = await axios.Put('JurisdictionGroup', jurisdictionGroup);
    return response.data;
}

export const deleteJurisdictionGroup = async (id:string) => {
    const response = await axios.Delete('JurisdictionGroup/',id);
    return response.data;
}