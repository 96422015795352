import "../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Vector from "../../../components/Atomos/Icons/Vector";
import SortArrow from "../../../components/Atomos/Icons/SortArrow";
import Manage from "../../../components/Atomos/Icons/Manage";
import NoteFrame from "./Notes/NoteFrame";
import { useNavigate } from "react-router-dom";
import { format, isValid } from "date-fns";

interface INotesProps {
  name: string;
  noteList: any;
  id: string;
  stateData: any;
}

const NotesGT: React.FC<INotesProps> = ({ name, id, noteList, stateData }) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedNoteList, setSortedNoteList] = useState<any[]>([]);
  const navigate = useNavigate();

  const manageNotes = () => {
    navigate(`/Staff/ManageFirm/GeneralTab/${id}/Notes`, {
      state: { ...stateData, firmNotes: noteList },
    });
  };

  useEffect(() => {
    const sortedList = [...noteList].sort((a: any, b: any) => {
      const dateA = new Date(a.dateCreated || a.dateCreated);
      const dateB = new Date(b.dateCreated || b.dateCreated);

      if (sortOrder === "asc") {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });

    setSortedNoteList(sortedList);
  }, [noteList, sortOrder]);

  return (
    <>
      <div className="flex flex-col gap-10 justify-end">
        <article className="articleSection relative">
          <section className="flex w-full flex-col gap-7">
            <div className="flex flex-col gap-5 lg:flex-row lg:justify-between">
              <CatalogSubTitle
                title={"Notes"}
                className="text-Default lg:text-[32px] md:text-[28px] text-[26px] not-italic font-medium lg:leading-9 md:leading-[44px] leading-normal"
              />

              <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                <div>
                  <span className="text-Default not-italic font-semibold lg:leading-5 lg:text-lg text-base leading-[18px] ">
                    Sort By
                  </span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="20"
                    viewBox="0 0 2 20"
                    fill="none"
                  >
                    <path d="M1 0V20" stroke="#EBEBEB" />
                  </svg>
                </div>
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                    Date
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrder === "desc" && "rotate-180"}`}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-6 gap-7 w-full">
              {sortedNoteList.map((note: any) => (
                <>
                  <div className="grid flex-1 col-span-6">
                    <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
                  </div>
                  <NoteFrame
                    name={note?.name}
                    date={
                      isValid(new Date(note?.dateCreated))
                        ? format(new Date(note?.dateCreated), "MM/dd/yyyy")
                        : "Invalid date" // Handle invalid dates gracefully
                    }
                    note={note?.completeNote ? note.completeNote : note?.note}
                  />
                </>
              ))}
            </div>
          </section>
          <div className="flex w-full  justify-end">
            <div
              className="flex gap-2.5 items-center cursor-pointer"
              onClick={() => {
                manageNotes();
              }}
            >
              <span className=" text-base not-italic font-bold leading-7 text-primary">
                MANAGE
              </span>
              <Manage className="w-4 h-4 fill-primary" />
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default NotesGT;
