import React from "react";

function Overview() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
     
      viewBox="0 0 48 48"
    >
      <path 
        d="M40 41.3l1.4-1.4-3.75-3.75v-5.6h-2v6.4L40 41.3zm-3.35 4.65c-2.6 0-4.817-.925-6.65-2.775-1.833-1.85-2.75-4.042-2.75-6.575 0-2.6.917-4.825 2.75-6.675 1.833-1.85 4.05-2.775 6.65-2.775 2.567 0 4.775.925 6.625 2.775 1.85 1.85 2.775 4.075 2.775 6.675 0 2.533-.925 4.725-2.775 6.575-1.85 1.85-4.058 2.775-6.625 2.775zM14 17h20v-3H14v3zm11.5 25H9a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 016 39V9c0-.833.292-1.542.875-2.125A2.893 2.893 0 019 6h30c.833 0 1.542.292 2.125.875S42 8.167 42 9v16.45c-.833-.433-1.7-.75-2.6-.95a12.639 12.639 0 00-4.1-.225c-.433.05-.867.125-1.3.225v-2H14v3h17.2a12.33 12.33 0 00-3.225 2.325c-.95.95-1.725 2.008-2.325 3.175H14v3h10.55c-.1.433-.175.867-.225 1.3-.05.433-.075.883-.075 1.35 0 .967.1 1.883.3 2.75.2.867.517 1.733.95 2.6z"
      ></path>
    </svg>
  );
}

export default Overview;
