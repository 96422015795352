import React, { useEffect } from "react";
import UserIcon from "../../Atomos/Icons/EditMode/UserIcon";
import Modal from "../Modals/Modal";
import { useAppContext } from "../../../Context";
import ImageCropper from "../../Organismos/ImageCropper/ImageCropper";
import Image from "../../Atomos/Icons/Image";
import NewTooltip from "../Tooltip/NewTooltip";

type inputImageProps = {
  maxsize?: number;
  width?: number;
  height?: number;
  extension?: string;
  photo?: string;
  setImage: (photo: string | File | null) => void;
  title?: string;
  icon?:React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  error?: boolean;
  errorMsg?: string;
};

const InputImage: React.FC<inputImageProps> = ({icon=<Image/>,...props}:inputImageProps) => {

  const [hasPhoto, setHasPhoto] = React.useState(false)
  const [spinner, setSpinner] = React.useState(false)

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [draggedPhoto, setDraggedPhoto] = React.useState<string | File | null>()
  const { mode } = useAppContext();
  const [newphoto, setPhoto] = React.useState<File | null>(null);

  useEffect(() => {
    props.setImage(newphoto);
  }
    , [newphoto]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = !isModalOpen ? 'hidden' : '';
  };

  const photoHasLoaded = async () => {
    setHasPhoto(true)
  }
  const handleSpinner = async () => {
    setSpinner(true)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];

    if (droppedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          setDraggedPhoto(event.target.result);
        }
      };
      reader.readAsDataURL(droppedFile);
    }
  };

  return (
    <>
      <Modal title={props.title ? props.title : "Upload Profile Image"} isModalOpen={isModalOpen} toggleModal={toggleModal} >
        <ImageCropper
          setPhoto={setPhoto}
          onHandleUpload={photoHasLoaded}
          draggedPhoto={draggedPhoto}
          onImageUpload={() => {
            toggleModal();
          }}
          shape="round"
        />
      </Modal>
      <div className={`flex flex-col gap-[.625rem] self-stretch items-start w-full`}>
            {props.title && <div className=" flex gap-2.5 w-full">
                {mode !== 'member' ?
                    <span className={` inputLabel ${props?.disabled ? ' text-gray-200' : !props.required ? 'text-gray-font' : ' text-primary'} `}>{props.title}</span>
                    : <span className={`inputLabel ${props?.disabled ? ' text-gray-200' : 'text-gray-font'} `}>{props.title} <span className={`${props.required ? props.disabled ? ' text-gray-200' : ' text-feedback-error' : 'hidden'}`}>*</span></span>
                }
                {props.tooltip && (
                    <NewTooltip text={props.tooltip} />
                )}
            </div>}

      <div className="flex flex-col gap-2.5 items-center w-full h-full">
        <span className={`flex-1 text-base lg:text-[22px] not-italic font-bold leading-[18px] lg:leading-8 ${props.disabled ? 'text-[#C2C2C2]' : 'text-gray-font'}`}>Upload Image</span>
        
        <div className={`flex flex-col items-center gap-2.5 [&>*:last-child]:gap-0`}>
          <div className={`w-full h-full flex flex-col gap-2.5 items-center justify-center`}>
            <div
              className="bg-[#F4F4F4] rounded-full border-[1px] border-dashed border-[#BEBDBD] relative flex items-center justify-center w-[6.25rem] h-[6.28rem] lg:w-40 lg:h-40  overflow-hidden "
              onClick={props.disabled ? () => '' : toggleModal}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                handleDrop(e);
                toggleModal();
              }}
            >
              <div className="relative flex-1 flex  justify-center items-center">
                {
                  newphoto ?
                    <img src={URL.createObjectURL(newphoto)} alt="Wsg_Profile_Picture" className="object-cover w-fit h-fit rounded-full" />
                    :
                    props.photo ? (
                      <img src={`${props.photo}`} alt="Wsg_Profile_Picture" className="object-cover w-full h-full rounded-full" />
                    ) :
                    (
                      <div className="w-[3.75rem] h-[3.75rem] lg:w-[6rem] lg:h-[6rem] fill-gray-font">{icon}</div>
                    )}
              </div>
              
            </div>              
            <span className=" text-base lg:text-lg leading-[1.125rem] lg:leading-5 text-gray-600 font-normal not-italic">Click or Drag</span>

          </div>

          <div className="flex flex-col items-center">
            {props.maxsize && (
              <span className="text-base lg:text-lg leading-[1.125rem] lg:leading-5 text-gray-600 font-normal not-italic">{props.maxsize} MB max.</span>
            )}
            {props.extension && (
              <span className="text-base lg:text-lg leading-[1.125rem] lg:leading-5 text-gray-600 font-normal not-italic">
                Accepted File Types are: {props.extension}.
              </span>
            )}
            {props.width && props.height && (
          
              <span className=" text-base lg:text-lg leading-[1.125rem] lg:leading-5 text-gray-600 font-normal not-italic">
                {props.width} x {props.height} pixels.
              </span>
            )}
          </div>
        </div>
      </div>
      <span className={`flex flex-wrap items-center ${!props.error && 'hidden'} text-sm font-medium text-feedback-error`}>
          {props.errorMsg}
      </span>
      </div>
    </>
  )
}
export default InputImage;