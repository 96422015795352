
function Receipt_long() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16"  >
            <path d="M3.73792 15.0999C3.15655 15.0999 2.66459 14.8987 2.26204 14.4961C1.85948 14.0935 1.6582 13.6001 1.6582 13.0158V10.7014H3.8879V1.1731L4.9411 2.21078L5.98487 1.1731L7.01922 2.21078L8.07242 1.1731L9.10675 2.21078L10.1539 1.1731L11.1916 2.21078L12.2387 1.1731L13.2763 2.21078L14.3423 1.1731V13.0202C14.3423 13.6016 14.1401 14.0935 13.7357 14.4961C13.3313 14.8987 12.8403 15.0999 12.2626 15.0999H3.73792ZM12.2673 13.7702C12.4863 13.7702 12.6653 13.7006 12.8042 13.5613C12.9431 13.4221 13.0126 13.2411 13.0126 13.0183V3.19775H5.20822V10.7014H11.5126V13.0202C11.5126 13.2424 11.5836 13.423 11.7256 13.5619C11.8676 13.7008 12.0482 13.7702 12.2673 13.7702ZM6.09155 5.88761V4.88761H9.91255V5.88761H6.09155ZM6.09155 8.12095V7.12095H9.91255V8.12095H6.09155ZM11.4626 5.88761C11.3292 5.88761 11.2126 5.83761 11.1126 5.73761C11.0126 5.63761 10.9626 5.52095 10.9626 5.38761C10.9626 5.25428 11.0126 5.13761 11.1126 5.03761C11.2126 4.93761 11.3292 4.88761 11.4626 4.88761C11.5959 4.88761 11.7126 4.93761 11.8126 5.03761C11.9126 5.13761 11.9626 5.25428 11.9626 5.38761C11.9626 5.52095 11.9126 5.63761 11.8126 5.73761C11.7126 5.83761 11.5959 5.88761 11.4626 5.88761ZM11.4626 8.03761C11.3292 8.03761 11.2126 7.98761 11.1126 7.88761C11.0126 7.78761 10.9626 7.67095 10.9626 7.53761C10.9626 7.40428 11.0126 7.28761 11.1126 7.18761C11.2126 7.08761 11.3292 7.03761 11.4626 7.03761C11.5959 7.03761 11.7126 7.08761 11.8126 7.18761C11.9126 7.28761 11.9626 7.40428 11.9626 7.53761C11.9626 7.67095 11.9126 7.78761 11.8126 7.88761C11.7126 7.98761 11.5959 8.03761 11.4626 8.03761ZM3.72125 13.7702H10.1923V12.0311H2.98792V13.0202C2.98792 13.2424 3.0582 13.423 3.19875 13.5619C3.33931 13.7008 3.51348 13.7702 3.72125 13.7702Z" />
        </svg>
    );
}

export default Receipt_long;
