import React from "react";

function RotateClockwise(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M10.979 18.722V17.06c.47-.076.934-.203 1.39-.383.457-.18.903-.436 1.34-.77l1.202 1.2a6.954 6.954 0 01-1.847 1.076 8.979 8.979 0 01-2.085.539zm-1.875 0c-1.99-.3-3.63-1.178-4.92-2.636-1.291-1.458-1.937-3.198-1.937-5.22 0-1.066.195-2.07.583-3.01a7.816 7.816 0 011.61-2.474 7.678 7.678 0 012.41-1.68 7.182 7.182 0 012.968-.62h.381l-1.47-1.48L9.93.426l3.47 3.47-3.47 3.47-1.2-1.2 1.435-1.421h-.37c-1.685 0-3.09.6-4.212 1.802-1.123 1.201-1.685 2.64-1.685 4.318 0 1.594.492 2.95 1.476 4.068.983 1.118 2.227 1.827 3.731 2.127v1.662zm7.12-2.916l-1.212-1.21c.312-.41.56-.847.743-1.314.184-.466.31-.96.38-1.48h1.671a8.972 8.972 0 01-.549 2.155 7.679 7.679 0 01-1.034 1.85zm1.582-5.878h-1.671a6.962 6.962 0 00-.392-1.423 7.016 7.016 0 00-.742-1.394l1.223-1.154a8.593 8.593 0 011.05 1.893c.265.667.443 1.36.532 2.078z"></path>
      </svg>
    </div>
  );
}

export default RotateClockwise;
