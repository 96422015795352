import React from "react"; 

const WeChat: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
      <path  d="M13.534 6.767c.233 0 .459.01.684.04-.604-2.851-3.662-4.971-7.138-4.971C3.182 1.836 0 4.483 0 7.85c0 1.942 1.065 3.546 2.833 4.786l-.702 2.13 2.473-1.24c.887.175 1.592.354 2.48.354.225 0 .44-.011.665-.03a5.002 5.002 0 01-.225-1.483c-.004-3.087 2.65-5.6 6.01-5.6zm-3.8-1.924c.528 0 .88.353.88.887 0 .528-.352.88-.88.88-.538 0-1.065-.352-1.065-.88.004-.534.531-.887 1.066-.887zM4.775 6.61c-.527 0-1.065-.352-1.065-.88 0-.538.538-.887 1.066-.887.538 0 .887.353.887.887 0 .531-.35.88-.887.88zM20 12.287c0-2.833-2.833-5.138-6.014-5.138-3.372 0-6.015 2.305-6.015 5.138 0 2.833 2.647 5.138 6.014 5.138.702 0 1.415-.185 2.131-.36l1.942 1.066-.538-1.768C18.945 15.294 20 13.88 20 12.287zm-7.967-.891c-.353 0-.702-.353-.702-.713 0-.352.353-.702.702-.702.538 0 .887.353.887.702 0 .364-.353.713-.887.713zm3.894 0c-.352 0-.701-.353-.701-.713 0-.352.352-.702.701-.702.527 0 .887.353.887.702.004.364-.36.713-.887.713z" ></path>
    </svg>
  );
}

export default WeChat;
