import React from "react";

interface ContactFrameProps {
  name?: string;
  date?: string;
  note?: string;
}

const PressCard: React.FC<ContactFrameProps> = ({ name, date, note }) => {
  return (
    <div className="grid col-span-6 gap-5">
      <div className="grid gap-1">
        <div className="not-italic font-bold lg:text-[22px] md:text-[20px] text-lg lg:leading-7 leading-normal text-primary">
          {"Smith V. Acme Corp."}
        </div>
        <div className="not-italic font-normal text-sm lg:text-base leading-5 lg:leading-6 text-gray-font">
          {"December 18, 2023"}
        </div>
      </div>
      <div className="not-italic font-normal text-base lg:text-lg leading-6 lg:leading-8 text-gray-font">
        {
          "Met with client John Smith today regarding the breach of contract case against Acme Corp. He signed the contract on January 15th, which outlined five key deliverables, but as March 10th, three remain incomplete. I need to review clauses 5, 9 and 11 of lorem ipsum dolor sit amet consectetur. Varius vel ut vitae sed libero volutpat dui neque. Facilisis et dignissim ornare elit donec viverra tellus interdum ornare."
        }
      </div>
    </div>
  );
};

export default PressCard;
