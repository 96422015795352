import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import GoBack from "../../../components/Moleculas/GoBack";
import Input from "../../../components/Atomos/Inputs/Input";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import { ILanguage } from "../../../interfaces/models/ILanguage";
import { useLogging } from "../../../Context/LoggingContext";

interface iCatalogProps {
  mode: "add" | "edit";
}
interface IErrorState {
  hasError: boolean;
  message: string;
}

const initialErrorState: IErrorState = {
  hasError: false,
  message: "",
};

const AddLanguage: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();
  const axios = new Axios();
  const navigate = useNavigate();
  const { logActivity } = useLogging();
  const location = useLocation()
  const { id } = useParams();
  const { mode } = props;
  const [buttonClicked, setButtonClicked] = useState('saveButton');
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [currentName, setCurrentName] = useState(state?.name ?? '')
  const [language, setLanguage] = useState<any>(state ? state as any : { name: '' })

  const validateForm = () => {
    if (currentName.trim() === '') {
      setErrorState({ hasError: true, message: 'Name is required' });
      return false;
    }
    setErrorState(initialErrorState);
    return true;
  };

  useEffect(() => {
  }, [state])

  useEffect(() => {
    setLanguage(state)
    if (mode === 'edit' && state) {
      setCurrentName(state?.name)
      setButtonClicked('updateButton')
    }
  }, [mode, language, state]);

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Language`, id);
    if (response.status === 200) {
      logActivity('DELETE_LANGUAGE', location.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/Language')
    } else {
      console.error('Error al eliminar el elemento:', response.data);
    }
  }

  const handleSaveOrUpdate = async () => {
    if (!validateForm()) return;
    let dataItem: ILanguage = { name: currentName };

    if (mode === 'edit' && id) {
      dataItem = { ...dataItem, id };
    }

    try {
      if (mode === 'edit') {
        const response = await axios.Put("/Language", dataItem)
        if (response && response.data) {
          if (response.data.error) {
            setErrorState({ hasError: true, message: 'This value is already registered in the DB. Please input a different/new one.' });
          } else {
            logActivity('UPDATE_LANGUAGE', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response.data) }))
            navigate('/Staff/Catalogs/Language')
          }
        }
      } else if (mode === 'add') {
        const response = await axios.Post("/Language", dataItem)
        if (response && response.data) {
          if (response.data.error) {
            setErrorState({ hasError: true, message: 'This value is already registered in the DB. Please input a different/new one.' });
          } else {
            logActivity('CREATE_LANGUAGE', location.pathname, JSON.stringify(response.data))
            navigate('/Staff/Catalogs/Language')
          }
        }
      }
    } catch (error) {
      console.error('Error saving/updating language:', error);
    }
  };

  const handleNameChange = (e: any) => {
    setCurrentName(e);
    setErrorState({ hasError: false, message: '' })
  }

  return (
    <>
      <main className='mainSection'>
        <CatalogTitle
          title={`${props.mode === 'add' ? 'New Language' : 'Edit ' + (state ? state.name || 'Edit none' : 'Loading...')}`} className="text-Default">
          <GoBack />
        </CatalogTitle>
        <article className='articleSection'>
          <section className="formsectionNTitle">
            <CatalogSubTitle className="text-accent-Default-dark" title="Language Information" />
            <Input
              onChange={(e) => handleNameChange(e.target.value)}
              value={currentName}
              title="Name"
              name="Name"
              required
              error={errorState.hasError}
              errorMsj={errorState.message}
            />
          </section>
          <ButtonsCrud
            Catalog="Language"
            mode={mode}
            id={id || ''}
            disabled={errorState.hasError}
            actionButton={() => handleSaveOrUpdate()}
            onDelete={handleDelete}
          />
        </article>
      </main>
    </>
  );
}
export default AddLanguage;