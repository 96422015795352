
import '../../../assets/css/Text.css'

import React, { Children } from 'react'

interface IHeaderTitle {
    title: string;
    children?: React.ReactNode;
    className?: string;
}

const CatalogSubTitle: React.FC<IHeaderTitle> = ( { title, children ,className}  ) => {
    return (
        <div className={`${className}`}> 
            <h2 className={`${className}`}>
                {title}
            </h2>
             {Children.map(children, (child, index) => (  
                <div className="Row" key={index}> 
                {child}
                </div>
            ))}
        </div>
    )
}

export default CatalogSubTitle