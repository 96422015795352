function Gavel(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M2.32675 14.4337V13.3206H10.6565V14.4337H2.32675ZM6.21516 10.9003L2.28906 6.97422L3.68183 5.54814L7.64126 9.47422L6.21516 10.9003ZM10.75 6.35611L6.82386 2.39667L8.24996 1.00391L12.1855 4.93001L10.75 6.35611ZM13.9659 13.6163L4.80646 4.45682L5.73256 3.52131L14.892 12.6902L13.9659 13.6163Z" />
      </svg>
    </div>
  );
}

export default Gavel;
