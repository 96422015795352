import React, { useEffect, useMemo, useState } from 'react';
import MappingFilter from '../../components/Catalogs/Filters/MappingFilter';
import { AlphabetOptions } from '../../utils/AlphabetOptions';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import useDataLoader2 from '../../hooks/useDataLoader2';
import { UsePracticeIndustryList } from '../../hooks/Catalogs/usePracticeIndustry';
import { UseFirmList, firmType } from '../../hooks/Catalogs/useFirm';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { findPracticeIndustryNames, transformData } from '../../hooks/Table/TransformColumnsForExport';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { formatTimeSpanToString } from '../../utils/functions';




const AreaOfPractice: React.FC = ({ ...props }) => {
  const DefaultPageSize = 20;

  const { generateColumns } = useColumnConfig(['name', 'isMapped', 'isIgnored', 'isTargeted', 'firms', 'practiceIndustries', '_ts']);

  const [selectedFirm, setSelectedFirm] = useState<Option[]>([]);
  const [selectedPracticeIndustry, setSelectedPracticeIndustry] = useState<Option[]>([]);
  const [selectedInitials, setSelectedInitials] = useState<Option[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [selectedTab, setSelectedTab] = useState(1);
  const [tableInstance, setTableInstance] = useState<any>();
  const { practiceIndustryData: practiceIndustries } = UsePracticeIndustryList();
  const [aopData, setAopData] = useState<any>([]);
  const { firmData } = UseFirmList(firmType.member);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name', 
    'isMapped', 
    'isIgnored', 
    'isTargeted',   
    'firms',   
    'firmNames',
    'practiceIndustyNames',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/areaOfPractice',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    { id: 'isMapped', header: 'isMapped' },
    { id: 'isIgnored', header: 'isIgnored' },
    { id: 'isTargeted', header: 'isTargeted' },
    { id: 'firms', header: 'firms' },
    { id: 'practiceIndustries', header: 'practiceIndustries' },
    { id: '_ts', header: 'Created Date' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    },
  ];

  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, firmData, practiceIndustries);

const columns = generateColumns(columnConfigs.map(column => ({
  ...column,
  cell: column.cell || ((props: any) => {
    switch (column.id) {
      case 'count':
        return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
      case 'isMapped':
      case 'isIgnored':
      case 'isTargeted':
        return <p>{props.getValue() ? 'Yes' : 'No'}</p>;
      case 'firms':
        return props.getValue()?.map((firm: any) => firm.name).join(', ');
      case 'practiceIndustries':
        return findPracticeIndustryNames(props.row.original.id, practiceIndustries);
      case '_ts':
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      default:
        return <p>{props.getValue()}</p>;
    }
  })
})));

const formatData = (data: any[]) => {
  const formattedData = data.map((d: any) => {


    return {
      ...d,
      isMapped: d.isMapped ? 'Yes' : 'No',
      isIgnored: d.isIgnored ? 'Yes' : 'No',
      isTargeted: d.isTargeted ? 'Yes' : 'No',
      firms: d.firmNames,
      practiceIndustries: d.practiceIndustryNames
    };
  });
  return formattedData;
};

useEffect(() => {
  if (data) setAopData(formatData(data))
},[data]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      firms: {
        value: selectedFirm?.map(c => c.id),
      }, //TODO Modify how person data is saving
      initials: {
        value: selectedInitials?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      practiceIndustries: {value: selectedPracticeIndustry?.map(f => f.id)},
    }
    setFilter(filterAux)
  },[
    nameFilter,
    selectedPracticeIndustry, 
    selectedInitials, 
    selectedFirm
  ]);

  const clearSelections = () => {
    setSelectedPracticeIndustry([]);
    setSelectedFirm([]);
    setSelectedInitials([]);
    setNameFilter('');
    setFilter([]);
    fetchData(true)
  };

  const practiceIndustriesAsFilterProps: Option[] = useMemo(() => {
    return practiceIndustries
      .filter(pi => pi != null && typeof pi.name === 'string')
      .map(pi => ({ id: pi.id, name: pi.name } as Option));
  }, [practiceIndustries]);

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  

  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title="Areas Of Practice Catalog"
          catalogName="AREAS OF PRACTICE"
          catalogShortName='Area Of Practice'
          route="AreaOfPractice"
          headers={['name', 'isMapped', 'isIgnored', 'isTargeted', 'firms', 'practiceIndustries', '_ts']}
          totalCount={totalCount}
          isfiltered
          data={data}
          clearSelections={clearSelections}
          practiceIndustries={practiceIndustries}
          exportData={aopData}
        >
          <MappingFilter
            order={['name', 'firm', 'practiceIndustries', 'initial']}
            search={fetchData}
            resetFilters={clearSelections}
            headers={['name', 'isMapped', 'isIgnored', 'isTargeted', 'firms', 'practiceIndustries', '_ts']}
            practiceIndustriesData={practiceIndustriesAsFilterProps}
            selectedPracticeIndustries={selectedPracticeIndustry}
            setSelectedPracticeIndustries={setSelectedPracticeIndustry}
            selectedTab={selectedTab}
            initialsData={AlphabetOptions}
            firmData={firmData}
            selectedInitials={selectedInitials}
            setSelectedInitials={setSelectedInitials}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            nameFilter={nameFilter}
            onNameFilterChange={handleNameFilterChange}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default AreaOfPractice;
