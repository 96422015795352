function MessageIcon(props: any) {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#474F56"
    >
      <path d="M16.2115 8.49998V5.49998H8.21147V8.49998H6.7115V4H17.7115V8.49998H16.2115ZM18.0191 12.4999C18.3025 12.4999 18.54 12.4041 18.7317 12.2124C18.9233 12.0208 19.0191 11.7833 19.0191 11.4999C19.0191 11.2166 18.9233 10.9791 18.7317 10.7874C18.54 10.5958 18.3025 10.4999 18.0191 10.4999C17.7358 10.4999 17.4983 10.5958 17.3067 10.7874C17.115 10.9791 17.0191 11.2166 17.0191 11.4999C17.0191 11.7833 17.115 12.0208 17.3067 12.2124C17.4983 12.4041 17.7358 12.4999 18.0191 12.4999ZM16.2115 19.1923V14.923H8.21147V19.1923H16.2115ZM17.7115 20.6922H6.7115V16.6922H3V8.49998H21.4229V16.6922H17.7115V20.6922ZM19.923 15.1923V9.99993H4.49995V15.1923H6.7115V13.423H17.7115V15.1923H19.923Z" />
    </svg>
  );
}

export default MessageIcon;
