import React from "react";
import PencilEdit from "../Atomos/Icons/PencilEdit";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Context";

interface SignificantAccomplishmentsCardProps {
  person?: any;
  isEditable?: boolean;
}

const SignificantAccomplishmentsCard: React.FC<SignificantAccomplishmentsCardProps> = ({
  person,
  isEditable
}) => {
  const { mode } = useAppContext();

  const hasContent = (field: string | any[]) => {
    if (!field) return false;
    if (Array.isArray(field)) {
      return field.some(item => item && item.trim() !== '');
    }
    return field && field.trim() !== '';
  };

  const editLinkPath = mode === 'staff'
    ? `/staff/profile/edit/attorney/${person?.id}/${person?.generatedId}`
    : `/profile/edit/attorney/${person?.id}/${person?.generatedId}`;

  if (!person) {
    return null;
  }

  const hasSignificantAccomplishments = hasContent(person.significantAccomplishments);
  const hasAffiliations = hasContent(person.industryOrganizationAffiliation);
  const hasAdditionalActivities = hasContent(person.additionalActivities);
  const hasSeminarPresentation = hasContent(person.seminarPresentation);
  const hasAdditionalInformation = hasContent(person.additionalInformation);

  if (
    !hasSignificantAccomplishments &&
    !hasAffiliations &&
    !hasAdditionalActivities &&
    !hasSeminarPresentation &&
    !hasAdditionalInformation
  ) {
    return null;
  }

  return (
    <div className={`flex flex-col py-5 px-4 gap-[1.25rem] self-stretch items-end md:py-7 md:px-7 lg:py-10 lg:px-10`}>
      <div className="flex flex-col items-end gap-5 self-stretch">
        {hasSignificantAccomplishments && (
          <div className="flex flex-col items-start gap-5 self-stretch lg:gap-6">
            <span className="text-lg font-semibold leading-5 text-[#474F56] lg:text-[1.375rem] lg:leading-8 md:text-[1.25rem]">
              Significant Accomplishments
            </span>
            <span className="text-[#474F56] text-base leading-6 whitespace-pre-wrap lg:text-lg lg:leading-8">
              {person.significantAccomplishments}
            </span>
          </div>
        )}
        {hasAffiliations && (
          <div className="flex flex-col items-start gap-5 self-stretch lg:gap-6">
            <span className="text-lg font-semibold leading-5 text-[#474F56] lg:text-[1.375rem] lg:leading-8 md:text-[1.25rem]">
              Affiliations
            </span>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-y-3 md:gap-x-5 md:gap-y-[.625rem] self-stretch">
              {person.industryOrganizationAffiliation.filter((ioa: any) => ioa && ioa.trim() !== '').map((ioa: any, index: any) => (
                <span key={index} className="text-[#474F56] text-base leading-6 lg:text-lg lg:leading-8">&bull; {ioa}</span>
              ))}
            </div>
          </div>
        )}
        {hasAdditionalActivities && (
          <div className="flex flex-col items-start gap-5 self-stretch lg:gap-6">
            <span className="text-lg font-semibold leading-5 text-[#474F56] lg:text-[1.375rem] lg:leading-8 md:text-[1.25rem]">
              Additional Activities
            </span>
            <span className="text-[#474F56] text-base leading-6 whitespace-pre-wrap lg:text-lg lg:leading-8">
              {person.additionalActivities}
            </span>
          </div>
        )}
        {hasSeminarPresentation && (
          <div className="flex flex-col items-start gap-5 self-stretch lg:gap-6">
            <span className="text-lg font-semibold leading-5 text-[#474F56] lg:text-[1.375rem] lg:leading-8 md:text-[1.25rem]">
              Seminar/ Presentation
            </span>
            <span className="text-[#474F56] text-base leading-6 whitespace-pre-wrap lg:text-lg lg:leading-8">
              {person.seminarPresentation}
            </span>
          </div>
        )}
        {hasAdditionalInformation && (
          <div className="flex flex-col items-start gap-5 self-stretch lg:gap-6">
            <span className="text-lg font-semibold leading-5 text-[#474F56] lg:text-[1.375rem] lg:leading-8 md:text-[1.25rem]">
              Additional Information
            </span>
            <span className="text-[#474F56] text-base leading-6 whitespace-pre-wrap lg:text-lg lg:leading-8">
              {person.additionalInformation}
            </span>
          </div>
        )}
        {isEditable && (
          <Link to={editLinkPath} state={{ ...person, isOnlyProfessionalCareer: true }}>
            <div className="flex p-0 justify-center items-start gap-[.625rem]">
              <span className="text-[#0070CD] text-right font-decimal text-base font-bold leading-6 uppercase lg:text-[1.125rem] lg:leading-[1.5rem]">Edit</span>
              <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-Default">
                <PencilEdit className='w-full h-full pencilBlue' />
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SignificantAccomplishmentsCard;
