export const LEGACY_STAFF_URL = 'https://wsg-iis-dev.eastus.cloudapp.azure.com';
export const LEGACY_MEMBER_URL = 'http://wsg-iis-dev.eastus.cloudapp.azure.com';

export const LegacyRedirection = {
	'/staff/groups': `${LEGACY_STAFF_URL}/wsg_admin15.asp`,
	'/staff/events': `${LEGACY_STAFF_URL}/meetings_admin.asp`,
	'/staff/manage-profile': `${LEGACY_STAFF_URL}/wsg_admin22.asp`,
	'/staff/lists': `${LEGACY_STAFF_URL}/admin_invited.asp?action=page&pcomp=10435&show=m`,

	'/staff/send-grid': `${LEGACY_STAFF_URL}/dashboard_sendgrid.asp`,

	'/staff/overview/firm': `${LEGACY_STAFF_URL}/dashboard_powerbi.asp?rt=stats`,
	'/staff/overview/events': `${LEGACY_STAFF_URL}/dashboard_powerbi.asp?rt=events`,
	'/staff/overview/referrals': `${LEGACY_STAFF_URL}/dashboard_powerbi.asp?rt=referrals`,
	'/staff/overview/dues': `${LEGACY_STAFF_URL}/dashboard_powerbi.asp?rt=dues`,

	'/staff/google/wsg-site': `${LEGACY_STAFF_URL}/dashboard_ganalytics.asp?rt=all`,
	'/staff/google/firm-profile': `${LEGACY_STAFF_URL}/dashboard_ganalytics.asp?rt=firm`,
	'/staff/google/professional-profile': `${LEGACY_STAFF_URL}/dashboard_ganalytics.asp?rt=pro`,
	'/staff/google/article': `${LEGACY_STAFF_URL}/dashboard_ganalytics.asp?rt=article`,
	'/staff/google/campaigns': `${LEGACY_STAFF_URL}/dashboard_ganalytics.asp?rt=campaign`,

	'/staff/firm-score': `${LEGACY_STAFF_URL}/admin_firmsocialmedia.asp`,
	'/staff/practice-head': `${LEGACY_STAFF_URL}/admin_firmpracticeheads.asp`,
	'/staff/wsg-contacts': `${LEGACY_STAFF_URL}/admin_wsgcontacts.asp`,

	'/staff/firm-referrals': `${LEGACY_STAFF_URL}/admin_manage_referrals.asp`,
	'/staff/secondments': `${LEGACY_STAFF_URL}/admin_manage_secondments.asp`,
	'/staff/dealmaker': `${LEGACY_STAFF_URL}/admin_manage_dealmaker.asp`,
	'/staff/assessment-fee': `${LEGACY_STAFF_URL}/admin_assessmentfees.asp`,
	'/staff/member-to-member': `${LEGACY_STAFF_URL}/membertomember.aspx?eventid=2437`,

	'/staff/sync-module': `${LEGACY_STAFF_URL}/admin_importmodule.asp`,
	'/staff/press-sync': `${LEGACY_STAFF_URL}/admin_rssfeedsync.asp`,
	'/staff/rss-feed': `${LEGACY_STAFF_URL}/admin_Rssfeeds.asp`,
	
	'/staff/how-to': `${LEGACY_STAFF_URL}/howto_admin.asp`,
	'/staff/faq': `${LEGACY_STAFF_URL}/faq_admin.asp`,

	//#region member site
	'/staff/about-us': `${LEGACY_MEMBER_URL}/aboutus.asp`,
	'/about-us': `${LEGACY_MEMBER_URL}/aboutus.asp`,
	'/staff/members': `${LEGACY_MEMBER_URL}/wsg_members.asp`,
	'/members': `${LEGACY_MEMBER_URL}/wsg_members.asp`,
	'/groups': `${LEGACY_MEMBER_URL}/wsg_groups.asp`,
	'/events': `${LEGACY_MEMBER_URL}/meetings`,

	'/gc-insights': `${LEGACY_MEMBER_URL}/gcinsights.asp`,
	'/member-blogs': `${LEGACY_MEMBER_URL}/blog`,
	'/featured': `${LEGACY_MEMBER_URL}/blog/featuredblog.aspx`,
	'/staff/gc-insights': `${LEGACY_MEMBER_URL}/gcinsights.asp`,
	'/staff/member-blogs': `${LEGACY_MEMBER_URL}/blog`,
	'/staff/featured': `${LEGACY_MEMBER_URL}/blog/featuredblog.aspx`,

	'/wsg-blog': `${LEGACY_MEMBER_URL}/blog/default.aspx`,
	'/wsg-knowledge-center': `${LEGACY_MEMBER_URL}/wsg-knowledge-center.asp`,
	'/secondments': `${LEGACY_MEMBER_URL}/secondments.asp`,
	'/dealmakers': `${LEGACY_MEMBER_URL}/dealmaker.asp`,
	'/help-center': `${LEGACY_MEMBER_URL}/help.asp`,
	'/staff/wsg-blog': `${LEGACY_MEMBER_URL}/blog/default.aspx`,
	'/staff/wsg-knowledge-center': `${LEGACY_MEMBER_URL}/wsg-knowledge-center.asp`,
	'/staff/dealmakers': `${LEGACY_MEMBER_URL}/dealmaker.asp`,
	'/staff/help-center': `${LEGACY_MEMBER_URL}/help.asp`,

	'/release': `${LEGACY_MEMBER_URL}/releases.asp`,
	'/collaborations': `${LEGACY_MEMBER_URL}/success_stories.asp`,
	'/newsletters': `${LEGACY_MEMBER_URL}/networkwire_2q2024.asp`,
	'/latest-news': `${LEGACY_MEMBER_URL}/newsfeeds`,
	'/submit-article': `${LEGACY_MEMBER_URL}/submitarticle.asp?action=add`,
	'/submit-release': `${LEGACY_MEMBER_URL}/submitrelease.asp?action=add`,
	'/submit-event': `${LEGACY_MEMBER_URL}/manage_events.asp?action=add`,
	'/staff/release': `${LEGACY_MEMBER_URL}/releases.asp`,
	'/staff/collaborations': `${LEGACY_MEMBER_URL}/success_stories.asp`,
	'/staff/newsletters': `${LEGACY_MEMBER_URL}/networkwire_2q2024.asp`,
	'/staff/latest-news': `${LEGACY_MEMBER_URL}/newsfeeds`,
	'/staff/submit-article': `${LEGACY_MEMBER_URL}/submitarticle.asp?action=add`,
	'/staff/submit-release': `${LEGACY_MEMBER_URL}/submitrelease.asp?action=add`,
	'/staff/submit-event': `${LEGACY_MEMBER_URL}/manage_events.asp?action=add`,

	'/leadership-program': `${LEGACY_MEMBER_URL}/wsglearninginstitute_growthplay.asp`,
	'/marketing-workshop': `${LEGACY_MEMBER_URL}/wsglearninginstitute_marketingworkshop.asp`,
	'/emeritus-program': `${LEGACY_MEMBER_URL}/wsglearninginstituteoverview.asp`,
	'/staff/leadership-program': `${LEGACY_MEMBER_URL}/wsglearninginstitute_growthplay.asp`,
	'/staff/marketing-workshop': `${LEGACY_MEMBER_URL}/wsglearninginstitute_marketingworkshop.asp`,
	'/staff/emeritus-program': `${LEGACY_MEMBER_URL}/wsglearninginstituteoverview.asp`,

	'/covid-19': `${LEGACY_MEMBER_URL}/covid19_memberinsights.asp`,
	'/ukraine-crisis': `${LEGACY_MEMBER_URL}/hub-resource-center.asp`,
	'/staff/covid-19': `${LEGACY_MEMBER_URL}/covid19_memberinsights.asp`,
	'/staff/ukraine-crisis': `${LEGACY_MEMBER_URL}/hub-resource-center.asp`,
	//#endregion
}
