import React, { useState, useContext, useEffect, Fragment } from "react";
import css from "./Header.module.css";
import AuthContext from "../../../store/AuthContext";
import HeaderExtra from "../HeaderExtra/HeaderExtra";
import LightLogo from "../Logo/LightLogo";
import WhiteBars from "../../Atomos/Icons/WhiteBars";
import Search from "../../Atomos/Icons/Search";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import RedWorldDark from "../Logo/RedWorldDark";
import DarkSearch from "../../Atomos/Icons/DarkSearch";
import RedBars from "../../Atomos/Icons/RedBars";
import Account from "../../Atomos/Icons/Account";
import {  useNavigate, Link } from 'react-router-dom';
import Axios from "../../../utils/axios";
import { NavigationItemProps } from "../Navigation/INavigation";
import
  {
    GetInsights, GetCategoriesSubNav, GetMemberNavigation, GetLearningInstitute, GetGlobalCrisisHub, GetSEOSubNav, GetStaffNavigation, GetCategoriesFirm, GetResources, GetNewsArticles,
    GetAnalyticsDashboard, GetTools, GetSync, GetHelpGuide, GetWSGOverview, GetGoogleAnalytics
  }
from "../Navigation/NavigationLists";
import AnimationLogo from "../LoadingAnimation/AnimationLogo";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import Navigation from "../Navigation/Navigation";

interface User {
  name: string;
  middleName?: string;
  lastName?: string;
  // ... otros campos relevantes
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { tokenDecoded, isAuthenticated } = useContext(AuthContext);
  const loginUrl = process.env.REACT_APP_SIGNIN_AZB2C_URL as string;
  const [isHeaderExtraOpen, setIsHeaderExtraOpen] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isCatalogsOpen, setIsCatalogsOpen] = useState(false);
  const [isSubCatalogsOpen, setIsSubCatalogsOpen] = useState(false);
  const [showingLoader, setShowingLoader] = useState(false);
  const userPermissions = tokenDecoded?.extension_WSG_Permissions 
  ? tokenDecoded.extension_WSG_Permissions.split(',') 
  : [];
  const axios = new Axios();

  const [user, setUser] = useState<User | null>(null);
  const [userPhoto, setUserPhoto] = useState('');

  const { isStaffMode, toggleStaffMode } = useStaffMode();

  const fetchPerson = async () => {
    const res: any = await axios.Get('/person')
    res.data.forEach((person: any) => {
      if (person.userId === tokenDecoded?.oid) {
        setUser(person)
        setUserPhoto(person.photo)
      }
    })
  }

  useEffect(() => {
    fetchPerson()
  }, [])

  const [routeName, setRouteName] = useState('');

  const toggleNav = () => {
    const body = document.querySelector('body') as HTMLElement;
    setIsNavigationOpen(!isNavigationOpen);
    !isNavigationOpen && body.classList.add('fixed', 'w-full');
  };

  const toggleCatalog = (name: string) => {
    const validCatalogNames = [
      'Insights', 'Catalogs', 'WSG Learning Institute', 'Global Crisis Hub', 'SEO', 'Firm', 'Resources', 'News & Articles',
      'Analytics Dashboard', 'Tools', 'Sync', 'Help Guide', 'WSG Overview', 'Google Analytics'
    ];

    if (!validCatalogNames.includes(name)) {
      return;
    }

    setIsCatalogsOpen(true); // Always open catalogs on toggle
    setRouteName(name);
  };

  const handleClose = () => {
    setIsCatalogsOpen(false);
  };

  const toogleSubmenus = () => {
    const body = document.querySelector('body') as HTMLElement;
    body.classList.remove('fixed')
    setIsNavigationOpen(!isNavigationOpen);
    setIsCatalogsOpen(!isCatalogsOpen);
  };

  const headerColor = isAuthenticated ? (isStaffMode ? "bg-Default" : " bg-accentDefault") : "bg-white";

  const handleToggle = () => {
    toggleHeaderExtra()
  };

  const toggleHeaderExtra = () => {
    setIsHeaderExtraOpen(!isHeaderExtraOpen);
  };

  const getInsights: NavigationItemProps[] = GetInsights(toggleNav);
  const catalogNavegation: NavigationItemProps[] = GetCategoriesSubNav(toggleNav).sort((a, b) => a.name.localeCompare(b.name));
  const getLearningInstitute: NavigationItemProps[] = GetLearningInstitute(toggleNav);
  const getGlobalCrisisHub: NavigationItemProps[] = GetGlobalCrisisHub(toggleNav);
  const seoNavigation: NavigationItemProps[] = GetSEOSubNav(toggleNav);
  const firmNavigation: NavigationItemProps[] = GetCategoriesFirm(toggleNav);
  const staffnavigation: NavigationItemProps[] = GetStaffNavigation(toggleNav, userPermissions);
  const memberNavegation: NavigationItemProps[] = GetMemberNavigation(toggleNav);
  const resourceNavigation: NavigationItemProps[] = GetResources(toggleNav);
  const analyticsDashboard: NavigationItemProps[] = GetAnalyticsDashboard(toggleNav);
  const tools: NavigationItemProps[] = GetTools(toggleNav);
  const sync: NavigationItemProps[] = GetSync(toggleNav);
  const helpGuide: NavigationItemProps[] = GetHelpGuide(toggleNav);
  const wsgOverview: NavigationItemProps[] = GetWSGOverview(toggleNav);
  const googleAnalytics: NavigationItemProps[] = GetGoogleAnalytics(toggleNav);
  const newsAndArticlesNavigation: NavigationItemProps[] = GetNewsArticles(toggleNav);
  const mainNavegation: NavigationItemProps[] = isStaffMode
    ? memberNavegation.concat(staffnavigation)
    : memberNavegation;

  let navigationListToUse;
  switch (routeName) {
    case 'Insights':
      navigationListToUse = getInsights;
      break;
    case "Catalogs":
      navigationListToUse = catalogNavegation;
      break;
    case "WSG Learning Institute":
      navigationListToUse = getLearningInstitute;
      break
    case "Global Crisis Hub":
      navigationListToUse = getGlobalCrisisHub;
      break
    case "SEO":
      navigationListToUse = seoNavigation;
      break;
    case "Firm":
      navigationListToUse = firmNavigation;
      break;
    case "Resources":
      navigationListToUse = resourceNavigation;
      break;
    case "News & Articles":
      navigationListToUse = newsAndArticlesNavigation;
      break;
    case "Analytics Dashboard":
      navigationListToUse = analyticsDashboard;
      break;
    case "Tools":
      navigationListToUse = tools;
      break;
    case "Sync":
      navigationListToUse = sync;
      break;
    case "Help Guide":
      navigationListToUse = helpGuide;
      break;
    case "WSG Overview":
      navigationListToUse = wsgOverview;
      break;
    case "Google Analytics":
      navigationListToUse = googleAnalytics;
      break;
    default:
      navigationListToUse = mainNavegation;
  }

  return (
    <> {
      showingLoader ?
        <AnimationLogo />
        :
        <div className={`relative`}>
          <div className={`absolute transition-all duration-500 ${isNavigationOpen ? 'z-40' : '-z-10 hidden'}`}>
            <Navigation NavLvl={0} isAuthenticated={isAuthenticated} closeAll={toogleSubmenus} opensubmenu={toggleCatalog} isSubMenuOpen={isCatalogsOpen} isOpen={isNavigationOpen} onClose={toggleNav} IsStaffNavigate={isStaffMode} navigationList={mainNavegation} />
            <Navigation NavLvl={1} isAuthenticated={isAuthenticated} closeAll={toogleSubmenus} opensubmenu={toggleCatalog} isSubMenuOpen={isSubCatalogsOpen} isOpen={isCatalogsOpen} onClose={handleClose} IsStaffNavigate={isStaffMode} navigationList={navigationListToUse} tabTitle={routeName === 'SEO' ? 'Meta Tags' : undefined} />
          </div>

          <div className={`relative header-container z-30 w-full p-5  top-0 ${headerColor}`} >
            <div className="flex items-center header-components align-middle h-10">
              <div className="flex items-center ">
                <div className="w-[5.8rem] h-[2rem] md:w-[7.25rem] md:h-[2.5rem]">
                  <Link to="/">
                    {isAuthenticated ?
                      <LightLogo /> :
                      <RedWorldDark />
                    }
                  </Link>
                </div>
                <div className="items-center pl-6">
                </div>
              </div>

              <div className="flex align-middle h-10 ml-auto justify-center">
                {!isAuthenticated ? (
                  <Link
                    to={loginUrl}
                    className={`flex items-center justify-center h-fit my-auto lg:mr-8 roboto_font ${css.grayText}`}
                  >
                    <div className="w-8 h-8"><Account /></div>
                    <span className="hidden md:flex mr-0 md:mr-4 lg:mr-0 text-lg">
                      Member login
                    </span>
                  </Link>
                ) : (
                  <div className="w-auto h-auto flex mr-4 md:mr-6 md:gap-2 items-center justify-center">
                    <div className="md:w-10 md:h-10 h-8 w-8 relative rounded-full bg-secondary-light flex items-center align-middle justify-center">
                      {
                        isAuthenticated && user && (
                          <img src={userPhoto} alt="profile phto" className='relative rounded-full object-center object-cover h-full' />
                        )
                      }
                    </div>
                    <button className="flex items-center justify-center" onClick={toggleHeaderExtra}>
                      <span className="text-white font-bold hidden md:block text-lg">{user?.name} {user?.middleName} {user?.lastName}</span>
                      <div className="flex w-5 h-5 ml-1 fill-white " onClick={(e) => { e.currentTarget.classList.toggle('rotate-180') }}>
                        {isHeaderExtraOpen && !isNavigationOpen ?
                          <>
                            <ChevronDown />
                          </>
                          : <ChevronDown />
                        }
                      </div>
                    </button>

                  </div>
                )}
                <div className="flex items-center justify-center">
                  <div className=" w-6 h-6 fill-neutral">
                    {isAuthenticated ? <Search /> : <DarkSearch />}
                  </div></div>
                <p
                  className={`text-lg md:flex hidden h-fit my-auto mr-12 roboto_font hover:opacity-80 font-bold ${isAuthenticated ? 'text-white' : ''}`}
                >
                  Member search
                </p>
                <div className={`flex align-middle justify-center items-center w-12 h-12 ${isAuthenticated ? 'fill-neutral' : 'fill-accentDefault'} `} onClick={toggleNav} >
                  {isAuthenticated ? <WhiteBars /> : <RedBars />}
                </div>
              </div>
            </div>
          </div>

          <div className={`absolute top-17 w-full z-20 transform transition-transform ease-in-out duration-150 ${isHeaderExtraOpen ? 'translate-y-0' : '-translate-y-full'} mt-auto`}>
            <HeaderExtra isOpen={true} onNavigate={toggleHeaderExtra} onToggle={handleToggle} IsStaffNavigate={isStaffMode} />
          </div>
        </div>
    }
    </>
  );
};

export default Header;
