import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const jurisdictionGroupsSlice = createSlice({
  name: "jurisdictionGroups",
  initialState: Empty,
  reducers: {
    SetJurisdictionGroups: (_, action) =>  action.payload,
  },
});

export const { SetJurisdictionGroups } = jurisdictionGroupsSlice.actions;
