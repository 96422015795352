import React from "react";

function PlaceHolderProfile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 16 16" 
    >
     
        <path  d="M0 0H16V16H0z"></path>
     
    </svg>
  );
}

export default PlaceHolderProfile;
