import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Axios from "../../utils/axios";
import PencilEdit from "../../components/Atomos/Icons/PencilEdit";
import NewButton from "../../components/Atomos/Buttons/newButton";
import SortArrow from "../../components/Atomos/Icons/SortArrow";
import GoBack from "../../components/Moleculas/GoBack";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import "../../assets/css/AddCatalogs.css";
import OfficeMembership from "../../components/Catalogs/OfficeContact/OfficeMembership";

interface Firm {
  id?: string;
  name?: string;
  shortId?: string;
  membershipType?: string;
}

const OfficeContactView: React.FC = () => {
  const axios = new Axios();
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState<Firm[] | null>(null);
  const [firm, setFirm] = useState<Firm>();
  const [officesData, setOfficesData] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [contactOfficesId, setContactOfficesId] = useState<any[]>([]);

  const [officeGroup, setOfficeGroup] = useState<any[]>([]);
  const [officesToPass, setOfficesToPass] = useState<any[]>([]);
  const [sortedOffices, setSortedOffices] = useState<any[]>([]);

  useEffect(() => {
    const GetOffice = async () => {
      try {
        const response = await axios.Get(`/office`);
        const data = response.data;
        if (data != null) {
          setOfficesData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetOffice();
    const fetchFirmData = async () => {
      try {
        const response = await axios.Get("/firm");
        const data = response.data;
        if (data != null) {
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFirmData();
  }, []);

  useEffect(() => {
    if (state.offices) {
      const updatedContactOffices: any = state.offices.map(
        (office: any) => office.office
      );
      setContactOfficesId(updatedContactOffices);
    }
  }, [state.offices]);

  useEffect(() => {
    const expectedFirm = data?.find((firm) => firm.id === state.firm);
    setFirm(expectedFirm);
  }, [data]);

  useEffect(() => {
    const sortOffices = (officesToPass ?? []).slice().sort((a: any, b: any) => {
      const nameA =
        officesData.find((of: any) => of.id === a.office)?.name || "";
      const nameB =
        officesData.find((of: any) => of.id === b.office)?.name || "";
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedOffices(sortOffices);
  }, [officesToPass, sortOrder]);

  useEffect(() => {
    handleOffices();
  }, [firm, officesData]);

  const handleOffices = () => {
    const activeIds = officesData
      .filter((office) => office.firm === firm?.id)
      .map((office) => office.id);

    setOfficeGroup(activeIds);
    const missingIds = activeIds.filter((id) => !contactOfficesId.includes(id));
    const missingOffices = missingIds.map((id) => ({
      office: id,
      contacts: [],
    }));

    const updatedJurisdictions = [
      ...(state.offices || []).filter((office: any) =>
        activeIds.includes(office.office)
      ),
      ...missingOffices,
    ];
    setOfficesToPass(updatedJurisdictions);
  };

  return (
    <>
      <div className="mainSection">
        <CatalogTitle
          title={`${firm ? firm?.name : ""}`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>
        <section className="gap-6">
          <div className="flex h-auto w-full flex-col gap-6 lg:gap-7 p-10 bg-white">
            <div className="flex gap-6 flex-wrap">
              <div className="flex items-center w-full gap-1 bg-white">
                <span className="text-Default text-[24px] md:text-[28px] lg:text-[32px] leading-8 lg:leading-9 font-medium not-italic">
                  Member Main Contacts
                </span>
              </div>

              <div className="flex flex-wrap md:flex-nowrap justify-between items-center w-full gap-5 bg-white">
                {/* Edit Contacts Button (w-full for small screens, md:w-auto for larger screens) */}
                <div className="w-full md:w-auto order-first md:order-last">
                  <Link
                    to={`/Staff/Catalogs/MemberMainContact/Edit/` + id}
                    state={{
                      ...state,
                      offices: sortedOffices,
                      officesId: officeGroup,
                      officesObjects: contactOfficesId,
                      firmMembershipType: firm?.membershipType,
                      firmName: firm?.name,
                    }}
                  >
                    <NewButton
                      text="Edit Contacts"
                      color="accent"
                      style="filled"
                      size="large"
                      content="textIcon"
                      icon={<PencilEdit />}
                    />
                  </Link>
                </div>

                {/* Sorting Section (flex-wrap for small screens, md:flex-nowrap for larger screens) */}
                <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                  <div>
                    <span className="text-Default not-italic leading-5 font-semibold text-lg">
                      Sort By
                    </span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2"
                      height="20"
                      viewBox="0 0 2 20"
                      fill="none"
                    >
                      <path d="M1 0V20" stroke="#EBEBEB" />
                    </svg>
                  </div>
                  <div
                    className="flex items-center gap-3 cursor-pointer"
                    onClick={() =>
                      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                    }
                  >
                    <span className="text-gray-font not-italic leading-5 font-normal text-lg">
                      Office
                    </span>
                    <SortArrow
                      className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrder === "desc" && "rotate-180"}`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-5 md:gap-6 lg:gap-7">
              {sortedOffices.map((office: any) => (
                <OfficeMembership
                  office={
                    officesData.find((of: any) => of.id === office.office)?.name
                  }
                  contacts={office.contacts}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OfficeContactView;
