// context/LoggingContext.tsx
import React, { createContext, useContext, useEffect, ReactNode, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../store/AuthContext';
import Axios from '../utils/axios';
const axios = new Axios();

interface ActivityLog {
  personId: string;
  action: string;
  page: string;
  resourceUpdated?: string;
  _ts: string;
}

interface LoggingContextProps {
  logActivity: (action: string, page: string, resourceUpdated?: string) => void;
  deleteActivityLog: (id: string) => void;
}

const LoggingContext = createContext<LoggingContextProps | undefined>(undefined);

interface LoggingProviderProps {
  children: ReactNode;
}

const excludedRoutes = ['/Staff/activityLog'];

export const LoggingProvider: React.FC<LoggingProviderProps> = ({ children }) => {
  const location = useLocation();
  const { tokenDecoded } = useContext(AuthContext);
  const lastLogTime = useRef<number | null>(null);
  const delay = 5000;

  const formatDetails = (details: any): string => {
    return JSON.stringify(details, null, 2);
  };

  const logActivity = async (action: string, page: string, resourceUpdated?: string) => {
    const normalizedPage = page.toLowerCase();
    const isExcluded = excludedRoutes.some(route => route.toLowerCase() === normalizedPage);

    if (isExcluded) {
      return;
    }

    const userId = tokenDecoded?.oid;

    if (!userId) {
      console.warn('User not authenticated');
      return;
    }

    const log: ActivityLog = {
      personId: userId,
      action,
      page,
      resourceUpdated: resourceUpdated ? formatDetails(resourceUpdated) : undefined,
      _ts: new Date().toISOString(),
    };

    const now = Date.now();
    if (lastLogTime.current && now - lastLogTime.current < delay) {
      return;
    }
    lastLogTime.current = now;

    try {
      await axios.Post('/activityLog', log);
    } catch (error) {
      console.error('Error logging activity:', error);
    }
  };

  const deleteActivityLog = async (id: string) => {
    try {
      await axios.Delete('/activityLog', id);
    } catch (error) {
      console.error('Error deleting activity log:', error);
    }
  };


  return (
    <LoggingContext.Provider value={{ logActivity, deleteActivityLog }}>
      {children}
    </LoggingContext.Provider>
  );
};

export const useLogging = (): LoggingContextProps => {
  const context = useContext(LoggingContext);
  if (!context) {
    throw new Error('useLogging must be used within a LoggingProvider');
  }
  return context;
};
