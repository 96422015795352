import React, { useEffect, useState } from "react";
import ContactFrame from "../ContactFrame";
import Vector from "../../../../components/Atomos/Icons/Vector";

interface DataItem {
  id?: string;
  name?: string;
  phoneNumber?: string;
  firmEmail?: string;
  countryCodePhoneNumber?: string;
  lastName?: string;
}

interface JurisdictionMembershipProps {
  jurisdiction?: string;
  contacts?: { type: string; contact: string }[];
  firm?: string;
  filterBy?: string[];
  persons?: any[];
}

const formatContactType = (type: string): string => {
  const formattedType = type?.replace(/([A-Z])/g, " $1").trim();
  return formattedType.charAt(0).toUpperCase() + formattedType.slice(1);
};

const JurisdictionMembership: React.FC<JurisdictionMembershipProps> = ({
  jurisdiction,
  contacts,
  filterBy,
  persons,
}) => {
  const [contactsWithDetails, setContactsWithDetails] = useState<
    {
      type: string;
      contact: string;
      name?: string;
      phoneNumber?: string;
      firmEmail?: string;
      lastName?: string;
    }[]
  >([]);

  useEffect(() => {
    if ((persons ?? []).length > 0 && contacts) {
      const updatedContacts = contacts.map((contact) => {
        const foundPerson = persons?.find(
          (person) => person.id === contact.contact
        );
        return {
          ...contact,
          name: foundPerson?.name,
          phoneNumber:
            foundPerson?.countryCodePhoneNumber +
            " " +
            foundPerson?.phoneNumber,
          firmEmail: foundPerson?.firmEmail,
          countryCodePhoneNumber: foundPerson?.countryCodePhoneNumber,
          lastName: foundPerson?.lastName,
        };
      });
      setContactsWithDetails(updatedContacts);
    }
  }, [persons, contacts]);

  return (
    <div className="flex flex-col items-left gap-6">
      <div className="flex flex-row items-center self-stretch gap-4">
        <h2 className="not-italic font-medium text-lg leading-5 text-Default font-decimal">
          {jurisdiction + " Membership"}
        </h2>
        <div className="flex flex-1 ">
          <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
        </div>
      </div>
      <div className="grid grid-cols-6 gap-5">
        {contactsWithDetails
          .filter((contact) => contact && contact.name)
          .sort((a: any, b: any) => {
            if (filterBy && filterBy.length > 0) {
              for (const filter of filterBy ? filterBy : []) {
                if (a[filter] === b[filter]) {
                  continue;
                }
                return a[filter] < b[filter] ? -1 : 1;
              }
            }
            return 0;
          })
          .map(
            (contact, index) =>
              contact.name && (
                <ContactFrame
                  key={index}
                  type={formatContactType(contact.type)}
                  name={contact.name + " " + contact.lastName}
                  phone={contact.phoneNumber}
                  email={contact.firmEmail}
                />
              )
          )}
      </div>
    </div>
  );
};

export default JurisdictionMembership;
