import React, { useEffect, useState } from 'react';
import ManageFirmFilterList from '../../components/ManageFirm/TableFilters/ManageFirmFilterList';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import BackgroundComponent from '../../components/Layout/BackgroundProfile/BackgroundProfile';
import ManageFirmBackground from '../../assets/images&icons/bg-manageFirm.png'
import CurrentPage from '../../components/Layout/CurrentPageTab/CurrentPage';
import CatalogTitle from '../../components/Moleculas/Catalog Text/CatalogTitle';
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { ColumnConfig, useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { formatTimeSpanToString, getNameById, isUUID } from '../../utils/functions';
import { Link } from 'react-router-dom';
import { UseJurisdictionGroupList } from '../../hooks/Catalogs/useJurisdictionGroup';
import { useStatesList } from '../../hooks/Catalogs/useStates';
import { UseCountryList } from '../../hooks/Catalogs';
import { UseJurisdictionList } from '../../hooks/Catalogs/useJurisdiction';
import { UseRegionList } from '../../hooks/Catalogs/useRegionListOptions';
import Bar_chart_4_bars from '../../components/Atomos/Icons/Bar_chart_4_bars';
import Receipt_long from '../../components/Atomos/Icons/Receipt_long';
import Imagesmode from '../../components/Atomos/Icons/Imagesmode';
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const ManageFirmList: React.FC = ({ ...props }) => {
  const Headers: string[] = ['shortId', 'region', 'membershipType', 'jurisdiction', 'firmName', 'membershipStatus', 'serviceType', 'dateCreated','logo'];
  const DefaultPageSize = 50;
  const { regionData } = UseRegionList();
  const { jurisdictionData } = UseJurisdictionList();
  const { countryData } = UseCountryList();
  const { statesData } = useStatesList();
  const { jurisdictionGroupData } = UseJurisdictionGroupList();
  const [firmFilters, setFirmFilters] = useState<{ 
    regions: Option[], 
    jurisdictionTypes: Option[], 
    membershipStatus: Option[], 
    serviceTypes: Option[] 
  }>({ 
    regions: [], 
    serviceTypes: [], 
    membershipStatus: [], 
    jurisdictionTypes: [] 
  });
  const [firmData, setFirmData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { generateColumns } = useColumnConfig(Headers);

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'shortId', header: 'Short ID' },
    { id: 'region', header: 'Region' },
    { id: 'membershipType', header: 'Membership Type' },
    { id: 'jurisdiction', header: 'Jurisdiction' },
    { id: 'firmName', header: 'Firm Name', 
      cell: (props: any) => (
        <Link
          className="edit text-secondary underline"
          to={`generalTab/${props.row.original.id}`}
          state={{...props.row.original, selectedTab:"GeneralTab"}}
        >
          {props.getValue()}
        </Link>
      ) 
    },
    { id: 'membershipStatus', header: 'Membership Status' },
    { id: 'serviceType', header: 'Service Type' },
    { id: '_ts', header: 'Created Date' },

    {
      id: 'edit',
      header: 'Actions',
      enableSorting: false,
      cell: (props: any) => (
        <div className="flex font-bold items-center min-w-[13.29rem] gap-4">
          <div className="flex ">
            <Link
              className="edit"
              to={`View/${props.row.id}`}
              state={props.row}
            >
              <span>KPIs</span>
              <div className="h-5 w-5">
                <Bar_chart_4_bars />
              </div>
            </Link>
          </div>
          <div className="flex ">
            <Link
              className="edit"
              to={`View/${props.row.id}`}
              state={props.row}
            >
              <span>Activity</span>
              <div className="h-5 w-5">
                <Receipt_long />
              </div>
            </Link>
          </div>
          <div className="flex">
            <Link
              className="edit"
              to={`View/${props.row.id}`}
              state={props.row}
            >
              <span>Logo</span>
              <div className="h-5 w-5">
                <Imagesmode />
              </div>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/firm',
    defaultPageSize: DefaultPageSize,
  });

  const clearSelections = () => {
    setFirmFilters({ regions: [], jurisdictionTypes: [], serviceTypes: [], membershipStatus: [] });
    fetchData(true);
  }

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case '_ts':
          const value = props?.getValue();
          return (
            <p>
              {value && formatTimeSpanToString(value) }
            </p>
          );
        default:
          return <p>{props?.getValue()}</p>;
      }
    })
  })));

  const [membershipStatus] = useState<Option[]>([{ id: "accepted", name: "Accepted" }, { id: "member", name: "Member" }, { id: "onboarding", name: "Onboarding" }, { id: "temporaryWithdrawn", name: "Temporary Withdrawn" }, { id: "withdrawn", name: "Withdrawn" }]);
  const [serviceTypes] = useState<Option[]>([{ id: "accounting", name: "Accounting" }, { id: "investmenBanking", name: "Investment Banking" }, { id: "legal", name: "Legal" }, { id: "other", name: "Other" }]);
  const [jurisdictionTypeData] = useState<Option[]>([{ id: "Primary", name: "Primary" }, { id: "Secondary", name: "Secondary" }, { id: "Regional", name: "Regional" }, { id: "Multiple", name: "Multiple" }]);

  const transformDataForExport = (data: any) => {
    let transformedData: any[] = [];

    data.forEach((row: any) => {
      transformedData.push(row);
      if (row.hasSecondary && Array.isArray(row.secondaryJurisdictionsNames)) {
        row.secondaryJurisdictionsNames.forEach((secondary: any, idx: any) => {
          const secondaryRow = {
            membershipType: "Secondary",
            jurisdiction: secondary,
            region: row.region,
          };
          transformedData.push(secondaryRow);
        });
      }
    });
    return transformedData;
  };

  const formatData = (data: any[]) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        jurisdiction: getNameById(d.jurisdiction, jurisdictionData),
        secondaryJurisdiction: getNameById(d.secondaryJurisdiction, jurisdictionData),
        jurisdictionGroup: getNameById(d.jurisdictionGroup, jurisdictionGroupData),
        region: typeof d.region === 'string' ? d.region : d.region?.map((r: any) => getNameById(r.id, regionData)),
        state: isUUID(d.state) ? getNameById(d.state, statesData) : d.state,
        country: isUUID(d.country) ? getNameById(d.country, countryData) : d.country,
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (data) setFirmData(formatData(data))
  },[data]);

  useEffect(() => {
    localStorage.removeItem("firmCountWorldwide");
    localStorage.removeItem("firmCountPrimary");
  }, []);


  useEffect(() => {
    if (
      !jurisdictionData.length || 
      !jurisdictionGroupData.length || 
      !regionData.length || 
      !statesData.length || 
      !countryData.length
    ) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[jurisdictionData, jurisdictionGroupData, regionData, statesData, countryData]);

  useEffect(() => {
    let filterAux = {
      membershipType: {
        value: firmFilters.jurisdictionTypes?.map(f => f.name),
        comparisonType: 'EQUAL'
      },
      region: {
        value: firmFilters.regions?.map(f => f.name),
        comparisonType: 'EQUAL'
      },
      serviceType: {
        value: firmFilters.serviceTypes?.map(f => f.name),
        comparisonType: 'EQUAL'
      },
      membershipStatus: {
        value: firmFilters.membershipStatus?.map(f => f.name),
        comparisonType: 'EQUAL'
      },
    }
    setFilter(filterAux)
  },[firmFilters]);

  return (
    <>
      <BackgroundComponent image={ManageFirmBackground} />


      <div className=' mainSection'>
        <div className='flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5'>
          <div className='flex lg:min-h-[70px] gap-4'>
            <CatalogTitle title={`Manage Firm`} className='text-white !p-0' />
            <div className='min-w-[29rem] min-h[4.5rem] overflow-hidden flex-1 relative hidden z-20 lg:flex' style={{
              backgroundImage: `url(${WSGDots.default})`,
            }}></div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Home" currentPage={`Manage Firm`} />
          </div>
        </div>


        <CatalogTableC
          catalogName={'FIRMS'} route={'Firm'}
          catalogShortName={'FIRM'}
          isfiltered={true}
          data={data} headers={Headers}
          totalCount={totalCount}
          exportData={transformDataForExport(data)}
        >
          <ManageFirmFilterList key={0}
            Search={fetchData}
            filters={firmFilters} setFilters={setFirmFilters}
            regionData={regionData} jurisdictionData={jurisdictionTypeData} serviceTypeData={serviceTypes} membershipStatusData={membershipStatus}//los datos para los dropdowns
            headers={Headers}
            clearSelections={clearSelections}
          />
          <TableWithPagination
              data={firmData}
              columnsDef={columns}
              sorting={sort}
              setSorting={setSort}
              totalCount={totalCount}
              pagination={pagination}
              setPagination={setPagination}
            />
        </CatalogTableC>
      </div>
    </>

  );
};

export default ManageFirmList;
