import "../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Vector from "../../../components/Atomos/Icons/Vector";
import ContactFrame from "./ContactFrame";
import SortArrow from "../../../components/Atomos/Icons/SortArrow";
import Manage from "../../../components/Atomos/Icons/Manage";

interface IMainBoxProps {
  dataAdminList: any;
  personList: any;
}

const DataAdminGT: React.FC<IMainBoxProps> = ({
  dataAdminList,
  personList,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortOrderType, setSortOrderType] = useState<"asc" | "desc">("asc");
  const [sortedAdminList, setSortedAdminList] = useState<any[]>([]);

  useEffect(() => {
    const sortedList = [...dataAdminList].sort((a: any, b: any) => {
      let locationA = a.membershipLocation;
      let locationB = b.membershipLocation;

      // Handle "Primary" case
      if (locationA === "Primary") locationA = "";
      if (locationB === "Primary") locationB = "";

      if (sortOrder === "asc") {
        return locationA.localeCompare(locationB);
      } else {
        return locationB.localeCompare(locationA);
      }
    });

    setSortedAdminList(sortedList);
  }, [dataAdminList, sortOrder]);

  useEffect(() => {
    const sortedList = [...dataAdminList].sort((a: any, b: any) => {
      let typeA = a.type === 0 ? "Primary" : a.membershipLocation + " Admin";
      let typeB = b.type === 0 ? "Primary" : b.membershipLocation + " Admin";

      if (typeA === "Primary" && typeB === "Primary") return 0;
      if (typeA === "Primary") return sortOrderType === "asc" ? -1 : 1;
      if (typeB === "Primary") return sortOrderType === "asc" ? 1 : -1;

      if (sortOrderType === "asc") {
        return typeA.localeCompare(typeB);
      } else {
        return typeB.localeCompare(typeA);
      }
    });

    setSortedAdminList(sortedList);
  }, [sortOrderType]);

  return (
    <>
      <div className="flex flex-col gap-10 justify-end">
        <article className="articleSection relative">
          <section className="flex w-full flex-col gap-7">
            <div className="flex flex-col gap-5 lg:flex-row lg:justify-between">
              <CatalogSubTitle
                title={"Data Admin"}
                className="text-Default lg:text-[32px] md:text-[28px] text-[26px] not-italic font-medium lg:leading-9 md:leading-[44px] leading-normal"
              />

              <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                <div>
                  <span className="text-Default not-italic font-semibold lg:leading-5 lg:text-lg text-base leading-[18px] ">
                    Sort By
                  </span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="20"
                    viewBox="0 0 2 20"
                    fill="none"
                  >
                    <path d="M1 0V20" stroke="#EBEBEB" />
                  </svg>
                </div>
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                    Membership Location
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrder === "desc" && "rotate-180"}`}
                  />
                </div>
                <div className="flex items-center text-center gap-3">
                  <span className="text-gray-font text-center justify-center not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px] ">
                    •
                  </span>
                </div>
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrderType(sortOrderType === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                    Data Admin Type
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrderType === "desc" && "rotate-180"}`}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-1 ">
              <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
            </div>

            <div className="grid grid-cols-6 gap-5">
              {sortedAdminList.map((contact: any) => {
                const foundPerson = personList.find(
                  (person: any) => person.id === contact.person
                );
                return (
                  <ContactFrame
                    key={contact.id}
                    type={
                      contact.type === 0
                        ? "Primary"
                        : contact.membershipLocation + " Admin"
                    }
                    name={foundPerson?.name + " " + foundPerson?.lastName}
                    phone={
                      foundPerson
                        ? foundPerson.countryCodePhoneNumber +
                          " " +
                          foundPerson.phoneNumber
                        : ""
                    }
                    email={foundPerson?.firmEmail}
                  />
                );
              })}
            </div>
          </section>
          <div className="flex w-full gap-2.5 items-center justify-end">
            <span className=" text-base not-italic font-bold leading-7 text-primary">
              MANAGE
            </span>
            <Manage className="w-4 h-4 fill-primary" />
          </div>
        </article>
      </div>
    </>
  );
};

export default DataAdminGT;
