import React from "react";
import PaginationComponent from "../../../components/Atomos/Pagination/PaginationComponent";
import TableV2 from "./TableTransitionToTanSackArticles";

interface TableWithPaginationProps {
  data: any[];
  columns: any[];
  totalCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  recordsPerPage: number;
  setRecordsPerPage: (size: number) => void;
  setTableInstance: (instance: any) => void;
  sort: any;
  setSort: (sort: any) => void;
}

const TableWithPagination: React.FC<TableWithPaginationProps> = ({
  data,
  columns,
  totalCount,
  currentPage,
  setCurrentPage,
  recordsPerPage,
  setRecordsPerPage,
  setTableInstance,
  sort,
  setSort,
}) => {
  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setRecordsPerPage(pageSize);
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5">
        <PaginationComponent
          recordsPerPage={recordsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalCount={totalCount}
          member={true}
        />
        <TableV2
          data={data.slice(
            (currentPage - 1) * recordsPerPage,
            (currentPage - 1) * recordsPerPage + recordsPerPage
          )}
          columnsDef={columns}
          setTableInstance={setTableInstance}
          sorting={sort}
          setSorting={setSort}
        />
        <PaginationComponent
          recordsPerPage={recordsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalCount={totalCount}
          member={true}
        />
      </div>
    </>
  );
};

export default TableWithPagination;
