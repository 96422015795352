import { createSlice } from "@reduxjs/toolkit"; 
import { Empty } from "../emptyStates/Any";
 
export const citySlice = createSlice({
  name: "city",
  initialState: Empty,
  reducers: {
    ModifyCity: (state, action) =>  ({...state, ...action.payload}),
    NewCity: (_, action) => action.payload, 
    ResetCity: () => Empty, 
  },
});

export const { NewCity, ModifyCity, ResetCity} = citySlice.actions;
