import React from "react";

const Instagram: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
      <path d="M10.004 5.508a4.481 4.481 0 00-4.488 4.488 4.481 4.481 0 004.488 4.488 4.481 4.481 0 004.488-4.488 4.481 4.481 0 00-4.488-4.488zm0 7.406a2.923 2.923 0 01-2.918-2.918 2.92 2.92 0 012.918-2.918 2.92 2.92 0 012.918 2.918 2.923 2.923 0 01-2.918 2.918zm5.719-7.59c0 .582-.47 1.047-1.047 1.047a1.047 1.047 0 111.047-1.047zm2.972 1.063c-.066-1.403-.386-2.645-1.414-3.668-1.023-1.024-2.265-1.344-3.668-1.414-1.445-.082-5.777-.082-7.222 0-1.399.066-2.641.386-3.668 1.41C1.695 3.738 1.379 4.98 1.309 6.383c-.082 1.445-.082 5.777 0 7.222.066 1.403.386 2.645 1.414 3.668 1.027 1.024 2.265 1.344 3.668 1.415 1.445.082 5.777.082 7.222 0 1.403-.067 2.645-.387 3.668-1.415 1.024-1.023 1.344-2.265 1.414-3.668.082-1.445.082-5.773 0-7.218zm-1.867 8.77a2.954 2.954 0 01-1.664 1.663c-1.152.457-3.887.352-5.16.352-1.274 0-4.012.101-5.16-.352a2.954 2.954 0 01-1.664-1.664c-.457-1.152-.352-3.886-.352-5.16 0-1.273-.101-4.012.352-5.16a2.954 2.954 0 011.664-1.664c1.152-.457 3.886-.352 5.16-.352 1.273 0 4.012-.101 5.16.352a2.954 2.954 0 011.664 1.664c.457 1.152.352 3.887.352 5.16 0 1.274.105 4.012-.352 5.16z" ></path>
    </svg>
  );
}

export default Instagram;
