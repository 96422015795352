import React, { useContext, useEffect, useState } from 'react';
import css from './ProfileInfo.module.css';
import logo from '../../../../assets/images&icons/logo-footer.svg';
import Mail from '../../../Atomos/Icons/Mail';
import User from '../../../Atomos/Icons/User';
import Drafts from '../../../Atomos/Icons/Drafts';
import Lock from '../../../Atomos/Icons/Lock';
import Notifications from '../../../Atomos/Icons/Notifications';
import QuestionCircle from '../../../Atomos/Icons/QuestionCircle';
import Card from '../../../Layout/Card/Card';
import Tune from '../../../Atomos/Icons/Tune';
import BlueCalendar from '../../../Atomos/Icons/BlueCalendar';
import Network from '../../../Atomos/Icons/Network';
import Referrals from '../../../Atomos/Icons/Referrals';
import Axios from '../../../../utils/axios';
import AuthContext from '../../../../store/AuthContext';
import AccountInfo from '../../../AccountInfo/AccountInfo';
import AccountInfoProps from '../../../../interfaces/Components/IAccountInfo';
import { useLocation } from 'react-router-dom';
import { DataItem } from '../../../../interfaces/models/IPerson';
import { useStaffMode } from '../../../../hooks/StaffModeProvider';
import SidebarItem from '../../../Atomos/SidebarItem/SidebarItem';
import { UseFirmList } from '../../../../hooks/Catalogs/useFirm';

const ProfileInfo = () => {
  const location = useLocation();
  const { loggedUserId, tokenDecoded } = useContext(AuthContext)
  const permissions = tokenDecoded?.extension_WSG_Permissions
  const [person, setPerson] = useState<any>()
    const [IsStaffNavigate, setIsStaffNavigate] = useState(location.pathname.includes('Staff') || location.pathname.includes('staff') && (permissions === "WSG Staff" || permissions === "WSG Staff Super"));

    
  const [ imageUrl, setImageUrl ] = useState('')
  const [ userId, setUserId ] = useState('')
  const [ user, setUser ] = useState<DataItem>()
  const axios = new Axios()
  const { isStaffMode } = useStaffMode()
  const [personMainOffice, setPersonMainOffice] = useState<any>()
  const { firmData } = UseFirmList()
  const [personFirm, setPersonFirm] = useState<any>()

  const fetchPerson = async () => {
    const res: any = await axios.Get('/person')
    const userWithMatchingId = res.data.find((person: any) => person.userId === tokenDecoded?.oid);
    setUser(userWithMatchingId)
    if (userWithMatchingId) {
      setImageUrl(userWithMatchingId.photo)
      setUserId(userWithMatchingId.id)

    } else {
      const res: any = await axios.Get('/user', tokenDecoded?.oid)
      setUserId(res.data.Id)
    }
  }
  useEffect(() => {
    fetchPerson()
  }, [])

  useEffect(() => {
    if (user) {
      getPersonByState(user.id)
      getPersonMainOffice(user)
    }
  }, [user])

  const getPersonFirm = async (firmId: string) => {
    if (firmId && firmData.length > 0) {
      const firm = firmData.find((firm: any) => firm.id === firmId);
      if (firm) {
        setPersonFirm(firm)
      } else {
        console.error("Firm not found with the provided ID");
      }
    } else {
      console.error("Firm ID or firmData is invalid");
    }
  }

  const getPersonByState = async (personId: string) => {
    try {
      const response = await axios.Get('/person', personId)
      if (response && response.data) {
        setPerson(response.data[0])
        await getPersonFirm(response.data[0].firm)
      }
    } catch (error) {

    }
  }
  const getPersonMainOffice = async (person: any) => {
    if (person) {
      try {
        const allOffices = await axios.Get('/office')
        if (allOffices && allOffices.data) {
          setPersonMainOffice(allOffices.data.find((office: any) => office.id === person.mainOffice))
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const userName = `${tokenDecoded?.name} ${tokenDecoded?.family_name || ''}`
  const personNane = userName !== '' ? userName : ((person?.displayName && person?.displayName !== '') ? person.displayName : (person?.name + person?.lastName))

  const accountInfoData: AccountInfoProps[] = [
    {
      imageSrc: user?.photo ?? '',
      title: `${personNane}`,
      subtitle1: `${user?.title}`,
      subtitle2: `${personMainOffice ? personMainOffice.name : ''}`,

    },
    {
      imageSrc: personFirm?.logo,
      title: `${personFirm?.name}`,
      subtitle1: personFirm?.country ? 'Member Location(s):' : '',
      subtitle2: `${personFirm?.state ?? ''}, ${personFirm?.country ?? ''}`,
      titleColor: '#153D6A',
      organization: true,
    },
  ];

  const userUrl = `${user?.name}-${user?.middleName ? `${user.middleName.charAt(0)}-` : ''}${user?.lastName}`
  const urlWithGeneratedId = `${isStaffMode ? `/staff/profile/edit/attorney/${userUrl.toLocaleLowerCase()}/${user?.generatedId}` : `/profile/edit/attorney/${userUrl.toLocaleLowerCase()}/${user?.generatedId}`}`

  return (
    <div className={`
       lg:w-profileContainer lg:px-12 lg:py-9
        md:mb-0 md:w-11/12 md:px-6 md:py-6 md:flex md:gap-[1.5rem]
        bg-white relative z-10 w-11/12 px-3 py-5 mx-auto pt-5
      `}>
      <div className={`
         lg:mr-0 lg:w-10/12 lg:gap-[2.75rem]
          md:flex md:flex-col md:justify-between md:w-9/12 
        `}>
        <div className={`md:flex md:flex-row gap-[.75rem] flex flex-col md:gap-x-[0rem] md:mb-[1.5rem]  lg:gap-[4rem] xl:gap-[8rem]  ${css.handleGaps}`}>
          {accountInfoData.map((info, index) => (
            <AccountInfo isMywsg={true} key={index} {...info} />
          ))}
        </div>
        <hr className="
        md:hidden
        w-full border-[#CACACA] my-3 
        "/>
        <div className='
          lg:gap-5 lg:w-full
          flex gap-2 w-full 
          '>
          <Card image={<div className='w-12 h-12 fill-Default'><Referrals /></div>} title="My Referrals" cssClass={css.outlineCard} />
          <Card image={<div className='w-12 h-12 fill-Default'><Network /></div>} title="My Network" cssClass={css.outlineCard} />
          <Card image={<div className='w-12 h-12 fill-Default'><BlueCalendar /></div>} title="My Events" cssClass={css.outlineCard} />
          <Card image={<div className='w-12 h-12 fill-Default'><Tune /></div>} title="My Preferences" cssClass={css.outlineCard} />
        </div>
      </div>
      <div className="
       lg:w-3/12 
        md:mt-0 md:min-w-[23%]  
        w-auto h-auto flex mt-3
        ">
        <ul className={`
            md:flex md:w-full md:pl-0 md:flex-col md:grid-cols-none md:gap-0
            grid grid-cols-2 gap-2 w-full mx-auto justify-around whitespace-nowrap md:justify-between
          `}>
          <SidebarItem isFirst={true} isLast={false} image={<div className='fill-neutral'><Mail /></div>} text="My WSG Inbox" className={css.bgblue} />
          <SidebarItem url={`${urlWithGeneratedId}`} isFirst={false} isLast={false} image={<User />} text="Edit My Profile" className={css.hoverBlue} />
          <SidebarItem isFirst={false} isLast={false} image={<Drafts />} text="Email Subscription" className={css.hoverBlue} />
          <SidebarItem url={`${IsStaffNavigate ? `/staff/mywsg/ID_password/${userId}` : `/mywsg/ID_password/${userId}`}`} isFirst={false} isLast={false} image={<Lock />} text="ID & Password" className={css.hoverBlue} />
          <SidebarItem isFirst={false} isLast={true} image={<Notifications />} text="Notifications" className={css.hoverBlue} />
        </ul>
        <div className={`
           lg:top-6 
            md:flex lg:p-0.5 
            top-3 cursor-pointer hidden bg-gray-200 rounded-full w-fit h-fit absolute right-2
            lg:right-3 xl:right-4
          `}>
          <QuestionCircle />
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
