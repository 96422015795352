import { axios } from "..";

export const getAllAddresses = async () => {
  try {
    const response = await axios.Get('/Address')
    if(response && response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}