import React, { useEffect, useState } from 'react';
import Axios from '../../utils/axios';
import UserFilter from '../../components/Catalogs/Filters/UserFilter';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import { useTableSettings } from '../../hooks/Table/UseTableSettings';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import TableWithPagination from '../../components/Catalogs/Tables/TableWithPagination/TableWithPagination';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { UseFirmList } from '../../hooks/Catalogs/useFirm';
import { ISorting } from '../../interfaces/Components/ITable';


const User: React.FC = ({ ...props }) => {

  const axios = new Axios();
  const [personsData, setPersonsData] = useState<any[]>([]);
  const [accessData, setAccessData] = useState<any[]>([]);
  const DefaultPageSize = 20;
  const [tableInstance, setTableInstance] = useState<any>();
  const [userData, setUserData] = useState<any>([]);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [filter, setFilter] = useState<any>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {firmData} = UseFirmList();


  useEffect(() => {
    fetchPersons();
    fetchUsers()
    setAccessData([{ id: "member", name: "Member" }, { id: "dataAdmin", name: "Data Admin + Member" }, { id: "wsgStaff", name: "WSG Staff" }, { id: "wsgSuper", name: "WSG Staff Super" }]);
    
  }, []);
  
  const fetchPersons = async () => {
    try {
      const response = await axios.Get('./person');
      const data = response.data;
      setPersonsData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.Get('./user');
      const data = response.data;
      if (data != null) {
        setUsersData(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const Headers: string[] = ['fullName', 'generatedId', 'firm', 'email', 'access', '_ts'];
  const { sort, setSort, currentPage, setCurrentPage, recordsPerPage, setRecordsPerPage } = useTableSettings({ defaultPageSize: DefaultPageSize });
  const [pageHeaders, setPageHeaders] = useState<string[]>([]);
  const [selectedFirm, setSelectedFirm] = useState([] as Option[])
  const [selectedAccess, setSelectedAccess] = useState([] as Option[])
  const { generateColumns } = useColumnConfig(Headers);

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + (currentPage - 1) * recordsPerPage}</p>, enableSorting: false, size: 50 },
    { id: 'fullName', header: 'Name', cell: (props: any) => props.getValue() },
    { id: 'generatedId', header: 'Generated ID', cell: (props: any) => props.getValue() },
    { id: 'firm', header: 'Firm', cell: (props: any) => props.getValue() },
    { id: 'email', header: 'Email', cell: (props: any) => props.getValue() },
    { id: 'access', header: 'Access', cell: (props: any) => props.getValue() },

    {
      id: 'edit', header: 'Edit', cell: (props: any) =>
      (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={{ 
            user: props.row.original, 
            person: generatePersonForUser(props.row.original), 
            firm: generateFirmForPerson(props.row.original), 
            userType: generatePersonForUser(props.row.original)?.subject?.memberType 
          }}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const generateFirmForPerson = (user:any) => {
    return firmData.find((fi:any) => fi.id === user?.subject?.firm)
  }

  const generatePersonForUser = (user: any) => {
    return personsData.find((p: any) => p.id === user.id) 
  }

  const formatData = (data:any) => {
    const formattedData = data.map((subject: any) => {
      const firm = firmData?.find((fi: any) => fi.id === subject?.firm);
      return {
        id: subject.id,
        fullName: subject ? (
          [
            subject.honorary,
            subject.name,
            subject.middleInitial,
            subject.lastName,
            subject.suffix
          ]
            .filter(part => part)
            .join(" ")
        ) : '',
        generatedId: subject ? subject.generatedId : '',
        firm: firm ? firm.name : '',
        email: subject ? subject.firmEmail : '',
        userName: subject?.mailNickname,
        person: subject ? subject.id : "",
        access: subject?.access,
        userId: subject ? subject.userId : "",
        subject: subject,
        _ts: subject?.createdDateTime
      };
    });
    return formattedData;
  };
  

  useEffect(() => {
    if (pageHeaders.length === 0) {
      setPageHeaders(Headers);
    }
  }, []);

  const clearSelections = () => {
    setSelectedAccess([]);
    setSelectedFirm([]);
    setFilter([]);
    fetchData(true);
  }

  const fetchData = async(resetFilter = false) => {
    let userPersonData = usersData
    .filter(u => personsData.map((d: any) => d.userId).includes(u.id))
    .map((user) => {return {
      ...user, 
      ...personsData.find((d: any) => d.userId === user.id)
    }});
    setTotalCount(userPersonData.length);
    userPersonData = formatData(userPersonData);

    if (resetFilter) {
      if (filter.name) userPersonData = userPersonData.filter((user: any) => user.name.toLowerCase().includes(filter.name.toLowerCase()));
      if (filter.firm?.length) userPersonData = userPersonData.filter((user: any) => filter.firm.includes(user.firm));
      if (filter.access?.length) userPersonData = userPersonData.filter((user: any) => filter.access.includes(user.access));
    }

    if (sort) userPersonData = sortData(userPersonData, sort);
    setUserData(userPersonData);
  };

  const sortData = (data: any[], sort: ISorting): any[] => {
    if (sort.order === 'default') {
      return data; 
    }
  
    return data.sort((a, b) => {
      const fieldA = a[sort.field];
      const fieldB = b[sort.field];
  
      if (fieldA === fieldB) return 0;
  
      if (sort.order === 'asc') {
        return fieldA > fieldB ? 1 : -1;
      } else {
        return fieldA < fieldB ? 1 : -1;
      }
    });
  };
  
  useEffect(() => {
    fetchData()
  }, [sort]);

  useEffect(() => {
    if (!personsData.length || !usersData.length ||!firmData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[personsData, usersData, firmData]);

  useEffect(() => {
    setFilter({
      name: nameFilter, 
      firm: selectedFirm?.map(c => c.id),
      access: selectedAccess?.map(c => c.id)
    })
  },[
    nameFilter, 
    selectedFirm, 
    selectedAccess, 
  ]);

  return (
    <>
      <div className=' mainSection'>
        <CatalogTableC
          title={`User Catalog`}
          catalogName={'USER'}
          route={'User'}
          data={userData}
          headers={Headers}
          totalCount={totalCount}
          isfiltered={true}
          exportData={userData}
        >
          <UserFilter
            nameFilter={nameFilter}
            onNameFilterChange={setNameFilter}
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            accessData={accessData}
            selectedAccess={selectedAccess}
            clearSelections={clearSelections}
            Search={fetchData}
            headers={Headers}
            setSelectedAccess={setSelectedAccess}
          />
          <TableWithPagination
            data={userData}
            columns={columns}
            setTableInstance={setTableInstance}
            totalCount={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordsPerPage={recordsPerPage}
            setRecordsPerPage={setRecordsPerPage}
            sort={sort}
            setSort={setSort}
          />
        </CatalogTableC>
      </div>
    </>
  );

};

export default User;