import { axios } from "..";
import { Option } from "../components/Atomos/DropDown/DDMultiple";
import { ICity } from "../interfaces";

export const countCities = async () => {
  try {
    const response = await axios.Get("/City?count=true");
    const data = response.data;
    if (data != null) {
      return data[0];
    }
  } catch (error) {
    console.error("Error fetching total count:", error);
    return 0;
  }
};

export const getFilteredCities = async (
  filter: any,
  pagination: any,
  sort: any
) => {
  try {
    const queryString =
      "/City?" +
      "filter=" +
      JSON.stringify(filter) +
      "&pagination=" +
      JSON.stringify({ ...pagination, pageNumber: pagination.pageNumber - 1 }) +
      "&sorting=" +
      JSON.stringify(sort) +
      "&count=false";
    const response = await axios.Get(queryString);
    const dataToAdd = response.data;
    if (dataToAdd != null) {
      return dataToAdd;
    }
  } catch (error) {
    console.error("Error al obtener la información de la columna:", error);
  }
};

export const getAllCities = async () => {
  try {
    const response = await axios.Get("/City");
    const data = response.data;
    if (data != null) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getCityById = async (id: string) => {
  try {
    const filter = { id: id };
    const response = await axios.Get(
      `/City?filter=${JSON.stringify(filter)}&count=false`
    );
    const data = response.data;
    if (data != null) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const addCity = async (item: ICity) => {
  try {
    const res = await axios.Post("City", item);
    if (res.status === 200) {
      console.log("City added successfully");
      return { success: true, data: res.data };
    } else {
      console.error(res.data);
      return { success: false, data: res.data };
    }
  } catch (error: any) {
    console.error("Error adding city:", error);
    return { success: false, error: error.message };
  }
};


export const updateCity = async (item: ICity) => {
  try {
    const res = await axios.Put("City", item);
    if (res.status === 200) {
      console.log("City updated successfully");
      return { success: true, data: res.data };
    } else {
      console.error(res.data);
      return { success: false, data: res.data };
    }
  } catch (error: any) {
    console.error("Error updating city:", error);
    return { success: false, error: error.message };
  }
};

export const deleteCity = async (id: string) => {
  console.log("Deleting City with id: ", id);
  const response = await axios.Delete("/City", id);
  if (response.status === 200) {
    return true;
  } else {
    console.error("Error al eliminar el elemento:", response.data);
    return false;
  }
};

export const getCitiesListOptions = async (filter: any) => {
  const isObject = (val: any) =>
    val && typeof val === "object" && !Array.isArray(val);

  const filterData = filter
    ? isObject(filter)
      ? filter
      : JSON.parse(filter)
    : {};

  const CityOptionsFields = ["id", "name"];
  const response = await axios.Get(
    `/City?fields=${JSON.stringify(CityOptionsFields)}&filter=${JSON.stringify(filterData)}&count=false`
  );
  const cities = response.data;
  console.log("citieslist",cities);
  return cities?.map((city: Option) => ({
    id: city.id,
    name: city.name,
  }));
};
