import  React from 'react';
import css from './Navigation.module.css';
import {NavigationItemProps, NavigationProps} from './INavigation'; 

export function UseMainNavigation(props: NavigationProps) {
    const navigationClassName = props.isAuthenticated
    ? !props.IsStaffNavigate
      ? `${css.navigationColor} bg-primary `
      : `${css.navigationColor} bg-secondary`
    : 'bg-white z-40'; //CHANGE TO WHITE

    const closeNavButton:string = props.isAuthenticated ? '' : 'bg-black';

    const [closedNav, setClosedNav] = React.useState(props.isOpen);
    const body = document.querySelector('body') as HTMLElement;

    const handleClose = () => {
      console.log('close:' + props.NavLvl)
      body.classList.remove('fixed')
      setClosedNav(!closedNav);
      props.onClose() 
         
    };

      const closeAll = () => { 
        body.classList.remove('fixed')
        props.closeAll()
      }
    const IsStaffNavigate = props.IsStaffNavigate
    return {closedNav,closeNavButton,navigationClassName ,IsStaffNavigate,handleClose,closeAll};
}

export function useSubNavigation(){
  //abrir sub menu

};
