import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./store/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContextProvider } from "./Context";

import Axios from "./utils/axios";
import { PaginationProvider } from "./Context/PaginationContext";

export const axios = new Axios();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <AppContextProvider>
          <PaginationProvider>
            <App />
          </PaginationProvider>
        </AppContextProvider>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
