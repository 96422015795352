import React from "react";

function HandShake() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
       
        <path  
          d="M23.728 46.933L3.746 26.95l15.602-15.602L27.1 19.1l2.072-2.072L16.917 4.746 1.824 19.839l3.509 3.509-2.58 2.552-6.084-6.11L16.867-.41l6.624 6.624 6.54-6.539L50.492 20.14 23.728 46.933zm.022-5.133l21.56-21.66L30.03 4.83l-3.937 3.966 8.233 8.232-7.226 7.227-7.752-7.753L8.9 26.95l2.1 2.1 7.867-7.867 2.15 2.15L13.15 31.2l2.15 2.15 7.867-7.867 2.1 2.1L17.4 35.45l2.05 2.05 7.867-7.867 2.15 2.15L21.6 39.65l2.15 2.15z"
        ></path>
       
    </svg>
  );
}

export default HandShake;