import React, { useRef } from 'react';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import Input from '../../Atomos/Inputs/Input';
import { InputTextRef } from '../../Atomos/Inputs/InputText';
import CatalogFilterButtons from '../CatalogFilterButtons';


interface ICountryFilterProps {
  regionData: any[]
  selectedRegion: Option[];
  setSelectedRegion: React.Dispatch<React.SetStateAction<Option[]>>;
  search: () => void;
  headers: string[];
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  abbreviationFilter: string;
  onAbbreviationFilterChange: (newAbbreviationFilter: string) => void;
  resetFilters: () => void;
  tableInstance: any;

}

const CountryFilter: React.FC<ICountryFilterProps> = ({ tableInstance, regionData, selectedRegion, setSelectedRegion, search, headers, nameFilter, onNameFilterChange, resetFilters, abbreviationFilter, onAbbreviationFilterChange }) => {
  return (

    <>
      <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:flex-1 lg:flex lg:flex-row
      border border-gray-100'>
        <div className='w-full'>
          <Input
            type="text"
            value={nameFilter}
            onChange={(e) => onNameFilterChange(e.target.value)}
            error={false}
            title='By Name'
            isWfull
          />
        </div>
        <div className='w-full'>
          <Input
            type="text"
            value={abbreviationFilter}
            onChange={(e) => onAbbreviationFilterChange(e.target.value)}
            error={false}
            title='By Abbreviation'
            isWfull
          />
        </div>
        <div className='w-full'>
          <DDMultiple
            title="By Region:"
            objects={regionData}
            selectedObjst={selectedRegion}
            seter={setSelectedRegion}
          />
        </div>
        <div className='flex flex-row gap-2 w-full md:justify-end lg:w-max md:col-span-2 md:max-w-[17rem] md:ml-auto'>
          <CatalogFilterButtons tableInstance={tableInstance} onClickSearch={search} handleFormReset={resetFilters} headers={headers} />
        </div>
      </div>
    </>

  );
};

export default CountryFilter;
