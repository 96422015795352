import React, { useEffect, useState } from 'react'
import Axios from '../../../utils/axios';
const axios = new Axios()

interface ArticlesCardProps {
  articles: any[];
}

const ArticlesCard: React.FC<ArticlesCardProps> = ({ articles }) => {
  const [authorNames, setAuthorNames] = useState<{ [key: string]: string }>({});

  const getAuthors = async (ids: string[]) => {
    for (const id of ids) {
      if (authorNames[id]) return
      try {
        const author = await axios.Get(`/person`, id);
        if (author && author.data) {
          setAuthorNames(prevState => ({ ...prevState, [id]: author.data.name }));
        }
      } catch (error) {
        console.error('Error fetching author:', error);
      }
    }
  };

  useEffect(() => {
    articles?.forEach(article => {
      getAuthors(article.authors);
    });
  }, [articles]);



  return (
    <div className="flex flex-col lg:justify-end items-end lg:gap-[2.125rem] self-stretch">
      <div className="flex flex-col items-end gap-7 lg:items-start lg:gap-[1.5rem] self-stretch">
        {articles && articles.length > 0 &&
          articles.map((article: any) => (
            <React.Fragment>
              <div className="flex items-start gap-2 self-stretch">
                <div className="flex flex-col items-start gap-5 flex-1">
                  <div className="flex flex-col items-start self-stretch">
                    <span className="self-stretch text-[1.125rem] leading-normal text-Default lg:text-xl font-bold lg:leading-7">
                      {article?.title}
                    </span>
                    <div className='flex items-center gap-[.625rem]'>
                      <span className="text-gray-font lg:text-base lg:leading-[1.125rem]">
                        {article.authors.map((authorId: any) => authorNames[authorId] || 'Loading...').join(', ')}
                      </span>
                      <hr className='w-0 h-[1rem] border border-[#474F56] flex flex-col' />
                      <span className="text-gray-font lg:text-base lg:leading-[1.125rem]">
                        {article?.publishedDate}
                      </span>
                    </div>
                  </div>
                  <span className="self-stretch text-gray-font lg:text-lg lg:leading-6">
                    {typeof(article?.articleContent) === 'string' ? article.articleContent : article?.articleContent?.text}
                  </span>
                </div>
              </div>
              <hr className="flex h-0 lg:justify-center lg:items-center self-stretch" />
            </React.Fragment>
          ))
        }
      </div>
    </div>
  )
}

export default ArticlesCard;