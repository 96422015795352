import { useAppContext } from "../../../Context";
import WSGCheck from "../../Atomos/Icons/WSGCheck";
import React from 'react';

interface CheckboxProps {
    index: number;
    text: string;
    checked: boolean;
    handleCheckboxChange: (index: number) => void; 
    inputRef?: React.Ref<HTMLInputElement>;
    disable?:boolean;
    error?:boolean;
    errormessage?:string;
    removeClassName?:boolean;
    customClassName?:string;
    className?:string;
    icon?: JSX.Element;
}

const WSGCheckbox: React.FC<CheckboxProps> = ({ inputRef, text, checked, index, handleCheckboxChange ,disable,error, removeClassName, customClassName,className, icon}) => {

    const {mode} = useAppContext();

    return (
        <label className={`${removeClassName ? customClassName : 'flex items-center gap-[0.625rem] py-[0.625rem] lg:py-[0.81rem] align-middle text-lg leading-5 text-gray-font bg-white hover:bg-gray-50 '}${className}`}>
          <input
              ref={inputRef}
              type="checkbox"
              className="hidden" onChange={(e) => handleCheckboxChange(index)} checked={checked} /> 
          <div className="relative w-6 h-6 ">
            <div className={`absolute stroke-2 min-w-[24px] min-h-[24px] inset-0 ${error ? ' stroke-feedback-error' : 'stroke-gray-font'}  ${ !checked?" fill-none" : disable ? "fill-gray-200 " : mode === 'member'? " fill-accentDefault":" fill-Default"}` }> 
                    {<WSGCheck check={checked} />}
                </div>
            </div>
            {!icon && <div className="flex flex-col flex-1 "> 
                <span className="   text-base leading-6 lg:text-lg lg:leading-8 text-gray-font first-letter:uppercase">{text}</span> 
            </div> }
            {icon && <div className="flex flex-row flex-1 gap-1 items-center"> 
                <span className="h-6 w-6">{icon}</span><span className="text-base leading-6 lg:text-lg lg:leading-8 text-gray-font first-letter:uppercase">{text}</span> 
            </div> }
        </label>
    );
};

export default WSGCheckbox;
