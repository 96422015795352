import React, { useEffect, useState } from 'react';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import BaseFilter from '../../components/Catalogs/Filters/BaseFilter/BaseFilter';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { Link } from 'react-router-dom';
import useDataLoader2 from '../../hooks/useDataLoader2';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { transformData } from '../../hooks/Table/TransformColumnsForExport';
import { formatTimeSpanToString } from '../../utils/functions';

const BarAdmission: React.FC = () => {
  const Headers: string[] = ['name', '_ts'];
  const DefaultPageSize = 50;
  const { generateColumns } = useColumnConfig(Headers);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [tableInstance, setTableInstance] = useState<any>();
  
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/barAdmission',
    defaultPageSize: DefaultPageSize,
    fields
  });
  
  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    {
      id: '_ts', header: "Created Date", cell: (props: any) => {
        const value = props.getValue();
        return value ? formatTimeSpanToString(value) : "";
      }
    },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    },
  ];


  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case 'name':
          return <p>{props.row.original.name}</p>;
        case '_ts':
          const value = props.getValue();
          return (
            <p>
              {value
                ? new Date(Number(value) * 1000).toLocaleDateString("en-GB", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
                : ""}
            </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize);

  const clearSelections = () => {
    setNameFilter('')
    fetchData(true)
  }

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  }, []);

  useEffect(() => {
    setFilter({ name: {
      value: nameFilter,
      comparisonType: 'CONTAINS'
    }})
  }, [nameFilter]);

  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title="Bar Admission Catalog"
          catalogName="Bar Admission"
          route="BarAdmission"
          headers={Headers}
          totalCount={totalCount}
          isfiltered={true}
          data={data}
          exportData={transformedData}
        >
          <BaseFilter
            title="By Name"
            search={fetchData}
            headers={Headers}
            onNameFilterChange={setNameFilter}
            nameFilter={nameFilter}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default BarAdmission;
