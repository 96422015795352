import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Atomos/Inputs/Input";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { getStatesListOptions } from "../../../Services/State";
import { addCity, deleteCity, updateCity } from "../../../Services/City";
import { UseCountryList } from "../../../hooks/Catalogs";
import { useLogging } from "../../../Context/LoggingContext";
import { useStatesList } from "../../../hooks/Catalogs/useStates";
import { ICity } from "../../../interfaces";
import { updateData } from "../../../utils/functions";

interface City {
  id?: string;
  name?: string;
  state?: string;
}

type listobj = { id: string; name: string };

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  name: error;
  country: error;
  state: error;
};

const AddCountry: React.FC<iCatalogProps> = (props) => {
  const crudNavegate = () => {
    navigate("/Staff/Catalogs/City");
  };

  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const [city, setCity] = useState<ICity>(
    state
      ? { ...state}
      : { name: "", state: "" }
  );
  const [buttonClicked, setButtonClicked] = useState("");
  const [stateData, setStateData] = useState<any[]>([]);
  const [errors, setErrors] = useState<errors>({
    name: { error: false, msj: "" },
    country: { error: false, msj: "" },
    state: { error: false, msj: "" },
  });
  const [selectedCountry, setSelectedCountry] = useState<listobj>({
    id: state?.countryId ?? "",
    name: state?.country ?? "",
  });
  const [selectedState, setSelectedState] = useState<listobj>({
    id: state?.stateId ?? "",
    name: state?.state ?? "",
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [query, setQuery] = useState("");

  const { countryData } = UseCountryList();
  const { statesData } = useStatesList();
  const { logActivity } = useLogging();

  const filteredCountries =
    query === ""
      ? countryData
      : countryData?.filter((country) => {
        return country.name.toLowerCase().includes(query.toLowerCase());
      });

  useEffect(() => {
    if (!statesData.length) return

    setStateData(statesData.filter(s => s.country === selectedCountry.id))
    setSelectedState(statesData.find(s => s.name === state?.state))
  }, [statesData, selectedCountry]);

  useEffect(() => {
    if (state) {
      const country = countryData.find((country) => country.name === state?.country);
      if(country) {
        setSelectedCountry(country);
      }
    }
  }, [countryData]);

  useEffect(() => {
    if (errors.name.error || errors.country.error || errors.state.error)
      validateForm();
  }, [city, selectedCountry, selectedState]);

  useEffect(() => {
    if (!errors.name.error && !errors.country.error && !errors.state.error)
      setSaveOverrideOpen(false);
  }, [errorFields]);

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;
    newerrors.name =
      city?.name === ""
        ? { error: true, msj: "Name is required" }
        : { error: false, msj: "" };
    newerrors.country =
      selectedCountry.name === ""
        ? { error: true, msj: "Country is required" }
        : { error: false, msj: "" };
    newerrors.state =
      selectedState?.name === ""
        ? { error: true, msj: "State is required" }
        : { error: false, msj: "" };

    valid = Object.values(errors).every((error) => !error.error);

    setErrors(errors);
    setErrorFields(
      Object.keys(errors).filter(
        (key) => errors[key as keyof typeof errors].error
      )
    );

    if (!valid) setSaveOverrideOpen(true);

    return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const performAction = async (
      action: () => Promise<any>,
      activityType: string,
      additionalData: any
    ) => {
      try {
        const response = await action();
    
        if (response.success) {
          logActivity(
            activityType,
            location?.pathname,
            JSON.stringify(additionalData)
          );
        }

        if (state.name !== cityData.name) {
          await Promise.all([
            updateData('address', 'cityName', state.name, cityData.name),
            updateData('office', 'cityName', state.name, cityData.name),
          ])
        }
    
        crudNavegate();
      } catch (error) {
        console.error("Error performing action:", error);
      }
    };

    const cityData = {
      ...city, 
      state: selectedState?.id,
      stateName: selectedState?.name,
      countryName: city.country,
      regionNames: city.regions,
    };
    const logData = {
      prevState: state ? { ...state } : {},
      city: city.name,
      state: selectedState?.name,
      country: selectedCountry.name,
    };

    switch (buttonClicked) {
      case "delete":
        performAction(() => deleteCity(String(city.id)), "", "");
        break;
      case "saveOverride":
        if (props.mode === "add") {
          performAction(() => addCity(cityData), "CREATE_CITY", logData);
        } else {
          performAction(() => updateCity(cityData), "UPDATE_CITY", logData);
        }
        break;
      case "saveButton":
      case "updateButton":
        if (validateForm()) {
          const action = buttonClicked === "saveButton" ? addCity : updateCity;
          const activityType =
            buttonClicked === "saveButton" ? "CREATE_CITY" : "UPDATE_CITY";
          performAction(() => action(cityData), activityType, logData);
        }
        break;
      default:
        // Handle unexpected buttonClicked values if needed
        break;
    }
  };

  const handleSetSelectedCountry = (e: any) => {
    setSelectedCountry(e);
    setSelectedState({ id: "", name: "" });
  };

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    setCity({ ...city, state: e.id });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setCity({ ...city, [e.target.name]: e.target.value });
  };

  return (
    <main className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}>
      <form onSubmit={handleSubmit}>
        <SaveOverride
          fields={errorFields}
          handleButtonClick={handleButtonClick}
          open={saveOverrideOpen}
          close={() => {
            setSaveOverrideOpen(!saveOverrideOpen);
          }}
        />
        <CatalogTitle
          title={`${props.mode === "add" ? "New City" : "Edit " + (state ? state?.name || "Edit none" : "Loading...")}`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>
        <article className="articleSection ">
          <section className="formsectionNTitle">
            <CatalogSubTitle
              title=" City Information"
              className="text-accent-Default-dark"
            />
            <div className="formsection flex w-full">
              <div className=" w-full lg:w-1/2">
                <Input
                  name="name"
                  value={city.name}
                  required={true}
                  title="Name"
                  error={errors.name.error}
                  errorMsj={errors.name.msj}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </section>
          <section className="formsectionNTitle">
            <CatalogSubTitle
              title=" Catalog List Relationships"
              className="text-accent-Default-dark"
            />
            <div className="formsection flex w-full flex-wrap md:flex-nowrap">
              <div className="w-full md:w-1/2">
                <DropDownList
                  title="Country"
                  getid
                  required={true}
                  selectedObjst={selectedCountry.name}
                  seter={handleSetSelectedCountry}
                  data={filteredCountries}
                  error={errors.country.error}
                  errormsj={errors.country.msj}
                />
              </div>
              <div className="w-full md:w-1/2">
                <DropDownList
                  title="State"
                  getid
                  tooltip={"Please select a Country"}
                  required={true}
                  disabled={selectedCountry.name === ""}
                  selectedObjst={selectedState?.name}
                  seter={handleSetSelectedState}
                  data={stateData}
                  error={errors.state.error}
                  errormsj={errors.state.msj}
                />
              </div>
            </div>
          </section>
          <ButtonsCrud
            Catalog="City"
            mode={props.mode}
            id={id ? id : ""}
            disabled={
              errors.name.error || errors.state.error || errors.country.error
            }
            actionButton={handleButtonClick}
            onDelete={() => {
              deleteCity(String(city.id)).then(() => {
                logActivity(
                  "DELETE_CITY",
                  location?.pathname,
                  JSON.stringify(state ? state : {})
                );
                crudNavegate();
              });
            }}
          />
        </article>
      </form>
    </main>
  );
};
export default AddCountry;
