
import { useNavigate } from 'react-router-dom';
import { TrashCan } from '../Atomos/Icons/TrashCan';
import NewButton from '../Atomos/Buttons/newButton';
import Close from '../Atomos/Icons/Close';
import { useEffect, useState } from 'react';
import ArrowDropDown from '../Atomos/Icons/ArrowDropDownButton';

interface IHeaderTitle {
    mode: string;
    id: string;
    onDelete: (id: string) => void; //handleDelete
    hiddenDelete?: boolean;
    actionButton: (buttonClicked: string) => void; //handleButtonClick\
    disabled: boolean;
    Catalog: string;
    deleteOff?: boolean;
    optionsCreate?: any[];
    optionsUpdate?: any[];
    specialPath?: string;
}

const ButtonsCrudWithDropDown: React.FC<IHeaderTitle> = (props) => {
    const navigate = useNavigate();
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.mode === "add" ? "Create" : "Save");

    useEffect(() => {
      const handleResize = () => {
        setViewportWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
      
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleOptionClick = (option: any) => {
        setSelectedOption(option)
        setShowDropdown(!showDropdown)
    };

    return (<>
        {
            props.mode === "add" &&
            <div className="flex flex-row items-start justify-center md:justify-end self-stretch shrink-0 relative">
                <div className="flex flex-col md:flex-row gap-4 items-start self-stretch justify-center md:justify-end shrink-0 flex-1 md:flex-none ">
                    <NewButton onClick={() => { props.specialPath ? navigate(props.specialPath) : navigate(-1)}}
                        text={"Cancel"}  
                        className='flex-1 md:flex-none !w-full md:!w-auto'
                        icon={<Close  />}
                        color='neutral'
                        content='textIcon'
                        size="medium"
                        style="outlined"
                    />
                    <div className='flex self-stretch !w-full md:!w-auto'>
                        <div className='flex self-stretch w-full'>
                            <button onClick={() => props.actionButton('saveButton')} className=' flex self-stretch w-full flex-1 h-full '
                            type="submit">
                            <NewButton
                                text={selectedOption} 
                                color='accent'
                                content='textIcon'
                                size="medium"
                                className='!py-2 !px-3 lg:!p-3'
                                style="filled"
                                disabled={props.disabled}
                                /> 
                            </button>
                        </div>
                        <div className='flex justify-center items-center border-l border-neutral-light'>
                            <NewButton
                                text={""} 
                                icon={<ArrowDropDown className={""}/>}
                                color='accent'
                                content='textIcon'
                                size="dropdown"
                                style="filled"
                                disabled={props.disabled}
                                onClick={handleDropdownClick}
                            /> 
                            {showDropdown && (
                                <div className="absolute w-full top-[100%] left-0 bg-white border border-gray-200 shadow-md mt-2"> {/* Adjust the mt-2 value for desired spacing */}
                                    {props.optionsCreate &&
                                        props.optionsCreate.map((option: any, index: number) => (
                                            <div
                                                key={index}
                                                className="cursor-pointer pl-4 pr-5 py-2.5 leading-5 font-normal text-lg text-gray-font hover:bg-gray-100"
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {option}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>                       
                </div>
            </div>
        }
        {props.mode === "edit" && (
        <div className="flex flex-col md:flex-row gap-4 justify-between self-stretch shrink-0 relative">
            <div className='flex flex-row gap-4 justify-between w-full md:w-full '>
                {!props.hiddenDelete && (
                    <div className='w-auto'>
                    <button onClick={() => { props.id != undefined ? props.onDelete(props.id) : console.log("id is undefined") }}>
                        <NewButton 
                        text='Delete' 
                        icon={<TrashCan />}
                        color='delete'
                        content={viewportWidth < 768 ? 'iconSquare' : 'textIcon'}
                        size="medium"
                        style="outlined"
                        />
                    </button>
                    </div>
                )}
                <div className='w-full md:w-auto md:order-3'>
                    <NewButton 
                    onClick={() => { navigate(-1) }}
                    text={"Cancel"}  
                    icon={<Close  />}
                    color='neutral'
                    content='textIcon'
                    size="medium"
                    style="outlined"
                    />
                </div>
            </div>
            <div className='flex flex-1 min-w-max self-stretch w-full md:w-auto'>
                <button onClick={() => props.actionButton('updateButton')} className='w-full flex-1 h-full'>
                    <NewButton
                    text={selectedOption} 
                    color='accent'
                    content='textIcon'
                    size="medium"
                    className='py-2 px-3 lg:p-3'
                    style="filled"
                    disabled={props.disabled}
                    /> 
                </button>
            <div className='flex justify-center items-center border-l border-neutral-light'>
                <NewButton
                text={""} 
                icon={<ArrowDropDown className={""}/>}
                color='accent'
                content='textIcon'
                size="dropdown"
                style="filled"
                disabled={props.disabled}
                onClick={handleDropdownClick}
                /> 
                {showDropdown && (
                <div className="absolute w-[20.375rem] md:w-[19.125rem] top-[100%] right-0 bg-white border border-gray-200 shadow-md mt-1">
                    {props.optionsUpdate &&
                    props.optionsUpdate.map((option: any, index: number) => (
                        <div
                        key={index}
                        className="cursor-pointer pl-4 pr-5 py-2.5 leading-5 font-normal text-lg text-gray-font hover:bg-gray-100"
                        onClick={() => handleOptionClick(option)}
                        >
                        {option}
                        </div>
                    ))}
                </div>
                )}
            </div>
            </div>
        </div>
        )}

    </>
    )
}

export default ButtonsCrudWithDropDown