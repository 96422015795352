
import '../../assets/css/Text.css'
import NewButton from '../../components/Atomos/Buttons/newButton';
import Close from '../../components/Atomos/Icons/Close';
import Danger from '../../components/Atomos/Icons/Danger';

interface OverrideVCFProps {
  fields: string[]
  handleButtonClick: (buttonClicked: string) => void;
  open?: boolean;
  title?: string;
  close?: () => void;
  subtitle?: string;
}

const OverrideVCF: React.FC<OverrideVCFProps> = (props) => {

  if (!props.open) {
    return null;
  }

  return (
    <div className={`relative flex mt-7 px-4 py-5 md:px-7 lg:px-10 lg:py-16 flex-col items-start self-stretch rounded-none border-[2px] border-feedback-error  bg-neutral `}>
      <div className='  flex justify-between flex-1 items-center w-full'>
        <div className=" absolute right-[0.3125rem] top-[0.3125rem] md:right-5 md:top-5 ">
          <NewButton icon={<Close />} content='iconSquare' style='ghost' color="error" size='medium' onClick={props.close} />
        </div>
      </div>

      <div className='flex justify-between gap-6 items-center self-stretch flex-col md:flex-row'>
        <div className=' flex  gap-6 w-full flex-col md:flex-row items-left text-balance md:w-[66%]'>
          <div className='flex gap-1 flex-col text-justify self-stretch'>
            {
              props.fields.map((field, index) => {
                return <span
                  key={index}
                  className="text-feedback-error text-base not-italic font-semibold leading-[1.125rem]"
                >
                  {field}
                  {index < props.fields.length - 1 && ', '}
                </span>
              })
            }
            <span className='text-gray-font text-base leading-[1.125rem] font-normal not-italic min-w-[150px]'> is/are missing from the profile, the VCF can't be generated</span>

          </div>
        </div>
        <button onClick={() => { props.handleButtonClick('saveOverride') }} type='submit' className=' md:w-auto w-full '>
          <NewButton
            text={props.title ? props.title : "SAVE OVERRIDE"}
            className=' !p-1 md:!text-base md:!leading-[1.125rem] md:!pl-3 md:!pr-2 md:!py-2 !lg:py-[0.815rem] !lg:pr-4 !lg:pl-5 !gap-1 '
            icon={<Danger />}
            content='textIcon'
            style='filled'
            color="delete"
            size='large'
          />
        </button>
      </div>
    </div>
  )
}

export default OverrideVCF