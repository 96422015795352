// src/components/AnimationLogo.tsx
import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { Transition } from "@headlessui/react";
import { useTimeoutFn } from "react-use";
import { useSelector } from 'react-redux';
import { Appstore } from "../../../redux";

const AnimationLogo: React.FC = () => {
  const [isShowing, setIsShowing] = useState(false);
  const isLoading = useSelector((state: Appstore) => state.loader.isLoading);
  let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 1000);

  useEffect(() => {
    setIsShowing(true);
    const intervalId = setInterval(() => {
      setIsShowing((prevVisible) => !prevVisible);
    }, 850);

    return () => clearInterval(intervalId);
  }, []);

  return (
    isLoading ? (
      <div className="w-full h-screen fixed flex items-center justify-center z-20">
        <div className="w-full h-screen top-0 flex items-center justify-center fixed scale left-0 bg-[#2c2c2c] opacity-40 -z-10" />
        <Transition
          show={isShowing}
          enter="transform transition duration-[1600ms]"
          enterFrom="opacity-100 rotate-[-360deg] scale-50"
          enterTo="opacity-100 rotate-0 scale-80"
          leave="transform transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100"
          leaveTo="opacity-100 scale-50 duration-[800ms]"
        >
          <div className="w-full h-full flex items-center justify-center">
            <Spinner />
          </div>
        </Transition>
      </div>
    ) : null
  );
}

export default AnimationLogo;
