// hooks/useGenerateColumns.ts
import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import SvgIcon from '../components/ViewMode/IconComponent';
import ShapeEdit from '../components/Atomos/Icons/ShapeEdit';
import { IPracticeIndustry } from '../interfaces/models/IPracticeIndustry';
import Axios from '../utils/axios';
import { IPerson } from '../interfaces/models/IPerson';
import { getFirms } from '../Services/Firm';
import { UsePracticeIndustryList } from './Catalogs/usePracticeIndustry';
import { UseFirmList } from './Catalogs/useFirm';

const useGenerateColumns = (Headers: string[], practiceIndustries?: IPracticeIndustry[], selectedTab?: number) => {
  const columnHelper = createColumnHelper<any>();
  const [persons, setPersons] = useState<IPerson[]>([]);
  const axios = new Axios()
  const [firms, setFirms] = useState<{ [key: string]: string }>({});
  const practiceIndustryData = UsePracticeIndustryList();
  const {firmData} = UseFirmList();

  const findProfessionalsForAop = (aop: string) => {
    if (persons) {
      const filteredPersons = persons.filter((person) =>
        person.areasOfPractice?.some((area) => area.id === aop)
      );
      return filteredPersons.length.toString();
    }
    return '0'
  }

  const findPracticeIndustryNames = (areaOfPracticeId: string) => {
    const industryNames = [];
    if (practiceIndustries) {
      for (const industry of practiceIndustries) {
        if (industry && Array.isArray(industry.areasOfPractice)) {
          const areasOfPractice = industry.areasOfPractice;
          if (areasOfPractice.some(aop => aop.id === areaOfPracticeId)) {
            industryNames.push(industry.name);
          }
        }
      }
    }
    return industryNames.length > 0 ? industryNames.join(', ') : 'Not Mapped';
  };


  const renderBoolean = (value: boolean) => value ? 'Yes' : 'No';


  const formatISODate = (dateString: any): string => {
    const date = new Date(dateString.getValue());
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  };

  const formatDate = (value: any) => {
    const formattedDate = new Date(Number(value.getValue()) * 1000).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  }

  const getPersons = async () => {
    try {
      const response = await axios.Get('/person')
      if (response && response.data) {
        setPersons(response.data)
      }
    } catch (error: any) {
      throw error
    }
  }

  useEffect(() => {
    getPersons()
  }, [])

  useEffect(() => {
    if (!firmData.length) return
    loadFirms()
  }, [firmData])

  const personValue = (personData: any) => {
    return `${personData.name} ${personData.lastName}`
  }

  const loadFirms = async () => {
    try {
        const firmsMap = firmData.reduce((acc: { [key: string]: string }, firm: any) => {
          acc[firm.id] = firm.name;
          return acc;
        }, {});
        setFirms(firmsMap);
    } catch (error) {
      console.error('Failed to load firms', error);
    }
  };



  const formatRankValue = (rankData: any) => {
    return `${rankData && rankData.name || ''}`;
  };


  const firmValue = (firmId: any) => {
    return firmId?.name || '';
  };

  const customAccessors: Record<string, (info: any) => JSX.Element | string> = {
    icon: (info) => (
      <div className="flex w-8 h-8 lg:w-10 lg:h-10 items-center justify-center">
        <SvgIcon iconUrl={info.row.original?.icon} practiceId={info.row.original.id} practiceName={info.row.original.practiceName} />
      </div>
    ),
    logo: (info) => (
      <div className="flex w-8 h-8 lg:w-10 lg:h-10 items-center justify-center">
        <img src={info.row.original.logo} />
      </div>
    ),
    isMapped: (info) => renderBoolean(info.row.original.isMapped),
    isIgnored: (info) => renderBoolean(info.row.original.isIgnored),
    isTargeted: (info) => renderBoolean(info.row.original.isTargeted),
    _ts: formatDate,
    createdAt: formatISODate,
    practiceIndustries: (info) => findPracticeIndustryNames(info.row.original.id),
    firms: (info) => info.getValue()?.map((firm: any) => firm.name).join(', '),
    firm: (info) => firmValue(info.getValue()),
    office: (info) => info.getValue()?.map((firm: any) => firm.name).join(', '),
    professionals: (info) => findProfessionalsForAop(info.row.original.id),
    client: (info) => info.row.original.name,
    name: (info) => info.getValue(),
    ranking_person: (info) => info.row.original.person?.name,
    'Full Name': (info) => `${info.row.original.person?.name} ${info.row.original.person?.lastName}`,
    person: (info) => personValue(info.getValue()),
  };

  for (let i = 1; i <= 4; i++) {
    customAccessors[`rank_${i}`] = (info) => formatRankValue(info.row.original.ranks[i - 1]);
  }

  const formatHeader: any = (header: string): string => {
    if (header === 'practiceIndustries') {
      return 'Practice Industries';
    } if (header === 'professionals') {
      return '# of Professionals';
    } else if (header === '_ts' || header === 'createdAt') {
      return 'Created Date';
    } else if (header.startsWith('rank_')) {
      const rankNumber = header.split('_')[1];
      return `Rank ${rankNumber}`;
    } else {
      return header.charAt(0).toUpperCase() + header.slice(1).replace(/([A-Z])/g, ' $1').trim();
    }
  };

  const baseColumns = useMemo(() => [
    columnHelper.accessor('index', {
      id: 'index',
      header: '#',
      cell: (info) => info.row.index + 1,
    }),
    ...Headers.map(header => columnHelper.accessor(header, {
      id: header,
      header: formatHeader(header),
      cell: (info) => customAccessors[header] ? customAccessors[header](info) : info.getValue(),
    })),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (info) => (
        <Link className="edit" to={`Edit/${info.row.original.id}`} state={info.row.original}>
          Edit<div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    }),
  ], [Headers, practiceIndustries]);

  return baseColumns;
};

export default useGenerateColumns;
