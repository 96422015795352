import { createSlice } from "@reduxjs/toolkit";   
import { JurisdictionEmpty } from "../emptyStates";

export const jurisdictionSlice = createSlice({
  name: "state",
  initialState: JurisdictionEmpty,
  reducers: {
    ModifyJurisdiction: (state, action) => ({ ...state, ...action.payload }),
    NewJurisdiction: (_, action) => action.payload, 
    ResetJurisdiction: () => JurisdictionEmpty, 
  },
});

export const { NewJurisdiction, ModifyJurisdiction, ResetJurisdiction } =
jurisdictionSlice.actions;
