import React, { useEffect, useState } from 'react';
import Axios from '../../utils/axios';
import PersonFilter from '../../components/Catalogs/Filters/PersonFilter';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import useDataLoader2 from '../../hooks/useDataLoader2';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { transformData } from '../../hooks/Table/TransformColumnsForExport';
import { formatTimeSpanToString, getNameById } from '../../utils/functions';
interface IPersonFilterData {
  id_: string;
  name: string;
  firmsId: Option[];
  titleId: Option[];
  memberType: Option[];
  isStaff: Option[];
  isAttorney: Option[];
}

const Person: React.FC = ({ ...props }) => {      
  const DefaultPageSize = 50;
  const axios = new Axios();
  const [personFilter, setPersonFilter] = useState<IPersonFilterData>({
    id_: '',
    name: '',
    firmsId: [],
    titleId: [],
    memberType: [],
    isStaff: [],
    isAttorney: [],
  });
  const [tableInstance, setTableInstance] = useState<any>();
  const [firms, setAllFirms] = useState<any[]>([])
  const [offices, setAllOffices] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { data, totalCount, fetchData, setFilter, sort, setSort, pagination, setPagination } = useDataLoader2<any>({
    endpoint: '/person',
    defaultPageSize: DefaultPageSize
  });

  const Headers: string[] = [
    "generatedId",
    "memberType",
    "isAttorney",
    "firm",
    "photo",
    "name",
    "middleInitial",
    "lastName",
    "title",
    "unlistedTitle",
    "listTitle",
    "listingCategory",
    "isSearchable",
    "firmEmail",
    "mainOffice",
    "_ts"
  ];

  const getFirms = async () => {
    try {
      const res = await axios.Get('/firm?withWSG=true')
      if (res && res.data) {
        setAllFirms(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getOffices = async () => {
    try {
      const res = await axios.Get('/office')
      if (res && res.data) {
        setAllOffices(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const filterFirms = (inputFirms: string) => {
    if (firms && inputFirms) {
      const matchingFirms = firms.filter(firm => firm.id === inputFirms)
      return <React.Fragment>
        <p>
          {matchingFirms.map(f => f.name).join(', ')}
        </p>
      </React.Fragment>
    }
  }

  const filterOffices = (inputOffices: string) => {
    if (offices && inputOffices) {
      const matchingOffices = offices.filter(firm => firm.id === inputOffices)
      return <React.Fragment>
        <p>
          {matchingOffices.map(f => f.name).join(', ')}
        </p>
      </React.Fragment>
    }
  }

  useEffect(() => {
    getFirms()
    getOffices()
  }, [])

  const { generateColumns } = useColumnConfig(Headers);
  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'generatedId', header: 'Generated ID' },
    { id: 'memberType', header: 'Member Type' },
    { id: 'isAttorney', header: 'Is Attorney' },
    { id: 'firm', header: 'Firm' },
    { id: 'photo', header: 'Photo' },
    { id: 'name', header: 'Name' },
    { id: 'middleInitial', header: 'Middle Initial' },
    { id: 'lastName', header: 'Last Name' },
    { id: 'title', header: 'Title' },
    { id: 'unlistedTitle', header: 'Unlisted Title' },
    { id: 'listTitle', header: 'List Title' },
    { id: 'listingCategory', header: 'Listing Category' },
    { id: 'isSearchable', header: 'Is Searchable' },
    { id: 'firmEmail', header: 'Firm Email' },
    { id: 'mainOffice', header: 'Main Office' },
    { id: '_ts', header: "Created Date" },
    { id: 'edit', header: 'Edit', enableSorting: false },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + (pagination.pageIndex) * pagination.pageSize}</p>;
        case 'memberType':
          return <p>{props.getValue() === 0 ? 'Member' : props.getValue() === 2 ? 'Staff' : 'Non member'}</p>
        case 'firm':
          return <p>{props.row.original.firmName}</p>
        case 'photo':
          return <div className={`p-4 text-left not-italic text-gray-font font-normal !text-[0.875rem] lg:!text-base !leading-4 lg:!leading-[1.5rem] relative`}  >
            <img className="w-10 h-10 text-center rounded-full  " src={props.getValue()} alt="avatar" />
          </div>
        case 'mainOffice':
          return <p>{filterOffices(props?.getValue())}</p>
        case 'edit':
          return <Link
            className="edit"
            to={`Edit/${props.row.original.id}`}
            state={props.row.original}
          >
            Edit
            <div className="editsvg"><ShapeEdit /></div>
          </Link>
        case '_ts':
          const value = props.getValue();
          return (
            <p>
              {value && formatTimeSpanToString(value) }
            </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, firms, null, 'Person')
  
  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: personFilter.name,
        comparisonType: 'CONTAINS'
      },
      firm: {
        value: personFilter.firmsId.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      title: {
        value: personFilter.titleId.map(f => f.name), 
        comparisonType: 'EQUAL'
      },
      isAttorney: {
        value: personFilter.isAttorney.map(f => f.id === 'No' ? false : true ),
        comparisonType: 'EQUAL'
      },
      memberType: {
        value: personFilter.memberType.map(f => Number(f.id)),
        comparisonType: 'EQUAL'
      }
    }
    setFilter(filterAux)
  }, [personFilter])
  
  const clearSelections = () => {
    setPersonFilter({
      id_: '',
      name: '',
      firmsId: [],
      titleId: [],
      memberType: [],
      isStaff: [],
      isAttorney: [],
    })
    fetchData(true)
  };

  return (
    <>
      <div className='mainSection'>
        <CatalogTableC
          headers={Headers}
          route={'Person'}
          catalogName='person'
          title={`Person Catalog`}
          totalCount={totalCount}
          isfiltered={true}
          data={data}
          exportData={transformedData}
        >
          <PersonFilter
            onClickSearch={fetchData}
            handleFormReset={clearSelections}
            headers={Headers}
            setPersonFilter={setPersonFilter}
            personFilter={personFilter}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default Person;