import React, { useEffect, useRef, useState } from 'react';
import InputText, { InputTextRef } from '../../Atomos/Inputs/InputText';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';
import Input from '../../Atomos/Inputs/Input';


interface IStateFilterProps {
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  abbreviationFilter: string;
  onAbbreviationFilterChange: (newAbbreviationFilter: string) => void;
  countryData: any[]
  stateData: any[]
  selectedCountry: Option[];
  setSelectedCountry: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedState: Option[];
  setSelectedState: React.Dispatch<React.SetStateAction<Option[]>>;
  search: () => void;
  resetFilters: () => void;
  headers: string[];
  tableInstance:any;
}

const SubStateFilter: React.FC<IStateFilterProps> = ({ tableInstance, countryData, stateData, selectedCountry, setSelectedCountry, selectedState, setSelectedState, search, resetFilters, headers, nameFilter, onNameFilterChange, abbreviationFilter, onAbbreviationFilterChange }) => {
  const nameRef = useRef<InputTextRef | null>(null);
  const abbreviationRef = useRef<InputTextRef | null>(null);

  const selectedOptions = (options: any[]) => {
    const objects = options.map((option) => option);
  };

  return (

    <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:flex-1 lg:flex lg:flex-row
      border border-gray-100'>
      <div className='w-full'>
        <Input
          type="text"
          value={nameFilter}
          onChange={(e) => onNameFilterChange(e.target.value)}
          error={false}
          title='By Name'
          isWfull
        />
      </div>
      <div className='w-full'>
        <Input
          type="text"
          value={abbreviationFilter}
          onChange={(e) => onAbbreviationFilterChange(e.target.value)}
          error={false}
          title='By Abbreviation'
          isWfull
        />
      </div>
      <div className='w-full'>
        <DDMultiple title="By Country:" objects={countryData} selectedObjst={selectedCountry} seter={setSelectedCountry} />
      </div>
      <div className='w-full'>
        <DDMultiple title="By State:" objects={stateData} selectedObjst={selectedState} seter={setSelectedState} />
      </div>
      <div className='flex flex-row gap-2 w-full md:justify-end lg:w-max md:col-span-2 md:max-w-[17rem] md:ml-auto'>
        <CatalogFilterButtons
        tableInstance={tableInstance} onClickSearch={search} handleFormReset={resetFilters} headers={headers} />
      </div>
    </div>
  );
};

export default SubStateFilter;
