function Dashboard(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M8.44554 6.39452V1.57422H14.426V6.39452H8.44554ZM1.57812 8.67715V1.57422H7.55856V8.67715H1.57812ZM8.44554 14.4033V7.2909H14.426V14.4032L8.44554 14.4033ZM1.57812 14.4033V9.57352H7.55856V14.4033H1.57812ZM2.90786 7.35684H6.23828V2.89452H2.90786V7.35684ZM9.77526 13.0735H13.1057V8.62062H9.77526V13.0735ZM9.77526 5.07422H13.1057V2.89452H9.77526V5.07422ZM2.90786 13.0735H6.23828V10.9033H2.90786V13.0735Z" />
      </svg>
    </div>
  );
}

export default Dashboard;
