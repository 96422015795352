

function Drag_pan(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" >
        <path d="M10.0039 19.6573L6.1001 15.7534L7.1693 14.6842L9.25395 16.7689V10.7534H3.25387L5.33852 12.8227L4.25392 13.9073L0.350098 10.0034L4.23855 6.11501L5.30775 7.18421L3.2385 9.25346H9.25395V3.23801L7.1693 5.32264L6.1001 4.25343L10.0039 0.349609L13.9077 4.25343L12.8385 5.32264L10.7539 3.23801V9.25346H16.754L14.6693 7.18421L15.7539 6.09961L19.6577 10.0034L15.7539 13.9073L14.6847 12.8381L16.7693 10.7534H10.7539V16.7535L12.8231 14.6688L13.9077 15.7534L10.0039 19.6573Z" />
        </svg>
        );
  }
  
  export default Drag_pan;