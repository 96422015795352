
function Insert_text() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16" >
            <path d="M7.50021 10.6666V6.3333H5.50021V5.3333H10.5002V6.3333H8.50021V10.6666H7.50021ZM0.450212 15.55V11.6797H1.76686V4.32027H0.450195V0.449951H4.32051V1.76662H11.6799V0.449951H15.5502V4.32027H14.2336V11.6797H15.5502V15.55H11.6799V14.2333H4.32051V15.55L0.450212 15.55ZM4.32051 12.913H11.6799V11.6797H12.9132V4.32027H11.6799V3.08693H4.32051V4.32027H3.08718V11.6797H4.32051V12.913ZM1.56325 3.21663H3.21688V1.563H1.56325V3.21663ZM12.7835 3.21663H14.4372V1.563H12.7835V3.21663ZM12.7835 14.4369H14.4372V12.7833H12.7835V14.4369ZM1.56325 14.4369H3.21688V12.7833H1.56325V14.4369Z"  />
        </svg>
    );
}

export default Insert_text;
