import { axios } from "..";
import { IAreaOfPractice } from "../interfaces/models/IAreaOfPractice";

export const getAllAops = async () => {
  try {
    const response = await axios.Get(`/AreaOfPractice`);
    if (response && response.data) return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createAop = async (aop: IAreaOfPractice) => {
  try {
    const response = await axios.Post(`/AreaOfPractice`, aop);
    if (response && response.data) return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAop = async (aop: IAreaOfPractice) => {
  try {
    const response = await axios.Put(`/AreaOfPractice`, aop);
    if (response && response.data) return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAop = async (id:string) => {
  try {
    const response = await axios.Delete(`/AreaOfPractice`, `${id}`);
    if (response && response.data) return response.data;
  } catch (error) {
    console.log(error);
  }
};
