import '../../../assets/css/AddCatalogs.css';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState, useLayoutEffect } from "react";
import PlusIcon from '../../../components/Atomos/Icons/PlusIcon';
import DropDown from '../../../components/Atomos/DropDown/Dropdown';
import Remove from '../../../components/Atomos/Icons/Remove';
import SaveOverride from '../../../components/Catalogs/SaveOverride';
import Input from '../../../components/Atomos/Inputs/Input';
import SwitchToogle from '../../../components/Atomos/Switch-Toogle/Switch';
import GoBack from '../../../components/Moleculas/GoBack';
import NewButton from '../../../components/Atomos/Buttons/newButton';
import ButtonsCrud from '../../../components/Catalogs/ButtonsCrud';
import DDMultiple, { Option } from '../../../components/Atomos/DropDown/DDMultiple';
import CatalogTitle from '../../../components/Moleculas/Catalog Text/CatalogTitle';
import CatalogSubTitle from '../../../components/Moleculas/Catalog Text/CatalogSubTitle';
import PracticeIndustriesMapping from '../../../components/MappingAOP/AOPMapping';
import usePracticeIndustryMapping from '../../../hooks/AOPMapping/useAopMapping';
import { IAreaOfPractice } from '../../../interfaces/models/IAreaOfPractice';
import Modal from '../../../components/Atomos/Modals/Modal';
import Rewind from '../../../components/Atomos/Icons/Rewind';
import CancelClear from '../../../components/Atomos/Icons/CancelClear';
import IOffice from '../../../interfaces/models/IOffice';
import { IPerson } from '../../../interfaces';
import { UseFirmList, firmType } from '../../../hooks/Catalogs/useFirm';
import { useLogging } from '../../../Context/LoggingContext';
import { UsePracticeIndustryList } from '../../../hooks/Catalogs/usePracticeIndustry';

const axios = new Axios();
interface iCatalogProps {
  mode: "add" | "edit";
  returnPage?: string;
}

interface IErrorState {
  nameAlreadyExists: boolean;
  nameRequired: boolean;
  firmsRequired: boolean;
  officesRequired: boolean;
  firmsErrorMessage: string;
  officesErrorMessage: string;
  lenErr?: boolean;
  practiceIndustriesRequired?: boolean;
}

const initialErrorState: IErrorState = {
  nameAlreadyExists: false,
  nameRequired: false,
  firmsRequired: false,
  officesRequired: false,
  firmsErrorMessage: "",
  officesErrorMessage: "",
  lenErr: false,
  practiceIndustriesRequired: false,
};

function transformFirmsData(firmsData: any) {
  return firmsData?.map((firm: any) => ({
    id: firm.id,
    name: firm.name,
    title: firm.firmName,
    disable: false
  }));
}

function transformOfficesData(officesData: any) {
  return officesData?.map((office: any) => ({
    id: office.id,
    name: office.name,
    title: office.name,
    disable: false
  }));
}
interface FirmOfficePair {
  firm: Option | null;
  offices: Option[];
  formattedOffices?: Option[];
  error: boolean;
}

const AddAOP: React.FC<iCatalogProps> = (props) => {
  const { logActivity } = useLogging();
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const { mode } = props;
  const isEditMode = props.mode === "edit";
  const [isMapped, setIsMapped] = useState(false);
  const [isIgnored, setIsIgnored] = useState(false);
  const [isTargeted, setIsTargeted] = useState(false);
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [formattedFirms, setFormattedFirms] = useState<any[]>([])
  const [formattedOffices, setFormatedOffices] = useState<any[]>([])
  const [showSaveOverride, setShowSaveOverride] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('')
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModified, setIsModified] = useState(true);
  const [firmsOfficesPairs, setFirmsOfficesPairs] = useState<FirmOfficePair[]>([{
    firm: null,
    offices: [],
    error: false,
  }]);
  const [selectedFirmIds, setSelectedFirmIds] = useState<string[]>([]);
  const [uniqueFirms, setUniqueFirmIds] = useState<Option[]>([] as Option[]);
  const [uniqueOffices, setUniqueOffices] = useState<Option[]>([] as Option[]);

  const [hasMerged, setHasMerged] = useState<boolean>(false);
  const [nameRepeated, setNameRepeated] = useState<string>('');

  const [persons, setPersons] = useState<IPerson[]>([]);
  const [totalProfessionals, setTotalProfessionals] = useState<number>(0)
  const { firmData } = UseFirmList(firmType.member);
  const { practiceIndustryData } = UsePracticeIndustryList();

  const getPersons = async () => {
    try {
      const response = await axios.Get('/person')
      if (response && response.data) {
        setPersons(response.data)
      }
    } catch (error: any) {
      throw error
    }
  }

  const findProfessionalsForAop = async (aop: string) => {
    if (persons) {
      const filteredPersons = persons.filter((person) =>
        person.areasOfPractice?.some((area) => area.id === aop)
      );
      setTotalProfessionals(filteredPersons.length);
    }
  };

  useEffect(() => {
    if (state && mode === 'edit') {
      findProfessionalsForAop(state.id)
    }
  }, [mode])

  const attemptSaveOrUpdate = async () => {
    const hasFirmError = firmsOfficesPairs.some(pair => (
      !pair.firm ||
      Object.keys(pair.firm).length === 0 ||
      pair.firm.name === ''
    ));
    const hasOfficesError = firmsOfficesPairs.some(pair => pair.offices.length === 0);
    const areFirmsOfficesPairsEmpty = firmsOfficesPairs.length === 0;
    const hasPracticeIndustriesError = currentPracticeIndustries.every(industry => !industry.isChecked) && !isIgnored;

    const errors = {
      ...initialErrorState,
      nameRequired: (inputValue?.trim() === '' && mode === 'add'),
      firmsRequired: hasFirmError || areFirmsOfficesPairsEmpty,
      officesRequired: hasOfficesError || areFirmsOfficesPairsEmpty,
      practiceIndustriesRequired: mode === 'edit' && hasPracticeIndustriesError,
    };

    setErrorState(errors);

    const errorFields = Object.entries(errors)
      .filter(([_, value]) => value)
      .map(([key]) => {
        switch (key) {
          case "nameRequired": return "Name";
          case "firmsRequired": return "Firm";
          case "officesRequired": return "Offices";
          case "practiceIndustriesRequired": return "Map Or Ignore AOP";
          default: return "";
        }
      });

    const errorsOverride = {
      ...errors,
      firmsErrorMessage: (hasFirmError || firmsOfficesPairs.length === 0) ? "The Firm is Required" : "",
      officesErrorMessage: (hasOfficesError || firmsOfficesPairs.length === 0) ? "The Office is Required" : "",
    };
    setErrorState(errorsOverride)
    const isValid = Object.values(errors).every((error) => !error);

    if (!isValid) {
      setShowSaveOverride(true);
      setErrorFields(errorFields);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return
    }
    else {
      handleSave()
    }
  };

  const handleAddFirmOfficePair = () => {
    setFirmsOfficesPairs([...firmsOfficesPairs, { firm: {} as Option, offices: [], error: false }]);
    setErrorState(initialErrorState)
    setShowSaveOverride(false)
  };

  const handleRemoveFirmOfficePair = (index: number) => {
    setFirmsOfficesPairs(firmsOfficesPairs.filter((_, i) => i !== index));
    setErrorState(initialErrorState)
    setShowSaveOverride(false)
  };

  const getFilteredFirms = (index: any) => {
    const currentFirmId = firmsOfficesPairs[index].firm?.id;
    return formattedFirms.filter(firm =>
      !selectedFirmIds.includes(firm.id) || firm.id === currentFirmId
    );
  };

  const handleFirmsChange = async (selectedItem: Option, index: number) => {
    const selectionPropItem: Option = {
      id: selectedItem.id,
      name: selectedItem.name,
      disable: false
    };

    const newPairs = [...firmsOfficesPairs];
    newPairs[index].firm = selectionPropItem;

    try {
      const response = await axios.Get('/officeList?firm=' + selectedItem.id);
      const officesData = transformOfficesData(response.data);
      newPairs[index].formattedOffices = officesData;
    } catch (error) {
      console.error('Error fetching offices data:', error);
    }

    setFirmsOfficesPairs(newPairs);
    setErrorState(initialErrorState);
  };

  const handleOfficesChange = (selectedItems: Option[], index: number) => {
    const newPairs = [...firmsOfficesPairs];
    newPairs[index].offices = selectedItems;
    setFirmsOfficesPairs(newPairs);
    setErrorState(initialErrorState);
  };

  useEffect(() => {
    const fetchOfficesByFirms = async () => {
      try {
        const officesPromises = selectedFirmIds?.map(firm =>
          axios.Get('/officeList?firm=' + firm)
        );

        const responses = await Promise.all(officesPromises);
        const allOffices = responses.flatMap(response => response.data);
        const transformedOffices = transformOfficesData(allOffices);
        setFormatedOffices(transformedOffices);
      } catch (error) {
        console.error('Error fetching offices data:', error);
      }
    };
    fetchOfficesByFirms();
  }, [selectedFirmIds]);

  useEffect(() => {
    if (!firmData.length) return

    const transformedFirms = transformFirmsData(firmData);
    setFormattedFirms(transformedFirms)
    console.log(state)
  }, []);

  useEffect(() => {
    const updatedUniqueFirms = firmsOfficesPairs
      .filter(pair => pair.firm !== null)
      ?.map(pair => pair.firm)
      .filter((firm, index, self) => self.findIndex(f => f?.id === firm?.id) === index) as Option[];

    const updatedUniqueOffices = firmsOfficesPairs
      .flatMap(pair => pair.offices)
      .filter((office, index, self) => self.findIndex(o => o.id === office.id) === index);

    setUniqueFirmIds(updatedUniqueFirms);
    setUniqueOffices(updatedUniqueOffices);
  }, [firmsOfficesPairs]);

  const updateAop = async (aop: IAreaOfPractice) => {
    const firmNames = aop.firms.map(f => f.name)?.join(', ');
    const practiceIndustryNames = practiceIndustryData.filter(p => p.areasOfPractice
      ?.map((a: any) => a.id)?.includes(aop.id))?.map((p: any) => p.name)?.join(', ');
    const newAOP = {
      ...aop,
      firmNames,
      practiceIndustryNames
    }
    if (mode === 'edit') {
      try {
        await axios.Put('/areaOfPractice', newAOP)
      } catch (error) {
        console.log(error)
      }
    }
    else {
      try {
        await axios.Post('/areaOfPractice', newAOP)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const {
    currentPracticeIndustries,
    setCurrentPracticeIndustries,
    currentAOPS,
    errors,
    handleCheckboxChange,
    prepareUpdatedIndustries,
    updateIndustries,
    updateErrors
  } = usePracticeIndustryMapping([], [], state);

  useEffect(() => {
    setErrorState(initialErrorState);
    setShowSaveOverride(false)
  }, [currentPracticeIndustries, isIgnored]);

  const normalizeName = (name: string) => name?.trim().replace(/\s+/g, ' ');
  const handleSave = async () => {

    const selectedIndustries = currentPracticeIndustries.filter(industry => industry.isChecked);
    const isNameDuplicated = inputValue?.trim() !== '' && currentAOPS.some(aop => normalizeName(aop.name) === normalizeName(inputValue));
    if (mode === 'add' && isNameDuplicated) {
      setErrorState({
        ...errorState,
        nameAlreadyExists: true,
      });
      return;
    }

    if (selectedIndustries.length === 0 && !isIgnored) {
      updateErrors("Please map or Ignore this AOP");
    }

    if (inputValue && selectedIndustries.length === 0 && !isIgnored) {
      updateErrors("Please Map or Ignore the AOP.");
    }

    if (!hasMerged && isNameDuplicated) {
      setNameRepeated(normalizeName(inputValue));
      setIsModalOpen(true);
      return;
    }

    const newState = {
      ...state,
      id: mode === 'edit' && (state && state.id) ? state.id : undefined,
      name: inputValue?.trim() !== '' ? inputValue : state?.name,
      isIgnored: isIgnored,
      firms: uniqueFirms,
      offices: uniqueOffices,
      isMapped: mode === 'edit' && selectedIndustries.length > 0 ? true : false,
      isTargeted: false,
      professionals: mode === 'edit' ? totalProfessionals : 0
    };

    const { toAdd, toRemove } = prepareUpdatedIndustries(currentPracticeIndustries, selectedIndustries, newState);
    await updateIndustries(toAdd, toRemove);

    if (hasMerged) {
      const aopToMergeWith = currentAOPS.find(aop => aop.name?.trim() === inputValue?.trim());
      if (aopToMergeWith) {
        handleMergeNames(true)
        await axios.Delete('/areaOfPractice', aopToMergeWith.id)
        setHasMerged(false);
      }
      setTimeout(() => {
        navigate(props.returnPage ? props.returnPage : '/Staff/Catalogs/AreaOfPractice')
      }, 200);
      logActivity('MERGE_AOP', location.pathname, `Merged AOP with name: ${inputValue}`);
      return;
    } else {
      await updateAop(newState);
      logActivity(mode === 'edit' ? 'UPDATE_AOP' : 'CREATE_AOP', location.pathname, mode === 'edit' ? JSON.stringify({ prevState: state, newState: newState }) : JSON.stringify({ name: inputValue, firms: uniqueFirms, offices: uniqueOffices })); // Log create/update action
    }

    setTimeout(() => {
      navigate(props.returnPage ? props.returnPage : '/Staff/Catalogs/AreaOfPractice');
    }, 200);
    setHasMerged(false);
  };

  useLayoutEffect(() => {
    const loadOfficesForFirms = async () => {
      if (state && state.firms && state.offices) {
        const { isMapped, isIgnored, isTargeted, firms } = state;

        setIsMapped(isMapped);
        setIsTargeted(isTargeted);
        setIsIgnored(isIgnored);
        const initialSelectedFirms = transformFirmsData(firms);
        const promises = initialSelectedFirms.map(async (firm: any) => {
          try {
            const response = await axios.Get(`/officeList?firm=${firm.id}`);
            const officesData = transformOfficesData(response.data);
            const selectedOffices = officesData.filter((office: IOffice) =>
              state.offices?.some((stateOffice: IOffice) => stateOffice.id === office.id)
            );
            return {
              firm,
              offices: selectedOffices,
              formattedOffices: officesData,
              error: false
            };
          } catch (error) {
            console.error('Error fetching offices data:', error);
            return {
              firm,
              offices: [],
              formattedOffices: [],
              error: true
            };
          }
        });
        const firmsOfficesPairsTemp: any[] = await Promise.all(promises);
        setFirmsOfficesPairs(firmsOfficesPairsTemp);
      }
    };
    loadOfficesForFirms();
  }, [state]);

  const handleMergeNames = async (confirm?: boolean) => {
    const aopToMergeWith = currentAOPS.find(aop => aop.name?.trim() === inputValue?.trim());

    if (!aopToMergeWith) {
      console.error("AOP to merge with not found.");
      return;
    }

    const combinedFirms = [...state.firms, ...aopToMergeWith.firms]
      .reduce((acc, current) => {
        if (!acc?.some((firm: any) => firm.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

    const combinedOffices = [...state.offices, ...aopToMergeWith.offices]
      .reduce((acc, current) => {
        if (!acc?.some((office: any) => office.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

    const newFirmsOfficesPairs = await Promise.all(combinedFirms.map(async (firm: any) => {
      try {
        const response = await axios.Get(`/officeList?firm=${firm.id}`);
        const officesData = transformOfficesData(response.data);
        return {
          firm,
          offices: officesData,
          formattedOffices: officesData,
          error: false
        };
      } catch (error) {
        console.error('Error fetching offices data for firm', firm.id, ':', error);
        return {
          firm,
          offices: [],
          formattedOffices: [],
          error: true
        };
      }
    }));

    setFirmsOfficesPairs(newFirmsOfficesPairs);

    const updatedPracticeIndustries = currentPracticeIndustries.map(industry => ({
      ...industry,
      isChecked: industry.isChecked || industry.areasOfPractice?.some(ap => ap.id === aopToMergeWith.id),
    }));
    setCurrentPracticeIndustries(updatedPracticeIndustries)
    setIsIgnored(aopToMergeWith.isIgnored)

    if (hasMerged && confirm) {
      const updatedAOP = {
        ...state,
        id: mode === 'edit' && (state && state.id) ? state.id : undefined,
        isIgnored: state?.isIgnored,
        isTargeted: state?.isTargeted,
        name: nameRepeated,
        firms: combinedFirms,
        offices: combinedOffices,
        isMapped: true,
        professionals: mode === 'edit' ? totalProfessionals : 0
      }
      updateAop(updatedAOP)
    }
    setHasMerged(true);
    setIsModalOpen(false);
  };

  const inputOnchange = (e: any) => {
    setInputValue(e.target.value);
    const nameError = inputValue?.trim() === '';
    setErrorState(prevState => ({
      ...prevState,
      nameRequired: nameError,
      nameAlreadyExists: false
    }));
    setIsModified(true);
    setErrorState(initialErrorState);
  };

  const isButtonDisabled = () => {
    return !isModified || Object.values(errorState)?.some(error => error);
  };

  const handleCloseSaveOverride = () => {
    setErrorState({ ...initialErrorState })
    setShowSaveOverride(false);
  };

  const RemoveIcon = () => {
    return (
      <div className='w-[1.25rem] h-[1.25rem]'>
        <Remove />
      </div>
    )
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const MergeIcon = () => (
    <div className=''>
      <Rewind />
    </div>
  )

  const CancelIcon = () => (
    <div className=''>
      <CancelClear />
    </div>
  )

  const processEntities = async (entities: any, aopId: any, endpoint: any) => {
    const filteredEntities = entities.filter((entity: any) => entity.areasOfPractice?.some((aop: any) => aop.id === aopId));
    const updatePromises = filteredEntities.map(async (entity: any) => {
      const updatedAreas = entity.areasOfPractice.filter((aop: any) => aop.id !== aopId);
      return axios.Put(`/${endpoint}`, { ...entity, areasOfPractice: updatedAreas });
    });

    await Promise.all(updatePromises);
  };

  useEffect(() => {
    getPersons()
  }, [])

  const handleDelete = async (id: string) => {
    try {
      const deleteAopResponse = await axios.Delete(`/AreaOfPractice`, id);
      if (deleteAopResponse.status === 200) {
        await processEntities(persons, id, 'person')
        await processEntities(currentPracticeIndustries, id, 'practiceIndustry')
        navigate(props.returnPage ? props.returnPage : '/Staff/Catalogs/AreaOfPractice');
        logActivity('DELETE_AOP', location.pathname, JSON.stringify(state ? state : {})); 
      } else {
        console.error('Error al eliminar el AOP:', deleteAopResponse.data);
      }
    } catch (error) {
      console.error('Error durante la eliminación:', error);
    }
  };

  return (
    <>
      <main className={`mainSection ${showSaveOverride && 'pt-7'}`}>
        {showSaveOverride && (
          <SaveOverride
            fields={errorFields}
            open={showSaveOverride}
            close={handleCloseSaveOverride}
            handleButtonClick={handleSave}
          />
        )}
        {(isModalOpen) &&
          <>
            <div>
              <Modal isModalOpen={isModalOpen} toggleModal={handleCloseModal} title='Merge AOP' className='min-w-[18.75rem] md:min-w-[34.5rem] py-5 px-4 md:px-7 md:py-7 lg:px-10 lg:py-10'
                children={
                  <>
                    <span className='text-left text-[#474F56] w-full flex mb-4  text-base lg:text-[1.125rem] font-normal leading-6'>The name {nameRepeated} already exists as an Area of Practice.</span>
                    <span className='text-left text-[#474F56] w-full flex mb-8  text-base leading-6 lg:text-[1.125rem] font-normal lg:leading-6'>Do you want to merge this mapping with the existent data?</span>
                    <div className='flex flex-col md:flex-row justify-around gap-5'>
                      <NewButton className='!min-w-[13.125rem]' onClick={() => setIsModalOpen(false)} text='CANCEL' color='neutral' style='outlined' content='textIcon' icon={<CancelIcon />} />
                      <NewButton onClick={handleMergeNames} text='Merge AOP' color='primary' content='textIcon' icon={<MergeIcon />} />
                    </div>
                  </>
                } />
            </div>
          </>
        }
        <CatalogTitle title={`${props.mode === 'add' ? 'New Area Of Practice' : 'Edit ' + (state ? state?.name || '' : 'Loading...')}`} className="text-Default">
          <GoBack goBackTo={props.returnPage} />
        </CatalogTitle>
        <article className='articleSection'>
          {props.mode === 'add' &&
            <>
              <section className="formsectionNTitle">
                <CatalogSubTitle className="text-accent-Default-dark" title="AOP Information" />
              </section>
              <div className='flex flex-col items-start gap-5 self-stretch lg:flex-row'>
                <div className='flex items-start gap-5 self-stretch w-full'>
                  <Input
                    isWfull={true}
                    value={inputValue}
                    onChange={inputOnchange}
                    name="Name"
                    title="Name"
                    required={true}
                    error={errorState.nameRequired || errorState.nameAlreadyExists}
                    errorMsj={
                      errorState.nameRequired ? "Name is required." : errorState.nameAlreadyExists ? "This value is already registered in the DB. Please input a different/new one." :
                        ""}
                  />
                </div>
                <div className='flex justify-center items-start gap-5 self-stretch md:justify-start'>
                  <div className='flex flex-col items-center justify-center gap-[.625rem]'>
                    <label className='text-base leading-[1.125rem] text-center text-[#474F56]'>Is Mapped?</label>
                    <SwitchToogle seter={() => setIsMapped(!isMapped)} checked={isMapped} small disabled={!isEditMode} centered />
                  </div>
                  <div className='flex flex-col items-center justify-center gap-[.625rem]'>
                    <label className='text-base leading-[1.125rem] text-center text-[#474F56]'>Is Ignored?</label>
                    <SwitchToogle seter={() => setIsIgnored(!isIgnored)} checked={isIgnored} small disabled={!isEditMode} centered />
                  </div>
                  <div className='flex flex-col items-center justify-center gap-[.625rem]'>
                    <label className='text-base leading-[1.125rem] text-center text-[#474F56]'>Is Targeted?</label>
                    <SwitchToogle seter={() => setIsTargeted(!isTargeted)} checked={isTargeted} small disabled={!isEditMode} centered />
                  </div>
                </div>
              </div>
            </>
          }
          <div className='flex flex-col items-start gap-6 self-stretch'>
            <section className="formsectionNTitle">
              <CatalogSubTitle className="text-accent-Default-dark" title="Catalog List Relationships" />
            </section>
            <div className='flex flex-col items-start gap-5 self-stretch'>
              {
                firmsOfficesPairs?.map((pair, index) => (
                  <div className='w-full flex flex-col gap-5 md:flex-row' key={index}>
                    <DropDown
                      title="Firms"
                      required={true}
                      getid={true}
                      data={getFilteredFirms(index)}
                      selectedObjst={pair.firm ? pair.firm.name : ''}
                      seter={(selectedItem) => handleFirmsChange(selectedItem, index)}
                      error={errorState.firmsRequired}
                      errormsj={errorState.firmsRequired ? errorState.firmsErrorMessage : undefined}
                    />
                    <div className="w-full">
                      <DDMultiple
                        required
                        title="Offices"
                        objects={pair.formattedOffices ?? []}
                        selectedObjst={pair.offices}
                        seter={(selectedItem: any) => handleOfficesChange(selectedItem, index)}
                        error={errorState.officesRequired}
                        errorMessage={errorState.officesRequired ? errorState.officesErrorMessage : undefined}
                      />
                    </div>
                    {firmsOfficesPairs.length > 1 && (
                      <div className={`w-full h-max ${errorState.firmsRequired || errorState.officesRequired ? 'my-auto' : ''} md:mt-auto !max-h-[3rem] md:h-[3rem] lg:max-w-[11rem] md:max-w-[8.5rem]`}>
                        <NewButton
                          onClick={() => handleRemoveFirmOfficePair(index)}
                          text='REMOVE'
                          className=''
                          color='delete'
                          size='medium'
                          style='outlined'
                          content='textIcon'
                          icon={<RemoveIcon />}
                        />
                      </div>
                    )}
                  </div>
                ))
              }
              <div className={`w-full lg:max-w-[10rem] md:max-w-[8.5rem] ${errorState.lenErr ? '' : ''} `}>
                <NewButton onClick={handleAddFirmOfficePair} color='accent' size='medium' style='filled' text='ADD FIRM' content='textIcon' icon={<PlusIcon />} />
              </div>
            </div>
          </div>
          {props.mode === 'edit' &&
            <>
              <section className="formsectionNTitle">
                <div className='flex flex-col gap-[.625rem]'>
                  <CatalogSubTitle className="text-accent-Default-dark" title="Areas Of Practice Mapping" />
                  {errorState.practiceIndustriesRequired ? <span className={`flex flex-wrap items-center text-sm font-medium text-feedback-error`}>Map or Ignore AOP</span> : null}
                </div>
              </section>
              <div className='flex flex-col w-full gap-5 md:flex-row'>
                <div className='flex gap-[.625rem] items-center self-stretch md:flex-col md:items-center md:w-max'>
                  <label className='text-[#474F56] whitespace-nowrap text-base leading-[1.125rem] lg:text-[1.125rem] lg:leading-[1.125rem] roboto_font'>Ignore AOP</label>
                  <SwitchToogle checked={isIgnored} seter={() => setIsIgnored(!isIgnored)} small={true} centered />
                </div>
                <div className='flex items-start gap-5 self-stretch w-full'>
                  <Input
                    isWfull={true}
                    value={inputValue}
                    onChange={inputOnchange}
                    name="Name"
                    title="Rename AOP"
                    required={false}
                    error={errorState.nameRequired || errorState.nameAlreadyExists}
                    errorMsj={
                      errorState.nameRequired ? "Name is required." : errorState.nameAlreadyExists ? "This value is already registered in the DB. Please input a different/new one." : ""}
                  />
                </div>
              </div>
              <PracticeIndustriesMapping practiceIndustries={currentPracticeIndustries} onIndustryChange={handleCheckboxChange} />
            </>}
          <div className='flex gap-[.625rem] justify-end'>
          </div>
          <ButtonsCrud Catalog="AreaOfPractice" mode={props.mode} disabled={isButtonDisabled()} id={id ? id : ''} /*disabled={disabled}*/ actionButton={attemptSaveOrUpdate} onDelete={handleDelete} />
        </article>
      </main>
    </>
  );
}

export default AddAOP;
