import React from "react";

function MoreCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
    >
      <path
        d="M7.499 13.75c.347 0 .642-.121.886-.364.243-.243.365-.538.365-.885s-.121-.642-.364-.886a1.202 1.202 0 00-.885-.365c-.347 0-.642.121-.886.364a1.202 1.202 0 00-.365.885c0 .347.121.642.364.886.243.243.538.365.885.365zm5 0c.347 0 .642-.121.886-.364.243-.243.365-.538.365-.885s-.121-.642-.364-.886a1.202 1.202 0 00-.885-.365c-.347 0-.642.121-.886.364a1.202 1.202 0 00-.365.885c0 .347.121.642.364.886.243.243.538.365.885.365zm5 0c.347 0 .642-.121.886-.364.243-.243.365-.538.365-.885s-.122-.642-.364-.886a1.202 1.202 0 00-.885-.365c-.347 0-.642.121-.886.364a1.202 1.202 0 00-.365.885c0 .347.121.642.364.886.243.243.538.365.885.365zM12.502 22a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016A9.245 9.245 0 013 12.502c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03A9.245 9.245 0 0112.498 3c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016A9.245 9.245 0 0122 12.498c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zm-.002-1.5c2.233 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.442 2.325-5.675 0-2.233-.775-4.125-2.325-5.675C16.625 5.275 14.733 4.5 12.5 4.5c-2.233 0-4.125.775-5.675 2.325C5.275 8.375 4.5 10.267 4.5 12.5c0 2.233.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325z"
      ></path>
    </svg>
  );
}

export default MoreCircle;
