

function Sync(props: any) {
    return ( 

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
              
                    <path d="M4.2032 20.212V18.5565H7.09992L6.86625 18.3555C5.79016 17.4583 4.99867 16.5027 4.49177 15.4886C3.98489 14.4745 3.73145 13.3366 3.73145 12.075C3.73145 10.1953 4.3088 8.51828 5.46352 7.044C6.61824 5.56973 8.11155 4.58006 9.94345 4.07498V6.10545C8.68401 6.53225 7.66625 7.29873 6.89017 8.4049C6.11409 9.51105 5.72605 10.7344 5.72605 12.075C5.72605 13.0873 5.91481 13.9645 6.29235 14.7065C6.66988 15.4485 7.18256 16.0935 7.8304 16.6413L8.4956 17.0815V14.25H10.151V20.212H4.2032ZM14.0814 19.95V17.8945C15.3575 17.4677 16.3753 16.7013 17.1347 15.5951C17.8941 14.4889 18.2738 13.2656 18.2738 11.925C18.2738 11.1627 18.0827 10.3855 17.7005 9.59348C17.3182 8.80146 16.8293 8.0982 16.2336 7.4837L15.5934 6.9185V9.75H13.9238V3.78802H19.8858V5.44348H16.95L17.1978 5.69455C18.2166 6.64673 18.9831 7.67029 19.4972 8.76523C20.0114 9.86014 20.2684 10.9134 20.2684 11.925C20.2684 13.8047 19.6953 15.4859 18.5489 16.9685C17.4025 18.4511 15.9133 19.4449 14.0814 19.95Z"   />
            
            </svg> );
}

export default Sync;