import React, { useEffect, useState } from "react";
import AddressFilter from "../../components/Catalogs/Filters/AddressFilter";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import useDataLoader2 from "../../hooks/useDataLoader2";
import { useCityList } from "../../hooks/Catalogs/useCityList";
import { useStatesList } from "../../hooks/Catalogs/useStates";
import { UseCountryList } from "../../hooks/Catalogs";
import { transformData } from "../../hooks/Table/TransformColumnsForExport";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { formatTimeSpanToString, getNameById } from "../../utils/functions";
import { IAddress } from "../../interfaces/models/IAddress";

const Address: React.FC = ({ ...props }) => {
  const Headers: string[] = [
    "address1",
    "address2",
    "zipCode",
    "addressType",
    "city",
    "state",
    "country",
    "regions",
    "altitude",
    "latitude",
    "_ts",
  ];
  const DefaultPageSize = 50;
  const [tableInstance, setTableInstance] = useState<any>();
  const { countryData } = UseCountryList();
  const { regionData } = UseRegionList();
  const { cityData } = useCityList();
  const { statesData } = useStatesList();
  const { generateColumns } = useColumnConfig(Headers);
  const [nameFilter, setNameFilter] = useState('');
  const [selectedCities, setSelectedCities] = useState<Option[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<Option[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<Option[]>([]);
  const [selectedStates, setSelectedStates] = useState<Option[]>([]);
  const [addressData, setAddressData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'address1', 
    'address2', 
    'zipCode', 
    'addressType', 
    'city',
    "cityName",
    "stateName",
    "countryName",
    "regionNames",
    "altitude",
    "latitude",
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/address',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const clearSelections = () => {
    setNameFilter('')
    setSelectedCities([])
    setSelectedRegions([])
    setSelectedCountries([])
    setSelectedStates([])
    fetchData(true)
  }

  const addressColumnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false },
    { id: 'address1', header: "Address 1" },
    { id: 'address2', header: "Address 2" },
    { id: 'zipCode', header: "Zip Code" },
    { id: 'addressType', header: "Address Type" },
    { id: 'city', header: "City" },
    { id: 'state', header: "State" },
    { id: 'country', header: "Country" },
    { id: 'regions', header: "Regions" },
    {
      id: '_ts', header: "Created Date", cell: (props: any) => {
        const value = props.getValue();
        return value ? formatTimeSpanToString(value) : "";
      }
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ];

  const columns = generateColumns(addressColumnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const transformedData = transformData(data, addressColumnConfigs, pagination.pageIndex, pagination.pageSize, null, null, 'Address')

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const formatData = (data: IAddress[]) => {
    const formatedData = data.map((d: IAddress) => {
      return {
        ...d,
        city: d.cityName,
        state: d.stateName,
        country: d.countryName,
        regions: d.regionNames
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (data) setAddressData(formatData(data))
  },[data]);

  useEffect(() => {
    if (!cityData.length || !statesData.length ||!countryData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[cityData, statesData, countryData]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      countries: {
        value: selectedCountries?.map(c => c.id),
      },
      regions: {
        value: selectedRegions?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      states: {value: selectedStates?.map(f => f.id)},
      city: {
        value: selectedCities?.map(f => f.id),
        comparisonType: 'EQUAL'
      },
    }
    setFilter(filterAux)
  },[
    selectedStates, 
    selectedRegions, 
    selectedCountries, 
    selectedCities
  ]);

  return (
    <>
      <main className=" mainSection">
        <CatalogTableC
          title={`Address Catalog`}
          catalogName={"ADDRESSES"}
          catalogShortName={"Address"}
          route={"Address"}
          isfiltered={true}
          data={addressData}
          headers={Headers}
          totalCount={totalCount}
          exportData={addressData}
        >
          <AddressFilter
            stateData={statesData}
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            regionData={regionData}
            selectedRegions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            cityData={cityData}
            selectedCities={selectedCities}
            setSelectedCities={setSelectedCities}
            nameFilter={nameFilter}
            onNameFilterChange={handleNameFilterChange}
            key={0}
            search={fetchData}
            countryData={countryData}
            headers={Headers}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={addressData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default Address;
