import { useLocation, useNavigate } from "react-router-dom";
import Axios from "../../../utils/axios";
import { FormEvent, useEffect, useState } from "react";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { useLogging } from "../../../Context/LoggingContext";

type JurisdictionGroup = {
  id?: string;
  name: string;
}

type JurisdictionGroupError = {
  name: { error: boolean, msj: string }
}

interface iCatalogProps {
  mode: "add" | "edit";
}

const AddJurisdictionGroup: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const { state } = useLocation();
  const { logActivity } = useLogging();
  const location = useLocation()
  const navigate = useNavigate();
  const [jurisdictionGroup, setJurisdictionGroup] = useState<JurisdictionGroup>(state ? state as JurisdictionGroup : { id: '', name: '' });
  const [jurisdictionGroupError, setJurisdictionGroupError] = useState<JurisdictionGroupError>({ name: { error: false, msj: '' } });
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [hasError, setError] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const Validation = () => {
    let error = false;
    if (!jurisdictionGroup.name) {
      setJurisdictionGroupError({ ...jurisdictionGroupError, name: { error: true, msj: 'This field is required' } })
      error = true;
    }
    else
      setJurisdictionGroupError({ ...jurisdictionGroupError, name: { error: false, msj: '' } })
    setSaveOverrideOpen(error);
    setError(error);
    return !error;
  }
  const handleSetJurisdictionGroup = (inputName: string) => {
    setJurisdictionGroup({ ...jurisdictionGroup, name: inputName })
  }
  const handleOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }
  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  useEffect(() => {
    if (hasError)
      Validation();
  }
    , [jurisdictionGroup]);

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    if (buttonClicked === 'saveOverride') {

      if (props.mode === 'add') {
        handleSave(e);
      }
      else {
        handleUpdate(e);
      }
    }

    if (buttonClicked === 'updateButton') {
      if (Validation())
        handleUpdate(e);
    } else if (buttonClicked === 'saveButton') {
      if (Validation())
        handleSave(e);
    }

    if (buttonClicked === 'deleteButton') {
      handleDelete(e);
    }
  }
  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.Post('/jurisdictionGroup', jurisdictionGroup);
      if (response && response.data) {
        logActivity('CREATE_JURISDICTION_GROUP', location.pathname, JSON.stringify(response.data))
        navigate('/Staff/Catalogs/JurisdictionGroup');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.Put('/jurisdictionGroup', jurisdictionGroup);
      if (response && response.data) {
        logActivity('UPDATE_JURISDICTION_GROUP', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response.data) }))
        navigate('/Staff/Catalogs/JurisdictionGroup');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.Delete('/jurisdictionGroup', jurisdictionGroup.id as string);
      logActivity('DELETE_JURISDICTION_GROUP', location.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/JurisdictionGroup');
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>

        <form onSubmit={handleSubmit}>
          <SaveOverride fields={['Jurisdiction Group']} handleButtonClick={setButtonClicked} open={saveOverrideOpen} close={handleOverride} />
          <CatalogTitle title={`${props.mode === 'add' ? 'New Jurisdiction Group' : 'Edit ' + (state?.name ?? 'none')}`} className='text-Default'>
            <GoBack />
          </CatalogTitle>
          <article className='articleSection '>
            <section className="formsectionNTitle ">
              <CatalogSubTitle title={" Jurisdiction Group Information "} className=" text-accent-Default-dark" />
              <div className="formsection flex flex-1 w-full lg:w-1/2 ">
                <Input title={"Name"} value={jurisdictionGroup.name} onChange={(e) => handleSetJurisdictionGroup(e.target.value)} error={jurisdictionGroupError.name.error} errorMsj={jurisdictionGroupError.name.msj} required={true} />
              </div>
            </section>
            <ButtonsCrud Catalog="JurisdictionGroup" mode={props.mode} id={jurisdictionGroup.id ?? ''} disabled={jurisdictionGroupError.name.error} actionButton={setButtonClicked} onDelete={() => handleButtonClick('deleteButton')} />
          </article>
        </form>
      </main>
    </>
  );
}
export default AddJurisdictionGroup;