import React, { Children } from 'react';   
import '../../../assets/css/Text.css';

interface IHeaderTitle {
  title: string;
  children?: React.ReactNode; 
  className?: string;
}

const CatalogTitle: React.FC<IHeaderTitle> = ({ title, children,className }) => {  
  return ( 
    <div className={`flex justify-between items-center pb-5 md:pb-6 lg:pb-7 pt-7 md:pt-8 lg:pt-10 ${className} relative z-10`}>
      <h1 className={`${className}`}>
        {title}
      </h1>
      {Children.map(children, (child, index) => (  
        <div className="Row" key={index}> 
          {child}
        </div>
      ))}
    </div>
  );
};

export default CatalogTitle;

