import React from "react";

function TuneProfile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M2.76 19.275V17.45h6.5v1.825h-6.5zm0-12.725V4.725h10.65V6.55H2.76zm7.83 14.775v-5.95h1.825v2.075h8.825v1.825h-8.825v2.05H10.59zm-3.155-6.37v-2.05H2.76v-1.81h4.675v-2.1H9.26v5.96H7.435zm3.155-2.05v-1.81h10.65v1.81H10.59zm4.15-4.28v-5.95h1.825v2.05h4.675V6.55h-4.675v2.075H14.74z"></path>
    </svg>
  );
}

export default TuneProfile;
