import { MemberType } from "../../Enums/PersonEnums";
import { IPerson } from "../../interfaces";

export const PersonEmpty: IPerson = {
    id: "",
    memberType: MemberType.nonMember,
    firm: "",
    externalType: "",
    submittedBy: "",
    generatedId: 0,
    photo: "",
    name: "",
    middleInitial: "",
    lastName: "",
    suffix: "",
    honorary: "",
    title: "",
    unlistedTitle: false,
    listTitle: "",
    listingCategory: "",
    countryCodePhoneNumber: "",
    phoneNumber: "",
    phoneBaseCode: "",
    directCountryCodePhoneNumber: "",
    directPhoneNumber: "",
    directPhoneBaseCode: "",
    displayName: "",
    isSearchable: false,
    isAttorney: false,
    hasAssistant: false,
    assistantName: "",
    assistantEmail: "",
    firmEmail: "",
    mainOffice: "",
    profileURL: "",
    socialNetworks: [],
    additionalOffices: [],
    bio: "",
    languages: [],
    barAdmissions: [],
    areasOfPractice: [],
    significantAccomplishments: "",
    industryOrganizationAffiliation: [],
    additionalActivities: "",
    seminarPresentation: "",
    userId: "",
    _ts: 0,
    verificationCode: {
      verificationCode: 0,
      expires: 0,
      status: false
    }
    
  };
