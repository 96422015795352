// src/redux/states/firmProfileSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICity, IPerson } from "../../interfaces/models";
import IOffice from "../../interfaces/models/IOffice";
import IFirm from "../../interfaces/models/IFirm";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import { IAreaOfPractice } from "../../interfaces/models/IAreaOfPractice";

export interface FirmProfileState {
  urlType: string;
  currentFirm: IFirm;
  office: IOffice;
  cityInfo: ICity;
  addressInfo: any;
  practiceIndustries: IPracticeIndustry[];
  personCount: number;
  professionals: IPerson[];
  offices: IOffice[];
  articles: any[];
  mainProfessionalsByOffice: IPerson[];
  areasOfPractice: IAreaOfPractice[];
}

const initialState: FirmProfileState = {
  urlType: '',
  currentFirm: {} as IFirm,
  office: {} as IOffice,
  cityInfo: {} as ICity,
  addressInfo: null,
  practiceIndustries: [] as IPracticeIndustry[],
  personCount: 0,
  professionals: [] as IPerson[],
  offices: [] as IOffice[],
  articles: [] as any[],
  mainProfessionalsByOffice: [] as IPerson[],
  areasOfPractice: [] as IAreaOfPractice[]
};

const firmProfileSlice = createSlice({
  name: "firmProfile",
  initialState,
  reducers: {
    setFirmProfileState: (state, action: PayloadAction<Partial<FirmProfileState>>) => {
      return { ...state, ...action.payload };
    },
    setUrlType: (state, action: PayloadAction<string>) => {
      state.urlType = action.payload;
    },
    setCurrentFirm: (state, action: PayloadAction<IFirm>) => {
      state.currentFirm = action.payload;
    },
    setOffice: (state, action: PayloadAction<IOffice>) => {
      state.office = action.payload;
    },
    setCityInfo: (state, action: PayloadAction<ICity>) => {
      state.cityInfo = action.payload;
    },
    setAddressInfo: (state, action: PayloadAction<any>) => {
      state.addressInfo = action.payload;
    },
    setPracticeIndustries: (state, action: PayloadAction<IPracticeIndustry[]>) => {
      state.practiceIndustries = action.payload;
    },
    setPersonCount: (state, action: PayloadAction<number>) => {
      state.personCount = action.payload;
    },
    setProfessionals: (state, action: PayloadAction<IPerson[]>) => {
      state.professionals = action.payload;
    },
    setOffices: (state, action: PayloadAction<IOffice[]>) => {
      state.offices = action.payload;
    },
    setArticles: (state, action: PayloadAction<any[]>) => {
      state.articles = action.payload;
    },
    setMainProfessionalsByOffice: (state, action: PayloadAction<IPerson[]>) => {
      state.mainProfessionalsByOffice = action.payload;
    },
    setAreasOfPractice: (state, action: PayloadAction<IAreaOfPractice[]>) => {
      state.areasOfPractice = action.payload;
    }
  }
});

export const {
  setFirmProfileState,
  setUrlType,
  setCurrentFirm,
  setOffice,
  setCityInfo,
  setAddressInfo,
  setPracticeIndustries,
  setPersonCount,
  setProfessionals,
  setOffices,
  setArticles,
  setMainProfessionalsByOffice,
  setAreasOfPractice
} = firmProfileSlice.actions;

export default firmProfileSlice.reducer;
