import React from "react";

function SaveChanges() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
    >
       
        <path 
          d="M21.367 6.77v14.583H2.647V2.633H17.23l4.137 4.137zm-1.994.886l-3.03-3.029H4.627v14.746h14.746V7.656zm-7.38 10.092c.721 0 1.336-.252 1.844-.757a2.492 2.492 0 00.763-1.837 2.52 2.52 0 00-.757-1.844 2.492 2.492 0 00-1.837-.762c-.72 0-1.335.252-1.844.756a2.492 2.492 0 00-.762 1.838c0 .72.252 1.335.756 1.843a2.492 2.492 0 001.838.763zm-6.041-8.22h8.95V5.951h-8.95v3.575z"
        ></path> 
    </svg>
  );
}

export default SaveChanges;
