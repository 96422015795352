import { useEffect, useState } from "react";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import StateFilter from "../../components/Catalogs/Filters/StateFilter";
import { IStateFilterProps } from "../../interfaces/Catalogs/Filters/ICatalogsFilters";
import {
  createColumnHelper,
  Table,
} from "@tanstack/react-table";
import { IState } from "../../interfaces";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { Link } from "react-router-dom";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { useDispatch } from "react-redux";
import { formatTimeSpanToString } from "../../utils/functions";
import { stateSlice } from "../../redux";
import useDataLoader2 from '../../hooks/useDataLoader2';
import { UseCountryList } from "../../hooks/Catalogs";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
type tablebase = {
  count: number;
  edit: boolean;
};

const columnHelper = createColumnHelper<tablebase & IState>();

const State = () => {
  const DefaultPageSize = 50;
  const Headers = ["name", "abbreviation", "country", "regions", "hasSubstate", "_ts"];
  const dispatch = useDispatch()
  const [stateFilters, setStateFilters] = useState<IStateFilterProps>({
    name: "",
    abbreviation: "",
    countries: [],
    regions: [],
  });
  const [tableInstance, setTableInstance] = useState<Table<unknown>>({} as Table<unknown>);
  const {countryData} = UseCountryList();
  const {regionData} = UseRegionList();
  const [stateData, setStateData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    'abbreviation',
    'country',
    'regions',
    'hasSubstate',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/State',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columns = [
    columnHelper.accessor("count", {
      header: "#",
      enableSorting: false,
      cell: (props) => (
        <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
      ),
      size: 50,
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("abbreviation", {
      header: "Abbreviation",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("country", {
      header: "Country",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("regions", {
      header: "Regions",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("hasSubstate", {
      header: "Has Substate",
      cell: (props) => <p>{props.getValue() ? "YES" : "NO"}</p>,
    }),
    columnHelper.accessor("_ts", {
      header: "Created Date",
      cell: (props) => {
        const value = props.getValue();
        return (
          <p>
            {value ? formatTimeSpanToString(value.toString()) : ""}
          </p>
        );
      },
    }),
    columnHelper.accessor("edit", {
      header: "Edit",
      enableSorting: false,
      cell: (props) => (
        <Link
          className="edit"
          to={`Edit/${props.row["original"].id}`}
          state={props.row["original"]}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    }),
  ];
  useEffect(() => {
    dispatch(stateSlice.actions.InitializeState());
  }, []);
  
  const clearFilters = () => {
    setStateFilters({ name: "", abbreviation: "", countries: [], regions: [] });
    fetchData(true)
  };

  const formatData = (data: any[]) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        country: d.countryName,
        hasSubstate: d.hasSubstate ? "YES" : "NO",
        regions: d.regionNames
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (data) setStateData(formatData(data))
  },[data]);

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: stateFilters.name,
        comparisonType: 'CONTAINS'
      },
      abbreviation: {
        value: stateFilters.abbreviation,
        comparisonType: 'CONTAINS'
      },
      country: {
        value: stateFilters.countries.map(f => f.id),
        comparisonType: 'EQUAL'
      },
      regions: {value: stateFilters.regions.map(f => f.id)},
    }
    setFilter(filterAux)
  }, [stateFilters])
  
  return (
    <>
      <main className="mainSection">
        <CatalogTableC
          title="State Catalog"
          catalogName="STATES"
          catalogShortName="STATE"
          route="State"
          isfiltered={true}
          data={stateData}
          headers={Headers}
          totalCount={totalCount}
        >
          <StateFilter
            tableInstance={tableInstance}
            Search={fetchData}
            filters={stateFilters}
            setFilters={setStateFilters}
            headers={Headers}
            clearSelections={clearFilters}
          />
          <TableWithPagination
            data={stateData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default State;
