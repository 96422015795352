import { createSlice } from "@reduxjs/toolkit";
import { emptyRepresentativeClient } from "../emptyStates/RepresentativeClient";


export const representativeClientSlice = createSlice({
  name: 'representativeClient',
  initialState: emptyRepresentativeClient,
  reducers: {
    createRepresentativeClient: (state, action) => action.payload,
    modifyRepresentativeClient: (state, action) => ({ ...state, ...action.payload }),
    resetRepresentativeClient: () => emptyRepresentativeClient,
  }
})

export const { createRepresentativeClient, modifyRepresentativeClient, resetRepresentativeClient } = representativeClientSlice.actions;

export default representativeClientSlice.reducer;