import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface AppContextProps {
  countries?: any[];
  areasOfPractice?: any[];
  practiceIndustries?: any[];
  regions?: any[];
  firms?: any[];
  mode?: string;
  users?: any[];
  prevState?: any;
  updateState: (newState: Partial<AppContextProps>) => void;
}

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext debe ser utilizado dentro de un AppContextProvider');
  }
  return context;
};

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [state, setState] = useState<AppContextProps>({
    countries: [],
    regions: [],
    practiceIndustries: [],
    users: [],
    areasOfPractice: [],
    firms: [],
    mode: 'member',
    prevState:{},
    updateState: (newState: Partial<AppContextProps>) => {
      setState(prevState => ({ ...prevState, ...newState }));
    },
  });

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
};
