import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const contentTagsSlice = createSlice({
  name: "contentTags",
  initialState: Empty,
  reducers: {
    SetContentTags: (_, action) =>  action.payload,
    UpdateContentTags:(state, action) => ({...state, ...action.payload})
  },
});

export const { SetContentTags, UpdateContentTags } = contentTagsSlice.actions;
