function PencilEdit(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5204 2.4974C12.8974 1.11805 15.1299 1.11805 16.5068 2.4974C17.8838 3.87676 17.8838 6.11313 16.5068 7.49248L15.8478 8.15266L10.8614 3.15759L11.5204 2.4974ZM4.37698 18.9999H0V14.6153V14.0875L0.047091 14.0403L0.0316879 14.0249L1.62215 12.4316L1.62322 12.4327L10.1157 3.92544L15.0288 8.84712L6.67506 17.2154L6.67516 17.2155L5.24336 18.6498L5.24832 18.6548L4.90384 18.9999H4.37698L4.37698 18.9999ZM2.95851 17.5789L2.93981 17.5602L3.00285 15.9668L1.41847 16.0297V14.8367L2.56226 13.6909L5.31217 16.4456L4.17758 17.5822L4.17434 17.5789H2.95851ZM3.41312 12.9126L10.2148 6.09905L10.6654 6.55042L3.86371 13.3639L3.41312 12.9126Z"
        />
      </svg>
    </div>
  );
}

export default PencilEdit;
