

import { axios } from "..";

export const getFirmContact = async () => {
    try {
        const firmContacts = await axios.Get('./firmContact');
        const data = firmContacts.data;
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
  };