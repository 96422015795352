import css from './Homepage.module.css'


const HomePageComponent = () => {
    return ( 
        <div className="min-h-screen flex justify-end align-middle">
            <h1 className={`text-4xl roboto_font text-center m-auto h-fit ${css.redFont}`}>
            Public Homepage
            </h1>
        </div>
     );
}
 
export default HomePageComponent;