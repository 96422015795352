import React from "react";

function Linkedin2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
       
          <path 
            d="M12.675 18.398v25.146H4.308V18.398h8.367zm.546-7.777c0 1.243-.44 2.274-1.319 3.093-.879.818-2.016 1.227-3.41 1.227h-.046c-1.394 0-2.516-.409-3.365-1.227-.849-.819-1.273-1.85-1.273-3.093 0-1.242.44-2.273 1.319-3.092.879-.818 2.016-1.228 3.41-1.228 1.395 0 2.516.41 3.365 1.228.85.819 1.289 1.85 1.319 3.092zM42.779 29.13v14.415h-8.322v-13.46c0-1.788-.348-3.183-1.046-4.183-.697-1-1.773-1.501-3.228-1.501-1.061 0-1.94.288-2.638.864a5.925 5.925 0 00-1.637 2.183c-.182.515-.273 1.197-.273 2.046v14.052h-8.367c.03-6.76.046-12.233.046-16.417v-7.503l-.046-1.227h8.367v3.637h-.045a8.958 8.958 0 011.046-1.41c.364-.393.834-.833 1.41-1.318.575-.485 1.318-.849 2.228-1.091.91-.243 1.88-.38 2.91-.41 2.88 0 5.2.955 6.957 2.865 1.759 1.91 2.638 4.73 2.638 8.458z"
          ></path>
         
    </svg>
  );
}

export default Linkedin2;
