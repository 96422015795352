import React from "react";
interface IUser {
  fill?: string;
}
function UserRed(props: IUser) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M23.994 23.583c-2.41 0-4.38-.764-5.908-2.292-1.528-1.529-2.292-3.498-2.292-5.91 0-2.41.764-4.382 2.292-5.914 1.528-1.532 3.497-2.298 5.908-2.298 2.411 0 4.388.766 5.929 2.298 1.541 1.532 2.312 3.504 2.312 5.915 0 2.41-.77 4.38-2.312 5.909-1.541 1.528-3.518 2.292-5.929 2.292zm-16.7 17.49v-5.456c0-1.39.352-2.593 1.058-3.61a6.816 6.816 0 012.737-2.318c2.271-1.019 4.46-1.783 6.565-2.292a26.868 26.868 0 016.338-.764c2.156 0 4.277.263 6.362.789 2.086.526 4.255 1.282 6.507 2.267a6.519 6.519 0 012.805 2.29c.713 1.023 1.069 2.233 1.069 3.63v5.465H7.293z"
      ></path>
    </svg>
  );
}

export default UserRed;
