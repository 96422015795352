// src/redux/catalogs/states/contentsSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { contentContext, contentEmptyState } from "../emptyStates";

const emptyContent = {
  content: contentEmptyState,
  contentErrors: [],
  contentOptions: [],
  modified: false,
} as contentContext;

export const contentsSlice = createSlice({
  name: "content",
  initialState: emptyContent,
  reducers: {
    ModifyContent: (content, action) => ({
      ...content,
      ...action.payload,
      modified: true,
    }),
    NewContent: (_, action) => action.payload,
    InitializeContent: () => emptyContent,
  },
});

export const { NewContent, ModifyContent, InitializeContent } =
  contentsSlice.actions;
