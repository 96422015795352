import React from "react";

function Network() {
  return ( 

        <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 25 24"
        >
        
            <path 
            d="M.08 18.17v-1.5c0-.696.363-1.26 1.087-1.691.725-.432 1.672-.648 2.842-.648.151 0 .29.002.419.005.128.004.26.015.397.033a4.488 4.488 0 00-.462 2.004v1.797H.08zm6 0v-1.795c0-.561.149-1.075.445-1.54.296-.465.72-.869 1.273-1.212.552-.342 1.207-.602 1.966-.778a10.972 10.972 0 012.482-.264c.914 0 1.747.088 2.501.264.754.176 1.41.435 1.966.778.552.343.975.747 1.267 1.212.293.465.44.979.44 1.54v1.795H6.08zm14.052 0v-1.8a4.643 4.643 0 00-.432-1.999c.136-.02.27-.031.4-.035.13-.003.264-.005.4-.005 1.183 0 2.133.213 2.847.64.715.427 1.073.995 1.073 1.704v1.495h-4.288zM8.06 16.387h8.378v-.15c-.075-.57-.496-1.026-1.262-1.37-.767-.345-1.742-.518-2.927-.518s-2.16.175-2.927.524c-.766.35-1.187.813-1.262 1.39v.124zm-4.069-2.764c-.506 0-.94-.182-1.3-.546a1.8 1.8 0 01-.54-1.313c0-.514.18-.951.542-1.312.362-.36.798-.54 1.306-.54.512 0 .95.18 1.315.54.365.361.548.801.548 1.32 0 .504-.181.938-.544 1.303a1.8 1.8 0 01-1.327.548zm16.5 0c-.506 0-.94-.182-1.3-.546a1.8 1.8 0 01-.54-1.313c0-.514.18-.951.542-1.312.362-.36.798-.54 1.306-.54.512 0 .95.18 1.315.54.365.361.548.801.548 1.32 0 .504-.181.938-.544 1.303a1.8 1.8 0 01-1.327.548zm-8.235-1.778a3.063 3.063 0 01-2.25-.927 3.063 3.063 0 01-.927-2.25c0-.892.31-1.642.927-2.25.618-.608 1.368-.912 2.25-.912.892 0 1.643.304 2.25.912.609.608.913 1.358.913 2.25 0 .883-.304 1.633-.912 2.25-.608.618-1.359.927-2.251.927zm.009-1.783c.385 0 .712-.135.981-.407.27-.27.404-.603.404-.997 0-.393-.136-.722-.41-.987a1.362 1.362 0 00-.982-.397c-.395 0-.726.134-.994.4a1.34 1.34 0 00-.402.99c0 .387.133.716.399.99.265.272.6.408 1.004.408z"
            ></path>
       
        </svg> 
  );
}

export default Network;
