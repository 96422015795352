import React from "react";
import { IPerson } from "../../../interfaces";
import EmailIcon from "../../Atomos/Icons/EmailIcon";
import VerticalSeparator from "../../Atomos/Icons/VerticalSeparator";
import { Link } from "react-router-dom";

interface ShortPersonCardProps {
  person?: IPerson;
  className?: string;
  additionalInfo?: string;
}

const ShortPersonCard: React.FC<ShortPersonCardProps> = ({
  person,
  className,
  additionalInfo,
}) => {
  return (
    <React.Fragment>
      <div
        className={`${className} flex p-0 items-center gap-6 lg:gap-[.625rem] lg:flex-1 self-stretch  ${person?.isAttorney ? '' : ''} rounded-lg`}
      >
        <div className="flex lg:flex-col lg:items-center lg:gap-1">
          <div className="flex flex-col items-center gap-[0.625rem]">
            <div className="w-[5.5rem] h-[5.5rem] rounded-full bg-gray-100">
              <img
                src={person?.photo}
                className="w-full h-full bg-cover rounded-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1 self-stretch">
          <Link to={`/staff/profile/attorney/${(person?.name)?.trim()}-${(person?.lastName)?.trim()}/${person?.generatedId}`} className="text-Default lg:text-xl lg:leading-7 font-bold self-stretch whitespace-nowrap">
            {person?.name} {person?.lastName}
            {!person?.isAttorney && (
              <span className="text-sm font-normal"> {additionalInfo}</span>
            )}
          </Link>
          <span className="text-gray-font lg:text-[1.125rem] lg:leading-5 self-stretch whitespace-nowrap">
            {person?.title}
          </span>
          <div className="flex lg:items-start lg:gap-3 self-stretch">
            <span className="text-gray-font lg:text-[1.125rem] lg:leading-5 self-stretch whitespace-nowrap">
              {person?.phoneNumber}
            </span>
            <div className="w-0">
              <VerticalSeparator />
            </div>
            <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-Default">
              <div className="w-3 h-3">
                <EmailIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShortPersonCard;
