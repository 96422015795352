import React from "react";

function SendMail() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" >
     <path d="M1.098 13.569V2.422H14.91v11.147H1.098zM8 8.6L2.418 4.885v7.363h11.164V4.885L8 8.6zm0-1.216l5.515-3.634H2.501L8 7.385z"></path> 
    </svg>
  );
}

export default SendMail;
