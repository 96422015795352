function Success(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
      >
        <path
          d="M11.0808 16.7538L17.8038 10.0308L16.75 8.97693L11.0808 14.6462L8.23075 11.7962L7.17693 12.85L11.0808 16.7538ZM12.5016 22C11.1877 22 9.95268 21.7506 8.79655 21.252C7.6404 20.7533 6.63472 20.0765 5.7795 19.2217C4.92427 18.3669 4.24721 17.3616 3.74833 16.206C3.24944 15.0504 3 13.8156 3 12.5017C3 11.1877 3.24933 9.95268 3.748 8.79655C4.24667 7.6404 4.92342 6.63472 5.77825 5.7795C6.6331 4.92427 7.63834 4.24721 8.79398 3.74833C9.94959 3.24944 11.1844 3 12.4983 3C13.8122 3 15.0473 3.24933 16.2034 3.748C17.3596 4.24667 18.3652 4.92342 19.2205 5.77825C20.0757 6.6331 20.7527 7.63834 21.2516 8.79398C21.7505 9.94959 22 11.1844 22 12.4983C22 13.8122 21.7506 15.0473 21.252 16.2034C20.7533 17.3596 20.0765 18.3652 19.2217 19.2205C18.3669 20.0757 17.3616 20.7527 16.206 21.2516C15.0504 21.7505 13.8156 22 12.5016 22ZM12.5 20.5C14.7333 20.5 16.625 19.725 18.175 18.175C19.725 16.625 20.5 14.7333 20.5 12.5C20.5 10.2666 19.725 8.37498 18.175 6.82498C16.625 5.27498 14.7333 4.49998 12.5 4.49998C10.2666 4.49998 8.37498 5.27498 6.82498 6.82498C5.27498 8.37498 4.49998 10.2666 4.49998 12.5C4.49998 14.7333 5.27498 16.625 6.82498 18.175C8.37498 19.725 10.2666 20.5 12.5 20.5Z"
        />
      </svg>
    </div>
  );
}

export default Success;
