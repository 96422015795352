import { IAOPRanking } from "../interfaces/models/IAOPRanking";
import Axios from "../utils/axios";

const axios = new Axios();

export const getAOPRankings = async (): Promise<IAOPRanking[]> => {
  try {
    const response = await axios.Get('/aopRanking');
    return response.data;
  } catch (error) {
    console.error('Error fetching AOP Rankings:', error);
    return [];
  }
}

export const getAOPRankingById = async (id: string): Promise<IAOPRanking> => {
  try {
    const response = await axios.Get(`/aopRanking,`, `${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching AOP Ranking:', error);
    throw error;
  }
}

export const createAOPRanking = async (ranking: IAOPRanking): Promise<IAOPRanking> => {
  try {
    const response = await axios.Post('/aopRanking', ranking);
    return response.data;
  } catch (error) {
    console.error('Error creating AOP Ranking:', error);
    throw error;
  }
}

export const updateAOPRanking = async (ranking: IAOPRanking): Promise<IAOPRanking> => {
  try {
    const rankNames = ranking.ranks.map((r: any) => r.name)
    const newRanking = {
      ...ranking,
      rank1Name: rankNames?.[0],
      rank2Name: rankNames?.[1],
      rank3Name: rankNames?.[2],
      rank4Name: rankNames?.[3],
    }
    const response = await axios.Put('/aopRanking', newRanking);
    return response.data;
  } catch (error) {
    console.error('Error updating AOP Ranking:', error);
    throw error;
  }
}

export const deleteAOPRanking = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.Delete(`/aopRanking/`, `${id}`);
    return response.status === 200;
  } catch (error) {
    console.error('Error deleting AOP Ranking:', error);
    return false;
  }
}
