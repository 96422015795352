 
import { axios } from "..";
import { Option } from "../components/Atomos/DropDown/DDMultiple";
import { IState } from "../interfaces";

export const getState = async (id: string) => {
  const response = await axios.Get("state/id=" + id);
  const state: IState = response.data as IState;
  return state;
};

export const getStates = async () => {
  const states = await axios.Get("state");
  return states.data.map((state: IState) => ({
    id: state.id,
    name: state.name,
    abbreviation: state.abbreviation,
    country: state.country,
    UpdatedDate: state._ts,
  }));
};
export const saveState = async (state: IState) => {
  let errors = {};
  try {
    const response = await axios.Post("/State", state);

    if (response.status === 200) {
      if (response.data.error) {
        if (response.data.error.includes("Name")) {
          errors = {
            ...errors,
            name: {
              error: true,
              errorMsj: "Name already exists",
            },
          };
        } else if (response.data.error.includes("Abbreviation")) {
          errors = {
            ...errors,
            abbreviation: {
              error: true,
              errorMsj: "Abbreviation already exists",
            },
          };
        }

        return { errors, Done: false };
      } else {
        return { Done: true };
      }
    } else {
      console.error("Error creating element:", response.data);
    }
  } catch (error) {
    console.error("Error creating element:", error);
  }
};

//actualizar un estado
export const updateState = async (state: IState) => {
  const newstate = {  
    id: state.id,
    name: state.name,
    abbreviation: state.abbreviation,
    country: state.country,
    hasSubstate: state.hasSubstate,  
  }; 
  let errors = {};
  try {
    const response = await axios.Put("/State", newstate);
    if (response.status === 200) {
      if (response.data.error) {
        if (response.data.error.includes("Name")) {
          errors = {
            ...errors,
            name: {
              error: true,
              errorMsj: "Name already exists",
            },
          };
        } else if (response.data.error.includes("Abbreviation")) {
          errors = {
            ...errors,
            abbreviation: {
              error: true,
              errorMsj: "Abbreviation already exists",
            },
          };
        }

        return { Done: false, errors };
      } else {
        return { Done: true, errors: {} };
      }
    } else {
      console.error("Error creating element:", response.data);
      return { Done: false, errors: {} };
    }
  } catch (error) {
    console.error("Error creating element:", error);
    return { Done: false, errors: {} };
  }
};

//eliminar un estado
export const deleteState = async (id: string) => {
  const response = await axios.Delete(`/State`, id);
  if (response.status === 200) {
    return true;
  } else {
    console.error("Error al eliminar el elemento:", response.data);
    return false;
  }
};


 
//obtener los estados para mostrar en un dropdown o lista
export const getStatesListOptions = async (filter:any) => {
  console.log(filter); 
  const isObject = (val: any) => val && typeof val === 'object' && !Array.isArray(val);

  const filterData = filter ? isObject(filter) ? filter : JSON.parse(filter) : {};

  const StateOptionsFields = ["id", "name"];
  const response = await axios.Get(`/State?fields=${JSON.stringify(StateOptionsFields)}&filter=${JSON.stringify(filterData)}&count=false`);
  const states = response.data;
  return states?.map((state: Option) => ({
    id: state.id,
    name: state.name,
  }));
}

//obtener los estados por filtros
export const GetStatesByFilters = async (
  filters: any, 
  pagination: {pageNumber: number, pageSize: number}, 
  sorting: any, 
) => {
  try {

    const queryString = `/State?filter=${JSON.stringify(filters)}&pagination=${JSON.stringify({...pagination,pageNumber:pagination.pageNumber-1})}&sorting=${JSON.stringify(sorting)}&count=false`;
    
    const response = await axios.Get(queryString);
     return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return []
  }
};

//obtener el conteo total de estados
export const GetTotalStates = async () => {
  try {
    const response = await axios.Get('/State?count=true');
    const data = response.data;
    if (data != null) { 
     return data;
    }
  } catch (error) {
    console.error('Error fetching total count:', error);
  }
};
