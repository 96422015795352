import React from "react";

function BlueCalendar() {
  return ( 
      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 24" >
        <path 
          d="M11.665 14.085v-2.17h2.17v2.17h-2.17zm-4 0v-2.17h2.17v2.17h-2.17zm8 0v-2.17h2.17v2.17h-2.17zm-4 4v-2.17h2.17v2.17h-2.17zm-4 0v-2.17h2.17v2.17h-2.17zm8 0v-2.17h2.17v2.17h-2.17zM3.397 22.353V3.134h3.48v-1.5H8.74v1.5h8.02v-1.5h1.868v1.5h3.49v19.22H3.396zm1.98-1.98h14.746V9.75H5.377v10.623zm0-12.123h14.746V5.127H5.377V8.25z"
        ></path>
      </svg> 
  );
}

export default BlueCalendar;
