import React from "react";

function DarkSearch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 50 50"
    > 
          <path 
            d="M39.144 43.074L26.346 30.276c-.981.725-2.117 1.302-3.408 1.73-1.29.43-2.678.644-4.164.644-3.803 0-7.029-1.324-9.677-3.973C6.448 26.03 5.124 22.803 5.124 19s1.324-7.029 3.973-9.677c2.648-2.649 5.874-3.973 9.677-3.973s7.029 1.324 9.677 3.973C31.1 11.97 32.424 15.197 32.424 19c0 1.504-.214 2.892-.643 4.164-.43 1.272-1.006 2.389-1.73 3.351L42.875 39.37l-3.732 3.704zM18.774 27.35c2.33 0 4.305-.81 5.923-2.427 1.618-1.618 2.427-3.593 2.427-5.923s-.81-4.305-2.427-5.923c-1.618-1.618-3.592-2.427-5.923-2.427-2.33 0-4.305.81-5.923 2.427-1.618 1.618-2.427 3.593-2.427 5.923s.81 4.305 2.427 5.923c1.618 1.618 3.593 2.427 5.923 2.427z"
          ></path>
        
     </svg>
  );
}

export default DarkSearch;
