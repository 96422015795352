
import React from 'react'
import css from './Footer.module.css'

import facebook from '../../../assets/images&icons/fb.svg'
import linkedin from '../../../assets/images&icons/ln.svg'
import RedWorldDark from '../Logo/RedWorldDark'
import XIcon from '../../Atomos/Icons/ViewMode/X'

const HOME_LINK = process.env.NEXT_HOME_LINK
const SITE_MAP_LINK = process.env.NEXT_SITE_MAP_LINK
const PRIVACY_POLICY_LINK = process.env.NEXT_PRIVACY_POLICY_LINK
const DISCLAIMER_LINK = process.env.NEXT_DISCLAIMER_LINK
const CONTACT_LINK = process.env.NEXT_CONTACT_LINK


export default function Footer() {
  return (
    <div className="main-footer w-full  lg:flex bg-white h-auto bottom-0 xl:px-6 px-[0.31rem] lg:px-[0.94rem] pb-[3.3rem] pt-[4.38rem] md:pb-[4.2rem] md:pt-[5.62rem] relative">
      <div className="logo-container lg:pb-0 pb-[1.88rem] md:w-1/4 lg:w-1/4 xl:w-1/6 lg:pr-[2.13rem] xl:[pr-3.06rem] w-1/2 flex m-auto align-middle justify-center lg:justify-start">
        <RedWorldDark />
      </div>
      <div className='w-full mx-auto px-[1.75rem] pb-[0.6rem] md:flex md:flex-col md:justify-center md:items-center'>
        <ul className={`w-full lg:w-9/12 lg:pb-2 xl:pb-0 flex gap-x-4 lg:gap-x-[2.24rem] gap-y-[.6rem] whitespace-nowrap flex-wrap justify-center text-primary roboto_font uppercase font-medium`}>
          <li className='lg:w-fit'><a href={HOME_LINK}>Home</a></li>
          <li className='lg:w-fit'><a href={SITE_MAP_LINK}>Site Map</a></li>
          <li className='lg:w-fit'><a href={PRIVACY_POLICY_LINK}>Privacy Policy</a></li>
          <li className='lg:w-fit'><a href={DISCLAIMER_LINK}>Disclaimer</a></li>
          <li className='lg:w-fit'><a href={CONTACT_LINK}>Contact</a></li>
        </ul>
        <div className='mx-auto'>
          <p className="roboto_font text-[.9375rem] text-neutral-dark md:w-10/12 xl:w-full mx-auto pt-[1.23rem] xl:pt-[.93rem]"> 
            WSG´s members are independent firms and are not affiliated in the joint practice of professional services. <br />
            Each member exercises its own individual judgments on all client matters.</p>
          <p className="pt-[1.37rem] roboto_font text-[.937rem] text-neutral-dark">
            © 2023, World Services Group
          </p>
        </div>
      </div>
      <div className="social-media lg:w-1/4 flex lg:justify-end justify-center items-center pt-[1.25rem] lg:pt-0">
        <img className='mx-1 md:w-10 w-12' src={facebook} alt='wsg_facebook_social'/>
        <div className='w-[3rem] h-[3rem] md:w-[2.5rem] md:h-[2.5rem] bg-primary px-[.73rem] flex items-center justify-center pt-[.75rem] pb-[.62rem] rounded-full fill-white relative z-20'>
          <XIcon />
        </div>
        <img className='mx-1 md:w-10 w-12' src={linkedin} alt='wsg_linkedin_social'/>
      </div>
    </div>
  )
}
