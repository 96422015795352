import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const countriesSlice = createSlice({
  name: "countries",
  initialState: Empty,
  reducers: {
    SetCountries: (_, action) =>  action.payload,
  },
});

export const { SetCountries } = countriesSlice.actions;
