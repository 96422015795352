import React, { useEffect, useState } from 'react';
import Axios from '../../utils/axios';
import ContentGeneralTopicFilter from '../../components/Catalogs/Filters/ContentGeneralTopicFilter';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import { createColumnHelper } from '@tanstack/react-table';
import { useTableSettings } from '../../hooks/Table/UseTableSettings';
import useDataLoader2 from '../../hooks/useDataLoader2';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { formatTimeSpanToString } from '../../utils/functions';

const ContentTag: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', '_ts'];
  const DefaultPageSize = 50;
  const [filterName, setFilterName] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = ['id', 'name', '_ts']
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/contentTag',
    defaultPageSize: DefaultPageSize,
    fields
  });
  
  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);

  const clearSelections = () => {
    setFilterName('');
    fetchData(true)
  };

  const handleSetFilterName = (name: string) => {
    setFilterName(name);
  };

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    { id: '_ts', header: 'Created Date' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case '_ts':
          const value = props.getValue();
          return (
            <p>
              {value ? formatTimeSpanToString(value) : ""}
            </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    setFilter({name: {value: filterName, comparisonType: 'CONTAINS'}})
  }, [filterName]);

  return (
    <div className="mainSection">
      <CatalogTableC
        title={`Content Tag Catalog`}
        catalogName={'TAGS'}
        route={'PublicationTag'}
        catalogShortName={'TAG'}
        isfiltered={true}
        data={data}
        headers={Headers}
        totalCount={totalCount}
        exportData={data}
      >
        <ContentGeneralTopicFilter
          key={0}
          Search={fetchData}
          name={filterName}
          setName={handleSetFilterName}
          headers={Headers}
          clearSelections={clearSelections}
          tableInstance={tableInstance}
        />
        <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
      </CatalogTableC>
    </div>
  );
};

export default ContentTag;
