import React from "react";

function TriangleDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
    >
      <path d="M12 15.339L6.42 9.785h11.16L12 15.339z"></path>
    </svg>
  );
}

export default TriangleDown;
