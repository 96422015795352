import { useEffect, useRef, useState } from "react";

interface SVGProps {
  practiceId: string;
  practiceName?: string;
  iconUrl?: string;
  isSelected?: boolean;
}

const SvgIcon: React.FC<SVGProps> = ({ practiceId, iconUrl, isSelected }) => {
  const [svgElement, setSvgElement] = useState<SVGSVGElement | null>(null);
  const svgContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const storedIconBase64 = localStorage.getItem(`practiceIndustryIcon_${practiceId}`);

    if (storedIconBase64) {
      formatIcons(storedIconBase64);
    } else {
      fetchAndStoreIcon();
    }
  }, [practiceId, iconUrl]);

  const fetchAndStoreIcon = async () => {
    try {
      if (!iconUrl) return;
      const response = await fetch(iconUrl);

      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          localStorage.setItem(`practiceIndustryIcon_${practiceId}`, reader.result);
          formatIcons(reader.result);
        } else {
          console.error('Conversion to Base64 did not yield a valid string.');
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading blob as Base64:', error);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error al descargar el ícono:', error);
    }
  };

  const formatIcons = (iconBase64: string) => {
    const svgContent = atob(iconBase64.split(',')[1]);
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');
    const svg = doc.querySelector('svg');

    if (svg) {
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');
      setSvgElement(svg);
    }
  };

  useEffect(() => {
    if (svgElement && svgContainerRef.current) {
      svgContainerRef.current.innerHTML = '';
      svgContainerRef.current.appendChild(svgElement);

      applySelectedFill();
    }
  }, [svgElement, isSelected]); 

  useEffect(() => {
    if (svgElement && svgContainerRef.current) {
      const paths = svgContainerRef.current.querySelectorAll('path');
      paths?.forEach((path: any) => {
        const originalFill = path.getAttribute('fill');
        if (originalFill) {
          path.setAttribute('data-original-fill', originalFill);
        }
      });
    }
  }, [svgElement]); 

  const applySelectedFill = () => {
    const paths = svgContainerRef.current?.querySelectorAll('path');
    paths?.forEach(path => {
      const originalFill = path.getAttribute('data-original-fill');
      const originalStroke = path.getAttribute('stroke');
      if (isSelected) {
        if (originalFill === '#A2A9AD') {
          path.setAttribute('fill', '#903B2C');
        } else if (originalStroke === '#A2A9AD') {
          path.setAttribute('stroke', '#903B2C');
        }
        if (originalFill === '#4A4F54') {
          path.setAttribute('fill', '#BC3323');
        }
        else if (originalStroke === '#4A4F54') {
          path.setAttribute('stroke', '#BC3323');
        }
      } else {
        if (originalFill) {
          path.setAttribute('fill', originalFill);
        }
        if (originalStroke === '#903B2C') {
          path.setAttribute('stroke', '#A2A9AD');
        } else if (originalStroke === '#BC3323') {
          path.setAttribute('stroke', '#4A4F54');
        }
      }
    });
  };

  const handleMouseEnter = () => {
    if (!svgContainerRef.current || isSelected) return;
    const paths = svgContainerRef.current.querySelectorAll('path');
    paths.forEach(path => {
      const originalFill = path.getAttribute('data-original-fill');
      const originalStroke = path.getAttribute('stroke');
      if (originalFill === '#A2A9AD') {
        path.setAttribute('fill', '#903B2C');
      } else if (originalStroke === '#A2A9AD') {
        path.setAttribute('stroke', '#903B2C');
      }
      if (originalFill === '#4A4F54') {
        path.setAttribute('fill', '#BC3323');
      } else if (originalStroke === '#4A4F54') {
        path.setAttribute('stroke', '#BC3323');
      }
    });
  };

  const handleMouseLeave = () => {
    if (!svgContainerRef.current || isSelected) return;
    const paths = svgContainerRef.current.querySelectorAll('path');
    paths.forEach(path => {
      const originalFill = path.getAttribute('data-original-fill');
      const originalStroke = path.getAttribute('stroke');
      if (originalFill) {
        path.setAttribute('fill', originalFill);
      } else if (originalStroke === '#903B2C') {
        path.setAttribute('stroke', '#A2A9AD');
      } else if (originalStroke === '#BC3323') {
        path.setAttribute('stroke', '#4A4F54');
      }
    });
  };

  return (
    <div
      ref={svgContainerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    ></div>
  );
};

export default SvgIcon;
