import React from "react";

function VerticalSeparator() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 2 20"
      className="w-full h-full"
    >
      <path stroke="#EBEBEB" d="M1 0v20"></path>
    </svg>
  );
}

export default VerticalSeparator;
