import React, { useEffect, useState } from 'react'; 
import HeaderTitle from './HeaderTitle';
import { PersonOfficeProps } from '../../../interfaces/models/IPerson';
import WSGCheckbox from '../../Atomos/Checkbox/WSGCheckBox';

type PersonOfficePropsEdid = {
  offices: any[];
  handleAddAdditionalOffices: (newAdditionalOffices: string[]) => void;
  mainOffice: string;
} & PersonOfficeProps;

type OfficeList = {
  id: string;
  name: string;
  address: string;
};

const PersonOfficeEdit: React.FC<PersonOfficePropsEdid> = (props: PersonOfficePropsEdid) => {
  const [selectedOffices, setSelectedOffices] = useState<OfficeList[]>([]);
  const additionalOffices = props.offices;

  useEffect(() => {
    setSelectedOffices([]);

    const currentAdditionalOffices: OfficeList[] = props.additionalOffices?.map((officeId: string) => {
      const foundOffice = props.offices.find((office) => office && office.id === officeId);
      return foundOffice ? { ...foundOffice } : null;
    });

    setSelectedOffices(currentAdditionalOffices?.filter((office) => office !== null));
  }, [props.offices]);

  const handleOfficeschange = (index: number) => {
    setSelectedOffices((prevSelectedOfficeIds) => {
      const officeId = props.offices[index];

      if (Array.isArray(prevSelectedOfficeIds)) {
        if (prevSelectedOfficeIds.some((selectedOffice) => selectedOffice.id === officeId.id)) {


          props.handleAddAdditionalOffices(prevSelectedOfficeIds.map((selectedOffice) => selectedOffice.id).filter((id) => id !== officeId.id));

          return prevSelectedOfficeIds.filter((selectedOffice) => selectedOffice.id !== officeId.id);
        }

        props.handleAddAdditionalOffices([...prevSelectedOfficeIds.map((selectedOffice) => selectedOffice.id), officeId.id]);

        return [...prevSelectedOfficeIds, officeId];
      }
      props.handleAddAdditionalOffices([officeId.id]);

      return [officeId];
    });
  };

  return (
    <article className='flex flex-col gap-8 w-full'>
      <HeaderTitle title={'Office Locations'} />
      <section>
        <div className=" flex flex-col justify-start gap-2.5">
          <div className=" flex justify-start">
            <h6 className="text-[1.375rem] not-italic font-bold leading-6 text-gray-font">
              Additional Offices
            </h6>
          </div>
          <div className="grid md:grid-cols-3 xl:grid-cols-5 gap-6">
            {additionalOffices.map((office, index) => (
              <WSGCheckbox 
                disable={office.id === props.mainOffice}
                key={office.id}
                index={index}
                text={office.name + '\n' + office.address}
                checked={office.id === props.mainOffice || selectedOffices?.some((selectedOffice) => selectedOffice.id === office.id)}
                handleCheckboxChange={() => handleOfficeschange(index)}
              />
            ))}
          </div>
        </div>
      </section>
    </article>
  );
};

export default PersonOfficeEdit;
