
import { useEffect, useState } from "react";
import SortAsending from "../components/Atomos/Icons/SortAsending";
import SortDecending from "../components/Atomos/Icons/SortDecending";
import SortDefault from "../components/Atomos/Icons/SortDefault";
import { table } from "console";

 

const useViewport = () => { 
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => {
        setViewportWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => { window.removeEventListener('resize', handleResize); };
    }, []);
    return { viewportWidth };
}

export default useViewport;