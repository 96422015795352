function Eye2(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 21 20" 
      >
        <path
          d="M10.6671 12.9807C11.6113 12.9807 12.4133 12.6502 13.073 11.9893C13.7327 11.3283 14.0626 10.5257 14.0626 9.58142C14.0626 8.63718 13.7321 7.8352 13.0711 7.17548C12.4102 6.51576 11.6075 6.1859 10.6633 6.1859C9.71905 6.1859 8.91707 6.51638 8.25735 7.17735C7.59763 7.83834 7.26777 8.64096 7.26777 9.58521C7.26777 10.5294 7.59825 11.3314 8.25922 11.9911C8.92021 12.6509 9.72283 12.9807 10.6671 12.9807ZM10.6652 11.8333C10.0402 11.8333 9.50893 11.6146 9.07143 11.1771C8.63393 10.7396 8.41518 10.2083 8.41518 9.58331C8.41518 8.95831 8.63393 8.42706 9.07143 7.98956C9.50893 7.55206 10.0402 7.33331 10.6652 7.33331C11.2902 7.33331 11.8214 7.55206 12.2589 7.98956C12.6964 8.42706 12.9152 8.95831 12.9152 9.58331C12.9152 10.2083 12.6964 10.7396 12.2589 11.1771C11.8214 11.6146 11.2902 11.8333 10.6652 11.8333ZM10.6663 15.4166C8.74998 15.4166 7.00388 14.888 5.42802 13.8309C3.85217 12.7737 2.69192 11.3579 1.94727 9.58331C2.69192 7.80874 3.85179 6.39288 5.42689 5.33573C7.00197 4.27858 8.74769 3.75 10.664 3.75C12.5804 3.75 14.3265 4.27858 15.9023 5.33573C17.4782 6.39288 18.6384 7.80874 19.3831 9.58331C18.6384 11.3579 17.4786 12.7737 15.9035 13.8309C14.3284 14.888 12.5827 15.4166 10.6663 15.4166ZM10.6652 14.1666C12.2346 14.1666 13.6756 13.7535 14.9881 12.9271C16.3006 12.1007 17.3041 10.9861 17.9985 9.58331C17.3041 8.18054 16.3006 7.06595 14.9881 6.23956C13.6756 5.41317 12.2346 4.99998 10.6652 4.99998C9.09574 4.99998 7.65477 5.41317 6.34227 6.23956C5.02977 7.06595 4.02629 8.18054 3.33185 9.58331C4.02629 10.9861 5.02977 12.1007 6.34227 12.9271C7.65477 13.7535 9.09574 14.1666 10.6652 14.1666Z"
        />
      </svg>
    </div>
  );
}

export default Eye2;
