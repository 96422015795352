import React, { useContext, useEffect, useState, ReactNode } from 'react';
import AuthContext from '../../store/AuthContext';
interface AuthCheckerProps {
  children: ReactNode;
}

const AuthChecker: React.FC<AuthCheckerProps> = ({ children }) => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [checkingAuth, setCheckingAuth] = useState(true);

  const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token') || getCookie('token');
    const authorizationHeader = 'Bearer ' + token;

    if (authorizationHeader.startsWith('Bearer ') && token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    setCheckingAuth(false);
  }, [setIsAuthenticated]);

  if (checkingAuth) {
    return <div>Cargando...</div>;
  }

  return <>{children}</>;
};

export default AuthChecker;
