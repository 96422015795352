import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import RadioButton from "../../../components/Atomos/Radio/RadioButton";
import EditFirm from "../../../components/Catalogs/FirmContact/EditFirm";
import EditMembership from "../../../components/Catalogs/FirmContact/EditMembership";
import { Tab } from "@headlessui/react";
import Jurisdiction from "../Jurisdiction";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";

interface Jurisdiction {
  id: string;
  name: string;
}

const AddFirmContact: React.FC = () => {
  const axios = new Axios();
  const { id } = useParams();
  const { state } = useLocation();
  const [selectedOption, setSelectedOption] = useState(state?.level ? 0 : 1);
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>([]);
  const [buttonClicked, setButtonClicked] = useState('');
  const [selectedTab, setSelectedTab] = useState(state?.jurisdictions ? state?.jurisdictions[0]?.jurisdiction : "");
  const checkEditType = (index: number) => {
    setSelectedOption(index);
  };
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorJurisdiction, setErrorJurisdictions] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [update, setUpdate] = useState(false)

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }

  useEffect(() => {
    console.log("errorFields", errorFields)
    if (errorFields.length > 0) {

      setSaveOverrideOpen(true)
    }
    if (errorFields.length === 0 && errorJurisdiction.length === 0) {
      setSaveOverrideOpen(false)
    }
  }, [errorFields]);

  useEffect(() => {
    console.log("errorJurisdiction", errorJurisdiction)
    if (errorJurisdiction.length > 0) {
      setSaveOverrideOpen(true)
    }
    if (errorJurisdiction.length === 0 && errorFields.length === 0) {
      setSaveOverrideOpen(false)
    }
  }, [errorJurisdiction]);

  useEffect(() => {
    const GetJurisdictions = async () => {
      try {
        const response = await axios.Get(`/jurisdiction`);
        const data = response.data;
        if (data != null) {
          setJurisdictions(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetJurisdictions();

  }, []);

  const handleTabValue = (jurisdiction: any) => {
    setSelectedTab(jurisdiction);
  };

  return (
    <>
      <div className="mainSection">
        <SaveOverride fields={[...errorFields, ...errorJurisdiction]} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={handleCloseSaveOverride} />
        <CatalogTitle title={`Edit ${state?.firmName} Contacts`} className="text-Default">
          <GoBack/>
        </CatalogTitle>

        <section className="Contacts Edit">
          <div className="flex h-auto w-full gap-5 flex-col p-10 bg-white">
            <div className="flex flex-col lg:flex-row lg:items-center w-full gap-5 bg-white">
              <span className="text-gray-font not-italic leading-6 font-bold text-[22px]">
                Firm Contact Structure:
              </span>
              <RadioButton
                index={0}
                text={"Contacts defined Firm Wide"}
                selected={selectedOption === 0}
                handleRadioButtonChange={checkEditType}
                disabled={state?.firmMembershipType === "Primary"}
              />
              <RadioButton
                index={1}
                text={"Contacts defined by Membership Locations"}
                selected={selectedOption === 1}
                handleRadioButtonChange={checkEditType}
                disabled={state?.firmMembershipType === "Primary"}
              />
            </div>

            {selectedOption === 0 && <EditFirm setErrorFields={setErrorFields} buttonClicked={buttonClicked} update={update} setUpdate={setUpdate} />}
            {selectedOption === 1 && (
              <>
                <Tab.Group>
                  <Tab.List className={"grid lg:gap-0 lg:grid-cols-6"}>
                    {state?.jurisdictions.map(
                      (jurisdiction: any, index: number) => {
                        return (
                          <Tab
                            key={index}
                            onClick={() =>
                              handleTabValue(jurisdiction.jurisdiction)
                            }
                            className={` justify-center items-center col-span-1 bg-[#E5E3E4] max-h-[190px] text-gray-font ui-selected:text-neutral p-3 pr-2 ui-selected:bg-accent-Default-dark border-none rounded-none focus:border-none`}
                          >
                            <span
                              className={` not-italic text-lg leading-8 font-normal`}
                            >
                              {jurisdictions.find(
                                (jur: any) =>
                                  jur.id === jurisdiction.jurisdiction
                              )?.name ?? "undefined"}
                            </span>
                          </Tab>
                        );
                      }
                    )}
                  </Tab.List>
                </Tab.Group>
                <EditMembership
                  jurisdictionId={selectedTab}
                  jurisdictionName={
                    jurisdictions.find((jur: any) => jur.id === selectedTab)
                      ?.name
                  }
                  jurisdictionObjs={state?.jurisdictionsObjects}
                  setErrorFields={setErrorFields}
                  setErrorJurisdiction={setErrorJurisdictions}
                  buttonClicked={buttonClicked}
                />
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default AddFirmContact;
