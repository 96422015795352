import { IJurisdiction } from "../../interfaces";

export const JurisdictionEmpty: IJurisdiction = {
    name: "",
    nickname: "",
    abbreviation: "",
    country: "",
    state: "",
    substate: "",
    hasSubstate: false,
    isSecondary: false,
    partOfMulti: false,
    multiType: "",
    jurisdictionGroup: "",
};