import { Link, useLocation } from "react-router-dom";
import MenuGT from "../GeneralTab/Menu";
import CatalogTableC from "./AopManagementTable";
import TableWithPagination from "../../../components/Catalogs/Tables/TableWithPagination/TableWithPagination";
import { useTableSettings } from "../../../hooks/Table/UseTableSettings";
import useDataLoader from "../../../hooks/useDataLoader";
import Eye from "../../../components/Atomos/Icons/ViewMode/Eye";
import { ColumnConfig } from "../../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { useColumnConfig } from "../../../hooks/Table/UseColumnSettings";
import { useEffect, useMemo, useState } from "react";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from '../../../assets/images&icons/profileViewMode.jpeg';
import { Option } from "../../../components/Atomos/DropDown/DDMultiple";
import { UsePracticeIndustryList } from "../../../hooks/Catalogs/usePracticeIndustry";
import AopManagementFilter from "./AopManagementFilter";
import { transformData } from "../../../hooks/Table/TransformColumnsForExport";
import IOffice from "../../../interfaces/models/IOffice";
import { getOfficesByFirmId } from "../../../Services/Firm";
import { IAreaOfPractice } from "../../../interfaces/models/IAreaOfPractice";
import { useDispatch } from 'react-redux';
import Axios from "../../../utils/axios";

const axios = new Axios();

const AopManagement = () => {
  const dispatch = useDispatch();
  const DefaultPageSize = 150;
  const { state } = useLocation();
  const Headers: string[] = ["name", "professionals"];
  const {
    sort,
    setSort,
    currentPage,
    setCurrentPage,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    setFilter,
    queryParams
  } = useTableSettings({ defaultPageSize: DefaultPageSize });
  const { generateColumns } = useColumnConfig(Headers);
  const [tableInstance, setTableInstance] = useState<any>();
  const [nameFilter, setNameFilter] = useState('');
  const [selectedPracticeIndustry, setSelectedPracticeIndustry] = useState<Option[]>([]);
  const { practiceIndustryData: practiceIndustries } = UsePracticeIndustryList();
  const [firmOffices, setFirmOffices] = useState<IOffice[]>([]);
  const [aopFiltered, setAopFiltered] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<number | string>(0);
  const [allPersons, setAllPersons] = useState<any[]>([]);
  const { data } = useDataLoader<any>({
    endpoint: `/areaOfPractice`,
    queryParams,
    countOnly: true
  });


  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Area Of Practice' },
    { id: 'professionals', header: '# Of Professionals' },
    {
      id: 'edit',
      header: 'Actions',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`professionals/${props.row.original.id}`}
          state={{
            name: props.row.original.name,
            firm: state,
            filteredPersons: filterProfessionalsByAopAndOffice(props.row.original.name),
            selectedTab: selectedTab == 0 ? selectedTab : selectedTab.toString(),
          }}
        >
          View Professionals
          <div className="editsvg"><Eye /></div>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getAllPersons();
  }, []);

  const getAllPersons = async () => {
    const persons = await axios.Get('/person');
    if (persons && persons.data) {
      setAllPersons(persons.data);
    }
  };

  const filterProfessionalsByAopAndOffice = (aopName: string) => {
    if (allPersons && allPersons.length > 0) {
      if (selectedTab !== 0) {
        return allPersons.filter(person =>
          person.areasOfPractice?.some((aop: any) => aop.name === aopName) &&
          person.mainOffice === selectedTab.toString() &&
          person.firmId === state.firm?.id
        );
      } else {
        return allPersons.filter(person =>
          person.areasOfPractice?.some((aop: any) => aop.name === aopName) &&
          person.firmId === state.firm?.id &&
          firmOffices.some(office => office.id === person.mainOffice)
        );
      }
    }
    return [];
  };
  

  const getProfessionalCountForAopAndOffice = (aopName: string) => {
    const filteredProfessionals = filterProfessionalsByAopAndOffice(aopName);
    return filteredProfessionals.length;
  };

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + (currentPage - 1) * recordsPerPage}</p>;
        case 'professionals':
          const aopName = props.row.original.name;
          const professionalCount = getProfessionalCountForAopAndOffice(aopName);
          return <p>{professionalCount}</p>;
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const SubmitFilters = () => {
    setFilter([
      { field: 'name', value: nameFilter },
      { field: 'practiceIndustries', value: selectedPracticeIndustry.map(pi => pi.id).join(':') },
    ]);
  };

  const clearSelections = () => {
    setSelectedPracticeIndustry([]);
    setNameFilter('');
    setFilter([]);
    setCurrentPage(1);
  };

  const practiceIndustriesAsFilterProps: Option[] = useMemo(() => {
    return practiceIndustries
      .filter(pi => pi != null && typeof pi.name === 'string')
      .map(pi => ({ id: pi.id, name: pi.name } as Option));
  }, [practiceIndustries]);

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const memoizedData = useMemo(() => aopFiltered, [aopFiltered]);
  const transformedData = transformData(memoizedData ? memoizedData : [], columnConfigs, currentPage, recordsPerPage);

  const getFirmOffices = async (id: string) => {
    const data: any = await getOfficesByFirmId(id);
    if (data) {
      const updatedData = data.map((office: any) => ({
        ...office,
        action: () => {
          setSelectedTab(office.id);
        }
      }));
      setFirmOffices(updatedData);
    }
  };

  useEffect(() => {
    if (state && state.id) {
      getFirmOffices(state.id);
    }
  }, [state]);

  const filteredData = (by: string) => {
    if (data && data.length > 0 && state) {
      if (by === 'firm') {
        setAopFiltered(data.filter(aop => aop.firms?.some((firm: any) => firm?.id === state.id)));
      } else if (by === 'offices') {
        setAopFiltered(data.filter((aop: IAreaOfPractice) => aop.offices?.some((o: any) => o.id === selectedTab)));
      }
    }
  };

  useEffect(() => {
    if (data && data.length > 0 && selectedTab === 0) {
      filteredData('firm');
    }
  }, [state, data]);

  useEffect(() => {
    if (data && selectedTab !== undefined) {
      if (selectedTab === 0) {
        filteredData('firm');
      } else {
        filteredData('offices');
      }
    }
  }, [selectedTab, data]);

  const firmWideObject = {
    name: 'Firm Wide',
    id: 0,
    action: () => {
      setSelectedTab(0);
    },
    countryCodePhone: '',
    countryCodeFax: '',
    faxNumber: '',
    yearFounded: 0,
    jurisdiction: '',
    jurisdictionGroup: '',
    countOfPeople: 0,
    officeType: '',
    address: '',
    firm: '',
    city: '',
    phoneNumber: '',
    digPhoneNumber: '',
    codePhoneNumber: '',
    overview: ''
  };

  const currentTableCount = memoizedData ? memoizedData.length : 0;

  return (
    <>
      <MenuGT state={state}/>
      <BackgroundComponent image={ProfileViewModeBg} />
      <div className=" mainSection">
        <CatalogTableC
          title={`${state?.name}`}
          catalogName={"FIRMS"}
          route={"Firm"}
          catalogShortName={"FIRM"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={currentTableCount}
          exportData={transformedData}
          firmOffices={firmOffices ? [firmWideObject, ...firmOffices] : []}
          selectedTab={selectedTab}
          linkToAdd="/staff/manageFirm/aopManagement/update"
          currentState={state ? state : null}
          areasOfPractice={aopFiltered}
        >
          <AopManagementFilter
            order={['name', 'practiceIndustries']}
            search={SubmitFilters}
            resetFilters={clearSelections}
            headers={['name', 'practiceIndustries']}
            practiceIndustriesData={practiceIndustriesAsFilterProps}
            selectedPracticeIndustries={selectedPracticeIndustry}
            setSelectedPracticeIndustries={setSelectedPracticeIndustry}
            nameFilter={nameFilter}
            onNameFilterChange={handleNameFilterChange}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={memoizedData ? memoizedData : []}
            columns={columns}
            totalCount={currentTableCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordsPerPage={recordsPerPage}
            setRecordsPerPage={setRecordsPerPage}
            setTableInstance={setTableInstance}
            sort={sort}
            setSort={setSort}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default AopManagement;
