import React,{ useEffect} from "react";
import PersonDataEdit from "../../../components/Moleculas/PersonCatalog/PersonDataEdit"; 
import {  useLocation, useNavigate } from "react-router-dom"; 
import PersonAOPEdit from "../../../components/Moleculas/PersonCatalog/PersonAOPEdit"; 
import PersonRelations from "../../../components/Moleculas/PersonCatalog/PersonRelations"; 
import usePersonAdd from "../../../hooks/Catalogs/usePersonAdd";
import { PersonCatalogsProps } from "../../../interfaces/models/IPerson"; 
import '../../../assets/css/AddCatalogs.css'
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle"; 
import SaveOverride from "../../../components/Catalogs/SaveOverride";  
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle"; 
import ButtonsCrudWithDropdown from "../../../components/Catalogs/ButtonsCrudWithDropdown";
import { useAppContext } from "../../../Context";
import Input from "../../../components/Atomos/Inputs/Input"; 
import DropDown from "../../../components/Atomos/DropDown/Dropdown";  
import { axios } from "../../..";

const AddContentPerson: React.FC<PersonCatalogsProps> = (props: PersonCatalogsProps) => {
const { state } = useLocation(); 
const [saveOverrideOpen, setSaveOverrideOpen] = React.useState(true);
const [errorFields, seterrorFields] = React.useState<string[]>([]);
const { personState, personErrors, offices ,handleMultipleSelectChange,handleInputChange, handleCheckBoxChange,handleRadioButtonChange, handlePhoneNumberChange,  handlerAreasOfPractice, handleSubmit, handleDelete,handleButtonClick,setPersonErrors, setRegularPhoto } = usePersonAdd(state ? state : {});
const navigate = useNavigate();
const [user,setUser] = React.useState({
    hasUser:false,
    userName:"",
    accessType:"",
});
const [userError, setUserError] = React.useState({ error:false , errorMsj:""});


useEffect(()=>{
    setUser((currentUser)=>({...currentUser ,userName:(personState?.name?.slice(0,1) ?? "" ) + (personState?.lastName  ?? "" )})) 
},[personState?.name,personState?.lastName])

useEffect(()=>{
    if(errorFields.length<=0){
    setSaveOverrideOpen(false)
    setUserError({error:false , errorMsj:""})
}
},[errorFields])

// check if error open saveOverrideOpen
useEffect(
  ()=>{
    if(Object.values(personErrors).some((error) => {
      return error.error === true;
    }
    ))
    {
      setSaveOverrideOpen(true);
    }

    const fieldsWithError: string[] = Object.entries(personErrors)
    .filter(([key, value]) => value.error)
    .map(([key]) => {
      // Convertir la primera letra a mayúscula
      let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
      // Separar el nombre del campo en cada letra mayúscula adicional
      formattedKey = formattedKey.replace(/([A-Z])/g, ' $1');
      return formattedKey;
  });

    seterrorFields(fieldsWithError); 

    if(fieldsWithError.length === 0)
    {
      setSaveOverrideOpen(false);
    }

  },[personErrors]
)

const onSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
e.preventDefault()
//validar errores de person y de User
if(user.hasUser && user.accessType === ""){
    setUserError({ error:true , errorMsj:"Please select an Access Type"}) 
    seterrorFields((currentErrorFields)=>([...currentErrorFields,"Access Type"])) 
    setSaveOverrideOpen(true)
    return
}
 
await handleSubmit(e, state.contentRedirectUrl)

if(user.hasUser && errorFields.length <= 0)
{
    const saveUser = ()=>{
       return axios.Post(
            '/user',
            {
                fullname:personState.name + " " + personState.middleInitial + ". " +personState.lastName,
                nickName:user.userName,
                password:"gsW@4321",
                access:user.accessType,
                email:personState.firmEmail
            }
        )
    }  


    await saveUser()

    navigate(-1)
}


}

const {mode} = useAppContext();

  return (
    <main className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}>
      <form onSubmit={onSubmit}>
        <SaveOverride
          fields={errorFields}
          handleButtonClick={handleButtonClick}
          open={saveOverrideOpen}
          close={() => setSaveOverrideOpen(false)}
        />

        <CatalogTitle
          title={`${props.mode === "add" ? "New Person" : "Edit " + (state.name != null ? state.name || "Edit none" : "Loading...")}`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>

        <section className="articleSection">
          <PersonRelations
            mode={props.mode}
            isContentRedirect={!!state.contentRedirectUrl}
            {...personState}
            Errors={personErrors}
            handleInputChange={handleInputChange}
            handleMultipleSelectChange={handleMultipleSelectChange}
            handleRadioButtonChange={handleRadioButtonChange}
            disabledFirm={!!props?.disabledFirm}
          />

          <PersonDataEdit
            {...personState}
            setPhoto={setRegularPhoto}
            offices={offices}
            Errors={personErrors}
            handlePhoneNumberChange={handlePhoneNumberChange}
            handleInputChange={handleInputChange}
            handleCheckBoxChange={handleCheckBoxChange}
          />

          <PersonAOPEdit
            {...personState}
            Errors={personErrors}
            handlerAreasOfPractice={handlerAreasOfPractice}
          />

          <div className="flex gap-2.5">
            <SwitchToogle
              centered={true}
              seter={() => {
                setUser((currentUser) => ({
                  ...currentUser,
                  hasUser: !user.hasUser,
                }));
              }}
              checked={user.hasUser}
            />
            <span className="inputLabel"> New User</span>
          </div>

          {user.hasUser && (
            <div className="w-full formsectionNTitle">
              <CatalogSubTitle
                title={"User Information"}
                className={` text-accent-Default-dark`}
              />

              <div className=" flex formsection w-full">
                <Input
                  disabled
                  title="Email"
                  name="Email"
                  errorMsj={""}
                  error={false}
                  tooltip="Email domain must match the official Firm's Domain."
                  value={personState.firmEmail}
                  onChange={() => {}}
                  required={false}
                />

                <DropDown
                  title="Access Type"
                  required={true}
                  data={[
                    { id: "member", name: "Member" },
                    { id: "dataAdmin", name: "Data Admin + Member" },
                  ]}
                  selectedObjst={user.accessType}
                  seter={(e) => {
                    setUser((currentuser) => ({
                      ...currentuser,
                      accessType: e,
                    }));  
                    seterrorFields((errorFields.filter(error => error != "Access Type")))  
                  }}
                  error={userError.error}
                  errormsj={userError.errorMsj}
                />

                <Input
                  title="User Name"
                  name="UserName"
                  errorMsj={""}
                  error={false}
                  value={user.userName}
                  onChange={(e) => {
                    setUser((currentuser) => ({
                      ...currentuser,
                      userName: e.target.value,
                    }));
                  }}
                  required={false}
                />
              </div>
            </div>
          )}

          <ButtonsCrudWithDropdown
            Catalog="User"
            mode={props.mode}
            actionButton={handleButtonClick}
            onDelete={handleDelete}
            optionsCreate={["Create", "Create & Send Email"]}
            optionsUpdate={["Save", "Save & Send Email"]}
            specialPath={
              state?.userType === 0 || state?.userType === 2
                ? "/Staff/Catalogs/Person/"
                : undefined
            }
            id={""}
            disabled={errorFields.length>0}
          />
        </section>
      </form>
    </main>
  );
};

export default AddContentPerson;