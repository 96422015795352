function Cancel(props: any) {
    return (
        <div className={props.className}>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
                <path d="M6.22509 19.1642L4.83594 17.7751L10.6109 12.0001L4.83594 6.22509L6.22509 4.83594L12.0001 10.6109L17.7751 4.83594L19.1642 6.22509L13.3892 12.0001L19.1642 17.7751L17.7751 19.1642L12.0001 13.3892L6.22509 19.1642Z"/>
            </svg>

        </div>);
}

export default Cancel;