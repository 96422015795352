

function SortAsending(props: any) {
    return (
        <div className={props.className}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
          
            <path
              d="M25.6307 13.8325L22.8524 16.6108L17.072 10.8304L17.072 42.7065L13.0828 42.7065L13.0828 10.8304L7.2241 16.6608L4.4458 13.8825L15.0632 3.26514L25.6307 13.8325Z" 
            /> 
        </svg>
      </div>
      );
}

export default SortAsending;