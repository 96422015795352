import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import RadioButton from "../../../components/Atomos/Radio/RadioButton";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import { UseJurisdictionList } from "../../../hooks/Catalogs/useJurisdiction";
import { UseJurisdictionGroupList } from "../../../hooks/Catalogs/useJurisdictionGroup";
import { UseFirmList, firmType } from "../../../hooks/Catalogs/useFirm";
import { useLogging } from "../../../Context/LoggingContext";

enum DataAdminType {
  primary = 0,
  dedicated = 1,
}

interface DataAdmin {
  id?: string;
  firm?: string;
  type: DataAdminType;
  person?: string;
  membershipLocation?: string;
  subscription: boolean;
}

interface iCatalogProps {
  mode: "add" | "edit";
}

interface iCatalogProps {
  mode: "add" | "edit";
}

type error = { error: boolean; msj: string };
type errors = {
  person: error;
  membershipLocation: error;
  firm: error;
};

const AddDataAdmin: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [dataAdmin, setDataAdmin] = useState<DataAdmin>(
    state
      ? { ...state }
      : {
          firm: "",
          type: 0,
          person: "",
          membershipLocation: "",
          subscription: false,
        }
  );
  const [errors, setErrors] = useState<errors>({
    person: { error: false, msj: "" },
    membershipLocation: { error: false, msj: "" },
    firm: { error: false, msj: "" },
  });

  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [personData, setPersonData] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [filteredJurisdictions, setFilteredJurisdictions] = useState<any[]>([]);
  const [filteredPersons, setFilteredPersons] = useState<any[]>([]);
  const [primaryType, setPrimaryType] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState({
    id: state?.firmId ?? "",
    name: state?.firm ?? "",
  });
  const [selectedPerson, setSelectedPerson] = useState({ id: "", name: "" });
  const [selectedJurisdiction, setSelectedJurisdiction] = useState({
    id: "",
    name: "",
  });
  const [selectedJurisdictionGroup, setSelectedJurisdictionGroup] = useState({
    id: "",
    name: "",
  });
  const { logActivity } = useLogging();

  const { jurisdictionData: jurisdictionData } = UseJurisdictionList();
  const { jurisdictionGroupData: jurisdictionGroupData } =
    UseJurisdictionGroupList();
  const { firmData } = UseFirmList(firmType.member);

  useEffect(() => {
    if (
      errors.firm.error ||
      errors.person.error ||
      errors.membershipLocation.error
    )
      validateForm();
  }, [dataAdmin, selectedFirm, selectedJurisdiction, selectedPerson]);

  useEffect(() => {
    if (
      errors.firm.error ||
      errors.person.error ||
      errors.membershipLocation.error
    )
      validateForm();
  }, [dataAdmin, selectedFirm, selectedJurisdiction, selectedPerson]);

  useEffect(() => {
    if (
      !errors.firm.error &&
      !errors.person.error &&
      !errors.membershipLocation.error
    )
      setSaveOverrideOpen(false);
  }, [errorFields]);

  const checkEditType = (index: number) => {
    setSelectedJurisdiction({ id: "", name: "" });
    setDataAdmin({ ...dataAdmin, type: index });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.Get("/user");
      setUsers(res.data);
    };

    setErrors({
      person: { error: false, msj: "" },
      membershipLocation: { error: false, msj: "" },
      firm: { error: false, msj: "" },
    });

    fetchUsers();
  }, []);

  useEffect(() => {
    if (!personData.length || !selectedFirm.id || !users.length) return;
    const dataAdminUserIds = users
      .filter((user) => user.access === "Data Admin + Member")
      .map((user) => user.id);

    const filteredPersons = personData.filter((person) =>
      dataAdminUserIds.includes(person.userId)
    );

    setFilteredPersons(filteredPersons);
  }, [personData, selectedFirm, users]);

  useEffect(() => {
    const fetchPersons = async () => {
      try {
        const response = await axios.Get(
          `./person?firmsId=["${selectedFirm.id}"]`
        );
        const data = response.data;
        if (data != null) {
          setPersonData(data);
          setSelectedPerson({
            id: state?.personId ?? "",
            name: state?.person ?? "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPersons();

    if (selectedFirm.id !== "") {
      const firm = firmData.find((firm: any) => firm.id === selectedFirm.id);
      setPrimaryType(firm?.membershipType === "Primary");
      const numberOfJurisdictionsInJG = jurisdictionData.filter(
        (jurisdiction: any) =>
          jurisdiction.partOfMulti === true &&
          jurisdiction.jurisdictionGroupId === firm?.jurisdictionGroupId
      );
      setFilteredJurisdictions(numberOfJurisdictionsInJG);
    }
  }, [selectedFirm.id, jurisdictionData]);

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;

    newerrors.firm =
      selectedFirm.name === ""
        ? { error: true, msj: "Firm is required" }
        : { error: false, msj: "" };
    newerrors.membershipLocation =
      selectedFirm.name !== "" &&
      dataAdmin.type === DataAdminType.dedicated &&
      selectedJurisdiction.name === ""
        ? { error: true, msj: "Membership Location is required" }
        : { error: false, msj: "" };
    newerrors.person =
      selectedFirm.name !== "" && selectedPerson.name === ""
        ? { error: true, msj: "Person is required" }
        : { error: false, msj: "" };

    valid = Object.values(errors).every((error) => !error.error);
    setErrors(errors);
    setErrorFields(
      Object.keys(errors).filter(
        (key) => errors[key as keyof typeof errors].error
      )
    );

    if (!valid) setSaveOverrideOpen(true);

    return valid;
  };

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  };

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/DataAdmin`, id);

    if (response.status === 200) {
      logActivity(
        "DELETE_DATA_ADMIN",
        location.pathname,
        JSON.stringify(state ? state : {})
      );
      navigate("/Staff/Catalogs/DataAdmin");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === "delete") {
      axios.Delete("office", dataAdmin.id ?? "").then(() => {
        navigate("/Staff/Catalogs/DataAdmin");
      });
    } else if (buttonClicked === "saveOverride") {
      if (props.mode === "add") {
        handleSave(e);
      } else {
        handleUpdate(e);
      }
    } else if (validateForm()) {
      if (buttonClicked === "saveButton") {
        handleSave(e);
      } else if (buttonClicked === "updateButton") {
        handleUpdate(e);
      }
    }
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await axios.Post("DataAdmin", {
      type: dataAdmin.type,
      firm: selectedFirm.id,
      membershipLocation: selectedJurisdiction.id,
      person: selectedPerson.id,
      subscription: dataAdmin.subscription,
      createdAt: new Date(),
    });
    if (res.status === 200) {
      setTimeout(() => {
        logActivity(
          "CREATE_DATA_ADMIN",
          location.pathname,
          JSON.stringify(res.data)
        );

        navigate("/Staff/Catalogs/DataAdmin");
      }, 500);
    } else {
      console.error(res.data);
    }
  };

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .Put("DataAdmin", {
        ...state,
        id: dataAdmin.id,
        type: dataAdmin.type,
        firm: selectedFirm.id,
        membershipLocation: selectedJurisdiction.id,
        person: selectedPerson.id,
        subscription: dataAdmin.subscription,
      })
      .then((res) => {
        if (res.status === 200) {
          logActivity(
            "UPDATE_DATA_ADMIN",
            location.pathname,
            JSON.stringify({
              prevState: JSON.stringify(state),
              newState: JSON.stringify(res.data),
            })
          );
          setTimeout(() => {
            navigate("/Staff/Catalogs/DataAdmin");
          }, 500);
        } else {
          console.error(res.data);
        }
      });
  };

  const handleSetSelectedFirm = (e: any) => {
    setSelectedFirm(e);
    setSelectedPerson({ id: "", name: "" });
    setSelectedJurisdiction({ id: "", name: "" });
    setDataAdmin({ ...dataAdmin, firm: e.id });

    if (e.id !== "") {
      const firm = firmData.find((firm: any) => firm.id === e.id);
      const jurisdictionGroup = jurisdictionGroupData.find(
        (jur) => jur.id === firm.jurisdictionGroupId
      );
      setPrimaryType(firm.membershipType === "Primary");
      if (firm.membershipType === "Primary") {
        setDataAdmin({ ...dataAdmin, type: 0 });
      }
      if (jurisdictionGroup) {
        setSelectedJurisdictionGroup({
          id: jurisdictionGroup.id,
          name: jurisdictionGroup.name,
        });
      }
    }
  };

  const handleSetSelectedJurisdiction = (e: any) => {
    setSelectedJurisdiction(e);
    setDataAdmin({ ...dataAdmin, membershipLocation: e.id });
  };

  const handleSetSelectedPerson = (e: any) => {
    setSelectedPerson(e);
    setDataAdmin({ ...dataAdmin, person: e.id });
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setDataAdmin((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleCheckBoxDataSubscribeEmails = (checked: boolean) => {
    handleCheckBoxChange("subscription", checked);
  };

  return (
    <>
      <main
        className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}
      >
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={() => {
              setSaveOverrideOpen(!saveOverrideOpen);
            }}
          />
          <CatalogTitle
            title={`${props.mode === "add" ? "New DataAdmin" : "Edit " + (state ? state?.person || "Edit none" : "Loading...")}`}
            className="text-Default"
          >
            <GoBack />
          </CatalogTitle>
          <article className="articleSection">
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title="DataAdmin Information"
                className="text-accent-Default-dark"
              />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="col-span-12 md:col-span-6 lg:col-span-6">
                  <DropDownList
                    title="Firm"
                    required={true}
                    data={firmData}
                    selectedObjst={selectedFirm.name}
                    seter={handleSetSelectedFirm}
                    error={errors.firm.error}
                    errormsj={errors.firm.msj}
                    getid
                  />
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-6">
                  <DropDownList
                    title="Person"
                    required={true}
                    data={filteredPersons}
                    selectedObjst={selectedPerson.name}
                    seter={handleSetSelectedPerson}
                    error={errors.person.error}
                    errormsj={errors.person.msj}
                    disabled={!selectedFirm.id}
                    getid
                    tooltip="Please select Firm."
                  />
                </div>
                <div className="grid gap-2.5 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
                  <div className="grid">
                    <span className="text-gray-font text-base lg:text-lg not-italic font-normal leading-8">
                      DataAdmin Type:
                    </span>
                  </div>
                  <div className="flex flex-1 gap-5">
                    <RadioButton
                      index={DataAdminType.primary}
                      text={"Primary"}
                      selected={dataAdmin.type === DataAdminType.primary}
                      handleRadioButtonChange={checkEditType}
                      disabled={primaryType}
                    />
                    <RadioButton
                      index={DataAdminType.dedicated}
                      text={"Dedicated"}
                      selected={dataAdmin.type === DataAdminType.dedicated}
                      handleRadioButtonChange={checkEditType}
                      disabled={primaryType}
                    />
                  </div>
                </div>

                <div className="flex-wrap lg:col-start-7 col-span-12 md:col-span-6 lg:col-span-6">
                  <DropDownList
                    title="Membership Location"
                    required={dataAdmin.type === DataAdminType.dedicated}
                    data={filteredJurisdictions}
                    selectedObjst={selectedJurisdiction.name}
                    seter={handleSetSelectedJurisdiction}
                    error={errors.membershipLocation.error}
                    errormsj={errors.membershipLocation.msj}
                    disabled={
                      !selectedFirm.id ||
                      dataAdmin.type === DataAdminType.primary
                    }
                    getid
                    tooltip={
                      'To assign a specific Membership Location, please select DataAdmin Type "Dedicated".'
                    }
                    dynamic={true}
                  />
                </div>
                <div className="flex-2 gap-2.5 flex-wrap col-span-12 md:col-span-6 lg:col-span-6">
                  <SwitchToogle
                    titleFirst
                    className="flex-col items-center md:!items-start"
                    centered
                    small
                    seter={handleCheckBoxDataSubscribeEmails}
                    checked={dataAdmin.subscription}
                    title="Subscribe to Notifications Emails"
                  />
                </div>
              </div>
            </section>
            <ButtonsCrud
              Catalog="DataAdmin"
              mode={props.mode}
              id={id ? id : ""}
              disabled={
                errors.firm.error ||
                errors.person.error ||
                errors.membershipLocation.error
              }
              actionButton={handleButtonClick}
              onDelete={handleDelete}
            />
          </article>
        </form>
      </main>
    </>
  );
};

export default AddDataAdmin;
