type EntityWithId = { id: string; name: string };

export const getNamesByIds = <T extends EntityWithId>(ids: string[], entities: T[]): string => {
    return entities
        .filter(entity => ids.includes(entity.id))
        .map(entity => entity.name)
        .join(', ');
}

export const getFirmName = (id: string, firms: EntityWithId[]): string => {
    return getNamesByIds([id], firms);
}

export const getMainOfficeName = (id: string, offices: any[]): string => {
    return getNamesByIds([id], offices);
}

export const getAdditionalOfficesNames = (ids: string[], offices: any[]): string => {
    return getNamesByIds(ids, offices);
}

export const generateAOPS = (areas: EntityWithId[]): string => {
    return getNamesByIds(areas.map(area => area.id), areas);
}

export const generateLanguagesNames = (languageIds: string[], languages: any[]): string => {
    return getNamesByIds(languageIds, languages);
}

export const generateBarAdmissionsNames = (barIds: string[], barAdmissions: any[]): string => {
    return getNamesByIds(barIds, barAdmissions);
}
