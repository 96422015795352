import { useState, useMemo, useEffect } from 'react';
import { ISorting } from '../../interfaces/Components/ITable';

interface UseTableSettingsProps {
  defaultPageSize: number;
}

export const useTableSettings = ({ defaultPageSize }: UseTableSettingsProps) => {
  const [sort, setSort] = useState<ISorting>({ field: "_ts", order: "asc" });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(defaultPageSize);
  const [filter, setFilter] = useState<{ field: string; value: string }[]>([]);

  const queryParams = useMemo(() => {
    let params = [
      ...filter
        .filter(f => f.value !== "")
        .map(f => `filter=${f.field}:${f.value}`)
    ];
    params.push(`sort=${sort.field}:${sort.order}`);
    params.push(`page=${currentPage}`);
    params.push(`pageSize=${recordsPerPage}`);
    return params;
  }, [currentPage, recordsPerPage, sort, filter]);

  return {
    sort,
    setSort,
    currentPage,
    setCurrentPage,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    setFilter,
    queryParams,
  };
};
