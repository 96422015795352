import React, { useEffect, useState } from 'react';

interface CardProps {
  image: string | React.ReactNode;
  title: string;
  cssClass: string;
}
const Card: React.FC<CardProps> = ({ image, title, cssClass }) => {
  const [formattedTitle, setFormattedTitle] = useState(title);

  useEffect(() => {
    const handleFormattedTitle = () => {
      // Aquí verificamos si el ancho de la pantalla es menor o igual a 1024 (tableta o móvil)
      const isTabletOrMobile = window.innerWidth <= 768;
      if (isTabletOrMobile) {
        // Si es tableta o móvil, quitamos "My" del título
        setFormattedTitle(title.replace('My ', ''));
      } else {
        // Si no, mostramos el título original
        setFormattedTitle(title);
      }
    };

    // Ejecutamos la función al montar el componente y cada vez que se redimensione la ventana
    handleFormattedTitle();
    window.addEventListener('resize', handleFormattedTitle);

    // Limpiamos el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleFormattedTitle);
    };
  }, [title]);


  return (
    <div className={`w-full ${cssClass} md:h-[5.7rem] xl:py-6 lg:py-4 py-2 px-2 justify-center lg:px-8 text-center gap-2 xl:gap-3 flex flex-col`}>
      <div className="flex align-middle  justify-center">
        {typeof image === 'string' ? ( 
          <img src={image} alt='wsg_profile' className=''/>
        ) : (
          image
        )}
      </div>
      <p className='font-bold md:text-sm text-[.625rem] text-center roboto_font text-profile-dark-blue md:flex-nowrap flex justify-center w-max mx-auto'>{formattedTitle}</p>
    </div>
  );
};

export default Card;
