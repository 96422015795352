import WorldServicesGroupIcon from "../../Atomos/Icons/WorldServicesGroupIcon";

const GuidesCard = () => {
  return (
    <div className="flex px-7 py-7 gap-7 lg:py-11 lg:px-12 flex-col items-end self-stretch">
      <div className="flex flex-col md:flex-row items-start gap-8 self-stretch">
        <div className="flex p-[1.5rem] min-h-[11.5625rem] flex-col justify-center items-center gap-[.625rem] border border-solid border-gray-50 lg:h-[11.5625rem] lg:w-[18.625rem] w-full">
          <div className="flex py-0 px-4 flex-col justify-center items-center gap-[.625rem] flex-1 self-stretch">
            <WorldServicesGroupIcon />
            <span className="text-Default text-center text-xl font-bold leading-7 underline self-stretch relative z-20">
              Doing Business in Australia 2012
            </span>
          </div>
        </div>
        <div className="flex p-[1.5rem] min-h-[11.5625rem] flex-col justify-center items-center gap-[.625rem] border border-solid border-gray-50 lg:h-[11.5625rem] lg:w-[18.625rem] w-full">
          <div className="flex py-0 px-4 flex-col justify-center items-center gap-[.625rem] flex-1 self-stretch">
            <WorldServicesGroupIcon />
            <span className="text-Default text-center text-xl font-bold leading-7 underline self-stretch relative z-20">
              Tort Reform in Australia
            </span>
          </div>
        </div>
        <hr className="lg:hidden flex h-0 lg:justify-center lg:items-center self-stretch" />
      </div>
      <div className="flex p-3 flex-col items-start gap-[.625rem] lg:gap-10 self-stretch">
        <hr className="hidden lg:flex h-0 lg:justify-center lg:items-center self-stretch" />
        <div className="flex lg:py-3 lg:px-0 lg:flex-col lg:items-start lg:gap-[.625rem]">
          <div className="flex items-start gap-5">
            <div className="w-[1.5625rem] h-[1.5625rem] rounded-full bg-primary"></div>
            <div className="w-[1.5625rem] h-[1.5625rem] rounded-full bg-primary"></div>
            <div className="w-[1.5625rem] h-[1.5625rem] rounded-full bg-primary"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuidesCard;