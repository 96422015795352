import React from "react";

const LearningInstitutePage = () => {
    return ( 
        <React.Fragment>
            <div className="min-h-screen w-full flex items-center align-middle justify-center">
                Wsg Learning Institute Page
            </div>
        </React.Fragment>
     );
}
 
export default LearningInstitutePage;