import { useEffect, useState } from "react"
import Axios from "../../utils/axios"
import { ICountry, } from "../../interfaces/models/ICountry" 
import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { SetCountries } from "../../redux/catalogs/states";
import { getCountryList } from "../../Services/Country";

const axios = new Axios()
const route = '/country'

const useCountry = () => { 
    const [data, setData] = useState<ICountry[]>([]);

    useEffect(() => {
    getCountries();
    }, [route]);
  
    const getCountries = async () => {
      const response = await axios.Get(route)
      .then(response => {
          const responseData = response.data;
          setData(responseData);
      });
  };
  
    const handleDelete = async (id: string) => {
      try {
        await axios.Delete(route, id);
  
        getCountries();
      } catch (error) {
        console.error('Error al eliminar el elemento:', error);
      }
    };

    return {data,handleDelete};
}

  const UseCountryList = () => {
  const [isFetching, setIsFetching] = useState(true)

  const dispatch = useDispatch();
  const countryData = useSelector((state: Appstore) => state.countries);

  useEffect(() => {
    if (countryData.length) {
      setIsFetching(false)
      return
    }
    if (!isFetching) return

    getCountryList()
    .then((countries) => {
      dispatch(SetCountries(countries))
      if (!countries.length) console.error('No countries found. Please check the data source.')
    })
    .catch((error) => console.error(error))
    .finally(() => setIsFetching(false))
  }, [isFetching]);

  return {countryData, isFetching};
};

export {useCountry, UseCountryList}