import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const globalCrisisSlice = createSlice({
  name: "globalCrisis",
  initialState: Empty,
  reducers: {
    SetGlobalCrisis: (_, action) =>  action.payload,
    UpdateGlobalCrisis:(state, action) => ({...state, ...action.payload})
  },
});

export const { SetGlobalCrisis, UpdateGlobalCrisis } = globalCrisisSlice.actions;
