import { Option } from "../components/Atomos/DropDown/DDMultiple";
import Axios from "../utils/axios";

const axios = new Axios();

export const getGlobalCrisis = async () => {
  try {
    const response = await axios.Get('/globalCrisis');
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}

export const addGlobalCrisis = async (addData: Option) => {
  try {
    const response = await axios.Post('/globalCrisis',addData);
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}