import React from "react";

function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      
        <path 
          d="M42.197 12.033a16.926 16.926 0 01-3.96 4.092v1.012c0 2.142-.308 4.268-.924 6.38-.616 2.112-1.554 4.15-2.816 6.116a22.41 22.41 0 01-4.532 5.148c-1.76 1.467-3.872 2.67-6.335 3.608-2.464.939-5.12 1.379-7.964 1.32-4.43 0-8.478-1.188-12.145-3.564.558.059 1.188.088 1.892.088 3.696 0 6.982-1.13 9.857-3.388-1.731-.03-3.271-.557-4.62-1.584-1.35-1.026-2.289-2.332-2.817-3.916a9.697 9.697 0 001.496.132c.704 0 1.409-.088 2.112-.264-1.848-.381-3.373-1.29-4.576-2.728-1.202-1.437-1.804-3.124-1.804-5.06v-.088A8.122 8.122 0 008.67 20.35a8.237 8.237 0 01-2.596-2.816c-.645-1.144-.968-2.42-.968-3.828s.367-2.728 1.1-3.96a22.908 22.908 0 007.216 5.852c2.817 1.467 5.867 2.274 9.152 2.42a7.773 7.773 0 01-.22-1.804c0-2.2.778-4.063 2.332-5.588 1.555-1.525 3.418-2.302 5.588-2.332 2.318 0 4.254.836 5.808 2.508a15.864 15.864 0 005.06-1.936c-.616 1.877-1.789 3.344-3.52 4.4 1.526-.176 3.051-.587 4.576-1.232z"
        ></path> 
    </svg>
  );
}

export default Twitter;