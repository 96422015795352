import React from "react";

function AddToMyNetwork() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#BC3323"
        d="M9.583 14.965L4.166 17.29V3.333h6.25v1.25h-5v10.792l4.167-1.792 4.166 1.792V9.583H15v7.708l-5.416-2.326zm4.166-7.049V6.25h-1.666V5h1.666V3.333H15V5h1.667v1.25h-1.667v1.666h-1.25z"
      ></path>
    </svg>
  );
}

export default AddToMyNetwork;
