import React from "react";

function CloseModal() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
      <path d="M5.188 15.97L4.03 14.812 8.843 10 4.03 5.187 5.188 4.03 10 8.842l4.813-4.812 1.157 1.157L11.159 10l4.813 4.812-1.158 1.158L10 11.157 5.188 15.97z"></path>
    </svg>
  );
}

export default CloseModal;
