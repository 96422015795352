
import React from 'react';
import TextArea from '../../Atomos/Inputs/TextArea';
import HeaderTitle from './HeaderTitle';
import { PersonProfessionalCareerProps } from '../../../interfaces/models/IPerson';
import CatalogSubTitle from '../Catalog Text/CatalogSubTitle';
import WSGCheckbox from '../../Atomos/Checkbox/WSGCheckBox';
import { useAppContext } from '../../../Context';

type PersonProfessionalCareerErrors = {
  significantAccomplishments: { error: boolean, errorMsj: string };
  industryOrganizationAffiliation: { index: number, error: boolean, errorMsj: string }[];
  additionalActivities: { error: boolean, errorMsj: string };
  seminarPresentation: { error: boolean, errorMsj: string };
  additionalInformation: { error: boolean, errorMsj: string };
};

type PersonProfessionalCareerEditProps = {
  handleTextAreaElement: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleAddIndustryOrganizationAffiliation: (industryOrganizationAffiliation: string[]) => void;
  setPersonErrors: (errors: any) => void;
} & PersonProfessionalCareerProps;

const PersonProfessionalCareer: React.FC<PersonProfessionalCareerEditProps> = (props: PersonProfessionalCareerEditProps) => {

  const checkboxAffiliations =['ABA','IBA','UIA','INTA','IFA','ATLA'] ;
  const [selectedAffiliation, setSelectedAffiliation] = React.useState<string[]>(
    props.industryOrganizationAffiliation?.filter(aff => checkboxAffiliations?.includes(aff))
  );

  const [errors, setErrors] = React.useState<PersonProfessionalCareerErrors>({
    significantAccomplishments: { error: false, errorMsj: "" },
    industryOrganizationAffiliation: [{ index: 0, error: false, errorMsj: "" }],
    additionalActivities: { error: false, errorMsj: "" },
    seminarPresentation: { error: false, errorMsj: "" },
    additionalInformation: { error: false, errorMsj: "" },
  });

  const handlesignificantAccomplishmentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 4000) {
      setErrors({ ...errors, significantAccomplishments: { error: true, errorMsj: "SignificantAccomplishments must be less than 4000 characters" } });
    }
    else {
      setErrors({ ...errors, significantAccomplishments: { error: false, errorMsj: "" } });
    }

    props.handleTextAreaElement(event);
  }

  const handleAdditionalActivitiesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 1500) {
      setErrors({ ...errors, additionalActivities: { error: true, errorMsj: "Additional Activities must be less than 1500 characters" } });

    }
    else {
      setErrors({ ...errors, additionalActivities: { error: false, errorMsj: "" } });

    }



    props.handleTextAreaElement(event);
  }

  const handleSeminarPresentationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 1500) {
      setErrors({ ...errors, seminarPresentation: { error: true, errorMsj: "Seminar/Presentation must be less than 1500 characters" } });
    }
    else {
      setErrors({ ...errors, seminarPresentation: { error: false, errorMsj: "" } });
    }

    props.handleTextAreaElement(event);
  }
  const handleAdditionalInformationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 1500) {
      setErrors({ ...errors, additionalInformation: { error: true, errorMsj: "Additional Information must be less than 1500 characters" } });
    }
    else {
      setErrors({ ...errors, additionalInformation: { error: false, errorMsj: "" } });
    }

    props.handleTextAreaElement(event);
  }

  const [IndustryOrganizationAffiliations, setIndustryOrganizationAffiliations] = React.useState<string[]>(props.industryOrganizationAffiliation?.length > 0 ? props.industryOrganizationAffiliation : ["", "", "", ""]);

  const handleTextAreaChange = (index: number, newValue: string) => {
    const newValues = [...IndustryOrganizationAffiliations];
    newValues[index] = newValue;
    console.log("newValues:", newValues);
    setIndustryOrganizationAffiliations(newValues);

    if (newValue.length > 50) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industryOrganizationAffiliation: [
          ...prevErrors.industryOrganizationAffiliation.slice(0, index),
          { index: index, error: true, errorMsj: "Industry Organization Affiliation must be less than 50 characters" },
          ...prevErrors.industryOrganizationAffiliation.slice(index + 1),
        ],

      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industryOrganizationAffiliation: [
          ...prevErrors.industryOrganizationAffiliation.slice(0, index),
          { index: index, error: false, errorMsj: "" },
          ...prevErrors.industryOrganizationAffiliation.slice(index + 1),
        ],
      }));
    }

    props.handleAddIndustryOrganizationAffiliation(newValues);
  };

  const handleSelectedAffiliation = (affiliation: string) => {
    setSelectedAffiliation((prev) => {
      const prevAffiliations = Array.isArray(prev) ? prev : [];

      const updatedAffiliations = prevAffiliations.includes(affiliation)
        ? prevAffiliations.filter(item => item !== affiliation)
        : [...prevAffiliations, affiliation];

      props.handleAddIndustryOrganizationAffiliation(updatedAffiliations);

      return updatedAffiliations;
    });
  };
  const {mode} = useAppContext()

  return (
    <section className='formsectionNTitle'> 
      <CatalogSubTitle title={'Professional Career'} className={`${mode === 'staff' ? " text-Default" : 'text-accentDefault'}`}/>
      <div className='flex flex-col w-full gap-5'>
      <div className="col-span-12 md:col-span-6 w- ">
        <TextArea title="Significant Accomplishments" maxChars={4000} characterCount={true} name="significantAccomplishments" errorMsj={errors.significantAccomplishments.errorMsj} error={errors.significantAccomplishments.error} value={props.significantAccomplishments} onChange={handlesignificantAccomplishmentsChange} required={false} />
      </div>
      <span className={`  text-left text-lg leading-5 font-normal not-italic relative self-stretch text-neutral-dark   `}>
        Industry Organization Affiliation
      </span>
      <div className='grid grid-cols-3 md:grid-cols-6 gap-4'>
        {checkboxAffiliations.map((item, index) => (
          <WSGCheckbox
            index={index}
            key={item}
            text={item}
            checked={selectedAffiliation?.includes(item)}
            handleCheckboxChange={() => handleSelectedAffiliation(item)}
          />
        ))}
      </div>
      <div className="  grid grid-cols-1 md:grid-cols-2 gap-4">
        {IndustryOrganizationAffiliations.map((item, index) => ( 
          <TextArea
            title=""
            maxChars={50}
            height='h-[50px]'
            characterCount={true}
            name="industryOrganizationAffiliation"
            errorMsj={errors.industryOrganizationAffiliation.find((error) => error.index === index && error.error === true)?.errorMsj as string}
            error={errors.industryOrganizationAffiliation.some((error) => (error.index === index && error.error)) as boolean} // Added null check with "?"
            value={item}
            onChange={(e) => handleTextAreaChange(index, e.target.value)}
            required={false}
            key={index}
          />
        ))
        }
      </div>
      <div className="col-span-12 md:col-span-6 ">
        <TextArea title="Additional Activities" maxChars={1500} characterCount={true} name="additionalActivities" errorMsj={errors.additionalActivities.errorMsj} error={errors.additionalActivities.error} value={props.additionalActivities} onChange={handleAdditionalActivitiesChange} required={false} />
      </div>
      <div className="col-span-12 md:col-span-6 ">
        <TextArea title="Seminar/Presentation" maxChars={1500} characterCount={true} name="seminarPresentation" errorMsj={errors.seminarPresentation.errorMsj} error={errors.seminarPresentation.error} value={props.seminarPresentation} onChange={handleSeminarPresentationChange} required={false} />
      </div>
      {/* <div className="col-span-12 md:col-span-6 ">
        <TextArea title="Additional Information" maxChars={1500} characterCount={true} name="additionalInformation" errorMsj={errors.additionalInformation.errorMsj} error={errors.additionalInformation.error} value={props.additionalInformation} onChange={handleAdditionalInformationChange} required={false} />
      </div> */}
      </div>
    </section>
  )
}
export default PersonProfessionalCareer;
