import React, { useEffect, useState } from "react";
import FirmFilter from "../../components/Catalogs/Filters/FirmFilter";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { UseCountryList } from "../../hooks/Catalogs";
import { UseJurisdictionGroupList } from "../../hooks/Catalogs/useJurisdictionGroup";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import useDataLoader2 from "../../hooks/useDataLoader2";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { IJurisdiction } from "../../interfaces";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { transformData } from "../../hooks/Table/TransformColumnsForExport";
import { formatTimeSpanToString, getNameById, isUUID } from "../../utils/functions";
import { useStatesList } from "../../hooks/Catalogs/useStates";
import { useCityList } from "../../hooks/Catalogs/useCityList";

const Firm: React.FC = () => {
  const Headers: string[] = [
    "shortId",
    "name",
    "firmName",
    "membershipStatus",
    "jurisdiction",
    "jurisdictionGroup",
    "state",
    "country",
    "region",
    "membershipType",
    "serviceType",
    "otherServiceType",
    "hasSecondary",
    "secondaryJurisdiction",
    "acceptedEmailDomain",
    "dateJoined",
    "dateWithdrawn",
    "dateCreated",
  ];
  const DefaultPageSize = 50;
  const [stateData, setStateData] = useState<any[]>([]);
  const { regionData } = UseRegionList();
  const { jurisdictionData } = UseJurisdictionList();
  const { cityData } = useCityList();
  const { countryData } = UseCountryList();
  const { statesData } = useStatesList();
  const { jurisdictionGroupData } = UseJurisdictionGroupList();
  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);
  const [firms, setFirms] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/firm',
    defaultPageSize: DefaultPageSize,
  });

  const [firmFilters, setFirmFilters] = useState<{
    name: string;
    countries: Option[];
    regions: Option[];
    states: Option[];
    jurisdictions: Option[];
    jurisdictionGroups: Option[];
    membershipStatus: Option[];
    serviceTypes: Option[];
  }>({
    name: "",
    countries: [],
    regions: [],
    states: [],
    serviceTypes: [],
    membershipStatus: [],
    jurisdictions: [],
    jurisdictionGroups: [],
  });

  const filterJurisdictions = (jr: IJurisdiction[]) => {
    if (jurisdictionData && jr) {
      const filter = jurisdictionData.filter(allJurisdiction => jr?.some((j: any) => allJurisdiction.id === j))
      return <React.Fragment>
        <p>
          {filter.map(f => f.name).join(', ')}
        </p>
      </React.Fragment>
    }
  }

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'shortId', header: 'Short ID' },
    { id: 'name', header: 'Name' },
    { id: 'firmName', header: 'Firm Name' },
    { id: 'membershipStatus', header: 'Membership Status' },
    { id: 'jurisdiction', header: 'Jurisdiction' },
    { id: 'jurisdictionGroup', header: 'Jurisdiction Group' },
    { id: 'state', header: 'State' },
    { id: 'country', header: 'Country' },
    { id: 'region', header: 'Region' },
    { id: 'membershipType', header: 'Membership Type' },
    { id: 'serviceType', header: 'Service Type' },
    { id: 'otherServiceType', header: 'Other Service Type' },
    { id: 'hasSecondary', header: 'Has Secondary' },
    { id: 'secondaryJurisdiction', header: 'Secondary Jurisdiction' },
    { id: 'acceptedEmailDomain', header: 'Accepted Email Domain' },
    { id: 'dateWithdrawn', header: 'Date Withdrawn' },
    { id: '_ts', header: 'Created Date' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
      ...column,
      cell: column.cell || ((props: any) => {
        switch (column.id) {
          case 'count':
            return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
          case 'Secondary Jurisdiction':
            return <p>{filterJurisdictions(props?.getValue())}</p>
          case '_ts':
            const value = props?.getValue();
            return (
              <p>
                {value && formatTimeSpanToString(value) }
              </p>
            );
          default:
            return <p>{props?.getValue()}</p>;
        }
      })
    })));

  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, data)

  const clearSelections = () => {
    setFirmFilters({
      name: "",
      countries: [],
      regions: [],
      states: [],
      jurisdictions: [],
      jurisdictionGroups: [],
      serviceTypes: [],
      membershipStatus: [],
    });
    fetchData(true);
  };

  const [membershipStatus] = useState<Option[]>([
    { id: "accepted", name: "Accepted" },
    { id: "member", name: "Member" },
    { id: "onboarding", name: "Onboarding" },
    { id: "temporaryWithdrawn", name: "Temporary Withdrawn" },
    { id: "withdrawn", name: "Withdrawn" },
  ]);
  const [serviceTypes] = useState<Option[]>([
    { id: "accounting", name: "Accounting" },
    { id: "investmenBanking", name: "Investment Banking" },
    { id: "legal", name: "Legal" },
    { id: "other", name: "Other" },
  ]);
  
  useEffect(() => {
    setStateData(statesData.filter((s: any) => firmFilters.countries.map(f => f.id).includes(s.country)))
  },[firmFilters.countries]);

  const formatData = (data: any[]) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        jurisdiction: d.jurisdictionNames,
        secondaryJurisdiction: getNameById(d.secondaryJurisdiction, jurisdictionData),
        jurisdictionGroup: d.jurisdictionGroupNames,
        region: d.regionNames,
        state: d.stateName,
        country: d.countryName,
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (data) setFirms(formatData(data))
  },[data]);

  useEffect(() => {
    if (
      !jurisdictionData.length || 
      !jurisdictionGroupData.length ||
      !regionData.length ||
      !statesData.length ||
      !countryData.length
    ) return

    if(!isFetching) fetchData();
    setIsFetching(true)
  },[jurisdictionData, jurisdictionGroupData, regionData, statesData, countryData]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: firmFilters.name,
        comparisonType: 'CONTAINS'
      },
      regions: {
        value: firmFilters?.regions.map(c => c.id),
      },
      country: {
        value: firmFilters?.countries.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      state: {
        value: firmFilters?.states.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      serviceType: {
        value: firmFilters?.serviceTypes.map(c => c.name),
        comparisonType: 'EQUAL'
      },
      jurisdiction: {
        value: firmFilters?.jurisdictions.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      jurisdictionGroup: {
        value: firmFilters?.jurisdictionGroups.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      membershipStatus: {
        value: firmFilters?.membershipStatus.map(c => c.name),
        comparisonType: 'EQUAL'
      },
    }
    setFilter(filterAux)
  },[firmFilters]);

  return (
    <>
      <div className=" mainSection">
        <CatalogTableC
          title={`Firm Catalog`}
          catalogName={"FIRMS"}
          route={"Firm"}
          catalogShortName={"FIRM"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={transformedData}
        >
          <FirmFilter
            key={0}
            Search={fetchData}
            filters={firmFilters}
            setFilters={setFirmFilters}
            countryData={countryData}
            stateData={stateData}
            regionData={regionData}
            jurisdictionData={jurisdictionData}
            jurisdictionGroupData={jurisdictionGroupData}
            serviceTypeData={serviceTypes}
            membershipStatusData={membershipStatus}
            headers={Headers}
            clearSelections={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={firms}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default Firm;
