import React from "react";

interface SelectedFiltersProps {
  selectedFirm: any[];
  selectedPracticeIndustry: any[];
  selectedRegion: any[];
  selectedCountry: any[];
  selectedGeneralTopic: any[];
  selectedGlobalCrisis: any[];
}

const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  selectedFirm,
  selectedPracticeIndustry,
  selectedRegion,
  selectedCountry,
  selectedGeneralTopic,
  selectedGlobalCrisis,
}) => {
  // Function to join items with commas
  const joinItems = (items: any[], key: string) => {
    return items.map((item: any) => item[key]).join(", ");
  };

  return (
    <div className="flex flex-col gap-3 items-start justify-start">
      <div>
        <span className="text-primary not-italic font-semibold md:text-lg text-base leading-5">
          Selected Filters
        </span>
      </div>
      <div className="flex flex-wrap gap-2 text-gray-font text-base">
        {/* Firm */}
        <div>
          <span className="font-bold not-italic md:text-xl md:leading-7 text-base leading-6 text-gray-font">
            Firm:
          </span>{" "}
          <span className="font-normal not-italic md:text-xl md:leading-6 text-base leading-[18px] text-gray-font">
            {selectedFirm.length > 0
              ? joinItems(selectedFirm, "firmName")
              : "All"}
          </span>
        </div>

        {/* Separator */}
        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-sm text-base leading-5 self-center">
          •
        </span>

        {/* Practice Industry */}
        <div>
          <span className="font-bold not-italic md:text-xl md:leading-7 text-base leading-6 text-gray-font">
            Practice Industry:
          </span>{" "}
          <span className="font-normal not-italic md:text-xl md:leading-6 text-base leading-[18px] text-gray-font">
            {selectedPracticeIndustry.length > 0
              ? joinItems(selectedPracticeIndustry, "name")
              : "All"}
          </span>
        </div>

        {/* Separator */}
        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-sm text-base leading-5 self-center">
          •
        </span>

        {/* Region */}
        <div>
          <span className="font-bold not-italic md:text-xl md:leading-7 text-base leading-6 text-gray-font">
            Region:
          </span>{" "}
          <span className="font-normal not-italic md:text-xl md:leading-6 text-base leading-[18px] text-gray-font">
            {selectedRegion.length > 0
              ? joinItems(selectedRegion, "name")
              : "All"}
          </span>
        </div>

        {/* Separator */}
        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-sm text-base leading-5 self-center">
          •
        </span>

        {/* Country */}
        <div>
          <span className="font-bold not-italic md:text-xl md:leading-7 text-base leading-6 text-gray-font">
            Country:
          </span>{" "}
          <span className="font-normal not-italic md:text-xl md:leading-6 text-base leading-[18px] text-gray-font">
            {selectedCountry.length > 0
              ? joinItems(selectedCountry, "name")
              : "All"}
          </span>
        </div>

        {/* Separator */}
        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-sm text-base leading-5 self-center">
          •
        </span>

        {/* General Topic */}
        <div>
          <span className="font-bold not-italic md:text-xl md:leading-7 text-base leading-6 text-gray-font">
            General Topic:
          </span>{" "}
          <span className="font-normal not-italic md:text-xl md:leading-6 text-base leading-[18px] text-gray-font">
            {selectedGeneralTopic.length > 0
              ? joinItems(selectedGeneralTopic, "name")
              : "All"}
          </span>
        </div>

        {/* Separator */}
        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-sm text-base leading-5">
          •
        </span>

        {/* Global Crisis */}
        <div>
          <span className="font-bold not-italic md:text-xl md:leading-7 text-base leading-6 text-gray-font">
            Global Crisis:
          </span>{" "}
          <span className="font-normal not-italic md:text-xl md:leading-6 text-base leading-[18px] text-gray-font">
            {selectedGlobalCrisis.length > 0
              ? joinItems(selectedGlobalCrisis, "name")
              : "All"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SelectedFilters;
