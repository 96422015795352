import * as React from 'react';
import { Link } from 'react-router-dom';
import { NavigationItemProps } from './INavigation';
import css from './Navigation.module.css';
import ChevronRight from '../../Atomos/Icons/ChevronRight';

class NavigationItem extends React.Component<NavigationItemProps> {
  render() {
    const { onClick, to = '', name, hasSubmenu } = this.props;

    return (
      <li className={`flex self-stretch	items-center gap-2.5`}>
        {!hasSubmenu ?
          <Link onClick={onClick} to={to} className={`${css.link} ${css.leftToRight} not-italic leading-6 text-xl font-sans font-bold `}>
            {name}
          </Link>
          : <label onClick={onClick} className={`${css.link} ${css.leftToRight} not-italic leading-6 text-xl font-sans font-bold`}>{name}</label>}
        {hasSubmenu &&
          <div className='w-6 h-6 fill-white'>
            <ChevronRight />
          </div>

        }
      </li>
    );
  }
}

export default NavigationItem;
