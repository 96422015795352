import Axios from "../utils/axios";

const axios = new Axios();

export const getPersons = async () => {
  try {
    const response = await axios.Get("/person?withWSG=True");
    return response.data;
  } catch (error) {
    console.error("Error fetching firms:", error);
    return [];
  }
};

export const fetchPersonByFirm = async (firmid: any) => {
  try {
    const response = await axios.Get(`./person?firmsId=["${firmid}"]`);
    const data = response.data;
    if (data != null) {
      return data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};
