/* Code generated with AutoHTML Plugin for Figma */

export interface ITrashCanProps { }

export const TrashCan = ({ ...props }: ITrashCanProps): JSX.Element => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41217 21.3535C5.854 21.3535 5.38203 21.162 4.99624 20.7789C4.61046 20.3959 4.41757 19.9273 4.41757 19.373V5.36324H3.15234V3.38279H8.45999V2.37842H15.5263V3.38279H20.8481V5.36324H19.5829V19.373C19.5829 19.9148 19.3869 20.3803 18.9948 20.7696C18.6028 21.1588 18.1339 21.3535 17.5883 21.3535H6.41217ZM17.5883 5.36324H6.41217V19.373H17.5883V5.36324ZM8.99152 17.3361H10.7317V7.36107H8.99152V17.3361ZM13.2687 17.3361H15.023V7.36107H13.2687V17.3361Z"
      />
    </svg>
  );
};
