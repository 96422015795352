import React from "react";

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 16 16"
    >  
        <path
          d="M13.269 14.236c-1.327 0-2.674-.318-4.041-.953-1.367-.634-2.634-1.535-3.8-2.701-1.166-1.167-2.069-2.435-2.706-3.805-.638-1.37-.957-2.716-.957-4.036 0-.278.094-.512.281-.702a.94.94 0 01.695-.284h2.333c.262 0 .479.076.65.228.17.152.288.361.35.629l.45 1.943c.035.235.03.443-.017.624a.933.933 0 01-.282.465l-1.7 1.623c.263.44.543.85.84 1.235.297.385.631.753 1.001 1.106.386.403.783.763 1.192 1.078.408.316.831.588 1.268.817l1.63-1.662c.156-.166.332-.277.528-.334.196-.057.4-.062.61-.015l1.795.405c.267.076.476.206.628.39.152.184.228.406.228.666v2.306a.936.936 0 01-.285.698.952.952 0 01-.691.279zM3.864 6.087l1.34-1.3-.373-1.702H3.104c.016.429.081.886.196 1.37.115.486.303 1.03.564 1.632zm6.178 6.088c.424.198.889.361 1.394.488.505.127.998.205 1.48.233v-1.744l-1.576-.332-1.298 1.355z"
        ></path> 
    </svg>
  );
}

export default Phone;
