function ShareIcon(props: any) {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 50 50"
      fill="#474F56"
    >
      <path d="M36.4875 4.5H43.1063L28.65 21.0187L45.6563 43.5H32.3438L21.9094 29.8688L9.98439 43.5H3.35626L18.8156 25.8281L2.51251 4.5H16.1625L25.5844 16.9594L36.4875 4.5ZM34.1625 39.5437H37.8281L14.1656 8.25H10.2281L34.1625 39.5437Z" />
    </svg>
  );
}

export default ShareIcon;
