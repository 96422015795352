import React from "react";
import DDMultiple, {
  Option,
} from "../../../components/Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../../../components/Catalogs/CatalogFilterButtons";
import Input from "../../../components/Atomos/Inputs/Input";

interface IContentProps {
  title: string;
  setTitle: (name: string) => void;

  firmData: any[];
  selectedFirm: Option[];
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;

  countryData: any[];
  selectedCountry: Option[];
  setSelectedCountry: React.Dispatch<React.SetStateAction<Option[]>>;

  generalTopicData: any[];
  selectedGeneralTopic: Option[];
  setSelectedGeneralTopic: React.Dispatch<React.SetStateAction<Option[]>>;

  globalCrisisData: any[];
  selectedGlobalCrisis: Option[];
  setSelectedGlobalCrisis: React.Dispatch<React.SetStateAction<Option[]>>;

  practiceIndustryData: any[];
  selectedPracticeIndustry: Option[];
  setSelectedPracticeIndustry: React.Dispatch<React.SetStateAction<Option[]>>;

  regionData: any[];
  selectedRegion: Option[];
  setSelectedRegion: React.Dispatch<React.SetStateAction<Option[]>>;

  clearSelections: () => void;
  Search: () => void;
}
const ContentFilter: React.FC<IContentProps> = (props) => {
  return (
    <div className="flex formsection flex-col  w-full filtersbox">
      <div className="grid grid-cols-4 formsection ">
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <Input
            title="Keyword Search:"
            name="inputName"
            value={props.title}
            onChange={(e: any) => props.setTitle(e.target.value)}
            error={false}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Firm:"
            objects={props.firmData}
            selectedObjst={props.selectedFirm}
            seter={props.setSelectedFirm}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Region:"
            objects={props.regionData}
            selectedObjst={props.selectedRegion}
            seter={props.setSelectedRegion}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Country:"
            objects={props.countryData}
            selectedObjst={props.selectedCountry}
            seter={props.setSelectedCountry}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Practice Industry:"
            objects={props.practiceIndustryData}
            selectedObjst={props.selectedPracticeIndustry}
            seter={props.setSelectedPracticeIndustry}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By General Topic:"
            objects={props.generalTopicData}
            selectedObjst={props.selectedGeneralTopic}
            seter={props.setSelectedGeneralTopic}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Global Crisis:"
            objects={props.globalCrisisData}
            selectedObjst={props.selectedGlobalCrisis}
            seter={props.setSelectedGlobalCrisis}
          />
        </div>
        <div className="flex w-full col-span-4 md:col-span-2 lg:col-span-1 items-end justify-end">
          <CatalogFilterButtons
            onClickSearch={props.Search}
            handleFormReset={props.clearSelections}
            headers={[]}
            noColumns={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentFilter;
