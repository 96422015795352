import React, { useEffect, useRef, useState } from 'react';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import InputText, { InputTextRef } from '../../Atomos/Inputs/InputText';
import CatalogFilterButtons from '../../Catalogs/CatalogFilterButtons';
import Input from '../../Atomos/Inputs/Input';


interface IFirmFilterProps {
    regionData: any[];
    jurisdictionData: any[]
    serviceTypeData: any[]
    membershipStatusData: Option[];
    filters: {
      regions: Option[];
      jurisdictionTypes: Option[];
      serviceTypes: Option[];
      membershipStatus: Option[];
    };
    setFilters: React.Dispatch<
      React.SetStateAction<{
        regions: Option[];
        jurisdictionTypes: Option[];
        serviceTypes: Option[];
        membershipStatus: Option[];
      }>
    >;
    clearSelections: () => void;
    headers: string[];
    Search: () => void;
  } 

const JurisdictionFilter: React.FC<IFirmFilterProps> = ({
    regionData,
    jurisdictionData,
    serviceTypeData,
    membershipStatusData,
    filters,
    Search,
    setFilters,
    headers,
    clearSelections,
}) => {


      
      const handleSelectJurisdiction = (selected: Option[]) => {  
        setFilters({ ...filters, jurisdictionTypes: selected });
      }

      const handleSelectServiceType = (selected: Option[]) => {  
        setFilters({ ...filters, serviceTypes: selected});
      }

      const handleSelectMembershipStatus = (selected: Option[]) => {  
        setFilters({ ...filters, membershipStatus: selected});
      }
      
      const handleSelectRegion = (selected: Option[]) => {
        setFilters({ ...filters, regions: selected });
      }

    return (

        <div className='w-full formsection grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 filtersbox'> 
            
            <div>
                <DDMultiple title="By Jurisdiction Type:" objects={jurisdictionData} selectedObjst={filters.jurisdictionTypes} seter={(e:any)=>handleSelectJurisdiction(e)} />
            </div> 
            <div>
                <DDMultiple title="By Region:" objects={regionData} selectedObjst={filters.regions} seter={(e:any)=>handleSelectRegion(e)} />
            </div>
            <div>
                <DDMultiple title="By Service:" objects={serviceTypeData} selectedObjst={filters.serviceTypes} seter={(e:any)=>handleSelectServiceType(e)} />
            </div>
            <div>
                <DDMultiple title="By Status:" objects={membershipStatusData} selectedObjst={filters.membershipStatus} seter={(e:any)=>handleSelectMembershipStatus(e)} />
            </div>



            
            <div className='lg:col-start-4  md:col-start-2 flex justify-end'> 
                <div className='md:max-w-min flex-1 '>
                    <CatalogFilterButtons onClickSearch={Search} handleFormReset={clearSelections} headers={headers} />
                </div>
            </div>
 
        </div>
    );
};

export default JurisdictionFilter;


