import React from 'react';
import CloseModal from '../components/Atomos/Icons/EditMode/CloseModal';
import Folder from '../components/Atomos/Icons/EditMode/Folder';
import Plus from '../components/Atomos/Icons/EditMode/Plus';
import RotateAntiClockwise from '../components/Atomos/Icons/EditMode/RotateAntiClockwise';
import RotateClockwise from '../components/Atomos/Icons/EditMode/RotateClockwise';
import SaveChanges from '../components/Atomos/Icons/EditMode/SaveChanges';
import Trash from '../components/Atomos/Icons/EditMode/Trash';
import TriangleDown from '../components/Atomos/Icons/EditMode/TriangleDown';
import UserIcon from '../components/Atomos/Icons/EditMode/UserIcon';

import AddUser from '../components/Atomos/Icons/ViewMode/AddUser';
import ChevronDownCard from '../components/Atomos/Icons/ViewMode/ChevronDownCard';
import CrossEye from '../components/Atomos/Icons/ViewMode/CrossEye';
import DarkCrossEye from '../components/Atomos/Icons/ViewMode/DarkCrossEye';
import Eye from '../components/Atomos/Icons/ViewMode/Eye';
import Facebook from '../components/Atomos/Icons/ViewMode/Facebook';
import Groups from '../components/Atomos/Icons/ViewMode/Groups';
import Instagram from '../components/Atomos/Icons/ViewMode/Instagram';
import Linkedin from '../components/Atomos/Icons/ViewMode/Linkedin';
import Pencil from '../components/Atomos/Icons/ViewMode/Pencil';
import PlusBlue from '../components/Atomos/Icons/ViewMode/PlusBlue';
import SendMail from '../components/Atomos/Icons/ViewMode/SendMail';
import VCard from '../components/Atomos/Icons/ViewMode/VCard';
import WeChat from '../components/Atomos/Icons/ViewMode/WeChat';
import Whatsapp from '../components/Atomos/Icons/ViewMode/Whatsapp';
import XIcon from '../components/Atomos/Icons/ViewMode/X';
import XDark from '../components/Atomos/Icons/ViewMode/XDark';
import Youtube from '../components/Atomos/Icons/ViewMode/Youtube';
import Phone from '../components/Atomos/Icons/ViewMode/Phone';
import Tune from '../components/Atomos/Icons/ViewMode/Tune';

import Account from '../components/Atomos/Icons/Account';
import Add from '../components/Atomos/Icons/Add';
import ArrowBack from '../components/Atomos/Icons/ArrowBack';
import ArrowDropDown from '../components/Atomos/Icons/ArrowDropDownButton';
import Bar_chart_4_bars from '../components/Atomos/Icons/Bar_chart_4_bars';
import Bars from '../components/Atomos/Icons/Bars';
import BlueCalendar from '../components/Atomos/Icons/BlueCalendar';
import CalendarMonth from '../components/Atomos/Icons/CalendarMonth';
import { Cancel } from '../components/Atomos/Icons/Cancel';
import CancelClear from '../components/Atomos/Icons/CancelClear';
import Check from '../components/Atomos/Icons/Check';
import ChevronDown from '../components/Atomos/Icons/ChevronDown';
import ChevronRight from '../components/Atomos/Icons/ChevronRight';
import ChevronUp from '../components/Atomos/Icons/ChevronUp';
import { CircleRadio } from '../components/Atomos/Icons/CircleRadio';
import Close from '../components/Atomos/Icons/Close';
import { CloseSmall } from '../components/Atomos/Icons/CloseSmall';
import CrossArrows from '../components/Atomos/Icons/CrossArrows';
import CrossPoints from '../components/Atomos/Icons/CrossPoints';
import CustomShuffle from '../components/Atomos/Icons/CustomShuffle';
import Danger from '../components/Atomos/Icons/Danger';
import DarkSearch from '../components/Atomos/Icons/DarkSearch';
import DeleteIcon from '../components/Atomos/Icons/DeleteIcon';
import Drafts from '../components/Atomos/Icons/Drafts';
import Drag_pan from '../components/Atomos/Icons/Drag_pan';
import Email from '../components/Atomos/Icons/Email';
import ErrorCheck from '../components/Atomos/Icons/ErrorCheck';
import ErrorIcon from '../components/Atomos/Icons/ErrorFileInputIcon';
import Excel from '../components/Atomos/Icons/Excel'; 
import Eye2 from '../components/Atomos/Icons/Eye';
import Icon from '../components/Atomos/Icons/Facebook';
import FileInputIcon from '../components/Atomos/Icons/FileInputIcon';
import FillStar from '../components/Atomos/Icons/FillStar';
import Gear from '../components/Atomos/Icons/Gear';
import Google from '../components/Atomos/Icons/Google';
import GroupsAlt from '../components/Atomos/Icons/GroupsAlt';
import HandShake from '../components/Atomos/Icons/HandShake';
import IconPlaceHolder from '../components/Atomos/Icons/IconPlaceHolder';
import Image from '../components/Atomos/Icons/Image';
import Imagesmode from '../components/Atomos/Icons/Imagesmode';
import Insert_text from '../components/Atomos/Icons/Insert_text';
import Linkedin2 from '../components/Atomos/Icons/Linkedin';
import Lock from '../components/Atomos/Icons/Lock';
import Mail from '../components/Atomos/Icons/Mail';
import Microsoft from '../components/Atomos/Icons/Microsoft';
import Network from '../components/Atomos/Icons/Network';
import Notifications from '../components/Atomos/Icons/Notifications';
import OutfillStar from '../components/Atomos/Icons/OutfillStar';
import Overview from '../components/Atomos/Icons/Overview';
import PencilEdit from '../components/Atomos/Icons/PencilEdit';
import Pending from '../components/Atomos/Icons/Pending';
import Phone2 from '../components/Atomos/Icons/Phone';
import PlaceHolderProfile from '../components/Atomos/Icons/PlaceHolderProfile';
import Plus2 from '../components/Atomos/Icons/Plus';
import PlusIcon from '../components/Atomos/Icons/PlusIcon';
import Profilepreferences from '../components/Atomos/Icons/ProfilePreferences';
import Question from '../components/Atomos/Icons/Question';
import QuestionCircle from '../components/Atomos/Icons/QuestionCircle';
import Receipt_long from '../components/Atomos/Icons/Receipt_long';
import RedBars from '../components/Atomos/Icons/RedBars';
import RedPencil from '../components/Atomos/Icons/RedPencil';
import Referrals from '../components/Atomos/Icons/Referrals';
import Remove from '../components/Atomos/Icons/Remove';
import Search from '../components/Atomos/Icons/Search';
import ShapeEdit from '../components/Atomos/Icons/ShapeEdit';
import SortArrow from '../components/Atomos/Icons/SortArrow';
import SortAsending from '../components/Atomos/Icons/SortAsending';
import SortDecending from '../components/Atomos/Icons/SortDecending';
import SortDefault from '../components/Atomos/Icons/SortDefault';
import Success from '../components/Atomos/Icons/Success';
import Star from '../components/Atomos/Icons/Star';
import { TrashCan } from '../components/Atomos/Icons/TrashCan';
import Twitter from '../components/Atomos/Icons/Twitter';
import UnCheck from '../components/Atomos/Icons/UnCheck';
import UpdateIcon from '../components/Atomos/Icons/UpdateIcon';
import User from '../components/Atomos/Icons/User';
import UserRed from '../components/Atomos/Icons/UserRed';
import Video from '../components/Atomos/Icons/Video';
import Warning from '../components/Atomos/Icons/Warning';
import WhiteBars from '../components/Atomos/Icons/WhiteBars';
import WSGCheck from '../components/Atomos/Icons/WSGCheck';
import Sync from '../components/Atomos/Icons/Sync';

const IconTest: React.FC = () => {
    return (
        <>
            {/* EditMode */}
            
            <div className='w-full bg-Default mt-5'>
            <span className=' text-white min-w-full'>Edit Mode Icons</span>
            </div>
            <div className='grid grid-cols-5 fill-gray-font '>
                <div className='w-6 h-6'><CloseModal /></div>
                <div className='w-6 h-6 '><Folder /></div>
                <div className='w-6 h-6'><Plus /></div>
                <div className='w-6 h-6'><RotateAntiClockwise /></div>
                <div className='w-6 h-6'><RotateClockwise /></div>
                <div className='w-6 h-6'><SaveChanges /></div>
                <div className='w-6 h-6'><Trash /></div>
                <div className='w-6 h-6'><TriangleDown /></div>
                <div className='w-6 h-6'><UserIcon /></div>
            </div>
            {/* View Mode */}
            <div className='w-full bg-Default'>
            <span className=' text-white min-w-full'> View Mode Icons</span>
            </div>
           
            <div className='grid grid-cols-5 fill-gray-font'>
                <div className='w-6 h-6'><AddUser /></div>
                <div className='w-6 h-6'><ChevronDownCard /></div>
                <div className='w-6 h-6'><CrossEye /></div>
                <div className='w-6 h-6'><DarkCrossEye /></div>
                <div className='w-6 h-6'><Eye /></div>
                <div className='w-6 h-6'><Facebook /></div>
                <div className='w-6 h-6'><Groups /></div>
                <div className='w-6 h-6'><Instagram /></div>
                <div className='w-6 h-6'><Linkedin /></div>
                <div className='w-6 h-6'><Pencil /></div>
                <div className='w-6 h-6'><Phone /></div>
                <div className='w-6 h-6'><PlusBlue /></div>
                <div className='w-6 h-6'><SendMail /></div>
                <div className='w-6 h-6'><Tune /></div>
                <div className='w-6 h-6'><VCard /></div>
                <div className='w-6 h-6'><WeChat /></div>
                <div className='w-6 h-6'><Whatsapp /></div>
                <div className='w-6 h-6'><XIcon /></div>
                <div className='w-6 h-6'><XDark /></div>
                <div className='w-6 h-6'><Youtube /></div> 
            </div>
            
            <div className='w-full bg-Default'>
            <span className=' text-white min-w-full'>General Icons</span>
            </div>
            <div className='grid grid-cols-5 fill-gray-font'>
            <div className='w-6 h-6 '><Account /></div>
            <div className='w-6 h-6'><Add /></div>
            <div className='w-6 h-6'><ArrowBack /></div>
            <div className='w-6 h-6'><ArrowDropDown /></div>
            <div className='w-6 h-6'><Bar_chart_4_bars /></div>
            <div className='w-6 h-6'><Bars/></div>
            <div className='w-6 h-6'><BlueCalendar /></div>
            <div className='w-6 h-6'><CalendarMonth/></div>
            <div className='w-6 h-6'><Cancel/></div>
            <div className='w-6 h-6'><CancelClear /></div> 
            <div className='w-6 h-6'><Check /></div>
            <div className='w-6 h-6'><ChevronDown /></div>
            <div className='w-6 h-6'><ChevronRight/></div>
            <div className='w-6 h-6'><ChevronUp /></div>
            <div className='w-6 h-6'><CircleRadio /></div>
            <div className='w-6 h-6'><Close/></div>
            <div className='w-6 h-6'><CloseSmall /></div>
            <div className='w-6 h-6'><CrossArrows /></div>
            <div className='w-6 h-6'><CrossPoints /></div>
            <div className='w-6 h-6'><CustomShuffle/></div> 
            <div className='w-6 h-6'><Danger /></div>
            <div className='w-6 h-6'><DarkSearch /></div>
            <div className='w-6 h-6'><DeleteIcon /></div>
            <div className='w-6 h-6'><Drafts /></div>
            <div className='w-6 h-6'><Drag_pan /></div>
            <div className='w-6 h-6'><Email /></div>
            <div className='w-6 h-6'><ErrorCheck /></div>
            <div className='w-6 h-6'><ErrorIcon /></div>
            <div className='w-6 h-6'><Excel /></div>
            <div className='w-6 h-6'><Eye2/></div>
            <div className='w-6 h-6'><Icon /></div> 
            <div className='w-6 h-6'><FileInputIcon /></div> 
            <div className='w-6 h-6'><FillStar/></div> 
            <div className='w-6 h-6'><Gear /></div> 
            <div className='w-6 h-6'><Google /></div> 
            <div className='w-6 h-6'><GroupsAlt /></div> 
            <div className='w-6 h-6'><HandShake /></div> 
            <div className='w-6 h-6'><IconPlaceHolder /></div> 
            <div className='w-6 h-6'><Image /></div> 
            <div className='w-6 h-6'><Imagesmode /></div> 
            <div className='w-6 h-6'><Insert_text /></div> 
            <div className='w-6 h-6'><Linkedin2/></div> 
            <div className='w-6 h-6'><Lock /></div> 
            <div className='w-6 h-6'><Mail /></div> 
            <div className='w-6 h-6'><Microsoft /></div> 
            <div className='w-6 h-6'><Network /></div> 
            <div className='w-6 h-6'><Notifications /></div> 
            <div className='w-6 h-6'><OutfillStar /></div> 
            <div className='w-6 h-6'><Overview/></div> 
            <div className='w-6 h-6'><PencilEdit /></div> 
            <div className='w-6 h-6'><Pending /></div> 
            <div className='w-6 h-6'><Phone2 /></div> 
            <div className='w-6 h-6'><PlaceHolderProfile /></div> 
            <div className='w-6 h-6'><Plus2 /></div> 
            <div className='w-6 h-6'><PlusIcon /></div> 
            <div className='w-6 h-6'><Profilepreferences /></div> 
            <div className='w-6 h-6'><Question/></div> 
            <div className='w-6 h-6'><QuestionCircle /></div> 
            <div className='w-6 h-6'><Receipt_long/></div> 
            <div className='w-6 h-6'><RedBars /></div> 
            <div className='w-6 h-6'><RedPencil /></div> 
            <div className='w-6 h-6'><Referrals /></div> 
            <div className='w-6 h-6'><Remove /></div> 
            <div className='w-6 h-6'><Search /></div> 
            <div className='w-6 h-6'><ShapeEdit /></div> 
            <div className='w-6 h-6'><SortArrow /></div> 
            <div className='w-6 h-6'><SortAsending /></div> 
            <div className='w-6 h-6'><SortDecending /></div> 
            <div className='w-6 h-6'><SortDefault /></div> 
            <div className='w-6 h-6'><Star /></div> 
            <div className='w-6 h-6'><Success /></div> 
            <div className='w-6 h-6'><Sync /></div> 
            <div className='w-6 h-6'><TrashCan /></div> 
            <div className='w-6 h-6'><Tune /></div> 
            <div className='w-6 h-6'><Twitter /></div> 
            <div className='w-6 h-6'><UnCheck /></div> 
            <div className='w-6 h-6'><UpdateIcon /></div> 
            <div className='w-6 h-6'><User /></div> 
            <div className='w-6 h-6'><UserRed /></div> 
            <div className='w-6 h-6'><Video /></div> 
            <div className='w-6 h-6'><Warning /></div> 
            <div className='w-6 h-6'><WhiteBars /></div> 
            <div className='w-6 h-6'><WSGCheck /></div>  

            </div>
        </>
    );
};

export default IconTest;
