import React from "react"; 

const XIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M15.203 1.875h2.758l-6.023 6.883 7.085 9.367h-5.546l-4.348-5.68-4.969 5.68H1.398l6.442-7.363-6.793-8.887h5.687l3.926 5.191 4.543-5.191zm-.969 14.602h1.528l-9.86-13.04h-1.64l9.972 13.04z"></path>
    </svg>
  );
}

export default XIcon;

