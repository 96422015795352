import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import MainBoxDefault from "./MainBoxDefault";
import MainBoxUpdateMembership from "./MainBoxUpdateMembership";

interface IMainBoxProps {
  name: string;
  membershipStatus: string;
  membershipType: string;
  serviceType: string;
  joinedDate?: string;
  membershipOptimizationType?: string;
  dueDate?: string;
  duesPaymentStatusType?: string;
  logo?: string;
  state: any;
}

const MainBox: React.FC<IMainBoxProps> = ({ ...props }) => {
  const [updateMembership, setUpdateMembership] = useState(false);

  const [membershipDetails, setMembershipDetails] = useState({
    membershipStatus: props.membershipStatus,
    membershipType: props.membershipType,
    serviceType: props.serviceType,
    joinedDate: props.joinedDate,
    membershipOptimizationType: props.membershipOptimizationType,
    dueDate: props.dueDate,
    duesPaymentStatusType: props.duesPaymentStatusType,
    logo: props.logo,
    name: props.name,
  });

  const handleSaveMembership = (updatedDetails: any) => {
    setMembershipDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDetails,
    }));
    setUpdateMembership(false);
  };

  return (
    <>
      <article className="articleSection relative">
        {updateMembership ? (
          <MainBoxUpdateMembership
            membershipDetails={membershipDetails}
            setUpdateMembership={setUpdateMembership}
            propsState={props.state}
            onSaveMembership={handleSaveMembership}
          />
        ) : (
          <MainBoxDefault
            membershipDetails={membershipDetails}
            setUpdateMembership={setUpdateMembership}
          />
        )}
      </article>
    </>
  );
};

export default MainBox;
