import { createSlice } from "@reduxjs/toolkit"; 
import { PersonEmpty } from "../emptyStates";

export const personSlice = createSlice({
  name: "state",
  initialState: PersonEmpty,
  reducers: {
    ModifyPerson: (state, action) => ({ ...state, ...action.payload }),
    NewPerson: (_, action) => action.payload,
    ResetPerson: () => PersonEmpty, 
  },
});

export const { NewPerson,  ModifyPerson, ResetPerson } =
  personSlice.actions;
