import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 20 20"
    >
      <path 
        d="M9.374 10.625H4.582v-1.25h4.792V4.583h1.25v4.792h4.791v1.25h-4.791v4.791h-1.25v-4.791z"
      ></path>
    </svg>
  );
}

export default PlusIcon;
