import React, { lazy, useContext, useEffect, useState } from "react";
import PersonDataEdit from "../../components/Moleculas/PersonCatalog/PersonDataEdit";
import PersonSocialEdit from "../../components/Moleculas/PersonCatalog/PersonSocialEdit";
import PersonOfficeEdit from "../../components/Moleculas/PersonCatalog/PersonOfficeEdit";
import { useLocation, useNavigate } from "react-router-dom";
import PersonAOPEdit from "../../components/Moleculas/PersonCatalog/PersonAOPEdit";
import PersonProfessionalCareer from "../../components/Moleculas/PersonCatalog/PersonProfessionalCareerEdit";
import PersonBioEdit from "../../components/Moleculas/PersonCatalog/PersonBio";
import usePersonAdd from "../../hooks/Catalogs/usePersonAdd";
import { IPerson, PersonCatalogsProps } from "../../interfaces";
import '../../assets/css/AddCatalogs.css'
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import { MemberType } from "../../Enums/PersonEnums";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import BgEditMode from '../../assets/images&icons/edit-mode-bg.jpeg'
import Eye from "../../components/Atomos/Icons/ViewMode/Eye";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import ProfileEducationEdit from "../../components/Moleculas/Profile/ProfileEducationEdit";
import NewButton from "../../components/Atomos/Buttons/newButton";
import Cancel from "../../components/Atomos/Icons/Close";
import SaveChanges from "../../components/Atomos/Icons/EditMode/SaveChanges";
import RedDot from "../../components/Atomos/Icons/EditMode/RedDot";
import AuthContext from "../../store/AuthContext";
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const ProfileEditMode: React.FC<PersonCatalogsProps> = (props: PersonCatalogsProps) => {
  const { state } = useLocation();
  const location = useLocation()
  const [saveOverrideOpen, setSaveOverrideOpen] = React.useState(false);
  const { personState, personErrors, offices, officesAdditional, handleAddAdmision, handleMultipleSelectChange, handleInputChange, handleCheckBoxChange, handleRadioButtonChange, handlePhoneNumberChange, handleAddSocialNetworks, handleAddAdditionalOffices, handleTextAreaElement, handleAddlanguages, handlerAreasOfPractice, handleAddIndustryOrganizationAffiliation, handleSubmit, handleDelete, handleButtonClick, setPersonErrors, setRegularPhoto } = usePersonAdd(state);
  const [canSeeOffices, setCanSeeOffices] = useState(false)
  const [urlType, setUrlType] = useState('')

  const { loggedUserId, tokenDecoded } = useContext(AuthContext)

  useEffect(() => {
    if (location.pathname.includes('/staff/')) {
      setUrlType('staff')
    } else {
      setUrlType('member')
    }
  }, [location])

  let token: any = sessionStorage.getItem('token_decoded')
  let parsed = JSON.parse(token)

  useEffect(() => {
    if (parsed && (parsed.extension_WSG_Permissions === 'WSG Staff' || parsed.extension_WSG_Permissions === 'WSG Staff Super')) {
      setCanSeeOffices(true)
    }
  }, [token])

  useEffect(() => {
    if (loggedUserId && personState && tokenDecoded) {
      if (personState.id !== loggedUserId.id) {
        navigate('/404')
      }
    }
  }, [state])

  const handleNavigateProfile = () => {
    if (urlType === "staff") {
      navigate(`/staff/profile/attorney/${personState.name.trim()}-${personState.lastName.trim()}/${personState.generatedId}`);
    } else {
      navigate(`/profile/attorney/${personState.name.trim()}-${personState.lastName.trim()}/${personState.generatedId}`);
    }
  };

  const navigate = useNavigate()

  return (
    <>
      <BackgroundComponent image={BgEditMode} />
      <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
        <form onSubmit={handleSubmit} className="relative">
          <CatalogTitle title={`Profile Update`} className='text-white md:min-w-[23rem]'>
            <div className='min-w-[29rem] min-h-[4.5rem] pl-[1.5rem] overflow-hidden flex-1 hidden relative z-20 lg:flex' style={{
              backgroundImage: `url(${WSGDots.default})`,
            }}>
            </div>
            {/* <button
              onClick={() => ''}
              content="textIcon"
              type="button"
              className={`relative self-stretch h-[3.75rem] min-h-[2.25rem] md:min-h-[3.75rem] md:h-[3.75rem] lg:min-h-[4.5rem]
              flex w-full md:w-auto border border-solid ${urlType === 'staff' ? 'border-Default bg-Default' : 'border-[#BC3323] bg-[#BC3323]'} pl-3 pr-2 min-w-auto gap-3 items-center justify-center py-2  md:py-2 md:pr-2 md:pl-3 md:gap-3 lg:py-[1.4375rem] lg:pl-8 lg:pr-6 lg:gap-4`}
            >
              <span className="text-white text-[1rem] font-medium leading-[1.125rem] font-decimal text-center md:text-[1.125rem] lg:text-[1.25rem] lg:leading-[1.5rem]">PREVIEW PUBLIC PROFILE</span>
              <div className="w-[1.125rem] h-[1.125rem] md:w-5 md:h-5 flex items-center justify-center fill-white">
                <Eye />
              </div>
            </button> */}
          </CatalogTitle>

          <div className="flex self-stretch flex-col gap-[1.25rem]">
            <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
            <div className="flex w-full pb-[1.75rem]">
              <CurrentPage prevPage="HOME > My Profile" currentPage={`Edit Profile`} />
            </div>
          </div>

          <section className='articleSection'>
            {state && state.isOnlySocials ?
              <>
                <div className='flex flex-col items-start gap-6 self-stretch'>
                  <div className='flex flex-col md:flex-row md:justify-end md:items-end md:gap-5 justify-end items-end gap-5  lg:items-center lg:gap-6 self-stretch'>
                    <h4 className='w-full h-full my-auto md:min-w-fit lg:min-w-fit text-[#BC3323]  text-[1.75rem] leading-[2rem] font-decimal md:text-[1.75rem] md:leading-[2rem] lg:text-[2.375rem] font-medium lg:leading-[2.75rem] flex-1'>
                      Profile Update
                    </h4>
                    <div className='flex items-start gap-4 md:w-auto w-full'>
                      <NewButton content='textIcon' color='neutral' style='outlined' text='CANCEL' icon={<Cancel />} className='lg:!min-w-[8.875rem]' onClick={handleNavigateProfile} />
                      <button onClick={() => handleButtonClick('updateButton')} className=' flex-1 h-full  md:w-auto '
                        type="submit">
                        <NewButton content='textIcon' color={urlType === 'staff' ? 'accent' : 'primary'} style='filled' text='Save' icon={<SaveChanges />} className='lg:!min-w-[10rem]' />
                      </button>
                    </div>
                  </div>
                  <div className='flex p-[.625rem] items-start gap-[.625rem] self-stretch border-2 border-solid border-[#EBEBEB]'>
                    <span className='text-base leadin-[1.5rem] text-[#FF6109] text-center md:leading-6 lg:text-[1.125rem] lg:leading-8 flex-1'>
                      To delete profile, contact your firm's data admin - <span className='font-bold leading-[2rem] text-base lg:text-[1.125rem]'>Arvind Gupta</span>
                    </span>
                  </div>
                  <span className='text-[#FF6109] text-base leading-[1.5rem] lg:text-[1.125rem] lg:leading-8 self-stretch text-center'>
                    All fields marked with "*" are required.
                  </span>
                  <hr className='flex h-0 justify-center self-stretch' />
                </div>
                <PersonSocialEdit {...personState} handleAddSosialNetwork={handleAddSocialNetworks} />
                <hr className="flex h-0 justify-center items-center self-stretch" />
                <div className="flex gap-5 ">
                  <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                    <RedDot />
                  </div>
                  <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                    <RedDot />
                  </div>
                  <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                    <RedDot />
                  </div>
                </div>
              </>
              :
              state && state.isOnlyAops ?
                <>
                  <div className='flex flex-col items-start gap-6 self-stretch'>
                    <div className='flex flex-col md:flex-row md:justify-end md:items-end md:gap-5 justify-end items-end gap-5  lg:items-center lg:gap-6 self-stretch'>
                      <h4 className='w-full h-full my-auto md:min-w-fit lg:min-w-fit text-[#BC3323]  text-[1.75rem] leading-[2rem] font-decimal md:text-[1.75rem] md:leading-[2rem] lg:text-[2.375rem] font-medium lg:leading-[2.75rem] flex-1'>
                        Profile Update
                      </h4>
                      <div className='flex items-start gap-4 md:w-auto w-full'>
                        <NewButton content='textIcon' color='neutral' style='outlined' text='CANCEL' icon={<Cancel />} className='lg:!min-w-[8.875rem]' onClick={handleNavigateProfile} />
                        <button onClick={() => handleButtonClick('updateButton')} className=' flex-1 h-full  md:w-auto '
                          type="submit">
                          <NewButton content='textIcon' color={urlType === 'staff' ? 'accent' : 'primary'} style='filled' text='Save' icon={<SaveChanges />} className='lg:!min-w-[10rem]' />
                        </button>
                      </div>
                    </div>
                    <div className='flex p-[.625rem] items-start gap-[.625rem] self-stretch border-2 border-solid border-[#EBEBEB]'>
                      <span className='text-base leadin-[1.5rem] text-[#FF6109] text-center md:leading-6 lg:text-[1.125rem] lg:leading-8 flex-1'>
                        To delete profile, contact your firm's data admin - <span className='font-bold leading-[2rem] text-base lg:text-[1.125rem]'>Arvind Gupta</span>
                      </span>
                    </div>
                    <span className='text-[#FF6109] text-base leading-[1.5rem] lg:text-[1.125rem] lg:leading-8 self-stretch text-center'>
                      All fields marked with "*" are required.
                    </span>
                    <hr className='flex h-0 justify-center self-stretch' />
                  </div>
                  <PersonAOPEdit {...personState} Errors={personErrors} handlerAreasOfPractice={handlerAreasOfPractice} />
                  <hr className="flex h-0 justify-center items-center self-stretch" />
                  <div className="flex gap-5 ">
                    <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                      <RedDot />
                    </div>
                    <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                      <RedDot />
                    </div>
                    <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                      <RedDot />
                    </div>
                  </div>
                </>
                :
                state && state.isOnlyProfessionalCareer ?
                  <>
                    <div className='flex flex-col items-start gap-6 self-stretch'>
                      <div className='flex flex-col md:flex-row md:justify-end md:items-end md:gap-5 justify-end items-end gap-5  lg:items-center lg:gap-6 self-stretch'>
                        <h4 className='w-full h-full my-auto md:min-w-fit lg:min-w-fit text-[#BC3323]  text-[1.75rem] leading-[2rem] font-decimal md:text-[1.75rem] md:leading-[2rem] lg:text-[2.375rem] font-medium lg:leading-[2.75rem] flex-1'>
                          Profile Update
                        </h4>
                        <div className='flex items-start gap-4 md:w-auto w-full'>
                          <NewButton content='textIcon' color='neutral' style='outlined' text='CANCEL' icon={<Cancel />} className='lg:!min-w-[8.875rem]' onClick={handleNavigateProfile} />
                          <button onClick={() => handleButtonClick('updateButton')} className=' flex-1 h-full  md:w-auto '
                            type="submit">
                            <NewButton content='textIcon' color={urlType === 'staff' ? 'accent' : 'primary'} style='filled' text='Save' icon={<SaveChanges />} className='lg:!min-w-[10rem]' />
                          </button>
                        </div>
                      </div>
                      <div className='flex p-[.625rem] items-start gap-[.625rem] self-stretch border-2 border-solid border-[#EBEBEB]'>
                        <span className='text-base leadin-[1.5rem] text-[#FF6109] text-center md:leading-6 lg:text-[1.125rem] lg:leading-8 flex-1'>
                          To delete profile, contact your firm's data admin - <span className='font-bold leading-[2rem] text-base lg:text-[1.125rem]'>Arvind Gupta</span>
                        </span>
                      </div>
                      <span className='text-[#FF6109] text-base leading-[1.5rem] lg:text-[1.125rem] lg:leading-8 self-stretch text-center'>
                        All fields marked with "*" are required.
                      </span>
                      <hr className='flex h-0 justify-center self-stretch' />
                    </div>
                    <PersonProfessionalCareer {...personState} setPersonErrors={setPersonErrors} handleAddIndustryOrganizationAffiliation={handleAddIndustryOrganizationAffiliation} handleTextAreaElement={handleTextAreaElement} />
                    <hr className="flex h-0 justify-center items-center self-stretch" />
                    <div className="flex gap-5 ">
                      <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                        <RedDot />
                      </div>
                      <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                        <RedDot />
                      </div>
                      <div className="w-[1.5625rem] h-[1.5625rem] fill-[#BC3323]">
                        <RedDot />
                      </div>
                    </div>
                  </>
                  :
                  (
                    <>
                      <PersonDataEdit
                        actionButton={handleButtonClick}
                        mode={'edit'}
                        isEditMode {...personState} setPhoto={setRegularPhoto} offices={offices} Errors={personErrors} handlePhoneNumberChange={handlePhoneNumberChange} handleInputChange={handleInputChange} handleCheckBoxChange={handleCheckBoxChange} />
                      <PersonSocialEdit {...personState} handleAddSosialNetwork={handleAddSocialNetworks} />
                      {MemberType.member === personState.memberType &&
                        <PersonOfficeEdit {...personState} offices={officesAdditional} handleAddAdditionalOffices={handleAddAdditionalOffices} />
                      }
                      <PersonBioEdit  {...personState} handleTextAreaElement={handleTextAreaElement} handleAddlanguages={handleAddlanguages} handleAddAdmision={handleAddAdmision} />
                      <ProfileEducationEdit person={personState} />
                      <PersonAOPEdit {...personState} Errors={personErrors} handlerAreasOfPractice={handlerAreasOfPractice} />
                      <PersonProfessionalCareer {...personState} setPersonErrors={setPersonErrors} handleAddIndustryOrganizationAffiliation={handleAddIndustryOrganizationAffiliation} handleTextAreaElement={handleTextAreaElement} />
                    </>
                  )}
          </section>
        </form>
      </main>
    </>
  );
};

export default ProfileEditMode;