import React, { useEffect, useState } from "react";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import FirmNoteFilter from "../../components/Catalogs/Filters/FirmNoteFilter";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import { Link } from "react-router-dom";
import PencilEdit from "../../components/Atomos/Icons/PencilEdit";
import { formatTimeSpanToString } from "../../utils/functions";

const FirmNote: React.FC = () => {
  const Headers: string[] = ["name", "note", "firm", "noteDate", "dateCreated"];
  const DefaultPageSize = 50;
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name', 
    'note', 
    'firm',
    'firmName',
    'noteDate', 
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/firmNote',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columns = generateColumns([
    {
      id: "count",
      header: "#",
      cell: (props: any) => (
        <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
      ),
      enableSorting: false,
      size: 50,
    },
    { id: "name", header: "Name", cell: (props: any) => props.getValue() },
    { id: "note", header: "Note", cell: (props: any) => props.getValue() },
    { id: "firmName", header: "Firm", cell: (props: any) => props.getValue() },
    {
      id: "noteDate",
      header: "Note Date",
      cell: (props: any) => props.getValue(),
    },
    {
      id: "_ts",
      header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      },
    },
    {
      id: "edit",
      header: "Edit",
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg">
            <PencilEdit />
          </div>
        </Link>
      ),
      enableSorting: false,
    },
  ]);

  const [firmNotefilters, setFirmNoteFilters] = useState<{ name: string; firms: Option[] }>({
    name: "",
    firms: [],
  });
  const [tableInstance, setTableInstance] = useState<any>();
  const { firmData } = UseFirmList();

  const clearSelections = () => {
    setFirmNoteFilters({ name: "", firms: [] });
    fetchData(true);
  };

  useEffect(() => {
    if (!firmData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[firmData]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: firmNotefilters.name,
        comparisonType: 'CONTAINS'
      },
      firm: {
        value: firmNotefilters.firms?.map(c => c.id),
        comparisonType: 'EQUAL'
      }
    }
    setFilter(filterAux)
  }, [firmNotefilters]);

  return (
    <>
      <main className=" mainSection">
        <CatalogTableC
          title={`Firm Note Catalog`}
          catalogName={"NOTES"}
          route={"firmNote"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
        >
          <FirmNoteFilter
            key={0}
            Search={fetchData}
            filters={firmNotefilters}
            setFilters={setFirmNoteFilters}
            firmData={firmData}
            headers={Headers}
            clearSelections={clearSelections}
            tableInstance={tableInstance}
          />

          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default FirmNote;
