import "../../assets/css/AddCatalogs.css";
import React, { useEffect, useMemo, useState } from "react";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmPracticeHeadsBackground from "../../assets/images&icons/bg-manageFirmPracticeHeads.png";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import { useLocation } from "react-router-dom";
import MenuGT from "./GeneralTab/Menu";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { Link } from "react-router-dom";
import { transformData } from "../../hooks/Table/TransformColumnsForExport";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import useDataLoader2 from "../../hooks/useDataLoader2";
import Icon from "../../components/Atomos/Icons/Icon";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";

const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const ManageFirmPracticeHeadsTab: React.FC = () => {
  const { state } = useLocation();
  const [aopFiltered, setAopFiltered] = useState<any[]>([]);
  const [exportData, setExportData] = useState<any[]>([]);

  const Headers: string[] = ["AOP", "PracticeHead"];
  const DefaultPageSize = 50;  
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id',
    'name',
    'practiceHead',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/areaOfPractice',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const filteredData = () => {
    if (data && data.length > 0 && state) {
      setAopFiltered(
        data.filter((aop) =>
          aop.firms?.some((firm: any) => firm?.id === state.id)
        )
      );
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      filteredData();
    }
  }, [state, data]);

  const PracticeHeadColumnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false },
    { id: "name", header: "AOP" },
    { id: "practiceHead", header: "Practice Head" },
    {
      id: "edit",
      header: "Edit",
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={{ ...props.row.original, firmId: state.id }}
        >
          {props.row.original.practiceHead &&
          props.row.original.practiceHead.length > 0
            ? "Edit"
            : "Assign"}
          <div className="editsvg">
            <Icon />
          </div>
        </Link>
      ),
      enableSorting: false,
    },
  ];

  const columns = generateColumns(
    PracticeHeadColumnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          switch (column.id) {
            case "count":
              return (
                <p>
                  {props.row.index + 1 + pagination.pageIndex * pagination.pageSize}
                </p>
              );
            case "practiceHead":
              return (
                <p>
                  {props.row.original?.practiceHead?.length > 0
                    ? "Assigned"
                    : "Not Assigned"}
                </p>
              );
            default:
              return <p>{props.getValue()}</p>;
          }
        }),
    }))
  );

  const memoizedData = useMemo(() => aopFiltered, [aopFiltered]);
  const transformedData = useMemo(
    () =>
      transformData(
        memoizedData ? memoizedData : [],
        PracticeHeadColumnConfigs,
        pagination.pageIndex,
        pagination.pageSize
      ),
    [memoizedData, PracticeHeadColumnConfigs, pagination]
  );

  useEffect(() => {
    const newData = transformedData.map((data) => {
      return {
        ...data,
        PracticeHead:
          data["practiceHead"]?.length > 0 ? "Assigned" : "Not Assigned",
        AOP: data["name"],
      };
    });
    setExportData(newData);
  }, [data]);

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  return (
    <>
      <MenuGT state={state} />
      <BackgroundComponent image={ManageFirmPracticeHeadsBackground} />

      <div className="mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex lg:min-h-[70px] gap-4 justify-between">
            <CatalogTitle
              title={`Practice Heads`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm"
              currentPage={`Practice Heads`}
            />
          </div>
        </div>

        <CatalogTableC
          catalogName={"Practice Head"}
          route={"PracticeHead"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={memoizedData?.length ?? 0}
          exportData={exportData}
          noNewCatalog
        >
          <TableWithPagination
            data={data}
            columnsDef={columns}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default ManageFirmPracticeHeadsTab;
