import React, { useEffect, useState } from "react";
import ContactFrame from "./ContactFrame";
import Axios from "../../../utils/axios";
import Vector from "../../Atomos/Icons/Vector";

interface Person {
  id?: string;
  name?: string;
  directPhoneNumber?: string;
  firmEmail?: string;
  directCountryCodePhoneNumber?: string;
  lastName?: string;
}

interface OfficeMembershipProps {
  office?: string;
  contacts?: { type: string; contact: string }[];
  firm?: string;
  filterBy?: string[];
}

const OfficeMembership: React.FC<OfficeMembershipProps> = ({
  office,
  contacts, filterBy
}) => {
  const axios = new Axios();
  const [persons, setPersons] = useState<Person[]>([]); // Initialize as empty array
  const [contactsWithDetails, setContactsWithDetails] = useState<
    {
      contact: string;
      name?: string;
      phoneNumber?: string;
      firmEmail?: string;
      lastName?: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchPersonData = async () => {
      try {
        const response = await axios.Get("./person");
        const data = response.data;
        if (data != null) {
          setPersons(data);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPersonData();
  }, []);

  useEffect(() => {
    if (persons.length > 0 && contacts) {
      const updatedContacts = contacts.map((contact) => {
        const foundPerson = persons.find(
          (person) => person.id === contact.contact
        );
        return {
          ...contact,
          name: foundPerson?.name,
          phoneNumber: foundPerson?.directCountryCodePhoneNumber + " " + foundPerson?.directPhoneNumber,
          firmEmail: foundPerson?.firmEmail,
          countryCodePhoneNumber: foundPerson?.directCountryCodePhoneNumber,
          lastName: foundPerson?.lastName,
        };
      });
      setContactsWithDetails(updatedContacts);
    }
  }, [persons, contacts]);

  
  return (
    <div className="flex flex-col items-left gap-5 md:gap-6 lg:gap-7">
      <div className="flex flex-row items-center self-stretch gap-4">
        <h2 className="not-italic font-medium text-lg leading-5 text-Default font-decimal">
          {office}
        </h2>
        <div className="flex flex-1 "><Vector className="w-full h-0 stroke-gray-50 stroke-1"/></div>
      </div>
      <div className="grid grid-cols-6 gap-5">
        {contactsWithDetails.filter(contact => contact && contact.name).sort((a: any, b: any) => {

          if (filterBy && filterBy.length > 0) {
            for (const filter of filterBy ? filterBy : []) {

              if (a[filter] === b[filter]) {
                continue;
              }
              return a[filter] < b[filter] ? -1 : 1;
            }
          }
          return 0;

        }).map((contact, index) => (
          contact.name &&
          <ContactFrame
            key={index}
            name={contact.name + " " + contact.lastName}
            phone={contact.phoneNumber}
            email={contact.firmEmail}
          />
        ))}
      </div>
    </div>
  );
};

export default OfficeMembership;
