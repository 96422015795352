import React, { useEffect, useRef, useState } from 'react';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import Input from '../../Atomos/Inputs/Input';
import CatalogFilterButtons from '../CatalogFilterButtons';


interface IContentGeneralTopicProps {  
    name: string
    setName: (name: string) => void;
    clearSelections: () => void;
    headers: string[];
    Search: () => void;  
    tableInstance: any;  
} 
const ContentGeneralTopicFilter: React.FC<IContentGeneralTopicProps> = ({name, setName, Search,headers,clearSelections, tableInstance}) => {

    return (
        <div className='flex formsection flex-col md:flex-row lg:flex-row w-full md:flex-wrap lg:flex-nowrap md:items-end'> 
            <div className=' md:w-[48%] lg:w-5/12 flex-1'>
                <Input title="By Name:" name="inputName" value={name} onChange={(e: any) => setName(e.target.value)} error={false}/>
            </div>
            <div >
                <CatalogFilterButtons onClickSearch={Search} handleFormReset={clearSelections} headers={headers} tableInstance={tableInstance}/>
            </div>
        </div>
    );
};

export default ContentGeneralTopicFilter;