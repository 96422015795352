import React, { useEffect, useState } from "react";
import FirmContactFilter from "../../components/Catalogs/Filters/FirmContactFilter";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { Link } from "react-router-dom";
import Eye from "../../components/Atomos/Icons/ViewMode/Eye";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";

const FirmContact: React.FC = () => {
  const Headers: string[] = ["firm", "level"];
  const DefaultPageSize = 50;

  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const { generateColumns } = useColumnConfig(Headers);
  const [tableInstance, setTableInstance] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'contacts', 
    'firm',
    'level', 
    'jurisdictions', 
    'firmName',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/firmContact',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'firmName', header: 'Firm', cell: (props: any) => props.getValue()?.length ? props.getValue() : "No Value ---" },
    { id: 'level', header: 'Level', cell: (props: any) => props.getValue() ? 'Firm' : 'Membership Location' },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`View/${props.row.original.id}`}
          state={props.row.original}
        >
          View
          <div className="editsvg"><Eye /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const clearSelections = () => {
    setSelectedFirm([]);
    fetchData(true);
  };
  const { firmData } = UseFirmList();

  useEffect(() => {
    if (!firmData.length) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[firmData]);

  useEffect(() => {
    setFilter({firm: {
      value: selectedFirm?.map(c => c.id),
      comparisonType: 'EQUAL'
    }})
  },[selectedFirm]);

  return (
    <>
      <div className=' mainSection'>
        <CatalogTableC
          title={`Firm Contact Catalog`}
          catalogName={"FIRM CONTACT"}
          route={"FirmContact"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          noNewCatalog
        >
          <FirmContactFilter
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            search={fetchData}
            headers={Headers}
            resetFilters={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default FirmContact;
