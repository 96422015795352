
 const useTable = () => {

    //Ordering
    let draggedColumnIndex = -1;
    const headers = document.querySelectorAll("th");
   
    const  handleDrop = (event:any,index:any) => {
    if (draggedColumnIndex !== -1) { 
      const targetColumnIndex = index;
      const rows = document.querySelectorAll("tr");
      rows.forEach((row) => {
          const cells = row.getElementsByTagName("td");
          if (cells.length > draggedColumnIndex && cells.length > targetColumnIndex) {
              const temp = cells[draggedColumnIndex].innerHTML;
              cells[draggedColumnIndex].innerHTML = cells[targetColumnIndex].innerHTML;
              cells[targetColumnIndex].innerHTML = temp;
          }
      });
      const tempHeader = headers[draggedColumnIndex].innerHTML;
      headers[draggedColumnIndex].innerHTML = headers[targetColumnIndex].innerHTML;
      headers[targetColumnIndex].innerHTML = tempHeader;
   
          draggedColumnIndex = -1;
      }
    };
   
   const handledragstart = (e:any,index:any)=>{
   draggedColumnIndex = index;  
   const targetElement = e.target as HTMLElement;
   if (targetElement) {
      targetElement.classList.add("dragged");}
    };

    return { handledragstart,handleDrop };
}

export default useTable;