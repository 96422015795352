import { Link } from "react-router-dom";
import "../../../assets/css/AddCatalogs.css";
import "./GeneralTabMenuCSS.css";
import React, { useEffect, useState } from "react";
import Dashboard from "../../../components/Atomos/Icons/Dashboard";
import Apartment from "../../../components/Atomos/Icons/Apartment";
import ClinicalNotes from "../../../components/Atomos/Icons/ClinicalNotes";
import Communication from "../../../components/Atomos/Icons/Communication";
import GroupsAlt from "../../../components/Atomos/Icons/GroupsAlt";
import SupervisorAccount from "../../../components/Atomos/Icons/SupervisorAccount";
import Gavel from "../../../components/Atomos/Icons/Gavel";
import LocalActivity from "../../../components/Atomos/Icons/LocalActivity";

interface IMenuProps {
  state?: any;
  tabSelected?: string;
}

const MenuGT: React.FC<IMenuProps> = ({ state, tabSelected }) => {



  return (
    <div className="w-full overflow-x-auto bg-[#E5F3FF] border-b-[#E5F3FF] scrollbar-hide z-50">
      <ul className="menu-bar">
        <Link to="/Staff/ManageFirm">
          <li className={`menu-item`}>
            <span className=" menu-text ">All Firms</span>
          </li>
        </Link>
        <Link
          to={"/Staff/ManageFirm/GeneralTab/" + (state?.id ?? "")}
          state={{ ...state, selectedTab: "GeneralTab" }}
        >
          <li
            className={
              state?.selectedTab === "GeneralTab"
                ? "bg-[#004578] flex fill-white text-white border-b-[#004578] py-2.5 px-3 w-auto items-center flex-row gap-2 flex-nowrap"
                : "menu-item"
            }
          >
            <span>
              <Dashboard
                className={
                  state?.selectedTab === "GeneralTab"
                    ? "'fill-white w-4 h-4'"
                    : "menu-icon"
                }
              />
            </span>
            <span
              className={
                state?.selectedTab === "GeneralTab"
                  ? "text-center justify-center items-center text-base font-normal not-italic text-white"
                  : " menu-text"
              }
            >
              General
            </span>
          </li>
        </Link>
        <li className="menu-item hover:text-[#E5F3FF]">
          <span>
            <Dashboard className="menu-icon" />
          </span>
          <span className=" menu-text ">Dashboard</span>
        </li>
        <Link
          to={"/Staff/ManageFirm/Profile/" + (state?.id ?? "")}
          state={{ ...state, selectedTab: "Profile" }}
        >
          <li
            className={
              state?.selectedTab === "Profile"
                ? "bg-[#004578] flex fill-white text-white border-b-[#004578] py-2.5 px-3 w-auto items-center flex-row gap-2 flex-nowrap"
                : "menu-item"
            }
          >
            <span>
              <Apartment
                className={
                  state?.selectedTab === "Profile"
                    ? "'fill-white w-4 h-4'"
                    : "menu-icon"
                }
              />
            </span>
            <span
              className={
                state?.selectedTab === "Profile"
                  ? "text-center justify-center items-center text-base font-normal not-italic text-white"
                  : " menu-text"
              }
            >
              Profile
            </span>
          </li>
        </Link>
        <li className={`menu-item`}>
          <span>
            <ClinicalNotes className="menu-icon" />
          </span>
          <span className=" menu-text ">Professionals</span>
        </li>
        <li className="menu-item ">
          <span>
            <Communication className="menu-icon" />
          </span>
          <span className=" menu-text ">Referrals</span>
        </li>
        <li className="menu-item ">
          <span className="menu-icon">
            <GroupsAlt />
          </span>
          <span className=" menu-text ">Group Participants</span>
        </li>
        <Link
          to={"/Staff/ManageFirm/PracticeHeads/" + (state?.id ?? "")}
          state={{ ...state, selectedTab: "PracticeHeads" }}
        >
          <li
            className={
              state?.selectedTab === "PracticeHeads"
                ? "bg-[#004578] flex fill-white text-white border-b-[#004578] py-2.5 px-3 w-auto items-center flex-row gap-2 flex-nowrap"
                : "menu-item"
            }
          >
            <span>
              <SupervisorAccount
                className={
                  state?.selectedTab === "PracticeHeads"
                    ? "'fill-white w-4 h-4'"
                    : "menu-icon"
                }
              />
            </span>
            <span
              className={
                state?.selectedTab === "PracticeHeads"
                  ? "text-center justify-center items-center text-base font-normal not-italic text-white"
                  : " menu-text"
              }
            >
              Practice Heads
            </span>
          </li>
        </Link>
        <Link
          state={{ ...state, selectedTab: "AopManagement" }}
          to="/Staff/ManageFirm/aopManagement"
        >
          <li
            className={
              state?.selectedTab === "AopManagement"
                ? "bg-[#004578] flex fill-white text-white border-b-[#004578] py-2.5 px-3 w-auto items-center flex-row gap-2 flex-nowrap"
                : "menu-item"
            }
          >
            <span>
              <Gavel
                className={
                  state?.selectedTab === "AopManagement"
                    ? "'fill-white w-4 h-4'"
                    : "menu-icon"
                }
              />
            </span>
            <span
              className={
                state?.selectedTab === "AopManagement"
                  ? "text-center justify-center items-center text-base font-normal not-italic text-white"
                  : " menu-text"
              }
            >
              AOP Management
            </span>
          </li>
        </Link>
        <li className="menu-item ">
          <span>
            <LocalActivity className="menu-icon" />
          </span>
          <span className=" menu-text ">Events</span>
        </li>
        <Link
          state={{ ...state, selectedTab: "Content" }}
          to={`/Staff/ManageFirm/Content/${state?.id}`}
        >
          <li
            className={
              state?.selectedTab === "Content"
                ? "bg-[#004578] flex fill-white text-white border-b-[#004578] py-2.5 px-3 w-auto items-center flex-row gap-2 flex-nowrap"
                : "menu-item"
            }
          >
            <span>
              <Dashboard
                className={
                  state?.selectedTab === "Content"
                    ? "'fill-white w-4 h-4'"
                    : "menu-icon"
                }
              />
            </span>
            <span
              className={
                state?.selectedTab === "Content"
                  ? "text-center justify-center items-center text-base font-normal not-italic text-white"
                  : " menu-text"
              }
            >
              Content
            </span>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default MenuGT;
