import { createSlice } from "@reduxjs/toolkit"; 
import { LanguageEmpty } from "../emptyStates"; 
 
export const languageSlice = createSlice({
  name: "state",
  initialState: LanguageEmpty,
  reducers: {
    ModifyLanguage: (state, action) =>  ({...state, ...action.payload}),
    NewLanguage: (_, action) => action.payload, 
    ResetLanguage: () => LanguageEmpty, 
  },
});

export const { NewLanguage,ModifyLanguage, ResetLanguage } = languageSlice.actions;
