import "../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import GoBack from "../../components/Moleculas/GoBack";
import { useLocation } from "react-router-dom";
import MainBox from "./GeneralTab/MainBox/MainBox";
import MembershipCategory from "./GeneralTab/MembershipCategory";
import DataAdminGT from "./GeneralTab/DataAdmin";
import MenuGT from "./GeneralTab/Menu";
import { getDataAdmin } from "../../Services/DataAdmin";
import { fetchPersonByFirm, getPersons } from "../../Services/Person";
import FirmContactGT from "./GeneralTab/FirmContact";
import { getFirmContact } from "../../Services/FirmContact";
import { getJurisdiction } from "../../Services/Jurisdiction";
import MemberMainContactGT from "./GeneralTab/MemberMainContactGT";
import { getMemberMainContact } from "../../Services/MemberMainContact";
import { getOffice } from "../../Services/Office";
import NotesGT from "./GeneralTab/Notes";
import { getAllFirmNote } from "../../Services/FirmNote";
import { useCityList } from "../../hooks/Catalogs/useCityList";
import { useStatesList } from "../../hooks/Catalogs/useStates";
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const ManageFirmGeneralTab: React.FC = () => {
  const { state } = useLocation();
  const [dataAdminList, setDataAdminList] = useState<any[]>([]);
  const [personList, setPersonList] = useState<any[]>([]);
  const [firmPersonList, setFirmPersonList] = useState<any[]>([]);
  const [wsgWorldwide, setWsgWorldwide] = useState("0000");
  const [wsgInJur, setWsgInJur] = useState("0000");
  const [jurisdictionFirmContact, setJurisdictionFirmContact] = useState<any[]>(
    []
  );
  const { cityData } = useCityList();
  const { statesData } = useStatesList();
  const [offices, setOffices] = useState<any[]>([]);
  const [firmNotes, setFirmNotes] = useState<any[]>([]);
  const [firmContact, setFirmContact] = useState<any>([]);
  const [memberMainContact, setMemberMainContact] = useState<any>([]);

  useEffect(() => {
    const fetchDataAdmin = async () => {
      const data = await getDataAdmin();
      const dataAdminList = data.filter(
        (dataAdmin: any) => dataAdmin.firm === state.id
      );
      setDataAdminList(dataAdminList);
    };
    fetchDataAdmin();

    const fetchPerson = async () => {
      const data = await getPersons();
      setPersonList(data);
    };
    fetchPerson();

    const fetchFirmContact = async () => {
      const data = await getFirmContact();
      const firmContactList = data.filter(
        (firmContact: any) => firmContact.firm === state?.id
      );
      setFirmContact(firmContactList);
    };
    fetchFirmContact();

    const fetchJurisdictions = async () => {
      const data = await getJurisdiction();
      setJurisdictionFirmContact(data);
    };
    fetchJurisdictions();

    const fetchOffices = async () => {
      const data = await getOffice();
      setOffices(data);
    };
    fetchOffices();

    const fetchFirmPerson = async () => {
      const data = await fetchPersonByFirm(state?.id);
      const firmPerson = data.filter((person: any) => person.firm === state.id);
      setWsgWorldwide(firmPerson.length);
      setFirmPersonList(firmPerson);
    };
    fetchFirmPerson();

    const fetchMemberMainContact = async () => {
      const data = await getMemberMainContact();
      const memberMainContactList = data.filter(
        (memberMainContact: any) => memberMainContact.firm === state.id
      );
      setMemberMainContact(memberMainContactList);
    };
    fetchMemberMainContact();

    const fetchFirmNote = async () => {
      const data = await getAllFirmNote();
      const firmNoteList = data.filter(
        (firmNote: any) => firmNote.firm === state.id
      );
      setFirmNotes(firmNoteList);
    };
    fetchFirmNote();
  }, [state]);

  useEffect(() => {
    if (state.membershipType === "Primary") {
      const firmOffices = offices.filter(
        (office: any) => office.firm === state.id
      );

      const stateId = cityData.find((c: any) => c.id === state.cityId)?.state
      const countryId = statesData.find((c: any) => c.id === stateId)?.country
      const statesInCountry = statesData.filter((state: any) => state.country === countryId)
      const citiesInCountry = cityData.filter((city: any) => statesInCountry.some(state => state.id === city.state))

      const firmOfficesInMain = firmOffices.filter(
        (office: any) => citiesInCountry.some(city => city.id === office.city)
      );

      const personInMain = firmPersonList.filter((person) =>
        firmOfficesInMain.some(
          (office) =>
            person.mainOffice === office.id ||
            person.additionalOffices?.includes(office.id)
        )
      );

      setWsgInJur(personInMain?.length?.toString());
    }

    if (state.membershipType && state.membershipType !== "Primary") {
      // Filter firm offices for the current firm
      const firmOffices = offices.filter((office: any) => office.firm === state.id);
  
      // Get all jurisdictions related to the firm's jurisdiction group
      const jurisdictionInFirm = jurisdictionFirmContact.filter(
        (jurisdiction) => jurisdiction.jurisdictionGroup === state.jurisdictionGroupId
      );
  
      // Get all the countries related to those jurisdictions
      const countriesInJurisdiction = jurisdictionInFirm.map(
        (jurisdiction) => jurisdiction.country
      );
  
      // Find the states that belong to the countries in the jurisdiction
      const statesInCountries = statesData.filter((state: any) =>
        countriesInJurisdiction.includes(state.country)
      );
  
      // Get the cities that belong to those states
      const citiesInCountries = cityData.filter((city: any) =>
        statesInCountries.some((state: any) => state.id === city.state)
      );
  
      // Filter firm's offices based on whether their city belongs to the found cities
      const firmOfficesInMain = firmOffices.filter((office: any) =>
        citiesInCountries.some((city: any) => city.id === office.city)
      );
  
      // Filter people based on whether their main office or additional offices match the firm's offices
      const personInMain = firmPersonList.filter((person: any) =>
        firmOfficesInMain.some(
          (office: any) =>
            person.mainOffice === office.id ||
            person.additionalOffices?.includes(office.id)
        )
      );
  
      // Set the WSG in Jurisdiction state
      setWsgInJur(personInMain.length.toString());
    }
  }, [offices, firmPersonList, state, jurisdictionFirmContact, cityData, statesData]);

  return (
    <>
      <MenuGT state={state} />
      <BackgroundComponent image={ManageFirmBackground} />

      <div className=" mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex lg:min-h-[70px] gap-4 justify-between">
            <CatalogTitle
              title={`General`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Manage Firm" currentPage={`General`} />
          </div>
        </div>

        <div className="flex flex-col gap-10 justify-end">
          <MainBox
            name={state?.name}
            serviceType={state?.serviceType}
            membershipStatus={state?.membershipStatus}
            membershipType={state?.membershipType}
            logo={state?.logo ?? ""}
            state={state}
          />

          <MembershipCategory
            name={state?.name}
            state={state}
            professionalsInPrimary={wsgInJur ?? "0000"}
            professionalsWorldwide={wsgWorldwide ?? "0000"}
            membershipType={state?.membershipType}
            jurisdiction={state?.jurisdiction}
            firmProfessionalsInPrimary={state?.firmProfessionalsInPrimary}
            firmProfessionalsWorldWide={state?.firmProfessionalsWorldWide}
          />

          <DataAdminGT dataAdminList={dataAdminList} personList={personList} />

          <FirmContactGT
            name={state?.name}
            firmContactList={firmContact}
            personList={personList}
            jurisdictionList={jurisdictionFirmContact}
            membershipType={state?.membershipType}
            firmJurisdiction={state?.jurisdictionId}
          />

          <MemberMainContactGT
            name={state?.name}
            memberMainContactList={memberMainContact}
            personList={personList}
            officeList={offices}
          />

          <NotesGT name={state?.name} noteList={firmNotes} id={state?.id} stateData={state}/>
        </div>
      </div>
    </>
  );
};

export default ManageFirmGeneralTab;
