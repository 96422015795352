import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetFirms } from "../../redux/catalogs/states";
import { getAllFirms } from "../../Services/Firm";

export enum firmType {
  member= 'member',
  nonMember= 'nonMember',
  both = 'both' 
}

const UseFirmList = (firmTypes: firmType = firmType.both) => {
  const [isFetching, setIsFetching] = useState(true);

  const refetchFirms = () => {
    getAllFirms()
      .then((response) => dispatch(SetFirms(response)))
      .catch((error) => console.error(error))
  };

  const dispatch = useDispatch();
  const firms = useSelector((state: Appstore) => state.firms);

  useEffect(() => {
    if (firms.length) {
      setIsFetching(false);
      return;
    }

    if (!isFetching) return;

    getAllFirms()
      .then((response) => {
        dispatch(SetFirms(response))
        if (!response.length) console.error('No firms found. Please check the data source.')
      })
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false));
  }, [isFetching]);

  const firmData: any =
  firmTypes === firmType.member
  ? firms.filter((f) => f.isMember === true)
  : firmTypes === firmType.nonMember
  ? firms.filter((f) => f.isMember === false)
  : firms;
  
  return { firmData, isFetching, refetchFirms };
};

export { UseFirmList };
