import React from 'react';
import css from './ProfileLatest.module.css';
import plus from '../../../../assets/images&icons/next-arrow-blue.svg';
import Card from '../../../../components/Layout/Card/EventCard';

const ProfileLatest: React.FC = () => {
  return (
    <React.Fragment>
      <div className={`lg:w-profileContainer w-11/12 mx-auto md:flex px-0 gap-10 md:gap-4 my-8`}>
        <Card title="ARTICLES" buttonText="VIEW ALL" buttonImage={plus} buttonSecondText='SUBMIT AN ARTICLE'/>
        <Card title="WSG BLOGS" buttonText="VIEW ALL" buttonImage={plus} />
      </div>
      <div className={`lg:w-profileContainer w-11/12 mx-auto md:flex px-0 gap-10 md:gap-4 mb-8`}>

        <Card title="PRESS" buttonText="VIEW ALL" buttonImage={plus} buttonSecondText='SUBMIT A PRESS' />
        <Card title="LATEST NEWS" buttonImage={plus} />
      </div>
    </React.Fragment>
  );
};

export default ProfileLatest;
