import React, { useEffect, useState } from 'react';
import CountryFilter from '../../components/Catalogs/Filters/CountryFilter';
import { Option } from '../../components/Atomos/DropDown/DDMultiple';
import useDataLoader2 from '../../hooks/useDataLoader2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { getRegions } from '../../Services/Region';
import { IRegion } from '../../interfaces';
import { formatTimeSpanToString } from '../../utils/functions';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { transformData } from '../../hooks/Table/TransformColumnsForExport';

const Country: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', 'abbreviation', 'hasState', 'regions', '_ts'];
  const DefaultPageSize = 50;
  const [tableInstance, setTableInstance] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState([] as Option[])
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState('');
  const [abbreviationFilter, setAbbreviationFilter] = useState('');
  const [allRegions, setAllRegions] = useState<IRegion[]>([] as IRegion[])
  const fields = [
    'id', 
    'name',
    'hasState', 
    'abbreviation', 
    'regions', 
    'regionNames', 
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/country',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const { generateColumns } = useColumnConfig(Headers);

  const getRegionData = async () => {
    const data = await getRegions()
    if (data) {
      setAllRegions(data)
    }
  }

  useEffect(() => {
    getRegionData()
  }, [])

  const filterRegions = (regions: IRegion[]) => {
    if (allRegions && regions?.length > 0) {
      const filter = allRegions.filter(allRegions => regions?.some(region => allRegions.id === region.id))
      return <React.Fragment>
        <p>
          {filter.map(f => f.name).join(', ')}
        </p>
      </React.Fragment>
    }
  }

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    { id: 'abbreviation', header: 'Abbreviation' },
    { id: 'hasState', header: 'Has State' },
    { id: 'regionNames', header: 'Regions' },
    { id: '_ts', header: 'Created Date' },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ),
    },
  ];

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>},
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    { id: 'abbreviation', header: 'Abbreviation', cell: (props: any) => props.getValue() },
    { id: 'hasState', header: 'Has State', cell: (props: any) => <p>{props.getValue() ? 'Yes' : 'No'}</p> },
    { id: 'regionNames', header: 'Regions', cell: (props: any) => props.getValue() },
    {
      id: '_ts', header: 'Created Date', cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {" "}
            {value && formatTimeSpanToString(value) }
          </p>
        );
      }
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  }, []);

  const clearSelections = async () => {
    setNameFilter('')
    setAbbreviationFilter('')
    setSelectedRegion([])
    fetchData(true)
  };

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const handleAbbreviationFilterChange = (newAbbreviationFilter: string) => {
    setAbbreviationFilter(newAbbreviationFilter);
  };
  
  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, null, null, 'Country', null, null, null, null, allRegions)

  useEffect(() => {
    let filterAux = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      regions: {
        value: selectedRegion?.map(f => f.id),
        comparisonType: 'ARRAY_CONTAINS',
        arraySearchField: 'id'
      },
      abbreviation: {
        value: abbreviationFilter,
        comparisonType: 'CONTAINS'
      }
    }
    setFilter(filterAux)
  }, [nameFilter, selectedRegion, abbreviationFilter]);
  
  return (
    <>
      <div className=' mainSection'>
          <CatalogTableC
            noRedBox
            title={`Country Catalog`}
            catalogName={'COUNTRIES'}
            catalogShortName={'COUNTRY'}
            route={'Country'}
            isfiltered={false}
            data={data}
            headers={Headers}
            totalCount={totalCount}
            exportData={transformedData}
            regions={allRegions}

          >
            <CountryFilter
              abbreviationFilter={abbreviationFilter}
              onAbbreviationFilterChange={handleAbbreviationFilterChange}
              nameFilter={nameFilter}
              onNameFilterChange={handleNameFilterChange}
              resetFilters={clearSelections}
              search={fetchData}
              regionData={allRegions}
              selectedRegion={selectedRegion}
              setSelectedRegion={setSelectedRegion}
              headers={Headers}
              tableInstance={tableInstance}
            />
            <TableWithPagination
              data={data}
              columnsDef={columns}
              setTableInstance={setTableInstance}
              sorting={sort}
              setSorting={setSort}
              totalCount={totalCount}
              pagination={pagination}
              setPagination={setPagination}
            />
          </CatalogTableC>
        </div>
    </>
  );
};

export default Country;