import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetStates } from "../../redux/catalogs/states";
import { getStates } from "../../Services/State";

export const useStatesList = () => {
  const [isFetching, setIsFetching] = useState(true)

  const dispatch = useDispatch();
  const statesData = useSelector((state: Appstore) => state.states);

  useEffect(() => {
      if (statesData.length) {
        setIsFetching(false)
        return
      }
      if (!isFetching) return

      getStates()
      .then((response) => dispatch(SetStates(response)))
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false))
  }, [isFetching]);


  return {statesData, isFetching};
};
