import { useState, useEffect } from 'react';
import Axios from '../../utils/axios'; 
import { IAreaOfPractice } from '../../interfaces/models/IAreaOfPractice';

const useAreaOfPractice = (): [IAreaOfPractice[], (aops: IAreaOfPractice[]) => void] => {
  const [currentAOPS, setCurrentAOPS] = useState<IAreaOfPractice[]>([]);

  useEffect(() => {
    fetchCurrentAOPS();
  }, []);

  const fetchCurrentAOPS = async () => {
    try {
      const response = await new Axios().Get('/areaOfPractice');
      setCurrentAOPS(response.data as IAreaOfPractice[]);
    } catch (error:any) {
      console.log(error?.message);
    }
  };

  return [currentAOPS, setCurrentAOPS];
};

export default useAreaOfPractice;
