import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import css from './HeaderExtra.module.css';
import ToggleSwitch from "./Toggle";
import { useStaffMode } from "../../../hooks/StaffModeProvider";
import AuthContext from "../../../store/AuthContext";

interface HeaderExtraProps {
  isOpen?: boolean;
  IsStaffNavigate?: boolean;
  onToggle: () => void;
  onNavigate?: () => void;
}

const HeaderExtra: React.FC<HeaderExtraProps> = ({ isOpen, onToggle, IsStaffNavigate, onNavigate }) => {
  const [isStaff, setIsStaff] = useState(false);
  const [userId, setUserId] = useState<any>();
  const { isStaffMode } = useStaffMode();
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const eraseCookie = (name: string) => {
    document.cookie = name + '=; Max-Age=-99999999;';
  };

  const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("access_timestamp");
    sessionStorage.removeItem("token_decoded");

    eraseCookie("token");
    eraseCookie("refresh_token");
    eraseCookie("access_token");
    eraseCookie("access_timestamp");
    eraseCookie("token_decoded");

    setIsAuthenticated(false);

    localStorage.setItem("logout", Date.now().toString());

    window.location.href = "/";
  };

  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === "logout") {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("access_timestamp");
        sessionStorage.removeItem("token_decoded");

        eraseCookie("token");
        eraseCookie("refresh_token");
        eraseCookie("access_token");
        eraseCookie("access_timestamp");
        eraseCookie("token_decoded");

        setIsAuthenticated(false);
        window.location.href = "/";
      }
    };

    window.addEventListener("storage", syncLogout);

    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [setIsAuthenticated]);

  useEffect(() => {
    const tokenDecodedString = sessionStorage.getItem("token_decoded") || getCookie("token_decoded");
    const permissions = tokenDecodedString ? JSON.parse(tokenDecodedString).extension_WSG_Permissions : null;
    setIsStaff(permissions === "WSG Staff" || permissions === "WSG Staff Super");
  }, [isOpen]);

  useEffect(() => {
    const tokenDecodedString = sessionStorage.getItem("token_decoded") || getCookie("token_decoded");
    if (tokenDecodedString) {
      const parsed = JSON.parse(tokenDecodedString);
      setUserId(parsed.oid);
    }
  }, []);

  const navigationClassName = !IsStaffNavigate ? `bg-primary-light` : `bg-secondary-light`;
  const isStaffLink = IsStaffNavigate ? `/staff` : '';

  const navUrl = '/staff';

  return (
    <React.Fragment>
      <div
        id="headerExtra"
        className={`w-full h-auto ${navigationClassName} 
        md:px-6 left-0 md:items-center md:align-middle md:justify-end md:flex-row 
        flex flex-col-reverse
        `}
      >
        <div className="md:order-1">
          <ul className={`md:flex justify-center ml-auto text-white text-lg font-medium roboto_font align-middle`}>
            <li className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}>
              <Link to={`${isStaffLink}/mywsg`} onClick={onNavigate}>
                My WSG
              </Link>
            </li>
            <li className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}>
              <Link to={userId ? `${isStaffMode ? `${navUrl}/profile/attorney/${userId}` : `/profile/attorney/${userId}`}` : ''} onClick={onNavigate}>
                My Profile
              </Link>
            </li>
            <li className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}>
              Referrals
            </li>
            <li className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}>
              Inbox
            </li>
            {IsStaffNavigate &&
              <li className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`}>
                <Link to={`${navUrl}/content`} onClick={onNavigate}>
                  Contents
                </Link>
              </li>
            }
            <li className={`md:mx-3 py-4 cursor-pointer  ${css.link} ${css.leftToRight}`} onClick={handleLogout}>
              Log Out
            </li>
          </ul>
        </div>
        {isStaff &&
          <>
            <div className="w-1 h-12 bg-white border-l border-white mx-4 hidden md:block md:order-2"></div>
            <ToggleSwitch IsStaffNavigate={IsStaffNavigate} onToggle={onToggle} />
          </>
        }
      </div>
    </React.Fragment>
  );
};

export default HeaderExtra;
