import React, { useEffect, useRef, useState } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../CatalogFilterButtons";
import Input from "../../Atomos/Inputs/Input";

interface IUserFilterProps {
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  firmData: any[];
  selectedFirm: Option[];
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;

  accessData: any[];
  selectedAccess: Option[];
  setSelectedAccess: React.Dispatch<React.SetStateAction<Option[]>>;

  clearSelections: () => void;
  headers: string[];
  Search: () => void;
}

const UserFilter: React.FC<IUserFilterProps> = (props) => {

  return (
    <div className="grid grid-cols-6 w-full items-center gap-5">
      <div className="grid col-span-6 md:col-span-3 lg:col-span-2">
        <Input
          name="nameRef"
          title="By Name:"
          value={props.nameFilter}
          onChange={(e) => props.onNameFilterChange(e.target.value)}  
          error={false}
        />
      </div>
      <div className=" grid col-span-6 md:col-span-3 lg:col-span-2">
        <DDMultiple
          title="By Firm:"
          objects={props.firmData}
          selectedObjst={props.selectedFirm}
          seter={props.setSelectedFirm}
        />
      </div>
      <div className="grid col-span-6 md:col-span-3 lg:col-span-2">
        <DDMultiple
          title="By Access:"
          objects={props.accessData}
          selectedObjst={props.selectedAccess}
          seter={props.setSelectedAccess}
        />
      </div>
      <div className="flex items-end justify-end w-full col-span-6">
        <div className="w-full md:w-auto">
          <CatalogFilterButtons
            onClickSearch={props.Search}
            handleFormReset={props.clearSelections}
            headers={props.headers}
          />
        </div>
      </div>
    </div>
  );
};

export default UserFilter;
