import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const contentGeneralTopicsSlice = createSlice({
  name: "contentGeneralTopics",
  initialState: Empty,
  reducers: {
    SetContentGeneralTopics: (_, action) =>  action.payload,
    UpdateContentGeneralTopics:(state, action) => ({...state, ...action.payload})
  },
});

export const { SetContentGeneralTopics, UpdateContentGeneralTopics } = contentGeneralTopicsSlice.actions;
