function Phone2(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 20 20" 
      >
        <path
          d="M16.5855 17.7944C14.9265 17.7944 13.2428 17.3977 11.5342 16.6042C9.82567 15.8107 8.24237 14.685 6.78433 13.2269C5.3263 11.7689 4.19859 10.1836 3.40118 8.47106C2.60378 6.75851 2.20508 5.07674 2.20508 3.42574C2.20508 3.07826 2.3224 2.78607 2.55704 2.54918C2.79169 2.31229 3.08108 2.19385 3.4252 2.19385H6.34187C6.66977 2.19385 6.94046 2.28881 7.15393 2.47872C7.3674 2.66864 7.51369 2.93057 7.59279 3.26451L8.15381 5.69362C8.19828 5.98726 8.19138 6.2473 8.1331 6.47374C8.07482 6.7002 7.95714 6.89411 7.78006 7.05547L5.65437 9.08426C5.98408 9.63257 6.33462 10.1471 6.70599 10.6277C7.07737 11.1084 7.49434 11.5692 7.95691 12.01C8.4394 12.5142 8.93592 12.9635 9.44647 13.3578C9.95704 13.7521 10.4856 14.0924 11.0321 14.3786L13.0701 12.3016C13.264 12.0939 13.4836 11.9544 13.7291 11.8831C13.9745 11.8119 14.2286 11.8059 14.4913 11.865L16.735 12.3714C17.0689 12.4662 17.3308 12.6286 17.5208 12.8587C17.7107 13.0888 17.8056 13.3663 17.8056 13.6911V16.5743C17.8056 16.9229 17.6869 17.2134 17.4495 17.4458C17.212 17.6782 16.924 17.7944 16.5855 17.7944ZM4.82918 7.6087L6.50491 5.98281L6.03752 3.85599H3.87902C3.89893 4.39223 3.98074 4.9634 4.12443 5.56951C4.26811 6.17564 4.50303 6.85537 4.82918 7.6087ZM12.552 15.2183C13.0822 15.4665 13.663 15.67 14.2943 15.8288C14.9257 15.9876 15.5421 16.0848 16.1435 16.1205V13.9405L14.1743 13.5245L12.552 15.2183Z"
        />
      </svg>
    </div>
  );
}

export default Phone2;
