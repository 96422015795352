import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import GoBack from "../../components/Moleculas/GoBack";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, contentContext, contentsSlice } from "../../redux";
import IFirm from "../../interfaces/models/IFirm";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import AddContent from "../Catalogs/CRUD/AddContent";
import { contentTypeEnum } from "../../Enums/ContentEnums";
import { ContentType } from "../../interfaces";
import { formatDateToString } from "../../utils/functions";

interface iCatalogProps {
  mode: "add" | "edit";
  disabledFirm?: boolean
}

const statusClass: any = {
  Yes: "text-feedback-success-Default",
  No: "text-feedback-error",
  Pending: "text-pending",
};

const ManageArticle: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();

  const dispatch = useDispatch();

  const currentContent: contentContext = useSelector(
    (store: Appstore) => store.content
  );

  const { jurisdictionData } = UseJurisdictionList();
  const [isGlobalLocation, setIsGlobalLocation] = useState<boolean>(false);
  const [firms] = useState<IFirm[]>();
  const [contentTitle] = useState<ContentType>(state.contentType || 'article');

  const { firmData: firmsAux } = UseFirmList();

  useEffect(() => {
    let updatedContent = { ...currentContent?.content };

    if (!currentContent?.modified && state?.content) {
      updatedContent = {...state.content};
    }
    if (state?.contentType) {
      updatedContent.contentType = state.contentType;
    }
    if (state?.memberFirms?.length) {
      updatedContent.memberFirms = state.memberFirms;
    }

    if (Object.keys(updatedContent).length) {
      dispatch(
        contentsSlice.actions.ModifyContent({
          ...currentContent,
          content: updatedContent,
        })
      );
    }
  }, []);

  useEffect(() => {
    const content = currentContent?.content;

    setIsGlobalLocation(!!content?.jurisdictions?.includes("GlobalLocation"));
  }, [currentContent?.content]);

  useEffect(() => {
    const firms = firmsAux.filter((f: any) =>
      currentContent?.content?.memberFirms?.includes(f.id)
    );

    firms.map((f: any) => {
      if (f.membershipType !== "Primary") return;

      const jurisdictionsIds = firms.map((m: any) => m.jurisdictionId);
      const relevantJurisdiction = jurisdictionData
        .filter((j: any) => jurisdictionsIds.includes(j.id))
        .map((m: any) => m.id);

      dispatch(
        contentsSlice.actions.ModifyContent({
          content: {
            ...currentContent?.content,
            jurisdictions: relevantJurisdiction,
          },
        })
      );
    });
  }, [currentContent?.content?.memberFirms]);

  useEffect(() => {
    if (isGlobalLocation)
      handleDropdownChange("jurisdictions", "GlobalLocation", false);
  }, [isGlobalLocation]);

  interface DropdownValue {
    id?: string;
  }
  
  const handleDropdownChange = (
    name: string,
    value: string | DropdownValue[],
    multipleOption: boolean = false
  ) => {
    const updatedContent = {
      ...currentContent?.content,
      [name]: multipleOption
        ? (value as DropdownValue[]).map(v => v.id)
        : value,
    };
  
    dispatch(
      contentsSlice.actions.ModifyContent({ content: updatedContent })
    );
  };
  
  const formatDate = (date: Date | 'pending' | undefined) => {
    if (!date) return ''
    return date === "pending"
      ? "Pending"
      : formatDateToString(new Date(date));
  };

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
          <div className=" bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
            <div className="flex flex-col items-start gap-5 self-stretch">
              <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch flex-col ">
                <div className="flex flex-col justify-between gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
                  <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                    {`${props.mode === "edit" ? "Edit" : "New"} ${contentTypeEnum[contentTitle]}`}
                  </h2>
                  <div className="flex justify-end">
                    <GoBack className="!text-white" />
                  </div>
                </div>
                <hr className="flex h-0 justify-center items-center self-stretch relative" />
                <CurrentPage
                  prevPage={"Manage Article"}
                  currentPage={`${props.mode === "edit" ? "Edit" : "New"} ${contentTypeEnum[contentTitle]}`}
                />
                {props.mode === "edit" && (
                  <div className={`flex flex-col bg-white px-10 pb-10 gap-5`}>
                    <div className="grid grid-cols-12 lg:gap-10">
                      <div className="col-span-12 lg:col-span-9">
                        <CatalogTitle
                          title={state?.content?.title}
                          className="text-disclosure-primary-dark text-[32px] lg:text-[42px]"
                        ></CatalogTitle>
                      </div>
                      <div className="flex col-span-3 items-center justify-center md:justify-start lg:justify-center text-accent-Default-dark font-bold max-h-20 lg:max-h-44">
                        <img
                          src={(firms && firms[0].logo) ?? ""}
                          className="border-none h-full"
                        />
                      </div>
                    </div>
                    <div className="font-bold text-[26px] text-gray-font">
                      Status:{" "}
                      <span
                        className={`${statusClass[state?.publishedDate] ?? "text-pending"} `}
                      >
                        {state?.content?.publishedDate ?? "Pending"}
                      </span>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                        <div className="font-bold">Published Date</div>
                        {formatDate(currentContent?.content?.publishedDate)}
                      </div>
                      <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                        <div className="font-bold">Written Date</div>
                        {formatDate(currentContent?.content?.writtenDate)}
                      </div>
                      <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                        <div className="font-bold">Reviewed Date</div>
                        {formatDate(currentContent?.content?.reviewedDate)}
                      </div>
                      <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                        <div className="font-bold">Reviewed By</div>
                        <div>{currentContent?.content?.reviewedBy ?? "-"}</div>
                      </div>
                      <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                        <div className="font-bold">Total Comments</div>
                        <div>{currentContent?.content?.totalComments ?? 0}</div>
                      </div>
                      <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                        <div className="font-bold">Views</div>
                        <div>{currentContent?.content?.views ?? 0}</div>
                      </div>
                    </div>
                  </div>
                )}
                <AddContent 
                  mode={props.mode}
                  embedded={true} 
                  redirectURL={props.disabledFirm 
                    ? `/Staff/ManageFirm/Content/${currentContent?.content?.memberFirms[0]}` 
                    : "/staff/content"}
                  disabledFirm={props.disabledFirm}
                />
              </div>
            </div>
          </div>
    </>
  );
};

export default ManageArticle;
