import NewButton from '../Atomos/Buttons/newButton'
import Danger from '../Atomos/Icons/Danger';
import Close from '../Atomos/Icons/Close'
import '../../assets/css/Text.css'

interface SaveOverrideProps {
    fields:string[]
    handleButtonClick: (buttonClicked: string) => void; 
    open?: boolean;
    title?: string;
    close?: () => void;
}

const SaveOverride: React.FC<SaveOverrideProps> = (props) => {

    if (!props.open) {
        return null; // Don't render anything if not open
    }
     
    return (  
        <div className={`relative flex mt-7 px-4 py-5 md:px-7 lg:px-10 lg:py-5 flex-col items-start gap-6 self-stretch rounded-none border-[2px] border-feedback-error  bg-neutral `}>
            <div className='  flex justify-between x flex-1 items-center w-full'>
                <span className='font-decimal text-[1.75rem] not-italic font-medium leading-[2rem] text-feedback-error '>Sorry, information could not be saved.</span>
                <div className=" absolute right-[0.3125rem] top-[0.3125rem] md:right-5 md:top-5 ">
                <NewButton icon={<Close />} content='iconSquare' style='ghost' color="error" size='medium' onClick={props.close} />
                </div>
            </div>
          
            <div className='flex justify-between gap-6 items-center self-stretch flex-col md:flex-row'>
                <div className=' flex  gap-6 w-full flex-col md:flex-row items-left text-balance md:w-[66%]'> 
                    <span className='text-gray-font text-base leading-[1.125rem] font-normal not-italic min-w-[150px]'>Please review the following:</span> 
                    <div className='flex gap-6 flex-wrap'>
                    {
                        props.fields.map((field, index) => { 
                           return <span key={index} className=' text-feedback-error text-base not-italic font-semibold leading-[1.125rem] '>*{field} </span>
                            })
                    }
                    </div>
                </div>
                <button onClick={()=>{props.handleButtonClick('saveOverride')}} type='submit' className=' md:w-auto w-full '>
                <NewButton
                    text={props.title ? props.title : "SAVE OVERRIDE"}
                    className=' !p-1 md:!text-base md:!leading-[1.125rem] md:!pl-3 md:!pr-2 md:!py-2 !lg:py-[0.815rem] !lg:pr-4 !lg:pl-5 !gap-1 '
                    icon={<Danger/>} 
                    content='textIcon'
                    style='filled'
                    color="delete"
                    size='large' 
                />
                </button>
            </div> 
            <div className="w-full text-right align-self: stretch;">
                <span className="text-font-gray text-base italic font-semibold leading-[18px]">Note:</span>
                <span className="text-font-gray text-base italic font-normal leading-[18px]"> Override does not work for unique errors.</span>
            </div>
            <div className="w-full text-right align-self: stretch;">
                <span className="text-font-gray text-base italic font-semibold leading-[18px]">Note:</span>
                <span className="text-font-gray text-base italic font-normal leading-[18px]"> For Catalog List Relationships, Override will be saved if all fields are completed.</span>
            </div>

        </div> 
    )
}

export default SaveOverride