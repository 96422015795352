import React, { useEffect } from 'react';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';

export type FilterComponentKey = 'country' | 'region' | 'state' | 'substate' | 'name' | 'jurisdictionGroup' | 'multiType' | 'isSecondary';

interface IJurisdictionFilterProps {
  countryData: Option[];
  selectedCountries: Option[];
  setSelectedCountries: React.Dispatch<React.SetStateAction<Option[]>>;
  regionData: Option[];
  selectedRegions: Option[];
  setSelectedRegions: React.Dispatch<React.SetStateAction<Option[]>>;
  stateData: Option[];
  selectedStates: Option[];
  setSelectedStates: React.Dispatch<React.SetStateAction<Option[]>>;
  subStateData: Option[];
  selectedSubStates: Option[];
  setSelectedSubStates: React.Dispatch<React.SetStateAction<Option[]>>;
  jurisdictionGroupData: Option[];
  selectedJurisdictionGroups: Option[];
  setSelectedJurisdictionGroups: React.Dispatch<React.SetStateAction<Option[]>>;
  multiTypeData: Option[];
  selectedMultiTypes: Option[];
  setSelectedMultiTypes: React.Dispatch<React.SetStateAction<Option[]>>;
  isSecondaryData: Option[];
  selectedIsSecondary: Option[];
  setSelectedIsSecondary: React.Dispatch<React.SetStateAction<Option[]>>;
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  selectedTab?: number;
  order: FilterComponentKey[];
  search: () => void;
  resetFilters: () => void;
  headers: any;
  tableInstance: any;
}

const JurisdictionFilter: React.FC<IJurisdictionFilterProps> = ({
  countryData,
  selectedCountries,
  setSelectedCountries,
  regionData,
  selectedRegions,
  setSelectedRegions,
  stateData,
  selectedStates,
  setSelectedStates,
  subStateData,
  selectedSubStates,
  setSelectedSubStates,
  jurisdictionGroupData,
  selectedJurisdictionGroups,
  setSelectedJurisdictionGroups,
  multiTypeData,
  selectedMultiTypes,
  setSelectedMultiTypes,
  isSecondaryData,
  selectedIsSecondary,
  setSelectedIsSecondary,
  nameFilter,
  onNameFilterChange,
  order,
  search,
  resetFilters,
  headers,
  tableInstance,
}) => {


  const setHandler = (key: 'multiTypes' | 'Secondaries', selectedOptions: Option[]) => {
    if (key === 'multiTypes') {
      setSelectedMultiTypes(selectedOptions);
    } else if (key === 'Secondaries') {
      setSelectedIsSecondary(selectedOptions);
    }
  };


  const filterComponents: any = {
    country: (
      <div className="w-full self-stretch">
        <DDMultiple
          title="By Country:"
          objects={countryData || []}
          selectedObjst={selectedCountries || []}
          seter={setSelectedCountries}
        />
      </div>
    ),
    region: (
      <div className="w-full self-stretch">
        <DDMultiple
          title="By Region:"
          objects={regionData || []}
          selectedObjst={selectedRegions || []}
          seter={setSelectedRegions}
        />
      </div>
    ),
    state: (
      <div className="w-full self-stretch">
        <DDMultiple
          title="By State:"
          objects={stateData || []}
          selectedObjst={selectedStates || []}
          seter={setSelectedStates}
        />
      </div>
    ),
    substate: (
      <div className="w-full self-stretch">
        <DDMultiple
          title="By Substate:"
          objects={subStateData || []}
          selectedObjst={selectedSubStates || []}
          seter={setSelectedSubStates}
        />
      </div>
    ),
    name: (
      <div className="w-full flex gap-2.5 justify-between flex-col lg:items-end">
        <label htmlFor="nameFilter" className="inputLabel text-[#474F56 text-left flex justify-start">
          By Name:
        </label>
        <input
          type="text"
          id="nameFilter"
          value={nameFilter}
          onChange={(e) => onNameFilterChange(e.target.value)}
          className="w-full h-auto p-3 bg-[#F4F4F4]"
        />
      </div>
    ),
    jurisdictionGroup: (
      <div className="w-full self-stretch">
        <DDMultiple
          title="By Jurisdiction Group:"
          objects={jurisdictionGroupData || []}
          selectedObjst={selectedJurisdictionGroups || []}
          seter={setSelectedJurisdictionGroups}
        />
      </div>
    ),
    multiType: (
      <div className="w-full self-stretch grid md:grid-cols-2 gap-[.625rem]">
        <DDMultiple
          title="MultiType:"
          objects={multiTypeData || []}
          selectedObjst={multiTypeData.filter((multiType: Option) =>
            selectedMultiTypes.some(selected => selected.id === multiType.id)
          )}
          seter={(e: any) => setHandler("multiTypes", e)}
          
        />
        <DDMultiple
          title="Secondary:"
          objects={isSecondaryData || []}
          selectedObjst={isSecondaryData.filter((isSecondary: Option) =>
            selectedIsSecondary.some(selected => selected.id === isSecondary.id)
          )}
          seter={(e: any) => setHandler("Secondaries", e)}
        />
      </div>
    ),
  };


  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-[.625rem] w-full items-end self-end">
      {order
        .filter(key => key !== 'isSecondary') // Filtramos isSecondary del order
        .map((key) => (
          <div key={key} className="w-full self-stretch">
            {filterComponents[key as FilterComponentKey]}
          </div>
        ))}
      <div className="w-full flex justify-end">
        <CatalogFilterButtons
          onClickSearch={search}
          handleFormReset={resetFilters}
          headers={headers}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
}

export default JurisdictionFilter;
