import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';

export type FilterComponentKey = 'firm' | 'initial' | 'name' | 'practiceIndustries';

interface IAreaOfPracticeFilterProps {
  initialsData: any[];
  firmData: any[];
  selectedInitials: Option[];
  setSelectedInitials: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedFirm: Option[];
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  selectedTab?: number;
  practiceIndustriesData: any[];
  selectedPracticeIndustries: Option[];
  setSelectedPracticeIndustries: React.Dispatch<React.SetStateAction<Option[]>>;
  order: FilterComponentKey[];
  search: () => void;
  resetFilters: () => void;
  headers: any;
  tableInstance: any;

}


const AreaOfPracticeFilter: React.FC<IAreaOfPracticeFilterProps> = ({
  initialsData,
  firmData,
  selectedInitials,
  setSelectedInitials,
  selectedFirm,
  setSelectedFirm,
  nameFilter,
  onNameFilterChange,
  selectedTab,
  practiceIndustriesData,
  selectedPracticeIndustries,
  setSelectedPracticeIndustries,
  order,
  search,
  resetFilters,
  headers,
  tableInstance
}) => {
  const handleInitialsChange = (newSelection: any) => {
    setSelectedInitials(newSelection);
  };

  const filteredOrder = order.filter(key => {
    if (key === 'practiceIndustries') {
      return selectedTab === 1;
    }
    return true;
  });

  const handlePracticeIndustriesChange = (newSelection: Option[]) => {
    setSelectedPracticeIndustries(newSelection);
  };

  const filterComponents = {
    firm: (
      <div className='w-full self-stretch '>
        <DDMultiple
          title="By Firm:"
          objects={firmData || []}
          selectedObjst={selectedFirm || []}
          seter={setSelectedFirm}
        />
      </div>
    ),
    initial: (
      <div className='w-full self-stretch '>
        <DDMultiple title="By Initial:" objects={initialsData} selectedObjst={selectedInitials} seter={setSelectedInitials} />
      </div>
    ),
    name: (
      <div className='w-full flex gap-2.5 justify-between flex-col items-end '>
        <label htmlFor="nameFilter" className='inputLabel text-[#474F56]'>By Name:</label>
        <input
          type="text"
          id="nameFilter"
          value={nameFilter}
          onChange={(e) => onNameFilterChange(e.target.value)}
          className="w-full h-auto p-3 bg-[#F4F4F4]"
        />
      </div>
    ),
    practiceIndustries: (
      <div className='w-full '>
        {
          selectedTab === 1 &&
          <>
            <DDMultiple
              title="By Practice Industry:"
              objects={practiceIndustriesData}
              selectedObjst={selectedPracticeIndustries}
              seter={setSelectedPracticeIndustries}
            />
          </>
        }
      </div>
    ),
  };


  return (
    <div className={`${selectedTab === 1 ? 'md:grid md:grid-cols-2 lg:grid-cols-4 justify-between' : 'md:grid md:grid-cols-2 lg:flex lg:flex-row lg:grid-cols-4 lg:justify-end'} flex flex-col  gap-5 w-full items-end `}>
      {filteredOrder.map(key => (
        <div key={key} className='w-full self-stretch'>
          {filterComponents[key as FilterComponentKey]}
        </div>
      ))}
      <div className={`w-full ${selectedTab === 1 ? 'md:w-max ml-auto col-span-2 lg:col-span-4' : ''}`}>
        <CatalogFilterButtons onClickSearch={search} handleFormReset={resetFilters} headers={headers} tableInstance={tableInstance} />
      </div>
    </div>
  );
};

export default AreaOfPracticeFilter;
