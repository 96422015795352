import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AddContent from "./pages/Catalogs/CRUD/AddContent";
import { Provider } from "react-redux";
import store from "./redux/store";
import checkSessionStatus from "./utils/sessionTimeout";
import { StaffModeProvider, useStaffMode } from "./hooks/StaffModeProvider";
import { ModeProvider } from "./hooks/useMode";
import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import ConfirmLoginPage from "./pages/ConfirmLoginPage/ConfirmLoginPage";
import Login from "./pages/Login/Login";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import CityPage from "./pages/TestingCatalogs/CityPage/CityPage";
import AuthContext from "./store/AuthContext";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import MembersPage from "./pages/MembersPage/MembersPage";
import GroupsPage from "./pages/GroupsPage/GroupsPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";
import LearningInstitutePage from "./pages/LearningInstitutePage/LearningInstitutePage";
import AuthChecker from "./components/Auth/AuthChecker";
import ProfileEditMode from "./pages/ProfileEditMode/ProfileEditMode";
import StaffHomePage from "./pages/StaffHomePage/StaffHomePage";
import Language from "./pages/Catalogs/Language";
import Region from "./pages/Catalogs/Region";
import AddLanguage from "./pages/Catalogs/CRUD/AddLanguage";
import AddRegion from "./pages/Catalogs/CRUD/AddRegion";
import AddJurisdictionGroup from "./pages/Catalogs/CRUD/AddJurisdictionGroup";
import Country from "./pages/Catalogs/Country";
import AddCountry from "./pages/Catalogs/CRUD/AddCountry";
import State from "./pages/Catalogs/State";
import AddState from "./pages/Catalogs/CRUD/AddState";
import City from "./pages/Catalogs/City";
import AddCity from "./pages/Catalogs/CRUD/AddCity";
import SubState from "./pages/Catalogs/SubState";
import AddSubState from "./pages/Catalogs/CRUD/AddSubState";
import Jurisdiction from "./pages/Catalogs/Jurisdiction";
import AddJurisdiction from "./pages/Catalogs/CRUD/AddJurisdiction";
import Degree from "./pages/Catalogs/Degree";
import AddDegree from "./pages/Catalogs/CRUD/AddDegree";
import Firm from "./pages/Catalogs/Firm";
import AddFirm from "./pages/Catalogs/CRUD/AddFirm";
import School from "./pages/Catalogs/School";
import AddSchool from "./pages/Catalogs/CRUD/AddSchool";
import PracticeIndustry from "./pages/Catalogs/PracticeIndustry";
import AddPracticeIndustry from "./pages/Catalogs/CRUD/AddPracticeIndustry";
import ManageFirmList from "./pages/ManageFirm/ManageFirmList";
import Address from "./pages/Catalogs/Address";
import AddAddress from "./pages/Catalogs/CRUD/AddAddress";
import AreaOfPractice from "./pages/Catalogs/AreaOfPractice";
import Office from "./pages/Catalogs/Office";
import AddOffice from "./pages/Catalogs/CRUD/AddOffice";
import Person from "./pages/Catalogs/Person";
import ContentGeneralTopic from "./pages/Catalogs/ContentGeneralTopic";
import AddContentGeneralTopic from "./pages/Catalogs/CRUD/AddContentGeneralTopic";
import GlobalCrisis from "./pages/Catalogs/GlobalCrisis";
import AddGlobalCrisis from "./pages/Catalogs/CRUD/AddGlobalCrisis";
import AOPMapping from "./pages/SEO/AOPMapping";
import ViewMode from "./pages/ProfileViewMode/ViewMode";
import ContentTag from "./pages/Catalogs/ContentTag";
import AddContentTag from "./pages/Catalogs/CRUD/AddContentTag";
import DataAdmin from "./pages/Catalogs/DataAdmin";
import AddDataAdmin from "./pages/Catalogs/CRUD/AddDataAdmin";
import OfficeContact from "./pages/Catalogs/OfficeContacts";
import OfficeContactView from "./pages/Catalogs/OfficeContactsView";
import OfficeContactsEdit from "./pages/Catalogs/OfficeContactsEdit";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";
import AddAOP from "./pages/Catalogs/CRUD/AddAOP";
import FirmContact from "./pages/Catalogs/FirmContact";
import FirmContactView from "./pages/Catalogs/FirmContactView";
import MappingProcess from "./pages/SEO/MappingProcess";
import AddFirmContact from "./pages/Catalogs/CRUD/AddFirmContact";
import Education from "./pages/Catalogs/Education";
import AddEducation from "./pages/Catalogs/CRUD/AddEducation";
import WSG from "./pages/Catalogs/WSG";
import AddWSG from "./pages/Catalogs/CRUD/addWSG";
import PersonAdd from "./pages/Catalogs/CRUD/PersonAdd";
import User from "./pages/Catalogs/User";
import AddUser from "./pages/Catalogs/CRUD/AddUser";
import IconTest from "./pages/IconTest";
import BarAdmission from "./pages/Catalogs/BarAdmission";
import AddBarAdmission from "./pages/Catalogs/CRUD/AddBarAdmission";
import JurisdictionGroup from "./pages/Catalogs/JurisdictionGroup";
import Content from "./pages/Catalogs/Content";
import FirmNote from "./pages/Catalogs/FirmNote";
import AddFirmNote from "./pages/Catalogs/CRUD/AddFirmNote";
import RepresentativeClient from "./pages/Catalogs/RepresentativeClient";
import AddRepresentativeClient from "./pages/Catalogs/CRUD/AddRepresentativeClient";
import AOPRanking from "./pages/Catalogs/AOPRanking";
import AddAOPRanking from "./pages/Catalogs/CRUD/AddAopRanking";
import AopRankingModule from "./pages/AopRanking/AopRankingModule";
import FirmProfile from "./pages/FirmProfile/FirmProfile";
import ManageFirmGeneralTab from "./pages/ManageFirm/GeneralTab";
import ManageFirmProfile from "./pages/ManageFirm/Profile";
import AopManagement from "./pages/ManageFirm/AopManagement/AopManagement";
import AopProfessionalsPage from "./pages/ManageFirm/AopManagement/Professionals";
import UpdateAops from "./pages/ManageFirm/AopManagement/UpdateAops";
import ManageFirmPracticeHeadsTab from "./pages/ManageFirm/PracticeHeads";
import ManageFirmPracticeHeadsEditTab from "./pages/ManageFirm/PracticeHeads/PracticeHeadsEdit";
import ContentHome from "./pages/Content/Home";
import Preview from "./pages/Content/Preview";
import EditArticle from "./pages/Content/EditArticle";
import ManageArticle from "./pages/Content/ManageArticle";
import AnimationLogo from "./components/Layout/LoadingAnimation/AnimationLogo";
import { LoggingProvider } from "./Context/LoggingContext";
import ActivityLog from "./pages/SEO/ActivityLog";
import ActivityLogDetails from "./pages/SEO/ActivityLogDetails";
import MemberArticlesHomePage from "./pages/MemberArticles/HomePage";
import AddContentPerson from "./pages/Catalogs/CRUD/AddPersonContent";
import MemberArticlesManage from "./pages/MemberArticles/ManagePage";
import ForgotpasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ForgotPasswordPage/ResetPasswordPage";
import CreatePageArticles from "./pages/MemberArticles/CreatePage";
import LegacyIFrame from "./pages/Legacy/LegacyIFrame";
import ViewNotes from "./pages/ManageFirm/GeneralTab/Notes/ViewNotes";

function NotFound() {
  const { pageNotFound } = useStaffMode();

  useEffect(() => {
    pageNotFound();
  }, []);

  return null;
}

function App() {
  const { isAuthenticated, tokenDecoded } = useContext(AuthContext);

  const isStaffUser = (tokenDecoded?.extension_WSG_Permissions === "WSG Staff") || (tokenDecoded?.extension_WSG_Permissions === "WSG Staff Super");
  const Wrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <div className="App">
      <Provider store={store}>
        <StaffModeProvider>
          <Header />
          <AuthChecker>
            <ModeProvider>
              <LoggingProvider>
                <Wrapper>
                  <AnimationLogo />

                  <Routes>
                    <Route
                      path="/staff/content"
                      element={isAuthenticated 
                        ? <ContentHome 
                          breadcrumb={{
                            prevPage: "Home",
                            currentPage: "Content",
                          }}/> 
                        : <HomePage />}
                    />
                    <Route
                      path="/staff/content/manage/:id"
                      element={
                        isAuthenticated ? (
                          <ManageArticle mode="edit" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/staff/content/edit/:id"
                      element={
                        isAuthenticated ? (
                          <EditArticle mode="edit" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/staff/content/create"
                      element={
                        isAuthenticated ? (
                          <EditArticle mode="add" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/Staff/Content/Preview"
                      element={
                        isAuthenticated ? (
                          <Preview
                            canComment={true}
                            showComments={true}
                            canEdit={true}
                            breadcrumb={{
                              prevPage: "New Article",
                              currentPage: "Preview",
                            }}
                          />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/staff/content/AddPerson"
                      element={
                        isAuthenticated ? (
                          <AddContentPerson visibles={[]} mode={"add"} />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/staff/profile/edit/attorney/:customPath/:generatedId"
                      element={
                        isAuthenticated ? (
                          <ProfileEditMode visibles={[]} mode="edit" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/staff/mywsg"
                      element={isAuthenticated ? <ProfilePage /> : <HomePage />}
                    />
                    <Route
                      path="/staff/profile/attorney/:userId"
                      element={isAuthenticated ? <ViewMode /> : <HomePage />}
                    />
                    <Route
                      path="/staff/profile/attorney/:customPath/:generatedId"
                      element={isAuthenticated ? <ViewMode /> : <HomePage />}
                    />
                    <Route
                      path="/staff/wsg-learning-institute"
                      element={
                        isAuthenticated ? (
                          <LearningInstitutePage />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route path="/staff/about-us" element={<LegacyIFrame />} />
                    <Route path="/staff/members" element={<LegacyIFrame />} />
                    <Route path="/staff/groups" element={<LegacyIFrame />} />
                    <Route path="/staff/events" element={<LegacyIFrame />} />
                    <Route path="/staff/manage-profile" element={<LegacyIFrame />} />
                    <Route path="/staff/lists" element={<LegacyIFrame />} />
                    <Route path="/staff/" element={<StaffHomePage />} />

                    <Route path="/staff/gc-insights" element={<LegacyIFrame />} />
                    <Route path="/staff/member-blogs" element={<LegacyIFrame />} />
                    <Route path="/staff/featured" element={<LegacyIFrame />} />

                    <Route path="/staff/wsg-blog" element={<LegacyIFrame />} />
                    <Route path="/staff/wsg-knowledge-center" element={<LegacyIFrame />} />
                    <Route path="/staff/secondments" element={<LegacyIFrame />} />
                    <Route path="/staff/dealmakers" element={<LegacyIFrame />} />
                    <Route path="/staff/help-center" element={<LegacyIFrame />} />

                    <Route path="/staff/release" element={<LegacyIFrame />} />
                    <Route path="/staff/collaborations" element={<LegacyIFrame />} />
                    <Route path="/staff/newsletters" element={<LegacyIFrame />} />
                    <Route path="/staff/latest-news" element={<LegacyIFrame />} />
                    <Route path="/staff/submit-article" element={<LegacyIFrame />} />
                    <Route path="/staff/submit-release" element={<LegacyIFrame />} />
                    <Route path="/staff/submit-event" element={<LegacyIFrame />} />

                    <Route path="/staff/leadership-program" element={<LegacyIFrame />} />
                    <Route path="/staff/marketing-workshop" element={<LegacyIFrame />} />
                    <Route path="/staff/emeritus-program" element={<LegacyIFrame />} />

                    <Route path="/staff/covid-19" element={<LegacyIFrame />} />
                    <Route path="/staff/ukraine-crisis" element={<LegacyIFrame />} />

                    <Route path="/staff/send-grid" element={<LegacyIFrame />} />

                    <Route path="/staff/overview/firm" element={<LegacyIFrame />} />
                    <Route path="/staff/overview/events" element={<LegacyIFrame />} />
                    <Route path="/staff/overview/referrals" element={<LegacyIFrame />} />
                    <Route path="/staff/overview/dues" element={<LegacyIFrame />} />

                    <Route path="/staff/google/wsg-site" element={<LegacyIFrame />} />
                    <Route path="/staff/google/firm-profile" element={<LegacyIFrame />} />
                    <Route path="/staff/google/professional-profile" element={<LegacyIFrame />} />
                    <Route path="/staff/google/article" element={<LegacyIFrame />} />
                    <Route path="/staff/google/campaigns" element={<LegacyIFrame />} />

                    <Route path="/staff/firm-score" element={<LegacyIFrame />} />
                    <Route path="/staff/practice-head" element={<LegacyIFrame />} />
                    <Route path="/staff/wsg-contacts" element={<LegacyIFrame />} />

                    <Route path="/staff/firm-referrals" element={<LegacyIFrame />} />
                    <Route path="/staff/secondments" element={<LegacyIFrame />} />
                    <Route path="/staff/dealmaker" element={<LegacyIFrame />} />
                    <Route path="/staff/assessment-fee" element={<LegacyIFrame />} />
                    <Route path="/staff/member-to-member" element={<LegacyIFrame />} />

                    <Route path="/staff/sync-module" element={<LegacyIFrame />} />
                    <Route path="/staff/press-sync" element={<LegacyIFrame />} />
                    <Route path="/staff/rss-feed" element={<LegacyIFrame />} />

                    <Route path="/staff/how-to" element={<LegacyIFrame />} />
                    <Route path="/staff/faq" element={<LegacyIFrame />} />

                    <Route path="/staff/*" element={<NotFound />} />
                    <Route
                      path="/staff/profile/firm/:id"
                      element={isAuthenticated ? <FirmProfile /> : <HomePage />}
                    />
                    <Route
                      path="/Articles"
                      element={
                        isAuthenticated ? (
                          <MemberArticlesHomePage />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/Articles/Public"
                      element={
                        isAuthenticated ? (
                          <Preview
                            breadcrumb={{
                              prevPage: "Articles",
                              currentPage: "Public",
                            }}
                            hideModal
                          />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/Articles/Manage"
                      element={
                        isAuthenticated ? (
                          <MemberArticlesManage />
                        ) : (
                          <HomePage />
                        )
                      }
                    />

                    <Route
                      path="/Articles/Manage/Create"
                      element={
                        isAuthenticated ? (
                          <CreatePageArticles mode="add" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/Articles/Manage/Create/Firm"
                      element={
                        isAuthenticated ? (
                          <AddFirm mode="add" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/Articles/Manage/Create/AddPerson"
                      element={
                        isAuthenticated ? (
                          <AddContentPerson visibles={[]} mode={"add"} disabledFirm/>
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/Articles/Manage/Preview"
                      element={
                        isAuthenticated ? (
                          <Preview
                            hideModal
                            canComment={false}
                            showComments
                            canEdit={false}
                            breadcrumb={{
                              prevPage: "Edit Content",
                              currentPage: "Preview",
                            }}
                          />
                        ) : (
                          <HomePage />
                        )
                      }
                    />

                    <Route
                      path="/Articles/Manage/Edit/:id"
                      element={
                        isAuthenticated ? (
                          <CreatePageArticles mode="edit" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />

                    {isStaffUser && (
                      <>
                      <Route
                        path="/Articles/Manage/Create"
                        element={
                          isAuthenticated ? (
                            <CreatePageArticles mode="add" />
                          ) : (
                            <HomePage />
                          )
                        }
                      />
                      <Route
                        path="/Articles/Manage/Preview"
                        element={
                          isAuthenticated ? (
                            <Preview
                              hideModal
                              canComment={false}
                              showComments
                              canEdit={false}
                              breadcrumb={{
                                prevPage: "Edit Content",
                                currentPage: "Preview",
                              }}
                            />
                          ) : (
                            <HomePage />
                          )
                        }
                      />

                        <Route
                          path="/Staff/Catalogs/Person/Create"
                          element={
                            isAuthenticated ? (
                              <PersonAdd mode="add" visibles={[]} />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Person/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <PersonAdd mode="edit" visibles={[]} />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Person/"
                          element={isAuthenticated ? <Person /> : <HomePage />}
                        />

                        <Route
                          path="/Staff/Catalogs/Language"
                          element={
                            isAuthenticated ? <Language /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Language/Create"
                          element={
                            isAuthenticated ? (
                              <AddLanguage mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Language/edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddLanguage mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/barAdmission"
                          element={
                            isAuthenticated ? <BarAdmission /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/barAdmission/Create"
                          element={
                            isAuthenticated ? (
                              <AddBarAdmission mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/barAdmission/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddBarAdmission mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Country"
                          element={isAuthenticated ? <Country /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Country/Create"
                          element={
                            isAuthenticated ? (
                              <AddCountry mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Country/edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddCountry mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Region"
                          element={isAuthenticated ? <Region /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Region/Create"
                          element={
                            isAuthenticated ? (
                              <AddRegion mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Region/edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddRegion mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Jurisdiction"
                          element={
                            isAuthenticated ? <Jurisdiction /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Jurisdiction/Create"
                          element={
                            isAuthenticated ? (
                              <AddJurisdiction mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Jurisdiction/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddJurisdiction mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/JurisdictionGroup"
                          element={
                            isAuthenticated ? (
                              <JurisdictionGroup />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/JurisdictionGroup/Create"
                          element={
                            isAuthenticated ? (
                              <AddJurisdictionGroup mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/JurisdictionGroup/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddJurisdictionGroup mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/City"
                          element={isAuthenticated ? <City /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/City/Create"
                          element={
                            isAuthenticated ? (
                              <AddCity mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/City/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddCity mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Degree"
                          element={isAuthenticated ? <Degree /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Degree/Create"
                          element={
                            isAuthenticated ? (
                              <AddDegree mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Degree/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddDegree mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/School"
                          element={isAuthenticated ? <School /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/School/Create"
                          element={
                            isAuthenticated ? (
                              <AddSchool mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/School/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddSchool mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/State"
                          element={isAuthenticated ? <State /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/State/Create"
                          element={
                            isAuthenticated ? (
                              <AddState mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/State/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddState mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/SubState"
                          element={
                            isAuthenticated ? <SubState /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/SubState/Create"
                          element={
                            isAuthenticated ? (
                              <AddSubState mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/SubState/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddSubState mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Firm"
                          element={isAuthenticated ? <Firm /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Firm/Create"
                          element={
                            isAuthenticated ? (
                              <AddFirm mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Firm/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddFirm mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Address"
                          element={isAuthenticated ? <Address /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Address/Create"
                          element={
                            isAuthenticated ? (
                              <AddAddress mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Address/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAddress mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Office"
                          element={isAuthenticated ? <Office /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Office/Create"
                          element={
                            isAuthenticated ? (
                              <AddOffice mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Office/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddOffice mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/PracticeIndustry"
                          element={
                            isAuthenticated ? (
                              <PracticeIndustry />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/PracticeIndustry/Create"
                          element={
                            isAuthenticated ? (
                              <AddPracticeIndustry mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/PracticeIndustry/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddPracticeIndustry mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Firm/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddFirm mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/ManageFirm/"
                          element={
                            isAuthenticated ? <ManageFirmList /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/GeneralTab/:id"
                          element={
                            isAuthenticated ? (
                              <ManageFirmGeneralTab />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/GeneralTab/:id/Notes"
                          element={
                            isAuthenticated ? (
                              <ViewNotes />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/Content/:id"
                          element={
                            isAuthenticated ? <ContentHome 
                            breadcrumb={{
                              prevPage: "ManageFirm",
                              currentPage: "Content",
                            }}
                            /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/Content/Manage/:id"
                          element={
                            isAuthenticated ? (
                              <ManageArticle mode="edit" disabledFirm />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/Content/Create"
                          element={
                            isAuthenticated ? (
                              <EditArticle mode="add" disabledFirm />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/Content/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <EditArticle mode="edit" disabledFirm />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/Profile/:id"
                          element={
                            isAuthenticated ? (
                              <ManageFirmProfile />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/aopManagement"
                          element={
                            isAuthenticated ? <AopManagement /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/aopManagement/professionals/:id"
                          element={
                            isAuthenticated ? (
                              <AopProfessionalsPage />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/staff/manageFirm/aopManagement/update"
                          element={
                            isAuthenticated ? <UpdateAops /> : <HomePage />
                          }
                        />

                        <Route
                          path="/Staff/ManageFirm/PracticeHeads/:id"
                          element={
                            isAuthenticated ? (
                              <ManageFirmPracticeHeadsTab />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ManageFirm/PracticeHeads/:id/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <ManageFirmPracticeHeadsEditTab />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/FirmContact"
                          element={
                            isAuthenticated ? <FirmContact /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmContact/View/:id"
                          element={
                            isAuthenticated ? <FirmContactView /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmContact/Edit/:id"
                          element={
                            isAuthenticated ? <AddFirmContact /> : <HomePage />
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/WSG"
                          element={isAuthenticated ? <WSG /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/WSG/Create"
                          element={
                            isAuthenticated ? (
                              <AddWSG mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/WSG/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddWSG mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/User"
                          element={isAuthenticated ? <User /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/User/Create"
                          element={
                            isAuthenticated ? (
                              <AddUser mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/User/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddUser mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/DataAdmin"
                          element={
                            isAuthenticated ? <DataAdmin /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/DataAdmin/Create"
                          element={
                            isAuthenticated ? (
                              <AddDataAdmin mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/DataAdmin/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddDataAdmin mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/MemberMainContact"
                          element={
                            isAuthenticated ? <OfficeContact /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/MemberMainContact/View/:id"
                          element={
                            isAuthenticated ? (
                              <OfficeContactView />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/MemberMainContact/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <OfficeContactsEdit />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/FirmNote"
                          element={
                            isAuthenticated ? <FirmNote /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmNote/Create"
                          element={
                            isAuthenticated ? (
                              <AddFirmNote mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmNote/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddFirmNote mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/AreaOfPractice"
                          element={
                            isAuthenticated ? <AreaOfPractice /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AreaOfPractice/Create"
                          element={
                            isAuthenticated ? (
                              <AddAOP
                                returnPage="/Staff/Catalogs/AreaOfPractice"
                                mode="add"
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AreaOfPractice/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAOP
                                returnPage="/Staff/Catalogs/AreaOfPractice"
                                mode="edit"
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/RepresentativeClient"
                          element={
                            isAuthenticated ? (
                              <RepresentativeClient />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/RepresentativeClient/Create"
                          element={
                            isAuthenticated ? (
                              <AddRepresentativeClient mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/RepresentativeClient/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddRepresentativeClient mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/AOPRanking"
                          element={
                            isAuthenticated ? <AOPRanking /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AOPRanking/Create"
                          element={
                            isAuthenticated ? (
                              <AddAOPRanking mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AOPRanking/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAOPRanking mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/FirmContact"
                          element={
                            isAuthenticated ? <FirmContact /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmContact/View/:id"
                          element={
                            isAuthenticated ? <FirmContactView /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmContact/Edit/:id"
                          element={
                            isAuthenticated ? <AddFirmContact /> : <HomePage />
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/WSG"
                          element={isAuthenticated ? <WSG /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/WSG/Create"
                          element={
                            isAuthenticated ? (
                              <AddWSG mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/WSG/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddWSG mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/User"
                          element={isAuthenticated ? <User /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/User/Create"
                          element={
                            isAuthenticated ? (
                              <AddUser mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/User/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddUser mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/DataAdmin"
                          element={
                            isAuthenticated ? <DataAdmin /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/DataAdmin/Create"
                          element={
                            isAuthenticated ? (
                              <AddDataAdmin mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/DataAdmin/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddDataAdmin mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/MemberMainContact"
                          element={
                            isAuthenticated ? <OfficeContact /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/MemberMainContact/View/:id"
                          element={
                            isAuthenticated ? (
                              <OfficeContactView />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/MemberMainContact/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <OfficeContactsEdit />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/FirmNote"
                          element={
                            isAuthenticated ? <FirmNote /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmNote/Create"
                          element={
                            isAuthenticated ? (
                              <AddFirmNote mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/FirmNote/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddFirmNote mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/AreaOfPractice"
                          element={
                            isAuthenticated ? <AreaOfPractice /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AreaOfPractice/Create"
                          element={
                            isAuthenticated ? (
                              <AddAOP
                                returnPage="/Staff/Catalogs/AreaOfPractice"
                                mode="add"
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AreaOfPractice/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAOP
                                returnPage="/Staff/Catalogs/AreaOfPractice"
                                mode="edit"
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/RepresentativeClient"
                          element={
                            isAuthenticated ? (
                              <RepresentativeClient />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/RepresentativeClient/Create"
                          element={
                            isAuthenticated ? (
                              <AddRepresentativeClient mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/RepresentativeClient/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddRepresentativeClient mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/AOPRanking"
                          element={
                            isAuthenticated ? <AOPRanking /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AOPRanking/Create"
                          element={
                            isAuthenticated ? (
                              <AddAOPRanking mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/AOPRanking/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAOPRanking mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/ActivityLog"
                          element={
                            isAuthenticated ? <ActivityLog /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/ActivityLog/Details/:id"
                          element={
                            isAuthenticated ? (
                              <ActivityLogDetails />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Education"
                          element={
                            isAuthenticated ? <Education /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Education/Create"
                          element={
                            isAuthenticated ? (
                              <AddEducation mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Education/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddEducation mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/ContentGeneralTopic"
                          element={
                            isAuthenticated ? (
                              <ContentGeneralTopic />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/ContentGeneralTopic/Create"
                          element={
                            isAuthenticated ? (
                              <AddContentGeneralTopic mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/ContentGeneralTopic/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddContentGeneralTopic mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/GlobalCrisis"
                          element={
                            isAuthenticated ? <GlobalCrisis /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/GlobalCrisis/Create"
                          element={
                            isAuthenticated ? (
                              <AddGlobalCrisis mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/GlobalCrisis/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddGlobalCrisis mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/PublicationTag"
                          element={
                            isAuthenticated ? <ContentTag /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/PublicationTag/Create"
                          element={
                            isAuthenticated ? (
                              <AddContentTag mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/PublicationTag/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddContentTag mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/Catalogs/Content"
                          element={isAuthenticated ? <Content /> : <HomePage />}
                        />
                        <Route
                          path="/Staff/Catalogs/Content/Create"
                          element={
                            isAuthenticated ? (
                              <AddContent
                                mode="add"
                                redirectURL="/Staff/Catalogs/Content"
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Content/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddContent
                                mode="edit"
                                redirectURL="/Staff/Catalogs/Content"
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/Catalogs/Content/Preview"
                          element={
                            isAuthenticated ? (
                              <Preview
                                canComment={false}
                                showComments={false}
                                canEdit={false}
                                breadcrumb={{
                                  prevPage: "Edit Content",
                                  currentPage: "Preview",
                                }}
                              />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/AOP"
                          element={
                            isAuthenticated ? <AOPMapping /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/AOP/Create"
                          element={
                            isAuthenticated ? (
                              <AddAOP returnPage="/Staff/AOP" mode="add" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/AOP/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAOP returnPage="/Staff/AOP" mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/AOP/Mapping/Edit/:id"
                          element={
                            isAuthenticated ? <MappingProcess /> : <HomePage />
                          }
                        />

                        <Route
                          path="/Staff/ActivityLog"
                          element={
                            isAuthenticated ? <ActivityLog /> : <HomePage />
                          }
                        />
                        <Route
                          path="/Staff/AOPRanking"
                          element={
                            isAuthenticated ? (
                              <AopRankingModule />
                            ) : (
                              <HomePage />
                            )
                          }
                        />
                        <Route
                          path="/Staff/AOPRanking/Edit/:id"
                          element={
                            isAuthenticated ? (
                              <AddAOPRanking mode="edit" />
                            ) : (
                              <HomePage />
                            )
                          }
                        />

                        <Route
                          path="/Staff/IconTest"
                          element={
                            isAuthenticated ? <IconTest /> : <HomePage />
                          }
                        />
                      </>
                    )}
                    <Route
                      path="/mywsg"
                      element={isAuthenticated ? <ProfilePage /> : <HomePage />}
                    />
                    <Route
                      path="/mywsg/ID_password/:userId"
                      element={
                        isAuthenticated ? <UpdatePassword /> : <HomePage />
                      }
                    />
                    <Route
                      path="/staff/mywsg/ID_password/:userId"
                      element={
                        isAuthenticated ? <UpdatePassword /> : <HomePage />
                      }
                    />
                    <Route
                      path="/profile/attorney/:userId"
                      element={isAuthenticated ? <ViewMode /> : <HomePage />}
                    />
                    <Route
                      path="/profile/attorney/:customPath/:generatedId"
                      element={isAuthenticated ? <ViewMode /> : <HomePage />}
                    />
                    <Route
                      path="/wsg-learning-institute"
                      element={
                        isAuthenticated ? (
                          <LearningInstitutePage />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/profile/edit/attorney/:customPath/:generatedId"
                      element={
                        isAuthenticated ? (
                          <ProfileEditMode visibles={[]} mode="edit" />
                        ) : (
                          <HomePage />
                        )
                      }
                    />
                    <Route
                      path="/profile/idPassword"
                      element={
                        isAuthenticated ? <UpdatePassword /> : <HomePage />
                      }
                    />
                    <Route
                      path="/profile/firm/:id"
                      element={isAuthenticated ? <FirmProfile /> : <HomePage />}
                    />

                    <Route path="/" element={<HomePage />} />
                    <Route path="/forgot-password" element={<ForgotpasswordPage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/confirmlogin"
                      element={<ConfirmLoginPage />}
                    />
                    <Route path="/about-us" element={<LegacyIFrame />} />
                    <Route path="/members" element={<LegacyIFrame />} />
                    <Route path="/groups" element={<LegacyIFrame />} />
                    <Route path="/events" element={<LegacyIFrame />} />

                    <Route path="/gc-insights" element={<LegacyIFrame />} />
                    <Route path="/member-blogs" element={<LegacyIFrame />} />
                    <Route path="/featured" element={<LegacyIFrame />} />

                    <Route path="/wsg-blog" element={<LegacyIFrame />} />
                    <Route path="/wsg-knowledge-center" element={<LegacyIFrame />} />
                    <Route path="/secondments" element={<LegacyIFrame />} />
                    <Route path="/dealmakers" element={<LegacyIFrame />} />
                    <Route path="/help-center" element={<LegacyIFrame />} />

                    <Route path="/release" element={<LegacyIFrame />} />
                    <Route path="/collaborations" element={<LegacyIFrame />} />
                    <Route path="/newsletters" element={<LegacyIFrame />} />
                    <Route path="/latest-news" element={<LegacyIFrame />} />
                    <Route path="/submit-article" element={<LegacyIFrame />} />
                    <Route path="/submit-release" element={<LegacyIFrame />} />
                    <Route path="/submit-event" element={<LegacyIFrame />} />

                    <Route path="/leadership-program" element={<LegacyIFrame />} />
                    <Route path="/marketing-workshop" element={<LegacyIFrame />} />
                    <Route path="/emeritus-program" element={<LegacyIFrame />} />

                    <Route path="/covid-19" element={<LegacyIFrame />} />
                    <Route path="/ukraine-crisis" element={<LegacyIFrame />} />
                  </Routes>
                </Wrapper>
              </LoggingProvider>
            </ModeProvider>
          </AuthChecker>
          <Footer />
        </StaffModeProvider>
      </Provider>
    </div>
  );
}

export default App;
