import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Input from "../../../components/Atomos/Inputs/Input";
import '../../../assets/css/AddCatalogs.css';
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import TextArea from "../../../components/Atomos/Inputs/TextArea";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import { getStates, getStatesListOptions } from "../../../Services/State";
import { getAllCities, getCitiesListOptions } from "../../../Services/City";
import { UseCountryList } from "../../../hooks/Catalogs";
import { useLogging } from "../../../Context/LoggingContext";
import { useStatesList } from "../../../hooks/Catalogs/useStates";
import { useCityList } from "../../../hooks/Catalogs/useCityList";
import { getNameById, updateData } from "../../../utils/functions";
import { UseRegionList } from "../../../hooks/Catalogs/useRegionListOptions";

interface Address {
  id?: string;
  address1?: string;
  address2?: string;
  noZipCode: boolean;
  zipCode?: string;
  city?: string;
  addressType?: string;
  venueLink?: string;
  notes?: string;
  altitude?: number;
  latitude?: number;
}

interface iCatalogProps {
  mode: "add" | "edit";
}

type listobj = { id: string, name: string };

interface iCatalogProps {
  mode: "add" | "edit";
}


type error = { error: boolean, msj: string };
type errors = {
  address1: error;
  country: error;
  state: error;
  city: error;
  zipCode: error;
  addressType: error;
  notes: error;
};

const AddAddress: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const location = useLocation()
  const [address, setAddress] = useState<Address>(state ? { ...state } : { address1: '', address2: '', zipCode: '', noZipCode: false, city: '', addressType: '', venueLink: '', notes: ''});
  const [buttonClicked, setButtonClicked] = useState('');
  const [stateData, setStateData] = useState<any[]>([]);
  const [cityData, setCityData] = useState<any[]>([]);
  const [errors, setErrors] = useState<errors>({
    address1: { error: false, msj: '' },
    country: { error: false, msj: '' },
    state: { error: false, msj: '' },
    city: { error: false, msj: '' },
    zipCode: { error: false, msj: '' },
    addressType: { error: false, msj: '' },
    notes: { error: false, msj: '' }
  });
  const [selectedCountry, setSelectedCountry] = useState<listobj>({ id: state?.countryId || '', name: state?.country || '' });
  const [selectedState, setSelectedState] = useState<listobj>({ id: state?.stateId || '', name: state?.state || '' });
  const [selectedCity, setSelectedCity] = useState({ id: state?.cityId || '', name: state?.city || '' });
  const [selectedAddressType, setSelectedAddressType] = useState(state?.addressType ?? '');
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const { countryData } = UseCountryList();
  const { statesData } = useStatesList();
  const { cityData: citiesList } = useCityList();
  const { logActivity } = useLogging();
  const { regionData } = UseRegionList();

  useEffect(() => {
    setErrors({
      address1: { error: false, msj: '' },
      country: { error: false, msj: '' },
      state: { error: false, msj: '' },
      city: { error: false, msj: '' },
      zipCode: { error: false, msj: '' },
      addressType: { error: false, msj: '' },
      notes: { error: false, msj: '' }
    });
  }, []);

  useEffect(() => {
    if (!citiesList.length || !statesData.length || !countryData.length) return

    const currentCity = citiesList?.find(c => c.id === state?.city)
    const currentState = statesData?.find(s => s.id === currentCity?.state)
    const currentCountry = countryData?.find(c => c.id === currentState?.country)

    setSelectedCity({ id: currentCity?.id, name: currentCity?.name })
    setSelectedState({ id: currentState?.id, name: currentState?.name })
    setSelectedCountry({ id: currentCountry?.id, name: currentCountry?.name })
  }, [citiesList, statesData, countryData, state]);

  useEffect(() => {
    if (!selectedCountry.id) return
    getStates().then((data) => {
      if (data != null) {
        const newStates = data.filter(
          (item: any) => item.country === selectedCountry.id
        );
        setStateData(newStates);
      }
    });
    if (selectedCountry.id === "")
      setSelectedState({ id: state?.stateId ?? "", name: state?.state ?? "" });
  }, [selectedCountry]);

  useEffect(() => {
    if (!selectedState.id) return
    getAllCities().then((data) => {
      if (data != null) {
        const newCities = data.filter(
          (item: any) => item.state === selectedState.id
        );
        setCityData(newCities);
      }
    });
    if (selectedState.id === "")
      setSelectedCity({ id: state?.cityId ?? "", name: state?.city ?? "" });
  }, [selectedState]);

  useEffect(() => {
    if (errors.address1.error || errors.country.error || errors.state.error || errors.city.error || errors.addressType.error || errors.zipCode.error)
      validateForm();
  }, [address, selectedAddressType, selectedState, selectedCountry, selectedState]);

  useEffect(() => {
    if (!errors.address1.error && !errors.country.error && !errors.state.error && !errors.city.error && !errors.zipCode.error && !errors.addressType.error)
      setSaveOverrideOpen(false);
  }, [errorFields]);

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Address`, id);
    if (response.status === 200) {
      logActivity('DELETE_AOP', location?.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/Address')
    } else {
      console.error('Error al eliminar el elemento:', response.data);
    }
  }

  const validateForm = () => {
    let valid = true;
    let newerrors: errors = errors;
    newerrors.address1 = address?.address1 === '' ? { error: true, msj: 'Address1 is required' } : { error: false, msj: '' };
    newerrors.zipCode = address?.zipCode === '' && address.noZipCode === false ? { error: true, msj: 'Zip Code is required' } : { error: false, msj: '' };
    newerrors.country = selectedCountry.name === '' ? { error: true, msj: 'Country is required' } : { error: false, msj: '' };
    newerrors.state = selectedState.name === '' ? { error: true, msj: 'State is required' } : { error: false, msj: '' };
    newerrors.city = selectedCity.name === '' ? { error: true, msj: 'City is required' } : { error: false, msj: '' };
    newerrors.addressType = selectedAddressType === '' ? { error: true, msj: 'Address Type is required' } : { error: false, msj: '' };

    valid = Object.values(errors).every((error) => !error.error);
    setErrors(errors);
    setErrorFields(Object.keys(errors).filter((key) => errors[key as keyof typeof errors].error));
    if (!valid) setSaveOverrideOpen(true);
    return valid;
  }

  const handleButtonClick = (action: string) => {
    setButtonClicked(action);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === 'delete') {
      handleDelete(address.id ?? '');
    } else if (buttonClicked === 'saveOverride') {
      if (props.mode === 'add') {
        handleSave(e);
      } else {
        handleUpdate(e);
      }
    } else if (validateForm()) {
      if (buttonClicked === 'saveButton') {
        handleSave(e);
      } else if (buttonClicked === 'updateButton') {
        handleUpdate(e);
      }
    }
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const regions = countryData.find(c => c.id === selectedCountry.id);
      const regionNames = regions?.regions.map((r: any) => getNameById(r.id, regionData))?.join(', ');

      const res = await axios.Post('Address', {
        address1: address.address1,
        address2: address.address2,
        zipCode: address.zipCode,
        noZipCode: address.noZipCode,
        city: selectedCity.id,
        addressType: selectedAddressType,
        venueLink: address.venueLink,
        notes: address.notes,
        cityName: selectedCity.name,
        stateName: selectedState.name,
        countryName: selectedCountry.name,
        altitude: address.altitude,
        latitude: address.latitude,
        regionNames,
      });
      if (res.status === 200) {
        if (res.data.error) {
          if (res.data.error[0].includes('Address') || res.data.error[0].includes('Duplicate')) {
            setErrors({ ...errors, address1: { error: true, msj: 'Address already exists' } });
          }
        } else {
          logActivity('CREATE_ADDRESS', location?.pathname, JSON.stringify(res.data ? res.data : {}));
          setTimeout(() => {
            navigate('/Staff/Catalogs/Address');
          }, 500);
        }
      } else {
        console.error(res.data);
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const regions = countryData.find(c => c.id === selectedCountry.id);
      const regionNames = regions?.regions.map((r: any) => getNameById(r.id, regionData))?.join(', ');

      const res = await axios.Put('Address', {
        id: address.id,
        address1: address.address1,
        address2: address.address2,
        zipCode: address.zipCode,
        noZipCode: address.noZipCode,
        city: selectedCity.id,
        addressType: selectedAddressType,
        venueLink: address.venueLink,
        notes: address.notes,
        cityName: selectedCity.name,
        stateName: selectedState.name,
        countryName: selectedCountry.name,
        altitude: address.altitude,
        latitude: address.latitude,
        regionNames,
      });
      if (res.status === 200) {
        if (res.data.error) {
          if (res.data.error[0].includes('Address') || res.data.error[0].includes('Duplicate')) {
            setErrors({ ...errors, address1: { error: true, msj: 'Address already exists' } });
          }
        } else {
          logActivity('UPDATE_ADDRESS', location?.pathname, JSON.stringify({ prevState: state ? state : {}, newState: res.data }));
          if (state.address1 !== address.address1) {
            await updateData('office', 'addressName', state.address1, address.address1)
          }
          setTimeout(() => {
            navigate('/Staff/Catalogs/Address');
          }, 500);
        }
      } else {
        console.error(res.data);
      }
    } catch (error) {
      console.error('Error during update:', error);
    }
  };

  const handleSetSelectedCountry = (e: any) => {
    setSelectedCountry(e);
    setSelectedState({ id: "", name: "" });
    setSelectedCity({ id: "", name: "" });
  }

  const handleSetSelectedState = (e: any) => {
    setSelectedState(e);
    setSelectedCity({ id: "", name: "" })
  }

  const handleSetSelectedCity = (e: any) => {
    setSelectedCity(e);
    setAddress({ ...address, city: e.id });
  }

  const handleSetSelectedAddressType = (e: any) => {
    setSelectedAddressType(e);
    setAddress({ ...address, addressType: e });
  }

  useEffect(() => {
  }, [address]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const numberRegex = /^[0-9]+$/;
    if (!numberRegex.test(address.zipCode ?? "")) {
      setAddress(prevData => ({
        ...prevData,
        zipCode: prevData.zipCode?.replace(/[^0-9]/g, '')
      }));
    }
  }, [address.zipCode]);

  const handleTextAreaChange = (
    event: React.ChangeEvent<
      HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    if (value.length > 200) {
      setErrors(prevState => ({
        ...prevState,
        notes: { error: true, msj: '' },
      }));
    } else {
      setErrors(prevState => ({
        ...prevState,
        notes: { error: false, msj: '' },
      }));
    }
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setAddress(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleCheckBoxNoZipCode = (checked: boolean) => {
    address.zipCode = ''
    handleCheckBoxChange('noZipCode', checked)
  };

  return (
    <>
      <main className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
        <form onSubmit={handleSubmit}>
          <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={() => { setSaveOverrideOpen(!saveOverrideOpen) }} />
          <CatalogTitle title={`${props.mode === 'add' ? 'New Address' : 'Edit ' + (state ? state?.address1 || 'Edit none' : 'Loading...')}`} className='text-Default'>
            <GoBack />
          </CatalogTitle>
          <article className='articleSection'>
            <section className="formsectionNTitle">
              <CatalogSubTitle title=" Catalog List Relationships" className="text-accent-Default-dark" />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <DropDownList title="Country" getid required={true} data={countryData} selectedObjst={selectedCountry.name} seter={handleSetSelectedCountry} error={errors.country.error} errormsj={errors.country.msj} />
                </div>
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <div className="flex flex-col w-full">
                    <DropDownList title="State"
                      disabled={selectedCountry.name === ""}
                      required={selectedCountry.name !== ""}
                      data={stateData} getid selectedObjst={selectedState.name} tooltip={"Please Select a Country"} seter={handleSetSelectedState} error={errors.state.error} errormsj={errors.state.msj} />
                  </div>
                </div>
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-4">
                  <div className="flex flex-col w-full">
                    <DropDownList title="City"
                      disabled={selectedState.name === ""}
                      required={selectedState.name !== ""}
                      data={cityData} getid selectedObjst={selectedCity.name} tooltip={"Please Select a State"} seter={handleSetSelectedCity} error={errors.city.error} errormsj={errors.city.msj} />
                  </div>
                </div>
              </div>
            </section>
            <section className="formsectionNTitle">
              <CatalogSubTitle title="Address Information" className="text-accent-Default-dark" />
              <div className="formsection grid grid-cols-12 gap-5 w-full">
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
                  <div className=" w-full ">
                    <Input title="Address 1" name="address1" errorMsj={errors.address1.msj} error={errors.address1.error} value={address.address1} onChange={handleInputChange} required={true} />
                  </div>
                </div>
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6">
                  <div className=" w-full">
                    <Input title="Address 2" name="address2" error={false} value={address.address2} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="flex gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <div className=" w-full">
                    <Input title="Zip Code" name="zipCode" errorMsj={errors.zipCode.msj} error={errors.zipCode.error} disabled={address.noZipCode === true} value={address.zipCode} onChange={handleInputChange} required={address.noZipCode === false} />
                  </div>
                  <div className="flex items-center w-full">
                    <SwitchToogle small seter={handleCheckBoxNoZipCode} checked={address.noZipCode} title='No Zip Code' />
                  </div>
                </div>
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <div className="flex flex-col gap-1 w-full">
                    <DropDownList title="Address Type" required={true} selectedObjst={selectedAddressType} seter={handleSetSelectedAddressType} data={[{ id: "Office", name: "Office" }, { id: "Venue", name: "Venue" }, { id: "Legal", name: "Legal" }]} error={errors.addressType.error} errormsj={errors.addressType.msj} />
                  </div>
                </div>
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <div className=" w-full">
                    <Input
                      title="Altitude"
                      name="altitude"
                      error={false}
                      value={address.altitude?.toString().trim()}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="gap-5 flex-wrap col-span-12 md:col-span-6 lg:col-span-3">
                  <div className=" w-full">
                    <Input
                      title="Latitude"
                      name="latitude"
                      error={false}
                      value={address.latitude?.toString().trim()}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>


                <div className="gap-5 flex-wrap col-span-12 lg:col-span-6">
                  <div className=" w-full ">
                    <Input title="Venue Link" name="venueLink" error={false} value={address.venueLink} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="gap-5 flex-wrap col-span-12 lg:gap-5">
                  <TextArea
                    title="Notes"
                    name="notes"
                    maxChars={200}
                    value={address.notes}
                    error={errors.notes.error}
                    errorMsj="Character limit has been reached."
                    onChange={handleTextAreaChange}
                    characterCount={true}
                  />
                </div>
              </div>
            </section>
            <ButtonsCrud Catalog="Address" mode={props.mode} id={id ? id : ''} disabled={errors.address1.error || errors.country.error || errors.state.error || errors.city.error || errors.addressType.error || errors.zipCode.error || errors.notes.error} actionButton={handleButtonClick} onDelete={handleDelete} />
          </article>
        </form>
      </main>
    </>
  );
}
export default AddAddress;