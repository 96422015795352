import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect } from "react";
import CatalogSubTitle from "../../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import RadioButton from "../../../../components/Atomos/Radio/RadioButton";
import DropDownList from "../../../../components/Atomos/DropDown/Dropdown";
import Close from "../../../../components/Atomos/Icons/Close";
import SaveChanges from "../../../../components/Atomos/Icons/EditMode/SaveChanges";
import { updateFirm } from "../../../../Services/Firm";
import Modal from "../../../../components/Atomos/Modals/Modal";
import ModalConfirmation from "./ModalConfirmation";

enum MembershipStatusType {
  accepted = "Accepted",
  member = "Member",
  temporarilyWithdrawn = "Temporarily Withdrawn",
  withdrawn = "Withdrawn",
  onboarding = "Onboarding",
}

interface IMainBoxUpdateMembershipProps {
  membershipDetails: any;
  propsState: any;
  onSaveMembership: (updatedDetails: any) => void; // New prop for saving
  setUpdateMembership: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainBoxUpdate: React.FC<IMainBoxUpdateMembershipProps> = ({
  onSaveMembership,
  setUpdateMembership,
  propsState,
  membershipDetails,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(
    membershipDetails.membershipStatus ?? "Onboarding"
  );
  const [selectedMembershipType, setSelectedMembershipType] = React.useState(
    membershipDetails.membershipType ?? ""
  );
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedWithdrawn, setSelectedWithdrawn] = React.useState("");

  const updateFirmMembership = () => {
    const firm = {
      ...propsState,
      membershipStatus: selectedOption,
      membershipType: selectedMembershipType,
    };

    updateFirm(firm);
    onSaveMembership(firm);
  };

  const checkEditType = (type: any) => {
    if (type === 0) {
      setSelectedOption("Accepted");
      return;
    }
    if (type === 1) {
      setSelectedOption("Member");
      return;
    }
    if (type === 2) {
      setSelectedOption("Onboarding");
      return;
    }
    if (type === 3) {
      setOpenModal(true);
      setSelectedWithdrawn("Temporarily Withdrawn");
      return;
    }
    if (type === 4) {
      setOpenModal(true);
      setSelectedWithdrawn("Withdrawn");
      return;
    }

  };

  const setConfirmation = (confirmation: any) => {
    if(confirmation){
      setSelectedOption(selectedWithdrawn); 
    }
  };

  const membershipTypeData = [
    { id: "primary", name: "Primary" },
    { id: "multiplePrimary", name: "Multiple Primary" },
    { id: "regional", name: "Regional" },
    { id: "secondary", name: "Secondary" },
  ];

  return (
    <>
      <ModalConfirmation
        openModal={openModal}
        setOpenModal={setOpenModal}
        setConfirmation={setConfirmation}
      />
      <section className="flex w-full flex-col">
        <div className="grid flex-row w-full gap-5 items-stretch">
          <div>
            <div className="flex flex-col gap-3 mb-6">
              <CatalogSubTitle
                title={membershipDetails.name ?? "Loading..."}
                className="text-Default lg:text-[42px] md:text-[34px] text-[26px] not-italic font-medium leading-normal md:leading-[48px] lg:leading-[44px]"
              />
              <div className="flex flex-col gap-5">
                <span className="text-[#333] lg:text-[28px] md:text-[26px] text-lg not-italic font-bold  leading-normal md:leading-9 lg:leading-8">
                  {"Membership Status: "}
                </span>
                <div className="lg:flex lg:flex-wrap gap-5 grid md:grid-cols-2 grid-cols-1">
                  <RadioButton
                    index={0}
                    text={"Accepted"}
                    selected={selectedOption === MembershipStatusType.accepted}
                    handleRadioButtonChange={checkEditType}
                  />
                  <RadioButton
                    index={1}
                    text={"Member"}
                    selected={selectedOption === MembershipStatusType.member}
                    handleRadioButtonChange={checkEditType}
                  />
                  <RadioButton
                    index={2}
                    text={"Onboarding"}
                    selected={
                      selectedOption === MembershipStatusType.onboarding
                    }
                    handleRadioButtonChange={checkEditType}
                  />
                  <RadioButton
                    index={3}
                    text={"Temporarily Withdrawn"}
                    selected={
                      selectedOption ===
                      MembershipStatusType.temporarilyWithdrawn
                    }
                    handleRadioButtonChange={checkEditType}
                  />
                  <RadioButton
                    index={4}
                    text={"Withdrawn"}
                    selected={selectedOption === MembershipStatusType.withdrawn}
                    handleRadioButtonChange={checkEditType}
                  />

                </div>
              </div>
            </div>

            <div className="flex flex-1 justify-center align-middle mb-5 self-stretch lg:absolute lg:right-10 lg:top-10 ">
              {membershipDetails.logo ? (
                <img
                  src={membershipDetails.logo}
                  alt="Wsg_Profile_Picture"
                  className="object-cover max-h-[174px] max-w-[330px]"
                />
              ) : (
                <div></div>
              )}
            </div>

            <div className="grid w-full grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
              <div className="grid flex-col col-span-2 md:col-span-1 gap-2">
                <DropDownList
                  title="Membership Type"
                  data={membershipTypeData}
                  selectedObjst={selectedMembershipType}
                  seter={setSelectedMembershipType}
                  error={false}
                  generalTab
                />
              </div>

              <div className="grid flex-col col-span-2 md:col-span-1 gap-2">
                <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
                  {"Service Type: "}
                </span>
                <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
                  {membershipDetails.serviceType ?? "Loading..."}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid w-full col-start-5 grid-cols-4 gap-5 mb-10">
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              {"Date Joined: "}
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
              {"MM/DD/YYYY"}
            </span>
          </div>
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              {"Membership Organization: "}
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
              {"70% Optimized"}
            </span>
          </div>
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              {"Due Date: "}
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg  not-italic font-normal leading-6 lg:leading-7">
              {"MM/DD/YYYY"}
            </span>
          </div>
          <div className="grid flex-col col-span-4 md:col-span-2 lg:col-span-1 gap-2">
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lg not-italic font-bold leading-normal md:leading-5 lg:leading-normal">
              {"Dues Payment Status: "}
            </span>
            <span className="text-gray-900 lg:text-[22px] md:text-[20px] text-lgs  not-italic font-normal leading-6 lg:leading-7">
              {"PENDING"}
            </span>
          </div>
        </div>

        <div className="flex w-full flex-col gap-5 md:flex-row md:justify-end">
          <div className=" w-full md:w-min  ">
            <NewButton
              onClick={() => {
                setUpdateMembership(false);
              }}
              text={"Cancel"}
              className="!max-h-[35px] !max-w-[45px]"
              icon={<Close />}
              color="neutral"
              content="textIcon"
              size="large"
              style="outlined"
            />
          </div>
          <div className="w-full md:w-min ">
            <NewButton
              text={"Save Changes"}
              icon={<SaveChanges />}
              onClick={() => {
                updateFirmMembership();
              }}
              className="!max-h-[35px] !max-w-[45px]"
              color={"accent"}
              content="textIcon"
              size="large"
              style="filled"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBoxUpdate;
