import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';

interface IEducationProps {
  firmData: any[];
  selectedFirm: Option[];
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;
  personData: Option[];
  selectedPerson: Option[];
  setSelectedPerson: React.Dispatch<React.SetStateAction<Option[]>>;
  schoolData: Option[];
  selectedSchool: Option[];
  setSelectedSchool: React.Dispatch<React.SetStateAction<Option[]>>
  degreeData: Option[];
  selectedDegree: Option[];
  setSelectedDegree: React.Dispatch<React.SetStateAction<Option[]>>
  resetFilters: () => void;
  selectedTab?: number;
  headers: string[];
  search: () => void;
  tableInstance: any;
}



const EducationFilter: React.FC<IEducationProps> = ({
  firmData,
  selectedFirm,
  setSelectedFirm,
  personData,
  selectedPerson,
  setSelectedPerson,
  schoolData,
  selectedSchool,
  setSelectedSchool,
  degreeData,
  selectedDegree,
  setSelectedDegree,
  selectedTab,
  search,
  resetFilters,
  headers,
  tableInstance
}) => {


  return (
    <div className="flex formsection flex-col w-full">
      <div className="grid grid-cols-4 formsection">
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple title="By Firm:" objects={firmData} selectedObjst={selectedFirm} seter={setSelectedFirm} />
        </div>

        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple title="By Person:" objects={personData} selectedObjst={selectedPerson} seter={setSelectedPerson} />
        </div>

        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple title="By School:" objects={schoolData} selectedObjst={selectedSchool} seter={setSelectedSchool} />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple title="By Degree:" objects={degreeData} selectedObjst={selectedDegree} seter={setSelectedDegree} />
        </div>
        </div>
        <div className="flex items-end justify-end">
          <CatalogFilterButtons 
            tableInstance={tableInstance} 
            onClickSearch={search} 
            handleFormReset={resetFilters} 
            headers={headers} 
          />
        
      </div>
    </div>
  );
};

export default EducationFilter;
