function SortArrow(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 16 16" 
      >
        <path
          d="M8.00083 10.2259L4.28125 6.52295H11.7204L8.00083 10.2259Z" 
        />
      </svg>
    </div>
  );
}

export default SortArrow;
