import React from "react";
interface CardProps {
  title: string;
  subtitle?: string;
  items?: any[];
  categories?: any[];
  itemText?: string;
}

const maxitems = 4;
const EventCard: React.FC<CardProps> = (props) => {
  return (
    <div className="flex flex-col bg-gray-25">
      <div className="bg-accentDefault w-full flex-row p-[18px]">
        <h2 className="flex flex-col w-full justify-center flex text-white font-bold text-lg uppercase">
          {props?.title ?? ""}
        </h2>
        {props?.subtitle && (
          <h2 className="flex flex-col w-full justify-center flex text-white font-medium text-lg uppercase">
            {props?.subtitle}
          </h2>
        )}
      </div>
      <div className="flex-row m-10">
        {props.items?.slice(0, maxitems).map((article, index) => (
          <div className="flex flex-row" key={index}>
            <div className="m-[5px]">
              <div className="flex flex-row text-gray-font">{article.text}</div>
              <div className="flex flex-row text-gray-400">{article.date}</div>
            </div>
          </div>
        ))}
        {props.categories && (
          <div className="m-[5px] font-bold text-gray-font">
            Categories: {props.categories.join(" | ")}
          </div>
        )}
        {props.items && props.items.length > maxitems && (
          <div className=" text-end">
            <button className="m-[5px] font-bold text-lg text-accent-Default-dark">
              More {props.itemText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
