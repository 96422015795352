import React from "react";

function Google() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
      
    >
      
        <path 
          d="M46.875 24.544c0 13.265-9.084 22.706-22.5 22.706-12.863 0-23.25-10.388-23.25-23.25C1.125 11.137 11.512.75 24.375.75c6.262 0 11.531 2.297 15.59 6.084l-6.328 6.085C25.36 4.93 9.967 10.93 9.967 24c0 8.11 6.478 14.681 14.409 14.681 9.206 0 12.656-6.6 13.2-10.022h-13.2v-7.996h22.134c.216 1.19.366 2.334.366 3.88z"
        ></path> 
    </svg>
  );
}

export default Google;
