import React, { useEffect, useState } from "react";
import OfficeFilter from "../../components/Catalogs/Filters/OfficeFilter";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { axios } from "../..";
import { UseCountryList } from "../../hooks/Catalogs";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { Link } from "react-router-dom";
import { formatTimeSpanToString } from "../../utils/functions";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { UseJurisdictionGroupList } from "../../hooks/Catalogs/useJurisdictionGroup";
import { useCityList } from "../../hooks/Catalogs/useCityList";
import { useStatesList } from "../../hooks/Catalogs/useStates";

const Office: React.FC = () => {

  const DefaultPageSize = 50;
  const [officeFilters, setOfficeFilters] = useState<{
    name: string;
    countries: Option[];
    regions: Option[];
    states: Option[];
    cities: Option[];
    firms: Option[];
    officeTypes: Option[];
  }>({
    name: "",
    countries: [],
    regions: [],
    states: [],
    cities: [],
    firms: [],
    officeTypes: [],
  });
  const { countryData, isFetching: isFetchingCountry } = UseCountryList();
  const { firmData, isFetching: isFetchingFirm } = UseFirmList();
  const { jurisdictionData } = UseJurisdictionList();
  const { jurisdictionGroupData } = UseJurisdictionGroupList();
  const { cityData } = useCityList();
  const { statesData } = useStatesList();
  const { regionData, isFetching: isFetchingRegion } = UseRegionList();
  const [tableInstance, setTableInstance] = useState<any>();
  const Headers: string[] = [
    "name",
    "officeType",
    "firm",
    "jurisdiction",
    "jurisdictionGroup",
    "state",
    "address",
    "city",
    "country",
    "regions",
    "countOfPeople",
    "phoneNumber",
    "yearFounded",
    "_ts",
  ];
  const { generateColumns } = useColumnConfig(Headers);
  const [statesByCountry, setStatesByCountry] = useState<any>([]);
  const [citiesByState, setCitiesByState] = useState<any>([]);
  const [addressData, setAddressData] = useState<any>([]);
  const [officeData, setOfficeData] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    'officeType',
    'firm',
    'jurisdiction',
    'jurisdictionGroup',
    'city',
    'address',
    'firmName',
    'jurisdictionName',
    'jurisdictionGroupName',
    'cityName',
    'addressName',
    'stateName',
    'countryName',
    'regionNames',
    'countOfPeople',
    'digPhoneNumber',
    'codePhoneNumber',
    'basecodePhoneNumber',
    'yearFounded',
    'overview',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/office',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    { id: 'officeType', header: 'Office Type', cell: (props: any) => props.getValue() },
    { id: 'firm', header: 'Firm', cell: (props: any) => props.getValue() },
    { id: 'jurisdiction', header: 'Jurisdiction', cell: (props: any) => props.getValue() },
    { id: 'jurisdictionGroup', header: 'Jurisdiction Group', cell: (props: any) => props.getValue() },
    { id: 'state', header: 'State', cell: (props: any) => props.getValue() },
    { id: 'address', header: 'Address', cell: (props: any) => props.getValue() },
    { id: 'city', header: 'City', cell: (props: any) => props.getValue() },
    { id: 'country', header: 'Country', cell: (props: any) => props.getValue() },
    { id: 'regions', header: 'Region', cell: (props: any) => props.getValue() },
    { id: 'countOfPeople', header: 'Professionals in Office', cell: (props: any) => props.getValue() },
    { id: 'phoneNumber', header: 'Phone Number', cell: (props: any) => props.getValue() },
    {
      id: 'yearFounded', header: "Year Founded",
      cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      },
    },
    {
      id: '_ts', header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) }
          </p>
        );
      },
    },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={data[props.row.id]}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  useEffect(() => {
    setStatesByCountry(statesData.filter((s: any) => 
      officeFilters.countries.map(o => o.id).includes(s.country)));
  }, [officeFilters.countries]);

  useEffect(() => {
    setCitiesByState(cityData.filter((c: any) => 
      officeFilters.states.map(o => o.id).includes(c.state)));
  }, [officeFilters.states]);

  const clearSelections = () => {
    setOfficeFilters({
      name: "",
      countries: [],
      regions: [],
      states: [],
      cities: [],
      firms: [],
      officeTypes: [],
    });
    fetchData(true);
  };

  const [officeTypeList] = useState<Option[]>([
    { id: "main", name: "Main" },
    { id: "additional", name: "Additional" },
  ]);
  
  useEffect(() => {
    fetchAddresses()
  },[]);

  const fetchAddresses = async () => {
    try {
      const params = new URLSearchParams();
      params.append('fields', JSON.stringify(['id', 'address1']))
      
      axios.Get(`/address?${params.toString()}`).then((resp) => {
        setAddressData(resp.data.map((d: any) => ({ id: d.id, name: d.address1 })))
      })
    } catch (error) {
      console.error(error)
    }
  }

  const formatData = (data: any[]) => {
    const formatedData = data.map((d: any) => {
      return {
        ...d,
        firm: d.firmName,
        jurisdiction: d.jurisdictionName,
        jurisdictionGroup: d.jurisdictionGroupName,
        address: d.addressName,
        city: d.cityName,
        state: d.stateName,
        country: d.countryName,
        phoneNumber: d.digPhoneNumber,
        regions: d.regionNames,
      }
    })
    return formatedData
  }

  useEffect(() => {
    if (data) setOfficeData(formatData(data))
  },[data]);

  useEffect(() => {
    if (
      !cityData.length || 
      !firmData.length ||
      !countryData.length ||
      !jurisdictionData.length ||
      !jurisdictionGroupData.length ||
      !addressData.length ||
      !statesData.length
    ) return
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[
    cityData,
    firmData,
    countryData,
    jurisdictionData,
    jurisdictionGroupData,
    addressData,
    statesData,
  ]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: officeFilters.name,
        comparisonType: 'CONTAINS'
      },
      regions: {
        value: officeFilters.regions?.map(c => c.id),
        comparisonType: 'EQUAL',
      },
      country: {
        value: officeFilters.countries?.map(c => c.id),
        comparisonType: 'EQUAL',
      },
      state: {
        value: officeFilters.states?.map(c => c.id),
        comparisonType: 'EQUAL',
      },
      city: {
        value: officeFilters.cities?.map(c => c.id),
        comparisonType: 'EQUAL',
      },
      firm: {
        value: officeFilters.firms?.map(c => c.id),
        comparisonType: 'EQUAL',
      },
      officeType: {
        value: officeFilters.officeTypes?.map(c => c.name),
        comparisonType: 'EQUAL',
      },
    }
    setFilter(filterAux)
  },[officeFilters]);

  return (
    <>
      <main className=" mainSection">
        <CatalogTableC
          title={`Office Catalog`}
          catalogName={"OFFICES"}
          catalogShortName={"Office"}
          route={"Office"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
        >
          <OfficeFilter
            key={0}
            Search={fetchData}
            filters={officeFilters}
            setFilters={setOfficeFilters}
            countryData={countryData}
            stateData={statesByCountry}
            regionData={regionData}
            cityData={citiesByState}
            firmData={firmData}
            officeTypeData={officeTypeList}
            headers={Headers}
            clearSelections={clearSelections}
            tableInstance={tableInstance}
          />

          <TableWithPagination
            data={officeData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default Office;
