import * as React from 'react';
import css from './Navigation.module.css';

export interface ICloseNavigationButton {
  closedNav: boolean;
  handleClose: () => void;
  classname: string; // The classname prop
}

export const CloseNavigationButton: React.FC<ICloseNavigationButton> = ({ closedNav, handleClose, classname }) => {
  return (
    <div
      className={` ${css.closeContainer} ${
        !closedNav ? css.animateFadeIn : css.animateFadeOut
      } relative z-50 flex justify-center ${classname}`}
      onClick={handleClose}
    >
      <div className={`${css.leftright}`}></div>
      <div className={`${css.rightleft}`}></div>
    </div>
  );
};
