import { useLocation } from "react-router-dom";
import Axios from "../../utils/axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonsCrud from "../../components/Catalogs/ButtonsCrud";
import Input from "../../components/Atomos/Inputs/Input";
import GoBack from "../../components/Moleculas/GoBack";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import RadioButton from "../../components/Atomos/Radio/RadioButton";
import NewButton from "../../components/Atomos/Buttons/newButton";
import CalendarInput from "../../components/Atomos/Inputs/CalendarInput";
import UnMark from "../../components/Atomos/Icons/Offline";
import { isApprovedEnum } from "../../Enums/ContentEnums";
import { IContent } from "../../interfaces/models/IContent";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, contentContext, contentsSlice } from "../../redux";
import IFirm from "../../interfaces/models/IFirm";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import Preview from "./Preview";
import AuthContext from "../../store/AuthContext";
import { formatDateToString } from "../../utils/functions";

const initialFormData: IContent = {
  title: "",
  contentType: "article",
  writtenDate: undefined,
  reviewedDate: "pending",
  publishedDate: "pending",
  isApproved: "pending",
  shortId: "",
  authors: [],
  submissionType: "manualInput",
  footnotes: "",
  link: "",
  memberFirms: [],
  nonMemberFirms: [],
  jurisdictions: [],
  tags: [],
  articleContent: "",
  generalTopics: [],
  globalCrisisHub: [],
  practiceIndustries: [],
  notifySubscribers: true,
};

interface iCatalogProps {
  mode: "add" | "edit";
  disabledFirm?: boolean;
}

const ManageArticle: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [firms, setFirms] = useState<IFirm[]>();
  const [status, setStatus] = useState<Status>();
  const [statusClass, setStatusClass] = useState<string>("text-pending");
  const { firmData } = UseFirmList();
  const [publishStrategy, setPublishStrategy] =
    useState<PublishStrategy>("scheduled");
  const [canSave, setCanSave] = useState(true);
  const dispatch = useDispatch();

  const isInitializedRef = useRef(false);
  const { tokenDecoded } = useContext(AuthContext);

  const currentContent: contentContext = useSelector(
    (store: Appstore) => store.content
  );

  const approvedTypes = Object.entries(isApprovedEnum).map(([key, value]) => ({
    key,
    text: value,
  }));

  type PublishStrategy = "now" | "scheduled";
  type Status = "Unpublished" | "Published" | "Scheduled" | "Pending Review";

  interface PublishStrategyType {
    key: PublishStrategy;
    text: string;
  }

  const publishStrategyTypes: PublishStrategyType[] = [
    { key: "now", text: "Now" },
    { key: "scheduled", text: "Scheduled" },
  ];

  const notifySubscribersTypes = [
    { key: true, text: "Yes" },
    { key: false, text: "No" },
  ];

  useEffect(() => {
    const content = currentContent?.content;
    setCanSave(
      !(content?.isApproved === "yes" && content.publishedDate === "pending")
    );
  }, [
    currentContent?.content?.isApproved,
    currentContent?.content?.publishedDate,
  ]);

  useEffect(() => {
    if (props.mode === "add" && !isInitializedRef.current) {
      handleAdd();
    }
  }, [props.mode]);

  useEffect(() => {
    if (publishStrategy === "now") {
      dispatch(
        contentsSlice.actions.ModifyContent({
          content: {
            ...currentContent?.content,
            publishedDate: new Date(),
          },
        })
      );
    }
  }, [publishStrategy]);

  const handleAdd = () => {
    if (!isInitializedRef.current && props.mode === "add") {
      dispatch(contentsSlice.actions.NewContent({ content: initialFormData }));
      isInitializedRef.current = true;
    }
  };

  useEffect(() => {
    if (!(state === null || state.newItem)) {
      dispatch(
        contentsSlice.actions.ModifyContent({
          ...currentContent,
          content: { ...state, notifySubscribers: true },
        })
      );
    } else {
      dispatch(contentsSlice.actions.InitializeContent());
    }
  }, []);

  useEffect(() => {
    if (state?.unpublishedDate !== "pending") {
      setStatus("Unpublished");
      setStatusClass("text-feedback-error");
    } else if (new Date(state?.publishedDate) < new Date()) {
      setStatus("Published");
      setStatusClass("text-feedback-success-Default");
    } else if (new Date(state?.publishedDate) >= new Date()) {
      setStatus("Scheduled");
      setStatusClass("text-feedback-success-Default");
    } else {
      setStatus("Pending Review");
      setStatusClass("text-pending");
    }
  }, [state]);

  const handleApprovedArticle = (key: any) => {
    handleRadioChange("isApproved", key);

    const updatedContent = {
      ...currentContent?.content,
      isApproved: key,
      reviewedDate: key === "yes" ? new Date() : "pending",
      ...(key === "yes" && { publishedDate: "pending" }),
    };

    dispatch(contentsSlice.actions.ModifyContent({ content: updatedContent }));
  };

  const handleRadioChange = (name: string, value: any) => {
    const updatedContent = {
      ...currentContent?.content,
      [name]: value,
    };
    dispatch(contentsSlice.actions.ModifyContent({ content: updatedContent }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const updatedContent = {
      ...currentContent?.content,
      [name]: value,
    };

    dispatch(contentsSlice.actions.ModifyContent({ content: updatedContent }));
  };

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Content`, id);
    if (response.status === 200) {
      navigate("/Staff/Catalogs/Content");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };

  const handleUpdate = async () => {
    try {
      const content = currentContent?.content ?? initialFormData;

      const currentPerson = await fetchPerson();
      const fullName = `${currentPerson.name} ${currentPerson.lastName}`;

      const reviewedBy =
        content?.isApproved !== "pending" &&
        (content?.isApproved !== state.isApproved ||
          content?.reviewedDate !== state.reviewedDate ||
          content?.publishedDate !== state.publishedDate ||
          content?.notifySubscribers !== state.notifySubscribers)
          ? fullName
          : undefined;

      const unpublishedBy =
        content?.unpublishedDate !== "pending" &&
        content?.unpublishedDate !== state.unpublishedDate
          ? fullName
          : undefined;

      const updatedData: IContent = {
        ...content,
        reviewedBy,
        unpublishedBy,
      };

      const response = await axios.Put("Content", updatedData);

      if (response.data.error) {
        console.error(response.data.error);
      } else {
        navigate(props.disabledFirm 
          ? `/Staff/ManageFirm/Content/${state.memberFirms[0]}` 
          : "/Staff/Content");
      }
    } catch (error) {
      console.error("Error al actualizar el elemento:", error);
    }
  };

  const fetchPerson = async () => {
    try {
      const res = await axios.Get("/person");
      return res.data.find(
        (person: any) => person.userId === tokenDecoded?.oid
      );
    } catch (error) {
      console.error("Error al obtener la persona:", error);
      return undefined;
    }
  };

  useEffect(() => {
    if (!currentContent?.content?.memberFirms?.length || !firmData.length)
      return;

    const firmNames = currentContent?.content?.memberFirms?.map(
      (memberFirm: any) => {
        return firmData.find((f: any) => f.id === memberFirm);
      }
    );
    setFirms(firmNames);
  }, [currentContent, firmData]);

  const formatDate = (date: Date | 'pending' | undefined) => {
    if (!date) return ''
    return date === "pending"
      ? "Pending"
      : formatDateToString(new Date(date));
  };

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      <div className={`mainSection pt-9`}>
        <div className=" bg-gray-100 min-h-screen overflow-hidden text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
          <div className="flex flex-col items-start gap-10 self-stretch">
            <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch flex-col ">
              <div className="flex flex-col justify-between gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
                <h2 className="text-left w-full flex-1 text-white font-decimal">
                  Manage Article
                </h2>
                <div className="flex justify-end">
                  <GoBack className="!text-white" />
                </div>
              </div>
              <hr className="flex h-0 justify-center items-center self-stretch relative" />
              <CurrentPage
                prevPage={"Publications"}
                currentPage={"Manage Article"}
              />
              <div className={`flex flex-col bg-white px-10 pb-10 gap-5`}>
                <div className="grid grid-cols-12 lg:gap-10">
                  <div className="col-span-12 lg:col-span-9 flex">
                    <div className="flex">
                      <CatalogTitle
                        title={state?.title}
                        className="text-disclosure-primary-dark text-[32px] lg:text-[42px]"
                      />
                    </div>
                  </div>
                  <div className="h-full flex col-span-3 items-center justify-center md:justify-start lg:justify-center text-accent-Default-dark font-bold max-h-20 lg:max-h-44">
                    <img
                      src={(firms && firms[0].logo) ?? ""}
                      className="border-none h-full"
                    />
                  </div>
                </div>
                <div className="font-bold text-[26px] text-gray-font">
                  Status: <span className={statusClass}>{status}</span>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                    <div className="font-bold">Published Date</div>
                    <div>
                      {formatDate(currentContent?.content?.publishedDate)}
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                    <div className="font-bold">Written Date</div>
                    <div>
                      {formatDate(currentContent?.content?.writtenDate)}
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                    <div className="font-bold">Reviewed Date</div>
                    <div>
                      {formatDate(currentContent?.content?.reviewedDate)}
                    </div>
                  </div>
                  <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                    <div className="font-bold">Reviewed By</div>
                    <div>{currentContent?.content?.reviewedBy ?? "-"}</div>
                  </div>
                  <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                    <div className="font-bold">Total Comments</div>
                    <div>{currentContent?.content?.totalComments ?? 0}</div>
                  </div>
                  <div className="col-span-3 lg:col-span-1 text-xl text-gray-font">
                    <div className="font-bold">Views</div>
                    <div>{currentContent?.content?.views ?? 0}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white flex w-full flex-col gap-5 p-10 z-20">
              <div className="flex">
                <CatalogTitle
                  title="Manage Publishing"
                  className="text-accent-Default-dark flex flex-col sm:flex-row gap-2 justify-between h-fit !p-0"
                />
              </div>

              <div className="flex flex-row gap-5 flex-wrap">
                <div className="flex flex-col w-full lg:w-fit">
                  <div className="flex text-lg text-primary">
                    Approved Article
                  </div>
                  <div className="flex w-full">
                    <div className="flex gap-5 pt-3">
                      {approvedTypes.map((type, index) => (
                        <RadioButton
                          key={type.key}
                          index={index}
                          text={type.text}
                          selected={
                            currentContent?.content?.isApproved === type.key
                          }
                          handleRadioButtonChange={() =>
                            handleApprovedArticle(type.key)
                          }
                          name="isApproved"
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-1 lg:flex-none w-full md:w-1/2 lg:w-fit">
                  <CalendarInput
                    title="Reviewed Date"
                    type="single"
                    errorMsj=""
                    error={false}
                    value={
                      currentContent?.content?.reviewedDate &&
                      currentContent?.content?.reviewedDate.toString()
                    }
                    onChange={(e: any) => {
                      dispatch(
                        contentsSlice.actions.ModifyContent({
                          content: {
                            ...currentContent?.content,
                            writtenDate: e && new Date(e),
                          },
                        })
                      );
                    }}
                    tooltip="Reviewed Date of the Content that will be displayed."
                    disabled={
                      currentContent?.content?.isApproved === "pending"
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-1 lg:flex-none w-full md:w-1/2 lg:w-fit">
                  <div className="flex">
                    <Input
                      name="name"
                      value={currentContent?.content?.reviewedBy ?? ""}
                      title="Reviewed By"
                      error={false}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-5 flex-wrap">
                <div className="flex flex-col w-full lg:w-fit">
                  <div
                    className={`flex text-lg text-primary
                      ${currentContent?.content?.isApproved !== "yes" ? "text-light-gray" : "text-primary"}`}
                  >
                    Publish Strategy
                  </div>
                  <div className="flex w-full">
                    <div className="flex gap-5 pt-3">
                      {publishStrategyTypes.map((type, index) => (
                        <RadioButton
                          key={type.key}
                          index={index}
                          text={type.text}
                          selected={publishStrategy === type.key}
                          handleRadioButtonChange={() =>
                            setPublishStrategy(type.key)
                          }
                          name="submissionType"
                          disabled={
                            currentContent?.content?.isApproved !== "yes"
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-1 lg:flex-none w-full md:w-1/2 lg:w-fit">
                  <CalendarInput
                    title="Published Date"
                    type="single"
                    errorMsj={""}
                    error={false}
                    value={
                      currentContent?.content?.publishedDate &&
                      currentContent?.content?.publishedDate.toString()
                    }
                    onChange={(e: any) => {
                      dispatch(
                        contentsSlice.actions.ModifyContent({
                          content: {
                            ...currentContent?.content,
                            publishedDate: e && new Date(e),
                          },
                        })
                      );
                    }}
                    required={currentContent?.content?.isApproved === "yes"}
                    tooltip="Published Date of the Content that will be displayed."
                    disabled={currentContent?.content?.isApproved !== "yes"}
                  />
                </div>
                <div className="flex flex-col md:flex-1 lg:flex-none w-full md:w-1/2 lg:w-fit">
                  <div
                    className={`flex text-lg 
                      ${currentContent?.content?.isApproved !== "yes" ? "text-light-gray" : "text-primary"}`}
                  >
                    Notify Subscribers?
                  </div>
                  <div className="flex gap-5 pt-3">
                    {notifySubscribersTypes.map((type, index) => (
                      <RadioButton
                        key={type.key.toString()}
                        index={index}
                        text={type.text}
                        selected={
                          currentContent?.content?.notifySubscribers ===
                          type.key
                        }
                        handleRadioButtonChange={() =>
                          handleRadioChange("notifySubscribers", type.key)
                        }
                        disabled={currentContent?.content?.isApproved !== "yes"}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-5 flex-wrap h-full items-end">
                <div className="flex flex-col w-full lg:w-fit">
                  <div className="flex items-end w-full md:w-fit">
                    <NewButton
                      className={`${state?.publishedDate === "pending" ? "!border-light-gray" : "!border-feedback-error"} text-feedback-error`}
                      text="Unpublish Article"
                      icon={<UnMark />}
                      content="textIcon"
                      size="large"
                      style="outlined"
                      onClick={() => {
                        dispatch(
                          contentsSlice.actions.ModifyContent({
                            content: {
                              ...currentContent?.content,
                              unpublishedDate: new Date(),
                            },
                          })
                        );
                      }}
                      disabled={state?.publishedDate === "pending"}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-1 lg:flex-none w-full md:w-1/2 lg:w-fit">
                  <CalendarInput
                    title="Unpublished Date"
                    type="single"
                    errorMsj={""}
                    error={false}
                    value={
                      currentContent?.content?.unpublishedDate &&
                      currentContent?.content?.unpublishedDate.toString()
                    }
                    onChange={(e: any) => {
                      dispatch(
                        contentsSlice.actions.ModifyContent({
                          content: {
                            ...currentContent?.content,
                            writtenDate: e && new Date(e),
                          },
                        })
                      );
                    }}
                    tooltip="Creation Date of the Content that will be displayed."
                    disabled={
                      state?.publishedDate === "pending"
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-1 lg:flex-none w-full md:w-1/2 lg:w-fit">
                  <Input
                    value={currentContent?.content?.unpublishedBy ?? ""}
                    title="Unpublished By"
                    error={false}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>
              <div className="pt-5">
                <ButtonsCrud
                  Catalog="ContentGeneralTopic"
                  mode={props.mode}
                  id={currentContent?.content?.id ?? ""}
                  disabled={!canSave}
                  actionButton={handleUpdate}
                  onDelete={handleDelete}
                  hiddenDelete={true}
                />
              </div>
            </div>
            <Preview
              canComment={true}
              showComments={true}
              canEdit={true}
              breadcrumb={{
                prevPage: "Manage Article",
                currentPage: "Preview",
              }}
              hideModal
              content={state}
              editmode
              disabledFirm={props.disabledFirm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageArticle;
