import React from "react";

function WorldServicesGroupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 138 137"
      className="w-[8.625rem] h-[8.5625rem] absolute"
    >
      <path
        fill="#F6F6F6"
        d="M5.426 55.88A89.87 89.87 0 0117.86 35.742a112.245 112.245 0 018.398-9.307 29.962 29.962 0 012.396-9.147 22.723 22.723 0 016.567-8.505A68.905 68.905 0 006.448 39.7a37.664 37.664 0 00-1.022 16.18zM37.103 26.86a66.302 66.302 0 009.932 30.302c22.61-24.578 54.235-37.442 73.184-29.205a22.482 22.482 0 018.72 6.766c-7.28-12.797-18.496-22.939-32.002-28.937-16.5-4.547-40.078 4.118-59.834 21.074zM39.5 13.33c-.423.969-.792 1.96-1.103 2.97C52.285 6.135 67.33.44 79.766.84 78.743.68 77.72.52 76.698.414a69.49 69.49 0 00-26.512 2.22A20.792 20.792 0 0039.5 13.33zM85.903 128.067c9.097 4.012 24.708-2.942 34.99-15.538a42.434 42.434 0 002.88-3.985c-9.555 7.087-24.978 6.472-41.504-1.444-4.414 9.44-3.284 17.945 3.634 20.967zM122.723 82.492c-8.775-3.878-23.578 2.487-33.752 14.201 15.772 7.676 30.495 8.211 39.431 1.23 1.669-7.033-.161-12.997-5.679-15.43zM35.894 119.1l-.458-.401a20.857 20.857 0 004.293 8.981 21.032 21.032 0 007.98 5.996l.673.268a68.48 68.48 0 0012.974 2.674c5.57.586 11.192.496 16.741-.267-12.058.534-27.427-5.349-42.203-17.251zM23.917 107.816a96.397 96.397 0 01-19.19-30.595 38.193 38.193 0 00.215 16.635c5.534 13.823 15.445 25.482 28.234 33.216a24.575 24.575 0 01-6.845-8.576 24.423 24.423 0 01-2.414-10.68zM63.187 94.955A117.012 117.012 0 0146.418 78.32a65.894 65.894 0 00-11.627 29.713 115.437 115.437 0 007.428 6.553 87.848 87.848 0 0030.63 16.608c-7.805-5.349-8.074-17.357-1.319-30.168-2.799-1.818-5.571-3.85-8.343-6.07zM1.79 66.446a42.524 42.524 0 01-.618-10.163 52.676 52.676 0 00-.7 4.6 68.459 68.459 0 000 16.047A44.872 44.872 0 011.79 66.446z"
      ></path>
      <path
        fill="#F6F6F6"
        d="M135.345 49.703a20.659 20.659 0 00-10.982-10.992c-18.168-8.023-48.825 5.055-69.98 29.259a108.161 108.161 0 0017.306 17.57 102.82 102.82 0 006.864 5.082c13.97-16.769 34.99-25.969 47.21-20.566 7.913 3.477 10.282 12.168 7.456 22.438.432-.692.827-1.406 1.184-2.14a70.465 70.465 0 003.176-14.254c.99-8.86.231-17.826-2.234-26.397zM38.612 67.511a79.273 79.273 0 01-12.085-30.355 103.95 103.95 0 00-4.172 4.841 92.646 92.646 0 00-14.56 24.658 96.88 96.88 0 0017.01 30.489A78.642 78.642 0 0138.612 67.51z"
      ></path>
    </svg>
  );
}

export default WorldServicesGroupIcon;