import React, { Fragment, useEffect, useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import WSGCheck from "../../Atomos/Icons/WSGCheck";
import { useAppContext } from "../../../Context";
import NewTooltip from "../Tooltip/NewTooltip";
import useViewport from "../../../hooks/useViewPort";

type OptionWithParent = Option & {
  parent?: string;
};

type Option = {
  id?: string;
  name: string;
  selected?: any;
  disable?: boolean;
  firms?: any | null;
  offices?: any | null;
};

type dDMultipleProps = {
  disabled?: boolean;
  title: string;
  objects: Option[];
  selectedObjst: Option[];
  seter: React.Dispatch<React.SetStateAction<Option[]>>;
  error?: boolean;
  required?: boolean;
  errorMessage?: string;
  placeholder?: string;
  tooltip?: string;
  dynamic?: boolean;
  hideCheckboxes?: boolean; // Nuevo prop para esconder los checkboxes
};

function DDMultiple(props: dDMultipleProps) {
  const objects: Option[] = props.objects;
  const [query, setQuery] = useState("");
  const {viewportWidth} = useViewport();

  const [windowWidth, setWindowWidth] = useState(window.outerWidth);

  const filteredAndSortedObjects =
    query === ""
      ? objects
      : objects
          .filter((object) => {
            const name = object.name
              ? object.name.toLowerCase().replace(/\s+/g, "")
              : "override";
            return name.includes(query.toLowerCase().replace(/\s+/g, ""));
          })
          .sort((a, b) => {
            const nameA = a.name?.toLowerCase() || "override";
            const nameB = b.name?.toLowerCase() || "override";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

  useEffect(() => {
    if (props.selectedObjst?.length === 0) {
      setQuery("");
    }
  }, [props.selectedObjst]);

  const { mode } = useAppContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState<number>(0);

  useEffect(() => {
    const updateInputWidth = () => {
      if (inputRef.current) {
        setInputWidth(inputRef.current.offsetWidth);
      }
    };

    updateInputWidth(); // Llamada inicial para obtener el ancho al inicio

    window.addEventListener("resize", updateInputWidth); // Suscripción al evento resize

    return () => {
      window.removeEventListener("resize", updateInputWidth); // Limpiar al desmontar el componente
    };
  }, []);

  useEffect(() => {
    if (inputWidth < 140) {
      inputRef.current?.setAttribute("placeholder", "");
    }
    else {
      inputRef.current?.setAttribute("placeholder", props.placeholder ? props.placeholder : "Select Multiple");
    }
  }, [inputWidth]);

  return (
    <>
      <div className="flex flex-col gap-2.5 w-full">
        <div className="flex gap-2.5 w-full">
          {mode !== "member" && props.title ? (
            <label
              htmlFor={props.title}
              className={` inputLabel ${props && props.disabled ? " text-gray-200" : !props?.required ? "text-gray-font" : " text-primary"} `}
            >
              {props.title}
            </label>
          ) : (
            <label
              htmlFor={props.title}
              className={`inputLabel ${props?.disabled ? " text-gray-200" : "text-gray-font"} `}
            >
              {props.title}{" "}
              <span
                className={`${props && props?.required ? (props?.disabled ? " text-gray-200" : " text-feedback-error") : "hidden"}`}
              >
                *
              </span>
            </label>
          )}
          {props.tooltip && (
            <NewTooltip
              text={props.tooltip}
              size={windowWidth}
              dynamic={props.dynamic}
            />
          )}
        </div>

        <Combobox<Option>
          value={props.selectedObjst}
          onChange={props.seter}
          multiple
          disabled={props.disabled}
        >
          {({ open }) => (
            <div className="relative">
              <div
                className={`relative items-start self-stretch bg-[#F4F4F4] border border-[#F4F4F4] ${props.error ? "errorInput" : ""}`}
              >
                <Combobox.Input
                  ref={inputRef}
                  className={`bg-transparent text-lg leading-5 font-normal not-italic border-none w-full ${props.disabled ? "text-gray-300 border border-solid border-gray-700 border-1" : " text-gray-font placeholder-gray-font focus:outline-none"} `}
                  displayValue={() =>
                    props.selectedObjst
                      ?.map((object: Option) =>
                        object?.name ? object.name : "Override"
                      )
                      .join(", ")
                  }
                  onFocus={(e) => {
                    e.target.value = "";
                    setQuery("");
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = props.selectedObjst
                        ?.map((object: Option) =>
                          object.name ? object.name : "Override"
                        )
                        .join(", ");
                    }
                  }}
                  onChange={(event) => setQuery(event.target.value)}
                  onReset={() => props.seter([])}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {!open ? (
                    <div
                      id="chevron-up"
                      className={`w-6 h-6 ${
                        props.disabled == true
                          ? "fill-gray-500"
                          : "fill-neutral-dark"
                      }`}
                    >
                      <ChevronDown />
                    </div>
                  ) : (
                    <div id="chevron-down" className="w-6 h-6 rotate-180">
                      <ChevronDown />
                    </div>
                  )}
                </Combobox.Button>
              </div>
              <Combobox.Options className="absolute max-h-[400px] no-scrollbar overflow-scroll flex 
              flex-col w-full gap-3 opacity-1 visible scale-[100%] top-30 transform transition-transform 
              ease-out duration-200 transition-delay-200 z-10 bg-white shadow-lg ring-1 ring-black 
              ring-opacity-5 focus:outline-none"
              >
                {filteredAndSortedObjects?.length > 0 &&
                  filteredAndSortedObjects?.map((object: Option) => (
                    <Combobox.Option
                      key={object?.id || object?.name}
                      value={object}
                      as={Fragment}
                      disabled={props.disabled} // Ensure disabled prop is passed here
                    >
                      {({ selected }) => (
                        <li
                          key={object?.id || object?.name}
                          className={`flex  gap-[0.625rem] py-[0.625rem] pl-4 pr-3 lg:py-[0.81rem] lg:pl-5 lg:pr-4 align-middle  text-lg leading-5 text-gray-font bg-white hover:bg-gray-50`}
                        >
                          {!props.hideCheckboxes && (
                            <div
                              className={` stroke-gray-font stroke-2 ${!selected && "fill-neutral"} ${mode !== "member" && selected ? "fill-Default" : " fill-accentDefault"} h-5 w-5 min-w-[20px] min-h-[20px] `}
                            >
                              <WSGCheck check={selected} />
                            </div>
                          )}
                          <div className=" first-letter:uppercase">
                            {object.name ? object.name : "Overide"}
                          </div>
                        </li>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </div>
          )}
        </Combobox>

        {props.error && (
          <span
            className={`flex flex-wrap items-center   text-sm font-medium text-feedback-error`}
          >
            {props.errorMessage}
          </span>
        )}
      </div>
    </>
  );
}

export default DDMultiple;
export type { Option, OptionWithParent };
