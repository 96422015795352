import useTh from "../../../hooks/useTh";
import { addSpacesBetweenUppercase } from "../../../utils/String";
import CustomShuffle from "../../Atomos/Icons/CustomShuffle";
import SortDefault from "../../Atomos/Icons/SortDefault";
import "../../../assets/css/table/Table.css";

interface IThProps {
  sort: { field: string; order: string }[];
  HandlerSort: (field: string) => void;
  index: number;
  title: string;
  displayName?: string;
  sorting: boolean;
  handledragstart: (e: any, index: any) => void;
  handleDrop: (e: any, index: any) => void;
  className?: string;
}

const Th: React.FC<IThProps> = ({
  handledragstart,
  handleDrop,
  sorting,
  index,
  sort,
  title,
  HandlerSort,
  displayName,
  className,
}) => {
  const { mouseoutHandler, handleMouseDown, mouseoverHandler, toogleSortIcon } =
    useTh({ sorting, index, sort, title, HandlerSort });

  return (
    <th
      onMouseDown={(e) => {
        if (e.target === e.currentTarget) {
          handleMouseDown(e, e.currentTarget);
        }
      }}
      onMouseOver={() => mouseoverHandler(index)}
      onMouseOut={() => mouseoutHandler(index)}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragStart={(e) => {
        handledragstart(e, index);
      }}
      onDrop={(e) => handleDrop(e, index)}
      className={`th-${title} text-left font-normal text-[1.125rem] text-sm p-2 ${className}`}
    >
      <div className=" flex justify-start">
        <div
          draggable
          className="min-w-[1rem] fill-white flex items-center justify-end cursor-grab mr-2"
        >
          <CustomShuffle />
        </div>
        <div className="flex items-center gap-1">
          <span className="select-none first-letter:uppercase">
            {addSpacesBetweenUppercase(displayName ? displayName : title)}
          </span>
          {sorting && (
            <span
              className=" w-4 h-4 fill-neutral"
              onClick={() => HandlerSort(title)}
            >
              {sort && sort?.some((sortItem) => sortItem.field === title) ? (
                toogleSortIcon(title)
              ) : (
                <SortDefault />
              )}
            </span>
          )}
        </div>
      </div>
    </th>
  );
};

export default Th;
