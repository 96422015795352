function Email(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 21 20" 
      >
        <path
          d="M2.03906 16.9612V3.02734H19.3063V16.9612H2.03906ZM10.6668 10.752L17.6441 6.10618V4.68949L10.6668 9.23116L3.68944 4.68949V6.10618L10.6668 10.752Z" 
        />
      </svg>
    </div>
  );
}

export default Email;
