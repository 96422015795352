import React from "react";

function DarkCrossEye() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
      <path d="M15.796 13.341l-1.567-1.552c.387-.929.23-1.681-.47-2.257-.7-.577-1.41-.692-2.133-.346L10.16 7.704a3.1 3.1 0 01.88-.343c.32-.074.642-.111.961-.111 1.183 0 2.188.412 3.012 1.237.825.825 1.238 1.83 1.238 3.013 0 .31-.039.637-.116.982-.078.344-.19.63-.338.86zm3.493 3.522l-1.212-1.226c.77-.59 1.44-1.245 2.01-1.963a9.495 9.495 0 001.324-2.174c-.852-1.84-2.105-3.3-3.757-4.38C16 6.04 14.2 5.5 12.25 5.5c-.7 0-1.398.062-2.093.186-.696.124-1.224.268-1.584.432L7.14 4.675c.584-.267 1.344-.5 2.28-.7.937-.2 1.838-.3 2.705-.3 2.421 0 4.65.696 6.686 2.087 2.036 1.391 3.54 3.304 4.514 5.738a12.086 12.086 0 01-1.682 2.989 13.068 13.068 0 01-2.354 2.374zm.984 5.664l-4.002-3.941a9.989 9.989 0 01-1.99.544 13.23 13.23 0 01-2.281.195c-2.48 0-4.743-.698-6.787-2.094C3.168 15.835 1.655 13.925.675 11.5c.315-.857.775-1.71 1.38-2.559a16.435 16.435 0 012.156-2.44l-3.108-3.08L2.266 2.22l19.095 19.094-1.088 1.213zM5.476 7.763a9.523 9.523 0 00-1.71 1.74c-.532.69-.928 1.356-1.188 1.997.86 1.85 2.137 3.313 3.83 4.387C8.104 16.962 10.034 17.5 12.2 17.5c.494 0 .99-.029 1.49-.086.501-.057.885-.152 1.151-.286l-1.6-1.6a2.14 2.14 0 01-.583.167 4.232 4.232 0 01-.658.055 4.133 4.133 0 01-3-1.225c-.833-.817-1.25-1.825-1.25-3.025 0-.212.016-.43.048-.651.033-.222.081-.419.146-.59L5.476 7.763z"></path>
    </svg>
  );
}

export default DarkCrossEye;
