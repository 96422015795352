import React from "react";

function Notifications() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M6.785 38.622v-3.99h4.454V20.125c0-2.923.863-5.578 2.588-7.963 1.726-2.386 4.023-3.89 6.893-4.513V3.18h6.56v4.468c2.889.623 5.204 2.127 6.946 4.513 1.742 2.385 2.613 5.04 2.613 7.963v14.509h4.404v3.989H6.785zm17.23 6.084c-1.133 0-2.122-.413-2.97-1.24-.847-.828-1.271-1.823-1.271-2.986h8.48c0 1.176-.417 2.174-1.252 2.995-.835.821-1.83 1.231-2.988 1.231z"
      ></path>
    </svg>
  );
}

export default Notifications;
