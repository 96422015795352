import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetContentGeneralTopics, UpdateContentGeneralTopics } from "../../redux/catalogs/states";
import { addContentGeneralTopics, getContentGeneralTopics } from "../../Services/ContentGeneralTopic";
import { v4 as UUIDV4 } from "uuid";

const UseContentGeneralTopicList = () => {
  const [isFetching, setIsFetching] = useState(true)

  const dispatch = useDispatch();
  const contentGeneralTopicsData = useSelector((state: Appstore) => state.contentGeneralTopics);

  const resetContentGeneralTopics = () => {
    setIsFetching(true)
  }

  useEffect(() => {
      if (contentGeneralTopicsData.length) {
        setIsFetching(false)
        return
      }
      if (!isFetching) return

      getContentGeneralTopics()
      .then((response) => {
        dispatch(SetContentGeneralTopics(response))
        if (!response.length) console.error('No general topics found. Please check the data source.')
      })
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false))
  }, [isFetching]);

  return {contentGeneralTopicsData, isFetching,resetContentGeneralTopics};
};

const AddContentGeneralTopic = async (modalNameAux: string) => {
  // const dispatch = useDispatch(); // Hooks can only be called inside of the body of a function component. but this is just a normal function is not a component or react node
    const addData = { id: UUIDV4(), name: modalNameAux };
    const response = await addContentGeneralTopics(addData);
    // dispatch(UpdateContentGeneralTopics(response));
    console.log("GeneralTopic",response);
    return response;
};


export {UseContentGeneralTopicList, AddContentGeneralTopic}
