import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M16.548 37.184L24 32.734l7.453 4.5-2.015-8.419L36 23.098l-8.648-.744L24 14.407l-3.352 7.926-8.648.715 6.571 5.69-2.023 8.446zm-5.944 8.286l3.533-15.266-11.861-10.27 15.632-1.328L24 4.19l6.092 14.415 15.632 1.329-11.86 10.27 3.56 15.265L24 37.34 10.604 45.47z"
      ></path>
    </svg>
  );
}

export default Icon;
