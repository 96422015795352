import React, { useState, useEffect, useRef } from "react";
import "./Tooltip.css"; // Estilo del tooltip
import { set } from "date-fns";

interface TooltipProps {
  text: string;
  size?: number;
  dynamic?: boolean;
}

const NewTooltip: React.FC<TooltipProps> = ({ text, size, dynamic }) => {
  const [tooltipPosition, setTooltipPosition] = useState(false);
  const [multiLine, setMultiline] = useState(false);
  const tooltipRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if(dynamic){
      if(size){
        let newSize = size;
        if (size > window.innerWidth){
          newSize = window.innerWidth;
        } 
        if(newSize >= 1150){
          setMultiline(true);
          setTooltipPosition(false);
        } else if(newSize < 1150 && newSize >= 768){
          setTooltipPosition(true);
          setMultiline(true);
        } else if ( newSize <= 767 && newSize > 400){
          setMultiline(true);
          setTooltipPosition(false);
        } else{
          setTooltipPosition(true);
          setMultiline(true);
        }
      }
    }
  }
  , [size]);

  return (
    <div className="has-tooltip p-0.5 gap-2 justify-center items-center rounded-full bg-accent-Default-dark relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.31057 10.513C7.31057 9.75829 7.39753 9.18051 7.57147 8.77966C7.7454 8.37881 8.10844 7.93437 8.66058 7.44632C9.09049 7.06342 9.41634 6.7162 9.63813 6.40466C9.85993 6.09312 9.97083 5.73992 9.97083 5.34504C9.97083 4.82111 9.79241 4.38564 9.43557 4.03862C9.07872 3.69161 8.58748 3.51811 7.96183 3.51811C7.39089 3.51811 6.94644 3.6711 6.6285 3.97707C6.31054 4.28305 6.06909 4.6245 5.90413 5.00142L4.6875 4.46682C4.94648 3.82837 5.35075 3.29611 5.90032 2.87006C6.44988 2.44399 7.13706 2.23096 7.96183 2.23096C9.03875 2.23096 9.8676 2.53459 10.4484 3.14186C11.0291 3.74911 11.3195 4.46984 11.3195 5.30402C11.3195 5.82539 11.2065 6.28543 10.9804 6.68414C10.7543 7.08286 10.4114 7.49804 9.95157 7.92966C9.37293 8.46042 9.0146 8.87901 8.87657 9.18542C8.73852 9.49184 8.6695 9.93436 8.6695 10.513H7.31057ZM7.96183 14.3335C7.68919 14.3335 7.45437 14.235 7.25737 14.038C7.06037 13.841 6.96187 13.6062 6.96187 13.3335C6.96187 13.0609 7.06037 12.826 7.25737 12.629C7.45437 12.432 7.68919 12.3335 7.96183 12.3335C8.23448 12.3335 8.4693 12.432 8.6663 12.629C8.86331 12.826 8.96182 13.0609 8.96182 13.3335C8.96182 13.6062 8.86331 13.841 8.6663 14.038C8.4693 14.235 8.23448 14.3335 7.96183 14.3335Z"
          fill="white"
        />
      </svg>
      <p
        ref={tooltipRef}
        className={`tooltip flex items-center justify-center md:justify-start text-[0.875rem] leading-4 not-italic font-normal
       text-neutral lg:leading-5 lg:text-lg absolute bottom-0 ${tooltipPosition ? "right-[28px]" : "left-[28px]"} ${multiLine ? "min-w-[158px] md:min-w-[309px]" : "min-w-content"} bg-gray-font p-6 min-h-max'}`}
        style={{
          boxShadow:
            "var(--elevation-2-box-shadow, 0px 0px 12px 0px rgba(44, 44, 44, 0.12))",
          whiteSpace: tooltipPosition || multiLine ? "normal" : "nowrap", // Allow line breaks
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default NewTooltip;
