import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  createEditor,
  Descendant,
  Transforms,
  Editor,
  Element as SlateElement,
  BaseEditor,
} from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";
import isHotkey from "is-hotkey";
import {
  Button,
  LinkButton,
  MarkButton,
  BlockButton,
  Toolbar,
  CheckListItemElement,
} from "./Components";
import { useAppContext } from "../../Context";

interface EditorProps {
  title: string;
  name?: string;
  errorMsj?: string;
  value?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  tooltip?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxChars?: number;
  characterCount?: boolean;
  height?: string;
}

const HOTKEYS: { [key: string]: string } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

type CustomElement = {
  type: any;
  url?: string;
  checked?: boolean;
  children: CustomText[];
};
type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  [key: string]: any;
};

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const init: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const RichTextEditor: React.FC<EditorProps> = (props) => {
  const [charactersNumber, setCharactersNumber] = useState<number>(0);
  const [initialValue, setInitialValue] = useState<Descendant[]>(
    props.value ? props.value : init
  );
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const { mode } = useAppContext();

  useEffect(() => {
    setInitialValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.value) {
      editor.children = props.value;
      editor.onChange();
    }
  }, [props.value, editor]);

  useEffect(() => {
    let count = 0;

    const countNodes = (nodes: any[]) => {
      if (!nodes?.length) return;
      for (const node of nodes) {
        if (node.text) {
          count += node.text.length;
        }
        if (node.children) {
          countNodes(node.children);
        }
      }
    };

    countNodes(initialValue);

    setCharactersNumber(count);
  }, [initialValue]);

  const onChange = (value: any) => {
    // setInitialValue(value);
    if (props.onChange) {
      const syntheticEvent = {
        target: {
          name: props.name,
          value: value,
        },
      };
      props.onChange(syntheticEvent as React.ChangeEvent<HTMLTextAreaElement>);
    }
  };

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case "link":
        return (
          <a
            className="text-Default underline"
            {...props.attributes}
            href={props.element.url}
          >
            {props.children}
          </a>
        );
      case "heading-one":
        return <h1 {...props.attributes}>{props.children}</h1>;
      case "heading-two":
        return <h2 {...props.attributes}>{props.children}</h2>;
      case "heading-three":
        return <h3 {...props.attributes}>{props.children}</h3>;
      case "bulleted-list":
        return (
          <ul className="ml-4 list-disc" {...props.attributes}>
            {props.children}
          </ul>
        );
      case "numbered-list":
        return (
          <ol className="ml-4 list-decimal" {...props.attributes}>
            {props.children}
          </ol>
        );
      case "list-item":
        return <li {...props.attributes}>{props.children}</li>;
      case "check-list-item":
        return <CheckListItemElement {...props} />;
      case "left":
        return (
          <div style={{ textAlign: "left" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "center":
        return (
          <div style={{ textAlign: "center" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "right":
        return (
          <div style={{ textAlign: "right" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "justify":
        return (
          <div style={{ textAlign: "justify" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "paragraph":
      default:
        return <p {...props.attributes}>{props.children}</p>;
    }
  }, []);

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  const onKeyDown = (event: React.KeyboardEvent) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  const toggleMark = (editor: Editor, format: string) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isMarkActive = (editor: Editor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const removeAllMarks = (editor: Editor) => {
    const marks = Object.keys(Editor.marks(editor) || {});
    marks.forEach((mark) => Editor.removeMark(editor, mark));

    Transforms.setNodes(
      editor,
      { type: "paragraph" },
      { match: (n) => SlateElement.isElement(n) }
    );

    const { children } = editor;
    if (
      children.length === 0 ||
      (children.length === 1 &&
        Editor.isEmpty(editor, children[0] as SlateElement))
    ) {
      Transforms.insertNodes(editor, {
        type: "paragraph",
        children: [{ text: "" }],
      });
    }
  };

  function Divider() {
    return (
      <div className="border border-x-[0.5px] border-solid border-gray-300 " />
    );
  }

  return (
    <div className={`flex flex-col self-stretch items-start`}>
      <div className="flex justify-between w-full pb-3">
        {mode !== "member" ? (
          <label
            className={` inputLabel ${props.disabled ? " text-gray-200" : props.error ? "text-feedback-error" : !props.required ? "text-gray-font" : " text-primary"} `}
          >
            {props.title}
          </label>
        ) : (
          <label
            className={`inputLabel ${props.disabled ? " text-gray-200" : "text-gray-font"} `}
          >
            {props.title}{" "}
            <span
              className={`${props.required ? (props.disabled ? " text-gray-200" : " text-feedback-error") : "hidden"}`}
            >
              *
            </span>
          </label>
        )}
        {props.tooltip && (
          <div className="has-tooltip relative">
            <span
              className="tooltip text-[0.625rem] leading-3 bottom-6 right-0 h-5 min-w-max shadow-sm px-2 py-1 bg-gray-font text-neutral"
              style={{
                boxShadow:
                  "var(--elevation-2-box-shadow, 0px 0px 12px 0px rgba(44, 44, 44, 0.12))",
              }}
            >
              {props.tooltip}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.07082 10.4625C7.08193 9.56826 7.18615 8.90956 7.3835 8.48637C7.58084 8.06319 7.92783 7.65062 8.42445 7.24869C8.87227 6.87044 9.20862 6.50826 9.4335 6.16216C9.65838 5.81607 9.77082 5.44129 9.77082 5.03783C9.77082 4.57551 9.61828 4.19182 9.31322 3.88674C9.00814 3.58168 8.587 3.42914 8.04982 3.42914C7.50972 3.42914 7.08097 3.58204 6.76358 3.88784C6.44619 4.19363 6.21213 4.55257 6.0614 4.96466L4.32227 4.20668C4.59811 3.45691 5.05125 2.83577 5.68168 2.34326C6.31213 1.85075 7.10074 1.60449 8.04752 1.60449C9.21658 1.60449 10.1168 1.9337 10.7483 2.59213C11.3797 3.25054 11.6955 4.04669 11.6955 4.98059C11.6955 5.58929 11.58 6.13072 11.3491 6.60488C11.1182 7.07903 10.7496 7.54557 10.2433 8.00451C9.7177 8.48276 9.3962 8.86235 9.2788 9.14327C9.16141 9.4242 9.1003 9.86393 9.09547 10.4625H7.07082ZM8.04782 14.9306C7.66412 14.9306 7.33797 14.7963 7.06937 14.5277C6.80077 14.2591 6.66647 13.9339 6.66647 13.552C6.66647 13.1702 6.80052 12.8449 7.06862 12.5763C7.33672 12.3077 7.66353 12.1733 8.04907 12.1733C8.43459 12.1733 8.76165 12.3077 9.03025 12.5763C9.29885 12.8449 9.43315 13.1702 9.43315 13.552C9.43315 13.9339 9.29818 14.2591 9.02825 14.5277C8.75832 14.7963 8.4315 14.9306 8.04782 14.9306Z"
                fill="#2C2C2C"
              />
            </svg>
          </div>
        )}
      </div>

      <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
        <div className="flex w-full overflow-x-auto">
          <Toolbar>
            <BlockButton
              format="heading-one"
              icon={
                <path
                  stroke="currentColor"
                  strokeWidth="1"
                  fill="currentColor"
                  d="M1.3,5h1.4v5.8h5.4V5h1.4v14H8.1v-6.6H2.7V19H1.3V5z M13.2,17.6h2.3V8.4h-1.9V7.3c0.9-0.2,1.6-0.5,2.2-0.9h1v11.3h2.2v1.4h-5.8V17.6z"
                />
              }
            />
            <BlockButton
              format="heading-two"
              icon={
                <path
                  stroke="currentColor"
                  strokeWidth="1"
                  fill="currentColor"
                  d="M1.3,5h1.4v5.8h5.4V5h1.4v14H8.1v-6.6H2.7V19H1.3V5z M13.1,18.4c2.7-2.8,4.2-4.5,4.2-5.9c0-1-0.5-1.8-1.7-1.8c-0.7,0-1.3,0.5-1.9,1.2l-0.7-0.7  c0.7-0.8,1.5-1.5,2.7-1.5c1.7,0,2.7,1.2,2.7,2.8c0,1.8-1.5,3.5-3.5,5.8c0.5,0,1,0,1.5,0H19v1h-5.7v-0.8H13.1z"
                />
              }
            />
            <BlockButton
              format="heading-three"
              icon={
                <path
                  stroke="currentColor"
                  strokeWidth="1"
                  fill="currentColor"
                  d="M1.3,5h1.4v5.8h5.4V5h1.4v14H8.1v-6.6H2.7V19H1.3V5z M16,19c-0.9,0-1.5-0.3-2.1-0.9l0.5-0.6l0,0c0.4,0.4,0.9,0.9,1.8,0.9c0.9,0,1.5-0.6,1.5-1.4  c0-0.6-0.2-1.4-2.2-1.4h-0.3v-0.7c1.3,0,2-0.5,2-1.4c0-0.6-0.4-1.2-1.2-1.2c-0.6,0-1.2,0.3-1.5,0.8l0,0l-0.5-0.6l0.1-0.1  c0.7-0.6,1.3-0.8,1.9-0.8c1.3,0,2,0.6,2,1.8c0,0.7-0.4,1.3-0.9,1.6l-0.3,0.1l0.2,0.2c0.7,0.5,1.1,1,1.1,1.8C18.2,18.2,17.3,19,16,19  z"
                />
              }
            />
            <Divider />
            <BlockButton
              format="bulleted-list"
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5"
                />
              }
            />
            <BlockButton
              format="numbered-list"
              icon={
                <path
                  stroke="currentColor"
                  d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.321 1.5L4 20h5M4 5l2-1v6m-2 0h4"
                />
              }
            />
            <BlockButton
              format="check-list-item"
              icon={
                <path
                  stroke="currentColor"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              }
            />
            <Divider />
            <Button
              onMouseDown={() => editor.undo()}
              icon={
                <path
                  stroke="currentColor"
                  d="M3 9h13a5 5 0 0 1 0 10H7M3 9l4-4M3 9l4 4"
                />
              }
            />
            <Button
              onMouseDown={() => editor.redo()}
              icon={
                <path
                  stroke="currentColor"
                  d="M21 9H8a5 5 0 0 0 0 10h9m4-10-4-4m4 4-4 4"
                />
              }
            />
            <Button
              onMouseDown={() => removeAllMarks(editor)}
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              }
            />

            <MarkButton
              format="bold"
              icon={
                <path
                  stroke="currentColor"
                  d="M8 5h4.5a3.5 3.5 0 1 1 0 7H8m0-7v7m0-7H6m2 7h6.5a3.5 3.5 0 1 1 0 7H8m0-7v7m0 0H6"
                />
              }
            />
            <MarkButton
              format="italic"
              icon={
                <path
                  stroke="currentColor"
                  d="m8.874 19 6.143-14M6 19h6.33m-.66-14H18"
                />
              }
            />
            <MarkButton
              format="underline"
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  d="M6 19h12M8 5v9a4 4 0 0 0 8 0V5M6 5h4m4 0h4"
                />
              }
            />
            <Divider />
            <LinkButton
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                />
              }
            />
            <Divider />
            <BlockButton
              format="left"
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  d="M5 7h14M5 12h14M5 17h10"
                />
              }
            />
            <BlockButton
              format="center"
              icon={
                <path stroke="currentColor" d="M8 6h8M6 10h12M8 14h8M6 18h12" />
              }
            />
            <BlockButton
              format="right"
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  d="M5 7h14M5 12h14M9 17h10"
                />
              }
            />
            <BlockButton
              format="justify"
              icon={
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  d="M5 7h14M5 12h14M5 17h14"
                />
              }
            />
          </Toolbar>
        </div>
        <Editable
          className={`h-[240px] w-full overflow-y-auto bg-gray-50 p-3 focus:outline-none focus:ring-0 focus:border-transparent`}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
        />
      </Slate>

      <span
        className={`flex flex-wrap items-center ${!props.error && "hidden"} text-sm font-medium text-feedback-error`}
      >
        {props.errorMsj}
      </span>

      {props.characterCount && !props.maxChars && (
        <p className="w-fit flex ml-auto gray-font text-sm">
          Characters: {charactersNumber}
        </p>
      )}
      {props.characterCount && props.maxChars && (
        <p className="w-fit flex ml-auto gray-font text-sm">
          Characters: {charactersNumber}/{props.maxChars}
        </p>
      )}
    </div>
  );
};

export default RichTextEditor;
