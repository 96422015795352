import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileViewModeBg from "../../assets/images&icons/bg-login.jpg";
import Axios from "../../utils/axiosPublic";

const axios = new Axios();

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { userName, validationCode } = location?.state as { userName: string, validationCode: string };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);

    const isPasswordValid =
      newPassword.trim() === newPassword && // No leading or trailing spaces
      newPassword.length >= 8 && // Minimum 8 characters
      /[A-Z]/.test(newPassword) && // At least 1 uppercase letter
      /\d/.test(newPassword) && // At least 1 number
      /[!@#$%^&*(),.?":{}|<>]/.test(newPassword) && // At least 1 special character
      /^[^\s!@#$%^&*(),.?":{}|<>]/.test(newPassword); // Does not start with special character or space

    setIsPasswordValid(isPasswordValid);

    if (!isPasswordValid) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain at least 1 uppercase letter, 1 number, 1 special character, cannot start with a space or special character, and have no leading or trailing spaces."
      );
    } else {
      setErrorMessage("");
    }
  };




  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPasswordTouched(true);
    const password = event.target.value;
    setConfirmPassword(password);
    if (password !== newPassword) {
      setErrorMessage("Passwords do not match.");
    } else if (!isPasswordValid) {
      setErrorMessage("Password must be at least 6 characters long, contain at least 1 uppercase letter and 1 number, and have no leading or trailing spaces.");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    if (!isPasswordValid) {
      setErrorMessage("Password must be at least 6 characters long, contain at least 1 uppercase letter and 1 number, and have no leading or trailing spaces.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const numberCode = parseInt(validationCode);
      if (isNaN(numberCode)) {
        setErrorMessage("Invalid verification code.");
        return;
      }

      const response = await axios.Post(`/password`, {
        userName,
        verificationCode: numberCode,
        password: newPassword,
        confirmPassword
      });

      if (response.data.status === "success") {
        setSuccessMessage("Password has been reset successfully. Please log in with your new password.");
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setErrorMessage(response.data.message || "Failed to reset password. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to reset password. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <div
        className="absolute w-full min-h-screen"
        style={{
          backgroundImage: `url(${ProfileViewModeBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </div>
      <div className="w-full min-h-screen justify-center items-center flex self-stretch relative">
        <div className="lg:w-[520px] w-8/12">
          <div className={`flex w-full align-middle justify-center gap-28 px-5 py-4 bg-[#9C3325]`}>
            <h2 className={`text-center text-xl font-sans text-white roboto-font`}>RESET PASSWORD</h2>
          </div>
          <div className='bg-white w-full py-4'>
            <form className='text-black py-8 flex flex-col gap-4' onSubmit={handleSubmit}>
              <div className='w-full px-5'>
                <input
                  type="password"
                  placeholder='Enter New Password'
                  value={newPassword}
                  onChange={handlePasswordChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <ul className="text-left list-item text-[.75rem] mt-2">
                  <li className={newPassword.length >= 8 ? 'text-green-500' : 'text-feedback-error'}>- Minimum 8 characters</li>
                  <li className={/[A-Z]/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- At least 1 Capital Letter</li>
                  <li className={/\d/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- At least 1 number</li>
                  <li className={/[!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- At least 1 special character</li>
                  <li className={/^[^\s!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? 'text-green-500' : 'text-feedback-error'}>- Must not start with a space or special character</li>
                </ul>
              </div>
              <div className='w-full px-5'>
                <input
                  type="password"
                  placeholder='Confirm New Password'
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                {confirmPasswordTouched && (
                  newPassword !== '' && newPassword === confirmPassword ? (
                    <span className="text-[.75rem] text-feedback-success">
                      Passwords match!
                    </span>
                  ) : (
                    newPassword !== '' && (
                      <span className="text-[.75rem] text-feedback-error">
                        Passwords don't match
                      </span>
                    )
                  )
                )}
              </div>
              <button
                type="submit"
                disabled={!isPasswordValid || newPassword !== confirmPassword}
                className={`cursor-pointer w-11/12 text-white mx-auto flex text-center align-middle justify-center py-4 inter_font bg-[#BC3323] font-semibold text-[1rem] ${(!isPasswordValid || newPassword !== confirmPassword) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                Reset Password
              </button>
            </form>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPasswordPage;
