import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetJurisdictionGroups } from "../../redux/catalogs/states";
import { getJurisdictionList } from "../../Services/JurisdictionGroup";

const UseJurisdictionGroupList = () => {
  const [isFetching, setIsFetching] = useState(false)

  const dispatch = useDispatch();
  const jurisdictionGroupData = useSelector((state: Appstore) => state.jurisdictionGroups)

  useEffect(() => {
    if (jurisdictionGroupData.length)  {
      setIsFetching(false)
      return
    }
    if (!isFetching) return

    getJurisdictionList()
    .then((resp) => dispatch(SetJurisdictionGroups(resp)))
    .catch((error) => console.error(error))
    .finally(() => setIsFetching(false))
  }, [isFetching]);

  useEffect(() => {
    setIsFetching(true)
  }, []);

  return {jurisdictionGroupData, isFetching};
};

export {UseJurisdictionGroupList}