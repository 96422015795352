import React from "react";

function FillStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 44 42"
    >
      <path 
        d="M8.604 41.47l3.533-15.266L.276 15.934 15.92 14.6 22 .19 28.08 14.6l15.644 1.335-11.86 10.27 3.56 15.265L22 33.34 8.604 41.47z"
      ></path>
    </svg>
  );
}

export default FillStar;
