import React from "react";

function CrossPoints() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M23.995 27.98c-1.105 0-2.044-.387-2.817-1.163-.772-.775-1.159-1.716-1.159-2.821 0-1.106.388-2.045 1.164-2.817.775-.773 1.716-1.16 2.821-1.16 1.106 0 2.045.388 2.817 1.164.773.775 1.16 1.716 1.16 2.822 0 1.105-.388 2.044-1.164 2.817-.775.772-1.716 1.159-2.822 1.159zM22.02 15.52V5.35h3.961v10.17h-3.96zm0 27.13V32.48h3.961v10.17h-3.96zM32.48 25.98v-3.96h10.17v3.96H32.48zm-27.13 0v-3.96h10.17v3.96H5.35z"
      ></path>
    </svg>
  );
}

export default CrossPoints;
