import React from 'react';
import css from './ProfileHeading.module.css' 
import { useNavigate } from 'react-router-dom'; 
import { useModeContext } from '../../../../hooks/useMode'; 
import Eye2 from '../../../Atomos/Icons/Eye';
import TuneProfile from '../../../Atomos/Icons/ViewMode/Tune';
import CrossEye from '../../../Atomos/Icons/ViewMode/CrossEye';
import VCard from '../../../Atomos/Icons/ViewMode/VCard';
import TriangleDown from '../../../Atomos/Icons/EditMode/TriangleDown';
import NewButton from '../../../Atomos/Buttons/newButton';

interface headingProps {
  currentPage: string;
  title: string;
  viewModeButtons?: boolean;
  isProfileUser?: boolean;
  isEditMode?: boolean;
  currentUser?: string;
  updateProfile?: () => void;
  isDisabled?: boolean;
  draftDropDown?: boolean;
  onToggleDraftOption?: (e:any) => void;
}

const ProfileHeading: React.FC<headingProps> = ({ title, currentPage, viewModeButtons, isProfileUser, isEditMode, currentUser, updateProfile, isDisabled, draftDropDown, onToggleDraftOption }) => {
  const navigate = useNavigate()
  const {isViewMode, toggleMode} = useModeContext()
  return (
    <div className={`lg:w-profileContainer w-11/12 mx-auto ${css.backgroundProfile} pt-[2.5rem] lg:pt-[5.5rem] md:pt-[3rem] relative`}>
      <div className='w-full mx-auto overflow-hidden'>
        <div className='flex items-center justify-between'>
          <h1 id='mainTitle' className={`relative text-white font-bold roboto_font
          whitespace-nowrap text-[2.5rem] roboto_font pr-2 text-left`}> 
          {title}
          </h1> 
          <div className={`${css.mainTitle}`}>
            &nbsp;
          </div>
          { viewModeButtons &&
            
            <div className='gap-4 hidden md:flex'>
             <div className={`z-10 relative flex justify-center gap-2 ${!isViewMode ? 'mr-4 w-fit ml-auto' : ''}`}>
              {
                isViewMode ?
                <React.Fragment> 
                  <NewButton text='Preview Public Profile' content='textIcon' color='primary' icon={<Eye2/>} onClick={toggleMode}/>
                  <NewButton text='Site Preferences' color='accent' content='textIcon' icon={<TuneProfile/>}/>  
                </React.Fragment>
                :
                <React.Fragment>
                  {isProfileUser && 
                    <NewButton text='Exit Preview Mode' content='textIcon' color='primary' icon={<CrossEye/>} onClick={toggleMode}/> 
                  }
                  <NewButton text='Download VCard' content='textIcon' color='accent'  icon={<VCard/>}/>
                </React.Fragment>
              }
            </div>
            </div>
          }
          {
          isEditMode ?
          <div>
            <div className='gap-4 hidden md:flex md:gap-0'> 
              <NewButton text='save' color='primary' onClick={updateProfile} disabled={isDisabled}/>
              <hr className='h-[3rem] relative border-white border w-[1px]'/>
              <div  onClick={onToggleDraftOption} className='w-[2.25rem] h-[3rem] fill-neutral bg-primary-light flex items-center justify-center cursor-pointer'>
                <TriangleDown />
              </div>
            </div>
            {draftDropDown && 
                <div className="bg-white text-xs hidden md:flex w-[6.5rem] absolute right-[.02%] mt-2 ml-auto z-20 mb-4 p-4 cursor-pointer roboto_font">
                    Save as Draft
                </div>}
          </div>
          
          : null
          }
        </div>
        <hr className='my-4'/>
        <div className="flex roboto_font text-white">
          <a href="/" className="text-base">Home</a>
          <span>&nbsp; &gt; &nbsp; </span>
          {!isProfileUser ?
            <p className="font-bold  mb-4">{ currentPage}</p>
            :
            <p className="font-bold  mb-4">Member Profile</p>
          }
        </div>
      </div>
    </div>
  );
};

export default ProfileHeading;
