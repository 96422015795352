import React from "react";
import CurrentPage from "../Layout/CurrentPageTab/CurrentPage";
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

interface IHeaderWithDotsProps {
  title: string;
  currentpage: string;
}

const HeaderWithDots: React.FC<IHeaderWithDotsProps> = ({ title, currentpage }) => {
  return (
    <React.Fragment>
      <div className="flex flex-col items-start pt-7 px-4 mb-5 md:pt-8 md:px-6 lg:pt-12 lg:px-10">
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
            <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
              {title}
            </h2>
            <div className='self-stretch flex-1 hidden md:flex' style={{
              backgroundImage: `url(${WSGDots.default})`,
            }}>
            </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Home" currentPage={currentpage} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default HeaderWithDots;