import React from "react";

function ChevronUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M37.028 29L34.2 31.828l-9.9-9.9-9.9 9.9L11.57 29 24.3 16.27 37.028 29z"
      ></path>
    </svg>
  );
}

export default ChevronUp;
