import React from 'react';
import css from './EventCard.module.css';
import nextArrow from '../../../assets/images&icons/next-arrow.svg'
interface CardProps {
  title: string;
  buttonText?: string;
  buttonImage: string;
  buttonSecondText?: string;
  buttonThirdText?: string;
}

const EventCard: React.FC<CardProps> = ({ title, buttonText, buttonImage, buttonSecondText, buttonThirdText }) => {
  return (
    <div className={`relative ${css.cardLayout}`}>
      <div className={`${css.cardHeader} flex`}>
        <h2 className='w-11/12 flex text-base'>
          {title}
        </h2>
        <div className={`${css.editButton}`}>
        </div>
      </div>
      <div className={`${css.cardBody}`}>
      </div>
      <div className={`${buttonText && buttonSecondText && !buttonThirdText ? 'md:absolute bottom-0' : 'relative'}
      lg:flex md:gap-[1.5rem] lg:flex-row  
      md:px-4 md:py-3 md:justify-between md:flex-row md:flex-wrap
      w-full justify-between flex flex-row-reverse items-start self-stretch px-4 py-5
      ${buttonThirdText ? 'md:gap-y-3' : 'md:gap-y-0'} `}>

        { buttonText &&
          <div className={`flex gap-2 ${buttonSecondText && buttonThirdText ? 'md:col-span-2 md:w-full lg:w-auto' : ''} w-auto`}>
          <p className='redTextCard roboto_font'>{buttonText}</p>
          <img src={nextArrow} alt="nextArrow" className=' flex'/>
        </div>}
        <div className={`flex ${buttonSecondText && buttonThirdText ? 'w-max md:w-full lg:w-auto justify-around md:justify-between gap-3' : ''}`}>
          { buttonSecondText &&
            <div className='flex items-center gap-2 '>
              <p className='blueCardText roboto_font'>{buttonSecondText}</p>
              <img src={buttonImage} alt="plus" className=' flex'/>
            </div>
          }
          { buttonThirdText &&
          <div className='flex items-center gap-2 '>
            <p className='blueCardText roboto_font'>{buttonThirdText}</p>
            <img src={buttonImage} alt="plus" className=' flex'/>
          </div>
          }
        </div>

      </div>
    </div>
  );
};

export default EventCard;
