import React, { useState, useEffect } from 'react';
import { IPerson } from '../../../interfaces'; // Ajusta la ruta según sea necesario
import { IAOPRanking } from '../../../interfaces/models/IAOPRanking';
import Axios from '../../../utils/axios';
import Input from '../../../components/Atomos/Inputs/Input';
import SaveOverride from '../../../components/Catalogs/SaveOverride';
import CatalogTitle from '../../../components/Moleculas/Catalog Text/CatalogTitle';
import GoBack from '../../../components/Moleculas/GoBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CatalogSubTitle from '../../../components/Moleculas/Catalog Text/CatalogSubTitle';
import ButtonsCrud from '../../../components/Catalogs/ButtonsCrud';
import { deleteAOPRanking, getAOPRankingById, updateAOPRanking } from '../../../Services/AOPRanking';
import { modifyAOPRanking, resetAOPRanking } from '../../../redux';
import { useDispatch } from 'react-redux';
import RankingItem from '../../../components/Moleculas/AopRanking/RankingItem';
import { useLogging } from '../../../Context/LoggingContext';
import { UseFirmList } from '../../../hooks/Catalogs/useFirm';

interface AddAOPRankingProps {
  mode: 'add' | 'edit';
  personId?: string;
}

interface IErrorState {
  nameAlreadyExists: boolean;
  nameRequired: boolean;
  firmsRequired: boolean;
  officesRequired: boolean;
  firmsErrorMessage: string;
  officesErrorMessage: string;
  lenErr?: boolean;
  practiceIndustriesRequired?: boolean;
}

const initialErrorState: IErrorState = {
  nameAlreadyExists: false,
  nameRequired: false,
  firmsRequired: false,
  officesRequired: false,
  firmsErrorMessage: "",
  officesErrorMessage: "",
  lenErr: false,
  practiceIndustriesRequired: false,
};

const axios = new Axios();

const AddAOPRanking: React.FC<AddAOPRankingProps> = ({ mode, personId }) => {
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const { state } = useLocation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPerson, setSelectedPerson] = useState<IPerson | null>(null);
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [isModified, setIsModified] = useState(true);
  const [showSaveOverride, setShowSaveOverride] = useState(false);
  const [ranking, setRanking] = useState<IAOPRanking>({
    id: null,
    ranks: [null, null, null, null], // Inicializa ranks como un arreglo vacío
    person: {} as IPerson,
    firm: '',
  });
  const [firmName, setFirmName] = useState('');
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { logActivity } = useLogging();
  const {firmData} = UseFirmList()


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const state = location.state;
    setRanking(state);
  }, [location.state]);

  useEffect(() => {
    if (!state) return
      const currentFirm = firmData.find((f:any) =>f.id === state.firm)
      setFirmName(currentFirm?.firmName)
  }, [state]);

  const fetchPersons = async () => {
    try {
      const response = await axios.Get('/person');
      setPersons(response.data);
    } catch (error) {
      console.error('Error fetching persons:', error);
    }
  };

  useEffect(() => {
    fetchPersons();
    if (mode === 'edit' && personId) {
      fetchRankingData(personId);
    }
  }, [mode, personId]);

  const fetchRankingData = async (personId: string) => {
    try {
      const response = await getAOPRankingById(personId);
      setRanking(response);
      setSelectedPerson(persons.find(p => p.id === response.person.id) || null);
    } catch (error) {
      console.error('Error fetching ranking data:', error);
    }
  };

  const handleRankingChange = (index: number, value: any) => {
    setRanking(prev => {
      const newRanks = [...prev.ranks];
      newRanks[index] = value;
      return { ...prev, ranks: newRanks };
    });
  };

  const handleCloseSaveOverride = () => {
    setErrorState({ ...initialErrorState });
    setShowSaveOverride(false);
  };

  const handleSave = async () => {
    try {
      const payload: IAOPRanking = {
        ...ranking,
        person: ranking.person.id ? ranking.person : {} as IPerson,
        firm: ranking.person.firm,
      };
      const res = await updateAOPRanking(payload);
      if (res) {
        dispatch(modifyAOPRanking(payload));
        logActivity(mode === 'add' ? 'CREATE_AOP_RANKING' : 'UPDATE_AOP_RANKING', location.pathname, `${mode === 'add' ? JSON.stringify(res) : JSON.stringify({prevState: state, newState: res})}`);
      }
      navigate(-1);
    } catch (error) {
      console.error('Error saving ranking:', error);
    }
  };

  const handleDelete = async (id: string) => {
    const res = await deleteAOPRanking(id);
    if (res) {
      dispatch(resetAOPRanking());
      logActivity('DELETE_AOP_RANKING', location.pathname, JSON.stringify(state ? state : {}));
    }
    navigate(-1);
  };

  const isButtonDisabled = () => {
    return !isModified || Object.values(errorState).some(error => error);
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, position: number) => {
    event.dataTransfer.setData("text/plain", position.toString());
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>, newPosition: number) => {
    event.preventDefault();
    const draggedPosition = parseInt(event.dataTransfer.getData("text/plain"), 10);

    setRanking(prev => {
      const newRanks = [...prev.ranks];
      const temp = newRanks[draggedPosition];
      newRanks[draggedPosition] = newRanks[newPosition];
      newRanks[newPosition] = temp;
      return { ...prev, ranks: newRanks };
    });
  };

  return (
    <>
      <main className={`mainSection ${showSaveOverride && 'pt-7'}`}>
        {showSaveOverride && (
          <SaveOverride
            fields={errorFields}
            open={showSaveOverride}
            close={handleCloseSaveOverride}
            handleButtonClick={handleSave}
          />
        )}
        <CatalogTitle title={`${mode === 'add' ? 'New AOP Ranking' : 'Edit ' + (state ? state?.person?.name || '' : 'Loading...') + ' Ranking'}`} className="text-Default">
          <GoBack />
        </CatalogTitle>
        <section className="formsectionNTitle">
          <article className='articleSection'>
            <CatalogSubTitle className="text-accent-Default-dark" title="Catalog List Relationships" />
            <div className='flex flex-col items-start formsection md:flex-row lg:items-start self-stretch'>
              <Input
                title='Firm'
                error={false}
                value={`${firmName}`}
                disabled
              />
              <Input
                title='Person'
                error={false}
                value={`${state?.person?.name} ${state?.person?.lastName}`}
                disabled
              />
            </div>
            <div className='w-full self-stretch'>
              <h2 className="text-accent-Default-dark font-decimal text-[1.625rem] font-medium leading-normal self-stretch pb-[1.5rem]">AOP Ranking</h2>
              <div className='flex flex-col items-start gap-5 self-stretch'>
                <div className='flex flex-col items-start gap-[.625rem] self-stretch'>
                  <div className='flex items-center gap-4 self-stretch'>
                    <span className="text-lg leading-5 not-italic font-normal text-gray-font w-[2.75rem]">Rank</span>
                    <span className="text-lg leading-5 not-italic font-normal text-gray-font flex-1">Expertise</span>
                    <span className="text-lg leading-5 not-italic font-normal text-gray-font">Matches</span>
                  </div>
                  <div className='flex flex-col items-start gap-5 self-stretch'>
                    {ranking.ranks.map((rank, index) => (
                      <RankingItem
                        key={index}
                        rank={`rank_${index + 1}`}
                        index={index}
                        ranking={ranking}
                        isMobile={isMobile}
                        onDragStart={onDragStart}
                        onDrop={onDrop}
                        onChange={value => handleRankingChange(index, value)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-end self-stretch'>
              <ButtonsCrud Catalog="AreaOfPractice" mode={mode} disabled={isButtonDisabled()} id={id ? id : ''} actionButton={handleSave} hiddenDelete onDelete={handleDelete} />
            </div>
          </article>
        </section>
      </main>
    </>
  );
};

export default AddAOPRanking;
