
import React from 'react';
import css from './Banner.module.css'; 
import NewButton from '../../Atomos/Buttons/newButton';
interface BannerProps {
  title: string;
  description: string;
  buttonText: string;
  backgroundImage: string;
  isCompleteWidth: boolean;
  viewMode?: boolean;
  icon: React.ReactNode;
  isLeft?: boolean;
} 
const Banner: React.FC<BannerProps> = ({ title, description, buttonText, backgroundImage, isCompleteWidth, viewMode, icon, isLeft}) => {
    const bannerStyle = {
      backgroundImage: `url(${backgroundImage})`,
    };
  
    return <NewButton text={buttonText} size='large' content='textIcon' color='accent' icon={icon as React.ReactElement | undefined} />;
};
  
  export default Banner;
  
