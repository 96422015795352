import React, { FormEvent, ReactNode, useEffect, useRef, useState } from "react";
import ExcelModal from "../Atomos/Modals/ExcelModal";
import Sync from "../Atomos/Icons/Sync";
import Search from "../Atomos/Icons/Search";
import Plus2 from "../Atomos/Icons/Plus"; 
import Axios from "../../utils/axios";
import Excel from "../Atomos/Icons/Excel";
import AnimationLogo from "../Layout/LoadingAnimation/AnimationLogo";
import PaginationComponent from "../Atomos/Pagination/PaginationComponent";
import NewButton from "../Atomos/Buttons/newButton";
import Close from "../Atomos/Icons/Close";
import RadioButton from "../Atomos/Radio/RadioButton";
import { Link } from "react-router-dom";
import "../../assets/css/table/Table.css";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import VisibleColumns from "../Organismos/Table/VisibleColumns"; 
import useViewport from "../../hooks/useViewPort"; 
import '../../assets/css/CatalogTable.css' 
import CatalogTitle from "../Moleculas/Catalog Text/CatalogTitle";
import CounterTotalCatalog from "../Moleculas/Catalog Text/CounterTotalCatalog";

interface Region {
  name: string;
  id: string;
}

interface ICatalogTableProps {
  title?: string;
  catalogName?: string;
  headers: string[];
  pageSize: number;
  isPaginated: boolean;
  route: string;
  filter?: ReactNode;
  totalCount: number;
  table: ReactNode;
  isfiltered: boolean;
  data: any[];
  totalData?: any[];
  HandleFilter?: (field: string, value: string) => void;
  handlePageChange: (page: number, recordsPerPage: number) => void;
  onsubmit?: (e: FormEvent<HTMLFormElement>) => void;
  clearSelections?: () => void;
  shouldFormat?: boolean;
  hasTotalData?: boolean;
  GetTotalData?: (all: boolean) => Promise<any[]>;
  linkToAdd?: string;
  noNewCatalog?: boolean;
  noExcelExport?: boolean;
  practiceIndustries?: IPracticeIndustry[];
  showAddButton?: boolean;
  selectedTab?: number;
  catalogShortName?: string;
  noRedBox?: boolean;
  hideDefaultFilters?: boolean;

}

const CatalogTable: React.FC<ICatalogTableProps> = (props: ICatalogTableProps) => { 
  const {viewportWidth}= useViewport()

  function formatDate(value: string) {
    const formattedDate = new Date(Number(value) * 1000).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    return formattedDate;
  }

  const formatDateValue = (value: string) => {
    return new Date(Number(value) * 1000).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const handleRegions = async (regionData: Region[]) => {
    const regionIds: string[] = regionData.map(region => region.id);
    const regionNames: string[] = await Promise.all(regionIds.map(async (id) => {
      const foundRegion = props.data?.find(region => region.id === id);
      return foundRegion?.name ?? '';
    }));
    return regionNames.filter(Boolean).join(', ');
  };

  const safeValue = (value: any) => (value === undefined ? '' : value);

  const headerMappings: { [key: string]: (item: any) => any } = {
    _ts: (item: any) => formatDateValue(safeValue(item['_ts'])),
    regions: (item: any) => handleRegions(item['regions'] as Region[]),
    firms: (item: any) => (item['firms'] as { name: string }[]).map(firm => firm.name).join(', '),
    // region: (item: any) => handleRegions(item['region'] as Region[]),
    practiceIndustries: (item: any) => {
      const relatedPracticeIndustries = props.practiceIndustries?.filter(pi =>
        pi.areasOfPractice?.some(aop => aop.id === item.id)
      );
      return relatedPracticeIndustries?.map(pi => pi.name).join(', ');
    },
    person: (item: any) => {
      const name = safeValue(item['person']?.name);
      const lastName = safeValue(item['person']?.lastName);
      return [name, lastName].filter(Boolean).join(' ');
    },
    firm: (item: any) => {
      return (item['firm'])
    }
  };

  const axios = new Axios();
  const formRef = useRef<HTMLFormElement>(null);

  const [open, setOpen] = useState<boolean>(false); 
  const [totalData, setTotalData] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(props.pageSize);
  const [spinner, setSpinner] = useState(false);

  const GetTotalData = async () => {
    let datapassed: any[] = [];
    const fetchTotalCount = async () => {
      try {
        var queryString = `/${props.route}`;
        const response = await axios.Get(queryString);
        const data = response.data;
        if (data != null) {
          setTotalData(data);
          datapassed = data;
        }
      } catch (error) {
        console.error("Error al obtener la información de la columna:", error);
      }
    };

    await fetchTotalCount();
    return datapassed;
  };

  const handleFormReset = () => {
    if (formRef.current) {
      props.clearSelections?.();
      setTimeout(() => formRef.current?.reset(), 0);
    }
  }

  const submite = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.onsubmit) {
      props.onsubmit?.(e);
    }
  }


  const excelExport = async (jsonData: any[]) => {
    setSpinner(true);
    try {
      setOpen(false);
      const XLSX = require("xlsx");



      const headerMappingsCustom: { [key: string]: string } = {
        _ts: 'Created Date',
        professionals: '# Of Professionals',
        practiceIndustries: 'Practice Industries',
        isIgnored: 'Ignored',
      };
  
      const resolvedData = await Promise.all(jsonData.map(async (item) => {
      const rowData: { [key: string]: any } = {};

      for (const header of props.headers) {
        const modifiedHeader = headerMappingsCustom[header] || header;
        let value;

        if (header === '_ts') {
          value = formatDateValue(safeValue(item[header]));
        } else {
          value = headerMappings[header] ? headerMappings[header](item) : safeValue(item[header]);
        }
        rowData[modifiedHeader] = value;
      }
      return rowData;
    }));

  
    const ws = XLSX.utils.json_to_sheet(resolvedData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const year = currentDate.getFullYear();

    const formattedDate = `${month}_${day}_${year}`;
    const outputFile = `${props.route}_${formattedDate}.xlsx`;

    XLSX.writeFile(wb, outputFile, { bookSST: true, type: 'binary' });
  } catch (e:any) {
    console.error(e.message);
  } finally {
    setSpinner(false);
  }
  };
  

  const dataExcelExport = async (current: boolean) => {
    if (!current) {
      let dataToExport: any[] = [];

      if (!props.hasTotalData) dataToExport = await GetTotalData();
      else dataToExport = (await props.GetTotalData?.(true)) ?? [];
      dataToExport.forEach((item: any) => {
        if (item._ts) {
          item["Created Date"] = formatDate(item._ts);
        }
      });
      excelExport(dataToExport);
    } else {
      try {
        setSpinner(true);
        if (!props.hasTotalData) excelExport(props.data);
        else excelExport((await props.GetTotalData?.(false)) ?? []);
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(() => {
          setSpinner(false);
        }, 1000);
      }
    }
  };

  return (
    <>
      <ExcelModal open={open} setOpen={setOpen} dataExcelExport={dataExcelExport}/> 
      {spinner && <AnimationLogo />}
      {spinner && <AnimationLogo />}

      <section className=" CatalogTableMain  relative z-10"> 
        {props.title && <CatalogTitle title={props.title} className='text-Default'/>   }

        {/* make it a component */}
        <div className="flex flex-col md:flex-row h-auto w-full">
          <CounterTotalCatalog title={`${props.catalogName} (${props.totalCount} Total)`} className=" bg-accent-Default-dark text-neutral"></CounterTotalCatalog>
          <div className="flex lg:flex-row-reverse">
             
            <div className="flex w-full">
              {!props.noExcelExport && (
                <div className="flex-1">
                  <NewButton
                    text={viewportWidth < 1024 ? `Export` : `Export to Excel`}
                    color="excel"
                    size="large"
                    content="textIcon"
                    onClick={() => setOpen(true)}
                    icon={<Excel />}
                  />
                </div>
              )}

              {!props.noNewCatalog && (
                <Link
                  className="flex-1"
                  to={
                    props.linkToAdd
                      ? (props.linkToAdd as string)
                      : `/Staff/Catalogs/${props.route}/Create`
                  }
                >
                  <NewButton
                    text={viewportWidth < 1024 ? `New` : `New ${props.catalogShortName ? `${props.catalogShortName}` : `${props.catalogName}`}`}
                    color="accent"
                    size={"large"}
                    content="textIcon"
                    icon={<Plus2 />}
                  />
                </Link>
              )}
            </div>

            {!props.isfiltered && !props.noRedBox && (
              <VisibleColumns
                className={"right-0"}
                pageHeaders={props.headers}
              ></VisibleColumns>
            )}
          </div>
        </div> 
 

        <div className="CatalogFormNTable ">
          {props.isfiltered && (
            <div className=" formsection w-full items-center border border-gray-100 p-4 ">  
              <form ref={formRef} onSubmit={submite}   >
                <div className="flex justify-center items-end gap-5 self-stretch flex-wrap lg:justify-end">
                  {props.filter}
                  {!props.hideDefaultFilters && 
                  <div className="flex items-end justify-end col-span-2 gap-2 flex-1 md:flex-none w-full md:w-max">
                    <button type="submit" className=" flex-1 md:flex-none ">
                      <NewButton text="Search" className="!min-h-[3rem]" content="textIcon" icon={<Search />} color="accent" size="large" />
                    </button>
                    <div className="max-h-[3rem]">
                      <NewButton onClick={handleFormReset} text="Reset" className="" content="iconSquare" icon={<Sync />} style="ghost" color="neutral" size="large" />
                    </div>
                    <div className="max-h-[3rem]">
                      <VisibleColumns pageHeaders={props.headers} className={'right-0'} ></VisibleColumns>
                    </div>
                  </div>}
                </div>
              </form> 
            </div> 
          )}
        
          {props.table}
          {props.isPaginated && (
            <>
              <PaginationComponent
                recordsPerPage={recordsPerPage}
                currentPage={currentPage}
                onPageChange={props.handlePageChange}
                totalCount={props.totalCount}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default CatalogTable;
