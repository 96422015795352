import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Table } from '@tanstack/react-table';
import NewButton from '../../Atomos/Buttons/newButton';
import Gear from '../../Atomos/Icons/Gear';
import WSGCheckbox from '../../Atomos/Checkbox/WSGCheckBox';
import { addSpacesBetweenUppercase } from '../../../utils/String';

interface ColumnVisibilityControlProps {
  className?: string;
  table: Table<any>;
}

export const ColumnVisibilityControl: React.FC<ColumnVisibilityControlProps> = ({ table, className }) => {
  const [dropdown, setDropdown] = useState(false);
  const [visibilityState, setVisibilityState] = useState<Record<string, boolean>>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialVisibilityState = table.getAllLeafColumns().reduce((acc, column) => ({
      ...acc,
      [column.id]: column.getIsVisible(),
    }), {});

    setVisibilityState(initialVisibilityState);
  }, [table]);

  const handleToggle = () => setDropdown(!dropdown);

  const handleToggleVisibility = (columnId: string) => {
    setVisibilityState(prevState => ({
      ...prevState,
      [columnId]: !prevState[columnId]
    }));
    table.setColumnVisibility({
      ...visibilityState,
      [columnId]: !visibilityState[columnId]
    });
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdown(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, handleClickOutside]);

  return (
    <div className="relative flex text-left h-full items-center" ref={dropdownRef}>
      <div className='flex items-center justify-center h-full'>
        <NewButton
          onClick={handleToggle}
          icon={<Gear />}
          content="iconSquare"
          size="large"
          color="primary"
          style="filled"
          className="!w-full !border-1 !border-accentDefault"
        />
      </div>
      <div className={`absolute top-12 right-0 w-max ${className} ${dropdown ? `opacity-1 visible scale-[100%] duration-0` : `opacity-0 invisible scale-95 duration-200`} transform ease-out transition-opacity duration-200 transition-delay-200 z-10 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <span className="text-sm w-full text-justify font-medium block py-[0.625rem] pl-4 pr-3 lg:py-[0.81rem] lg:pl-5 lg:pr-4">Columns</span>
        {table && table.getAllLeafColumns().map(column => (
          (column.id !== 'index' && column.id !== 'actions') && (
            <label key={column.id}>
              <WSGCheckbox
                checked={visibilityState[column.id]}
                index={0}
                text={column.id === '_ts' ? 'Created Date' : addSpacesBetweenUppercase(column.id)}
                handleCheckboxChange={() => handleToggleVisibility(column.id)}
              />
            </label>
          )
        ))}
      </div>
    </div>
  );
};
