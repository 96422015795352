import React, { Fragment, useEffect, useState } from "react"; 
import JurisdictionMembership from "../../components/Catalogs/FirmContact/JurisdictionMembership";
import { Link, useLocation, useParams } from "react-router-dom";
import Axios from "../../utils/axios";
import PencilEdit from "../../components/Atomos/Icons/PencilEdit";
import NewButton from "../../components/Atomos/Buttons/newButton";
import SortArrow from "../../components/Atomos/Icons/SortArrow";
import GoBack from "../../components/Moleculas/GoBack";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import "../../assets/css/AddCatalogs.css";

interface Jurisdiction {
  id: string;
  name: string;
}

interface DataItem {
  id?: string;
  name?: string;
  shortId?: string;
  membershipType?: string;
  jurisdiction: string;
  jurisdictionGroup?: string;
  jurisdictionGroupId?: string;
}

const FirmContactView: React.FC = () => {
  const axios = new Axios();
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState<DataItem[] | null>(null);
  const [firm, setFirm] = useState<DataItem>();
  const [jurisdictions, setJurisdictions] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [contactJurisdictionsId, setContactJurisdictionsId] = useState<any[]>(
    []
  );

  const [jurisdictionsGroup, setJurisdictionsGroup] = useState<any[]>([]);
  const [jurisdictionsToPass, setJurisdictionsToPass] = useState<any[]>([]);
  const [sortedJurisdictions, setSortedJurisdictions] = useState<any[]>([]);

  useEffect(() => {
    const GetJurisdictions = async () => {
      try {
        const response = await axios.Get(`/jurisdiction`);
        const data = response.data;
        if (data != null) {
          setJurisdictions(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetJurisdictions();
  }, []);

  useEffect(() => {
    const fetchFirmData = async () => {
      try {
        const response = await axios.Get("/firm");
        const data = response.data;
        if (data != null) {
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFirmData();
  }, [state]);

  useEffect(() => {
    if (state.jurisdictions) {
      const updatedContactJurisdictions: any = state.jurisdictions.map(
        (jurisdiction: any) => jurisdiction.jurisdiction
      );
      setContactJurisdictionsId(updatedContactJurisdictions);
    }
  }, [state.jurisdictions]);

  useEffect(() => {
    const expectedFirm = data?.find((firm) => firm.id === state.firm);
    const newexpectedFirm = data?.find((firm) => firm.shortId === state.firm);
    setFirm(expectedFirm ? expectedFirm : newexpectedFirm);
    handleJurisdictions()
  }, [data]);

  useEffect(() => {
    
    const sortJurisdictions = (jurisdictionsToPass ?? [])
      .slice()
      .sort((a: any, b: any) => {
        const nameA =
          jurisdictions.find((jur: any) => jur.id === a.jurisdiction)?.name || "";
        const nameB =
          jurisdictions.find((jur: any) => jur.id === b.jurisdiction)?.name || "";
        if (sortOrder === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
    });
    
    setSortedJurisdictions(sortJurisdictions);
  }, [jurisdictionsToPass, sortOrder]);


  useEffect(() => {
    handleJurisdictions()
  }, [firm, jurisdictions]);
  
  const handleJurisdictions = () => {
    if (firm?.membershipType !== "Primary" && firm?.jurisdictionGroup) {
      const activeIds = jurisdictions
      .filter((jurisdiction) => jurisdiction.jurisdictionGroup !== "" && jurisdiction.jurisdictionGroup === firm?.jurisdictionGroup)
      .map((jurisdiction) => jurisdiction.id);

      setJurisdictionsGroup(activeIds)
      const missingIds = activeIds.filter(id => !contactJurisdictionsId.includes(id));
      const missingJurisdictions = missingIds.map(id => ({ jurisdiction: id, contacts: [] }));
        
      const updatedJurisdictions = [
        ...(state.jurisdictions || []).filter((jurisdiction:any) => activeIds.includes(jurisdiction.jurisdiction)),
        ...missingJurisdictions
      ];
      setJurisdictionsToPass(updatedJurisdictions);
    }
  };


  return (
    <>
      <div className="mainSection">
        <CatalogTitle
          title={`${firm ? firm?.name : ""}`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>
        <section className="FirmContactTable">
          <div className="flex h-auto w-full gap-10 md:gap-6 flex-col p-10 bg-white">
            <div className="flex lg:items-center w-full gap-1 bg-white flex-col justify-start md:flex-row">
              <span className="font-bold not-italic leading-6 text-[22px] text-gray-font">
                Firm Contact Structure:
              </span>
              <span className="font-normal not-italic leading-5 text-base md:text-lg text-gray-font">
                Contacts defined by Membership Locations
              </span>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-[1.25rem] md:justify-between md:items-center w-full md:gap-1 bg-white ">
              {/* Sorting by: element */}
              <div className="flex flex-row gap-3 items-center justify-start">
                <div>
                  <span className="text-Default not-italic leading-5 font-semibold text-lg">
                    Sort By
                  </span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="20"
                    viewBox="0 0 2 20"
                    fill="none"
                  >
                    <path d="M1 0V20" stroke="#EBEBEB" />
                  </svg>
                </div>

                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic leading-5 font-normal text-lg">
                    Membership
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrder === "desc" && "rotate-180"}`}
                  />
                </div>
              </div>

              <Link
                to={`/Staff/Catalogs/FirmContact/Edit/` + id}
                state={{
                  ...state,
                  jurisdictions: jurisdictionsToPass,
                  firmName: firm?.name,
                  firmid: firm?.id,
                  jurisdictiongroup: firm?.jurisdictionGroup,
                  jurisdictionsId: jurisdictionsGroup,
                  jurisdictionsObjects: contactJurisdictionsId,
                  firmMembershipType: firm?.membershipType,
                }}
              >
                <NewButton
                  text="Edit Contacts"
                  color="accent"
                  style="filled"
                  size="large"
                  content="textIcon"
                  icon={<PencilEdit />}
                />
              </Link>
            </div>
            {state?.level && (
              <div>
                <JurisdictionMembership
                  jurisdiction={
                    jurisdictions.find(
                      (jur: any) => jur.id === firm?.jurisdiction
                    )?.name
                  }
                  firm={firm?.id}
                  contacts={state.contacts}
                />
              </div>
            )}

            {!state?.level && (
              <>
                <div className="flex flex-col gap-5">
                  {sortedJurisdictions.map((jurisdiction: any) => (
                    <JurisdictionMembership
                      jurisdiction={
                        jurisdictions.find(
                          (jur: any) => jur.id === jurisdiction.jurisdiction
                        )?.name
                      }
                      contacts={jurisdiction.contacts}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default FirmContactView;
