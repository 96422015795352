import React, { MouseEventHandler } from "react";
import { useAppContext } from "../../../Context";
import "../../../assets/css/Text.css";
import NewTooltip from "../Tooltip/NewTooltip";
import "../../../assets/css/InputText.css";
import InputLabel from "./inputLabel";

interface InputProps {
  title: string;
  name?: string;
  errorMsj?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error: boolean;
  isWfull?: boolean;
  tooltip?: string;
  type?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  onClick?: () => void;
  className?: string;
}

const Input: React.FC<InputProps> = (props) => {
  const { mode } = useAppContext();

  return (
    <div
      className={`flex flex-col gap-[.625rem] self-stretch items-start w-full `}
    >
      <InputLabel
        name={props.name ?? ""}
        title={props.title}
        required={props.required}
        disabled={props.disabled}
        tooltip={props.tooltip}
      />
      <input
        name={props.name}
        id={props.name}
        value={typeof props.value === "string" && props.value.trim() !== "" ? props.value : ""}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoComplete={props.name}
        type={props.type ?? "text"}
        disabled={props.disabled}
        onClick={props.onClick}
        className={`   ${props.error ? "  !outline !outline-1 !outline-feedback-error !outline-offset-0 !border-none " : " "} ${props.className}`}
      />

      <span
        className={`flex flex-wrap items-center ${!props.error && "hidden"} text-sm font-medium text-feedback-error`}
      >
        {props.errorMsj}
      </span>
    </div>
  );
};

export default Input;
