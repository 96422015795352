import React, { useState } from "react";
import "./Tooltip.css"; // Estilo del tooltip

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, className }) => {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    if (open) return;
    setTimeout(() => {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }, 1000);
  };

  return (
    <div className={`${className} relative`} onMouseEnter={handleMouseEnter}>
      {children}
      {open && (
        <div
          className=" absolute top-0 right-0 transform -translate-x-1/2 
      text-xs h-1 self-stretch flex items-center justify-center
      duration-700 bg-gray-font text-neutral p-4 z-10 whitespace-nowrap"
        >
          <span>{text}</span>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
