


function Bar_chart_4_bars() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16" >
            <path d="M1.09766 14.0689V12.7486H14.9114V14.0689H1.09766ZM1.76432 11.6073V7.08846H3.75129V11.6073H1.76432ZM5.24476 11.6073V3.75513H7.23172V11.6073H5.24476ZM8.74186 11.6073V5.75513H10.7383V11.6073H8.74186ZM12.2484 11.6073V1.75513H14.2448V11.6073H12.2484Z"   />
        </svg>
    );
}

export default Bar_chart_4_bars;
