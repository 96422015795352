function Image(props: any) {
  return (
    <div className={props.className}>
      <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 96 96"  >
      <path d="M21.2308 81.9998C19.2103 81.9998 17.5 81.2998 16.1 79.8998C14.7 78.4998 14 76.7895 14 74.769V21.2308C14 19.2103 14.7 17.5 16.1 16.1C17.5 14.7 19.2103 14 21.2308 14H74.769C76.7895 14 78.4998 14.7 79.8998 16.1C81.2998 17.5 81.9998 19.2103 81.9998 21.2308V74.769C81.9998 76.7895 81.2998 78.4998 79.8998 79.8998C78.4998 81.2998 76.7895 81.9998 74.769 81.9998H21.2308ZM21.2308 75.9999H74.769C75.0767 75.9999 75.3588 75.8717 75.6153 75.6153C75.8717 75.3588 75.9999 75.0767 75.9999 74.769V21.2308C75.9999 20.9231 75.8717 20.641 75.6153 20.3845C75.3588 20.1281 75.0767 19.9999 74.769 19.9999H21.2308C20.9231 19.9999 20.641 20.1281 20.3845 20.3845C20.1281 20.641 19.9999 20.9231 19.9999 21.2308V74.769C19.9999 75.0767 20.1281 75.3588 20.3845 75.6153C20.641 75.8717 20.9231 75.9999 21.2308 75.9999ZM30.6155 66.9998H65.692C66.4151 66.9998 66.951 66.6716 67.2997 66.0152C67.6484 65.3588 67.5946 64.7229 67.1382 64.1075L57.5998 51.3308C57.2255 50.8487 56.7434 50.6077 56.1537 50.6077C55.564 50.6077 55.0819 50.8487 54.7075 51.3308L44.923 64.0768L38.3692 55.6769C37.9949 55.2205 37.5192 54.9923 36.9423 54.9923C36.3654 54.9923 35.8897 55.2333 35.5154 55.7154L29.2078 64.1075C28.7257 64.7229 28.6591 65.3588 29.0078 66.0152C29.3565 66.6716 29.8924 66.9998 30.6155 66.9998Z" />
      </svg>
    </div>
  );
}

export default Image;
