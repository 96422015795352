import { Table, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import React, { Dispatch, SetStateAction, useEffect } from "react";
import { ISorting } from "../../../interfaces/Components/ITable";
import DraggableTableHeader from "../../../components/Organismos/Table/THTransitionToTanSack";
import DragAlongCell from "../../../components/Organismos/Table/TDTransitionToTanSack";

type tablebase = {
  data: any;
  columnsDef: any;
  setTableInstance?: (table: Table<unknown>) => void;
  sorting?: ISorting;
  setSorting?: Dispatch<SetStateAction<ISorting>>;
};

const TableV2: React.FC<tablebase> = ({
  data,
  columnsDef,
  setTableInstance,
  sorting,
  setSorting,
}) => {
  const [columnOrder, setColumnOrder] = React.useState<string[]>(
    columnsDef.map((column: any) => column.accessorKey)
  );

 

  const [columnVisibility, setColumnVisibility] = React.useState({});

  const table = useReactTable({
    columns: columnsDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnOrder,
      columnVisibility,
    },
    columnResizeMode: "onChange",
    defaultColumn: {
      size: 200,
      minSize: 50,
      maxSize: 500,
    }, 
  });

  useEffect(() => {
    if (setTableInstance && table) {
      setTableInstance(table);
    }
  }, [columnVisibility]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors} 
    >
      <div className="table-responsive tabledata no-scrollbar overflow-x-scroll 	w-full">
        <table className="table min-w-full w-max">
          <thead className ="!bg-primary-dark">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-primary-dark">
                <SortableContext
                  items={columnOrder}
                  strategy={horizontalListSortingStrategy}
                > 
                  {headerGroup.headers.map((header) => ( 
                    <DraggableTableHeader
                      key={header.id}
                      header={header}
                      sorting={header.column.columnDef.enableSorting !== false ? sorting : undefined}
                      setSorting={   header.column.columnDef.enableSorting !== false ? setSorting : undefined }
                    />
                  ))}
                </SortableContext>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className=" even:bg-gray-100">
                {row.getVisibleCells().map((cell) => (
                  <SortableContext
                    key={cell.id}
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DragAlongCell key={cell.id} cell={cell} />
                  </SortableContext>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DndContext>
  );
};

export default TableV2;
