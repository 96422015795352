import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import IGlobalCrisis from "../../../interfaces/models/IGlobalCrisis";
import { useLogging } from "../../../Context/LoggingContext";

interface iCatalogProps {
  mode: "add" | "edit";
}
type Errors = { name: { error: boolean; message: string; }; }

const AddGlobalCrisis: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const { state } = useLocation();
  const [errors, setErrors] = useState<Errors>({ name: { error: false, message: '' } });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [globalCrisis, setGlobalCrisis] = useState<IGlobalCrisis>({ id: state?.id ?? '', name: state?.name ?? '' });
  const [buttonClicked, setButtonClicked] = useState('');
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const { logActivity } = useLogging();

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  useEffect(() => {
    if (globalCrisis.name?.trim() !== '') {
      setErrors({ ...errors, name: { error: false, message: '' } });
    }
  }, [globalCrisis]);

  const validation = async () => {

    const name = globalCrisis.name?.trim() === '' ? { error: true, message: 'Name is required' } : { error: false, message: '' };

    setErrors({ name: name });
    setError(name.error);
    return name.error;
  }

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/GlobalCrisis`, id);
    if (response.status === 200) {
      logActivity('DELETE_GLOBAL_CRISIS', location.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/GlobalCrisis')
    } else {
      console.error('Error al eliminar el elemento:', response.data);
    }
  }

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (buttonClicked === 'saveOverride') {
      if (props.mode === 'add') {
        handleSave(e);
      }
      else {
        handleUpdate(e);
      }
    }

    if (buttonClicked === 'updateButton') {
      handleUpdate(e);
    } else if (buttonClicked === 'saveButton') {
      handleSave(e);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {

    let newerror = false

    if (buttonClicked !== 'saveOverride') {
      newerror = await validation();
    }
    setError(newerror);
    if (!newerror) {
      try {
        const addData: IGlobalCrisis = globalCrisis;
        const response = await axios.Post("/GlobalCrisis", addData);
        if (response.status === 200) {
          if (response.data.error) {
            if (response.data.error.includes('Name')) {
              setErrors({ ...errors, name: { error: true, message: 'Name already exists' } });
            }
          }
          else {
            logActivity('CREATE_GLOBAL_CRISIS', location.pathname, JSON.stringify(response.data));
            setTimeout(() => {
              navigate('/Staff/Catalogs/GlobalCrisis')
            }, 500);
          }
        } else {
          console.error('Error creating element:', response.data);
        }
      } catch (error: any) {
        console.error('Error creating element:', error);
      }
    }
  };

  useEffect(() => {
    if (errors.name.error)
      setSaveOverrideOpen(true)
    else {
      setSaveOverrideOpen(false)
      if (error)
        setError(false)
    }
  }
    , [errors]);

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    let newerror = false

    if (buttonClicked !== 'saveOverride') {
      newerror = await validation();
    }

    if (globalCrisis?.id && !newerror) {
      const updatedData: IGlobalCrisis = globalCrisis;
      try {
        const response = await axios.Put("GlobalCrisis", updatedData);
        if (response.data.error) {
          if (response.data.error.includes('Name')) {
            setErrors({ ...errors, name: { error: true, message: 'Name already exists' } });
          }
        }
        else {
          logActivity('UPDATE_GLOBAL_CRISIS', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response.data) }));
          navigate("/Staff/Catalogs/GlobalCrisis");
        }
      } catch (error: any) {
        console.error("Error al actualizar el elemento:", error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (error) validation();
    setGlobalCrisis({ ...globalCrisis, [e.target.name]: e.target.value });
  }

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  }

  useEffect(() => {
    const errorsToSet = Object.keys(errors).filter((clave) => errors[clave as keyof typeof errors].error);
    console.log(errorsToSet);
    setErrorFields(errorsToSet);
  }, [errors]);

  return (
    <>
      <div className={`mainSection ${saveOverrideOpen ? 'pt-7 md:pt-9 ' : ''}`}>
        <form onSubmit={handleSubmit}>
          <SaveOverride fields={errorFields} handleButtonClick={handleButtonClick} open={saveOverrideOpen} close={handleCloseSaveOverride} />

          <CatalogTitle title={`${props.mode === 'add' ? 'New Global Crisis' : 'Edit ' + (state ? state.name || 'Edit none' : 'Loading...')}`} className="text-Default">
            <GoBack />
          </CatalogTitle>
          <article className='articleSection '>
            <section className='formsectionNTitle '>
              <CatalogSubTitle title="Global Crisis Information" className=" text-accent-Default-dark" />
              <div className="formsection flex w-full">
                <div className=" w-full">
                  <Input name="name" title="Name" value={globalCrisis.name} required={true} error={errors.name.error} errorMsj={errors.name.message} onChange={handleInputChange} />
                </div>
              </div>
              <ButtonsCrud Catalog="GlobalCrisis" mode={props.mode} id={id ? id : ''} disabled={errors.name.error} actionButton={handleButtonClick} onDelete={handleDelete} />
            </section>
          </article>
        </form>
      </div>
    </>
  );
}

export default AddGlobalCrisis;