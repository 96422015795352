import { useEffect, useState } from "react";
import SortAsending from "../components/Atomos/Icons/SortAsending";
import SortDecending from "../components/Atomos/Icons/SortDecending";
import SortDefault from "../components/Atomos/Icons/SortDefault";
import { table } from "console";

interface IThProps {
    sort: { field: string; order: string }[]; 
    HandlerSort: (field: string) => void;
      index: number;
      title: string;
      sorting: boolean;
  }

const useTh = (props: IThProps) => { 
    // Resize column
const [resizing, setResizing] = useState(false); 
const [currentTh, setCurrentTh] = useState<HTMLElement | null>(null);

const toogleSortIcon = (field: string) => {
  const sorted = props.sort.find(sortItem => sortItem.field === field);
  if (sorted?.field === field) {
    if (sorted.order === "asc") {
      return <SortAsending />;
    } else if (sorted.order === "desc") {
      return <SortDecending />;
    } else {
      return <SortDefault />;
    }
  }
}

const handleMouseDown = (e: any, th: any) => {
  const target = e.target as HTMLElement;
  const th1 = target.closest('th') as HTMLElement; 
  const body = document.body;
  body.style.cursor = 'col-resize';
  const boundingBox = th1.getBoundingClientRect();
  setResizing(true);
  console.log(th1);
  setCurrentTh(th1);
};

let lastMouseX = 0;

let counter = 1;
const handleMouseMove = (e: MouseEvent) => { 
  // declaramos un width minimo para nuestro th
  const MIN_TH_WIDTH = 50;
  // seleccionamos la tabla
  const table = document.querySelector('table') as HTMLElement;

  // Aqui vemos si estamos redimensionando y si tenemos un th seleccionado
  if (resizing && currentTh) { 
    // Obtenemos la nueva posición del mouse en la pantalla 
    const newMouseX = e.pageX;

    // Obtenemos la diferencia entre la posición anterior y la nueva
    const mouseDeltaX = newMouseX - lastMouseX; 
    
    // Obtenemos el ancho actual del th
    const newThWidth = e.pageX - currentTh.getBoundingClientRect().left;
    
    // Si el ancho es mayor al minimo permitido
    if (newThWidth > MIN_TH_WIDTH) {
      // Obtenemos el ancho del th y le sumamos la diferencia entre el ancho anterior y el nuevo
      const thWidthDifference = newThWidth - currentTh.offsetWidth; 
      // Obtenemos todos los th de la tabla
      const columns = Array.from(table.querySelectorAll('th'));
      // Obtenemos el indice del th que estamos redimensionando
      const columnIndex = columns.indexOf(currentTh as HTMLTableCellElement);
   
      // Obtenemos el ancho total de las columnas
      const totalColumnsWidth = columns.reduce((acc, th) => acc + th.offsetWidth, 0);
      
      // Calcula la proporción del ancho del th respecto al total de las columnas
      const proportion = thWidthDifference / totalColumnsWidth;

      // Aplica la diferencia proporcional al ancho de las otras columnas
      columns.forEach((th, index) => {
        // Si no es el th que estamos redimensionando
        if (index !== columnIndex) {
          // Obtenemos el ancho actual de la columna
          const newColumnWidth = th.offsetWidth - th.offsetWidth * proportion;
          // Aplicamos el nuevo ancho a la columna
          th.style.width = newColumnWidth + 'px';
        }
      }); 
      // Aplica el nuevo ancho al th que estamos redimensionando
      currentTh.style.minWidth = newThWidth + 'px';
      // Aplica el nuevo ancho a la tabla
      if(newThWidth >= MIN_TH_WIDTH)
      {
       // Obtenemos el ancho de la tabla y le sumamos la diferencia entre el ancho anterior y el nuevo
      const tableWidth = table.offsetWidth + thWidthDifference;
        // asignamos el ancho a la tabla
      table.style.width = tableWidth + 'px';
      }
    }

    lastMouseX = newMouseX; // Actualiza la posición X anterior para el próximo movimiento
  }
}


const handleMouseUp = () => {
  const body = document.body;
  body.style.cursor = ' default';
  setResizing(false);
  setCurrentTh(null);
};

useEffect(() => {
  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', handleMouseUp);

  return () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };
}, [resizing, currentTh]);  
 

//
const mouseoverHandler = (index: any) => {
  var table = document.getElementById("CatalogTable") as HTMLTableElement;

  for (var i = 0, row; row = table?.rows[i]; i++) {
    // Check if the row has enough cells before accessing them
    if (row.cells.length > index) {
      row.cells[index].classList.add('highlight');
    }
  }
};

const mouseoutHandler = (index: any) => {
  var table = document.getElementById("CatalogTable") as HTMLTableElement;

  for (var i = 0, row; row = table?.rows[i]; i++) {
    // Check if the row has enough cells before accessing them
    if (row.cells.length > index) {
      row.cells[index].classList.remove('highlight');
    }
  }
};

    return {  handleMouseDown, handleMouseMove, handleMouseUp, mouseoverHandler, mouseoutHandler, toogleSortIcon }
}
export default useTh;