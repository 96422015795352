import React from "react";

function Plus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
    >
      <path 
        d="M11.01 19.24v-6.25H4.76v-1.98h6.25V4.76h1.98v6.25h6.25v1.98h-6.25v6.25h-1.98z"
      ></path>
    </svg>
  );
}

export default Plus;
