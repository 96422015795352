import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetJurisdictions } from "../../redux/catalogs/states";
import { getJurisdictionList } from "../../Services/Jurisdiction";

const UseJurisdictionList = () => {
  const [isFetching, setIsFetching] = useState(true)

  const dispatch = useDispatch();
  const jurisdictionData = useSelector((state: Appstore) => state.jurisdictions)

  useEffect(() => {
    if (jurisdictionData.length) {
      setIsFetching(false)
      return
    }
    if (!isFetching) return

    getJurisdictionList()
    .then((resp) =>  {
      dispatch(SetJurisdictions(resp))
      if (!resp.length) console.error('No jurisdictions found. Please check the data source.')
    })
    .catch((error) => console.error(error))
    .finally(() => setIsFetching(false))
  }, [isFetching]);

  return {jurisdictionData, isFetching};
};

export {UseJurisdictionList}