import React from "react";

function XIcon() {
  return (
    <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48" >
      <path d="M36.487 4.5h6.62L28.65 21.019 45.656 43.5H32.344L21.909 29.869 9.984 43.5H3.356l15.46-17.672L2.513 4.5h13.65l9.421 12.46L36.487 4.5zm-2.325 35.044h3.666L14.166 8.25h-3.938l23.935 31.294z" ></path>
    </svg>
  );
}

export default XIcon;
