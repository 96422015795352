import Axios from "./axios";

export const formatDateToString = (date: Date): string => {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatTimeSpanToString = (value: string): string => {
  return new Date(Number(value) * 1000).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const getNameById = (field: any, data: any) => {
  if (!field) return [];
  return data
    .filter((d: any) => field.includes(d.id))
    .map((r: any) => r.name);
};

export const isUUID = (value: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}

const axios = new Axios();
export const updateData = async (
  endpoint: string, 
  fieldName: string, 
  beforeValue: any,
  fieldValue: any
) => {
  try {
    const params = new URLSearchParams();params.append('filter', JSON.stringify({[fieldName]: {value: beforeValue, comparisonType: 'EQUAL'}}))
    axios.Get(`/${endpoint}?${params.toString()}`).then((response) => {
      console.log(response)
      response.data.map((data: any) => {
        const updatedData = {
          ...data,
          [fieldName]: fieldValue
        }
        console.log(updatedData)
        axios.Put(endpoint, updatedData)
      })
    })
  } catch (error) {
    console.error(`Error updating ${endpoint}:`, error);
  }
}