import React, { useEffect } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../CatalogFilterButtons";
import Input from "../../Atomos/Inputs/Input";
import { set } from "date-fns";
import { getCountries } from "../../../Services/Country";
import { getRegions } from "../../../Services/Region";
import { Table } from "@tanstack/react-table";
import CatalogFilterButtonsV2 from "../CatalogFilterButtonsv2";

interface IStateFilterProps {
  tableInstance: Table<unknown>; 
  filters: {
    
    name: string;
    abbreviation: string;
    countries: Option[];
    regions: Option[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      name: string;
      abbreviation: string;
      countries: Option[];
      regions: Option[];
    }>
  >;

  clearSelections: () => void;
  headers: string[];
  Search: () => void;
}

const StateFilter: React.FC<IStateFilterProps> = ({
  filters,
  Search,
  setFilters,
  tableInstance, 
  clearSelections,
}) => {
  const [countryData, setCountryData] = React.useState<Option[]>([]);
  const [regionData, setRegionData] = React.useState<Option[]>([]);

  useEffect(() => {
    getCountries().then((data) => {
      setCountryData(
        data.map((country) => ({
          id: country?.id as string,
          name: country?.name as string,
        }))
      );
    });
    getRegions().then((data) => {
      setRegionData(
        data.map((region) => ({
          id: region?.id as string,
          name: region?.name as string,
        }))
      );
    });
  }, []);

  const handleSelectCountry = (selected: Option[]) => {
    setFilters({ ...filters, countries: selected as Option[] });
  };

  const handleSelectRegion = (selected: Option[]) => {
    setFilters({ ...filters, regions: selected as Option[] });
  };

  const handlerText = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <section className="flex justify-end formsection flex-col md:flex-row lg:flex-row w-full md:flex-wrap lg:flex-nowrap md:items-end ">
      <div className=" md:w-[72%] lg:w-5/12">
        <Input
          name="name"
          title="By Name:"
          value={filters.name}
          onChange={(e) => handlerText(e.target.name, e.target.value)}
          error={false}
          required={false}
        />
      </div>
      <div className="md:w-[24%] lg:max-w-[11%] ">
        <Input
          name="abbreviation"
          title="By Abbreviation:"
          value={filters.abbreviation}
          onChange={(e) => handlerText(e.target.name, e.target.value)}
          error={false}
          required={false}
        />
      </div>
      <div className="md:w-[48%] lg:w-5/12 ">
        <DDMultiple
          title="By Country:"
          objects={countryData}
          selectedObjst={filters.countries}
          seter={(e: any) => handleSelectCountry(e as Option[])}
        />
      </div>
      <div className="md:w-[48%] lg:w-5/12 ">
        <DDMultiple
          title="By Region:"
          objects={regionData}
          selectedObjst={filters.regions}
          seter={(e: any) => handleSelectRegion(e as Option[])}
        />
      </div>
      <div className="    ">
      <CatalogFilterButtonsV2
            onClickSearch={Search}
            handleFormReset={clearSelections} 
            tableInstance={tableInstance}
          />
      </div>
    </section>
  );
};

export default StateFilter;
