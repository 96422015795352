export interface ICloseSmallIcon {
  onClick?: () => void;
  className?: string;
}

export const CircleRadio = ({ ...props }: ICloseSmallIcon): JSX.Element => {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <rect x="1" y="1" width="21" height="21" rx="11"/>
      </svg>
    </div>
  );
};
