import React, { useEffect, useState } from "react";
import "../../../assets/css/Text.css";
import { useAppContext } from "../../../Context";

interface TextAreaProps {
  title: string;
  name?: string;
  errorMsj?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error: boolean;
  tooltip?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxChars?: number;
  characterCount?: boolean;
  height?: string;
}

const TextArea: React.FC<TextAreaProps> = (props) => {
  const [inputValue, setInputValue] = useState(props.value || "");
  const { mode } = useAppContext();

  useEffect(() => {
    setInputValue(props.value || "");
  }, [props.value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    if (props.maxChars && value.length > props.maxChars) {
      return;
    }

    setInputValue(value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const height = props.height || "h-[240px]";

  return (
    <div className={`flex flex-col gap-2.5 self-stretch items-start `}>
      <div className="flex justify-between w-full">
        {mode !== "member" ? (
          <label
            className={` inputLabel ${props.disabled ? " text-gray-200" : props.error ? "text-feedback-error" : !props.required ? "text-gray-font" : " text-primary"} `}
          >
            {props.title}
          </label>
        ) : (
          <label
            className={`inputLabel ${props.disabled ? " text-gray-200" : "text-gray-font"} `}
          >
            {props.title}{" "}
            <span
              className={`${props.required ? (props.disabled ? " text-gray-200" : " text-feedback-error") : "hidden"}`}
            >
              *
            </span>
          </label>
        )}
        {props.tooltip && (
          <div className="has-tooltip relative">
            <span
              className="tooltip text-[0.625rem] leading-3 bottom-6 right-0 h-5 min-w-max shadow-sm px-2 py-1 bg-gray-font text-neutral"
              style={{
                boxShadow:
                  "var(--elevation-2-box-shadow, 0px 0px 12px 0px rgba(44, 44, 44, 0.12))",
              }}
            >
              {props.tooltip}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.07082 10.4625C7.08193 9.56826 7.18615 8.90956 7.3835 8.48637C7.58084 8.06319 7.92783 7.65062 8.42445 7.24869C8.87227 6.87044 9.20862 6.50826 9.4335 6.16216C9.65838 5.81607 9.77082 5.44129 9.77082 5.03783C9.77082 4.57551 9.61828 4.19182 9.31322 3.88674C9.00814 3.58168 8.587 3.42914 8.04982 3.42914C7.50972 3.42914 7.08097 3.58204 6.76358 3.88784C6.44619 4.19363 6.21213 4.55257 6.0614 4.96466L4.32227 4.20668C4.59811 3.45691 5.05125 2.83577 5.68168 2.34326C6.31213 1.85075 7.10074 1.60449 8.04752 1.60449C9.21658 1.60449 10.1168 1.9337 10.7483 2.59213C11.3797 3.25054 11.6955 4.04669 11.6955 4.98059C11.6955 5.58929 11.58 6.13072 11.3491 6.60488C11.1182 7.07903 10.7496 7.54557 10.2433 8.00451C9.7177 8.48276 9.3962 8.86235 9.2788 9.14327C9.16141 9.4242 9.1003 9.86393 9.09547 10.4625H7.07082ZM8.04782 14.9306C7.66412 14.9306 7.33797 14.7963 7.06937 14.5277C6.80077 14.2591 6.66647 13.9339 6.66647 13.552C6.66647 13.1702 6.80052 12.8449 7.06862 12.5763C7.33672 12.3077 7.66353 12.1733 8.04907 12.1733C8.43459 12.1733 8.76165 12.3077 9.03025 12.5763C9.29885 12.8449 9.43315 13.1702 9.43315 13.552C9.43315 13.9339 9.29818 14.2591 9.02825 14.5277C8.75832 14.7963 8.4315 14.9306 8.04782 14.9306Z"
                fill="#2C2C2C"
              />
            </svg>
          </div>
        )}
      </div>
      <textarea
        name={props.name}
        value={inputValue}
        placeholder={props.placeholder}
        onChange={handleInputChange}
        disabled={props.disabled}
        className={`  ${height}
        ${props.error || (props.maxChars && inputValue.length >= props.maxChars) ? "!outline !outline-1 !outline-feedback-error" : " "}`}
      />

      <span
        className={`flex flex-wrap items-center ${!props.error && "hidden"} text-sm font-medium text-feedback-error`}
      >
        {props.errorMsj}
      </span>

      {props.characterCount && !props.maxChars && (
        <p className="w-fit flex ml-auto gray-font text-sm">
          Characters: {props.value?.length}
        </p>
      )}
      {props.characterCount && props.maxChars && (
        <p className="w-fit flex ml-auto gray-font text-sm">
          Characters: {inputValue.length}/{props.maxChars}
        </p>
      )}
    </div>
  );
};

export default TextArea;
