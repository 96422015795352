import React, { useContext, useEffect } from "react";
import css from './AccountInfo.module.css'
import AccountInfoProps from "../../interfaces/Components/IAccountInfo";
import SocialLinks from "../ViewMode/SocialLinks";
import ContactInfo from "../ViewMode/ContactInfo";

  const AccountInfo: React.FC<AccountInfoProps> = ({ imageSrc, title, subtitle1, subtitle2, titleColor, viewMode, organization, phoneNumber, userData, isMywsg }) => {

    return ( 
        <React.Fragment>
            <div
                className={`
                    w-max
                    ${!viewMode ? 'lg:w-full' : 'w-full'} h-auto flex 
                     ${isMywsg ? 'md:flex-col md:mb-[1.5rem] md:w-[50%] xl:w-[40%]' : 'md:flex-row '}
                    lg:flex-row
                    lg:gap-2 gap-2 lg:mb-0 md:mb-4 
                `}
                >
                <div
                    className={`${
                    !viewMode
                        ? 'relative w-[4.5rem] h-[4.5rem]'
                        : 'lg:w-20 lg:h-20 md:w-[4.5rem] md:h-[4.5rem] w-[4.5rem] h-[4.5rem]'
                    } rounded-full lg:flex p-[.1rem] align-middle justify-center flex border border-gray-100`}
                >
                    <div
                    className="relative rounded-full w-full h-full overflow-hidden"
                    >
                    <div
                        style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        overflow: "hidden",
                        }}
                    >
                        <img
                        src={imageSrc}
                        alt="ey"
                        className="w-full h-full object-cover object-center"
                        />
                    </div>
                    </div>
                </div>
                <div className=''>
                <h1
                    className={`text-[1.125rem] mb-1 ${organization ? 'text-[#1A4898]' : `text-primary-dark`} font-medium roboto_font text-left whitespace-normal lg:whitespace-nowrap`}
                >
                    {title}
                </h1>
                <p className='roboto_font text-left text-neutral-dark text-sm lg:whitespace-nowrap'>{subtitle1}</p>
                <p className='roboto_font text-left text-neutral-dark text-sm lg:whitespace-nowrap'>{subtitle2}</p>
                {
                    (userData && userData?.otherTitle && !subtitle1) &&
                    <React.Fragment>
                        <p className='roboto_font text-left text-neutral-dark text-sm lg:whitespace-nowrap'>{userData?.otherTitle}</p>
                    </React.Fragment>
                }
                {viewMode && userData &&
                <React.Fragment>
                    <SocialLinks user={userData} organization={organization}/>
                    <ContactInfo phone={phoneNumber} organization={organization}/>
                </React.Fragment>
                }
                </div>
                
            </div>
        </React.Fragment>
     );
}
 
export default AccountInfo;