import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import '../../../assets/css/AddCatalogs.css';
import AnimationLogo from "../../../components/Layout/LoadingAnimation/AnimationLogo";
import { IPracticeIndustry } from "../../../interfaces/models/IPracticeIndustry";
import GoBack from "../../../components/Moleculas/GoBack";
import Input from "../../../components/Atomos/Inputs/Input";
import FileInputButton from "../../../components/Atomos/FileInput/FileInputButton";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Warning from "../../../components/Atomos/Icons/Warning";
import Success from "../../../components/Atomos/Icons/Success";
import MoreCircle from "../../../components/Atomos/Icons/MoreCircle";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { useLogging } from "../../../Context/LoggingContext";
import { UsePracticeIndustryList } from "../../../hooks/Catalogs/usePracticeIndustry";
import SvgIcon from "../../../components/ViewMode/IconComponent";

interface DataItem {
  id?: string;
  name?: string;
  icon?: string;
  createdAt?: Date;
  areasOfPractice?: any[];
}
interface iCatalogProps {
  mode: "add" | "edit";
}
interface IErrorState {
  nameRequired: boolean;
  fileRequired: boolean;
  nameErrorMessage?: string;
  nameDuplicated: boolean,
  nameDuplicatedMessage?: string
}

const initialErrorState: IErrorState = {
  nameRequired: false,
  fileRequired: false,
  nameErrorMessage: '',
  nameDuplicatedMessage: '',
  nameDuplicated: false,
};

const AddPracticeIndustry: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();

  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams();
  const { mode } = props;
  const [buttonClicked, setButtonClicked] = useState('saveButton');
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [newPracticeIndustry, setNewPracticeIndustry] = useState<IPracticeIndustry>()
  const [triggerUpload, setTriggerUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('')
  const [errorImg, setErrorImg] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | string>()
  const [fileSelected, setFileSelected] = useState(false);
  const [fileStatus, setFileStatus] = useState('Please Select a File')
  const [practiceIndustries, setPracticeIndustries] = useState<any[]>([])
  const [previousImageUrl, setPreviousImageUrl] = useState('');
  const [showSaveOverride, setShowSaveOverride] = useState(false);
  const { logActivity } = useLogging();
  const location = useLocation()

  const { refetchPracticeIndustries } = UsePracticeIndustryList();
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const attemptSaveOrUpdate = async () => {
    const errors = {
      ...initialErrorState,
      nameRequired: name === '',
      fileRequired: !fileSelected
    };

    setErrorState(errors);

    const errorFields = Object.entries(errors)
      .filter(([key, value]) => value)
      .map(([key]) => {
        switch (key) {
          case "nameRequired":
            return "Name";
          case "fileRequired":
            return "Icon";
          default:
            return "Unknown Field";
        }
      });

    const isValid = Object.values(errors).every((error) => !error);

    if (!isValid) {
      setShowSaveOverride(true);
      setErrorFields(errorFields);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return
    } else {
      if (mode === 'add') {
        setButtonClicked('saveButton')
      }
      else if (mode === 'edit') {
        setButtonClicked('updateButton')
      }
      setButtonClicked('saveOverride')
      handleSubmit()
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.Get('./practiceIndustry');
        const data = response.data;
        if (data != null) {
          setPracticeIndustries(data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleImageUpload = async (url: string): Promise<void> => {
    return new Promise(async (resolve) => {
      if (url) {
        await uploadNewIcon(url);
        await setImageUrl(url);
        resolve();
      }
    });
  };

  const uploadNewIcon = async (url: string) => {
    try {
      if (newPracticeIndustry) {
        const response = await axios.Put(`/PracticeIndustry`, {
          id: newPracticeIndustry.id,
          name,
          icon: url,
          createdAt: newPracticeIndustry.createdAt,
          areasOfPractice: newPracticeIndustry.areasOfPractice,
        });
        if (response.data.id) {
          fetchAndStoreIcon(newPracticeIndustry.id, url)
          navigate('/Staff/Catalogs/PracticeIndustry');
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const fetchAndStoreIcon = async (practiceId: string, iconUrl: string) => {
    try {
      if (!iconUrl) return;
      const response = await fetch(iconUrl);

      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          localStorage.setItem(`practiceIndustryIcon_${practiceId}`, reader.result);
        } else {
          console.error('Conversion to Base64 did not yield a valid string.');
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading blob as Base64:', error);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error al descargar el ícono:', error);
    }
  };

  useEffect(() => {
    if (triggerUpload) {
      handleImageUpload(imageUrl);
    }
  }, [triggerUpload]);

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/PracticeIndustry`, id);
    if (response.status === 200) {
      logActivity('DELETE_PRACTICE_INDUSTRY', location.pathname, JSON.stringify(state ? state : {}));
      navigate('/Staff/Catalogs/PracticeIndustry')
    } else {
      console.error('Error al eliminar el elemento:', response.data);
    }
  }
  const handleButtonClick = async (buttonName: string) => {
    setButtonClicked(buttonName);
    handleSubmit()
  };

  const handleSubmit = async () => {
    if (buttonClicked === 'updateButton') {
      await handleUpdate();
    } else if (buttonClicked === 'saveButton') {
      await handleSave();
    }
  };

  const handleSave = useCallback(async () => {
    try {
      const addData: DataItem = {
        name: name,
        createdAt: new Date(),
        areasOfPractice: [],
      };
      const response = await axios.Post("/PracticeIndustry", addData);
      setName(response.data.name)
      setNewPracticeIndustry(response.data)
      if (response && response.data) {
        if (response.data.error) {
          const errors = {
            ...errorState,
            nameDuplicated: true,
            nameDuplicatedMessage: 'This value is already registered in the DB. Please input a different/new one.',
            fileRequired: true,
          }
          setErrorState(errors)
          const isValid = Object.values(errors).every((error) => !error)

          if (!isValid) {
            setTriggerUpload(false)
            setErrorImg(true)
            setSelectedFile('')
            setFileStatus('Please Select a File')
            return
          }
        } else {
          setTriggerUpload(true)
          logActivity('CREATE_PRACTICE_INDUSTRY', location.pathname, JSON.stringify(response.data))
          await handleImageUpload(imageUrl); //TODO rollback en caso de error
          await refetchPracticeIndustries()
        }
      }
    } catch (error: any) {
      console.error('Error creating element:', error);
    }
  }, [name, selectedFile]);

  const handleUpdate = useCallback(async () => {
    const updatedData: DataItem = {
      id,
      name: name,
      icon: imageUrl,
      createdAt: new Date(),
      areasOfPractice: state.areasOfPractice ? state.areasOfPractice : []
    };
    try {
      const response = await axios.Put("PracticeIndustry", updatedData);
      setName(response.data.name)
      setNewPracticeIndustry(response.data)
      if (response.data.error) {
        const errors = {
          ...errorState,
          nameDuplicated: true,
          nameDuplicatedMessage: 'This value is already registered in the DB. Please input a different/new one.'

        }
        setErrorState(errors)
        const isValid = Object.values(errors).every((error) => !error)
        if (!isValid) {
          return
        }
        setTriggerUpload(false)
        setTriggerUpload(false)
        setErrorImg(true)
        setSelectedFile('')
        setFileStatus('Please Select a File')
      }
      else {
        setTriggerUpload(true)
        logActivity('UPDATE_PRACTICE_INDUSTRY', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response.data) }))
        await handleImageUpload(imageUrl); //TODO rollback en caso de error
        await refetchPracticeIndustries()
      }
    } catch (error: any) {
      console.error("Error al actualizar el elemento:", error);
    }
  }, [id, name, selectedFile]);

  const inputOnchange = (e: any) => {
    setName(e.target.value)
    setErrorState({
      ...initialErrorState,
      nameRequired: false,
      nameErrorMessage: ''
    })
  }

  useLayoutEffect(() => {
    if (mode === 'edit') {
      if (state) {
        setPreviousImageUrl(state.icon || '');
        setName(state.name);
        if (state.icon) {
          setIcon(state.icon);
        }
      }
      setButtonClicked('updateButton')
    }
  }, [mode, practiceIndustries, id]);

  const handleFileSelected = (file: File) => {
    if (file.type !== 'image/svg+xml') {
      setErrorState(prevState => ({
        ...prevState,
        fileRequired: true,
      }));
      setFileStatus('Upload Failed');
      setSelectedFile(undefined);
      setFileSelected(false);
    } else {
      setErrorState(prevState => ({
        ...prevState,
        fileRequired: false,
      }));
      setFileStatus('Upload Successful');
      setSelectedFile(file);
      setFileSelected(true);
      setErrorImg(false);
    }
  };

  const handleFileRemoved = () => {
    setFileSelected(false);
  };

  const statusAttributes = () => {
    switch (fileStatus) {
      case 'Please Select a File':
        return { color: '#474F56', Icon: MoreCircle };
      case 'Upload Failed':
        return { color: '#BC3323', Icon: Warning };
      case 'Upload Successful':
        return { color: '#2AA042', Icon: Success };
      default:
        return { color: '#474F56', Icon: MoreCircle };
    }
  };

  const { color, Icon } = statusAttributes();
  const handleCloseSaveOverride = () => {
    setShowSaveOverride(false);
  };
  return (
    <>
      <main className={`mainSection ${showSaveOverride && 'pt-10'}`}>
        {triggerUpload &&
          <div className="absolute top-0 left-0">
            <AnimationLogo />
          </div>
        }
        {showSaveOverride && (
          <SaveOverride
            fields={errorFields}
            open={showSaveOverride}
            close={handleCloseSaveOverride}
            handleButtonClick={(buttonClicked) => {
              handleButtonClick(buttonClicked)
            }}
          />
        )}
        <CatalogTitle title={`${props.mode === 'add' ? 'New Practice Industry' : 'Edit ' + (state ? state.name || 'Edit none' : 'Loading...')}`} className="text-Default">
          <GoBack />
        </CatalogTitle>
        <article className='articleSection'>
          <section className="formsectionNTitle">
            <CatalogSubTitle className="text-accent-Default-dark" title=" Practice Industry Information" />
            <div className="flex flex-col gap-7 md:flex-row w-full">
              <div className="flex items-center flex-col gap-[.625rem] md:w-1/2">
                <Input
                  onChange={inputOnchange}
                  name="Name"
                  title="Name"
                  required
                  error={errorState.nameRequired}
                  errorMsj='Name is Required'
                  isWfull
                  value={name || ''}
                />
                <div className="w-full">
                  {errorState.nameDuplicated &&
                    <span className={`text-left flex flex-wrap items-center text-sm font-medium text-feedback-error`}>
                      {errorState.nameDuplicatedMessage}
                    </span>
                  }
                </div>
              </div>
              {mode === 'edit' && previousImageUrl && (
              <div className="flex flex-col w-12 h-12 lg:w-10 lg:h-10 items-center justify-center relative top-[60%] translate-y-[80%]">
                <SvgIcon practiceId={state?.id || ''} iconUrl={previousImageUrl || ''} key={state?.id ?? ''} />
                <span className="text-xs text-gray-font">Current</span>
              </div>
            )}
              <div className="flex flex-col items-center gap-4 md:gap-[.625rem] justify-start">
                <FileInputButton
                  onFileRemoved={handleFileRemoved}
                  required={true}
                  pIndustry={newPracticeIndustry?.id ? newPracticeIndustry?.id : null}
                  onImageUpload={handleImageUpload}
                  triggerUpload={triggerUpload}
                  triggerDelete={errorImg}
                  onFileSelected={handleFileSelected}
                  ownerId={newPracticeIndustry?.id ? newPracticeIndustry?.id : null}
                  imageCategory={name.trim()}
                />
                <div className="flex items-center gap-[.625rem] self-stretch ">
                  <span className='text-[.875rem] flex items-center justify-center'>
                    <div className={`w-6 h-6 fill-[${color}]`}>
                      <Icon />
                    </div>
                  </span>
                  <span className={`text-[${color}] text-[1rem] leading-[1.5rem] lg:text-[1.125rem] lg:leading-[2rem]`}>
                    {fileStatus}
                  </span>
                </div>
              </div>
            </div>
          </section>
          <ButtonsCrud Catalog="PracticeIndustry" mode={props.mode} disabled={errorState.nameRequired || errorState.fileRequired || errorState.nameDuplicated} id={id ? id : ''} /*disabled={disabled}*/ actionButton={attemptSaveOrUpdate} onDelete={handleDelete} />
        </article>
      </main>
    </>
  );
}
export default AddPracticeIndustry;