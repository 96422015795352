function SortDefault(props: any) {
    return ( 
            <svg
            width="100%" height="100%" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 48 48"
            > 
                    <path
                        d="M43.583 34.1673L33.0155 44.7065L22.4263 34.1173L25.2046 31.3107L31.035 37.1412L31.035 5.26513L35.0242 5.26513L35.0242 37.1412L40.8047 31.3607L43.583 34.1673ZM25.6307 13.8325L22.8524 16.6108L17.072 10.8304L17.072 42.7065L13.0828 42.7065L13.0828 10.8304L7.2241 16.6608L4.4458 13.8825L15.0632 3.26513L25.6307 13.8325Z"
                    
                    /> 
            </svg> 
    );
}

export default SortDefault;