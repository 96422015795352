import { Option } from "../components/Atomos/DropDown/DDMultiple";
import IContentTag from "../interfaces/models/IContentTag";
import Axios from "../utils/axios";

const axios = new Axios();

export const getContentGeneralTopics = async () => {
  try {
    const response = await axios.Get('/contentGeneralTopic');
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}

export const addContentGeneralTopics = async (addData: Option) => {
  try {
    const response = await axios.Post('/contentGeneralTopic', addData);
    return response.data;
  } catch (error) {
    console.error('Error fetching firms:', error);
    return [];
  }
}