import React, { useEffect, useState } from "react";
import { IPracticeIndustry } from "../../../interfaces/models/IPracticeIndustry";
import SvgIcon from "../../ViewMode/IconComponent";
import ChevronRight from "../../Atomos/Icons/ChevronRight";
import { IAreaOfPractice } from "../../../interfaces/models/IAreaOfPractice";


type IAopFirmProps = {
  practiceIndustries: IPracticeIndustry[],
  aops: IAreaOfPractice[],
}

const AopFirm: React.FC<IAopFirmProps> = ({ practiceIndustries, aops }) => {
  const [visibleCount, setVisibleCount] = useState(8);
  const [screenSize, setScreenSize] = useState('desktop');
  const [viewMoreActive, setViewMoreActive] = useState(false)
  const [AOPS, setAOPS] = useState<any[]>([]);


  function chunkArray(array: any[], size: number) {
    const chunks = [];
    for (let i = 0; i < array?.length; i += size) {
      chunks.push(array?.slice(i, i + size));
    }
    return chunks;
  }

  const toggleViewMore = () => {
    let total = 0
    if (viewMoreActive) {
      if (screenSize === 'mobile') {
        total = 4
      } else if (screenSize === 'tablet') {
        total = 8
      } else if (screenSize === 'desktop') {
        total = 8
      }

      setVisibleCount(total);
    } else {
      setVisibleCount(practiceIndustries?.length);
    }
    setViewMoreActive(!viewMoreActive);
  };

  useEffect(() => {
    if (aops) {
      setAOPS(chunkArray(aops?.slice(0, 30), 10).flat());
    }
  }, [aops]);




  const columns = screenSize === 'tablet' || screenSize === 'desktop' ? Math.ceil(AOPS?.length / 14) : 1;
  const columnItems = new Array(columns)?.fill([]).map((_, index) => AOPS?.slice(index * Math.ceil(AOPS?.length / columns), (index + 1) * Math.ceil(AOPS?.length / columns)));



  return (
    <React.Fragment>
      <div className="flex flex-col py-5 px-4 gap-[1.25rem] self-stretch items-end md:py-7 md:px-7 lg:py-10 lg:px-10 max-h-[53rem] overflow-y-scroll md:max-h-[45rem] lg:max-h-[45.5rem]">
        <div className="flex flex-col items-end gap-6 self-stretch">
          <div className="grid grid-cols-2 items-start gap-5 self-stretch
            md:grid-cols-4 md:flex-wrap md:gap-5
            lg:grid-cols-4 lg:grid">
            {practiceIndustries.slice(0, visibleCount).map((practice: any) =>
              <div key={practice.id} className={`flex lg:flex-1 flex-col items-center gap-[.5625rem]  h-auto self-stretch cursor-pointer`}
                onClick={() => ''}
              >
                <div className="flex w-10 h-10 lg:w-[3.75rem] lg:h-[3.75rem] justify-center">
                  <SvgIcon
                    iconUrl={practice?.icon} practiceId={practice.id} practiceName={practice.name} />
                </div>
                <span className="lg:whitespace-prewrap lg:w-10/12 lg:text-center text-[1rem] text-[#707070] leading-[1.125rem] text-center font-decimal font-medium lg:text-[1.25rem] lg:leading-[1.5rem]">
                  {practice.name}
                </span>
              </div>
            )}
          </div>
          {
            (screenSize === 'mobile' && practiceIndustries?.length > 4) ||
              ((screenSize === 'tablet' || screenSize === 'desktop') && practiceIndustries?.length > 8) ? (
              <div className="flex w-full p-0 justify-end items-center self-stretch cursor-pointer gap-[.625rem]" onClick={toggleViewMore}>
                <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">
                  {!viewMoreActive ? 'View More' : 'View Less'}
                </span>
                <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                  <div className={`w-3 h-3 fill-white duration-300 ${viewMoreActive ? 'rotate-180' : ''}`}>
                    <ChevronRight />
                  </div>
                </div>
              </div>
            ) : null
          }
          <div className="flex flex-col items-end gap-6 self-stretch">
            <div className="flex flex-col items-start gap-[.625rem] self-stretch md:gap-5">

              {screenSize === 'mobile' &&
                <div className="flex flex-col md:flex-row gap-[.625rem] justify-between self-stretch w-full flex-1">
                  {AOPS.map((aop: any) =>
                    <React.Fragment>
                      <span
                        className="col-span-1 text-[#474F56] text-base leading-6 md:w-1/10"
                      >
                        &bull; {aop.name}
                      </span>
                    </React.Fragment>)}
                </div>
              }
              {(screenSize === 'tablet' || screenSize === 'desktop') &&
                <div className={`flex flex-row self-stretch w-full gap-5 ${columnItems.length <= 2 ? 'justify-stretch' : 'justify-between'}`}>
                  {columnItems.map((column, columnIndex) => (
                    <div key={columnIndex} className="flex flex-col gap-[.625rem]">
                      {column.map((aop: any) => (
                        <span
                          key={aop.id}
                          className="text-[#474F56] text-base leading-6"
                        >
                          &bull; {aop.name}
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AopFirm;