import React from "react";
import { IPerson } from "../../../interfaces";
import ShortPersonCard from "../../../components/Moleculas/FirmProfile/ShortPersonCard";
import Disclosure from "../../../components/Moleculas/Disclosure/Disclosure";
import ContactFrame from "./ContactFrame";


interface ProfessionalsSectionProps {
  professionals: IPerson[];
}

const ProfessionalsSection: React.FC<ProfessionalsSectionProps> = ({ professionals }) => {
  const titles = ['Executive', 'Partners', 'Associates', 'Counsel'];

  return (
    <div className="flex px-7 py-7 gap-5 lg:py-11 lg:px-12 flex-col items-end lg:gap-[1.75rem] self-stretch">
      <div className="flex flex-col items-start gap-[1.75rem] md:gap-6 self-stretch">
        {titles.map(title => {
          const filteredProfessionals = professionals.filter(person => person.title === title);
          if (filteredProfessionals.length === 0) return null;
          return (
            <React.Fragment key={title}>
              <div className="flex items-center gap-4 self-stretch">
                <span className="font-decimal lg:text-lg lg:leading-5 font-medium text-Default">
                  {title}
                </span>
                <hr className="flex h-0 justify-center flex-1" />
              </div>
              <div className="grid w-full items-start gap-5 self-stretch">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-0 gap-5 self-stretch">
                  {filteredProfessionals.map((person: IPerson) => (
                    <ContactFrame key={person.id} person={person} />
                  ))}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const Professionals: React.FC<{ professionals: IPerson[] }> = ({ professionals }) => {
  const personCount = professionals.length;

  return (
    <Disclosure title="Professionals" isCardOpen={personCount > 0} onCardOpen={() => ''} color="secondary">
      <ProfessionalsSection professionals={professionals} />
    </Disclosure>
  );
};

export default Professionals;