function WSGCheck(props: any) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" >
            <path d="M1.2998 0.5H24.2998V23.5H1.2998V0.5Z" />
            <path d="M1.2998 0.5H24.2998V23.5H1.2998V0.5Z" />
        </svg>
    )
}

export default WSGCheck;