import React from "react";

function QuestionCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 8 8"
    > 
        <path 
          d="M3.535 5.231c.005-.447.058-.776.156-.988.099-.211.272-.418.52-.619.225-.189.393-.37.505-.543.113-.173.169-.36.169-.562a.78.78 0 00-.229-.576c-.152-.152-.363-.228-.632-.228-.27 0-.484.076-.643.229a1.412 1.412 0 00-.35.538l-.87-.379c.138-.375.364-.685.68-.931.315-.247.709-.37 1.182-.37.585 0 1.035.165 1.35.494.316.33.474.727.474 1.194 0 .305-.057.575-.173.812-.115.238-.3.47-.553.7-.263.24-.423.43-.482.57-.059.14-.09.36-.092.66H3.535zm.488 2.234a.666.666 0 01-.489-.201.664.664 0 01-.201-.488c0-.19.067-.354.2-.488a.666.666 0 01.491-.201c.193 0 .356.067.49.201a.665.665 0 01.202.488c0 .19-.067.354-.202.488a.67.67 0 01-.49.201z"
        ></path> 
    </svg>
  );
}

export default QuestionCircle;
