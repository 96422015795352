import React, { useEffect, useState } from "react";
import Axios from "../../../utils/axios";
import { useLocation } from "react-router-dom";
import ChevronRight from "../../../components/Atomos/Icons/ChevronRight";

const axios = new Axios();

interface ArticlesCardProps {
  articles: any[];
  viewAll?: boolean;
}

const ArticlesCard: React.FC<ArticlesCardProps> = ({ articles, viewAll }) => {
  const { state } = useLocation();
  const [showAll, setShowAll] = useState(false); // State to track if "View All" is clicked

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getArticleContentPreview = (content: any) => {
    const maxLength = 300;

    // Handle content if it's a string
    if (typeof content === "string") {
      return content.length > maxLength
        ? content.slice(0, maxLength) + "..."
        : content;
    }

    // Handle content if it's an array of rich text objects
    if (Array.isArray(content)) {
      const plainText = content
        .map((block) =>
          block.children.map((child: { text: any }) => child.text).join("")
        )
        .join("\n");
      return plainText.length > maxLength
        ? plainText.slice(0, maxLength) + "..."
        : plainText;
    }

    return "";
  };

  const displayedArticles = showAll ? articles : articles.slice(0, 3); // Show 3 articles or all based on showAll state

  return (
    <div className="flex flex-col lg:justify-end items-end lg:gap-[2.125rem] self-stretch">
      <div className="flex flex-col items-end gap-7 lg:items-start lg:gap-[1.5rem] self-stretch">
        {displayedArticles.map((article: any, index: number) => (
          <React.Fragment key={index}>
            <div className="flex items-start gap-2 self-stretch">
              <div className="flex flex-col items-start gap-5 flex-1">
                <div className="flex flex-col items-start self-stretch">
                  <span className="self-stretch text-[1.125rem] leading-normal text-primary lg:text-xl font-bold lg:leading-7">
                    {article?.title}
                  </span>
                  <div className="flex items-center gap-[.625rem]">
                    <span className="text-gray-font lg:text-base lg:leading-[1.125rem]">
                      {article?.writtenDate
                        ? formatDate(article?.writtenDate)
                        : "No Date Provided"}
                    </span>
                  </div>
                </div>
                <span className="self-stretch text-gray-font lg:text-lg lg:leading-6">
                  {getArticleContentPreview(article?.articleContent)}{" "}
                </span>
              </div>
            </div>
            {index < displayedArticles.length - 1 && (
              <hr className="self-stretch border-t border-gray-300 lg:my-4" />
            )}{" "}
          </React.Fragment>
        ))}
        {viewAll && (
          <div
            className="flex w-full p-0 justify-end items-center self-stretch cursor-pointer gap-[.625rem]"
            onClick={() => setShowAll(!showAll)} // Update showAll state when clicked
          >
            <span className="text-primary text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">
              {"View All " + state?.firmName + " Articles"}
            </span>
            <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-primary">
              <div className={`w-3 h-3 fill-white duration-300`}>
                <ChevronRight />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticlesCard;
