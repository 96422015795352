import React from "react";
import { useModeContext } from "../../hooks/useMode";
import AddUser from "../Atomos/Icons/ViewMode/AddUser";
import SendMail from "../Atomos/Icons/ViewMode/SendMail";
import Phone from "../Atomos/Icons/ViewMode/Phone";
import css from './SocialLinks.module.css'

interface ContactInfoProps {
    phone?: string;
    organization?: boolean;
}

const ContactInfo: React.FC<ContactInfoProps> = ({phone, organization}) => {
    const { isViewMode } = useModeContext()
    return ( 
        <React.Fragment>
            {(!organization && !isViewMode) && 
                <div className="gap-2 flex flex-col md:mt-3 mt-2">
                    <div className={`flex whitespace-nowrap items-center gap-2 roboto_font text-[#514F4F] text-sm cursor-pointer hover:text-primary ${css.addUserAnimation}`} >
                        <AddUser />
                        <p className="">Add to my network</p>
                    </div>
                    <div className={`flex whitespace-nowrap items-center gap-2 roboto_font text-[#514F4F] text-sm cursor-pointer hover:text-primary ${css.addUserAnimation}`} >
                        <SendMail />
                        <p className="">Send Email</p>
                    </div>
                    { 
                   phone && 
                    <div className={`flex whitespace-nowrap items-center gap-2 roboto_font text-[#514F4F] text-sm cursor-pointer hover:text-primary ${css.addUserAnimation}`} >
                        <Phone/>
                        <p className="">{phone}</p>
                    </div>
                    }
                </div>
                }
        </React.Fragment>
     );
}
 
export default ContactInfo;