// import React, { useEffect, useRef, useState } from "react";
// import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
// import CatalogFilterButtons from "../CatalogFilterButtons";
// import Input from "../../Atomos/Inputs/Input";

// interface IStateFilterProps {
//   stateData: any[];
//   countryData: any[];
//   regionData: any[];
//   cityData: any[];
//   filters: {
//     name: string;
//     states: Option[];
//     countries: Option[];
//     regions: Option[];
//     cities: Option[];
//   };
//   setFilters: React.Dispatch<
//     React.SetStateAction<{
//       name: string;
//       states: Option[];
//       countries: Option[];
//       regions: Option[];
//       cities: Option[];
//     }>
//   >;
//   clearSelections: () => void;
//   headers: string[];
//   Search: () => void;
// }

// const StateFilter: React.FC<IStateFilterProps> = ({
//   countryData,
//   regionData,
//   stateData,
//   cityData,
//   filters,
//   Search,
//   setFilters,
//   headers,
//   clearSelections,
// }) => {
//   const handleSelectCountry = (selected: Option[]) => {
//     setFilters({ ...filters, countries: selected });
//   };

//   const handleSelectRegion = (selected: Option[]) => {
//     setFilters({ ...filters, regions: selected });
//   };

//   const handleSelectState = (selected: Option[]) => {
//     setFilters({ ...filters, states: selected });
//   };

//   const handleSelectCity = (selected: Option[]) => {
//     setFilters({ ...filters, cities: selected });
//   };

//   const handlerText = (name: string, value: string) => {
//     setFilters({ ...filters, [name]: value });
//   };

//   return (
//     <div className="grid grid-cols-12 gap-5 w-full border border-gray-100 p-4">
//       <div className="col-span-12 md:col-span-6 lg:col-span-4 ">
//         <Input
//           name="name"
//           title="By Name:"
//           value={filters.name}
//           onChange={(e) => handlerText(e.target.name, e.target.value)}
//           error={false}
//           required={false}
//         />
//       </div>
//       <div className="col-span-12 md:col-span-6 lg:col-span-4">
//         <DDMultiple
//           title="By Region:"
//           objects={regionData}
//           selectedObjst={filters.regions}
//           seter={(e: any) => handleSelectRegion(e)}
//         />
//       </div>
//       <div className="col-span-12  md:col-span-6 lg:col-span-4">
//         <DDMultiple
//           title="By Country:"
//           objects={countryData}
//           selectedObjst={filters.countries}
//           seter={(e: any) => handleSelectCountry(e)}
//         />
//       </div>
//       <div className="col-span-12 md:col-span-6 lg:col-span-4">
//         <DDMultiple
//           title="By State:"
//           objects={stateData}
//           selectedObjst={filters.states}
//           seter={(e: any) => handleSelectState(e)}
//           disabled={filters.countries.length <= 0}
//           tooltip={
//             filters.countries.length <= 0
//               ? "Please select at least one country"
//               : ""
//           }
//         />
//       </div>
//       <div className="col-span-12 md:col-span-6 lg:col-span-4">
//         <DDMultiple
//           title="By City:"
//           objects={cityData}
//           selectedObjst={filters.cities}
//           seter={(e: any) => handleSelectCity(e)}
//           disabled={filters.states.length <= 0}
//           tooltip={
//             filters.states.length <= 0 ? "Please select at least one state" : ""
//           }
//         />
//       </div>
//       <div className="flex items-end justify-end col-span-12 md:col-span-6 lg:col-span-4">
//         <div className="w-full md:w-auto">
//           <CatalogFilterButtons
//             onClickSearch={Search}
//             handleFormReset={clearSelections}
//             headers={headers}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StateFilter;


import React, { useEffect, useRef, useState } from 'react';
import { InputTextRef } from '../../Atomos/Inputs/InputText';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';
import Input from '../../Atomos/Inputs/Input';


interface IAddressFilterProps {
  countryData: any[]
  stateData: any[]
  regionData: any[]
  cityData: any[]
  selectedCountries: Option[];
  setSelectedCountries: React.Dispatch<React.SetStateAction<Option[]>>;
  search: () => void;
  resetFilters: () => void;
  headers: any;
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  selectedRegions: Option[];
  setSelectedRegions: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedStates: Option[];
  setSelectedStates: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedCities: Option[];
  setSelectedCities: React.Dispatch<React.SetStateAction<Option[]>>;
  tableInstance: any;
}

const AddressFilter: React.FC<IAddressFilterProps> = ({ tableInstance, cityData, selectedCities, setSelectedCities, selectedRegions, selectedStates, setSelectedStates, stateData, countryData, regionData, setSelectedRegions, selectedCountries, setSelectedCountries, nameFilter, onNameFilterChange, search, resetFilters, headers }) => {

  return (
    <>
      <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:grid lg:grid-cols-3
      border border-gray-100'>
        <div className='w-full col-span-1'>
          <Input
            type="text"
            value={nameFilter}
            onChange={(e) => onNameFilterChange(e.target.value)}
            error={false}
            title='By Name'
          />
        </div>
        <div className="w-full col-span-1">
          <DDMultiple
            title="By Region:"
            objects={regionData}
            selectedObjst={selectedRegions}
            seter={setSelectedRegions}
          />
        </div>
        <div className="w-full col-span-1">
          <DDMultiple
            title="By Country:"
            objects={countryData}
            selectedObjst={selectedCountries}
            seter={setSelectedCountries}
          />
        </div>
        <div className="w-full col-span-1">
          <DDMultiple
            title="By State:"
            objects={stateData}
            selectedObjst={selectedStates}
            seter={setSelectedStates}
          />
        </div>
        <div className="w-full col-span-1">
          <DDMultiple
            title="By City:"
            objects={cityData}
            selectedObjst={selectedCities}
            seter={setSelectedCities}
          />
        </div>
        <div className="w-full md:w-auto col-span-1 md:flex md:justify-end">
          <CatalogFilterButtons
            onClickSearch={search}
            handleFormReset={resetFilters}
            headers={headers}
            tableInstance={tableInstance}
          />
        </div>
      </div>
    </>

  );
};

export default AddressFilter;
