

export interface ICancelIcon { }

export const Cancel = ({ ...props }: ICancelIcon): JSX.Element => {
  return (
    <svg
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.86281 16.8L12.4998 13.163L16.1367 16.8L17.2998 15.6369L13.6628 12L17.2998 8.36302L16.1367 7.19997L12.4998 10.8369L8.86281 7.19997L7.69976 8.36302L11.3367 12L7.69976 15.6369L8.86281 16.8ZM12.5017 22.3532C11.0772 22.3532 9.73551 22.0833 8.47656 21.5434C7.21763 21.0035 6.11783 20.2632 5.17718 19.3225C4.23655 18.3819 3.49628 17.2828 2.95636 16.0251C2.41644 14.7675 2.14648 13.4264 2.14648 12.0019C2.14648 10.5608 2.41644 9.21072 2.95636 7.95177C3.49628 6.69283 4.23582 5.59697 5.17498 4.66417C6.11413 3.73137 7.21288 2.9929 8.47121 2.44877C9.72954 1.90464 11.0714 1.63257 12.4966 1.63257C13.9386 1.63257 15.2896 1.90427 16.5496 2.44767C17.8097 2.99107 18.9058 3.72854 19.8379 4.66009C20.77 5.59164 21.5079 6.68708 22.0516 7.94639C22.5953 9.20571 22.8672 10.5569 22.8672 12C22.8672 13.4258 22.5951 14.768 22.051 16.0268C21.5068 17.2856 20.7684 18.3848 19.8356 19.3242C18.9028 20.2637 17.8075 21.0035 16.5499 21.5434C15.2923 22.0833 13.9429 22.3532 12.5017 22.3532ZM12.4998 20.3728C14.8287 20.3728 16.8065 19.5584 18.4329 17.9295C20.0594 16.3006 20.8726 14.3241 20.8726 12C20.8726 9.67099 20.0594 7.69327 18.4329 6.06682C16.8065 4.44037 14.8264 3.62714 12.4927 3.62714C10.1757 3.62714 8.20212 4.44037 6.57203 6.06682C4.94197 7.69327 4.12693 9.67334 4.12693 12.007C4.12693 14.3241 4.94137 16.2976 6.57023 17.9277C8.1991 19.5578 10.1756 20.3728 12.4998 20.3728Z"
      />
    </svg>
  );
};
