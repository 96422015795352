import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from '../../assets/images&icons/profileViewMode.jpeg';
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogSubTitle from "../../components/Moleculas/Catalog Text/CatalogSubTitle";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { getOffice } from "../../Services/Office";
import { ILanguage } from "../../interfaces";
import Axios from "../../utils/axios";
import { IBarAdmission } from "../../interfaces/models/IBarAdmission";
import { getFirmName, getMainOfficeName, getAdditionalOfficesNames, generateAOPS, generateLanguagesNames, generateBarAdmissionsNames } from '../../utils/activityLogUtils';
import IOffice from "../../interfaces/models/IOffice";

const axios = new Axios();

interface ParsedData {
  prevState: Record<string, any>;
  newState: Record<string, any>;
}

const ActivityLogDetails: React.FC = () => {
  const [parsedData, setParsedData] = useState<ParsedData | null>(null);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { state } = location as { state: { state: string; actionPerformed: string } };
  const { firmData } = UseFirmList();
  const [offices, setAllOffices] = useState<IOffice[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [barAdmissions, setBarAdmissions] = useState<IBarAdmission[]>([]);

  const fetchAllOffices = async () => {
    const offices = await getOffice();
    if (offices) setAllOffices(offices);
  };

  const fetchAllLanguages = async () => {
    const response = await axios.Get('/language');
    if (response?.data) setLanguages(response.data);
  };

  const fetchAllBarAdmissions = async () => {
    const response = await axios.Get('/barAdmission');
    if (response?.data) setBarAdmissions(response.data);
  };

  useEffect(() => {
    if (state?.state) {
      try {
        let dataString = state.state;
        while (typeof dataString === 'string') {
          dataString = JSON.parse(dataString);
        }
        setParsedData(dataString);
      } catch (error) {
        console.error("Failed to parse state:", error);
      }
    }
    fetchAllOffices();
    fetchAllLanguages();
    fetchAllBarAdmissions();
  }, [state]);

  const handleRemove = (option: Option) => {
    setParsedData((prevSelected) => {
      if (!prevSelected) return prevSelected;
      return {
        ...prevSelected,
        prevState: prevSelected.prevState.filter((item: any) => item.id !== option.id),
        newState: prevSelected.newState.filter((item: any) => item.id !== option.id),
      };
    });
  };

  const fieldsToExclude = ["_self", "_rid", "_etag", "_attachments", "id", 'externalType', 'mappedMainOffice', 'mappedMainOfficeAdditional', 'memberType'];
  const subFieldsToExclude = ["disable", "id", "title"];

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const formatValue = (key: string, value: any, isChanged: boolean) => {
    const formatters: Record<string, (val: any) => React.ReactNode> = {
      '_ts': formatTimestamp,
      'person': (val: any) => `${val.name} ${val.lastName}`,
      'photo': (val: any) => <img src={val} alt="profile" />,
      'firm': (val: any) => getFirmName(val, firmData),
      'areasOfPractice': (val: any) => generateAOPS(val),
      'mainOffice': (val: any) => getMainOfficeName(val, offices),
      'additionalOffices': (val: any) => getAdditionalOfficesNames(val, offices),
      'languages': (val: any) => generateLanguagesNames(val, languages),
      'barAdmissions': (val: any) => generateBarAdmissionsNames(val, barAdmissions),
      'ranks': (val: any) => val.map((item: any, index: number) => (
        <div key={index} className={`flex ${isChanged ? 'bg-opacity-50' : 'bg-[#F6F6F6]'}`}>
          {item.name}
        </div>
      )),
    };
  
    if (formatters[key]) {
      return formatters[key](value);
    }
  
    if (Array.isArray(value) && value.every(item => typeof item === 'object')) {
      return value.map((item, index) => (
        <div key={index} className={`p-2 ${isChanged ? 'pointer bg-opacity-50' : 'bg-[#F6F6F6]'}`}>
          {Object.entries(item)
            .filter(([subKey]) => !subFieldsToExclude.includes(subKey))
            .map(([subKey, subValue]) => (
              <div key={subKey}>
                {subValue?.toString()}
              </div>
            ))}
        </div>
      ));
    }
  
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
  
    return value?.toString();
  };
  
  const isCreateAction = state?.actionPerformed?.startsWith('CREATE_');
  const isUpdateAction = state?.actionPerformed?.startsWith('UPDATE_');
  const isDeleteAction = state?.actionPerformed?.startsWith('DELETE_');

  const renderField = (key: string, value: any, isChanged: boolean) => (
    <div className="flex flex-col gap-[.625rem]">
      <span className="text-xs">{(key === '_ts' || key === 'createdAt') ? 'Date' : key}</span>
      <div className={`flex py-2 pl-3 pr-4 items-center gap-[.625rem] min-h-[2.5rem] flex-1 ${isChanged ? 'bg-feedback-success bg-opacity-50' : 'bg-[#F6F6F6]'} ${isDeleteAction ? 'bg-gray-300' : ''} text-[1.125rem] font-normal`}>
        <span className={`relative text-sm text-neutral-dark flex gap-2 ${isDeleteAction ? '!text-gray-400 pointer-events-none' : ''}`}>
          {formatValue(key, value, isChanged)}
        </span>
      </div>
    </div>
  );

  const renderState = (stateData: Record<string, any>, otherStateData: Record<string, any>) => (
    Object.entries(stateData)
      .filter(([key]) => !fieldsToExclude.includes(key))
      .map(([key, value]) => {
        const otherValue = otherStateData[key];
        const isChanged = JSON.stringify(value) !== JSON.stringify(otherValue ?? {});
        return (
          <React.Fragment key={key}>
            {renderField(key, value, isChanged)}
          </React.Fragment>
        );
      })
  );

  return (
    <React.Fragment>
      <BackgroundComponent image={ProfileViewModeBg} />
      <main className='mainSection'>
        <div className='flex flex-col gap-5 lg:gap-6 lg:pb-[1.125rem] pb-5 relative'>
          <CatalogTitle title={`Catalogs Activity Log`} className='text-white !pb-0' />
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="HOME > SEO" currentPage={`Activity Log`} />
          </div>
        </div>
        <div>
          <article className='articleSection relative'>
            <section className="formsectionNTitle">
              <CatalogSubTitle className="text-accent-Default-dark" title="Log Details" />
            </section>
            {isCreateAction &&
              <div className="">
                <span className="text-gray-font text-[1.375rem] font-bold leading-6">
                  CREATED DATA:
                </span>
              </div>
            }
            {isUpdateAction &&
              <div className="">
                <span className="text-gray-font text-[1.375rem] font-bold leading-6">
                  UPDATED DATA:
                </span>
              </div>
            }
            {isDeleteAction &&
              <div className="">
                <span className="text-gray-font text-[1.375rem] font-bold leading-6">
                  DELETED DATA:
                </span>
              </div>
            }
            <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-5 self-stretch">
              {parsedData && isUpdateAction &&
                <>
                  <div className="flex flex-col gap-4">
                    <h3>Previous State</h3>
                    {parsedData.prevState && renderState(parsedData.prevState, parsedData.newState)}
                  </div>
                  <div className="flex flex-col gap-4">
                    <h3>New State</h3>
                    {parsedData.newState && renderState(parsedData.newState, parsedData.prevState)}
                  </div>
                </>
              }
              {parsedData && isCreateAction &&
                Object.entries(parsedData)
                  .filter(([key]) => !fieldsToExclude.includes(key))
                  .map(([key, value]) => (
                    <React.Fragment key={key}>
                      {renderField(key, value, false)}
                    </React.Fragment>
                  ))}
              {parsedData && isDeleteAction &&
                Object.entries(parsedData)
                  .filter(([key]) => !fieldsToExclude.includes(key))
                  .map(([key, value]) => (
                    <React.Fragment key={key}>
                      {renderField(key, value, false)}
                    </React.Fragment>
                  ))}
            </div>
          </article>
        </div>
      </main>
    </React.Fragment>
  );
}

export default ActivityLogDetails;
