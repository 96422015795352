import React from "react";

function Add() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M22.02 38.48v-12.5H9.52v-3.96h12.5V9.52h3.96v12.5h12.5v3.96h-12.5v12.5h-3.96z"
      ></path>
    </svg>
  );
}

export default Add;
