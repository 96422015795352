import React from "react";

function CrossArrows() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    > 
        <path d="M39.256 32.089l-2.121-2.121 4.419-4.42-12.976.036-.035-3.006 13.082-.07-4.49-4.49 2.12-2.122 8.097 8.097-8.096 8.096zM25.503 45.842l-3.006-.035V6.35l-4.49 4.49-2.121-2.12L23.982.622l8.097 8.096-2.122 2.121-4.454-4.454v39.456zm-6.046-20.4l-17.289.036v-3.041l17.289-.035v3.04z"></path>
        <path d="M8.744 15.86l2.122 2.122-4.42 4.42 12.976-.036.035 3.006-13.082.07 4.49 4.49-2.12 2.122-8.097-8.097 8.096-8.096zM22.497 2.109l3.006.035V41.6l4.49-4.49 2.121 2.12-8.096 8.097-8.097-8.096 2.122-2.122 4.454 4.455V2.108zm6.046 20.4l17.289-.036v3.041l-17.289.035v-3.04z"></path>
  
    </svg>
  );
}

export default CrossArrows;
