import React from "react";

function UserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 58 58" > 
        <path 
          d="M28.993 28.5c-2.893 0-5.257-.918-7.09-2.751-1.834-1.834-2.75-4.198-2.75-7.09 0-2.894.916-5.26 2.75-7.098 1.833-1.839 4.197-2.758 7.09-2.758s5.265.92 7.114 2.758c1.85 1.838 2.775 4.204 2.775 7.097 0 2.893-.925 5.257-2.775 7.09-1.85 1.834-4.22 2.751-7.114 2.751zM8.953 49.488V42.94c0-1.667.422-3.11 1.269-4.331a8.179 8.179 0 013.285-2.782c2.725-1.223 5.351-2.14 7.878-2.751a32.247 32.247 0 017.606-.917c2.587 0 5.132.316 7.634.947 2.503.631 5.104 1.542 7.803 2.733 1.402.609 2.528 1.524 3.378 2.745.85 1.221 1.276 2.67 1.276 4.347v6.558H8.952zm4.752-4.753h30.59v-1.667c0-.626-.19-1.222-.57-1.79-.38-.568-.85-.99-1.41-1.263-2.47-1.172-4.736-1.976-6.8-2.414-2.063-.437-4.24-.656-6.532-.656-2.246 0-4.432.219-6.558.656-2.126.438-4.388 1.24-6.785 2.41a3.348 3.348 0 00-1.395 1.263 3.303 3.303 0 00-.54 1.794v1.667zm15.288-20.99c1.474 0 2.693-.48 3.657-1.44.963-.96 1.445-2.178 1.445-3.654 0-1.485-.48-2.705-1.438-3.661-.96-.957-2.176-1.434-3.65-1.434-1.474 0-2.693.478-3.657 1.435-.963.957-1.445 2.172-1.445 3.644 0 1.48.48 2.703 1.438 3.666.96.963 2.176 1.444 3.65 1.444z"
        ></path> 
    </svg>
  );
}

export default UserIcon;
