import React from "react";

function Remove() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M6.665 10.625h6.667c.177 0 .325-.06.445-.18s.18-.268.18-.445a.604.604 0 00-.18-.445.605.605 0 00-.445-.18H6.665a.605.605 0 00-.445.18.605.605 0 00-.18.445c0 .178.06.326.18.446s.268.18.445.18zM10 17.917a7.713 7.713 0 01-3.088-.623 7.999 7.999 0 01-2.514-1.692 7.994 7.994 0 01-1.692-2.514 7.704 7.704 0 01-.624-3.086c0-1.095.208-2.125.623-3.088A7.997 7.997 0 014.397 4.4 7.993 7.993 0 016.91 2.707a7.705 7.705 0 013.087-.623c1.095 0 2.124.207 3.088.623.963.415 1.801.98 2.514 1.692a7.992 7.992 0 011.693 2.513c.415.963.623 1.992.623 3.087a7.713 7.713 0 01-.623 3.087A7.996 7.996 0 0115.6 15.6a7.995 7.995 0 01-2.513 1.693 7.705 7.705 0 01-3.087.624zM10 16.667c1.86 0 3.437-.646 4.729-1.938 1.292-1.291 1.937-2.868 1.937-4.729 0-1.861-.645-3.437-1.937-4.729-1.292-1.292-2.868-1.938-4.73-1.938-1.86 0-3.437.646-4.728 1.938-1.292 1.292-1.938 2.868-1.938 4.73 0 1.86.646 3.437 1.938 4.728 1.291 1.292 2.868 1.938 4.729 1.938z" ></path>
    </svg>
  );
}

export default Remove;
