import { axios } from "..";

export const getDataAdmin = async (): Promise<any[]> => {
    try {
        const countries = await axios.Get('./dataAdmin');
        const data = countries.data;
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
}