import React, { useEffect, useState } from "react";
import SwitchToogle from "../../Atomos/Switch-Toogle/Switch";
import NewButton from "../../Atomos/Buttons/newButton";
import DDMultiple from "../../Atomos/DropDown/DDMultiple";
import DropDown from "../../Atomos/DropDown/Dropdown";
import Jurisdiction from "../../../pages/Catalogs/Jurisdiction";

interface ContactProps {
  title: string;
  selectedObjst: any;
  getid?: boolean;
  seter?: any;
  error: boolean;
  errorMsj?: string;
  object?:any;
  type?:any;
  required?: boolean;
  contacts?: any;
  onApplyContact?: (jurisdictionIds: any, contactObject: any, type:any) => void;
  onApplyAllContact?: (contactObject: any, type:any) => void;
  jurisdictions?: any;
  jurisdictionId?: any;
}

const JurisdictionContact: React.FC<ContactProps> = (props) => {
  const [multipleMemberships, setMultipleMemberships] = useState(false);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<any[]>([]);

  useEffect(() => {
    setMultipleMemberships(false);
  }, [props.jurisdictionId]);

  useEffect(() => {
    if(!multipleMemberships)
    setSelectedJurisdictions([])
  }, [multipleMemberships]);

  const handleApplyContact = () => {
    if (props.onApplyContact) {
      props.onApplyContact(selectedJurisdictions, props.object, props.type); 
    }
  };

  const handleApplyAllContact = () => { 
    if (props.onApplyAllContact) {
      props.onApplyAllContact(props.object, props.type); 
    }
  };
  
  return (
    <div className="flex lg:flex-row flex-col justify-center items-center gap-5 w-full">
      <div className="w-full lg:w-[40%] flex gap-5">
        <div className="w-full">
          <DropDown
            title={props.title}
            required={props.required}
            selectedObjst={props.selectedObjst}
            seter={props.seter}
            data={props.contacts}
            error={props.error}
            errormsj={props.errorMsj ?? ""}
            getid={props.getid}
          />
        </div>
        <div className="text-center flex flex-col gap-2">
          <span className="text-gray-font text-center text-lg leading-5 not-italic font-normal">
            Multiple Memberships:
          </span>
          <SwitchToogle
            centered={true}
            seter={setMultipleMemberships}
            checked={multipleMemberships}
          />
        </div>
      </div>
      <div className="w-full flex lg:flex-row flex-col gap-5">
        <div className="lg:w-[60%]">
          <DDMultiple
            title="Assign Contact to"
            disabled={!multipleMemberships}
            selectedObjst={selectedJurisdictions}
            seter={setSelectedJurisdictions}
            objects={props.jurisdictions}
            error={false}
          />
        </div>

        <div className="lg:w-[15%] flex flex-col justify-end">
          <NewButton
            disabled={!multipleMemberships} 

            className="!max-h-[3rem]"
            text="APPLY"
            type="button"
            color="accent"
            style="outlined"
            size="large"
            content="text"
            onClick={handleApplyContact}
          />
        </div>
        <div className="lg:w-[25%]  flex flex-col justify-end">
          <NewButton
            disabled={!multipleMemberships}
            text={`APPLY TO ALL`}
            className="!max-h-[3rem]" 
            color="accent"
            style="filled"
            size="large"
            content="text"
            onClick={handleApplyAllContact}
          />
        </div>
      </div>
    </div>
  );
};

export default JurisdictionContact;
