

import { axios } from "..";

export const getMemberMainContact = async () => {
    try {
        const memberMainContact = await axios.Get('./officeContact');
        const data = memberMainContact.data;
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
  };