import { useState, useEffect, useRef } from 'react';
import Axios from '../utils/axios';
import { IPagination2, ISorting, IQueryParams, IFilters } from '../interfaces/Components/ITable';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../redux/loaderActions';
import { usePagination } from '../Context/PaginationContext';

interface DataLoaderProps {
  endpoint: string;
  defaultPageSize: number;
  fields?: string[];
}

interface DataLoaderResult<T> {
  data: T[];
  totalCount: number | undefined;
  fetchData: (resetFilters?:boolean, anotherFilter?:{[key:string]: IFilters } | undefined) => void;
  queryParams?: IQueryParams;
  sort: ISorting;
  setSort:React.Dispatch<React.SetStateAction<ISorting>> 
  pagination: IPagination2;
  setPagination: React.Dispatch<React.SetStateAction<IPagination2>>
  setFilter: React.Dispatch<any>;
}

const useDataLoader = <T extends unknown>({endpoint, defaultPageSize, fields}: DataLoaderProps): DataLoaderResult<T> => {
  const [data, setData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [filter, setFilter] = useState<{[key: string]: IFilters}>({});
  const [sort, setSort] = useState<ISorting>({ field: "_ts", order: "default" });
  const {pagination, setPagination, prevCatalog, setPrevCatalog, getCatalogFromEndpoint, restoreScrollPosition} = usePagination();
  const [filtersModified, setFiltersModified] = useState<boolean>(true)
  const [paginationDefault, setPaginationDefault] = useState<boolean>(true)
  const dispatch = useDispatch()
  const axios = new Axios();
  const isInitialMount = useRef(true);

  useEffect(()=> {
    setFiltersModified(true)
  },[filter])

  useEffect(() => {
    if (defaultPageSize && pagination && setPagination && paginationDefault) {
      setPaginationDefault(false);
      setPagination({ ...pagination, pageSize: defaultPageSize, pageIndex: 0 });
    }
  }, [defaultPageSize, pagination, setPagination, paginationDefault]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false; 
    } else {
      fetchData();
    }
  },[sort, pagination.pageIndex, pagination.pageSize ])

  useEffect(() => {
    const currentCatalog = getCatalogFromEndpoint(endpoint);
  
    if (prevCatalog !== currentCatalog) {
      setPaginationDefault(true);
    }
  
    setPrevCatalog(currentCatalog);  
  }, [endpoint]);

  useEffect(() => {
    restoreScrollPosition();
  }, [pagination.pageIndex]);
  
  const setQueryParams = (resetFilters:boolean = false, anotherFilter: any) => {
    const queryParams:IQueryParams = {
      fields: JSON.stringify(fields)
    }

    if (!resetFilters) {
      let filtersCleaned = cleanObject(filter)
      if (anotherFilter) filtersCleaned = {...filtersCleaned, ...anotherFilter}
      queryParams.filtering = filtersCleaned
    }

    if (resetFilters || filtersModified || anotherFilter) queryParams.pagination = {...pagination}
    else queryParams.pagination = pagination
    if (sort) queryParams.sorting = sort
    return queryParams
  }

  const fetchData = async (resetFilters:boolean = false, anotherFilter?:{[key:string]: IFilters} ) => {
    dispatch(showLoader());
    try {
      const queryParams = setQueryParams(resetFilters === true, anotherFilter)
      const dataUrl = buildUrl(endpoint, queryParams);
      let dataResponse, totalResponse;
      if (filtersModified || resetFilters || anotherFilter) {
        setPagination({...pagination})
        const totalUrl = `${dataUrl}&count=true`;
        
        [dataResponse, totalResponse] = await Promise.all([
          axios.Get(dataUrl),
          axios.Get(totalUrl)
        ]);
        setTotalCount(totalResponse.data);
      } else {
        dataResponse = await axios.Get(dataUrl);
      }
      setData(dataResponse.data);
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    } finally {
      dispatch(hideLoader());
      setFiltersModified(false)
    }
  };
  
  const buildUrl = (endpoint: string, queryParams: IQueryParams): string => {
    const params = new URLSearchParams();

    if (fields) params.append('fields', JSON.stringify(fields))
    if (queryParams?.filtering && Object.keys(queryParams.filtering).length) {
      params.append('filter', JSON.stringify(queryParams.filtering));
    }

    if (queryParams?.sorting?.order !== 'default' && queryParams?.sorting?.field !== '_ts') 
      params.append('sort', JSON.stringify(sort));
    if (queryParams.pagination) params.append('pagination', JSON.stringify(pagination));

    return `${endpoint}?${params.toString()}`;
  };

  const cleanObject = (params:any) => {
    const cleanedFiltering:any = {};

    for (const key in params) {
      const filter = params[key];
      if (filter.value !== "" && !(Array.isArray(filter.value) && filter.value.length === 0)) {
        cleanedFiltering[key] = filter;
      }
    }

    return cleanedFiltering
  }
  return { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    pagination, 
    setPagination, 
    sort, 
    setSort };
};

export default useDataLoader