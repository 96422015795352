import NewButton from "../Atomos/Buttons/newButton"
import VisibleColumns from "../Organismos/Table/VisibleColumns"
import Sync from "../Atomos/Icons/Sync"
import SearchIcon from "../Atomos/Icons/Search"
import { ColumnVisibilityControl } from "../Organismos/ColumnVisibilityControl/ColumnVisibilityControl"
import VisibleColumnsV2 from "../Organismos/Table/VisibleColumnsTransitionToTanSack"
import { Table } from "@tanstack/react-table"

type FilterButtonProps = {
  handleFormReset: () => void;
  onClickSearch: () => void;
  tableInstance: Table<unknown>; 
}

const CatalogFilterButtonsV2: React.FC<FilterButtonProps> = ({ handleFormReset,onClickSearch,tableInstance }) => {
  return (
    <div className="flex items-end justify-end gap-2 flex-1 md:min-h-9 md:flex-none w-full lg:max-w-[16.812rem]">
      <NewButton onClick={onClickSearch} text="Search" className="[&>span]:text-base [&>span]:leading-[1.125rem]  lg:[&>span]:text-lg lg:[&>span]:leading-5 h-full lg:!py-[.81rem] lg:!pr-[1rem] lg:!pl-[1.25rem]" content="textIcon" icon={<SearchIcon />} color="accent" size="filter" />
      <div className="  h-full ">
        <NewButton onClick={handleFormReset} text="Reset" className="[&>span]:fill-Default [&>span]:hover:fill-accent-Default-dark" content="iconSquare" icon={<Sync />} style="ghost" color="neutral" size="large" />
      </div>  
        <div className=" h-full">
          <VisibleColumnsV2   className={'right-0'}  tableInstance = {tableInstance}></VisibleColumnsV2>
        </div> 
    </div>
  )
}

export default CatalogFilterButtonsV2
