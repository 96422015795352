import React, { useEffect, useState } from "react";
import Axios from "../../utils/axios";
import AnimationLogo from "../../components/Layout/LoadingAnimation/AnimationLogo";
import useDataLoader from "../../hooks/useDataLoader";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { useTableSettings } from "../../hooks/Table/UseTableSettings";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import TableWithPagination from "../../components/Catalogs/Tables/TableWithPagination/TableWithPagination";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import ActivityLogFilter from "../../components/Catalogs/Filters/ActivityLog/ActivityLogFilter";
import { transformData } from "../../hooks/Table/TransformColumnsForExport";
import { Link } from "react-router-dom";
import Eye2 from "../../components/Atomos/Icons/Eye";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";

const ActivityLog: React.FC = ({ ...props }) => {
  const Headers: string[] = [
    "personId",
    "firm",
    "accessType",
    "action",
    "page",
    "_ts",
    "resourceUpdated",
  ];
  const axios = new Axios();
  const DefaultPageSize = 20;
  const {
    sort,
    setSort,
    currentPage,
    setCurrentPage,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    setFilter,
    queryParams,
  } = useTableSettings({ defaultPageSize: DefaultPageSize });
  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);
  const [allPersons, setAllPersons] = useState<any>();
  const [allUsers, setAllUsers] = useState<any>();
  const [nameFilter, setNameFilter] = useState("");
  const [accessFilter, setAccessFilter] = useState("");
  const [publishedDate, setPublishedDate] = useState<string>("");
  const { firmData } = UseFirmList();
  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);

  const { data, totalCount, isLoading } = useDataLoader<any>({
    endpoint: "/activityLog",
    queryParams,
    countOnly: true,
  });

  const getPersons = async (): Promise<void> => {
    try {
      const res = await axios.Get("/person");
      if (res && res.data) {
        setAllPersons(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPersons();
    getUsers();
  }, []);

  const getUsers = async (): Promise<void> => {
    const user = await axios.Get("/user");
    if (user && user.data) {
      setAllUsers(user.data);
    }
  };

  const filterAccessType = (id: string) => {
    if (allUsers && id) {
      return allUsers.filter((user: any) => user.id === id)[0];
    }
  };

  const filterPersonById = (id: string) => {
    const filteredPerson = allPersons?.filter(
      (person: any) => person.userId === id
    );

    if (!allPersons && !id) {
      return;
    }
    if (filteredPerson) {
      const personComposition = filteredPerson[0]?.displayName
        ? filteredPerson[0]?.displayName
        : `${filteredPerson[0]?.name} ${filteredPerson[0]?.lastName}`;
      return personComposition;
    }
  };

  const getUserName = (id: string) => {
    if(allUsers && id){
      const filteredUser = allUsers.find((user:any) => user.id === id)
      return filteredUser?.displayName ?? '' 
    }
  };

  const columnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false, size: 50 },
    { id: "personId", header: "User" },
    { id: "firm", header: "Firm" },
    { id: "accessType", header: "Access Type" },
    { id: "action", header: "Action" },
    { id: "page", header: "Page" },
    // { id: 'resourceUpdated', header: 'Updated Info' },
    {
      id: "_ts",
      header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return value
          ? new Date(Number(value) * 1000).toLocaleDateString("en-GB", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            })
          : "";
      },
    },
    {
      id: "details",
      header: "Details",
      enableSorting: false,
      cell: (props: any) => (
        <React.Fragment>
          <Link
            to={`details/${props.row.original.id}`}
            state={{
              state: JSON.parse(props.row.original.resourceUpdated),
              actionPerformed: props.row.original.action,
            }}
            className="flex gap-[.625rem] whitespace-nowrap items-center"
          >
            View Log Details
            <div className="editsvg">
              <Eye2 />
            </div>
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const columns = generateColumns(
    columnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          switch (column.id) {
            case "count":
              return (
                <p>
                  {props.row.index + 1 + (currentPage - 1) * recordsPerPage}
                </p>
              );
            case "personId":
              return <p>{filterPersonById(props.row.original.personId)}</p>;
            case "accessType":
              return (
                <p>{filterAccessType(props.row.original.personId)?.access}</p>
              );
            case "_ts":
              const value = props.getValue();
              return (
                <p>
                  {value
                    ? new Date(Number(value) * 1000).toLocaleDateString(
                        "en-GB",
                        {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        }
                      )
                    : ""}
                </p>
              );
            default:
              return <p>{props.getValue()}</p>;
          }
        }),
    }))
  );

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const handleAccessFilterChange = (newAccessFilter: string) => {
    setAccessFilter(newAccessFilter);
  };

  const transformedData = transformData(
    data,
    columnConfigs,
    currentPage,
    recordsPerPage,
    firmData
  );

  const clearSelections = () => {
    setFilter([]);
    setNameFilter("");
    setAccessFilter("");
    setPublishedDate("");
    setSelectedFirm([]);
    setCurrentPage(1);
  };

  const SubmitFilters = async () => {
    setFilter([
      { field: "access", value: accessFilter },
      { field: "name", value: nameFilter },
      { field: "publishedDate", value: publishedDate },
      { field: "firm", value: selectedFirm.map((f) => f.id).join(",") },
    ]);
  };

  return (
    <>
      <BackgroundComponent image={ProfileViewModeBg} />
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center -mt-24 z-10 relative">
          <div className="w-full h-full bg-white">
            <AnimationLogo />
          </div>
        </div>
      ) : (
        <>
          <main className="mainSection">
            <div className="flex flex-col gap-5 lg:gap-6 lg:pb-[1.125rem] pb-5">
              <CatalogTitle
                title={`Catalogs Activity Log`}
                className="text-white !pb-0"
              />
              <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
              <div className="flex w-full">
                <CurrentPage
                  prevPage="HOME > SEO"
                  currentPage={`Activity Log`}
                />
              </div>
            </div>
            <CatalogTableC
              noNewCatalog
              noRedBox
              catalogName={"Activity Log"}
              route={"activityLog"}
              isfiltered={true}
              data={data}
              headers={Headers}
              totalCount={totalCount}
              exportData={transformedData}
            >
              <ActivityLogFilter
                headers={Headers}
                resetFilters={clearSelections}
                search={SubmitFilters}
                tableInstance={tableInstance}
                nameFilter={nameFilter}
                onNameFilterChange={handleNameFilterChange}
                onAccessFilterChange={handleAccessFilterChange}
                publishedDate={publishedDate}
                setPublishedDate={setPublishedDate}
                firmData={firmData}
                selectedFirm={selectedFirm}
                setSelectedFirm={setSelectedFirm}
              />
              <TableWithPagination
                data={data}
                columns={columns}
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                recordsPerPage={recordsPerPage}
                setRecordsPerPage={setRecordsPerPage}
                setTableInstance={setTableInstance}
                sort={sort}
                setSort={setSort}
              />
            </CatalogTableC>
          </main>
        </>
      )}
    </>
  );
};

export default ActivityLog;
