import React, { useEffect, useLayoutEffect, useState } from "react";
import CatalogSubTitle from "../Catalog Text/CatalogSubTitle";
import DropDownList from "../../Atomos/DropDown/Dropdown";
import NewButton from "../../Atomos/Buttons/newButton";
import PlusIcon from "../../Atomos/Icons/PlusIcon";
import Modal from "../../Atomos/Modals/Modal";
import Input from "../../Atomos/Inputs/Input";
import CancelClear from "../../Atomos/Icons/CancelClear";
import Axios from "../../../utils/axios";
import RedPencil from "../../Atomos/Icons/RedPencil";
import DeleteIcon from "../../Atomos/Icons/DeleteIcon";
import { useLocation, useNavigate } from "react-router-dom";
import AnimationLogo from "../../Layout/LoadingAnimation/AnimationLogo";
import { useAppContext } from "../../../Context";
import { IPerson } from "../../../interfaces";
import { IEducation } from "../../../interfaces/models/IEducation";
const axios = new Axios();

type ProfileEducation = {
  person: IPerson;
}

const initialActiveErrors = {
  firmsRequired: false,
  personRequired: false,
  SchoolRequired: false,
  degreeRequired: false,
  startYearRequired: false,
  endYearRequired: false,
  notesRequired: false,
}

const initialModalErrors = {
  schoolRequired: false,
  degreeRequired: false,
}

const ProfileEducationEdit: React.FC<ProfileEducation> = ({ person }) => {
  const [activeModalErrors, setActiveModalErrors] = useState(initialModalErrors)
  const [addedSchool, setAddedSchool] = useState<any>('')
  const [addedDegree, setAddedDegree] = useState<any>('')
  const [selectedSchool, setSelectedSchool] = useState<any>('');
  const [selectedDegree, setSelectedDegree] = useState<any>('');
  const [schoolSelected, setSchoolSelected] = useState<any>()
  const [schools, setSchools] = useState<any[]>([]);
  const [activeErrors, setActiveErrors] = useState(initialActiveErrors);
  const [degree, setDegree] = useState<any[]>([])
  const [degreeSelected, setDegreeSelected] = useState<any>()
  const [newSchoolModal, setNewSchoolModal] = useState(false)
  const [newDegreeModal, setNewDegreeModal] = useState(false)
  const [newSchoolButtonText, setNewSchoolButtonText] = useState('');
  const [newDegreeButtonText, setNewDegreeButtonText] = useState('');
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(window.innerWidth < 768);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [duplicateError, setDuplicateError] = useState(false)
  const [firms, setFirms] = useState<any[]>([])
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [startYearError, setStartYearError] = useState(false);
  const [endYearError, setEndYearError] = useState(false);
  const [showSaveOverride, setShowSaveOverride] = useState(false);
  const [noteOne, setNoteOne] = useState('');
  const [noteTwo, setNoteTwo] = useState('');
  const [noteThree, setNoteThree] = useState('');
  const [noteFour, setNoteFour] = useState('');
  const [noteOneError, setNoteOneError] = useState(false);
  const [noteTwoError, setNoteTwoError] = useState(false);
  const [noteThreeError, setNoteThreeError] = useState(false);
  const [noteFourError, setNoteFourError] = useState(false);

  const [personFirm, setPersonFirm] = useState()
  const [editingEducation, setEditingEducation] = useState<IEducation | null>(null);
  const handleEditEducation = (education: IEducation) => {
    setEditingEducation(education);
    setSelectedSchool(education.school.name);
    setSchoolSelected(education.school);
    setSelectedDegree(education.degree.name);
    setDegreeSelected(education.degree);
    setStartYear(education.startYear);
    setEndYear(education.endYear);
    const notesArray = education.notes.split('\n');
    setNoteOne(notesArray[0] || '');
    setNoteTwo(notesArray[1] || '');
    setNoteThree(notesArray[2] || '');
    setNoteFour(notesArray[3] || '');
  };
  
  const clearForm = () => {
    setSelectedSchool('')
    setSelectedDegree('')
    setSchoolSelected('')
    setDegreeSelected('')
    setStartYear('');
    setEndYear('');
    setNoteOne('');
    setNoteTwo('');
    setNoteThree('');
    setNoteFour('');
  };
  const [filteredEducations, setFilteredEducations] = useState<IEducation[]>([]);
 const {mode} = useAppContext()

  const handleUpdateEducation = async (education: IEducation) => {
    try {
      const res = await axios.Post('/education', education)
      if (res && res.data) {
        const updatedEducations = res.data.filter((education: IEducation) => education.person.id === person.id);
        setFilteredEducations(updatedEducations);
      }
    } catch (error) {
      console.log(error)
    } finally {
      clearForm()
      fetchUpdatedEducation();
      setEditingEducation(null)
    }
  }

  const getPersonFirmData = async (person: IPerson) => {
    try {
      const response = await axios.Get('/firm?withWsg=true')
      if (response && response.data) {
        const filteredFirm = response.data.filter((firm: any) => firm.id === person.firm)
        setPersonFirm(filteredFirm)
      }
    } catch (error) {

    }
  }

  const collectAllFormFields = (): IEducation => {
    if (person) {
      getPersonFirmData(person)
    }
    return {
      person: person,
      firm: (person && personFirm) ? personFirm : null,
      school: schoolSelected,
      degree: degreeSelected,
      startYear,
      endYear,
      notes: [noteOne, noteTwo, noteThree, noteFour].join('\n'),
    };
  };

  const handleStartYearChange = (event: any) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setStartYear(value);
      setStartYearError(false);
    } else {
      setStartYearError(true);
    }
    setActiveErrors(prevState => ({ ...prevState, startYearRequired: false }));
    setShowSaveOverride(false)
  };

  const handleEndYearChange = (event: any) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEndYear(value);
      setEndYearError(false);
    } else {
      setEndYearError(true);
    }
    setActiveErrors(prevState => ({ ...prevState, endYearRequired: false }));
    setShowSaveOverride(false)
  };

  function transformDegreesData(degreesData: any[]) {
    return degreesData.map((degree: any) => ({
      id: degree.id,
      name: degree.name,
      title: degree.name,
      disable: false
    }));
  }

  function transformSchoolsData(schoolData: any[]) {
    return schoolData.map((school: any) => ({
      id: school.id,
      name: school.name,
      title: school.name,
      disable: false
    }));
  }
  useLayoutEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      setIsTabletOrMobile(width < 768);
      setIsDesktop(width > 1024);

      if (width < 768) {
        setNewSchoolButtonText('');
        setNewDegreeButtonText('');
      } else if (width < 1024) {
        setNewSchoolButtonText('NEW');
        setNewDegreeButtonText('NEW');
      } else {
        setNewSchoolButtonText('NEW SCHOOL');
        setNewDegreeButtonText('NEW DEGREE');
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);


  const handleSchoolSelect = (selectedItem: any) => {
    setSelectedSchool(selectedItem.name);
    setSchoolSelected(selectedItem)
    setActiveErrors(prevState => ({ ...prevState, SchoolRequired: !selectedItem }));
  };

  const handleDegreeSelect = (selectedItem: any) => {
    setSelectedDegree(selectedItem.name);
    setDegreeSelected(selectedItem)
    setActiveErrors(prevState => ({ ...prevState, degreeRequired: !selectedItem }));
  };

  const toggleNewSchoolModal = () => {
    setNewSchoolModal(!newSchoolModal)
  }

  const toggleNewDegreeModal = () => {
    setNewDegreeModal(!newDegreeModal)
  }

  const CancelIcon = () => (
    <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
      <CancelClear />
    </div>
  )

  const AddIcon = () => (
    <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
      <PlusIcon />
    </div>
  )

  const getSchools = async () => {
    try {
      const response = await axios.Get('/school');
      if (response && response.data) {
        setSchools(transformSchoolsData(response.data));
      }
    } catch (error) {
      console.error('Error fetching schools', error);
    }
  }

  const getDegrees = async () => {
    try {
      const response = await axios.Get('/degree');
      if (response.data)
        setDegree(transformDegreesData(response.data));
    } catch (error) {
      console.error('Error fetching degree', error);
    }
  };

  const getFirms = async () => {
    try {
      const response = await axios.Get('/firm');
      if (response.data)
        setFirms(response.data);
    } catch (error) {
      console.error('Error fetching firms', error);
    }
  };

  useEffect(() => {
    getFirms()
    getSchools()
    getDegrees()
  }, [])

  const addNewSchool = async () => {
    setDuplicateError(false)
    if (addedSchool.trim() === '') {
      const errors = {
        ...initialModalErrors,
        schoolRequired: true,
      }
      setActiveModalErrors(errors)
      return
    }
    await addSchool(addedSchool)
  }

  const addSchool = async (name: string) => {
    try {
      let newSchoolData = {
        name: name
      }
      const response = await axios.Post('/school', newSchoolData)
      const responseError = response.data.error
      if (responseError && responseError === 'Name already exists') {
        const errors = {
          ...initialModalErrors,
          schoolRequired: true,
        }
        setDuplicateError(true)
        setActiveModalErrors(errors)
        return
      }

      else {
        setTimeout(() => {
          toggleNewSchoolModal()
          getSchools()
          setAddedSchool('')
          setDuplicateError(false)
        }, 200)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const addDegree = async (name: string) => {
    try {
      let newDegreeData = {
        name: name
      }
      const response = await axios.Post('/degree', newDegreeData)
      const responseError = response.data.error
      if (responseError && responseError === 'Name already exists') {
        const errors = {
          ...initialModalErrors,
          degreeRequired: true,
        }
        setDuplicateError(true)
        setActiveModalErrors(errors)
        return
      }

      else {
        setTimeout(() => {
          toggleNewDegreeModal()
          getDegrees()
          setAddedDegree('')
          setDuplicateError(false)
        }, 200)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addNewDegree = async () => {
    setDuplicateError(false)
    if (addedDegree.trim() === '') {
      const errors = {
        ...initialModalErrors,
        degreeRequired: true,
      }
      setActiveModalErrors(errors)
      return
    }
    await addDegree(addedDegree)
  }


  const handleNewSchoolName = (e: any) => {
    setAddedSchool(e.target.value);
    if (activeModalErrors.schoolRequired && e.target.value.trim() !== '') {
      setActiveModalErrors(prev => ({ ...prev, schoolRequired: false }));
    }
  };

  const handleNewDegreeName = (e: any) => {
    setAddedDegree(e.target.value);
    if (activeModalErrors.degreeRequired && e.target.value.trim() !== '') {
      setActiveModalErrors(prev => ({ ...prev, degreeRequired: false }));
    }
  };

  const handleNoteChange = (note: string, setNote: React.Dispatch<React.SetStateAction<string>>, setNoteError: React.Dispatch<React.SetStateAction<boolean>>) => (event: any) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setNote(value);
      setNoteError(false);
    } else {
      setNoteError(true);
    }
  };

  const characterCount = (note: string) => `${note.length}/50`;
  const handleNoteOneChange = handleNoteChange(noteOne, setNoteOne, setNoteOneError);
  const handleNoteTwoChange = handleNoteChange(noteTwo, setNoteTwo, setNoteTwoError);
  const handleNoteThreeChange = handleNoteChange(noteThree, setNoteThree, setNoteThreeError);
  const handleNoteFourChange = handleNoteChange(noteFour, setNoteFour, setNoteFourError);
  const [isLoading, setIsLoading] = useState(false)

  const handlePutEducation = async (education: IEducation) => {
    try {
      const res = await axios.Put('/education', education)
      if (res && res.data) {
        const updatedEducations = res.data.filter((education: IEducation) => education.person.id === person.id);
        setFilteredEducations(updatedEducations);
      }
    } catch (error) {
      console.log(error)
    } finally {
      clearForm()
      fetchUpdatedEducation();
      setEditingEducation(null)
    }
  }
  const handleFormSubmit = () => {
    const formData = collectAllFormFields();
    let hasError = false;

    setActiveErrors({
      ...initialActiveErrors
    });

    if (!schoolSelected) {
      setActiveErrors(prev => ({ ...prev, SchoolRequired: true }));
      hasError = true;
    }
    if (!degreeSelected) {
      setActiveErrors(prev => ({ ...prev, degreeRequired: true }));
      hasError = true;
    }
    if (!formData.startYear) {
      setActiveErrors(prev => ({ ...prev, startYearRequired: true }));
      hasError = true;
    }
    if (!formData.endYear) {
      setActiveErrors(prev => ({ ...prev, endYearRequired: true }));
      hasError = true;
    }

    
    if (!hasError) {
      if (editingEducation) {
        formData.id = editingEducation.id;
        handlePutEducation(formData)
        return
      }
      handleUpdateEducation(formData)
    } else {
      console.log(activeErrors)
    }
  };

  const fetchUpdatedEducation = async () => {
    try {
      const res = await axios.Get('/education');

      if (res && res.data && person) {
        const updatedEducations = res.data.filter((education: IEducation) => education.person.id === person.id);
        setFilteredEducations(updatedEducations);
      }
    } catch (error) {
      console.error('Failed to fetch education:', error);
    }
  };

  useEffect(() => {
    fetchUpdatedEducation();
  }, [])

  useEffect(() => {
    setFilteredEducations(filteredEducations)
  }, [filteredEducations, person]);

  const handleDeleteEducation = async (education: IEducation) => {
    try {
      if (education && education.id) {
        const res = await axios.Delete('/education', education.id)
      }
    } catch (error) {

    } finally {
      fetchUpdatedEducation();
    }
  }

  return (
    <>
      {newSchoolModal &&
        <Modal className="lg:min-w-[44rem] lg:p-10" titleColor="#0070CD" isModalOpen={newSchoolModal} title={'New School'} toggleModal={toggleNewSchoolModal} children={
          <>
            <div className="flex flex-col gap-6">
              <div>
                <Input isWfull title={'Name'} error={activeModalErrors.schoolRequired} errorMsj={`${duplicateError ? 'This value is already registered in the DB. Please input a different/new one.' : 'Name is required'}`} required={true} value={addedSchool} onChange={(e) => handleNewSchoolName(e)} />
              </div>
              <div className='flex md:grid md:grid-cols-2 lg:flex lg:grid-cols-none lg:flex-row justify-center flex-col-reverse gap-[1rem] md:gap-[1rem] lg:justify-end items-center'>
                <NewButton color="neutral" style="outlined" onClick={() => setNewSchoolModal(false)} content='textIcon' text="CANCEL" icon={<CancelIcon />} />
                <NewButton color="accent" style="filled" onClick={() => addNewSchool()} content='textIcon' text="ADD" icon={<AddIcon />} />
              </div>
            </div>
          </>
        } />
      }
      {newDegreeModal &&
        <Modal className="lg:min-w-[44rem] lg:p-10" titleColor="#0070CD" isModalOpen={newDegreeModal} title={'New Degree'} toggleModal={toggleNewDegreeModal} children={
          <>
            <div className="flex flex-col gap-6">
              <div>
                <Input required title={'Name'} error={activeModalErrors.degreeRequired} value={addedDegree} errorMsj={`${duplicateError ? 'This value is already registered in the DB. Please input a different/new one.' : 'Name is required'}`} onChange={(e: any) => handleNewDegreeName(e)} />
              </div>
              <div className='flex md:grid md:grid-cols-2 lg:flex lg:grid-cols-none lg:flex-row justify-center flex-col-reverse gap-[.625rem] md:gap-[1rem] lg:justify-end'>
                <NewButton color="neutral" style="outlined" onClick={() => setNewDegreeModal(false)} content='textIcon' text="CANCEL" icon={<CancelIcon />} />
                <NewButton color="accent" style="filled" onClick={() => addNewDegree()} content='textIcon' text="ADD" icon={<AddIcon />} />
              </div>
            </div>
          </>
        } />
      }
      {isLoading ? (
        <div className='w-full h-screen flex items-center justify-center -mt-24 z-20'>
          <div className='w-full h-full bg-white'>
            <AnimationLogo />
          </div>
        </div>
      ) :
        <section className=' formsectionNTitle w-full'>
          <CatalogSubTitle title={'Education'} className={`${mode === 'staff' ? 'text-Default' : 'text-accentDefault'}`} />
          <div className="flex flex-col gap-5 md:flex-row  items-start lg:gap-[1.25rem] self-stretch">
            <div className="flex flex-col md:flex-1 items-end gap-[1.25rem] self-stretch">
              <div className="flex justify-end items-end gap-5 self-stretch">
                <DropDownList
                  title="School"
                  required={false}
                  getid={true}
                  data={schools}
                  selectedObjst={selectedSchool}
                  seter={handleSchoolSelect}
                  error={activeErrors.SchoolRequired}
                  errormsj=""
                />
                <div className={`flex h-auto ${activeErrors.SchoolRequired ? 'items-center' : 'items-end'}`}>
                  <NewButton onClick={toggleNewSchoolModal} text={newSchoolButtonText} content="textIcon" icon={<PlusIcon />}  color={`${mode === 'staff' ? 'accent' : 'primary'}`}/>
                </div>
              </div>
              <div className="flex justify-end items-end gap-5 self-stretch">
                <DropDownList
                  title="Degree"
                  required={false}
                  getid={true}
                  data={degree}
                  selectedObjst={selectedDegree}
                  seter={handleDegreeSelect}
                  error={activeErrors.degreeRequired}
                  errormsj=""
                />
                <div className={`flex h-auto ${activeErrors.degreeRequired ? 'items-center' : 'items-end'}`}>
                  <NewButton onClick={toggleNewDegreeModal} text={newDegreeButtonText} content="textIcon" icon={<PlusIcon />}  color={`${mode === 'staff' ? 'accent' : 'primary'}`}/>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row justify-end items-start gap-4 self-stretch">
                <Input
                  isWfull={true}
                  title={'Start Year'}
                  required={false}
                  error={startYearError || activeErrors.startYearRequired}
                  errorMsj={(startYearError || activeErrors.startYearRequired) ? "Please enter up to 4 digits." : ""}
                  name="startYear"
                  value={startYear}
                  onChange={handleStartYearChange}
                />
                <Input
                  isWfull={true}
                  title={'End Year (or expected)'}
                  required={false}
                  error={endYearError || activeErrors.endYearRequired}
                  errorMsj={(endYearError || activeErrors.endYearRequired) ? "Please enter up to 4 digits." : ""}
                  name="endYear"
                  value={endYear}
                  onChange={handleEndYearChange}
                />
              </div>
              <div className="flex flex-col items-end gap-4 self-stretch">
                <div className="w-full">
                  <Input
                    isWfull={true}
                    title={'Notables'}
                    required={false}
                    error={noteOneError}
                    errorMsj={noteOneError ? "Maximum characters exceeded (50)." : ""}
                    value={noteOne}
                    onChange={handleNoteOneChange}
                  />
                  <div className="flex justify-end">
                    <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteOne)}</span>
                  </div>
                </div>

                <div className="w-full">
                  <Input
                    isWfull={true}
                    title={''}
                    error={noteTwoError}
                    errorMsj={noteTwoError ? "Maximum characters exceeded (50)." : ""}
                    value={noteTwo}
                    onChange={handleNoteTwoChange}
                  />
                  <div className="flex justify-end">
                    <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteTwo)}</span>
                  </div>
                </div>

                <div className="w-full">
                  <Input
                    isWfull={true}
                    title={''}
                    error={noteThreeError}
                    errorMsj={noteThreeError ? "Maximum characters exceeded (50)." : ""}
                    value={noteThree}
                    onChange={handleNoteThreeChange}
                  />
                  <div className="flex justify-end">
                    <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteThree)}</span>
                  </div>
                </div>

                <div className="w-full">
                  <Input
                    isWfull={true}
                    title={''}
                    error={noteFourError}
                    errorMsj={noteFourError ? "Maximum characters exceeded (50)." : ""}
                    value={noteFour}
                    onChange={handleNoteFourChange}
                  />
                  <div className="flex justify-end">
                    <span className="flex text-base leading-[1.125rem] text-[#474F56] pt-[.625rem] px-2">{characterCount(noteFour)}</span>
                  </div>
                </div>
              </div>
              <div className="lg:max-w-[16rem] w-full md:max-w-[13.06rem]">
                <NewButton onClick={handleFormSubmit} size="medium" text="ADD EDUCATION" content="textIcon" icon={<PlusIcon />} color={`${mode === 'staff' ? 'accent' : 'primary'}`} className=""/>
              </div>
            </div>
            <hr className="flex h-0 md:h-auto md:w-0 flex-col justify-center items-center self-stretch border border-[#F6F6F6]" />
            <div className="flex flex-col items-end gap-[1.25rem] flex-1">
              {filteredEducations && filteredEducations.map((education: IEducation) => (
                <div className="flex flex-col items-end gap-4 self-stretch">
                  <div className="flex flex-col items-start gap-4 self-stretch">
                    <div className="flex items-start gap-4 self-stretch">
                      <div className="flex flex-col items-start gap-3">
                        <div className={`flex min-h-[2.25rem] p-[.46rem] justify-center items-center gap-2 rounded-full border border-solid ${mode === 'staff' ? 'border-Default bg-Default' : 'border-[#BC3323] bg-[#BC3323]'}  self-stretch cursor-pointer`} onClick={() => handleEditEducation(education)}>
                          <div className="flex w-5 h-5 justify-center items-center fill-white p-[0.15625rem] relative z-10">
                            <RedPencil />
                          </div>
                        </div>
                        <div className="flex min-h-[2.25rem] justify-center items-center gap-2 rounded-full border border-solid border-[#FFF3EB] bg-[#FFF3EB] self-stretch cursor-pointer" onClick={() => handleDeleteEducation(education)}>
                          <div className="flex w-5 h-5 justify-center items-center fill-[#BD4B00] relative z-10">
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-3 flex-1">
                        <span className="text-[1.125rem] font-semibold leading-[1.25rem] text-[#474F56]">{education?.school.name}</span>
                        <span className="text-[#474F56] text-lg leading-8">{education?.startYear} - {education?.endYear}</span>
                        <span className="text-[#474F56] text-lg leading-5 italic">{education?.degree.name}</span>
                      </div>
                    </div>
                  </div>
                  <span className="max-w-[32rem] whitespace-pre-wrap">
                    {education?.notes}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </section>
      }
    </>
  );
}

export default ProfileEducationEdit;