import React, { useEffect, useRef, useState } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../CatalogFilterButtons";


interface IOfficeContactsFilterProps {
  firmData: Option[];
  filters: {
    firms: Option[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      firms: Option[];
    }>
  >;
  clearSelections: () => void;
  headers: string[];
  Search: () => void;
} 

const OfficeContactsFilter: React.FC<IOfficeContactsFilterProps> = ({
  firmData,
  filters,
  Search,
  setFilters,
  headers,
  clearSelections,
}) => {

  const handleSelectFirm = (selected: Option[]) => {  
    setFilters({ ...filters, firms: selected });
  }

  return (
    <div className="formsection flex w-full flex-wrap md:flex-nowrap justify-end items-end border border-gray-100 p-4">
      <div className="w-full md:w-[90%]">
        <DDMultiple title="By Firm:" objects={firmData} selectedObjst={filters.firms} seter={(e:any)=>handleSelectFirm(e)}/>
      </div>
      <div className="flex items-end w-full lg:min-w-[250px] md:w-auto">
          <div className=" flex-1 flex lg:min-w-[250px]">
          <CatalogFilterButtons
            onClickSearch={Search}
            handleFormReset={clearSelections}
            headers={headers}
          />
        </div> 
      </div>
    </div>
  );
};

export default OfficeContactsFilter;
