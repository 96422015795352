import React, { useEffect, useState } from 'react';
import useDataLoader2 from '../../hooks/useDataLoader2';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import BaseFilter from '../../components/Catalogs/Filters/BaseFilter/BaseFilter';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import SvgIcon from '../../components/ViewMode/IconComponent';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { transformData } from '../../hooks/Table/TransformColumnsForExport';
import { IFilters } from '../../interfaces/Components/ITable';
import { formatTimeSpanToString } from '../../utils/functions';

const PracticeIndustry: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', 'icon', 'createdAt', '_ts'];
  const DefaultPageSize = 50;
  const [nameFilter, setNameFilter] = useState('');
  const [tableInstance, setTableInstance] = useState<any>(null);
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name', 
    'icon',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/practiceIndustry',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const clearSelections = () => {
    setNameFilter('');
    fetchData(true);
  };

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    { id: 'icon', header: 'Icon' },
    { id: '_ts', header: "Created Date" },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case 'icon':
          return <div className="flex w-8 h-8 lg:w-10 lg:h-10 items-center justify-center">
            <SvgIcon 
              practiceId={props.row.original.id} 
              practiceName={props.row.original.name} 
              iconUrl={data.find(p => p.id === props.row.original.id)?.icon} 
            />
          </div>
        case '_ts':
          const value = props.getValue();
          return (
            <p> {formatTimeSpanToString(value)} </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, null, null, 'PracticeIndustry');

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux:{[key: string]: IFilters} = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
    }
    setFilter(filterAux)
  },[nameFilter]);
  
  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title={`Practice Industry Catalog`}
          catalogName={'PRACTICE INDUSTRIES'}
          catalogShortName='Practice Industry'
          route={'PracticeIndustry'}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={transformedData}
        >
          <BaseFilter
            title='By Name'
            search={fetchData}
            onNameFilterChange={setNameFilter}
            nameFilter={nameFilter}
            resetFilters={clearSelections}
            headers={Headers}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default PracticeIndustry;