import { OnChangeFn, PaginationState, Table, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import '../../../assets/css/table/Pagination.css';
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import DraggableTableHeader from "./THTransitionToTanSack";
import DragAlongCell from "./TDTransitionToTanSack";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import { ISorting } from "../../../interfaces/Components/ITable";

type tablebase = {
  data: any;
  columnsDef: any;
  setTableInstance?: (table: Table<unknown>) => void;
  sorting?: ISorting;
  setSorting?: Dispatch<SetStateAction<ISorting>>;
  totalCount: number | undefined;
  pagination: PaginationState
  setPagination: OnChangeFn<PaginationState>;
};

const TableV2: React.FC<tablebase> = ({
  data,
  columnsDef,
  setTableInstance,
  sorting,
  setSorting,
  totalCount,
  pagination, 
  setPagination
}) => {
  const [columnOrder, setColumnOrder] = useState<string[]>(
    columnsDef.map((column: any) => column.accessorKey)
  );

  const [columnVisibility, setColumnVisibility] = useState({});
  const [disablePagination, setDisablePagination] = useState<{previousPage:boolean, nextPage:boolean}>({previousPage:true, nextPage:true})

  const table = useReactTable({
    columns: columnsDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnOrder,
      columnVisibility,
    },
    pageCount: totalCount ? Math.ceil(totalCount / pagination.pageSize) : 0,
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange",
  });

  useEffect(() => {
    if (setTableInstance && table) {
      setTableInstance(table);
    }
  }, [columnVisibility]);


  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const getVisiblePages = (currentPage: number, totalPages: number) => {
    const pages = [];
  
    // Always include the first page
    pages.push(1);
  
    // If total pages are 5 or less, return all pages (no ellipses needed)
    if (totalPages <= 3) {
      for (let i = 2; i <= totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  
    // Handle early pages (if the current page is near the start)
    if (currentPage <= 2) {
      pages.push(2);
      if (currentPage === 2) pages.push(3); // Show page 3 if currentPage is 2
      if (currentPage < totalPages - 2) {
        pages.push('...');
      }
      pages.push(totalPages);
      return pages;
    }
  
    // Handle pages in the middle
    if (currentPage > 2 && currentPage < totalPages - 1) {
      if (currentPage > 3) {
        pages.push('...');
      }
      pages.push(currentPage - 1);
      pages.push(currentPage);
      pages.push(currentPage + 1);
  
      if (currentPage + 2 < totalPages) {
        pages.push('...');
      }
      pages.push(totalPages);
      return pages;
    }
  
    // Handle the second-to-last page
    if (currentPage === totalPages - 1) {
      if (currentPage > 3) {
        pages.push('...');
      }
      pages.push(totalPages - 2); // Add the page before the second-to-last
      pages.push(totalPages - 1);
      pages.push(totalPages);
      return pages;
    }
  
    // Handle the last page
    if (currentPage === totalPages) {
      if (currentPage > 3) {
        pages.push('...');
      }
      pages.push(totalPages - 1);
      pages.push(totalPages);
      return pages;
    }
  
    return pages;
  };


  const visiblePages = getVisiblePages(pagination.pageIndex + 1, table.getPageCount())

  useEffect(() => {
    table.setPageSize(pagination.pageSize)
    setDisablePagination({
      previousPage: pagination.pageIndex <= 0,
      nextPage: pagination.pageIndex +1 >= table.getPageCount()
    })
  }, [pagination, table.getPageCount()]);

  const handleRowsPerPage = (e: any) => {
    table.setPageSize(Number(e.target.value))
    setPagination({...pagination, pageSize:(Number(e.target.value))})
  }

  const handlePage = (page: number) => {
    setPagination({...pagination, pageIndex: page})
  }

  const paginationHtml = () => {
    return (
      <div className="pagination">
      <label htmlFor="records-per-page" className="records-per-page">Rows per Page</label>
      <div className="relative flex justify-center items-center bg-gray-25">
        <select
          id="records-per-page"
          name="records-per-page"
          className="selectorPageSize"
          value={pagination.pageSize}
          onChange={handleRowsPerPage}
        >
          {[10, 20, 50, 100, 150, 200, 300].map(pageSize => (
            <option key={pageSize} value={pageSize} className="whitespace-nowrap">
              {pageSize}
            </option>
          ))}
        </select>
          <div className="absolute h-6 w-6 right-2 top-2 fill-gray-font">
            <ChevronDown />
          </div>
      </div>

      <button 
        className={`arrow ${disablePagination.previousPage && '!text-light-gray'}`}
        onClick={() => handlePage(pagination.pageIndex - 1)} 
        disabled={disablePagination.previousPage}
      >
        &lt;
      </button>

      {visiblePages.map((page, index) => (
        <button
          key={index}
          className={`paginationButton ${page === pagination.pageIndex + 1 ? 'currentButton bg-Default' : ''}`}
          onClick={() => typeof page === 'number' && handlePage(page - 1)}
          >
          {page}
        </button>
      ))}

      <button 
        className={`arrow ${disablePagination.nextPage && '!text-light-gray'}`}
        onClick={() => handlePage(pagination.pageIndex + 1)} 
        disabled={disablePagination.nextPage}
      >
        &gt;
      </button>
    </div>
    )
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      {paginationHtml()}
      <div className="table-responsive tabledata no-scrollbar overflow-x-scroll w-full">
        <table className="table min-w-full w-max">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <SortableContext
                  items={columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  {headerGroup.headers.map((header) => (
                    <DraggableTableHeader
                      key={header.id}
                      header={header}
                      sorting={header.column.columnDef.enableSorting !== false ? sorting : undefined}
                      setSorting={header.column.columnDef.enableSorting !== false ? setSorting : undefined}
                    />
                  ))}
                </SortableContext>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="even:bg-gray-100">
                {row.getVisibleCells().map((cell) => (
                  <SortableContext
                    key={cell.id}
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DragAlongCell key={cell.id} cell={cell} />
                  </SortableContext>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      {paginationHtml()}
    </DndContext>
  );
};

export default TableV2;
