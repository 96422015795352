import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetPracticeIndustries } from "../../redux/catalogs/states";
import { getAllPracticeIndustries } from "../../Services/PracticeIndustry";

const UsePracticeIndustryList = () => {
  const [isFetching, setIsFetching] = useState(true)

  const refetchPracticeIndustries = async () => {
    getAllPracticeIndustries()
      .then((response) => dispatch(SetPracticeIndustries(response)))
      .catch((error) => console.error(error))
  };

  const dispatch = useDispatch();
  const practiceIndustryData = useSelector((state: Appstore) => state.practiceIndustries);

  useEffect(() => {
      if (practiceIndustryData.length) {
        setIsFetching(false)
        return
      }
      if (!isFetching) return

      getAllPracticeIndustries()
      .then((response) => {
        dispatch(SetPracticeIndustries(response))
        if (!response.length) console.error('No practice industries found. Please check the data source.')
      })
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false))
  }, [isFetching]);

  return {practiceIndustryData, isFetching, refetchPracticeIndustries};
};

export {UsePracticeIndustryList}
