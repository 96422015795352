import React from "react";

function UpdateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 25"
    >
      <path 
        d="M4.203 20.432v-1.656H7.1l-.234-.2C5.79 17.677 5 16.721 4.492 15.707c-.507-1.014-.76-2.152-.76-3.413 0-1.88.577-3.557 1.732-5.031C6.618 5.79 8.112 4.8 9.944 4.294v2.031c-1.26.427-2.278 1.194-3.054 2.3a6.248 6.248 0 00-1.164 3.67c0 1.012.189 1.89.566 2.631.378.742.89 1.387 1.538 1.935l.666.44V14.47h1.655v5.962H4.203zm9.878-.262v-2.056a5.866 5.866 0 003.054-2.3 6.343 6.343 0 001.139-3.67c0-.761-.191-1.539-.573-2.33a8.01 8.01 0 00-1.467-2.11l-.64-.566V9.97h-1.67V4.008h5.962v1.655H16.95l.248.251c1.019.953 1.785 1.976 2.3 3.071.513 1.095.77 2.148.77 3.16 0 1.88-.573 3.56-1.72 5.043-1.145 1.483-2.635 2.477-4.467 2.982z"
      ></path>
    </svg>
  );
}

export default UpdateIcon;
