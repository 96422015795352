import React, { useEffect, useState } from "react";
import CatalogTableC from "../../components/Catalogs/CatalogTableC";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import useDataLoader2 from "../../hooks/useDataLoader2";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { formatTimeSpanToString } from "../../utils/functions";

type tablebase = {
  count: number;
  edit: boolean;
};
type Iregion = {
  id: string;
  name: string;
  abbreviation: string;
  _ts: string;
};
const columnHelper = createColumnHelper<tablebase & Iregion>();

const Region: React.FC = () => {
  const Headers: string[] = ["name", "abbreviation", "_ts"];
  const DefaultPageSize = 50;
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    'abbreviation',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/region',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columns = [
    columnHelper.accessor("count", {
      header: "#",
      enableSorting: false,
      cell: (props) => (
        <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
      ),
      size: 50,
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("abbreviation", {
      header: "Abbreviation",
      cell: (props) => <p>{props.getValue()}</p>,
    }),
    columnHelper.accessor("_ts", {
      header: "Created Date",
      cell: (props) => {
        const value = props.getValue();
        return (
          <p>
            {value && formatTimeSpanToString(value) } 
          </p>
        );
      },
    }),
    columnHelper.accessor("edit", {
      header: "Edit",
      enableSorting: false,
      cell: (props) => (
        <Link
          className="edit"
          to={`Edit/${props.row["original"].id}`}
          state={props.row["original"]}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    }),
  ];

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  return (
    <>
      <div className="mainSection">
        <CatalogTableC
          title="Region Catalog"
          catalogName={"REGIONS"}
          catalogShortName={"Region"}
          headers={Headers}
          route={"Region"}
          totalCount={totalCount}
          isfiltered={false}
          data={data}
        >
          <TableWithPagination
            data={data}
            columnsDef={columns}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default Region;
