import React, { useEffect, useState } from "react";
import PencilEdit from "../Atomos/Icons/PencilEdit";
import { Link, useLocation } from "react-router-dom";
import Axios from "../../utils/axios";
import { useAppContext } from "../../Context";

interface ProfileCardProps {
  isEditable?: boolean;
  person: any;
  personLanguages?: any;
  profileEducation?: any;
  personBAS: any;
}

const ProfileCardNew: React.FC<ProfileCardProps> = ({
  isEditable,
  person,
  personLanguages,
  profileEducation,
  personBAS,
}) => {
  const axios = new Axios()

 const {mode} = useAppContext()
  const location = useLocation()



  const editLinkPath = mode === 'staff'
  ? `/staff/profile/edit/attorney/${person?.id}/${person?.generatedId}`
  : `/profile/edit/attorney/${person?.id}/${person?.generatedId}`;


  return (
    <>
      <div className="flex flex-col items-end gap-6 self-stretch">
        <div className="flex flex-col gap-5 items-start self-stretch md:gap-6 lg:gap-[1.75rem]">
          {person && person?.bio &&
            <>
              <div className="flex flex-col items-end gap-[.625rem] self-stretch md:pt-0 md:px-0">
                <span className="text-gray-font text-base text-left w-full whitespace-pre-wrap lg:text-lg lg:leading-8">
                  {person?.bio}
                </span>
              </div>
              <div className={`flex items-start gap-[.625rem] self-stretch ${(person.bio && personLanguages?.length) === 0 ?  'hidden' : ''}`}>
                <hr className="flex h-0 justify-center items-center flex-1 self-stretch relative z-10" />
              </div>
            </>
          }
          {personLanguages && personLanguages?.length > 0 &&
            <>
              <div className={`${!person?.bio ? '' : 'md:pt-0 md:px-0'} flex flex-col items-start gap-5 self-stretch lg:gap-6`}>
                <span className="text-lg leading-5 font-semibold text-gray-font  lg:text-[1.375rem] lg:leading-6 md:text-[1.25rem]">
                  Languages
                </span>
                {personLanguages &&
                  <div className="grid grid-cols-3 gap-x-3 gap-y-4 md:grid-cols-4 items-start self-stretch gap-4 text-center md:text-left md:gap-x-5">
                    {
                      personLanguages.map((language: any) =>
                        <span key={language.id} className="text-gray-font text-base leading-[1.5rem] flex-1 lg:text-lg lg:leading-8">&bull; {language.name}</span>
                      )
                    }
                  </div>
                }
              </div>
              <div className="flex items-start gap-[.625rem] self-stretch">
                <hr className="flex h-0 justify-center items-center flex-1 self-stretch relative z-10" />
              </div>
            </>
          }
          {personBAS.length > 0 ?
            <>
              <div className="md:px-0 flex flex-col items-start gap-5 self-stretch lg:gap-6">
                <span className="text-lg leading-5 font-semibold text-gray-font  lg:text-[1.375rem] lg:leading-6 md:text-[1.25rem]">
                  Bar Admissions
                </span>
                <div className="grid grid-cols-3 gap-x-3 gap-y-4 md:grid-cols-4 items-start self-stretch gap-4 text-center md:text-left md:gap-x-5">
                  {personBAS.map((barAdmission:any) => (
                    <span key={barAdmission.id} className="text-gray-font text-base leading-[1.5rem] flex-1 lg:text-lg lg:leading-8">&bull; {barAdmission.name}</span>
                  ))}
                </div>
              </div>
              <div className="flex items-start gap-[.625rem] self-stretch">
                <hr className="flex h-0 justify-center items-center flex-1 self-stretch relative z-10" />
              </div>
            </> : null
          }

          {profileEducation && profileEducation?.length > 0 &&
            <>
              <div className="md:px-0 flex flex-col items-start gap-5 self-stretch lg:gap-6">
                <span className="text-lg leading-5 font-semibold text-gray-font  lg:text-[1.375rem] lg:leading-6 md:text-[1.25rem]">
                  Education
                </span>
                <div className="flex flex-col items-start self-stretch gap-5">
                  {profileEducation.map((education: any) =>
                    <div key={education.id} className="flex flex-col items-start gap-[.625rem] self-stretch">
                      <div className="flex flex-col items-start gap-1 self-stretch">
                        <div className="flex p-0 flex-col items-start self-stretch gap-2">
                          <span className="text-gray-font text-base leading-[1.125rem] font-semibold lg:text-lg lg:leading-[1.25rem] ">{education.school.name} University
                            <span className="text-gray-font text-base leading-[1.125rem] font-normal lg:text-[1.125rem] lg:leading-[2rem]">  ({education.startYear} - {education.endYear})</span>
                          </span>
                        </div>
                        <span className="text-gray-font text-base italic  leading-[1.125rem] font-normal lg:text-[1.25rem] lg:leading-[1.25rem]">
                          {education.degree.title}
                        </span>
                      </div>
                      {education.notes &&
                        education.notes.split("\n").map((note: string, index: number) =>
                          note.trim() && <span key={index} className="text-gray-font text-base leading-[1.5rem] font-normal lg:text-[1.125rem] lg:leading-[2rem]">
                            &bull; {note}
                          </span>
                        )
                      }
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </div>
        {isEditable &&
        <Link to={editLinkPath} state={{...person, isOnlyAops: null, isOnlyProfessionalCareer: null, isOnlySocials: null}}>
          <div className="flex flex-col items-end justify-end gap-[.625rem] self-stretch">
            <div className="flex p-0 justify-center items-start gap-[.625rem]">
              <span className="text-[#0070CD] text-right font-decimal text-base font-bold leading-6 uppercase lg:text-[1.125rem] lg:leading-[1.5rem]">Edit</span>
              <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-Default">
                <PencilEdit className='w-full h-full pencilBlue' />
              </div>
            </div>
          </div>
          </Link>
        }
      </div>
    </>
  )
}

export default ProfileCardNew;