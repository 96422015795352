import React from 'react';
import css from './Navigation.module.css';
import { NavigationProps } from './INavigation';
import { UseMainNavigation } from './UseNavigation';
import NavigationItem from './NavigationItem';
import { CloseNavigationButton } from './CloseNavigationButton';
import ArrowBack from '../../Atomos/Icons/ArrowBack';

const Navigation: React.FC<NavigationProps> = (props) => {
  
  const {closedNav,closeNavButton, navigationClassName,handleClose,closeAll } = UseMainNavigation(props);



  const handleBlackLayerClick = () => {
    if (props.isSubMenuOpen) {
      closeAll();
    } else {
      handleClose();
    }
  };

  const handleArrowBackClick = () => {
    handleClose();
  };

  const handleNavigationItemClick = (item:any) => {
    if (item.hasSubmenu) {
      props.opensubmenu(item.name);
    } else {
      props.NavLvl === 0 ? handleClose() : props.closeAll();
    }
  };




  return (
    <div className='relative'>
      {props.NavLvl == 0 && (
        <div
          id="blackLayer"
          className={`${props.isOpen ? css.animateFadeIn : css.animateFadeOut} fixed w-full min-h-screen h-screen bg-black filter ${props.isOpen ? '' : ''}`}
          onClick={handleBlackLayerClick}
        ></div>
      )}
      <nav
        className={`xl:w-1/4 lg:w-30 w-9/12 md:w-5/12 ${!props.isOpen ? 'translate-x-full' : ''} duration-300	 pt-16 absolute right-0 min-h-screen flex flex-col ${navigationClassName}  }`}
        >
          <div>
          {props.NavLvl > 0 && (
            <div onClick={handleArrowBackClick} className='cursor-pointer'>
              <ArrowBack className={'absolute w-12 h-12 top-4 left-5 opacity-75 fill-white'} />
            </div>
          )}
            <CloseNavigationButton classname={'w-12 h-12'} closedNav={false} handleClose={props.NavLvl>0?closeAll:handleClose} />
          </div>
          <div className={`w-full relative ${!props.IsStaffNavigate ? '' : ''}  `}> 
            <ul className={`roboto_font pt-6 gap-0 overflow-scroll ${css.navItemsList}`}>
             
            {props.navigationList.map((item, index) => (
              <React.Fragment key={index}>
                <NavigationItem
                  to={(props.IsStaffNavigate ? '/Staff' : '') + item.to}
                  name={item.name}
                  hasSubmenu={item.hasSubmenu || false}
                  onClick={() => handleNavigationItemClick(item)}
                />
              </React.Fragment>
            ))}

            </ul> 
          </div>
          {
          !props.IsStaffNavigate ? 
            <div className={`${css.dotsNav} w-full relative`}></div> :
            <div className={`${css.dotsNavWhite} w-full relative`}></div>
          }
      </nav>

    </div>
  );
};

export default Navigation;
