
export interface ICloseSmallIcon { 
    onClick?: () => void;
}

export const CloseSmall = ({ ...props }: ICloseSmallIcon): JSX.Element => {
  return (
<svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
<path d="M5.28408 15.65L4 14.3659L8.55601 9.80991L4 5.28408L5.28408 4L9.84009 8.55601L14.3659 4L15.65 5.28408L11.094 9.80991L15.65 14.3659L14.3659 15.65L9.84009 11.094L5.28408 15.65Z"/>
</svg>
  )
}
