import React from "react";

function CancelClear() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
    >
      <path 
        d="M6.225 19.164l-1.39-1.389L10.612 12 4.836 6.225l1.39-1.39L12 10.612l5.775-5.775 1.39 1.39L13.388 12l5.775 5.775-1.389 1.39L12 13.388l-5.775 5.775z"
      ></path>
    </svg>
  );
}

export default CancelClear;
