import React from "react";

function Eye() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g>
        <mask
          id="mask0_2083_6755"
          style={{ maskType: "alpha" }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path   d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_2083_6755)">
          <path 
            d="M12.003 15.75c1.181 0 2.184-.413 3.01-1.24.824-.827 1.237-1.832 1.237-3.013 0-1.181-.414-2.184-1.24-3.01-.828-.824-1.832-1.237-3.013-1.237-1.181 0-2.185.414-3.01 1.24-.825.827-1.237 1.832-1.237 3.013 0 1.181.413 2.185 1.24 3.01.827.825 1.831 1.237 3.013 1.237zm-.006-1.676c-.715 0-1.323-.25-1.822-.752a2.49 2.49 0 01-.75-1.824c0-.716.251-1.324.752-1.823.501-.5 1.11-.749 1.825-.749.716 0 1.323.25 1.823.752.5.5.749 1.109.749 1.825 0 .715-.25 1.323-.752 1.822a2.49 2.49 0 01-1.825.749zM12 19.325c-2.509 0-4.775-.724-6.798-2.174-2.023-1.45-3.532-3.333-4.527-5.65.995-2.319 2.504-4.203 4.527-5.652C7.225 4.399 9.492 3.675 12 3.675s4.774.725 6.798 2.174c2.023 1.45 3.532 3.333 4.527 5.651-.995 2.318-2.504 4.202-4.527 5.651-2.023 1.45-4.29 2.174-6.798 2.174zm-.003-1.825a9.997 9.997 0 005.547-1.637 10.65 10.65 0 003.853-4.363 10.656 10.656 0 00-3.85-4.362A9.982 9.982 0 0012.003 5.5a9.997 9.997 0 00-5.547 1.638A10.828 10.828 0 002.578 11.5c.9 1.817 2.192 3.27 3.875 4.363a9.983 9.983 0 005.544 1.637z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Eye;
