import css from './ProfilePage.module.css'

import BacgrkoundProfile from '../../assets/images&icons/bgprofile.jpeg'
import BackgroundComponent from '../../components/Layout/BackgroundProfile/BackgroundProfile';
import Banner from '../../components/Layout/Banner/Banner';
import Vortex from '../../assets/images&icons/vortex.jpeg'
import Webinars from '../../assets/images&icons/webinars.jpeg'
import ChevronRight from '../../components/Atomos/Icons/ChevronRight';
import Video from '../../components/Atomos/Icons/Video';
import { useStaffMode } from '../../hooks/StaffModeProvider';
import { useEffect, useState } from 'react';
import StaffModal from '../../components/Layout/StaffModal/StaffModal';
import ProfileHeading from '../../components/Moleculas/Profile/ProfileHeading/ProfileHeading';
import ProfileInfo from '../../components/Moleculas/Profile/ProfileInfo/ProfileInfo';
import ProfileGroups from '../../components/Moleculas/Profile/ProfileGroups/ProfileGroups';
import ProfileEvents from '../../components/Moleculas/Profile/ProfileEvents/ProfileEvents';
import ProfileLatest from '../../components/Moleculas/Profile/ProfileLatest/ProfileLatest';



const ProfilePage = () => {
  const { navigatedNoPermission, isWsgStaff, navigatedNotFound } = useStaffMode()
  useEffect(() => {
    if (navigatedNoPermission || navigatedNotFound) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';

    }
  }, [navigatedNoPermission, navigatedNotFound])

  return (
    <div className={`w-full h-auto bg-gray-200`}>
      {
        (navigatedNoPermission && isWsgStaff) &&
        <StaffModal text={"The requested page may not exist or may have been removed."} isWsg={true} />
      }
      {
        (navigatedNotFound && isWsgStaff) &&
        <StaffModal text={'The requested page may not exist or may have been removed.'} isWsg={true} />
      }
      {
        (navigatedNoPermission && !isWsgStaff) &&
        <StaffModal text={'You do not have permission to access this page.'} isWsg={false} />
      }
      {
        (navigatedNotFound && !isWsgStaff) &&
        <StaffModal text={'The requested page may not exist or may have been removed.'} isWsg={false} />
      }
      <BackgroundComponent image={BacgrkoundProfile} />
      <div>
        <ProfileHeading title='My WSG' currentPage='My WSG' />
        <ProfileInfo />
        <ProfileGroups />
        <ProfileEvents />
        <div className='w-11/12 flex mx-auto'>
          <Banner
            title="Join WSG Groups"
            description="Looking to engage with professionals relevant to your industry, practice and clients? Join one of WSG's many Practice Groups to learn, share, and gain client opportunities."
            buttonText="View Practice Groups"
            backgroundImage={Vortex}
            isCompleteWidth={true}
            viewMode={false}
            icon={<ChevronRight />}
            isLeft={true}
          />
        </div>
        <ProfileLatest />
        <div className='w-11/12 flex mx-auto pb-[3rem]'>
          <Banner
            title="Up Your Expertise"
            description="View one of many WSG informative sessions to get the best value out of your firm's membership and digital profile."
            buttonText="View Webinars"
            backgroundImage={Webinars}
            isCompleteWidth={true}
            viewMode={false}
            icon={<Video />}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;