import React, { useEffect, useState } from 'react';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import OfficeContactsFilter from '../../components/Catalogs/Filters/OfficeContactsFilter';
import { UseFirmList, firmType } from '../../hooks/Catalogs/useFirm';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import useDataLoader2 from '../../hooks/useDataLoader2';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { formatTimeSpanToString } from '../../utils/functions';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import Eye2 from '../../components/Atomos/Icons/Eye';
const OfficeContact: React.FC = () => {
  const Headers: string[] = ['firm', 'totalContacts'];
  const DefaultPageSize = 50;
  const [officeContactfilters, setOfficeContactfilters] = useState<any>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'firm',
    'offices',
    'totalContacts',
    'firmName',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/officeContact',
    defaultPageSize: DefaultPageSize,
    fields
  });
  const { firmData} = UseFirmList();
  const { generateColumns } = useColumnConfig(Headers);

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'firm', header: 'Firm', cell: (props: any) => firmData.find((f: any) => f.id === props.getValue())?.name },
    { id: 'totalContacts', header: 'Total Contacts', cell: (props: any) => props.getValue() },
    {
      id: '_ts', header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return (<p> {value && formatTimeSpanToString(value) } </p> );
      },
    },
    {
      id: 'view', header: 'View', cell: (props: any) => (
        <Link
          className="view"
          to={`View/${props.row.original.id}`}
          state={props.row.original}
        >
          <div className="editsvg flex gap-1"><span>View</span><Eye2 className="flex"/></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const clearSelections = () => {
    setOfficeContactfilters([])
    fetchData(true)
  }

    useEffect(() => {
      if (!firmData.length) return;
      if(!isFetching) fetchData();
      setIsFetching(true)
    },[firmData]);
  
    useEffect(() => {
      setFilter({
        firm: {
          value: officeContactfilters.firms?.map((o: any) => o.id) || [],
          comparisonType: 'EQUAL' 
        }
      })
    },[officeContactfilters]);

  return (
    <>
      <main className=' mainSection'>
        <CatalogTableC
          title={`Member Main Contacts Catalog`} catalogName={'MEMBER MAIN CONTACTS'} route={'OfficeContact'}
          isfiltered={true}
          data={data} headers={Headers}
          totalCount={totalCount}
          noExcelExport
          noNewCatalog
        >
          <OfficeContactsFilter key={0}
            Search={fetchData}
            filters={officeContactfilters} setFilters={setOfficeContactfilters}
            firmData={firmData}
            headers={Headers}
            clearSelections={clearSelections}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>

  );
};

export default OfficeContact;