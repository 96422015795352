import React from "react";
import { IContent, IPerson } from "../../interfaces";

interface PersonArticlesProps {
  person: IPerson;
  articles: IContent[];
}

const formatDate = (date: Date | string): string => {
  if (date instanceof Date) {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  return date;
};

const PersonArticles: React.FC<PersonArticlesProps> = ({ person, articles }) => {
  const validArticles = articles.filter(article => {
    const publishedDate = new Date(article.publishedDate);
    return !isNaN(publishedDate.getTime());
  });

  return (
    <React.Fragment>
      <div className="flex px-7 py-7 flex-col items-end gap-6 self-stretch lg:px-12 lg:py-8 lg:gap-[1.75rem]">
        <div className="flex flex-col items-end gap-7 self-stretch">
          {person && validArticles.length > 0 && (
            validArticles.map(article => (
              <div key={article.shortId || article.title} className="flex items-start gap-2 self-stretch">
                <div className="flex flex-col items-start gap-5 flex-1">
                  <div className="flex flex-col items-start self-stretch">
                    <span className="text-lg leading-normal font-bold text-[#0070CD] self-stretch">
                      {article.title}
                    </span>
                    <div className="flex items-start gap-2">
                      <div className="flex items-center gap-[.625rem]">
                        <span className="text-gray-font text-sm leading-5">
                          {person.name || ''} {person.lastName || ''}
                        </span>
                        <span className="text-gray-font text-lg leading-5">
                          |
                        </span>
                        <span className="text-gray-font text-sm leading-5">
                          {formatDate(new Date(article.publishedDate))}
                        </span>
                      </div>
                    </div>
                    <span className="text-sm leading-5 text-gray-font self-stretch">
                      {typeof article.articleContent === 'string' ? article.articleContent : ''}
                    </span>
                  </div>
                </div>
                <hr className="flex h-0 justify-center items-center self-stretch" />
              </div>
            ))
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PersonArticles;
