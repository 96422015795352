// ProfileAndSocialLinks.jsx
import React, { useEffect, useState } from 'react';
import SocialLinks from '../../components/ViewMode/SocialLinks';
import { IPerson } from '../../interfaces';
import PencilEdit from '../../components/Atomos/Icons/PencilEdit';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from '../../Context';
import AddToMyNetwork from '../../components/Atomos/Icons/ViewMode/AddToMyNetwork';

interface ProfileAndSocialLinksProps {
  person: IPerson;
  organization: boolean;
  isEditable?: boolean;
  currentFirm?: any;
  isPublicPreview?: boolean;
}


const ProfileAndSocialLinks: React.FC<ProfileAndSocialLinksProps> = ({ person, organization, isEditable, currentFirm, isPublicPreview }) => {
  const { mode } = useAppContext()

  const editLinkPath = mode === 'staff'
    ? `/staff/profile/edit/attorney/${person.id}/${person.generatedId}`
    : `/profile/edit/attorney/${person.id}/${person.generatedId}`;


  return (
    <div className="flex flex-col p-0 justify-center items-center gap-[.625rem] self-stretch lg:items-start lg:h-max">
      <div className="flex flex-col justify-center items-center gap-[.625rem] relative box-border">
        <div className="flex lg:flex-col w-[10.625rem] justify-center items-center gap-[.625rem] relative">
          <div className="w-[9rem] h-[9rem] shrink-0 overflow-hidden z-10 relative">
            {!organization ? <img
              src={person.photo}
              alt="Profile"
              className="w-full h-full object-cover absolute top-0 left-0 rounded-[9rem]"
            /> : <img
              src={currentFirm?.logo}
              alt="Profile"
              className="w-full h-full object-cover absolute top-0 left-0 rounded-[9rem]"
            />
            }
          </div>
          {(isPublicPreview || !isEditable) && !organization &&
            <div className='flex items-center gap-2 self-stretch cursor-pointer'>
              <div className='w-[1.125rem] h-[1.125rem]'>
                <AddToMyNetwork />
              </div>
              <span className='text-primary text-base leading-[1.125rem]'>
                Add to My Network
              </span>
            </div>
          }
          {isEditable && !organization &&
            <Link to={editLinkPath} state={{ ...person, isOnlyProfessionalCareer: false, isOnlyAops: false, isOnlySocials: false }}>
              <div className='w-8 h-8 absolute z-20 top-0 right-[1.3125rem] md:right-[.8125rem] rounded-full border border-solid bg-[#D6D6D6] border-[#D6D6D6] flex p-[.625rem] justify-center items-center gap-2 md:w-10 md:h-10'>
                <div className='w-[1.5rem] h-[1.5rem] flex items-center justify-center flex-shrink-0'>
                  <div className={`flex w-[1.5rem] h-[1.5rem] pl-[.19rem] pt-[.19rem] pr-[.12rem] pb-[.12rem] items-center justify-center flex-shrink-0 ${mode === 'staff' ? 'fill-Default' : 'fill-accentDefault'}`}>
                    <PencilEdit className='w-full h-full pencilRed' />
                  </div>
                </div>
              </div>
            </Link>
          }
        </div>
        <div className={`${organization ? 'flex' : 'hidden'}`}>
          <SocialLinks isPublicPreview={isPublicPreview} user={person} organization={organization} isEditable={isEditable} />
        </div>
      </div>
    </div>
  )
}

export default ProfileAndSocialLinks;
