import React from "react";

function Pencil() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">  
      <path d="M3.832 16.262h.776l9.135-9.159-.776-.775-9.135 9.158v.776zM16.577 6.219l-2.714-2.714L15.65 1.72l2.749 2.69-1.821 1.81zM2.441 17.641v-2.713L12.988 4.38l2.726 2.702-10.56 10.56H2.442zM13.367 6.716l-.4-.388.776.775-.376-.387z"></path> 
    </svg>
  );
}

export default Pencil;
