import { ICountry } from "../../interfaces";

export const CountryEmpty: ICountry = { 
    id: "",
    name: "",
    abbreviation: "",
    hasState: false,
    regions: [], 
    CreatedDate: 0,
    _ts: 0 
 };