import { NavigationItemProps } from "./INavigation";

export const GetMemberNavigation = (
  handleClose: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleClose, to: '/about-us', name: 'About Us' },
    { onClick: handleClose, to: '/members', name: 'Members' },
    { onClick: handleClose, to: '/groups', name: 'Groups' },
    { onClick: handleClose, to: '/events', name: 'Events' },
    { onClick: () => { }, name: 'Insights', hasSubmenu: true },
    { onClick: () => { }, name: 'Resources', hasSubmenu: true },
    { onClick: () => { }, name: 'WSG Learning Institute', hasSubmenu: true },
    { onClick: () => { }, name: 'Global Crisis Hub', hasSubmenu: true },
  ];
};

export const GetStaffNavigation = (
  handleClose: () => void,
  userPermissions: string[]
): NavigationItemProps[] => {
  const navigationItems: NavigationItemProps[] = [
    { name: '', className: `mt-[7rem]` },
    { onClick: () => { }, name: 'Analytics Dashboard', hasSubmenu: true },
    { onClick: () => { }, name: 'Firm', hasSubmenu: true },
    { onClick: () => { }, name: 'Tools', hasSubmenu: true },
    { onClick: handleClose, to: '/manage-profile', name: 'Manage Profile' },
    { onClick: () => { }, name: 'Sync', hasSubmenu: true },
    { onClick: () => { }, name: 'Catalogs', hasSubmenu: true },
    { onClick: handleClose, to: '/lists', name: 'Lists' },
    { onClick: () => { }, name: 'Help Guide', hasSubmenu: true },
    { onClick: () => { }, name: 'SEO', hasSubmenu: true },
  ];

  if (!userPermissions.includes('WSG Staff Super')) {
    return navigationItems.filter(item => item.name !== 'Catalogs');
  }

  return navigationItems;
}

export const GetInsights = (handleCloseAll: () => void): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: '/gc-insights', name: 'GC Insights' },
    { onClick: handleCloseAll, to: '/member-blogs', name: 'Member Blogs' },
    { onClick: handleCloseAll, to: '/featured', name: 'Featured' },
    { onClick: handleCloseAll, to: '/wsg-blog', name: 'WSG Blog' },
  ];
};

export const GetResources = (handleCloseAll: () => void): NavigationItemProps[] => {
  return [
    { onClick: () => { }, name: 'News & Articles', hasSubmenu: true },
    { onClick: handleCloseAll, to: '/wsg-knowledge-center', name: 'WSG Knowledge Center' },
    { onClick: handleCloseAll, to: '/secondments', name: 'Secondments' },
    { onClick: handleCloseAll, to: '/dealmakers', name: 'Dealmakers' },
    { onClick: handleCloseAll, to: '/help-center', name: 'Help Center' },
  ];
};

export const GetCategoriesSubNav = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/Catalogs/Address", name: "Address" },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/AreaOfPractice",
      name: "Areas Of Practice",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/AOPRanking",
      name: "AOP Ranking",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/BarAdmission",
      name: "Bar Admission",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/RepresentativeClient",
      name: "Representative Client",
    },
    { onClick: handleCloseAll, to: "/Catalogs/City", name: "City" },
    { onClick: handleCloseAll, to: "/Catalogs/Country", name: "Country" },
    { onClick: handleCloseAll, to: "/Catalogs/DataAdmin", name: "Data Admin" },
    { onClick: handleCloseAll, to: "/Catalogs/Degree", name: "Degree" },
    { onClick: handleCloseAll, to: "/Catalogs/Education", name: "Education" },
    { onClick: handleCloseAll, to: "/Catalogs/Firm", name: "Firm" },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/FirmContact",
      name: "Firm Contact",
    },
    { onClick: handleCloseAll, to: "/Catalogs/FirmNote", name: "Firm Note" },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/Jurisdiction",
      name: "Jurisdiction",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/JurisdictionGroup",
      name: "Jurisdiction Group",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/PracticeIndustry",
      name: "Practice Industry",
    },
    { onClick: handleCloseAll, to: "/Catalogs/Language", name: "Language" },
    { onClick: handleCloseAll, to: "/Catalogs/Region", name: "Region" },
    { onClick: handleCloseAll, to: "/Catalogs/Office", name: "Office" },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/MemberMainContact",
      name: "Member Main Contact",
    },
    { onClick: handleCloseAll, to: "/Catalogs/person", name: "Person" },
    { onClick: handleCloseAll, to: "/Catalogs/State", name: "State" },
    { onClick: handleCloseAll, to: "/Catalogs/Substate", name: "SubState" },
    { onClick: handleCloseAll, to: "/Catalogs/School", name: "School" },
    { onClick: handleCloseAll, to: "/timezone", name: "Timezone" },
    { onClick: handleCloseAll, to: "/Catalogs/User", name: "User" },
    { onClick: handleCloseAll, to: "/Catalogs/WSG", name: "WSG" },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/ContentGeneralTopic",
      name: "Content General Topic",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/GlobalCrisis",
      name: "Global Crisis",
    },
    {
      onClick: handleCloseAll,
      to: "/Catalogs/PublicationTag",
      name: "Content Tag",
    },
    { onClick: handleCloseAll, to: "/Catalogs/Content", name: "Content" },
  ].sort((a, b) => a.name.localeCompare(b.name));
};

export const GetLearningInstitute = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/leadership-program", name: "WSG Leadership Training Program" },
    { onClick: handleCloseAll, to: "/marketing-workshop", name: "WSG Marketing Workshop" },
    { onClick: handleCloseAll, to: "/emeritus-program", name: "WSG and Emeritus Program" },
  ];
};

export const GetGlobalCrisisHub = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/covid-19", name: "Covid-19 Crisis" },
    { onClick: handleCloseAll, to: "/ukraine-crisis", name: "Ukraine Crisis" },
  ];
};

export const GetNewsArticles = (handleCloseAll: () => void): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: '/articles', name: 'Articles' },
    { onClick: handleCloseAll, to: '/release', name: 'Press' },
    { onClick: handleCloseAll, to: '/collaborations', name: 'WSG Collaborations' },
    { onClick: handleCloseAll, to: '/newsletters', name: 'WSG Newsletter' },
    { onClick: handleCloseAll, to: '/latest-news', name: 'Latest World News' },
    { onClick: handleCloseAll, to: '/submit-article', name: 'Submit Articles' },
    { onClick: handleCloseAll, to: '/submit-release', name: 'Submit Press' },
    { onClick: handleCloseAll, to: '/submit-event', name: 'Submit Events' },
  ];
}

// staff navigation

export const GetAnalyticsDashboard = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: () => { }, name: "WSG Overview", hasSubmenu: true },
    { onClick: () => { }, name: "Google Analytics", hasSubmenu: true },
    { onClick: handleCloseAll, to: "/send-grid", name: "SendGrid Email Stats" },
  ];
};

export const GetWSGOverview = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/overview/firm", name: "Firm" },
    { onClick: handleCloseAll, to: "/overview/events", name: "events" },
    { onClick: handleCloseAll, to: "/overview/referrals", name: "Referrals" },
    { onClick: handleCloseAll, to: "/overview/dues", name: "Annual Dues By Year" },
  ];
};

export const GetGoogleAnalytics = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/google/wsg-site", name: "WSG Site" },
    { onClick: handleCloseAll, to: "/google/firm-profile", name: "Firm Profile" },
    { onClick: handleCloseAll, to: "/google/professional-profile", name: "Professional Profile" },
    { onClick: handleCloseAll, to: "/google/article", name: "Article/Press" },
    { onClick: handleCloseAll, to: "/google/campaigns", name: "Campaigns" },
  ];
};

export const GetCategoriesFirm = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/ManageFirm", name: "Manage Firm" },
    { onClick: handleCloseAll, to: "/firm-score", name: "Firm Score" },
    { onClick: handleCloseAll, to: "/practice-head", name: "Practice Head" },
    { onClick: handleCloseAll, to: "/wsg-contacts", name: "WSG Contacts" },
  ];
};

export const GetTools = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/firm-referrals", name: "Firm Referrals/Collaborations" },
    { onClick: handleCloseAll, to: "/secondments", name: "Secondments" },
    { onClick: handleCloseAll, to: "/dealmaker", name: "DealMaker" },
    { onClick: handleCloseAll, to: "/assessment-fee", name: "Assessment Fee" },
    { onClick: handleCloseAll, to: "/member-to-member", name: "Member To Member Request" },
  ];
};

export const GetSync = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/sync-module", name: "Sync Module" },
    { onClick: handleCloseAll, to: "/press-sync", name: "Article/Press Sync" },
    { onClick: handleCloseAll, to: "/rss-feed", name: "Article/Press RSS Feed" },
  ];
};

export const GetHelpGuide = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    { onClick: handleCloseAll, to: "/how-to", name: "How To" },
    { onClick: handleCloseAll, to: "/faq", name: "FAQ" },
  ];
};

export const GetSEOSubNav = (
  handleCloseAll: () => void
): NavigationItemProps[] => {
  return [
    {
      onClick: handleCloseAll,
      to: "",
      name: "Meta Tags",
      className: "text-[.5rem]",
    },
    { onClick: handleCloseAll, to: "/AOP", name: "AOP Mapping" },
    { onClick: handleCloseAll, to: "/AOPRanking", name: "AOP Ranking" },
    { onClick: handleCloseAll, to: "/ActivityLog", name: "Activity Log" },
  ];
};
