import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import Axios from '../../../utils/axios';
import { Title } from '../../../Enums/PersonEnums';
import CatalogFilterButtons from '../CatalogFilterButtons';
import Input from '../../Atomos/Inputs/Input';

const axios = new Axios();

interface IPersonFilterData {
  id_: string;
  name: string;
  firmsId: Option[];
  titleId: Option[];
  memberType: Option[];
  isAttorney: Option[];
  isStaff: Option[];
}

interface IPersnFilterProps {
  setPersonFilter: any;
  personFilter: IPersonFilterData;
  onClickSearch: () => void;
  handleFormReset: () => void;
  headers: string[];
  tableInstance: any;
}

const PersonFilter: React.FC<IPersnFilterProps> = ({ personFilter, setPersonFilter, onClickSearch, handleFormReset, headers, tableInstance }) => {
  const DefaultYesNoOptions = [{ id: 'No', name: 'No' }, { id: 'Yes', name: 'Yes' }];
  const memberTypeOptions = [{ id: '0', name: 'Member' }, { id: '1', name: 'Nonmeber' }, { id: '2', name: 'Staff' }];
  const [firms, setFirms] = useState<Option[]>([]);
  const enumTitles = Object.entries(Title).map(([key, value]) => ({ id: key, name: value } as Option));
  const [titles] = useState<Option[]>(enumTitles);
  const [isMemberOptions] = useState<Option[]>(memberTypeOptions);
  const [isAttorneyOptions] = useState<Option[]>(DefaultYesNoOptions);
  const [isStaffOptions] = useState<Option[]>(DefaultYesNoOptions);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.Get('/Firm');
      const firms = res.data;
      setFirms(firms);
    }
    fetchData();
  }, [])

  return (

    <div className='flex flex-col items-center self-stretch formsection justify-end p-4
      md:grid md:grid-cols-2 md:items-end  md:justify-center
       lg:flex-1 lg:flex lg:flex-row
       border border-gray-100'>
      <div className='w-full'>
        <Input value={personFilter.name} onChange={(e) => { setPersonFilter({ ...personFilter, name: e.currentTarget.value }); }} title={'By Name:'} error={false} />
      </div>
      <div className='w-full'>
        <DDMultiple title="By Firm:" objects={firms} selectedObjst={personFilter.firmsId} seter={(e) => { setPersonFilter({ ...personFilter, firmsId: e }) }} />
      </div>

      <div className='w-full'>
        <DDMultiple title="By Title:" objects={titles} selectedObjst={personFilter.titleId} seter={(e) => { setPersonFilter({ ...personFilter, titleId: e }) }} />
      </div>
      <div className='w-full'>
        <DDMultiple title="Person Type" objects={isMemberOptions} selectedObjst={personFilter.memberType} seter={(e: any) => setPersonFilter({ ...personFilter, memberType: e })} />
      </div>
      <div className='w-full'>
        <DDMultiple title="Is Attorney:" objects={isAttorneyOptions} selectedObjst={personFilter.isAttorney} seter={(e: any) => setPersonFilter({ ...personFilter, isAttorney: e })} />
      </div>
      <div className='w-full'>
        <CatalogFilterButtons
          onClickSearch={onClickSearch}
          handleFormReset={handleFormReset}
          headers={headers}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};

export default PersonFilter;


