import "../../../assets/css/AddCatalogs.css";
import React, { useState, useEffect } from "react";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Vector from "../../../components/Atomos/Icons/Vector";
import VectorUp from "../../../components/Atomos/Icons/VectorUp";
import PencilEdit from "../../../components/Atomos/Icons/PencilEdit";
import { updateFirm } from "../../../Services/Firm";
import SaveChanges from "../../../components/Atomos/Icons/EditMode/SaveChanges";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import Close from "../../../components/Atomos/Icons/Close";
import useViewport from "../../../hooks/useViewPort";

interface IMainBoxProps {
  name: string;
  state: any;
  professionalsInPrimary: string;
  professionalsWorldwide: string;
  membershipType: string;
  jurisdiction: string;
  firmProfessionalsInPrimary: string;
  firmProfessionalsWorldWide: string;
}

const MembershipCategory: React.FC<IMainBoxProps> = ({ ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [firmCountPrimary, setFirmCountPrimary] = useState(
    props.firmProfessionalsInPrimary ? props.firmProfessionalsInPrimary : "0000"
  );
  const [firmCountWorldwide, setFirmCountWorldwide] = useState(
    props.firmProfessionalsWorldWide ? props.firmProfessionalsWorldWide : "0000"
  );
  const { viewportWidth } = useViewport();

  useEffect(() => {
    const storedFirmCountPrimary = localStorage.getItem("firmCountPrimary");
    const storedFirmCountWorldwide = localStorage.getItem("firmCountWorldwide");
    if (storedFirmCountPrimary) setFirmCountPrimary(storedFirmCountPrimary);
    if (storedFirmCountWorldwide) setFirmCountWorldwide(storedFirmCountWorldwide);
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const updateFirmCount = () => {
    const firm = {
      ...props.state,
      firmProfessionalsInPrimary: firmCountPrimary,
      firmProfessionalsWorldWide: firmCountWorldwide,
      address: props.state.addressId,
      jurisdiction: props.state.jurisdictionId,
      jurisdictionGroup: props.state.jurisdictionGroupId,
    };
    updateFirm(firm);
    localStorage.setItem("firmCountWorldwide", firmCountWorldwide);
    localStorage.setItem("firmCountPrimary", firmCountPrimary);
    setIsEditing(false);
  };

  return (
    <div className="flex flex-col gap-10 justify-end">
      <article className="articleSection relative">
        <section className="flex w-full flex-col lg:gap-6 gap-7">
          <CatalogSubTitle
            title={"Membership Category Professional Count"}
            className="text-Default lg:text-[32px] md:text-[28px] text-[26px] not-italic font-medium lg:leading-9 md:leading-[44px] leading-normal"
          />
          <div className="flex flex-1 ">
            <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
          </div>
          <div className="grid w-full grid-cols-1 gap-5">
            <div className="flex flex-col lg:flex-row lg:gap-6 gap-7 lg:items-center">
              <div className="flex flex-col flex-1 gap-5">
                <span className="text-gray-font lg:text-[22px] md:text-xl text-lg not-italic font-bold leading-normal">
                  {"WSG Database "}
                </span>
                <div className="flex gap-5">
                  <div>
                    <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                      {props.membershipType === "Primary"
                        ? "Professionals in " + props.jurisdiction + ": "
                        : "In All Jurisdictions: "}
                    </span>
                    <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                      {props.professionalsInPrimary === ""
                        ? "0000"
                        : props.professionalsInPrimary}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                      {"Worldwide: "}
                    </span>
                    <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                      {props.professionalsWorldwide === ""
                        ? "0000"
                        : props.professionalsWorldwide}
                    </span>
                  </div>
                </div>
              </div>
              {viewportWidth > 1023 ? (
                <div className="flex">
                  <VectorUp className="w-0 stroke-gray-50 stroke-1" />
                </div>
              ) : (
                <div className="flex flex-1 ">
                  <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
                </div>
              )}
              {isEditing ? (
                <div className="flex flex-col flex-1 gap-5">
                  <div className="flex flex-row gap-2.5 items-center self-stretch">
                    <span className="text-gray-font lg:text-[22px] md:text-xl text-lg not-italic font-bold leading-normal">
                      {"Firm Count "}
                    </span>
                    <div onClick={updateFirmCount} className="cursor-pointer">
                      <div className="fill-Default w-5 h-5">
                        {" "}
                        <SaveChanges />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-[auto_1fr] gap-5">
                    <div className="flex items-center">
                      <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                        {props.membershipType === "Primary"
                          ? "Professionals in " + props.jurisdiction + ": "
                          : "In All Jurisdictions: "}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="text"
                        value={firmCountPrimary}
                        onChange={(e) =>
                          handleInputChange(e, setFirmCountPrimary)
                        }
                        className="max-w-[290px] text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5 border-solid border-[1px] bg-gray-25 border-gray-25 py-2.5 pl-4 pr-2.5"
                      />
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                        {"Worldwide: "}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="text"
                        value={firmCountWorldwide}
                        onChange={(e) =>
                          handleInputChange(e, setFirmCountWorldwide)
                        }
                        className="max-w-[290px] text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5 border-solid border-[1px] bg-gray-25 border-gray-25 py-2.5 pl-4 pr-2.5"
                      />
                    </div>
                  </div>
                  <NewButton
                    onClick={() => {
                      setIsEditing(false);
                      setFirmCountPrimary(localStorage.getItem("firmCountPrimary") ?? props.firmProfessionalsInPrimary ?? "");
                      setFirmCountWorldwide(localStorage.getItem("firmCountWorldwide") ?? props.firmProfessionalsWorldWide ?? "");
                    }}
                    text={"Cancel"}
                    className="!max-h-[35px] !max-w-[45px]"
                    icon={<Close />}
                    color="neutral"
                    content="textIcon"
                    size="medium"
                    style="outlined"
                  />
                </div>
              ) : (
                <div className="flex flex-col flex-1 gap-5">
                  <div className="flex flex-row gap-2.5 items-center self-stretch">
                    <span className="text-gray-font lg:text-[22px] md:text-xl text-lg not-italic font-bold leading-normal">
                      {"Firm Count "}
                    </span>
                    <div onClick={handleEditToggle} className="cursor-pointer">
                      <PencilEdit className="fill-Default w-5 h-5" />
                    </div>
                  </div>
                  <div
                    className={`flex gap-5 ${isEditing ? "flex-col self-stretch items-start" : ""}`}
                  >
                    <div>
                      <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                        {props.membershipType === "Primary"
                          ? "Professionals in " + props.jurisdiction + ": "
                          : "In All Jurisdictions: "}
                      </span>
                      <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                        {firmCountPrimary}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                        {"Worldwide: "}
                      </span>
                      <span className="text-gray-font lg:text-lg text-base not-italic font-medium leading-[18px] lg:leading-5">
                        {firmCountWorldwide}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </article>
    </div>
  );
};

export default MembershipCategory;
