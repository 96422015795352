import React from "react";

function Lock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M7.293 44.65V15.509h6.896V11.33c0-2.748.953-5.085 2.858-7.01 1.906-1.924 4.223-2.886 6.951-2.886 2.729 0 5.047.962 6.953 2.887 1.907 1.924 2.86 4.26 2.86 7.009v4.178h6.924V44.65H7.293zm16.715-10.706c1.062 0 1.967-.368 2.717-1.102.75-.734 1.125-1.617 1.125-2.648 0-1-.378-1.909-1.133-2.726-.756-.816-1.664-1.224-2.725-1.224-1.062 0-1.967.408-2.717 1.224-.75.817-1.125 1.734-1.125 2.75 0 1.017.378 1.892 1.133 2.625.756.734 1.664 1.1 2.725 1.1zM18.15 15.509h11.7v-4.172c0-1.666-.563-3.067-1.69-4.206-1.126-1.138-2.509-1.707-4.147-1.707-1.64 0-3.026.57-4.16 1.707-1.136 1.139-1.703 2.54-1.703 4.206v4.172z"
      ></path>
    </svg>
  );
}

export default Lock;
