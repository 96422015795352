import React, { ChangeEvent, useEffect, useState } from 'react';
import DDMultiple, { Option } from '../../../Atomos/DropDown/DDMultiple';
import Input from '../../../Atomos/Inputs/Input';
import CatalogFilterButtons from '../../CatalogFilterButtons';
import DropDownList from '../../../Atomos/DropDown/Dropdown';
import { DayPicker } from 'react-day-picker';
import CalendarInput from '../../../Atomos/Inputs/CalendarInput';

interface IActivityLogFilterProps {
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  onAccessFilterChange: (newAccessFilter: string) => void
  search: () => void;
  resetFilters: () => void;
  headers: any;
  tableInstance?: any;
  accessFilter?: string;
  publishedDate: string
  setPublishedDate: React.Dispatch<React.SetStateAction<string>>
  firmData: any;
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedFirm: Option[];
}

const ActivityLogFilter: React.FC<IActivityLogFilterProps> = ({ nameFilter,
  accessFilter,
  onNameFilterChange,
  tableInstance,
  search,
  resetFilters,
  headers,
  onAccessFilterChange,
  publishedDate,
  setPublishedDate,
  firmData,
  selectedFirm,
  setSelectedFirm,
}) => {

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const accessOptions: Option[] = [
    { id: 'DataAdmin', name: 'Data Admin' },
    { id: 'WSGStaff', name: 'WSG Staff' },
    { id: 'WSGStaffSuper', name: 'WSG Staff Super' },
    { id: 'Member', name: 'Member' }
  ];

  useEffect(() => {
    if (accessFilter) {
      const selected = accessOptions.filter(option => option.name === accessFilter);
      setSelectedOptions(selected);
    }
  }, [accessFilter]);

  return (
    <>
      <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:flex-1 lg:flex lg:flex-row
      border border-gray-100'>
        <div className='w-full'>
          <Input
            type="text"
            value={nameFilter}
            onChange={(e) => onNameFilterChange(e.target.value)}
            error={false}
            title='By Name'
            isWfull
          />
        </div>
        <div className="items-center w-full">
          <DDMultiple
            title="By Firm"
            objects={firmData}
            selectedObjst={selectedFirm}
            seter={(e) => setSelectedFirm(e)}
            error={false}
            required={false}
          />
        </div>
        <div className="items-center w-full">
          <DropDownList
            title="Access"
            seter={onAccessFilterChange}
            data={accessOptions}
            selectedObjst={accessFilter}
            error={false}
            required={false}
            getid={false}
          />
        </div>
        <div className='w-full'>
          <CalendarInput title={"By Published Date:"} error={false} type={'range'} value={publishedDate} onChange={(e: any) => setPublishedDate(e)} />
        </div>
        <div className='flex flex-row gap-2 w-full md:justify-end lg:w-max md:col-span-2 md:max-w-[17rem] md:ml-auto'>
          <CatalogFilterButtons onClickSearch={search} handleFormReset={resetFilters} headers={headers} tableInstance={tableInstance} />
        </div>
      </div>
    </>
  );
};

export default ActivityLogFilter;
