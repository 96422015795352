import { Combobox } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import { useAppContext } from "../../../Context";
import Close from "../../Atomos/Icons/Close";
import "../../../assets/css/Text.css";
import NewTooltip from "../Tooltip/NewTooltip"; 

interface InputProps {
  title: string;
  data: any[];
  selectedObjst: string | undefined;
  seter: (name: any) => void;
  error: boolean;
  errormsj?: string;
  tooltip?: string;
  disabled?: boolean;
  required?: boolean;
  getid?: boolean;
  mode?: "member" | "staff";
  name?: string;
  placeholder?: string;
  dynamic?: boolean;
  generalTab?: boolean;
  disableInput?: boolean; // Nueva prop para deshabilitar el input
}

const DropDownList: React.FC<InputProps> = ({
  getid,
  required,
  title,
  data,
  seter,
  selectedObjst,
  error,
  errormsj,
  tooltip,
  disabled,
  name,
  placeholder,
  generalTab,
  dynamic,
  disableInput,
}) => {
  const { mode } = useAppContext();

  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  const [dataQuery, setDataQuery] = useState('');
  const filteredAndSortedObjects = dataQuery === ''
  ? data
  : data?.filter((object) => {
      if (object.name) {
        return object.name.toLowerCase().includes(dataQuery.toLowerCase());
      } else {
        return 'Override';
      }
    })
    ?.sort((a, b) => {
      const nameA = a.name?.toLowerCase() || '';
      const nameB = b.name?.toLowerCase() || '';
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0
    });

  const handleClearSelection = () => {
    if (!disabled) {
      if (getid) {
        seter({ id: "", name: "" });
      } else {
        seter(null);
      }
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState<number>(0);

  // if data-open is true, the dropdown is open i want to clear the filter if the dropdown is open

  useEffect(() => {
    const updateInputWidth = () => {
      if (inputRef.current) {
        setInputWidth(inputRef.current.offsetWidth);
      }
    };

    const updateWindowDimensions = () => {
      setWindowWidth(window.outerWidth);
    };

    updateInputWidth();

    window.addEventListener("resize", updateInputWidth);
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateInputWidth);
      window.addEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (inputWidth < 103) {
      inputRef.current?.setAttribute("placeholder", "");
    } else {
      inputRef.current?.setAttribute(
        "placeholder",
        placeholder ? placeholder : "Select One"
      );
    }
  }, [inputWidth]);

  return (
    <div
      className={`flex flex-col gap-2.5 h-max w-full ${error ? "bottom-0 relative" : ""}`}
    >
      <div className="flex gap-2.5 w-full">
        {mode !== "member" ? (
          <span
            className={`${generalTab ? "text-gray-900 font-bold lg:text-[22px] md:text-[20px] text-lg leading-normal md:leading-5 lg:leading-normal" : `inputLabel labelInput ${disabled ? "text-gray-200" : !required ? "text-gray-font" : "text-primary"}` }`}
          >
            {title}
          </span>
        ) : (
          <span
            className={`inputLabel ${disabled ? "text-gray-200" : "text-gray-font"} ${generalTab ? "!font-bold" : ""}`}
          >
            {title}{" "}
            <span
              className={`${required ? (disabled ? "text-gray-200" : "text-feedback-error") : "hidden"}`}
            >
              *
            </span>
          </span>
        )}
        {tooltip && (
          <NewTooltip text={tooltip} size={windowWidth} dynamic={dynamic} />
        )}
      </div>

      <Combobox value={selectedObjst} onChange={seter} disabled={disabled}>
        {({ open }) => (
          <div className="relative">
            <div
              className={`relative  ${error && "outline outline-1 outline-feedback-error outline-offset-0 !border-none"}`}
            >
               
                <div className="flex flex-row">
                  <Combobox.Input
                    ref={inputRef}
                    onFocus={() => setDataQuery("")}
                    className={``}
                    onChange={(event) =>
                      setDataQuery(event.currentTarget.value)
                    }
                    placeholder={`Select One`}
                    name={name ? name : title}
                    readOnly={disableInput} // Agregar readOnly cuando disableInput es true
                  />
                  {selectedObjst && (
                    <div
                      className={`absolute top-3 right-10 h-5 w-5 cursor-pointer ${disabled ? "fill-gray-300 pointer-events-none" : "fill-gray-font"}`}
                      onClick={handleClearSelection}
                    >
                      <Close />
                    </div>
                  )}
                </div>

                <div>
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <div
                      id="chevron-up"
                      className={`w-6 h-6 ${open && "rotate-180 duration-75"} ${disabled ? "fill-gray-300" : "fill-gray-font"}`}
                    >
                      <ChevronDown />
                    </div>
                  </Combobox.Button>
                </div> 
            </div>

            <Combobox.Options className={`absolute top-14 w-full max-h-48 z-30 no-scrollbar overflow-scroll ${!open && 'hidden'}`}>
              {filteredAndSortedObjects?.map((object: any) => (
                <Combobox.Option key={object.id} value={getid ? object : object.name ? object.name : 'Override'} className={'p-3 text-base leading-[19px] text-neutral-dark bg-white hover:bg-gray-50'}>
                  {object.name ? object.name : 'Override'}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        )}
      </Combobox>
      {error && errormsj !== "" ? (
        <span className="false flex flex-wrap items-center false text-sm font-medium text-feedback-error">
          {errormsj}
        </span>
      ) : null}
    </div>
  );
};

export default DropDownList;
