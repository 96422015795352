import React from "react";



interface TotalCounterProps {
  title: string;
  total: number;
}

const TotalCounter: React.FC<TotalCounterProps> = ({title, total}) => {
  return ( 
    <>
    <div className="flex flex-col items-start flex-1">
      <div className="flex h-[3.5rem] py-3 px-4 justify-center items-center gap-[.625rem] self-stretch bg-[#0070CD]">
        <span className="text-center text-white text-[.75rem] font-medium lg:text-[.875rem]">{title}</span>
      </div>
      <div className="flex p-4 justify-center items-center gap-[.625rem] self-stretch bg-white">
        <span className="font-decimal text-[#2C2C2C] text-[1.5rem] md:text-[1.75rem] md:leading-[2rem] font-medium leading-[1.75rem] lg:text-[2rem] lg:leading-[2.25rem]">{total}</span>
      </div>

    </div>
    </>
   );
}
 
export default TotalCounter;