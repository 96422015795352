import { IRepresentativeClient } from "../interfaces/models/IRepresentativeClient";
import Axios from "../utils/axios";


const axios = new Axios()

export const getRepresentativeClients = async () => {
  try {
    const representativeClients = await axios.Get('./representativeClient');
    const data = representativeClients.data;
    if (data != null) {
        return representativeClients.data.map((representativeClient: IRepresentativeClient) => ({ name: representativeClient.name, firm: representativeClient.firm, office: representativeClient.office, logo: representativeClient.logo, id: representativeClient.id }));
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }  
  return [];  
}

export const newRepresentativeClient = async (newClient: IRepresentativeClient): Promise<IRepresentativeClient | null> => {
  try {
    const response = await axios.Post('./representativeClient', newClient);
    return response.data;
  } catch (error) {
    console.error('Error creating representative client:', error);
    return null;
  }
};

export const updateRepresentativeClient = async (updatedData: Partial<IRepresentativeClient>): Promise<IRepresentativeClient | null> => {
  try {
    const response = await axios.Put(`./representativeClient`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating representative client:', error);
    return null;
  }
};

export const deleteRepresentativeClient = async (id: string): Promise<boolean> => {
  try {
    await axios.Delete(`./representativeClient`,`${id}`);
    return true;
  } catch (error) {
    console.error('Error deleting representative client:', error);
    return false;
  }
};