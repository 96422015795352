import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LegacyRedirection } from "../../Enums/LegacyRedirection";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loaderActions";

const GroupsPage = () => {
	const iframeRef = useRef(null);
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if(!iframeRef.current) return;
		dispatch(showLoader());
		const iframe = iframeRef.current as HTMLIFrameElement;
		let path = location.pathname.toLowerCase();
		const legacyPath = (LegacyRedirection as any)[path];
		iframe.src = legacyPath;

		iframe.onload = () => { dispatch(hideLoader()) };
		iframe.onerror = () => { dispatch(hideLoader()) };
	}, [location.pathname]);

  return (
    <>
      <div className="min-h-screen w-full flex items-center align-middle justify-center">
        <iframe ref={iframeRef} className="min-h-screen w-full" title="Legacy"></iframe>
      </div>
    </>
  );
};

export default GroupsPage;
