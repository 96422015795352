import React from "react";

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32"
    >
     
        <path 
          d="M27.474 30.66l-3.886-3.889H8.267c-2.069 0-3.813-.7-5.235-2.099C1.611 23.272.9 21.54.9 19.471c0-1.815.574-3.367 1.72-4.655 1.148-1.288 2.585-2.072 4.312-2.35.02-.25.066-.561.141-.938a2.79 2.79 0 01.36-.956L1.92 5.058l1.57-1.532 25.569 25.57-1.584 1.565zm-19.2-6.53H21.01L9.707 12.828a2.147 2.147 0 00-.452.99c-.069.378-.103.743-.103 1.094h-.885c-1.313 0-2.428.42-3.348 1.263-.919.842-1.378 1.916-1.378 3.224 0 1.308.46 2.424 1.378 3.347.92.923 2.038 1.384 3.356 1.384zm20.494 1.495l-2.019-2.038c.543-.378.964-.783 1.263-1.215.298-.432.447-.957.447-1.575 0-.884-.326-1.647-.98-2.289-.653-.642-1.424-.963-2.312-.963h-2.441v-2.907c0-1.89-.657-3.478-1.97-4.764-1.314-1.286-2.914-1.93-4.8-1.93-.638 0-1.3.104-1.984.31a6.236 6.236 0 00-1.87.92l-1.87-1.871c.8-.67 1.702-1.17 2.706-1.502a9.528 9.528 0 013.001-.497c2.507 0 4.677.884 6.51 2.652 1.832 1.769 2.798 3.905 2.899 6.41v.7c1.625.09 2.99.672 4.095 1.745 1.104 1.073 1.657 2.417 1.657 4.033 0 .84-.202 1.721-.607 2.646-.404.925-.98 1.636-1.725 2.135z"
        ></path> 
    </svg>
  );
}

export default ErrorIcon;
