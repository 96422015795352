import React, { useCallback, useEffect, useRef, useState } from "react";
import NewButton from "../../Atomos/Buttons/newButton";
import { addSpacesBetweenUppercase } from "../../../utils/String"; 
import Gear from "../../Atomos/Icons/Gear";  
import WSGCheckbox from "../../Atomos/Checkbox/WSGCheckBox";

interface VisibleColumnsProps {
  pageHeaders: string[];
  className?: string;
  resetSignal?: number;
}

const VisibleColumns: React.FC<VisibleColumnsProps> = ({ className, pageHeaders, resetSignal }) => {
  const [dropdown, setDropdown] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<{ Column: string; visible: boolean; }[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setDropdown(!dropdown);
  }

  useEffect(() => {
    const initialVisibleColumns = pageHeaders.map(header => ({ Column: header, visible: true }));
    setVisibleColumns(initialVisibleColumns);
  }, [pageHeaders, resetSignal]);
  
  const handleCheckboxChange = useCallback((index: number) => {
    setVisibleColumns((current) =>
      current.map((column, i) => (i === index ? { ...column, visible: !column.visible } : column)),
    );
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdown(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, handleClickOutside]);

  useEffect(() => {
    visibleColumns.forEach(column => {
      const thElement = document.querySelector(`.th-${column.Column}`);
      const tdElements = document.querySelectorAll(`.td-${column.Column}`);
  
      if (!column.visible) {
        thElement?.classList.add('hidden');
        tdElements.forEach(element => element.classList.add('hidden'));
      } else {
        thElement?.classList.remove('hidden');
        tdElements.forEach(element => element.classList.remove('hidden'));
      }
    });
  }, [visibleColumns]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <NewButton
        onClick={handleToggle}
        icon={<Gear />} 
        content="iconSquare" 
        size="large" 
        color="primary" 
        style="filled"   
        className="!w-full f !border-1 !border-accentDefault" 
      /> 
      <div className={`absolute w-max ${className} ${dropdown ? `opacity-1 visible scale-[100%] duration-0` : `opacity-0 invisible scale-95 duration-200`} transform ease-out transition-opacity duration-200 transition-delay-200 z-10 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <span className="text-sm w-full text-justify font-medium block py-[0.625rem] pl-4 pr-3 lg:py-[0.81rem] lg:pl-5 lg:pr-4">Columns</span>
        {visibleColumns.map((column, index) => (
          <WSGCheckbox key={index} handleCheckboxChange={() => handleCheckboxChange(index)} text={column.Column === '_ts' ? 'Created Date' : addSpacesBetweenUppercase(column.Column)}
            index={index} checked={column.visible} />
        ))}
      </div>
    </div>
  )
};

export default VisibleColumns;
