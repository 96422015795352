import React from "react"; 
const Facebook: React.FC  = ( ) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M13.871 1.185v2.786h-1.648c-.607 0-1.017.126-1.232.379-.214.252-.322.631-.322 1.136v2.009h3.107l-.416 3.126h-2.69v8.034H7.43V10.62H4.738V7.495h2.69V5.183c0-1.313.366-2.33 1.1-3.05.732-.72 1.71-1.08 2.936-1.08 1.036 0 1.838.044 2.406.132z"></path>
    </svg>
  );
}

export default Facebook;
