import React, { HtmlHTMLAttributes, useEffect } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import Input from "../../Atomos/Inputs/Input";
import CatalogFilterButtonsV2 from "../CatalogFilterButtonsv2";
import { Table } from "@tanstack/react-table";
import { getStatesListOptions } from "../../../Services/State";
import { UseCountryList } from "../../../hooks/Catalogs";
import { UseRegionList } from "../../../hooks/Catalogs/useRegionListOptions";
import CatalogFilterButtons from "../CatalogFilterButtons";
import { useStatesList } from "../../../hooks/Catalogs/useStates";

interface IStateFilterProps {
  tableInstance: any;
  headers: string[];
  filters: {
    name: string;
    state: Option[];
    countries: Option[];
    regions: Option[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      name: string;
      state: Option[];
      countries: Option[];
      regions: Option[];
    }>
  >;
  clearSelections: () => void; 
  Search: () => void;
}

const StateFilter: React.FC<IStateFilterProps> = ({ 
  tableInstance, 
  filters,
  Search,
  setFilters,
  clearSelections,
  headers
}) => {

  const [stateData, setStateData] = React.useState<Option[]>([]);

  const {countryData} = UseCountryList();
  const {statesData} = useStatesList();
  const {regionData} = UseRegionList();

  useEffect(() => { 
    if (filters.countries.length || statesData.length) {
      const states = statesData.filter(s => filters.countries.map(c => c.id).includes(s.country))
        setStateData(states)
      } else {
      setStateData([])
    }
      
  }, [filters.countries, statesData]);

  const handleSelectCountry = (selected: Option[]) => {
    setFilters({ ...filters, countries: selected as Option[] });
  };

  const handleSelectRegion = (selected: Option[]) => {
    setFilters({ ...filters, regions: selected as Option[] });
  };

  const handleSelectState = (selected: Option[]) => {
    setFilters({ ...filters, state: selected as Option[] });
  };

  const handlerText = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <div className="grid grid-cols-12 gap-5 w-full border border-gray-100 p-4">
      <div className="col-span-12 md:col-span-6 lg:col-span-3 ">
        <Input
          name="name"
          title="By Name:"
          value={filters.name}
          onChange={ (e)=> handlerText(e) }
          error={false}
          required={false}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By Region:"
          objects={regionData}
          selectedObjst={filters.regions}
          seter={(e: any) => handleSelectRegion(e)}
        />
      </div>
      <div className="col-span-12  md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By Country:"
          objects={countryData}
          selectedObjst={filters.countries}
          seter={(e: any) => handleSelectCountry(e)}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By State:"
          objects={stateData}
          selectedObjst={filters.state}
          disabled={filters.countries.length === 0}
          tooltip={filters.countries.length !== 0?"":"Please select a Country"}
          seter={(e: any) => handleSelectState(e)}
        />
      </div>

      <div className="col-span-12  w-full flex justify-end ">
        <div className="w-full md:max-w-min ">
          <CatalogFilterButtons
          headers={headers}
            onClickSearch={Search}
            handleFormReset={clearSelections} 
            tableInstance={tableInstance}
          />
        </div>
      </div>
    </div>
  );
};

export default StateFilter;
