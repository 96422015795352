import React from "react";

function ChevronRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 12 12"
      width='100%'
      height='100%'
    >
      <path 
        d="M6.83 6.092L3.5 2.762 4.263 2l4.092 4.092-4.092 4.093-.763-.763 3.33-3.33z"
      ></path>
    </svg>
  );
}

export default ChevronRight;
