import React from 'react';
import CatalogFilterButtons from '../../CatalogFilterButtons';
import Input from '../../../Atomos/Inputs/Input';

interface BaseFilterProps {
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  search: () => void;
  resetFilters: () => void;
  headers: any;
  tableInstance?: any;
  title: string;
}

const BaseFilter: React.FC<BaseFilterProps> = ({
  onNameFilterChange,
  nameFilter,
  search,
  resetFilters,
  headers,
  tableInstance,
  title,
}) => {
  return (
    <div className='flex flex-col self-stretch w-full formsection md:flex-row md:items-end justify-end border border-gray-100 p-4'>
      <div className='flex flex-col gap-[.625rem] w-full self-stretch'>
        <Input
          type="text"
          value={nameFilter}
          onChange={(e) => onNameFilterChange(e.target.value)}
          error={false}
          title={title}
        />
      </div>
      <div className={`flex`}>
        <CatalogFilterButtons tableInstance={tableInstance} onClickSearch={search} handleFormReset={resetFilters} headers={headers} />
      </div>
    </div>
  );
};

export default BaseFilter;
