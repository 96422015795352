import React, { useEffect, useState } from "react";
import "../../../assets/css/Calendar.css";
import "../../../assets/css/Text.css";
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from "react-day-picker";
import Input from "./Input";
import NewButton from "../Buttons/newButton";
import Close from "../Icons/Close";
import { formatDateToString } from "../../../utils/functions";

interface InputProps {
  title: string;
  errorMsj?: string;
  startDate?: Date;
  endDate?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error: boolean;
  value?: string;
  defaultValue?: string;
  type: "single" | "range";
  selectDate?: SelectRangeEventHandler | undefined;
  onChange: (e: any) => void;
  tooltip?: string;
}

const CalendarInput: React.FC<InputProps> = (props) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [selected, setSelected] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>();
  const [appliedDate, setAppliedDate] = useState<Date>();
  const [appliedDateRange, setAppliedDateRange] = useState<DateRange>();
  const [value, setValue] = useState<string>("");

  const parseDateString = (dateString: string): Date | undefined => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? undefined : date;
  };

  useEffect(() => {
    if (props.type === "single" && props.value) {
      const defaultDate = parseDateString(props.value);
      if (defaultDate) {
        setSelected(defaultDate);
        setSelectedDate(defaultDate);
        setAppliedDate(defaultDate);
      }
    } else if (props.type === "range" && props.value) {
      const [fromString, toString] = props.value.split(" - ");

      const fromDate = parseDateString(fromString);
      const toDate = parseDateString(toString);
      if (fromDate && toDate) {
        setSelectedDateRange({ from: fromDate, to: toDate });
        setAppliedDateRange({ from: fromDate, to: toDate });
      }
    }
  }, [props.value, props.type]);

  useEffect(() => {
    if (!focus && (!value || value === "")) {
      setSelected(undefined);
      setSelectedDate(new Date());
      setSelectedDateRange({ from: undefined, to: undefined });
    }
  }, [focus, appliedDate, appliedDateRange, value]);

  let footer = (
    <div className="flex flex-row formsection items-center p-2">
      <NewButton
        onClick={() => setFocus(false)}
        className="flex-1 !pt-[16px]"
        icon={<Close />}
        color="neutral"
        content="iconSquare"
        size="large"
        style="ghost"
      />
      <NewButton
        text="Today"
        className="!text-lg"
        onClick={() => {
          setSelected(new Date());
          setSelectedDate(new Date());
          setSelectedDateRange({
            from: new Date(),
            to: undefined,
          });
        }}
        size="medium"
        style="ghost"
        color="primary"
      />
      <NewButton
        text="apply"
        className="!text-lg"
        onClick={() => {
          setAppliedDate(selected);
          setAppliedDateRange(selectedDateRange);
          setFocus(false);
        }}
        size="medium"
        style="ghost"
        color="accent"
      />
    </div>
  );

  useEffect(() => {
    let stringAux: string = "";
    if (props.type === "single") {
      if (appliedDate)
        stringAux = stringAux.concat(`${formatDateToString(appliedDate)}`);
      props.onChange(stringAux);
      setValue(stringAux);
    } else if (props.type === "range") {
      if (!!appliedDateRange?.from) 
        stringAux = `${formatDateToString(appliedDateRange?.from)} - `;
      if (!!appliedDateRange?.to) 
        stringAux = stringAux.concat(formatDateToString(appliedDateRange?.to));

      props.onChange(stringAux);
      setValue(stringAux);
    }
  }, [appliedDateRange, appliedDate]);

  useEffect(() => {
    if (props.value === "") {
      setValue("");
    }
  }, [props.value]);

  useEffect(() => {
    if (props.defaultValue) {
      setAppliedDate(new Date(props.defaultValue));
    }
  }, [props.defaultValue]);

  return (
    <div
      className={`flex flex-col gap-[.625rem] self-stretch items-start w-full relative `}
    >
      <Input
        title={props.title}
        required={props.required}
        error={props.error}
        placeholder={"MM/DD/YYYY"}
        onClick={() => setFocus(!focus)}
        errorMsj={props.errorMsj}
        disabled={props.disabled}
        value={value}
        tooltip={props.tooltip}
      />
      {focus && props.type === "single" && (
        <div className="absolute right-0 top-20 bg-neutral z-10 ">
          <DayPicker
            mode="single"
            footer={footer}
            selected={selected}
            onSelect={setSelected}
            month={selectedDate}
            onMonthChange={setSelectedDate}
            fixedWeeks
            captionLayout="dropdown-buttons"
            fromYear={1999}
            toYear={2100}
          />
        </div>
      )}
      {focus && props.type === "range" && (
        <div className="absolute top-20 right-0 bg-neutral z-10 ">
          <DayPicker
            mode="range"
            footer={footer}
            selected={selectedDateRange}
            onSelect={setSelectedDateRange}
            month={selected}
            onMonthChange={setSelected}
            captionLayout="dropdown-buttons"
            fromYear={1999}
            toYear={2100}
            fixedWeeks
          />
        </div>
      )}
    </div>
  );
};

export default CalendarInput;

// Arrow Top - move to the previous week
// Arrow Right - move to the next day
// Arrow Bottom - move to the next week
// Arrow Left - move to the previous day
// Page Up - move to the previous month
// Page Down - move to the next month
// Shift + Page Up - move to the previous year
// Shift + Page Down - move to the next year
// Home - move to the start of the week
// End - move to the end of the week
