function ErrorCheck(props: any) {
  return ( 
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
           
                  <path d="M8.68758 13.7083L14.9376 7.43751L13.6251 6.10418L8.68758 11.0417L6.39592 8.75001L5.08342 10.0833L8.68758 13.7083ZM1.97925 18.0208V1.95834H18.0417V18.0208H1.97925ZM3.93758 16.0625H16.0626V3.93751H3.93758V16.0625Z"   />
             
          </svg>
     );
}

export default ErrorCheck;