// DraggableHeader.tsx
import React, { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CustomShuffle from '../../Atomos/Icons/CustomShuffle';
import SortDefault from '../../Atomos/Icons/SortDefault';
import SortAscending from '../../Atomos/Icons/SortAsending';
import SortDescending from '../../Atomos/Icons/SortDecending';

interface DraggableHeaderProps {
  columnId: string;
  onSort: (columnId: string) => void;
  children: React.ReactNode;
  disableSort?: boolean;
  sortOrder?: 'asc' | 'desc' | 'none';
}

const DraggableHeader: React.FC<DraggableHeaderProps> = ({ columnId, onSort, children, disableSort = false, sortOrder = 'none' }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: columnId });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const SortIcon = () => {
    const Icon = sortOrder === 'asc' ? SortAscending : sortOrder === 'desc' ? SortDescending : SortDefault;
    return (
      <div className="w-3 h-3 fill-white">
        <Icon />
      </div>
    );
  };
  return (
    <div className='flex items-center'>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <button {...attributes} {...listeners} className='my-auto'>
          <div className='fill-white w-4 h-4 my-auto flex justify-start items-center mr-1'>
            <CustomShuffle />
          </div>
        </button>
        {children}
      </div>
      {!disableSort && (
        <div className='flex w-max ' onClick={() => onSort(columnId)}>
          <SortIcon />
        </div>
      )}
    </div>
  );
};

export default DraggableHeader;
