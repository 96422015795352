import { useNavigate } from "react-router-dom";
import SaveChanges from "../Atomos/Icons/EditMode/SaveChanges";
import { TrashCan } from "../Atomos/Icons/TrashCan";
import Plus2 from "../Atomos/Icons/Plus";
import NewButton from "../Atomos/Buttons/newButton";
import Close from "../Atomos/Icons/Close";
import { useEffect, useState } from "react";

interface IHeaderTitle {
  mode: string;
  id: string;
  onDelete: (id: string) => void; //handleDelete
  hiddenDelete?: boolean;
  actionButton: (buttonClicked: string) => void; //handleButtonClick\
  disabled: boolean;
  Catalog: string;
  deleteOff?: boolean;
  title?: string;
  member?: boolean;
}

const ButtonsCrud: React.FC<IHeaderTitle> = (props) => {
  const navigate = useNavigate();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {props.mode === "add" && (
        <div className="w-full gap-4 flex flex-wrap items-start justify-end md:justify-end self-stretch shrink-0 relative">
          <div className="flex w-full sm:w-auto">
            <NewButton
              onClick={() => {
                navigate(-1);
              }}
              text={"Cancel"}
              className="flex-1 md:flex-none !w-auto"
              icon={<Close />}
              color="neutral"
              content="textIcon"
              size="medium"
              style="outlined"
            />
          </div>
          <div className="flex w-full sm:w-auto ">
            <button
              onClick={() => props.actionButton("saveButton")}
              disabled={props.disabled}
              className=" flex-1 h-full  md:w-auto "
              type="submit"
            >
              <NewButton
                text={props.title ? props.title : "Create"}
                icon={<Plus2 />}
                color={props.member ? "primary":"accent"}
                content="textIcon"
                size="medium"
                style="filled"
                disabled={props.disabled}
              />
            </button>
          </div>
        </div>
      )}
      {props.mode === "edit" && (
        <div className="w-full flex flex-wrap gap-4 items-center justify-between shrink-0 relative">
          {!props.hiddenDelete && (
            <div className="flex w-auto">
              <button
                onClick={() => {
                  props.id !== undefined
                    ? props.onDelete(props.id)
                    : console.log("id is undefined");
                }}
              >
                <NewButton
                  text="Delete"
                  icon={<TrashCan />}
                  color="delete"
                  content={viewportWidth < 640 ? "iconSquare" : "textIcon"}
                  size="medium"
                  style="outlined"
                />
              </button>
            </div>
          )}

          <div className="flex flex-grow sm:grow-0 sm:ml-auto">
            <NewButton
              onClick={() => {
                navigate(-1);
              }}
              className="flex-1"
              text={"Cancel"}
              icon={<Close />}
              color="neutral"
              content="textIcon"
              size="medium"
              style="outlined"
            />
          </div>
          <div className={`flex w-full sm:w-auto`}>
            <button
              onClick={() => props.actionButton("updateButton")}
              disabled={props.disabled}
              type="submit"
              className="flex-1"
            >
              <NewButton
                text={"Save"}
                icon={<SaveChanges />}
                color={props.member ? "primary":"accent"}
                content="textIcon"
                size="medium"
                style="filled"
                disabled={props.disabled}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ButtonsCrud;
