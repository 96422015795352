import DDMultiple, { Option } from "../../../components/Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../../../components/Catalogs/CatalogFilterButtons";

export type FilterComponentKey = 'name' | 'practiceIndustries';

interface IAopManagementProps {
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  practiceIndustriesData: any[];
  selectedPracticeIndustries: Option[];
  setSelectedPracticeIndustries: React.Dispatch<React.SetStateAction<Option[]>>;
  order: FilterComponentKey[];
  search: () => void;
  resetFilters: () => void;
  headers: any;
  tableInstance: any;

}


const AopManagementFilter: React.FC<IAopManagementProps> = ({
  nameFilter,
  onNameFilterChange,
  practiceIndustriesData,
  selectedPracticeIndustries,
  setSelectedPracticeIndustries,
  order,
  search,
  resetFilters,
  headers,
  tableInstance
}) => {


  const filteredOrder = order.filter(key => {
    return true;
  });

  const filterComponents = {
    name: (
      <div className='w-full flex gap-2.5 justify-between flex-col items-end self-stretch'>
        <div className="w-full flex flex-col gap-[.625rem]">
          <label htmlFor="nameFilter" className='inputLabel text-[#474F56]'>By Name:</label>
          <input
            type="text"
            id="nameFilter"
            value={nameFilter}
            onChange={(e) => onNameFilterChange(e.target.value)}
            className="w-full h-auto p-3 bg-[#F4F4F4]"
          />
        </div>
      </div>
    ),
    practiceIndustries: (
      <div className='w-full self-stretch'>
        <DDMultiple
          title="By Practice Industry:"
          objects={practiceIndustriesData}
          selectedObjst={selectedPracticeIndustries}
          seter={setSelectedPracticeIndustries}
        />
      </div>
    ),
  };


  return (
    <div className={`w-full flex flex-col gap-[.625rem] md:grid md:grid-cols-3 items-end`}>
      {filteredOrder.map(key => (
        <div key={key} className='w-full'>
          {filterComponents[key as FilterComponentKey]}
        </div>
      ))}
      <div className={`flex justify-end`}>
        <CatalogFilterButtons onClickSearch={search} handleFormReset={resetFilters} headers={headers} tableInstance={tableInstance} />
      </div>
    </div>
  );
};

export default AopManagementFilter;
