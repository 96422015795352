import React, { useEffect, useRef, useState } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../CatalogFilterButtons";
import Input from "../../Atomos/Inputs/Input";

interface IStateFilterProps {
  firmData: Option[];
  personData: Option[];
  filters: {
    firms: Option[];
    person: string;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      firms: Option[];
      person: string;
    }>
  >;
  clearSelections: () => void;
  headers: string[];
  Search: () => void;
  tableInstance: any;
}

const dataAdminFilter: React.FC<IStateFilterProps> = ({
  firmData,
  filters,
  Search,
  setFilters,
  headers,
  clearSelections,
  tableInstance
}) => {
  const handleSelectFirm = (selected: Option[]) => {
    setFilters({ ...filters, firms: selected });
  };

  const handlerText = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value });
  };

  return (

    <div className="formsection flex w-full flex-wrap lg:flex-nowrap justify-end items-end border border-gray-100 p-4">
      <div className="w-full md:w-[48%] lg:w-[47%] lg:min-w-[108px]">
        <Input
            name="person"
            title="By Person:"
            value={filters?.person}
            onChange={(e) => handlerText(e.target.name, e.target.value)}
            error={false}
            required={false}
        />
      </div>
      <div className="w-full md:w-[48%] lg:w-[47%]">
        <DDMultiple
          title="By Firm:"
          objects={firmData}
          selectedObjst={filters?.firms}
          seter={(e: any) => handleSelectFirm(e)}
        />
      </div>


      <div className="flex justify-end items-end w-full lg:min-w-[250px] md:w-auto">
        <div className="flex-1 flex lg:min-w-[250px]">
          <CatalogFilterButtons
          tableInstance={tableInstance}
            onClickSearch={Search}
            handleFormReset={clearSelections}
            headers={headers}
          />
        </div>
      </div>
    </div>

    
  );
};

export default dataAdminFilter;
