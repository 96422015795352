import React, { ReactNode } from 'react';
import css from './SidebarItem.module.css';
import { Link } from 'react-router-dom';

interface SidebarItemProps {
  image: ReactNode;
  text: string;
  className: string;
  isLast: boolean;
  isFirst: boolean;
  url?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ image, text, className, isLast, isFirst, url }) => {
  return (
    <React.Fragment>
      <Link to={url ? url : ''} className={`flex bg-gray-100 md:my-[.35rem] ${!isLast ? !isFirst ? `grid-cols-2` : '' : ''}
      pl-[.5rem] pr-[.75rem] py-[.5rem]
      transition-all duration-500 hover:cursor-pointer
      items-center self-stretch md:bg-transparent ${className} 
      ${
        isFirst ? `${css.blueAnimation} md:mb-[1rem] text-white pl-3 pr-2 w-full col-span-2 flex-row-reverse justify-between items-center align-middle pt-2 pb-2` 
        : `${css.sidebarPadding} lg:pl-2 lg:pr-3`
      }
      ${isLast ? 'pb-2' : ''}
      `}>
      <li className={`${isFirst ? 'flex-row-reverse justify-between' : ''} w-full flex items-center gap-4 `}>
        <div className='w-5 h-5'>
          {image}
        </div>
        <span className={`text-left ${isFirst ? 'lg:text-sm' : ''} text-xs lg:text-sm font-medium flex`}>
            {text}
          </span>
      </li>
      </Link>
      {!isFirst && !isLast &&
        <hr className='hidden md:flex py-[0.2rem]'/>
      }
    </React.Fragment>
  );
};

export default SidebarItem;
