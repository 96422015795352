import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const personsSlice = createSlice({
  name: "countries",
  initialState: Empty,
  reducers: {
    SetPersons: (_, action) =>  action.payload,
  },
});

export const { SetPersons } = personsSlice.actions;
