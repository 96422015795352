import PencilEdit from "../../Atomos/Icons/PencilEdit";
import DeleteIcon from "../../Atomos/Icons/DeleteIcon";

interface CommentCardProps {
  personName?: string;
  personTitle?: string;
  firm?: string;
  comment?: string;
  date?: string;
  canEdit?: boolean;
}

const CommentCard: React.FC<CommentCardProps> = (props) => {
  return (
    <div>
      <div className="text-Default lg:text-xl lg:leading-7 font-bold self-stretch whitespace-nowrap">
        {props.personName ?? ""}
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-8 flex flex-row flex-grow gap-2">
          <span className="self-stretch">{props.personTitle ?? ""}</span>
          <span>|</span>
          <span className="text-Default self-stretch">{props.firm ?? ""}</span>
          {props.canEdit && (
            <div className="flex items-center">
              <button className="w-6 p-1">
                <PencilEdit className="text-Default fill-current h-full w-full" />
              </button>
              <button className="w-6">
                <DeleteIcon className="text-feedback-error fill-current h-full w-full" />
              </button>
            </div>
          )}
        </div>
        <div className="col-span-12 md:col-span-4 flex justify-end items-center">
          <div className="text-sm text-gray-500">{props.date ?? ""}</div>
        </div>
      </div>
      <div>{props.comment}</div>
    </div>
  );
};

export default CommentCard;
