function Profilepreferences() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 40 40"
    >
      <path 
        d="M24.48 16.72c-1.52 0-2.8.96-3.28 2.32H9.6v2.4h11.6a3.465 3.465 0 003.28 2.32c1.52 0 2.8-.96 3.28-2.32h2.64v-2.4h-2.64a3.465 3.465 0 00-3.28-2.32zm0 4.64c-.64 0-1.12-.48-1.12-1.12 0-.64.48-1.12 1.12-1.12.64 0 1.12.48 1.12 1.12 0 .64-.48 1.12-1.12 1.12zM18.8 12.64a3.465 3.465 0 00-3.28-2.32c-1.52 0-2.8.96-3.28 2.32H9.6v2.4h2.64a3.465 3.465 0 003.28 2.32c1.52 0 2.8-.96 3.28-2.32h11.6v-2.4H18.8zm-3.28 2.32c-.64 0-1.12-.48-1.12-1.12 0-.64.48-1.12 1.12-1.12.64 0 1.12.48 1.12 1.12 0 .64-.56 1.12-1.12 1.12zM15.52 23.12c-1.52 0-2.8.96-3.28 2.32H9.6v2.4h2.64a3.465 3.465 0 003.28 2.32c1.52 0 2.8-.96 3.28-2.32h11.6v-2.4H18.8c-.56-1.36-1.84-2.32-3.28-2.32zm0 4.64c-.64 0-1.12-.48-1.12-1.12 0-.64.48-1.12 1.12-1.12.64 0 1.12.48 1.12 1.12-.08.56-.56 1.12-1.12 1.12z"
      ></path>
    </svg>
  );
}

export default Profilepreferences;
