import React, { useState } from 'react';
import css from './ProfileGroups.module.css';
import plus from '../../../../assets/images&icons/plus.svg';
import star from '../../../../assets/images&icons/star.svg'; 
import { IButtonIcon } from '../../../../interfaces/Components/Ibutton';


const GroupButton: React.FC<IButtonIcon> = ({ label, isSelected, onClick, svg }) => {
  return (
    <button
      className={`whitespace-nowrap md:min-w-fit py-2 md:px-3 px-6  flex-auto flex items-center md:gap-1 justify-center text-center hover:cursor-pointer ${css.hoverRed} ${isSelected ? css.selected : 'text-neutral-dark'} roboto_font`}
      onClick={onClick}
    >
      <span className='lg:text-sm md:text-[.875rem] text-xs'>{label}</span>
      {isSelected && <img alt="star" src={svg} className="md:ml-0 ml-2"/>}
    </button>
  );
};

const ProfileGroups: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <div className={`lg:w-profileContainer w-11/12 grid mx-auto bg-white 
    ${css.paddingGroups} mt-10 lg:gap-2 relative z-10`
    }>
      <div className={`flex overflow-x-scroll overflow-hidden md:pl-6 md:pr-12 px-6 lg:px-6
       lg:h-10 ${css.groupButtonContainer}`
      }>
      <div className={`flex flex-1`}>
          <GroupButton
            label="My Groups Activity"
            isSelected={selectedTabIndex === 0}
            onClick={() => handleTabClick(0)}
            svg={star}
          />
          <GroupButton
            label="All My Groups"
            isSelected={selectedTabIndex === 1}
            onClick={() => handleTabClick(1)}
            svg={star}
          />
          <GroupButton
            label="My Industry Groups"
            isSelected={selectedTabIndex === 2}
            onClick={() => handleTabClick(2)}
            svg={star}
          />
          <GroupButton
            label="My Speciality Groups"
            isSelected={selectedTabIndex === 3}
            onClick={() => handleTabClick(3)}
            svg={star}
          />
          <GroupButton
            label="My Private Groups"
            isSelected={selectedTabIndex === 4}
            onClick={() => handleTabClick(4)}
            svg={star}
          />
          <GroupButton
            label="My Practice Groups"
            isSelected={selectedTabIndex === 5}
            onClick={() => handleTabClick(5)}
            svg={star}
          />
        </div>
      </div>
      <div className={`md:px-4 lg:px-0 px-2 mt-8 flex flex-col items-start w-full h-64 md:h-72 gap-4 shrink-0 self-stretch ${css.groupDetailContainer}`}>
        <p className='lg:text-sm text-[.875rem] roboto_font font-medium text-[#BABABA] pl-2'>Group Activity</p>
        <span className='lg:text-sm text-[.875rem] text-black roboto_font pl-2 font-medium'>No recent group activity since your last visit.</span>
      </div>
      <hr className='w-full mx-auto lg:border-1 border'/>
      <div className={`flex w-11/12 mx-auto ${css.blueContent} align-middle justify-center `}>
        <div className='w-full ml-auto flex items-center align-middle justify-center cursor-pointer py-6'>
          <span className='uppercase ml-auto flex mr-2 font-semibold h-4'>Join A Group</span>
          <img src={plus} alt="plus" className=''/>
        </div>
      </div>
    </div>
  );
};

export default ProfileGroups;
