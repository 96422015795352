import React from "react";

function PlusBlue() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" >
      <path d="M5.125 8.113l-.471-.471 1.65-1.65-1.65-1.65.471-.472 2.122 2.122-2.122 2.121z"></path>
    </svg>
  );
}

export default PlusBlue;
