import React from "react";
import CatalogFilterButtons from "../../../components/Catalogs/CatalogFilterButtons";
import Input from "../../../components/Atomos/Inputs/Input";
import CalendarInput from "../../../components/Atomos/Inputs/CalendarInput";

interface IContentProps {
  title: string;
  setTitle: (name: string) => void;
  publishedDate: string
  setPublishedDate: React.Dispatch<React.SetStateAction<string>>
  clearSelections: () => void;
  Search: () => void;
}
const ManageFilter: React.FC<IContentProps> = (props) => {
  return (
    <div className="flex formsection flex-col w-full filtersbox mb-5">
      <div className="grid grid-cols-4 formsection lg:grid-cols-7">
        <div className="col-span-4 md:col-span-2 lg:col-span-3">
          <Input
            title="By Title:"
            name="inputName"
            value={props.title}
            onChange={(e: any) => props.setTitle(e.target.value)}
            error={false}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-2">
          <CalendarInput
            title={"By Published Date:"}
            error={false}
            type={"range"}
            value={props.publishedDate}
            onChange={(e: any) => props.setPublishedDate(e)}
          />
        </div>
        <div className="flex w-full col-span-4 md:col-start-4 md:col-span-1 lg:col-start-7 lg:col-span-1 items-end justify-end">
          <CatalogFilterButtons
            onClickSearch={props.Search}
            handleFormReset={props.clearSelections}
            headers={[]}
            noColumns={true}
            member={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageFilter;
