import React, { useEffect, useRef, useState } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../CatalogFilterButtons";
import Input from "../../Atomos/Inputs/Input";

interface IStateFilterProps {
  stateData: any[];
  countryData: any[];
  regionData: any[];
  cityData: any[];
  officeTypeData: any[];
  firmData: Option[];
  filters: {
    name: string;
    states: Option[];
    countries: Option[];
    regions: Option[];
    cities: Option[];
    firms: Option[];
    officeTypes: Option[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      name: string;
      states: Option[];
      countries: Option[];
      regions: Option[];
      cities: Option[];
      firms: Option[];
      officeTypes: Option[];
    }>
  >;
  clearSelections: () => void;
  headers: string[];
  Search: () => void;
  tableInstance: any;
}

const StateFilter: React.FC<IStateFilterProps> = ({
  countryData,
  regionData,
  stateData,
  cityData,
  firmData,
  officeTypeData,
  filters,
  Search,
  setFilters,
  headers,
  clearSelections,
  tableInstance
}) => {
  const handleSelectCountry = (selected: Option[]) => {
    setFilters({ ...filters, countries: selected });
  };

  const handleSelectFirm = (selected: Option[]) => {
    setFilters({ ...filters, firms: selected });
  };

  const handleSelectOfficeType = (selected: Option[]) => {
    setFilters({ ...filters, officeTypes: selected });
  };

  const handleSelectRegion = (selected: Option[]) => {
    setFilters({ ...filters, regions: selected });
  };

  const handleSelectState = (selected: Option[]) => {
    setFilters({ ...filters, states: selected });
  };

  const handleSelectCity = (selected: Option[]) => {
    setFilters({ ...filters, cities: selected });
  };

  const handlerText = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="grid grid-cols-12 formsection w-full border border-gray-100 p-4">
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By Region:"
          objects={regionData}
          selectedObjst={filters.regions}
          seter={(e: any) => handleSelectRegion(e)}
        />
      </div>
      <div className="col-span-12  md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By Country:"
          objects={countryData}
          selectedObjst={filters.countries}
          seter={(e: any) => handleSelectCountry(e)}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By State:"
          disabled={filters.countries?.length <= 0}
          tooltip={
            filters.countries?.length <= 0
              ? "Please select at least one country"
              : ""
          }
          objects={stateData}
          selectedObjst={filters.states}
          seter={(e: any) => handleSelectState(e)}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By City:"
          objects={cityData}
          disabled={filters.states?.length <= 0}
          tooltip={
            filters.states?.length <= 0 ? "Please select at least one state" : ""
          }
          selectedObjst={filters.cities}
          seter={(e: any) => handleSelectCity(e)}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3 ">
        <Input
          name="name"
          title="By Name:"
          value={filters.name}
          onChange={(e) => handlerText(e.target.name, e.target.value)}
          error={false}
          required={false}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By Firm:"
          objects={firmData}
          selectedObjst={filters.firms}
          seter={(e: any) => handleSelectFirm(e)}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <DDMultiple
          title="By Office Type:"
          objects={officeTypeData}
          selectedObjst={filters.officeTypes}
          seter={(e: any) => handleSelectOfficeType(e)}
        />
      </div>
      <div className="flex items-end justify-end col-span-12 md:col-span-6 lg:col-span-3">
        <div className="w-full md:w-auto">
          <CatalogFilterButtons
            tableInstance={tableInstance}
            onClickSearch={Search}
            handleFormReset={clearSelections}
            headers={headers}
          />
        </div>
      </div>
    </div>
  );
};

export default StateFilter;
