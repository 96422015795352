import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { SetContentTags, UpdateContentTags } from "../../redux/catalogs/states";
import { getContentTags, addContentTag } from "../../Services/ContentTag";
import { v4 as UUIDV4 } from "uuid";
import { set } from "date-fns";

const UseContentTag = () => {
  const [isFetching, setIsFetching] = useState(false)

  const dispatch = useDispatch();
  const contentTagsData = useSelector((state: Appstore) => state.contentTags); 

  const resetContentTags = () => {
    setIsFetching(true)
  }

  useEffect(() => {
    
      if (contentTagsData.length) {
        setIsFetching(false)
        return
      }
      if (!isFetching) return

      getContentTags()
      .then((response) => dispatch(SetContentTags(response)))
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false))
  }, [isFetching]);

  useEffect(() => {
    setIsFetching(true)
  }, []);


  return {contentTagsData, isFetching,resetContentTags};
};

const AddContentTag = async (modalNameAux: string) => {
 // const dispatch = useDispatch(); // Hooks can only be called inside of the body of a function component. but this is just a normal function is not a component or react node
 console.log('modalNameAux', modalNameAux)
  try {
    const addData = { id: UUIDV4(), name: modalNameAux };
    const response = await addContentTag(addData); 
    return response;
  } catch (error) {
    console.error('Error creating element:', error);
    return null;
  }
};



export {UseContentTag, AddContentTag}
