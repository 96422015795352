import "../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Vector from "../../../components/Atomos/Icons/Vector";
import ContactFrame from "./ContactFrame";
import SortArrow from "../../../components/Atomos/Icons/SortArrow";
import Manage from "../../../components/Atomos/Icons/Manage";
import JurisdictionMembership from "./FirmContact/JurisdictionMembership";

interface IMainBoxProps {
  name: string;
  firmContactList: any;
  personList: any;
  jurisdictionList: any;
  membershipType: any;
  firmJurisdiction: any;
}

const FirmContactGT: React.FC<IMainBoxProps> = ({
  name,
  firmContactList,
  personList,
  jurisdictionList,
  membershipType,
  firmJurisdiction,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedJurisdictions, setSortedJurisdictions] = useState<any[]>([]);

  useEffect(() => {
    const sortJurisdictions = (firmContactList[0]?.jurisdictions ?? [])
      .slice()
      .sort((a: any, b: any) => {
        const nameA =
          jurisdictionList.find((jur: any) => jur.id === a.jurisdiction)
            ?.name || "";
        const nameB =
          jurisdictionList.find((jur: any) => jur.id === b.jurisdiction)
            ?.name || "";
        if (sortOrder === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

    setSortedJurisdictions(sortJurisdictions);
  }, [firmContactList, sortOrder]);

  return (
    <>
      <div className="flex flex-col gap-10 justify-end">
        <article className="articleSection relative">
          <section className="flex w-full flex-col gap-6 lg:gap-7">
            <div className="flex flex-col gap-5 lg:flex-row lg:justify-between">
              <CatalogSubTitle
                title={"Internal " + name + " Contact for WSG"}
                className="text-Default lg:text-[32px] md:text-[28px] text-[26px] not-italic font-medium lg:leading-9 md:leading-[44px] leading-normal"
              />

              <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                <div>
                  <span className="text-Default not-italic font-semibold lg:leading-5 lg:text-lg text-base leading-[18px] ">
                    Sort By
                  </span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="20"
                    viewBox="0 0 2 20"
                    fill="none"
                  >
                    <path d="M1 0V20" stroke="#EBEBEB" />
                  </svg>
                </div>
                <div
                  className="flex items-center gap-3 cursor-pointer"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                >
                  <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                    Membership Location
                  </span>
                  <SortArrow
                    className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrder === "desc" && "rotate-180"}`}
                  />
                </div>
              </div>
            </div>

            {membershipType === "Primary" && (
              <div className="grid gap-7">
                <JurisdictionMembership
                  jurisdiction={
                    jurisdictionList.find(
                      (jurisdiction: any) =>
                        jurisdiction.id === firmJurisdiction
                    )?.name
                  }
                  contacts={firmContactList[0]?.contacts}
                  firm={name}
                  persons={personList}
                />
              </div>
            )}
            {membershipType !== "Primary" && (
              <div className="grid gap-7">
                {sortedJurisdictions.map((contact: any) => (
                  <JurisdictionMembership
                    jurisdiction={
                      jurisdictionList.find(
                        (jurisdiction: any) =>
                          jurisdiction.id === contact.jurisdiction
                      )?.name
                    }
                    contacts={contact.contacts}
                    firm={name}
                    persons={personList}
                  />
                ))}
              </div>
            )}
          </section>
          <div className="flex w-full gap-2.5 items-center justify-end">
            <span className=" text-base not-italic font-bold leading-7 text-primary">
              MANAGE
            </span>
            <Manage className="w-4 h-4 fill-primary" />
          </div>
        </article>
      </div>
    </>
  );
};

export default FirmContactGT;
