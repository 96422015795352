import React from "react";
  
const Linkedin: React.FC = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M5.281 7.666v10.478H1.795V7.666h3.486zm.228-3.24c0 .518-.183.947-.55 1.288-.366.341-.84.512-1.42.512h-.02c-.58 0-1.048-.17-1.402-.512-.353-.34-.53-.77-.53-1.288s.183-.947.55-1.288c.365-.341.84-.512 1.42-.512.581 0 1.049.17 1.402.512.354.34.537.77.55 1.288zm12.316 7.712v6.006h-3.468v-5.608c0-.746-.145-1.327-.436-1.744-.29-.416-.739-.625-1.345-.625-.442 0-.808.12-1.099.36-.29.24-.518.543-.682.91-.076.214-.114.498-.114.852v5.855H7.195c.013-2.817.019-5.097.019-6.84V8.178l-.019-.512h3.486v1.516h-.018c.138-.228.284-.423.435-.588.152-.164.348-.347.588-.55.24-.201.55-.353.928-.454a5.3 5.3 0 011.213-.17c1.2 0 2.166.398 2.899 1.193.732.796 1.099 1.97 1.099 3.525z"></path>
    </svg>
  );
}
export default Linkedin;
