import React, { useEffect, useState } from 'react';
import ContentGeneralTopicFilter from '../../components/Catalogs/Filters/ContentGeneralTopicFilter';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { formatTimeSpanToString } from '../../utils/functions';
import useDataLoader2 from "../../hooks/useDataLoader2";

const ContentGeneralTopic: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', '_ts'];
  const DefaultPageSize = 50;
  const [filterName, setFilterName] = useState<string>('')
  const [tableInstance, setTableInstance] = useState<any>();
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name', 
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/contentGeneralTopic',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>},
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    {
      id: '_ts', header: 'Created Date', cell: (props: any) => {
        const value = props.getValue();
        return (
          <p>
            {" "}
            {value && formatTimeSpanToString(value) }
          </p>
        );
      }
    },
    { id: 'edit', header: 'Edit', cell: (props: any) => <Link className="edit" to={`Edit/${props.row.original.id}`} state={props.row.original}>Edit <div className="editsvg"><ShapeEdit /></div></Link>, enableSorting: false },
  ]);  

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  const clearSelections = () => {
    setFilterName('');
    fetchData(true)
  }

  useEffect(() => {
    setFilter({name: {value: filterName, comparisonType: 'CONTAINS'}})
  }, [filterName]);

  return (
    <>
      <div className='mainSection'>
        <CatalogTableC
          title="Content General Topic Catalog"
          catalogName="TOPICS"
          route="ContentGeneralTopic"
          catalogShortName="TOPIC"
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={data}
        >
          <ContentGeneralTopicFilter
            key={0}
            Search={fetchData}
            name={filterName}
            setName={(name) => setFilterName(name)}
            headers={Headers}
            clearSelections={clearSelections}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default ContentGeneralTopic;
