import React from "react";

function BackTime() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#fff"
        d="M9.008 15.375a6.19 6.19 0 01-2.487-.502A6.455 6.455 0 014.5 13.509a6.455 6.455 0 01-1.364-2.023A6.192 6.192 0 012.633 9c0-.885.167-1.713.502-2.487a6.455 6.455 0 011.364-2.022 6.455 6.455 0 012.022-1.364 6.192 6.192 0 012.487-.502c.943 0 1.84.198 2.688.595.85.397 1.578.954 2.187 1.672V3.115h1.125v3.808H11.2V5.798h1.962a5.668 5.668 0 00-1.85-1.502 5.014 5.014 0 00-2.304-.546c-1.463 0-2.703.51-3.722 1.528C4.267 6.297 3.758 7.538 3.758 9c0 1.463.51 2.703 1.528 3.722 1.019 1.019 2.26 1.528 3.722 1.528 1.312 0 2.457-.426 3.433-1.279.977-.852 1.56-1.926 1.749-3.221h1.148c-.183 1.611-.877 2.952-2.084 4.021-1.207 1.07-2.622 1.604-4.246 1.604zm2.23-3.355L8.445 9.228V5.25H9.57v3.522l2.458 2.458-.79.79z"
      ></path>
    </svg>
  );
}

export default BackTime;
