function LocalActivity(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M6.19505 10.898L8.00375 9.49146L9.82186 10.898L9.12693 8.66101L10.8356 7.23782H8.74164L8.00375 5.11971L7.26585 7.23782H5.18128L6.88056 8.66101L6.19505 10.898ZM2.42186 13.569C2.04956 13.569 1.73644 13.442 1.4825 13.1881C1.22854 12.9341 1.10156 12.621 1.10156 12.2487V10.0016C1.10156 9.89676 1.13055 9.80328 1.18851 9.72114C1.24649 9.63902 1.32548 9.58105 1.42548 9.54724C1.76363 9.43564 2.03308 9.2325 2.23381 8.93783C2.43453 8.64314 2.5349 8.33058 2.5349 8.00014C2.5349 7.67454 2.43453 7.36162 2.23381 7.06138C2.03308 6.76113 1.76363 6.55864 1.42548 6.45391C1.32548 6.42023 1.24649 6.36262 1.18851 6.28108C1.13055 6.19952 1.10156 6.10225 1.10156 5.98928V3.75159C1.10156 3.3767 1.22854 3.06136 1.4825 2.80556C1.73644 2.54977 2.04956 2.42188 2.42186 2.42188H13.5856C13.9605 2.42188 14.2759 2.54977 14.5317 2.80556C14.7874 3.06136 14.9153 3.3767 14.9153 3.75159V5.99869C14.9153 6.10353 14.8855 6.19726 14.8258 6.27989C14.766 6.36254 14.6848 6.42122 14.582 6.45594C14.2439 6.55932 13.976 6.76113 13.7784 7.06138C13.5808 7.36162 13.482 7.67454 13.482 8.00014C13.482 8.33058 13.5808 8.64314 13.7784 8.93783C13.976 9.2325 14.2439 9.43467 14.582 9.54434C14.682 9.57767 14.7626 9.63601 14.8237 9.71934C14.8848 9.80268 14.9153 9.89676 14.9153 10.0016V12.2487C14.9153 12.621 14.7874 12.9341 14.5317 13.1881C14.2759 13.442 13.9605 13.569 13.5856 13.569H2.42186ZM2.42186 12.2487H13.5856V10.5545C13.1634 10.2593 12.819 9.89036 12.5523 9.44761C12.2856 9.00485 12.1523 8.52236 12.1523 8.00014C12.1523 7.47164 12.2856 6.98601 12.5523 6.54326C12.819 6.1005 13.1634 5.73468 13.5856 5.44579V3.75159H2.42186V5.44579C2.86147 5.73468 3.21027 6.1005 3.46825 6.54326C3.72621 6.98601 3.8552 7.47164 3.8552 8.00014C3.8552 8.52236 3.72621 9.00485 3.46825 9.44761C3.21027 9.89036 2.86147 10.2593 2.42186 10.5545V12.2487Z" />
      </svg>
    </div>
  );
}

export default LocalActivity;
