function DeleteIcon(props: any) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M6.09 17.083c-.416 0-.77-.147-1.065-.441a1.451 1.451 0 01-.442-1.065V5H3.75V3.75H7.5v-.737h5v.737h3.75V5h-.833v10.577c0 .42-.146.777-.438 1.069a1.454 1.454 0 01-1.069.437H6.09zM14.167 5H5.833v10.577a.25.25 0 00.072.184.25.25 0 00.185.072h7.82a.245.245 0 00.176-.08.245.245 0 00.08-.176V5zm-6.33 9.166h1.25v-7.5h-1.25v7.5zm3.076 0h1.25v-7.5h-1.25v7.5z"></path>
    </svg>
  );
}

export default DeleteIcon;
