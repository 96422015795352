import React from "react";

function Bars() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      
          <path 
            d="M5.35 37.074v-5.3h37.3v5.3H5.35zm0-10.424v-5.3h37.3v5.3H5.35zm0-10.424v-5.3h37.3v5.3H5.35z"
          ></path>
        
    </svg>
  );
}

export default Bars;
