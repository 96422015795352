import React from "react";

function Referrals() {
  return ( 
        <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 25 24"
        >
        
            <path 
            d="M12.614 23.467l-9.991-9.992 7.8-7.8L14.3 9.55l1.036-1.036-6.127-6.14L1.662 9.92l1.754 1.754-1.29 1.276-3.041-3.055L9.184-.205l3.312 3.313 3.27-3.27 10.23 10.232-13.382 13.397zm.011-2.567l10.78-10.83-7.64-7.655-1.968 1.983 4.116 4.116-3.613 3.614-3.876-3.877L5.2 13.475l1.05 1.05 3.934-3.933 1.075 1.075L7.325 15.6 8.4 16.675l3.934-3.933 1.05 1.05-3.934 3.933 1.025 1.025 3.934-3.933 1.075 1.075-3.934 3.933 1.075 1.075z"
            ></path> 
        
        </svg> 
  );
}

export default Referrals;
