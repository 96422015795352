import "../../../assets/css/AddCatalogs.css";
import React from "react";
import ArrowDropDown from "../../../components/Atomos/Icons/ArrowDropDownButton";

const CommentAndDots: React.FC = () => {
  return (
    <div className="flex p-3 flex-col items-start gap-5 md:gap-6 lg:gap-7 self-stretch w-full">
      <hr className="lg:flex h-0 self-stretch border-Default" />
      <div className="flex flex-col items-center justify-center lg:flex-row w-full lg:space-x-2 flex-wrap">
        <div className="flex flex-col md:flex-row md:items-center lg:gap-2">
          <div className="flex items-center justify-center gap-2">
            <div className="flex max-h-5 max-w-[20px] p-1 justify-center items-center gap-[.625rem] rounded-full bg-Default">
              <div className="w-3 h-3 fill-white duration-300">
                <ArrowDropDown />
              </div>
            </div>
            <span className="text-base font-bold leading-7 text-accent-Default-dark whitespace-nowrap">
              WSG Import Module
            </span>
          </div>
          <span className="text-base leading-6 lg:text-lg lg:leading-8 whitespace-nowrap">
            Test Demo is on the WSG Import Module.
          </span>
        </div>
        <div className="flex flex-col md:flex-row items-center lg:gap-2">
          <div className="flex items-center gap-2">
            <span className="text-base leading-6 lg:text-lg lg:leading-8 whitespace-nowrap">
              Contact at
            </span>
            <span className="text-base leading-6 lg:text-lg lg:leading-8 text-Default underline whitespace-nowrap">
              support@worldservicesgroup.com
            </span>
          </div>
          <span className="text-base leading-6 lg:text-lg lg:leading-8 whitespace-nowrap">
            for support.
          </span>
        </div>
      </div>

      <hr className="lg:flex h-0 lg:justify-center lg:items-center self-stretch border-Default" />
      <div className="flex lg:py-3 lg:px-0 lg:flex-col lg:items-start lg:gap-[.625rem]">
        <div className="flex items-start gap-5">
          <div className="w-[1.5625rem] h-[1.5625rem] rounded-full bg-primary"></div>
          <div className="w-[1.5625rem] h-[1.5625rem] rounded-full bg-primary"></div>
          <div className="w-[1.5625rem] h-[1.5625rem] rounded-full bg-primary"></div>
        </div>
      </div>
    </div>
  );
};

export default CommentAndDots;
