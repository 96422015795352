function SortDecending(props: any) {
    return (
        <div className={props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 48 48"> 
                    <path
                        d="M43.5829 34.1673L33.0155 44.7065L22.4263 34.1173L25.2046 31.3107L31.035 37.1412L31.035 5.26513L35.0242 5.26513L35.0242 37.1412L40.8046 31.3607L43.5829 34.1673Z" 
                    /> 
            </svg>
        </div>
    );
}

export default SortDecending;