import React, { useEffect, useState } from 'react';
import ContentGeneralTopicFilter from '../../components/Catalogs/Filters/ContentGeneralTopicFilter';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import useDataLoader2 from '../../hooks/useDataLoader2';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';

const GlobalCrisis: React.FC = ({ ...props }) => {
  const Headers: string[] = ['name', '_ts'];
  const DefaultPageSize = 50;
  const [filterName, setFilterName] = useState<string>('')
  const [tableInstance, setTableInstance] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/globalCrisis',
    defaultPageSize: DefaultPageSize,
    fields
  });

  const { generateColumns } = useColumnConfig(Headers);
  const columns = generateColumns([
    { id: 'count', header: '#', cell: (props: any) => <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>, enableSorting: false, size: 50 },
    { id: 'name', header: 'Name', cell: (props: any) => props.getValue() },
    {
      id: 'edit', header: 'Edit', cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit
          <div className="editsvg"><ShapeEdit /></div>
        </Link>
      ), enableSorting: false
    },
  ]);

  const clearSelections = () => {
    setFilter([])
    setFilterName('')
    fetchData(true)
  }

  const handleSetFilterName = (name: string) => {
    setFilterName(name);
  }

  useEffect(() => {
    if(!isFetching) fetchData();
    setIsFetching(true)
  },[]);

  useEffect(() => {   
    setFilter({name: {
      value: filterName,
      comparisonType: 'CONTAINS'
    },})
  },[filterName]);

  return (
    <>
      <div className=' mainSection'>
        <CatalogTableC
          title={`Global Crisis Catalog`} catalogName={'GLOBAL CRISIS'} route={'GlobalCrisis'}
          catalogShortName={'CRISIS'}
          isfiltered={true}
          data={data} headers={Headers}
          totalCount={totalCount}
        >
          <ContentGeneralTopicFilter
          tableInstance={tableInstance}
            key={0}
            Search={fetchData}
            name={filterName}
            setName={handleSetFilterName}
            headers={Headers}
            clearSelections={clearSelections}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </div>
    </>
  );
};

export default GlobalCrisis;