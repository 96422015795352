import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Axios from "../../utils/axios";
import EditMembership from "../../components/Catalogs/FirmContact/EditMembership";
import { Tab } from "@headlessui/react";
import SaveOverride from "../../components/Catalogs/SaveOverride";
import GoBack from "../../components/Moleculas/GoBack";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import EditOffice from "../../components/Catalogs/OfficeContact/EditOffice";
import NewButton from "../../components/Atomos/Buttons/newButton";
import Plus2 from "../../components/Atomos/Icons/Plus";

interface Jurisdiction {
  id: string;
  name: string;
}

const OfficeContactsEdit: React.FC = () => {
  const axios = new Axios();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [offices, setOffices] = useState<Jurisdiction[]>([]);
  const [buttonClicked, setButtonClicked] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    state?.offices ? state?.offices[0]?.office : ""
  );
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorJurisdiction, setErrorJurisdictions] = useState<string[]>([]);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  useEffect(() => {
    if (errorFields.length > 0) {
      setSaveOverrideOpen(true);
    }
    if (errorFields.length === 0 && errorJurisdiction.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [errorFields]);

  useEffect(() => {
    if (errorJurisdiction.length > 0) {
      setSaveOverrideOpen(true);
    }
    if (errorJurisdiction.length === 0 && errorFields.length === 0) {
      setSaveOverrideOpen(false);
    }
  }, [errorJurisdiction]);

  useEffect(() => {
    const GetJurisdictions = async () => {
      try {
        const response = await axios.Get(`/office`);
        const data = response.data;
        if (data != null) {
          setOffices(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetJurisdictions();
  }, []);

  const handleTabValue = (office: any) => {
    setSelectedTab(office);
  };

  return (
    <>
      <div className="mainSection">
        <SaveOverride
          fields={[...errorFields, ...errorJurisdiction]}
          handleButtonClick={handleButtonClick}
          open={saveOverrideOpen}
          close={handleCloseSaveOverride}
        />
        <CatalogTitle
          title={`Edit ${state?.firmName} Contacts`}
          className="text-Default"
        >
          <GoBack />
        </CatalogTitle>

        <section className="Contacts Edit">
          <div className="flex h-auto w-full gap-5 flex-col p-10 bg-white">
            <div className="flex gap-6 flex-wrap">
              <span className="w-full text-Default text-[24px] md:text-[28px] lg:text-[32px] leading-8 lg:leading-9 font-medium not-italic">
                Member Main Contacts
              </span>
              {state?.offices?.length === 0 && (
                <div className="flex gap-2 flex-wrap md:justify-end lg:flex-nowrap self-stretch lg:justify-between w-full">
                  <span className=" text-gray-font text-lg leading-8 font-normal not-italic">
                    {"There are currently no Offices created for " +
                      state?.firmName +
                      ". Please Create an Office to continue the Member Main Contacts setup."}
                  </span>
                  <div className="flex w-full md:w-min lg:justify-end">
                    <NewButton
                      text={`NEW OFFICE`}
                      color="accent"
                      type="button"
                      style="filled"
                      size="large"
                      content="textIcon"
                      onClick={() => navigate(`/Staff/Catalogs/Office/Create`)}
                      icon={
                        <Plus2 className="w-5 h-5 shrink-0 relative overflow-visible" />
                      }
                    />
                  </div>
                </div>
              )}
              <div className="flex gap-1 overflow-x-auto scrollbar-hidden max-w-screen-xl">
                <Tab.Group>
                  <Tab.List className="flex gap-1">
                    {state?.offices?.map((office: any, index: number) => {
                      return (
                        <Tab
                          key={index}
                          onClick={() => handleTabValue(office.office)}
                          className={`justify-center w-auto items-center min-w-[131px] md:min-w-[150px] lg:min-w-[168.5px] bg-[#E5E3E4] max-h-[190px] text-gray-font ui-selected:text-neutral p-3 pr-2 ui-selected:bg-accent-Default-dark border-none rounded-none focus:border-none whitespace-nowrap`}
                        >
                          <span
                            className={`not-italic text-xs md:text-sm lg:text-base leading-8 font-normal`}
                          >
                            {offices.find(
                              (off: any) => off.id === office.office
                            )?.name ?? ""}
                          </span>
                        </Tab>
                      );
                    })}
                  </Tab.List>
                </Tab.Group>
              </div>
            </div>

            <EditOffice
              officeId={selectedTab}
              officeName={
                offices.find((jur: any) => jur.id === selectedTab)?.name
              }
              officeObjs={state?.officesId ? state?.officesId : []}
              setErrorFields={setErrorFields}
              setErrorJurisdiction={setErrorJurisdictions}
              buttonClicked={buttonClicked}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default OfficeContactsEdit;
