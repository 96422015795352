import React, { FC } from 'react';
import css from './BackgroundProfile.module.css';

interface BackgroundProps {
  image: string;
  viewMode?: boolean;
}

const BackgroundComponent: FC<BackgroundProps> = ({ image, viewMode }) => {
  const bgImg = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className='absolute w-full pointer-events-none -z--1'>
      <div className={`${css.bgHolder} w-full`}>
        <div className={`w-full bg-fixed bg-top bg-cover relative ${css.bgImage}`} style={bgImg}>
          <div className={`${css.grayLayer} absolute w-full bg-[#2c2c2c] opacity-50`}></div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundComponent;
