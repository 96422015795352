import React from "react";

function VectorUp(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1"
        height="158"
        viewBox="0 0 1 158"
        fill="none"
      >
        <path d="M0.5 0V158" stroke="#EBEBEB" />
      </svg>
    </div>
  );
}

export default VectorUp;
