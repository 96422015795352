
import  {  useState } from 'react';
import { useAppContext } from '../../../Context';
import Modal from '../../Atomos/Modals/Modal'; 
import Input from '../../Atomos/Inputs/Input';
import NewButton from '../../Atomos/Buttons/newButton';
import Plus from '../../Atomos/Icons/EditMode/Plus';
import CloseModal from '../../Atomos/Icons/EditMode/CloseModal'; 
import Axios from '../../../utils/axios'; 
import  '../../../assets/css/FormModal.css';
type titleprops = {
    open: boolean;
    toggleModal: () => void;
}

const axios = new Axios();

const AddAdmissionModal: React.FC<titleprops> = ({ open, toggleModal }) => {
    const { mode } = useAppContext();
    const [admission, setAdmission] = useState('');
    const [errors, setErrors] = useState({ name: { error: false, message: '' } }); 

    const saveAdmission  = async () => { 
            if(admission === '') {
                errors.name.error = true;
                errors.name.message = 'This field is required';
                setErrors({ name: { error: true, message: 'This field is required' }});
                return;
            } 
            else
            {
                const newAdmission = {
                    name: admission
                }
                const response = await axios.Post('barAdmission', newAdmission);
                if(response.status === 200) {
                    if(response.data.error) { 
                        setErrors({ name: { error: true, message: response.data.error }});
                        return;
                    }
                
                }

                ClearDataNClose();
                 
            }

    };

    const ClearDataNClose = () => {
        setAdmission(''); 
        errors.name.error = false;
        errors.name.message = '';
        setErrors({ name: { error: false, message: '' }});
        toggleModal();
    }

    const handleAdmissionError = (admissionstr:string) => {
        if(admissionstr !== '') {
                errors.name.error = false;
                errors.name.message = '';
                setErrors({ name: { error: false, message: '' }});
            }
            setAdmission(admissionstr);
    }   
  
    return (
        <Modal isModalOpen={open} toggleModal={ClearDataNClose} title={'New Bar Admission'}  >
            <div className='flex flex-col gap-10 formModal'>
                <div>
                    <Input title="Name" name="name" onChange={(e) => handleAdmissionError(e.target.value)} 
                    errorMsj={errors.name.message} value={admission} error={errors.name.error} required />
                </div>
                <div className="flex justify-end gap-5">
                    <NewButton onClick={ClearDataNClose} content='textIcon' style='outlined' color='neutral' icon={<CloseModal />} text="Cancel" />
                    <NewButton onClick={saveAdmission} content='textIcon' color={`${mode === 'member' ? 'primary' : 'accent'}`} icon={<Plus />} text="Add" />
                </div>
            </div>
        </Modal>
    )

};

export default AddAdmissionModal;