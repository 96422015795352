import React from "react";

function CalendarMonth() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M21.83 28.17v-4.34h4.34v4.34h-4.34zm-8 0v-4.34h4.34v4.34h-4.34zm16 0v-4.34h4.34v4.34h-4.34zm-8 8v-4.34h4.34v4.34h-4.34zm-8 0v-4.34h4.34v4.34h-4.34zm16 0v-4.34h4.34v4.34h-4.34zM5.294 44.707V6.265h6.963v-3h3.724v3h16.04v-3h3.736v3h6.979v38.442H5.293zm3.961-3.961h29.492V19.5H9.254v21.246zm0-24.246h29.492v-6.246H9.254V16.5z"
      ></path>
    </svg>
  );
}

export default CalendarMonth;
