import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IState, IStateErrors } from "../../interfaces";
import { Appstore, InitializeState, ModifyState, NewState } from "../../redux";
import { saveState, updateState } from "../../Services/State";
import { FormEvent, useEffect, useState } from "react";
import { ICatalogProps } from "../../interfaces/Catalogs/ICatalog";
import { UseCountryList } from '../../hooks/Catalogs/useCountry';
import { useLogging } from "../../Context/LoggingContext";

export const useStateHook = ({ mode }: ICatalogProps): any => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { logActivity } = useLogging();
  const location = useLocation()
  //redux
  const dispatch = useDispatch();
  const currentState: IState = useSelector((store: Appstore) => store.state);

  //error fields
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [errors, setErrors] = useState<IStateErrors>({
    name: { error: false, errorMsj: "" },
    abbreviation: { error: false, errorMsj: "" },
    country: { error: false, errorMsj: "" },
  });

  //list of countries
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [countryData, setCountryData] = useState<any[]>([]);

  //manage button clicked
  const [buttonClicked, setButtonClicked] = useState("");

  const { countryData: countryList } = UseCountryList();

  useEffect(() => {
    setCountryData(currentState?.hasSubstate 
      ? countryList.filter(c => c.hasState === true) 
      : countryList)
    
  }, [countryList, currentState.hasSubstate]);

  useEffect(() => {
    if (
      Object.keys(errors).filter(
        (key) => errors[key as keyof IStateErrors].error
      ).length > 0
    )
    validation();

    if (state?.country && countryList.length) {
        setSelectedCountry(
          countryList.find((item: any) => item.id === state.country)
            ?.name  || ""
        );
        dispatch(
          ModifyState({
            ...state,
            country:
            countryList.find(
                (item: any) => item.name === state.country
              )?.id || "",
          } as IState)
        );
    }
  }, [countryList, state]);

  const validation = async () => {
    let errors: IStateErrors = {
      name: { error: false, errorMsj: "" },
      abbreviation: { error: false, errorMsj: "" },
      country: { error: false, errorMsj: "" },
    };
    if (currentState.name?.trim() === "") {
      errors = {
        ...errors,
        name: { error: true, errorMsj: "Name is required" },
      };
    }
    if (currentState.abbreviation?.trim() === "") {
      errors = {
        ...errors,
        abbreviation: { error: true, errorMsj: "Abbreviation is required" },
      };
    }

    if (
      currentState.country === undefined ||
      selectedCountry === "" ||
      currentState.country.trim() === ""
    ) {
      errors = {
        ...errors,
        country: { error: true, errorMsj: "Country is required" },
      };
    }

    setErrors(errors);
    setSaveOverrideOpen(
      errors &&
      Object.keys(errors).filter(
        (key) => errors[key as keyof IStateErrors].error
      ).length !== 0
    );
    return (
      errors &&
      Object.keys(errors).filter(
        (key) => errors[key as keyof IStateErrors].error
      ).length !== 0
    );
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newState = {
      ...currentState,
      countryName: selectedCountry,
      regionNames: currentState?.regions.join(', '),
    }

    if (buttonClicked === "saveOverride") {
      if (mode === "add") {
        const response = await saveState(newState);
        response?.Done &&
          logActivity('CREATE_STATE', location.pathname, JSON.stringify(response))
        navigate("/Staff/Catalogs/State");
      } else {       
        const response = await updateState(newState);
        logActivity('UPDATE_STATE', location.pathname, JSON.stringify({ prevState: JSON.stringify(newState), newState: JSON.stringify(response) }))
        response?.Done && navigate("/Staff/Catalogs/State");
      }
    } else {
      let checkerrors = false;
      checkerrors = await validation();
      if (checkerrors === false) {
        if (buttonClicked === "updateButton") {
          const response = await updateState(newState);
          logActivity('UPDATE_STATE', location.pathname, JSON.stringify({ prevState: JSON.stringify(state), newState: JSON.stringify(response) }))
          response?.Done && navigate("/Staff/Catalogs/State");
        } else if (buttonClicked === "saveButton") {
          const response = await saveState(newState);
          logActivity('CREATE_STATE', location.pathname, JSON.stringify(response))
          response?.Done && navigate("/Staff/Catalogs/State");
        }
      }
    }
  };

  const handleChange = (eventOrValue: {
    target: { name: string; value: any };
  }) => {
    {
      const { name, value } = eventOrValue.target;
      dispatch(
        ModifyState({
          ...currentState,
          [name]: value.id ? value.id : value,
        } as IState)
      );
      setErrors({ ...errors, [name]: { error: false, errorMsj: "" } });
      if (name === "country") {
        setSelectedCountry(value.name);
      }
    }
  };

  return {
    currentState,
    errors,
    saveOverrideOpen,
    handleButtonClick,
    handleSubmit,
    handleChange,
    setSaveOverrideOpen,
    countryData,
    selectedCountry,
  };
};
