import { createSlice } from "@reduxjs/toolkit";   
import { JurisdictionGroupEmpty } from "../emptyStates";

export const jurisdictionGroupSlice = createSlice({
  name: "state",
  initialState: JurisdictionGroupEmpty,
  reducers: {
    ModifyJurisdictionGroup: (state, action) => ({ ...state, ...action.payload }),
    NewJurisdictionGroup: (_, action) => action.payload, 
    ResetJurisdictionGroup: () => JurisdictionGroupEmpty, 
  },
});

export const {  NewJurisdictionGroup, ModifyJurisdictionGroup, ResetJurisdictionGroup } =
jurisdictionGroupSlice.actions;
