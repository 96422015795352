import { Link, useLocation } from "react-router-dom"; 
import '../../../assets/css/AddCatalogs.css';
import Th from "./Th"; 
import useTable from "../../../hooks/UseTable";
import ShapeEdit from "../../Atomos/Icons/ShapeEdit";
import SvgIcon from "../../ViewMode/IconComponent";
import { useEffect, useState } from "react";
import Eye from "../../Atomos/Icons/Eye";
import Axios from "../../../utils/axios";

const axios = new Axios()
interface ITableProps {
  sort: { field: string; order: string }[];
  pageData: any[];
  pageHeaders: string[];
  HandlerSort: (field: string) => void;
  id?: string;
  pageSize: number;
  currentPage: number;
  view?:boolean;
}

function formatDate(dateString:any) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
}

const safeAccessName = (item: any) => item?.name ?? "";

const renderCellContent = (header: string, cellContent: any) => {
  if (cellContent === null || cellContent === undefined || (typeof cellContent === 'string' && cellContent.trim() === '')) {
    return "";
  }
  if (typeof cellContent === 'object') {
    if ((header === 'firm' || header === 'school' || header === 'degree' || header === 'person') && cellContent.name) {
      return cellContent.name;
    }
    return cellContent.toString();
  }
  return cellContent; 
};
const Table: React.FC<ITableProps> = ({ id, pageData, sort, pageHeaders, HandlerSort, pageSize, currentPage, view }) => {
  const [professionals, setProfessionals] = useState<any[]>([]);

  const getPersons = async () => {
    try {
      const response = await axios.Get('/person')
      if (response && response.data) {
        setProfessionals(response.data)
      }
    } catch (error: any) {
      throw error
    }
  }


  const findProfessionalsForOffice = (office: string) => {
    if (professionals) {
      const filteredPersons = professionals.filter((professional) =>
        professional.mainOffice === office
      );
      return filteredPersons.length.toString();
    }
    return '0'
  }


  useEffect(() => {
    getPersons()
  }, [])

  const { handledragstart,handleDrop } = useTable();
  let indexsize: number = 1;
  var counter = ((currentPage - 1) * pageSize) + 1;

  return (
    <div className="overflow-x-scroll no-scrollbar w-full">
      <div id="tablediv">
      <table id={id} className="w-full">
        <thead >
          <tr>
            <Th title="#" key={"#"} handledragstart={handledragstart} handleDrop={handleDrop} sort={sort} HandlerSort={HandlerSort} index={0} sorting={false} />
            { 
              pageHeaders.length > 0 && pageHeaders.map((header, index) => {
                indexsize = index + 1;
                var displayName = header;
                if(displayName === "_ts"){
                  displayName = "Date Created";
                } else if (displayName === "countOfPeople"){
                  displayName = "Professionals in Office"
                }
                return <Th key={header} handledragstart={handledragstart} handleDrop={handleDrop} displayName={displayName} title={header} sort={sort} HandlerSort={HandlerSort} index={index+1} sorting={true}/>
              }) 
            } 
            <Th handledragstart={handledragstart} handleDrop={handleDrop} title="Actions" key={"Actions"} sort={sort} HandlerSort={HandlerSort} index={indexsize+1} sorting={false} />
          </tr>
        </thead>
        <tbody>
            {pageData.length > 0 && pageData.map(row => (
              <tr key={row.id} className="even:bg-gray-100">
                <td className="w-10">{counter++}</td>
                {pageHeaders.map(header => {
                  let cellContent = row[header]; 
                 
                  if(typeof row[header] === 'boolean'){ 
                  if(row[header] === true){ 
                    cellContent = "Yes";
                  }
                  if(row[header] === false){
                    cellContent = "No";
                  }
                  }
                  if (cellContent === null || cellContent === undefined) {
                    cellContent = "";
                  }   
                  if (header === "_ts") {
                    cellContent = new Date(row[header] * 1000).toLocaleDateString('en-US');
                  } else if (header === 'createdAt') {
                    cellContent = formatDate(row[header])
                  }else if (header === 'icon') {
                    cellContent = (
                      <div className="flex w-[3rem] h-[3rem] max-w-[4rem] items-center justify-center">
                        <img src={row[header]} alt="Icon" className="w-full h-full bg-contain"/>
                      </div>
                    );
                  }
                  else {
                    cellContent = renderCellContent(header, cellContent);
                  }
                  return (
                    <td key={header} className={`td-${header} min-w-[13.29rem]`}>
                      {cellContent}
                    </td>
                  );
                })}

                {!view &&
                <td>
                  <Link className="edit" to={`Edit/${row.id}`} state={row}>Edit<div className="editsvg"><ShapeEdit /></div></Link>
                </td>
                }
                {view &&
                  <td>
                    <Link className="edit" to={`View/${row.id}`} state={row}>View<div className="editsvg"><Eye className="w-5 h-5" /></div></Link>
                  </td>
                }
              </tr>
            ))}
          </tbody>
      </table>
      </div>
      </div>
  )
};

export default Table;