import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const firmsSlice = createSlice({
  name: "firms",
  initialState: Empty,
  reducers: {
    SetFirms: (_, action) =>  action.payload,
  },
});

export const { SetFirms } = firmsSlice.actions;
