import { createSlice } from "@reduxjs/toolkit"; 
import { StateEmpty } from "../emptyStates/State";  

export const stateSlice = createSlice({
  name: "state",
  initialState: StateEmpty,
  reducers: {
    ModifyState: (state, action) =>  ({...state, ...action.payload}),
    NewState: (_, action) =>  action.payload, 
    InitializeState: () => StateEmpty, 
  },
});

export const { NewState, ModifyState, InitializeState} = stateSlice.actions;
