import React, { useState, useEffect } from "react";
import ProfileViewModeBg from "../../assets/images&icons/bg-login.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/loaderActions";
import Axios from "../../utils/axiosPublic";

const axios = new Axios();

const ForgotpasswordPage = () => {
  const [sentValidation, setSentValidation] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [validationCode, setValidationCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [timer, setTimer] = useState<number>(0);
  const [validationCompleted, setValidationCompleted] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleValidationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationCode(e.target.value);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    clearMessages();

    if (!isUserNameValid()) {
      setErrorMessage("Please enter a user name.");
      return;
    }

    dispatch(showLoader());

    try {
      const response = await fetchPasswordReset();

      if (response.data.status === "success") {
        handleSuccessResponse(response.data.message);
      } else {
        handleErrorMessage(response.data.message);
      }
    } catch (error) {
      handleErrorMessage("An error occurred. Please try again.");
    } finally {
      dispatch(hideLoader());
    }
  };

  const clearMessages = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const isUserNameValid = () => {
    return userName.trim().length > 0;
  };

  const buildUrl = () => {
    let url = `/password?userName=${userName}`;
    if (sentValidation && validationCode) {
      url += `&verificationCode=${validationCode}`;
    }
    return url;
  };

  const fetchPasswordReset = async () => {
    const url = buildUrl();
    return await axios.Get(url);
  };

  const handleSuccessResponse = (message: string) => {
    if (sentValidation) {
      if (message === "Code is valid.") {
        setValidationCompleted(true);
        navigate("/reset-password", { state: { userName, validationCode } });
      } else {
        setSuccessMessage(message);
      }
    } else {
      setSentValidation(true);
      setSuccessMessage(message);
      setTimer(20);
    }
  };

  const handleErrorMessage = (message: string) => {
    setErrorMessage(message);
  };

  const handleResendCode = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    dispatch(showLoader());

    try {
      const response = await axios.Get(`/password?userName=${userName}&resend=true`);
      if (response.data.status === "success") {
        setSentValidation(true);
        setSuccessMessage(response.data.message);
        setTimer(20);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  return (
    <React.Fragment>
      <div
        className="absolute w-full min-h-screen"
        style={{
          backgroundImage: `url(${ProfileViewModeBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </div>
      <div className="w-full min-h-screen justify-center items-center flex self-stretch relative">
        <div className="w-8/12 lg:w-[520px]">
          <div className={`flex w-full align-middle justify-center gap-28 px-5 py-4 bg-[#9C3325]`}>
            <h2 className={`text-center text-xl font-sans text-white roboto-font`}>RESET PASSWORD</h2>
          </div>
          <div className='bg-white w-full py-4'>
            <form className='text-black py-8 flex flex-col gap-4' onSubmit={handleSubmit}>
              <div className='w-full px-5'>
                <input
                  type="text"
                  placeholder='Enter your User Name'
                  value={userName}
                  onChange={handleUserNameChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                {errorMessage && (
                  <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                )}
              </div>
              {sentValidation && (
                <div className='w-full px-5'>
                  <input
                    type="text"
                    placeholder='Enter Validation Code'
                    value={validationCode}
                    onChange={handleValidationCodeChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                  )}
                  {successMessage && (
                    <p className="text-green-500 text-sm mt-2">{successMessage}</p>
                  )}
                </div>
              )}
              <button
                type="submit"
                className={`cursor-pointer w-11/12 text-white mx-auto flex text-center align-middle justify-center py-4 inter_font bg-[#BC3323] font-semibold text-[1rem]`}
              >
                {sentValidation ? 'SUBMIT' : 'GET VALIDATION CODE'}
              </button>
            </form>
            {sentValidation && timer > 0 && (
              <p className="text-center mt-4">Resend code in {timer} seconds</p>
            )}
            {sentValidation && timer === 0 && (
              <div
                onClick={handleResendCode}
                className="cursor-pointer text-center mt-4 text-blue-500"
              >
                Resend Code
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotpasswordPage;
