import React from "react";

function AddUser() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 16 16"
    > 
        <path
          d="M3.098 14.358V3c0-.363.13-.676.389-.937.26-.262.57-.392.931-.392h4.71v1.33h-4.71v9.35L8 10.845l3.582 1.505V6.898h1.33v7.46L8 12.264l-4.902 2.094zm8.53-8.46v-1.5h-1.5V3.18h1.5v-1.5h1.227v1.5h1.5v1.217h-1.5v1.5h-1.226z"
        ></path> 
    </svg>
  );
}

export default AddUser;
