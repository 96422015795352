import { useState } from "react";
import { CircleRadio } from "../../Atomos/Icons/CircleRadio";

interface RadioButtonProps {
  index: number;
  text: string;
  selected: boolean;
  handleRadioButtonChange: (index: number) => void;
  disabled?: boolean;
  name?: string
}

const RadioButton: React.FC<RadioButtonProps> = ({
  text,
  selected,
  index,
  handleRadioButtonChange: handleRadioButtonChange,
  disabled,
  name
}) => {
  const handleChange = () => { 
    handleRadioButtonChange(index);
  };

  return (
    <div onClick={handleChange} className={`flex gap-[0.625rem] items-center justify-start ${disabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <input
        type="radio"
        key={index}
        name={name}
        value={index}
        className="hidden"
        
        disabled={disabled}
      />
      <div
        className={` w-6 h-6 inset-0 flex items-center ${selected
            ? "fill-Default stroke-accent-Default-dark stroke-2"
            : "fill-white stroke-gray-font stroke-2"
          }`}
      >
        {selected ? <CircleRadio className="w-6 h-6" /> : <CircleRadio className="w-6 h-6" />}
      </div>
      <label htmlFor={name} className="flex-1 text-base md:text-lg leading-8 font-normal not-italic text-gray-font first-letter:uppercase">
        {text}
      </label>
    </div>
  );
};

export default RadioButton;
