
import { classNames } from 'react-easy-crop/helpers';
import '../../../assets/css/Text.css'
import React, { Children } from 'react'

interface IHeaderTitle {
    title: string;
    children?: React.ReactNode;
    className?: string;
}

const CounterTotalCatalog: React.FC<IHeaderTitle> = ({ title, children, className }) => {
    return (
        <div className={`flex justify-between items-center self-stretch px-6 py-4 gap-[0.625rem] relative ${className}`} >
            <h3 className={`${className}`}>
                {title}
            </h3>
            {Children.map(children, (child, index) => (
                <div className="Row" key={index}>
                    {child}
                </div>
            ))}
        </div>
    )
}

export default CounterTotalCatalog