import React from "react";

function RemoveX() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      className='w-full h-full'
    >
      <path
        fill="#000"
        d="M5.042 15.258l-.878-.878 4.667-4.667-4.667-4.667.878-.878L9.71 8.835l4.667-4.667.878.878-4.667 4.667 4.667 4.667-.878.878-4.667-4.667-4.667 4.667z"
      ></path>
    </svg>
  );
}

export default RemoveX;
