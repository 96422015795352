import React, { useEffect, useRef, useState } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import InputText, { InputTextRef } from "../../Atomos/Inputs/InputText";
import CatalogFilterButtons from "../CatalogFilterButtons";
import Input from "../../Atomos/Inputs/Input";

interface IFirmFilterProps {
  stateData: any[];
  countryData: any[];
  regionData: any[];
  jurisdictionData: any[];
  jurisdictionGroupData: any[];
  serviceTypeData: any[];
  membershipStatusData: Option[];
  filters: {
    name: string;
    states: Option[];
    countries: Option[];
    regions: Option[];
    jurisdictions: Option[];
    jurisdictionGroups: Option[];
    serviceTypes: Option[];
    membershipStatus: Option[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      name: string;
      states: Option[];
      countries: Option[];
      regions: Option[];
      jurisdictions: Option[];
      jurisdictionGroups: Option[];
      serviceTypes: Option[];
      membershipStatus: Option[];
    }>
  >;
  clearSelections: () => void;
  headers: string[];
  Search: () => void;
  tableInstance: any;
}

const JurisdictionFilter: React.FC<IFirmFilterProps> = ({
  countryData,
  regionData,
  stateData,
  jurisdictionData,
  jurisdictionGroupData,
  serviceTypeData,
  membershipStatusData,
  filters,
  Search,
  setFilters,
  headers,
  clearSelections,
  tableInstance,
}) => {
  const handleSelectCountry = (selected: Option[]) => {
    setFilters({ ...filters, countries: selected });
  };

  const handleSelectJurisdiction = (selected: Option[]) => {
    setFilters({ ...filters, jurisdictions: selected });
  };

  const handleSelectJurisdictionGroup = (selected: Option[]) => {
    setFilters({ ...filters, jurisdictionGroups: selected });
  };

  const handleSelectServiceType = (selected: Option[]) => {
    setFilters({ ...filters, serviceTypes: selected });
  };

  const handleSelectMembershipStatus = (selected: Option[]) => {
    setFilters({ ...filters, membershipStatus: selected });
  };

  const handleSelectRegion = (selected: Option[]) => {
    setFilters({ ...filters, regions: selected });
  };

  const handleSelectState = (selected: Option[]) => {
    setFilters({ ...filters, states: selected });
  };

  const handlerText = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="w-full formsection grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 filtersbox">
      <div>
        <DDMultiple
          title="By Region:"
          objects={regionData}
          selectedObjst={filters.regions}
          seter={(e: any) => handleSelectRegion(e)}
        />
      </div>
      <div>
        <DDMultiple
          title="By Country:"
          objects={countryData}
          selectedObjst={filters.countries}
          seter={(e: any) => handleSelectCountry(e)}
        />
      </div>
      <div>
        <DDMultiple
          title="By State:"
          objects={stateData}
          selectedObjst={filters.states}
          seter={(e: any) => handleSelectState(e)}
          disabled={filters.countries.length <= 0}
          tooltip={
            filters.countries.length <= 0
              ? "Please select at least one country"
              : ""
          }
        />
      </div>
      <div>
        <DDMultiple
          title="By Service Type:"
          objects={serviceTypeData}
          selectedObjst={filters.serviceTypes}
          seter={(e: any) => handleSelectServiceType(e)}
        />
      </div>
      <div>
        <Input
          name="name"
          title="By Name:"
          value={filters.name}
          onChange={(e) => handlerText(e.target.name, e.target.value)}
          error={false}
          required={false}
        />
      </div>
      <div>
        <DDMultiple
          title="By Jurisdiction Name:"
          objects={jurisdictionData}
          selectedObjst={filters.jurisdictions}
          seter={(e: any) => handleSelectJurisdiction(e)}
        />
      </div>
      <div>
        <DDMultiple
          title="By Jurisdiction Group:"
          objects={jurisdictionGroupData}
          selectedObjst={filters.jurisdictionGroups}
          seter={(e: any) => handleSelectJurisdictionGroup(e)}
        />
      </div>
      <div>
        <DDMultiple
          title="By Membership Status:"
          objects={membershipStatusData}
          selectedObjst={filters.membershipStatus}
          seter={(e: any) => handleSelectMembershipStatus(e)}
        />
      </div>

      <div className="lg:col-start-4  md:col-start-2 flex justify-end">
        <div className="md:max-w-min flex-1 ">
          <CatalogFilterButtons
            onClickSearch={Search}
            handleFormReset={clearSelections}
            headers={headers}
            tableInstance={tableInstance}
          />
        </div>
      </div>
    </div>
  );
};

export default JurisdictionFilter;
