import LoginForm from "../../components/Forms/LoginForm";
import Footer from "../../components/Layout/Footer/Footer";
import { Fragment } from "react";
import Header from "../../components/Layout/Header/Header";

const Login = () => {
    return ( 
        <Fragment>
            <LoginForm />
        </Fragment>
        );
}
 
export default Login;