import React from "react";
import NewButton from "../Buttons/newButton";
import Close from "../../Atomos/Icons/Close";
import Modal from "./Modal";
import RadioButton from "../Radio/RadioButton";
import { AppContextProvider, useAppContext } from "../../../Context";
import useViewport from "../../../hooks/useViewPort";
import Excel from "../Icons/Excel";

type propTypes = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataExcelExport: (currentData: boolean) => void;
};


const ExcelModal: React.FC<propTypes> = ({ open, setOpen, dataExcelExport }) => {
  const { viewportWidth } = useViewport()
  const [selectedOption, setSelectedOption] = React.useState("currentData");

  const handleSelection = (selectionType: string) => {
    setSelectedOption(selectionType);
  };
  
  return (
    <Modal title=" Export to Excel" isModalOpen={open} toggleModal={() => setOpen(false)}>
      <div className="flex flex-col gap-6">

        <p className="text-gray-font text-lg leading-6 not-italic font-normal">
          Please select the Data Set you want to export to Excel:
        </p>
        <RadioButton 
          index={0} 
          text={"Current Data Set in Table (Filtered/Current View)"} 
          selected={selectedOption === "currentData"} 
          handleRadioButtonChange={() => handleSelection("currentData")}
          name="excelSelection"
          />
        <RadioButton 
          index={1} 
          text={"All Data (Complete Table/All Pages/Unfiltered)"} 
          selected={selectedOption === "totalData"} 
          handleRadioButtonChange={() => handleSelection("totalData")}
          name="excelSelection"
          />
        <div className="flex justify-between gap-4">
          <NewButton onClick={() => setOpen(false)}
            text={"Cancel"}
            icon={<div className=" h-5 w-5"><Close /></div>}
            color='neutral'
            content='textIcon'
            size="large"
            style="outlined"
          />
          <NewButton
            text={viewportWidth < 1024 ? `Export` : `Export to Excel`}
            color="excel"
            size="large"
            content="textIcon"
            onClick={() => dataExcelExport(selectedOption === "currentData")}
            icon={<Excel />}
          />
        </div>

      </div>
    </Modal>
  );
};

export default ExcelModal;