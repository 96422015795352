import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const jurisdictionsSlice = createSlice({
  name: "jurisdiction",
  initialState: Empty,
  reducers: {
    SetJurisdictions: (_, action) =>  action.payload,
  },
});

export const { SetJurisdictions } = jurisdictionsSlice.actions;
