import { useDispatch, useSelector } from "react-redux";
import { Appstore } from "../../redux";
import { useEffect, useState } from "react";
import { getAllCities } from "../../Services/City";
import { SetCities } from "../../redux/catalogs/states";

export const useCityList = () => {
  const [isFetching, setIsFetching] = useState(false)

  const dispatch = useDispatch();
  const cityData = useSelector((state: Appstore) => state.cities);

  useEffect(() => {
      if (cityData.length > 0) {
        setIsFetching(false)
        return
      }
      if (!isFetching) return

      getAllCities()
      .then((response) => dispatch(SetCities(response)))
      .catch((error) => console.error(error))
      .finally(() => setIsFetching(false))
  }, [isFetching]);

  useEffect(() => {
    setIsFetching(true)
  }, []);

  return {cityData, isFetching};
};
