import React from "react";
import { IRegion } from "../../interfaces";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";

interface Row {
  [key: string]: any;
}

const getValue = (row: Row, column: ColumnConfig, context: any): any => {
  const { index, currentPage, recordsPerPage, firms, practiceIndustries, resource, countries, states, substates, jurisdictionGroups, regions } = context;

  const filterRegions = (rowRegions: IRegion[]) => {
    if (regions && rowRegions?.length > 0) {
      const filter = regions.filter((regions: any) => rowRegions?.some(region => regions.id === region.id))
      return filter.map((f: any) => f.name).join(', ')
    }
  }

  const mappings: Record<string, any> = {
    'count': () => index + 1 + (currentPage - 1) * recordsPerPage,
    'rank_': () => {
      const rankIndex = parseInt(column.id.split('_')[1], 10) - 1;
      return row.ranks && row.ranks[rankIndex] ? row.ranks[rankIndex].name : '';
    },
    'firmEmail': () => {
      return row.firmEmail || ''
    },
    'firm': () => {
      if (resource === 'Education' || resource === 'Person') {
        return firms.find((fi: any) => fi.id === row.firm?.id || fi.id === row.firm)?.name;
      } else if (resource === 'Representative Client') {
        return firms.find((fi: any) => fi.id === row.firm.id)?.name;
      } else if (resource === 'activityLog') {

      } else {
        return firms.find((fi: any) => fi.id === row.firm)?.name;
      }
    },
    'regions': () => {
      if (resource === 'Address') {
        return row.regions
      } else if (resource === 'Country') {
        return filterRegions(row?.regions)
      }
    },
    'school': () => row.school?.name,
    'abbreviation': () => row[column.id],
    'degree': () => row.degree?.name,
    'person': () => row.person?.name,
    'country': () => resource !== 'Address' ? countries?.find((country: any) => country.id === row.country)?.name : '',
    'state': () => resource !== 'Address' ? states?.find((state: any) => state.id === row.state)?.name : '',
    'substate': () => substates?.find((substate: any) => substate.id === row.substate)?.name,
    'jurisdictionGroup': () => jurisdictionGroups?.find((jgroup: any) => jgroup.id === row.jurisdictionGroup)?.name,
    'firms': () => row.firms?.map((firm: any) => firm.name).join(', ') || '',
    'practiceIndustries': () => findPracticeIndustryNames(row.id, practiceIndustries ? practiceIndustries : []),
    'createdAt': () => resource === 'PracticeIndustry' ? new Date(row.createdAt).toLocaleDateString() : '',
    '_ts': () => row._ts,
    'writtenDate': () => row[column.id],
    'publishedDate': () => row[column.id],
    'reviewedDate': () => row[column.id],
    'hasState': () => row[column.id] ? 'YES' : 'NO',

  };

  for (const key in mappings) {
    if (column.id.startsWith(key) || column.id === key) {
      return mappings[key]();
    }
  }

  return row[column.id] || '';
};

export const transformData = (
  data: any[],
  columnConfigs: ColumnConfig[],
  currentPage: number,
  recordsPerPage: number,
  firms?: any,
  practiceIndustries?: any,
  resource?: string,
  countries?: any,
  states?: any,
  substates?: any,
  jurisdictionGroups?: any,
  regions?: any
): Record<string, any>[] => {
  return data.map((row: any, index: number) => {
    const context = { index, currentPage, recordsPerPage, firms, practiceIndustries, resource, countries, states, substates, jurisdictionGroups, regions };
    const transformedRow: Record<string, any> = {};

    columnConfigs.forEach((column: ColumnConfig) => {
      transformedRow[column.id] = getValue(row, column, context);
    });

    return transformedRow;
  });
};



export const findPracticeIndustryNames = (areaOfPracticeId: string, practiceIndustries: any[]) => {
  const industryNames = [];
  if (practiceIndustries) {
    for (const industry of practiceIndustries) {
      if (industry && Array.isArray(industry.areasOfPractice)) {
        const areasOfPractice = industry.areasOfPractice;
        if (areasOfPractice.some((aop: any) => aop.id === areaOfPracticeId)) {
          industryNames.push(industry.name);
        }
      }
    }
  }
  return industryNames.length > 0 ? industryNames.join(', ') : 'Not Mapped';
};
