import React, { useEffect, useState } from "react";
import SvgIcon from "./IconComponent";
import ChevronRight from "../Atomos/Icons/ChevronRight";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Context";
import PencilEdit from "../Atomos/Icons/PencilEdit";

interface AreasOfPracticeCardProps {
  person?: any;
  personAOPS?: any;
  isEditable?: boolean;
  practiceIndustriesMatched: any;
}

const AreasOfPracticeCard: React.FC<AreasOfPracticeCardProps> = ({
  person,
  personAOPS,
  isEditable,
  practiceIndustriesMatched,
}) => {
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [AOPS, setAOPS] = useState<any[]>([]);
  const [screenSize, setScreenSize] = useState('desktop');
  const [visibleCount, setVisibleCount] = useState(8);
  const [viewMoreActive, setViewMoreActive] = useState(false);

  const { mode } = useAppContext();

  const updateScreenSize = () => {
    if (window.innerWidth < 768) {
      setScreenSize('mobile');
      setVisibleCount(4);
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setScreenSize('tablet');
      setVisibleCount(8);
    } else if (window.innerWidth >= 1024) {
      setScreenSize('desktop');
      setVisibleCount(8);
    }
  };

  useEffect(() => {
    if(practiceIndustriesMatched){
      console.log('Matched',practiceIndustriesMatched)
    }
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    if (personAOPS) {
      setAOPS(personAOPS);
    }
  }, [personAOPS]);

  const toggleViewMore = () => {
    setViewMoreActive(!viewMoreActive);
    if (viewMoreActive) {
      setVisibleCount(screenSize === 'mobile' ? 4 : 8);
    } else {
      setVisibleCount(practiceIndustriesMatched.length);
    }
  };

  const handleClickIndustry = (industry: any) => {
    const isSelected = selectedIndustries.includes(industry.id);
    if (isSelected) {
      setSelectedIndustries(selectedIndustries.filter(id => id !== industry.id));
    } else {
      setSelectedIndustries([...selectedIndustries, industry.id]);
    }
  };

  const columns = Math.max(Math.ceil(AOPS?.length / 14), 1);
  const columnItems = AOPS?.length && columns
    ? new Array(columns).fill([]).map((_, index) => AOPS.slice(index * Math.ceil(AOPS.length / columns), (index + 1) * Math.ceil(AOPS.length / columns)))
    : [];

  const editLinkPath = mode === 'staff'
    ? `/staff/profile/edit/attorney/${person?.id}/${person?.generatedId}`
    : `/profile/edit/attorney/${person?.id}/${person?.generatedId}`;

  return (
    <div className="flex flex-col py-5 px-4 gap-[1.25rem] self-stretch items-end md:py-7 md:px-7 lg:py-10 lg:px-10 max-h-[53rem] overflow-y-scroll md:max-h-[45rem] lg:max-h-[45.5rem]">
      <div className="flex flex-col items-end gap-6 self-stretch">
        <div className="grid grid-cols-2 items-start gap-5 self-stretch
          md:grid-cols-4 md:flex-wrap md:gap-5
          lg:grid-cols-4 lg:grid">
          {practiceIndustriesMatched.slice(0, visibleCount).map((practice: any) => (
            <div key={practice.id} className={`flex lg:flex-1 flex-col items-center gap-[.5625rem] h-auto self-stretch cursor-pointer`}
              onClick={() => handleClickIndustry(practice)}
            >
              <div className="flex w-10 h-10 lg:w-[3.75rem] lg:h-[3.75rem] justify-center">
                <SvgIcon isSelected={selectedIndustries.includes(practice.id)}
                  practiceId={practice.id} practiceName={practice.name} />
              </div>
              <span className="lg:whitespace-prewrap lg:w-10/12 lg:text-center text-[1rem] text-[#707070] leading-[1.125rem] text-center font-decimal font-medium lg:text-[1.25rem] lg:leading-[1.5rem]">
                {practice.name}
              </span>
            </div>
          ))}
        </div>
        {
          (screenSize === 'mobile' && practiceIndustriesMatched.length > 4) ||
          ((screenSize === 'tablet' || screenSize === 'desktop') && practiceIndustriesMatched.length > 8) ? (
            <div className="flex w-full p-0 justify-end items-center self-stretch cursor-pointer gap-[.625rem]" onClick={toggleViewMore}>
              <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">
                {!viewMoreActive ? 'View More' : 'View Less'}
              </span>
              <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                <div className={`w-3 h-3 fill-white duration-300 ${viewMoreActive ? 'rotate-180' : ''}`}>
                  <ChevronRight />
                </div>
              </div>
            </div>
          ) : null
        }
        <div className="flex flex-col items-end gap-6 self-stretch">
          <div className="flex flex-col items-start gap-[.625rem] self-stretch md:gap-5">
            {screenSize === 'mobile' &&
              <div className="flex flex-col md:flex-row gap-[.625rem] justify-between self-stretch w-full flex-1">
                {AOPS?.map((aop: any) => (
                  <span key={aop.id} className="col-span-1 text-[#474F56] text-base leading-6 md:w-1/10">
                    &bull; {aop.name}
                  </span>
                ))}
              </div>
            }
            {(screenSize === 'tablet' || screenSize === 'desktop') &&
              <div className={`flex flex-row self-stretch w-full gap-5 ${columnItems?.length <= 2 ? 'justify-stretch' : 'justify-between'}`}>
                {columnItems.map((column, columnIndex) => (
                  <div key={columnIndex} className="flex flex-col gap-[.625rem]">
                    {column.map((aop: any) => (
                      <span key={aop.id} className="text-[#474F56] text-base leading-6">
                        &bull; {aop.name}
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
        {isEditable &&
          <Link to={editLinkPath} state={{ ...person, isOnlyAops: true }}>
            <div className="flex p-0 justify-center items-start gap-[.625rem]">
              <span className="text-[#0070CD] text-right font-decimal text-base font-bold leading-6 uppercase lg:text-[1.125rem] lg:leading-[1.5rem]">Edit</span>
              <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-Default">
                <PencilEdit className='w-full h-full pencilBlue' />
              </div>
            </div>
          </Link>
        }
      </div>
    </div>
  );
}

export default AreasOfPracticeCard;
