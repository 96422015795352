import React from "react";
 

const RedPencil: React.FC  = ( )  => {
  return (
    <svg
    className="w-full h-full"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 19 19"
    >
      <path  
        d="M11.52 2.497a3.522 3.522 0 014.987 0 3.536 3.536 0 010 4.995l-.66.66-4.986-4.994.66-.66zM4.377 19H0v-4.912l.047-.048-.015-.015 1.59-1.593h.001l8.493-8.507 4.913 4.922-8.354 8.368-1.432 1.435.005.005-.344.345h-.527zm-1.418-1.421l-.02-.019.064-1.593-1.585.063v-1.193l1.144-1.146 2.75 2.755-1.134 1.136-.004-.003H2.96zm.454-4.666l6.802-6.814.45.451-6.801 6.814-.45-.451z"
      ></path>
    </svg>
  );
}

export default RedPencil;
