import { useState } from 'react';
import Axios from '../../utils/axios';
import useAreaOfPractice from '../AreaOfPractice/useAreaOfPractice';
import usePracticeIndustries from '../PracticeIndustries/usePracticeIndustry';
import { IPracticeIndustry } from '../../interfaces/models/IPracticeIndustry';
import { IAreaOfPractice } from '../../interfaces/models/IAreaOfPractice';
const axios = new Axios();

const usePracticeIndustryMapping = (initialIndustries: IPracticeIndustry[], initialAOPs: IAreaOfPractice[], state: IAreaOfPractice) => {
  const [currentAOPS, setCurrentAOPS] = useAreaOfPractice(); //TODO replace for AOP redux and hook
  const [currentPracticeIndustries, setCurrentPracticeIndustries] = usePracticeIndustries(state?.id, { id: state?.id, name: state?.name });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleCheckboxChange = (industryId: string) => {
    const newPracticeIndustries = currentPracticeIndustries.map(industry =>
      industry.id === industryId ? { ...industry, isChecked: !industry.isChecked } : industry
    );
    setCurrentPracticeIndustries(newPracticeIndustries);
    updateErrors(null);
  };


  const prepareUpdatedIndustries = (
    allIndustries: IPracticeIndustry[],
    selectedIndustries: IPracticeIndustry[],
    areaOfPractice: { id: string; name: string }
  ): { toAdd: IPracticeIndustry[], toRemove: IPracticeIndustry[] } => {
    let toAdd: IPracticeIndustry[] = [];
    let toRemove: IPracticeIndustry[] = [];

    allIndustries.forEach(industry => {
      const isSelected = selectedIndustries.some(selected => selected?.id === industry?.id);
      const areaExists = industry.areasOfPractice?.some(ap => ap.id === areaOfPractice.id);

      if (isSelected && !areaExists) {
        toAdd.push({
          ...industry,
          areasOfPractice: [...(industry.areasOfPractice || []), areaOfPractice]
        });
      } else if (!isSelected && areaExists) {
        toRemove.push({
          ...industry,
          areasOfPractice: industry.areasOfPractice?.filter(ap => ap?.id !== areaOfPractice?.id) || []
        });
      }
    });
    return { toAdd, toRemove };
  };

  const updateIndustries = async (industriesToAdd: IPracticeIndustry[], industriesToRemove: IPracticeIndustry[]) => {
    for (const industry of industriesToAdd) {
      await sendUpdateRequest(industry);
    }

    for (const industry of industriesToRemove) {
      await sendUpdateRequest(industry);
    }
  };

  const sendUpdateRequest = async (industry: IPracticeIndustry) => {
    try {
      const response = await axios.Put(`/PracticeIndustry?id=${industry.id}`, industry);
      if (response.status !== 200) {
        console.error("Error updating industry", industry.id, response.data);
      }
    } catch (error) {
      console.error("Network error updating industry", industry.id, error);
    }
  };

  const updateErrors = (newError: string | null) => {
    if (newError) {
      setErrors({ ...errors, 'selection': newError });
    } else {
      const updatedErrors = { ...errors };
      delete updatedErrors['selection'];
      setErrors(updatedErrors);
    }
  };

  return {
    currentPracticeIndustries,
    setCurrentPracticeIndustries,
    currentAOPS,
    setCurrentAOPS,
    errors,
    handleCheckboxChange,
    prepareUpdatedIndustries,
    updateIndustries,
    sendUpdateRequest,
    updateErrors
  };
};

export default usePracticeIndustryMapping;
