import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const practiceIndustriesSlice = createSlice({
  name: "practiceIndustries",
  initialState: Empty,
  reducers: {
    SetPracticeIndustries: (_, action) =>  action.payload,
  },
});

export const { SetPracticeIndustries } = practiceIndustriesSlice.actions;
