import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from "../../assets/images&icons/profileViewMode.jpeg";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import TabManager from "../../components/Layout/TabManager/TabManager";
import { useEffect, useState } from "react";
import CatalogTableC from "../../components/Catalogs/CatalogTableContent";
import {
  countContents,
  getFilteredContents,
  countUniqueMembersAuthors,
  countFirmsWithArticles,
} from "../../Services/Content";
import { contentInitialState, IContent, IContentFilters } from "../../interfaces";
import {
  contentStatusEnum,
  isApprovedEnum,
  submissionTypeEnum,
} from "../../Enums/ContentEnums";
import moment from "moment";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { UsePracticeIndustryList } from "../../hooks/Catalogs/usePracticeIndustry";
import { UseCountryList } from "../../hooks/Catalogs";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import ContentFilter from "../../components/Catalogs/Filters/ContentFilter";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import {
  ColumnConfig,
  useColumnConfig,
} from "../../hooks/Table/UseColumnSettings";
import { useTableSettings } from "../../hooks/Table/UseTableSettings";
import { Link, useLocation } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/loaderActions";
import { contentsSlice } from "../../redux";
import { useParams } from "react-router-dom";
import MenuGT from "../ManageFirm/GeneralTab/Menu";
import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import {
  formatDateToString,
  formatTimeSpanToString,
} from "../../utils/functions";
import useDataLoader2 from '../../hooks/useDataLoader2';
import { IFilters } from "../../interfaces/Components/ITable";

interface contentProps {
  breadcrumb :{prevPage: string, currentPage: string}
}

const ContentHome: React.FC<contentProps> = (props) => {
  const { id } = useParams<Record<string, string | undefined>>();
  const DefaultPageSize = 50;
  const [selectedTab, setSelectedTab] = useState(0);
  const [tableInstance, setTableInstance] = useState<any>();
  const [KPI, setKPI] = useState({
    uniqueMemberAuthors: undefined,
    firmsWithArticles: undefined,
  });
  const [disabledFirmFilter, setDisabledFirmFilter] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { state } = useLocation();
  const { countryData, isFetching: countryLoading } = UseCountryList();
  const { firmData, isFetching: firmLoading  } = UseFirmList();
  const { jurisdictionData, isFetching: jurisdictionLoading } = UseJurisdictionList();
  const { practiceIndustryData, isFetching: practiceIndustryLoading } = UsePracticeIndustryList();
  const { regionData, isFetching: regionLoading } = UseRegionList();
  const dispatch = useDispatch();
  const Headers: string[] = [
    "shortId",
    "title",
    "firms",
    "type",
    "isApproved",
    "writtenDate",
    "publishedDate",
    "reviewedDate",
    "practiceIndustries",
    "views",
    "jurisdictions",
    "regions",
    "_ts",
  ];
  const { generateColumns } = useColumnConfig(Headers);
  const contentType = [
    "article",
    "press",
    "guide",
    "successStory",
    "sharedNews",
    "memberReports"
  ];
  const [contentFilters, setContentFilters] = useState<IContentFilters>(contentInitialState);
  const [processedData, setProcessedData] = useState<any[]>([]);

  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/content',
    defaultPageSize: DefaultPageSize,
  });

  useEffect(() => {
    setProcessedData(processData(data))
  }, [data])


  useEffect(() => {
    if (
      firmLoading ||
      practiceIndustryLoading ||
      regionLoading ||
      jurisdictionLoading ||
      countryLoading
    ) {
      return;
    }

    let filtersAux = id ? { memberFirms: { value: [id] } } : {};

    const selectedFirm = id ? [firmData.find((f: any) => f.id === id)] : [];
    
    if (selectedFirm.length) {
      setContentFilters({...contentFilters, firms: selectedFirm})
      setDisabledFirmFilter(true);
    } 
    else {
      setDisabledFirmFilter(false);
    }
    if (!isFetching) handleSearch(0, filtersAux);
    setIsFetching(true)

    if (!KPI.uniqueMemberAuthors || !KPI.firmsWithArticles) updateKPI(filtersAux);
    dispatch(contentsSlice.actions.InitializeContent());
  }, [
    firmLoading,
    practiceIndustryLoading,
    regionLoading,
    jurisdictionLoading,
    countryLoading,
  ]);

  useEffect(() => {
    dispatch(showLoader());
  },[])

  const updateKPI = async (filtersAux: any) => {
    try {
      const [uniqueMembersResp, firmsResp] = await Promise.all([
        countUniqueMembersAuthors(filtersAux),
        countFirmsWithArticles(filtersAux),
      ]);

      setKPI((prevState) => ({
        ...prevState,
        uniqueMemberAuthors: uniqueMembersResp.uniqueMemberAuthors,
        firmsWithArticles: firmsResp.totalFirmWithArticles,
      }));
    } catch (error) {
      console.error("Error updating KPI:", error);
    }
  };
  const handleSearch = async (
      tab: number,
      firmFilter?: any
    ) => {
    setSelectedTab(tab);
    let auxFilter:{[key:string]: IFilters} = {contentType: {value: contentType[tab], comparisonType:'EQUAL'}}
    if (firmFilter) auxFilter = {
      ...auxFilter, 
      ...firmFilter
    }
    fetchData(false, auxFilter);
  };

  const processData = (data: IContent[]) => {
    return data.map((d: IContent) => ({
      ...d,
      type: submissionTypeEnum[d.submissionType],
      isApproved: d.isApproved,
      writtenDate: d.writtenDate && formatDateToString(new Date(d.writtenDate)),
      publishedDate:
        d.publishedDate === "pending"
          ? contentStatusEnum.pending
          : d.publishedDate && formatDateToString(new Date(d.publishedDate)),
      reviewedDate:
        d.reviewedDate === "pending"
          ? contentStatusEnum.pending
          : d.reviewedDate && formatDateToString(new Date(d.reviewedDate)),
      firms: getNameById(d.memberFirms.concat(d.nonMemberFirms), firmData).join(
        ", "
      ),
      practiceIndustries: getNameById(
        d.practiceIndustries,
        practiceIndustryData
      ),
      jurisdictions: getNameById(d.jurisdictions, jurisdictionData),
      regions: getRegionNames(d.jurisdictions),
      views: d.views ?? 0,
    }));
  };

  const getNameById = (field: any, data: any) => {
    if (!field) return [];
    if (field.includes("GlobalLocation")) return ["Global"];
    return data
      .filter((d: any) => field.includes(d.id))
      .map((r: any) => r.name);
  };

  const getRegionNames = (field: any) => {
    if (!field || !field.length) return [];

    if (field.includes("GlobalLocation")) return ["Global"];

    const countryIds: string[] = jurisdictionData
      .filter((j: any) => field.includes(j.id))
      .map((r) => r.countryId || r.country)
      .filter((id): id is string => id !== undefined);

    if (!countryIds.length) return [];

    const regionIds: any = countryData
      .filter((c: any) => countryIds.includes(c.id))
      .map((r) => r.regions.map((s: any) => s.id));

    if (!regionIds.length) return [];

    const region: any[] = regionData
      .filter((r: any) => regionIds.flat().includes(r.id))
      .map((m) => m.name);

    return region.join(", ");
  };

  const tabs = [
    { title: "Articles", action: () => handleSearch(0) },
    { title: "Presses", action: () => handleSearch(1) },
    { title: "Guides", action: () => handleSearch(2) },
    { title: "Success Stories", action: () => handleSearch(3) },
    { title: "News", action: () => handleSearch(4) },
    { title: "Member Reports", action: () => handleSearch(5) },
  ];

  const clearSelections = () => {
    let auxFilters = contentInitialState
    if (id) auxFilters = {...auxFilters, firms:[{id:id, name:id}]}
    setContentFilters(auxFilters)
    handleSearch(selectedTab)
  };

  useEffect(() => {
    let filterAux = {
      title: {
        value: contentFilters.title,
        comparisonType: 'CONTAINS'
      },
      memberFirms: {
        value: contentFilters.firms.map(c => c.id),
      },
      isApproved: {
        value: contentFilters.isApproved.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      practiceIndustries: {
        value: contentFilters.practiceIndustries.map(c => c.id),
      },
      regions: {
        value: contentFilters.regions.map(c => c.id),
      },
      jurisdictions: {
        value: contentFilters.regions.map(c => c.id),
      },
      writtenDate: {
        value: contentFilters.writtenDate,
        comparisonType: 'BETWEEN'
      },
      publishedDate: {
        value: contentFilters.publishedDate,
        comparisonType: 'BETWEEN'
      },
    }

    setFilter(filterAux)
  },[contentFilters])

  const columnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false },
    { id: "shortId", header: "Short ID" },
    { id: "title", header: "Title" },
    { id: "firms", header: "Firms", cell: (props: any) => props.getValue() },
    {
      id: "isApproved",
      header: "Approval Status",
      cell: (props: any) => {
        const value = props.getValue();

        let text = "";
        let color = "";

        switch (value) {
          case "yes":
            text = "Approved";
            color = "#2AA042";
            break;
          case "no":
            text = "Rejected";
            color = "#FF6109";
            break;
          case "pending":
            text = "Pending";
            color = "#C6A80D";
            break;
          default:
            text = value;
            break;
        }

        return <span style={{ color }}>{text}</span>;
      },
    },
    { id: "reviewedBy", header: "Reviewed By" },
    {
      id: "writtenDate",
      header: "Written Date",
      cell: (props: any) =>
        props.getValue() === "pending"
          ? isApprovedEnum["pending"]
          : props.getValue(),
    },
    {
      id: "publishedDate",
      header: "Published Date",
      cell: (props: any) =>
        props.getValue() === "pending"
          ? isApprovedEnum["pending"]
          : props.getValue(),
    },
    {
      id: "practiceIndustries",
      header: "Practice Industries",
      cell: (props: any) => props.getValue().join(", "),
    },
    { id: "views", header: "Views" },
    { id: "jurisdictions", header: "Jurisdictions" },
    {
      id: "regions",
      header: "Regions",
      cell: (props: any) => {
        const value = props.getValue();
        const isGlobal = props.row.original.jurisdictions;
        return isGlobal == "Global" ? "Global" : value;
      },
    },
    {
      id: "_ts",
      header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return value ? formatTimeSpanToString(value) : "";
      },
    },
    {
      id: "manage",
      header: "Manage",
      cell: (props: any) => (
        <Link
          className="edit"
          to={`${disabledFirmFilter 
            ? '/Staff/ManageFirm/Content/' 
            : ''}Manage/${props.row.original.id}`
          }
          state={data[props.row.id]}
        >
          Manage
          <div className="editsvg">
            <ShapeEdit />
          </div>
        </Link>
      ),
      enableSorting: false,
    },
  ];

  const columns = generateColumns(
    columnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          if (column.id === "count") {
            return (
              <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
            );
          } else {
            return props.getValue();
          }
        }),
    }))
  );

  return (
    <>
      {state && <MenuGT state={state} />}
        <BackgroundComponent image={ProfileViewModeBg} />
        <div className="bg-gray-100 min-h-screen text-left px-4 py-7 items-start flex flex-col gap-[1.5rem] md:py-8 md:px-6 lg:py-10 lg:px-10 lg:gap-[2.5rem]">
          <div className="flex flex-col items-start gap-5 self-stretch">
            <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 self-stretch !p-0 flex-col ">
              <div className="flex p-0 flex-col justify-center gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
                <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">
                  Content
                </h2>
              </div>
              <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
              <div className="flex w-full">
                <CurrentPage 
                  prevPage={props.breadcrumb.prevPage} 
                  currentPage={props.breadcrumb.currentPage} 
                />
              </div>
              <div className="flex w-full text-center">
                <div className="w-full md:w-[28rem] flex gap-5">
                  <div className="flex flex-col">
                    <div className="bg-Default text-xs lg:text-base text-white py-[3px] px-4">
                      # UNIQUE MEMBER AUTHORS
                    </div>
                    <div className="bg-white text-[26px] md:text-[28px] lg:text-[32px] font-medium p-4">
                      {KPI.uniqueMemberAuthors || '-'}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="bg-Default text-xs lg:text-base text-white py-[3px] px-4">
                      # TOTAL FIRM WITH ARTICLES
                    </div>
                    <div className="bg-white text-[26px] md:text-[28px] lg:text-[32px] font-medium p-4">
                      {KPI.firmsWithArticles || '-'}
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <TabManager tabs={tabs} selectedTabIndex={selectedTab} />
                <CatalogTableC
                  catalogName={"CONTENT"}
                  route={"Content"}
                  catalogShortName={tabs[selectedTab].title}
                  isfiltered={true}
                  data={data}
                  headers={Headers}
                  totalCount={totalCount}
                  exportData={data}
                  selectedTab={selectedTab}
                  linkToAdd={disabledFirmFilter 
                    ? "/Staff/ManageFirm/Content/Create" 
                    : "/staff/content/create"}
                    firmId={id}
                >
                <ContentFilter
                  filters={contentFilters}
                  setFilters={setContentFilters}
                  headers={Headers}
                  clearSelections={clearSelections}
                  Search={fetchData}
                  tableInstance={tableInstance}
                  disabledFirmFilter={disabledFirmFilter}
                />
                  <TableWithPagination
                    data={processedData}
                    columnsDef={columns}
                    setTableInstance={setTableInstance}
                    sorting={sort}
                    setSorting={setSort}
                    totalCount={totalCount}
                    pagination={pagination}
                    setPagination={setPagination}
                  />
                </CatalogTableC>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default ContentHome;
