import React, { useEffect, useState } from "react";
import CatalogTableC from "../../components/Catalogs/CatalogTableContent";
import ContentFilter from "../../components/Catalogs/Filters/ContentFilter";
import { contentInitialState, IContent, IContentFilters } from "../../interfaces/models/IContent";
import {
  contentStatusEnum,
  isApprovedEnum,
  submissionTypeEnum,
} from "../../Enums/ContentEnums";
import { UseCountryList } from "../../hooks/Catalogs/useCountry"; 
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { UsePracticeIndustryList } from "../../hooks/Catalogs/usePracticeIndustry";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import TableWithPagination from "../../components/Organismos/Table/TableTransitionToTanSack2";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import { useDispatch } from "react-redux";
import { contentsSlice } from "../../redux";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import {
  formatDateToString,
  formatTimeSpanToString,
} from "../../utils/functions";
import useDataLoader2 from '../../hooks/useDataLoader2';

const Content: React.FC = () => {
  const dispatch = useDispatch();

  const Headers: string[] = [
    "shortId",
    "title",
    "firms",
    "type",
    "isApproved",
    "writtenDate",
    "publishedDate",
    "reviewedDate",
    "practiceIndustries",
    "views",
    "jurisdictions",
    "regions",
    "_ts",
  ];
  const DefaultPageSize = 50;
  
  const { generateColumns } = useColumnConfig(Headers);
  const [tableInstance, setTableInstance] = useState<any>();
  const [contentFilters, setContentFilters] = useState<IContentFilters>(contentInitialState);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/content',
    defaultPageSize: DefaultPageSize,
  });

  useEffect(() => {
    setProcessedData(processData(data))
  }, [data])

  const columnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false },
    { id: "shortId", header: "Short ID" },
    { id: "title", header: "Title" },
    { id: "firms", header: "Firms", cell: (props: any) => props.getValue() },
    { id: "type", header: "Type" },
    {
      id: "isApproved",
      header: "Approval Status",
      cell: (props: any) => {
        const value = props.getValue();
        let text = "";
        let color = "";

        switch (value) {
          case "Yes":
            text = "Approved";
            color = "#2AA042";
            break;
          case "No":
            text = "Rejected";
            color = "#FF6109";
            break;
          case "Pending":
            text = "Pending";
            color = "#C6A80D";
            break;
          default:
            text = value;
            break;
        }

        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      id: "writtenDate",
      header: "Written Date",
    },
    {
      id: "publishedDate",
      header: "Published Date",
    },
    {
      id: "reviewedDate",
      header: "Reviewed Date",
    },
    {
      id: "practiceIndustries",
      header: "Practice Industries",
    },
    { id: "views", header: "Views" },
    { id: "jurisdictions", header: "Jurisdictions" },
    {
      id: "regions",
      header: "Regions",
    },
    {
      id: "_ts",
      header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return value ? formatTimeSpanToString(value) : "";
      },
    },
    {
      id: "edit",
      header: "Edit",
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={{content: data[props.row.id]}}
        >
          Edit
          <div className="editsvg">
            <ShapeEdit />
          </div>
        </Link>
      ),
      enableSorting: false,
    },
  ];

  const columns = generateColumns(
    columnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          if (column.id === "count") {
            return (
              <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
            );
          } else {
            return props.getValue();
          }
        }),
    }))
  );

  const { countryData, isFetching: countryLoading } = UseCountryList();
  const { firmData, isFetching: firmLoading  } = UseFirmList();
  const { jurisdictionData, isFetching: jurisdictionLoading } = UseJurisdictionList();
  const { practiceIndustryData, isFetching: practiceIndustryLoading } = UsePracticeIndustryList();
  const { regionData, isFetching: regionLoading } = UseRegionList();

  const clearSelections = () => {
    setContentFilters(contentInitialState)
    fetchData(true)
  };

  useEffect(() => {
    dispatch(contentsSlice.actions.InitializeContent());
  }, []);

  useEffect(() => {
    if (
      !firmLoading &&
      !practiceIndustryLoading &&
      !regionLoading &&
      !jurisdictionLoading &&
      !countryLoading
    ) {
      if(!isFetching)fetchData();
      setIsFetching(true)
    }
  }, [
    firmLoading,
    practiceIndustryLoading,
    regionLoading,
    jurisdictionLoading,
    countryLoading
  ]);

  const processData = (data: IContent[]) => {
    return data.map((d: IContent) => ({
      ...d,
      type: submissionTypeEnum[d.submissionType],
      isApproved: isApprovedEnum[d.isApproved],
      writtenDate: d.writtenDate && formatDateToString(new Date(d.writtenDate)),
      publishedDate:
        d.publishedDate === "pending"
          ? contentStatusEnum.pending
          : d.publishedDate && formatDateToString(new Date(d.publishedDate)),
      reviewedDate:
        d.reviewedDate === "pending"
          ? contentStatusEnum.pending
          : d.reviewedDate && formatDateToString(new Date(d.reviewedDate)),
      firms: d.firmNames,
      practiceIndustries: d.practiceIndustryNames,
      jurisdictions: d.jurisdictionNames,
      regions: d.regionNames,
      views: d.views ?? 0,
    }));
  };
  
  useEffect(() => {
    let filterAux = {
      title: {
        value: contentFilters.title,
        comparisonType: 'CONTAINS'
      },
      memberFirms: {
        value: contentFilters.firms?.map(c => c.id),
      },
      isApproved: {
        value: contentFilters.isApproved.map(c => c.id),
        comparisonType: 'EQUAL'
      },
      practiceIndustries: {
        value: contentFilters.practiceIndustries.map(c => c.id),
      },
      regions: {
        value: contentFilters.regions.map(c => c.id),
      },
      jurisdictions: {
        value: contentFilters.regions.map(c => c.id),
      },
      writtenDate: {
        value: contentFilters.writtenDate,
        comparisonType: 'BETWEEN'
      },
      publishedDate: {
        value: contentFilters.publishedDate,
        comparisonType: 'BETWEEN'
      },
    }
    setFilter(filterAux)
  },[contentFilters])

  return (
    <>
      <main className="mainSection">
        <CatalogTableC
          title={`Content Catalog`}
          catalogName={"CONTENTS"}
          route={"Content"}
          catalogShortName={"CONTENT"}
          isfiltered={true}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={data}
          newItemAction={() => {
            dispatch(contentsSlice.actions.InitializeContent());
          }}
        >
          <ContentFilter
            filters={contentFilters}
            setFilters={setContentFilters}
            headers={Headers}
            clearSelections={clearSelections}
            Search={fetchData}
            tableInstance={tableInstance}
            disabledFirmFilter={false}
          />
          <TableWithPagination
            data={processedData}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />
        </CatalogTableC>
      </main>
    </>
  );
};

export default Content;
