import { createColumnHelper, ColumnDef } from '@tanstack/react-table';

export interface ColumnConfig {
  id: string;
  header: string;
  cell?: (props: any) => JSX.Element | string;
  enableSorting?: boolean;
  size?: number;
}

export const useColumnConfig = (defaultHeaders: string[]) => {
  const columnHelper = createColumnHelper<any>();

  const generateColumns = (columns: ColumnConfig[]): ColumnDef<any>[] => {
    return columns.map(column => columnHelper.accessor(column.id, {
      header: column.header,
      cell: column.cell,
      enableSorting: column.enableSorting ?? true,
      size: column.size,
    }));
  };

  return { generateColumns };
};
