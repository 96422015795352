import React, { useEffect, useRef, useState } from 'react'; 
import css from './FileInputButton.module.css';
import Axios from '../../../utils/axios'; 
import Folder from '../../Atomos/Icons/EditMode/Folder';
import { uploadImage } from '../../../utils/UploadImage';
interface FileInputButtonProps {
    onImageUpload: (url: string) => void;
    pIndustry?: string | null;
    title?: string;
    required?: boolean;
    triggerUpload?: boolean;
    triggerDelete?: boolean;
    onFileSelected: (file: File) => void;
    onFileRemoved: () => void;
    generalCategory?: string | null;
    imageCategory?: String | null;
    ownerId?: string | null;
  }

  const FileInputButton: React.FC<FileInputButtonProps> = ({
    required,
    onImageUpload,
    pIndustry,
    title,
    triggerDelete,
    triggerUpload,
    onFileSelected,
    onFileRemoved,
    generalCategory,
    imageCategory,
    ownerId
  }) => {
  
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const axiosInstance = new Axios();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  


  const handleRemoveImage = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; 
    }
    setSelectedFile(null);
    onFileRemoved()
    setUploadError(null);
  };




  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 25) {
        setUploadError("File size exceeds 25 MB limit");
      } else {
        setSelectedFile(file);
        setUploadError(null);
        onFileSelected(file);
      }
    }
  }

  useEffect(() => {
    if (triggerUpload) {
      handleUpload();
    }
  }, [triggerUpload]);


  useEffect(() => {
    if (triggerDelete) {
      handleRemoveImage();
    }
  }, [triggerDelete]);

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const regex = /[\s\uFEFF\xA0]+/g;
        const sanitizedCategory = imageCategory?.replace(regex, '').trim();

          await uploadImage(selectedFile as File, `${sanitizedCategory}/${(ownerId)?.trim()}` as string, 'practice-industry/')
        .then(response => {
          if(response){
            onImageUpload(response);
          } else {
            setUploadError("Error uploading file");
          }
        })
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };




  return (
    <>
      <div className='lg:flex flex-col justify-between flex gap-[.625rem] self-stretch'>
      <span
        className={`text-base leading-[1.125rem] ${
          required ? 'text-[#BC3323]' : ' text-neutral-dark'
        }`}
      >
        Icon
      </span>
        <label
          className={`flex py-2 pr-2 pl-3 justify-center items-center gap-2 self-stretch border border-solid border-[#474F56] md:w-[11.3125rem]  lg:py-3 lg:pr-2 lg:pl-3 lg:w-[11.8125rem]
          text-[#474F56] text-center font-decimal font-base font-medium leading-[1.125rem] md:text-[1.125rem] md:leading-5`}
          htmlFor="fileInput"
          id="customFileInput"
        >
          <input
            type="file"
            id="fileInput"
            className='pointer-events-none'
            onChange={handleFileInput}
            style={{
              position: 'absolute',
              opacity: 0,
            }}
            name='fileInput'
          />
          <div className={`${css.customInputContainer} flex gap-2 items-center`}>
            <span className='font-medium font-decimal text-[1rem] leading-[1.125rem] lg:text-[1.125rem] lg:leading-5'>SELECT FILE</span>
            <div className='fill-[#474F56] w-5 h-5 lg:w-6 lg:h-6'>
            <Folder />
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default FileInputButton;
