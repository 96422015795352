import React from "react";

function Rewind() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        d="M10.007 17.083a6.88 6.88 0 01-2.763-.558 7.172 7.172 0 01-2.246-1.516 7.172 7.172 0 01-1.516-2.247A6.88 6.88 0 012.924 10c0-.983.186-1.904.558-2.762A7.172 7.172 0 014.998 4.99a7.173 7.173 0 012.246-1.516 6.88 6.88 0 012.763-.558c1.048 0 2.044.22 2.987.661a7.074 7.074 0 012.43 1.858V3.461h1.25v4.23h-4.231v-1.25h2.18a6.298 6.298 0 00-2.057-1.668 5.57 5.57 0 00-2.559-.607c-1.625 0-3.003.566-4.135 1.698-1.132 1.132-1.698 2.51-1.698 4.135s.566 3.004 1.698 4.136c1.132 1.132 2.51 1.698 4.135 1.698 1.459 0 2.73-.474 3.815-1.421 1.085-.947 1.733-2.14 1.943-3.58h1.276c-.203 1.791-.975 3.28-2.316 4.469-1.34 1.188-2.913 1.782-4.718 1.782zm2.478-3.728l-3.103-3.103v-4.42h1.25v3.914l2.73 2.73-.877.88z"
      ></path>
    </svg>
  );
}

export default Rewind;
