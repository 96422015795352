import React from "react";

function Folder(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M1.646 20.354V3.633h8.723l1.656 1.67h10.342v1.98h-11.19L9.52 5.627H3.627V18.43l2.508-9.647h17.647l-3.088 11.57H1.646zm4.093-1.981h13.466l1.86-7.596H7.599l-1.86 7.596z"></path>
      </svg>
    </div>
  );
}

export default Folder;
