import React, { useState } from "react";
import ProfileViewModeBg from "../../assets/images&icons/bg-login.jpg";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);

    const isPasswordValid =
      newPassword.trim() === newPassword && // No leading or trailing spaces
      newPassword.length >= 6 && // Minimum 6 characters
      /[A-Z]/.test(newPassword) && // At least 1 uppercase letter
      /\d/.test(newPassword); // At least 1 number

    setIsPasswordValid(isPasswordValid);
    if (!isPasswordValid) {
      setErrorMessage("Password must be at least 6 characters long, contain at least 1 uppercase letter and 1 number, and have no leading or trailing spaces.");
    } else {
      setErrorMessage("");
    }
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setConfirmPassword(password);
    if (password !== newPassword) {
      setErrorMessage("Passwords do not match.");
    } else if (!isPasswordValid) {
      setErrorMessage("Password must be at least 6 characters long, contain at least 1 uppercase letter and 1 number, and have no leading or trailing spaces.");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isPasswordValid) {
      setErrorMessage("Password must be at least 6 characters long, contain at least 1 uppercase letter and 1 number, and have no leading or trailing spaces.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      // Aquí iría la lógica para restablecer la contraseña
      console.log('Password reset successfully');
    } catch (error) {
      setErrorMessage("Failed to reset password. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <div
        className="absolute w-full min-h-screen"
        style={{
          backgroundImage: `url(${ProfileViewModeBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </div>
      <div className="w-full min-h-screen justify-center items-center flex self-stretch relative">
        <div className="lg:w-[520px]">
          <div className={`flex w-full align-middle justify-center gap-28 px-5 py-4 bg-[#9C3325]`}>
            <h2 className={`text-center text-xl font-sans text-white roboto-font`}>RESET PASSWORD</h2>
          </div>
          <div className='bg-white w-full py-4'>
            <div className='text-black py-8 flex flex-col gap-4'>
              <div className='w-full px-5'>
                <input
                  type="password"
                  placeholder='Enter New Password'
                  value={newPassword}
                  onChange={handlePasswordChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <ul className="text-left list-item text-[.75rem] mt-2">
                  <li className={newPassword.length >= 6 ? 'text-green-500' : ''}>- Minimum 6 characters</li>
                  <li className={/[A-Z]/.test(newPassword) ? 'text-green-500' : ''}>- At least 1 Capital Letter</li>
                  <li className={/\d/.test(newPassword) ? 'text-green-500' : ''}>- At least 1 number</li>
                </ul>
              </div>
              <div className='w-full px-5'>
                <input
                  type="password"
                  placeholder='Confirm New Password'
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                {newPassword !== '' && newPassword === confirmPassword ? (
                  <span className="text-[.75rem] text-feedback-success">
                    Passwords match!
                  </span>
                ) : (
                  newPassword !== '' && (
                    <span className="text-[.75rem] text-feedback-error">
                      Passwords don't match
                    </span>
                  )
                )}
              </div>
              {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}
              <button
                onClick={(e:any) => handleSubmit(e)}
                className={`cursor-pointer w-11/12 text-white mx-auto flex text-center align-middle justify-center py-4 inter_font bg-[#BC3323] font-semibold text-[1rem]`}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPasswordPage;
