import React from "react";

function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      className="w-full h-full"
    >
      <path
        fill="#fff"
        d="M2.154 9.75a.872.872 0 01-.641-.263.872.872 0 01-.263-.64V3.153c0-.253.087-.466.262-.641a.872.872 0 01.642-.263h7.692c.253 0 .466.087.642.263a.872.872 0 01.262.64v5.693a.872.872 0 01-.262.641.872.872 0 01-.642.263H2.154zM10 3.721L6.243 6.126a.59.59 0 01-.119.051.424.424 0 01-.248 0 .59.59 0 01-.12-.051L2 3.72v5.125a.15.15 0 00.043.11.15.15 0 00.11.044h7.693a.15.15 0 00.11-.043.15.15 0 00.044-.11V3.72zM6 5.5L9.923 3H2.077L6 5.5zM2 3.837v-.572.015-.016.573z"
      ></path>
    </svg>
  );
}

export default EmailIcon;
