import React from "react";
import { useAppContext } from "../../../Context";
import NewTooltip from "../Tooltip/NewTooltip";

type inputProps = {
  name: string;
  title: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  tooltip?: string;
};

const InputLabel: React.FC<inputProps> = ({
  name,
  title,
  required,
  error,
  disabled,
  tooltip,
}) => {
  const { mode } = useAppContext();
  return (
    <div className=" flex gap-2.5 w-full">
      {mode !== "member" ? (
        <label
          htmlFor={name}
          className={` inputLabel ${disabled ? " text-gray-200" : error ? "text-feedback-error" : !required ? "text-gray-font" :" text-primary"} `}
        >
          {title}
        </label>
      ) : (
        <label
          htmlFor={name}
          className={`inputLabel ${disabled ? " text-gray-200" : "text-gray-font"} `}
        >
          {title}{" "}
          <span
            className={`${required ? (disabled ? " text-gray-200" : " text-feedback-error") : "hidden"}`}
          >
            *
          </span>
        </label>
      )}
      {tooltip && <NewTooltip text={tooltip} />}
    </div>
  );
};

export default InputLabel;
