import React from "react";
import '../../../assets/css/table/Pagination.css';
import ChevronDown from "../../Atomos/Icons/ChevronDown";

interface IPaginationComponentProps {
  onPageChange: (pageNumber: number, pageSize: number) => void;
  totalCount: number;
  currentPage: number;
  recordsPerPage: number;
  member?: boolean;
}

const PaginationComponent: React.FC<IPaginationComponentProps> = ({
  totalCount,
  onPageChange,
  currentPage,
  recordsPerPage,
  member,
}) => {
  const totalPageCount = Math.ceil(totalCount / recordsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1, recordsPerPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPageCount) {
      onPageChange(currentPage + 1, recordsPerPage);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      onPageChange(1, recordsPerPage);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPageCount) {
      onPageChange(totalPageCount, recordsPerPage);
    }
  };

  return (
    <div className="pagination">
      <label htmlFor="records-per-page" className="records-per-page">Rows per Page</label>
      <div className="relative flex justify-center items-center bg-gray-25">
        <select
          id="records-per-page"
          name="records-per-page"
          className="selectorPageSize"
          value={recordsPerPage}
          onChange={(e) => {
            onPageChange(1, Number(e.target.value));
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="2000000000">All</option>
        </select>
        <div>
          <div className="absolute h-6 w-6 right-2 top-2 fill-gray-font">
            <ChevronDown/>
          </div>
        </div>
      </div>

      <button className="arrow" onClick={handlePreviousPage}>&lt;</button>

      {currentPage !== 1 && (
        <>
          <button className="button" onClick={handleFirstPage}>1</button>
          {(currentPage - 2) > 1 && <span className="text-lg font-semibold">...</span>}
        </>
      )}

      {(currentPage - 1) > 1 && (
        <button className="button" onClick={handlePreviousPage}>{currentPage - 1}</button>
      )}

      <button className={`currentButton ${member ? "bg-primary" : "bg-Default"}`}>{currentPage}</button>

      {(currentPage + 1) < totalPageCount && (
        <button className="button" onClick={handleNextPage}>{currentPage + 1}</button>
      )}

      {(currentPage + 2) < totalPageCount && <span className="text-lg font-semibold">...</span>}

      {totalPageCount !== currentPage && totalPageCount > 1 && (
        <button className="button" onClick={handleLastPage}>{totalPageCount}</button>
      )}

      <button className="arrow" onClick={handleNextPage}>&gt;</button>
    </div>
  );
};

export default PaginationComponent;
