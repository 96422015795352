import { useLocation } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import "../../../assets/css/AddCatalogs.css";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import GoBack from "../../../components/Moleculas/GoBack";
import WSGCheckbox from "../../../components/Atomos/Checkbox/WSGCheckBox";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import { UseRegionList } from "../../../hooks/Catalogs/useRegionListOptions";
import { useLogging } from "../../../Context/LoggingContext";
import { updateData } from "../../../utils/functions";

interface DataItemCountry {
  id?: string;
  name?: string;
  abbreviation?: string;
  hasState?: boolean;
  // regions?: string[];
  regions?: { id: string }[];
}

interface iCatalogProps {
  mode: "add" | "edit";
}


const AddCountry: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [country, setCountry] = useState<DataItemCountry>(
    state
      ? state
      : { id: "", name: "", abbreviation: "", hasState: false, regions: [] }
  );
  const [regionData, setRegionData] = useState<any[]>([]);
  const [activeErrors, setActiveErrors] = useState({
    name: { error: false, message: '' },
    abbreviation: { error: false, message: '' },
    regions: { error: false, message: '' },
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [buttonClicked, setButtonClicked] = useState("");
  const [error, setError] = useState(false);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [smallswitch, setSmallSwitch] = useState(false);
  const { regionData: regionAux } = UseRegionList();
  const location = useLocation();
  const { logActivity } = useLogging();

  useEffect(() => {
    if (viewportWidth < 1024) {
      setSmallSwitch(true);
    }
  }, [viewportWidth]);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCountryData = (field: string, data: string) => {
    if (error) validation();
    const newCountry: any = { ...country };
    if (field === "hasState") newCountry[field] = data === "true";
    else if (field === "Region") {
      newCountry.regions = newCountry.regions ?? [];
      if (newCountry.regions.some((region: any) => region.id === data)) {
        newCountry.regions = newCountry.regions.filter(
          (region: any) => region.id !== data
        );
      } else {
        newCountry.regions.push({ id: data });
      }
      newCountry.regionNames = regionData.filter(r => newCountry.regions
        ?.map((n: any) => n.id)?.includes(r.id))?.map(c => c.name).join(", ");
    } else newCountry[field] = data;
    setCountry(newCountry);
    return;
  };

  const validation = async () => {
    const name =
      country.name?.trim() === ""
        ? { error: true, message: "Name is required" }
        : { error: false, message: "" };
    const abbreviation =
      country.abbreviation?.trim() === ""
        ? { error: true, message: "Abbreviation is required" }
        : { error: false, message: "" };
    const regions =
      country.regions?.length === 0
        ? { error: true, message: "Select at least one region" }
        : { error: false, message: "" };
    setActiveErrors({
      name: name,
      abbreviation: abbreviation,
      regions: regions,
    });
    setError(name.error || abbreviation.error || regions.error);
    return name.error || abbreviation.error || regions.error;
  };

  useEffect(() => {
    if (activeErrors.abbreviation.error || activeErrors.name.error)
      setSaveOverrideOpen(true);
    else {
      setSaveOverrideOpen(false);
      if (error) setError(false);
    }
    const errorsToSet = Object.keys(activeErrors).filter(
      (clave) => activeErrors[clave as keyof typeof activeErrors].error
    );
    setErrorFields(errorsToSet);
  }, [activeErrors]);

  useEffect(() => {
    if (country.name?.trim() !== "") {
      setActiveErrors({ ...activeErrors, name: { error: false, message: "" } });
    }
    if (country.abbreviation?.trim() !== "") {
      setActiveErrors({
        ...activeErrors,
        abbreviation: { error: false, message: "" },
      });
    }
    if (country.regions?.length !== 0) {
      setActiveErrors({
        ...activeErrors,
        regions: { error: false, message: "" },
      });
    }
  }, [country]);

  useEffect(() => {
    const selectedRegions = regionAux.map((region: any) => ({
      ...region,
      checked: false,
    }));
    setRegionData(selectedRegions);
  }, [regionAux]);

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (buttonClicked === "updateButton") {
      handleUpdate(e);
    } else if (buttonClicked === "saveButton") {
      handleSave(e);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    let newerror = false;

    console.log("country", country);

    if (buttonClicked !== "saveOverride") {
      newerror = await validation();
    }

    setError(newerror);

    if (!newerror) {
      try {
        const addData: DataItemCountry = country;
        console.log("addData", addData);
        const response = await axios.Post("/Country", addData);
        if (response.status === 200) {
          if (response.data.error) {
            console.log(response.data.error);
            activeErrors.name.error = response.data.error.includes("Name");
            activeErrors.name.message = response.data.error.includes("Name")
              ? "Name already exists"
              : "";
            activeErrors.abbreviation.error =
              response.data.error.includes("Abbreviation");
            activeErrors.abbreviation.message = response.data.error.includes(
              "Abbreviation"
            )
              ? "Abbreviation already exists"
              : "";
            setActiveErrors(activeErrors);
          } else {
            logActivity(
              "CREATE_COUNTRY",
              location.pathname,
              JSON.stringify(response.data)
            );

            setTimeout(() => {
              navigate("/Staff/Catalogs/Country");
            }, 500);
          }
        } else {
          console.error("Error creating element:", response.data);
        }
      } catch (error: any) {
        console.error("Error creating element:", error);
      }
    }
  };

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    let newerror = false;

    if (buttonClicked !== "saveOverride") {
      newerror = await validation();
    }
    setError(newerror);

    if (country.id && !newerror) {
      const updatedData: DataItemCountry = country;

      try {
        const response = await axios.Put("Country", updatedData);
        if (response.data.error) {
          activeErrors.name.error = response.data.error.includes("Name");
          activeErrors.name.message = response.data.error.includes("Name")
            ? "Name already exists"
            : "";
          activeErrors.abbreviation.error =
            response.data.error.includes("Abbreviation");
          activeErrors.abbreviation.message = response.data.error.includes(
            "Abbreviation"
          )
            ? "Abbreviation already exists"
            : "";
          setActiveErrors(activeErrors);
        } else
          logActivity(
            "UPDATE_COUNTRY",
            location.pathname,
            `${JSON.stringify({ prevState: state ? state : {}, newState: updatedData })}`
          );
          if (state.name !== country.name) {
            await Promise.all([
              updateData('address', 'countryName', state.name, country.name),
              updateData('city', 'countryName', state.name, country.name),
              updateData('firm', 'countryName', state.name, country.name),
              updateData('jurisdiction', 'countryName', state.name, country.name),
              updateData('office', 'countryName', state.name, country.name),
              updateData('state', 'countryName', state.name, country.name),
              updateData('substate', 'countryName', state.name, country.name),
            ])
          }

        navigate("/Staff/Catalogs/Country");
      } catch (error: any) {
        console.error("Error al actualizar el elemento:", error);
      }
    }
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/Country`, id);

    if (response.status === 200) {
      logActivity(
        "DELETE_COUNTRY",
        location.pathname,
        JSON.stringify(state ? state : {})
      );

      navigate("/Staff/Catalogs/Country");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };

  return (
    <>
      <div className=" mainSection">
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={handleCloseSaveOverride}
          />
          <CatalogTitle title={`${props.mode === 'add' ? 'New Country' : 'Edit ' + (state?.name ?? 'none')}`} className="text-Default">
            <GoBack />
          </CatalogTitle>

          <article className="articleSection  ">
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title="Country Information"
                className=" text-accent-Default-dark"
              />
              <div className=" formsection grid grid-cols-12  ">
                <div className="  col-span-12 lg:col-span-4 md:col-span-6 w-full">
                  <Input
                    title={"Name"}
                    name="name"
                    value={country.name}
                    onChange={(e) => {
                      handleCountryData("name", e.currentTarget.value);
                    }}
                    error={activeErrors.name.error}
                    errorMsj={activeErrors.name.message}
                    required
                  />
                </div>
                <div className=" col-span-12 lg:col-span-2 md:col-span-4 w-full ">
                  <Input
                    title={"Abbreviation"}
                    name="abbreviation"
                    value={country.abbreviation}
                    onChange={(e) => {
                      handleCountryData("abbreviation", e.currentTarget.value);
                    }}
                    error={activeErrors.abbreviation.error}
                    errorMsj={activeErrors.abbreviation.message}
                    required
                  />
                </div>
                <div className="flex flex-row md:flex-col gap-2.5  text-left text-lg leading-5 font-normal not-italic justify-start items-start col-span-12  md:col-span-2 lg:col-span-3 text-gray-font">
                  <span className=" ">Has State:</span>
                  <SwitchToogle
                    title=""
                    checked={country.hasState ?? false}
                    seter={(isChecked) =>
                      handleCountryData(
                        "hasState",
                        isChecked ? "true" : "false"
                      )
                    }
                    small={smallswitch}
                    centered
                  />
                </div>
              </div>
            </section>
            <section className="formsectionNTitle">
              <CatalogSubTitle
                title="Catalog List Relationships"
                className=" text-accent-Default-dark"
              />
              <div className=" formsection">
                <label className="mb-[10px] text-left text-lg leading-5 font-normal not-italic relative self-stretch text-primary">
                  Regions
                </label>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5 w-full">
                  {regionData.map((region, index) => (
                    <div
                      key={region.id}
                      className={`${activeErrors.regions ? "text-feedback-error" : "text-neutral-dark"} w-[221px]`}
                    >
                      {true ? (
                        <WSGCheckbox
                          key={region.id}
                          text={region.name}
                          checked={
                            country.regions?.some(
                              (item) => item.id === region.id
                            ) ?? false
                          }
                          handleCheckboxChange={(e) => {
                            handleCountryData("Region", region.id);
                          }}
                          index={index}
                          error={activeErrors.regions.error}
                        />
                      ) : (
                        <WSGCheckbox
                          key={region.id}
                          text={region.name}
                          checked={
                            country.regions?.some(
                              (item) => item === region.id
                            ) ?? false
                          }
                          handleCheckboxChange={(e) => {
                            handleCountryData("Region", region.id);
                          }}
                          index={index}
                          error={activeErrors.regions.error}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {activeErrors.regions.error && (
                <label className=" false text-sm font-medium text-feedback-error ">
                  {" "}
                  Select at least one region
                </label>
              )}
            </section>
            <ButtonsCrud
              Catalog="Country"
              mode={props.mode}
              id={country.id ?? ""}
              actionButton={handleButtonClick}
              onDelete={handleDelete}
              disabled={error}
            />
          </article>
        </form>
      </div>
    </>
  );
};
export default AddCountry;
