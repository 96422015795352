import React from "react";

function Youtube() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> 
        <path d="M19.582 5.17a2.513 2.513 0 00-1.768-1.78C16.254 2.968 10 2.968 10 2.968s-6.254 0-7.814.42A2.513 2.513 0 00.418 5.17C0 6.74 0 10.014 0 10.014s0 3.276.418 4.845c.23.866.908 1.52 1.768 1.752 1.56.42 7.814.42 7.814.42s6.254 0 7.814-.42a2.476 2.476 0 001.768-1.752C20 13.29 20 10.014 20 10.014s0-3.275-.418-4.845zM7.955 12.987V7.04l5.227 2.974-5.227 2.974z"></path> 
    </svg>
  );
}

export default Youtube;
