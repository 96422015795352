
function Imagesmode() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16"  >
            <path d="M3.81103 11.4682H12.206L9.6335 7.97541L7.4335 10.8254L5.8835 8.70874L3.81103 11.4682ZM1.76465 14.2356V1.75513H14.2451V14.2356H1.76465ZM3.08495 12.9153H12.9154V3.08484H3.08495V12.9153ZM5.6688 6.66021C5.94531 6.66021 6.17969 6.56343 6.37195 6.36988C6.56419 6.17631 6.66032 5.94127 6.66032 5.66476C6.66032 5.38825 6.56354 5.15387 6.36998 4.96161C6.17643 4.76937 5.94139 4.67324 5.66487 4.67324C5.38835 4.67324 5.15397 4.77002 4.96172 4.96358C4.76947 5.15714 4.67335 5.39218 4.67335 5.66869C4.67335 5.9452 4.77013 6.17959 4.96368 6.37184C5.15724 6.56409 5.39228 6.66021 5.6688 6.66021Z" />
        </svg>
    );
}

export default Imagesmode;
