import React from "react";

function RedDot() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
    >
      <circle cx="12.5" cy="12.5" r="12.5"></circle>
    </svg>
  );
}

export default RedDot;
