// src/redux/catalogs/states/citiesSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";
import { ICity } from "../../../interfaces";

export const citiesSlice = createSlice({
  name: "cities",
  initialState: Empty,
  reducers: {
    SetCities: (_, action: PayloadAction<ICity[]>) => action.payload,
  },
});

export const { SetCities } = citiesSlice.actions;
export default citiesSlice.reducer;
