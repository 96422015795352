import React, { useEffect, useState } from 'react';
import { IPracticeIndustry } from '../../interfaces/models/IPracticeIndustry';
import WSGCheckbox from '../Atomos/Checkbox/WSGCheckBox';
import SvgIcon from '../ViewMode/IconComponent';

interface PracticeIndustriesMappingProps {
  practiceIndustries: IPracticeIndustry[];
  onIndustryChange: (industryId: string) => void;
}

function chunkArray(array: any[], size: number) {
  const chunks = [];
  for (let i = 0; i < array?.length; i += size) {
    chunks.push(array?.slice(i, i + size));
  }
  return chunks;
}


const PracticeIndustriesMapping: React.FC<PracticeIndustriesMappingProps> = ({ practiceIndustries, onIndustryChange }) => {

  const [ rowLength, setRowLength ] = useState<number>(7)
  const [industriesWithIcons, setIndustriesWithIcons] = useState<IPracticeIndustry[]>([]);


useEffect(() => {
  if(window && window.innerWidth < 1024){
    setRowLength(10)
  } 
}, [window, window.innerWidth])


useEffect(() => {
  const loadIcons = async () => {
    const loadedIndustries = await Promise.all(practiceIndustries.map(async (industry:any) => {
      if (industry.icon) {
        const response = await fetch(industry.icon);
        const blob = await response.blob();
        const iconURL = URL.createObjectURL(blob);
        return { ...industry, icon: iconURL };
      }
      return industry;
    }));

    setIndustriesWithIcons(loadedIndustries);
  };

  loadIcons();
}, [practiceIndustries]);


  return (
    <ul className='flex flex-col items-start gap-5 flex-1 w-full self-stretch md:flex-row'>
      {practiceIndustries &&
        ((window.innerWidth >= 768) ? (
          chunkArray(practiceIndustries?.slice(0, practiceIndustries.length), rowLength).map(
            (chunk: any[], chunkIndex: number) => (
              <div className="flex flex-col items-start gap-5 flex-1 w-full self-stretch" key={chunkIndex}>
                {chunk.map((industry: any) => (
                  <li key={industry.id} className='flex items-center gap-[.625rem]'>
                    <label className='flex items-center gap-[.625rem]'>
                      <input
                        type="checkbox"
                        className="hidden"
                        checked={industry.isChecked || false}
                        onChange={() => onIndustryChange(industry.id)}
                      />
                      <WSGCheckbox
                        removeClassName
                        index={1}
                        text={''}
                        checked={industry.isChecked || false}
                        handleCheckboxChange={() => onIndustryChange(industry.id)}
                      />
                      <div className='flex justify-center items-center gap-2'>
                        <div className='flex w-[1.5rem] h-[1.5rem] justify-center items-center'>
                          <SvgIcon practiceId={industry.id} practiceName={industry.name} isSelected={false} iconUrl={industry?.icon}/>
                        </div>
                        <span className='text-base leading-[1.5rem] text-[#474F56] lg:text-[1.125rem] lg:leading-[2rem]'>
                          {industry.name}
                        </span>
                      </div>
                    </label>
                  </li>
                ))}
              </div>
            )
          )
        ) :
          practiceIndustries.map((industry: any) => (
            <li key={industry.id} className='flex items-center gap-[.625rem]'>
              <label className='flex items-center gap-[.625rem]'>
                <input
                  type="checkbox"
                  className="hidden"
                  checked={industry.isChecked || false}
                  onChange={() => onIndustryChange(industry.id)}
                />
                <WSGCheckbox
                  removeClassName
                  index={1}
                  text={''}
                  checked={industry.isChecked || false}
                  handleCheckboxChange={() => onIndustryChange(industry.id)}
                />
                <div className='flex justify-center items-center'>
                  <div className='flex w-[1.5rem] h-[1.5rem] justify-center items-center px-1'>
                  <SvgIcon practiceId={industry.id} practiceName={industry.name} isSelected={false} iconUrl={industry.icon}/>
                  </div>
                  <span className='text-base leading-[1.5rem] text-[#474F56]'>
                    {industry.name}
                  </span>
                </div>
              </label>
            </li>
          ))
        )}



    </ul>
  );
};

export default PracticeIndustriesMapping;
