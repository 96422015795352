import React, { useCallback, useEffect, useRef, useState } from "react";
import NewButton from "../../Atomos/Buttons/newButton";
import Gear from "../../Atomos/Icons/Gear";
import { Table } from "@tanstack/react-table";
import WSGCheck from "../../Atomos/Icons/WSGCheck";
import { useAppContext } from "../../../Context";

interface VisibleColumnsProps {
  className?: string;
  tableInstance: Table<unknown>;
}

const VisibleColumnsV2: React.FC<VisibleColumnsProps> = ({
  className,
  tableInstance,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<boolean[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    const initialVisibleColumns =
      typeof tableInstance.getAllLeafColumns === "function"
        ? tableInstance.getAllLeafColumns().map((column) => column.getIsVisible())
        : [];
    setVisibleColumns(initialVisibleColumns);
  }, [tableInstance]);

  const handleCheckboxChange = (index: number) => {
    setVisibleColumns((current) =>
      current.map((column, i) => (i === index ? !column : column))
    );
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdown(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, handleClickOutside]);

  const { mode } = useAppContext();

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <NewButton
        onClick={handleToggle}
        icon={<Gear />}
        content="iconSquare"
        size="large"
        color="primary"
        style="filled"
        className="!w-full  f !border-1 !border-accentDefault"
      />
      <div
        className={`absolute w-max ${className} ${
          dropdown
            ? `opacity-1 visible scale-[100%] duration-0`
            : `opacity-0 invisible scale-95 duration-200`
        } transform ease-out transition-opacity duration-200 transition-delay-200 z-10 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        <span className="text-sm w-full text-justify font-medium block py-[0.625rem] pl-4 pr-3 lg:py-[0.81rem] lg:pl-5 lg:pr-4">
          Columns
        </span>

        {typeof tableInstance.getAllLeafColumns === "function" &&
          tableInstance
            .getAllLeafColumns()
            .map((column, index) => (
              <div key={column.id}>
                <label className="flex items-center gap-[0.625rem] py-[0.625rem] pl-4 pr-3 lg:py-[0.81rem] lg:pl-5 lg:pr-4 align-middle text-lg leading-5 text-gray-font bg-white hover:bg-gray-50 undefined">
                  <input
                    className="hidden"
                    onClick={() => handleCheckboxChange(index)}
                    {...{
                      type: "checkbox",
                      checked: visibleColumns[index],
                      onChange: column.getToggleVisibilityHandler(),
                    }}
                  />

                  <div className="relative w-6 h-6">
                    <div
                      className={`absolute stroke-2 min-w-[24px] min-h-[24px] inset-0 stroke-gray-font ${
                        !visibleColumns[index]
                          ? " fill-none"
                          : mode === "member"
                          ? " fill-accentDefault"
                          : " fill-Default"
                      }`}
                    >
                      {<WSGCheck check={visibleColumns[index]} />}
                    </div>
                  </div>
                  <span className="text-base leading-6 lg:text-lg lg:leading-8 text-gray-font first-letter:uppercase">
                    {String(column.columnDef.header)}
                  </span>
                </label>
              </div>
            ))}
      </div>
    </div>
  );
};

export default VisibleColumnsV2;
