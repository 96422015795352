// CustomReactTable.tsx
import React, { useEffect, useState } from 'react';
import { ColumnResizeDirection, ColumnResizeMode, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import DraggableHeader from '../DraggableHeader/DraggableHeader';
import { Link, useLocation } from 'react-router-dom';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { IPracticeIndustry } from '../../../interfaces/models/IPracticeIndustry';
import SvgIcon from '../../ViewMode/IconComponent';

interface TableProps {
  data: any;
  columns: any;
  onSort: (columnId: string) => void;
  setSorting: any,
  sorting: any,
  columnOrder: any,
  onTableInstanceReady?: (instance: any) => void;
  selectedTab?: number;
  currentResource?: string;
}

interface DataRow {
  id: string;
  name: string;
}


const CustomReactTable: React.FC<TableProps> = ({ data, columns, onSort, columnOrder, sorting, setSorting, onTableInstanceReady, selectedTab, currentResource }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const location = useLocation()
  const [columnResizeMode, setColumnResizeMode] = React.useState<ColumnResizeMode>('onChange')
  const [columnResizeDirection, setColumnResizeDirection] = React.useState<ColumnResizeDirection>('ltr')




  const table = useReactTable({
    data: data || [],
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnOrder,
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });


  useEffect(() => {
    if (table && onTableInstanceReady) {
      onTableInstanceReady(table);
    }
  }, [table, onTableInstanceReady, location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (currentResource && currentResource === 'mappingPage') {
      const columnsToShowInTab0 = ['index', 'name', 'professionals', 'firms', 'isTargeted', 'actions'];
      const columnsToShowInTab1 = ['index', 'name', 'professionals', 'firms', 'practiceIndustries', 'isIgnored', 'actions'];
      if (typeof selectedTab === 'number') {
        const newColumnVisibility = columns.reduce((acc: any, column: any) => {
          const isVisible =
            selectedTab === 0
              ? columnsToShowInTab0.includes(column.id)
              : columnsToShowInTab1.includes(column.id);
          return { ...acc, [column.id]: isVisible };
        }, {});
        setColumnVisibility(newColumnVisibility);
      }
    }
  }, [selectedTab, columns, setColumnVisibility, currentResource]);

  return (
    <>
      <table>
        <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (

                  <th
                    key={header.id}
                    className={`${header.id === 'actions' || header.id === 'index' ? '' : 'self-stretch relative !min-w-[12.5rem] lg:!w-[13.29rem]'}`}
                  >
                    <DraggableHeader
                      key={header.id}
                      columnId={header.id}
                      onSort={onSort}
                      disableSort={header.id === 'actions' || header.id === 'index' || header.id === 'icon'}
                      sortOrder={sorting && sorting.field === header.id ? sorting.order : 'none'}

                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </DraggableHeader>

                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`${!isMobile ? 'resizer' : ''} ${header.column.getIsResizing() ? 'isResizing' : ''
                          }`}
                      ></div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </SortableContext>

        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id} style={{ backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#E5E3E4' }}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}
                >
                  {selectedTab === 0 && cell.column.id === 'name' ?
                    <Link className='text-[#0070CD] underline' to={`Edit/${(row.original as DataRow).id}`} state={row.original as DataRow}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Link>
                    : flexRender(cell.column.columnDef.cell, cell.getContext())
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th
                  {...{
                    key: header.id,
                    style: {
                      width: header.getSize(),
                      display: 'flex',
                    },
                  }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
                </th>
              ))}

            </tr>
          ))}
        </tfoot>
      </table>
    </>
  );
};

export default CustomReactTable;
