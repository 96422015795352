import React from "react";

function Microsoft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
       
      viewBox="0 0 48 48"
    >
     
        <path 
          d="M0 0h22.993v22.993H0V0zm25.007 0H48v22.993H25.007V0zM0 25.007h22.993V48H0V25.007zm25.007 0H48V48H25.007V25.007z"
        ></path>
      
    </svg>
  );
}

export default Microsoft;
