function Apartment(props: any) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.67969 14.3219V4.42188H4.42969V1.67188H11.5797V7.17188H14.3297V14.3219H8.88332V11.2516H7.11665V14.3219H1.67969ZM2.99999 13.0016H4.74999V11.2516H2.99999V13.0016ZM2.99999 10.2516H4.74999V8.50161H2.99999V10.2516ZM2.99999 7.50161H4.74999V5.75161H2.99999V7.50161ZM5.74999 10.2516H7.49999V8.50161H5.74999V10.2516ZM5.74999 7.50161H7.49999V5.75161H5.74999V7.50161ZM5.74999 4.75161H7.49999V3.00161H5.74999V4.75161ZM8.49999 10.2516H10.25V8.50161H8.49999V10.2516ZM8.49999 7.50161H10.25V5.75161H8.49999V7.50161ZM8.49999 4.75161H10.25V3.00161H8.49999V4.75161ZM11.25 13.0016H13V11.2516H11.25V13.0016ZM11.25 10.2516H13V8.50161H11.25V10.2516Z"
        />
      </svg>
    </div>
  );
}

export default Apartment;
