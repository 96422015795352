import React from "react";

function Spinner() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" width="8rem" height="8rem">
      <g clipPath="url(#clip0_223_1400)">
        <path
          fill="#BC3323"
          d="M1.2 12.52A20.13 20.13 0 013.975 8.01a25.098 25.098 0 011.874-2.084c.069-.706.249-1.397.534-2.047a5.085 5.085 0 011.465-1.904 15.404 15.404 0 00-6.418 6.921A8.461 8.461 0 001.2 12.52z"
        ></path>
        <path
          fill="#DD3B33"
          d="M8.267 6.023a14.88 14.88 0 002.215 6.784c5.043-5.503 12.098-8.382 16.325-6.538a5.018 5.018 0 011.945 1.514 15.42 15.42 0 00-7.139-6.478c-3.68-1.018-8.94.922-13.346 4.718zM8.801 2.994a6.28 6.28 0 00-.246.664c3.098-2.275 6.454-3.55 9.228-3.46a13.426 13.426 0 00-.684-.096 15.446 15.446 0 00-5.914.497A4.646 4.646 0 008.8 2.994z"
        ></path>
        <path
          fill="#903B2C"
          d="M19.152 28.68c2.029.897 5.511-.66 7.805-3.48a9.49 9.49 0 00.642-.892c-2.131 1.587-5.572 1.45-9.258-.323-.984 2.114-.732 4.018.81 4.694z"
        ></path>
        <path
          fill="#BC3323"
          d="M27.365 18.477c-1.957-.868-5.26.557-7.529 3.179 3.518 1.718 6.802 1.838 8.796.275.372-1.574-.036-2.91-1.267-3.454z"
        ></path>
        <path
          fill="#903B2C"
          d="M7.997 26.673l-.102-.09a4.677 4.677 0 002.738 3.353l.15.06a15.227 15.227 0 006.628.54c-2.69.119-6.118-1.198-9.414-3.863zM5.325 24.147a21.593 21.593 0 01-4.28-6.85 8.58 8.58 0 00.048 3.724 15.356 15.356 0 006.298 7.436 5.499 5.499 0 01-2.066-4.31zM14.085 21.267a26.145 26.145 0 01-3.74-3.724 14.783 14.783 0 00-2.594 6.652c.528.508 1.08 1 1.657 1.466a19.571 19.571 0 006.832 3.719c-1.741-1.198-1.801-3.886-.294-6.754a23.24 23.24 0 01-1.861-1.36z"
        ></path>
        <path
          fill="#BC3323"
          d="M.39 14.884a9.554 9.554 0 01-.138-2.275c-.066.341-.12.683-.156 1.03a15.382 15.382 0 000 3.592 10.08 10.08 0 01.294-2.347zM30.18 11.136a4.617 4.617 0 00-2.449-2.46c-4.053-1.797-10.89 1.131-15.61 6.55a24.176 24.176 0 003.86 3.933c.511.413 1.021.79 1.532 1.138 3.116-3.754 7.804-5.814 10.53-4.604 1.765.778 2.294 2.724 1.663 5.023.097-.155.185-.315.264-.479a15.81 15.81 0 00.709-3.191c.22-1.984.051-3.99-.498-5.91zM8.603 15.124a17.785 17.785 0 01-2.695-6.796c-.319.348-.63.713-.93 1.084a20.758 20.758 0 00-3.249 5.52 21.712 21.712 0 003.794 6.826 17.634 17.634 0 013.08-6.634z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_223_1400">
          <path fill="#fff" d="M0 0H140V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Spinner;
