import React, { useEffect, useMemo, useState } from 'react';
import CatalogTableC from '../../components/Catalogs/CatalogTableC';
import RepresentativeClientFilter from '../../components/Catalogs/Filters/RepresentativeClientFilter';
import Axios from '../../utils/axios';
import TableWithPagination from '../../components/Organismos/Table/TableTransitionToTanSack2';
import { useColumnConfig } from '../../hooks/Table/UseColumnSettings';
import { Link } from 'react-router-dom';
import ShapeEdit from '../../components/Atomos/Icons/ShapeEdit';
import { ColumnConfig } from '../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport';
import { transformData } from '../../hooks/Table/TransformColumnsForExport';
import { formatTimeSpanToString } from '../../utils/functions';
import useDataLoader2 from "../../hooks/useDataLoader2";
import { UseFirmList } from '../../hooks/Catalogs/useFirm';

const axios = new Axios()

const RepresentativeClient: React.FC = ({ ...props }) => {

  const Headers: string[] = ['name', 'firm', 'office', '_ts'];
  const DefaultPageSize = 20;
  const [nameFilter, setNameFilter] = useState('');
  const [tableInstance, setTableInstance] = useState<any>();
  const [officeData, setOfficeData] = useState<any>()
  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const [selectedOffice, setSelectedOffice] = useState<any>([]);
  const { generateColumns } = useColumnConfig(Headers);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fields = [
    'id', 
    'name',
    'firm',
    'office',
    'firmName',
    'officeNames',
    '_ts'
  ]
  const { 
    data, 
    totalCount, 
    fetchData, 
    setFilter, 
    sort, 
    setSort, 
    pagination, 
    setPagination 
  } = useDataLoader2<any>({
    endpoint: '/representativeClient',
    defaultPageSize: DefaultPageSize,
    fields
  });
const {firmData} = UseFirmList()

  const columnConfigs: ColumnConfig[] = [
    { id: 'count', header: '#', enableSorting: false, size: 50 },
    { id: 'name', header: 'Name' },
    { id: 'firmName', header: 'Firm' },
    { id: 'officeNames', header: 'Office' },
    { id: '_ts', header: "Created Date" },
    {
      id: 'edit',
      header: 'Edit',
      enableSorting: false,
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={props.row.original}
        >
          Edit{" "}
          <div className="editsvg">
            <ShapeEdit />
          </div>{" "}
        </Link>
      ),
    },
  ];

  const columns = generateColumns(columnConfigs.map(column => ({
    ...column,
    cell: column.cell || ((props: any) => {
      switch (column.id) {
        case 'count':
          return <p>{props.row.index + 1 + pagination.pageIndex * pagination.pageSize}</p>
        case 'firmName':
          return <p>{props.getValue()}</p>
        case 'officeNames':
          return <p>{props.getValue()}</p>
        case '_ts':
          const value = props.getValue();
          return (
            <p>
              {value && formatTimeSpanToString(value) }
            </p>
          );
        default:
          return <p>{props.getValue()}</p>;
      }
    })
  })));

  const transformedData = transformData(data, columnConfigs, pagination.pageIndex, pagination.pageSize, firmData, null, 'Representative Client');


  useEffect(() => {
    fetchOfficeData()
  }, []);

  const handleNameFilterChange = (newNameFilter: string) => {
    setNameFilter(newNameFilter);
  };

  const clearSelections = () => {
    setNameFilter('');
    setSelectedFirm([])
    setSelectedOffice('')
    fetchData(true);
  };

  const fetchOfficeData = async () => {
    try {
      const response = await axios.Get('./office')
      const data = response.data
      if (data != null) {
        setOfficeData(data)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(!isFetching) {
      fetchData();
      fetchOfficeData()
    }
    setIsFetching(true)
  },[]);

  useEffect(() => {
    let filterAux = {
      name: {
        value: nameFilter,
        comparisonType: 'CONTAINS'
      },
      firm: {
        value: selectedFirm?.map((c: any) => c.id),
        comparisonType: 'EQUAL' //isComplex
      },
      office: {
        value: selectedOffice?.map((f: any) => f.id),
      },
    }
    setFilter(filterAux)
  },[
    nameFilter, 
    selectedFirm, 
    selectedOffice
  ]);

  return (
    <>
      <main className='mainSection'>
        <CatalogTableC
          title={`Representative Client Catalog`}
          catalogName={'Representative Client'}
          route={'RepresentativeClient'}
          isfiltered={false}
          data={data}
          headers={Headers}
          totalCount={totalCount}
          exportData={transformedData}
        >
          <RepresentativeClientFilter
            nameFilter={nameFilter}
            onNameFilterChange={handleNameFilterChange}
            headers={Headers}
            resetFilters={clearSelections}
            search={fetchData}
            firmData={firmData}
            selectedFirm={selectedFirm}
            setSelectedFirm={setSelectedFirm}
            officeData={officeData}
            selectedOffice={selectedOffice}
            setSelectedOffce={setSelectedOffice}
            tableInstance={tableInstance}
          />
          <TableWithPagination
            data={data}
            columnsDef={columns}
            setTableInstance={setTableInstance}
            sorting={sort}
            setSorting={setSort}
            totalCount={totalCount}
            pagination={pagination}
            setPagination={setPagination}
          />

        </CatalogTableC>
      </main>
    </>
  );
};

export default RepresentativeClient;