import { Children, FormEvent, useState } from "react";
import { IPracticeIndustry } from "../../../interfaces/models/IPracticeIndustry";
import useViewport from "../../../hooks/useViewPort";
import Axios from "../../../utils/axios";
import { IRegion } from "../../../interfaces";
import ExcelModal from "../../../components/Atomos/Modals/ExcelModal";
import AnimationLogo from "../../../components/Layout/LoadingAnimation/AnimationLogo";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import TabManager from "../../../components/Layout/TabManager/TabManager";
import CounterTotalCatalog from "../../../components/Moleculas/Catalog Text/CounterTotalCatalog";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import Excel from "../../../components/Atomos/Icons/Excel";
import { Link } from "react-router-dom";
import VisibleColumns from "../../../components/Organismos/Table/VisibleColumns";
import BackTime from "../../../components/Atomos/Icons/BackTime";
const WSGDots = require("../../../assets/images&icons/WSGDots.svg");

interface ICatalogTableProps {
  title?: string;
  catalogName?: string;
  headers: string[];
  route: string;
  totalCount: number;
  isfiltered: boolean;
  data: any[];
  // totalData?: any[];
  HandleFilter?: (field: string, value: string) => void;
  onsubmit?: (e: FormEvent<HTMLFormElement>) => void;
  clearSelections?: () => void;
  shouldFormat?: boolean;
  hasTotalData?: boolean;
  // GetTotalData?: (all: boolean) => Promise<any[]>;
  linkToAdd?: string;
  noNewCatalog?: boolean;
  noExcelExport?: boolean;
  showAddButton?: boolean;
  selectedTab?: any;
  catalogShortName?: string;
  noRedBox?: boolean;
  children?: React.ReactNode;
  practiceIndustries?: IPracticeIndustry[];
  resetSignal?: number;
  personFromDto?: boolean;
  exportData?: any;
  regions?: any[]
  firmData?: any;
  firmOffices?: any;
  currentState?: any;
  areasOfPractice?: any;
}

const AopManagementTable: React.FC<ICatalogTableProps> = (props: ICatalogTableProps) => {
  const { viewportWidth } = useViewport()
  function formatDate(value: string) {
    const formattedDate = new Date(Number(value) * 1000).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    return formattedDate;
  }

  const formatDateValue = (value: string) => {
    return new Date(Number(value) * 1000).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const safeValue = (value: any) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value === undefined ? '' : value;
  };


  const axios = new Axios();

  const [open, setOpen] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<any[]>([]);

  const [spinner, setSpinner] = useState(false);

  // const GetTotalData = async () => {
  //   let datapassed: any[] = [];
  //   let queryString = `/${props.route}`;

  //   if (props.selectedTab === 0) {
  //     queryString += '?resourceId=mappingPage&countOnly=true';
  //   }
  //   else if (props.selectedTab === 1) {
  //     queryString += '?resourceId=mappingPage&isMapped=true&countOnly=true';
  //   }
  //   const fetchTotalCount = async () => {
  //     try {
  //       if (props.selectedTab && props.selectedTab === 0) {
  //         let newString = '&resourceId=mappingPage&countOnly=true'
  //         queryString += newString
  //       }
  //       const response = await axios.Get(queryString);
  //       const data = response.data;
  //       if (data != null) {
  //         setTotalData(data);
  //         datapassed = data;
  //       }
  //     } catch (error) {
  //       console.error("Error al obtener la información de la columna:", error);
  //     }
  //   };
  //   await fetchTotalCount();
  //   return datapassed;
  // };
  

  const filterRegions = (reg: IRegion[]) => {
    return props.regions?.filter(allRegions => reg.some(region => allRegions.id === region.id))
      .map(region => region.name)
      .join(', ') || '';
  };

  const excelExport = async (jsonData: any[]) => {
    setSpinner(true);
    try {
      setOpen(false);
      const XLSX = require("xlsx");
      console.log('Exported Data', props.exportData)

      const resolvedData = jsonData.map(item => {
        const rowData: { [key: string]: any } = {};
        for (const header of props.headers) {
          const key = header.replace(/ /g, '_');
          let value = item[key] !== undefined ? item[key] : '';
          if (key === '_ts') {
            value = formatDateValue(value);
          } if ((props.catalogName !== 'Office' && props.catalogName !== 'Address') && key === 'regions') {
            value = filterRegions(value)
          }
          const formattedHeader = (header === '_ts' || header === 'dateCreated') ? 'Created Date' : header;
          rowData[formattedHeader] = value;
        }
        return rowData;
      });
      console.log("Resolved Data for Export:", resolvedData);

      const ws = XLSX.utils.json_to_sheet(resolvedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      const currentDate = new Date();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const year = currentDate.getFullYear();

      const formattedDate = `${month}_${day}_${year}`;
      const outputFile = `${props.route}_${formattedDate}.xlsx`;

      XLSX.writeFile(wb, outputFile, { bookSST: true, type: 'binary' });

      console.log("File successfully written:", outputFile);
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        setSpinner(false);
      }, 500);
    }
  };

  const dataExcelExport = async (current: boolean) => {
    if (!current) {
      let dataToExport: any[] = [];

      // if (!props.hasTotalData) dataToExport = await GetTotalData();
      // dataToExport = (await props.GetTotalData?.(true)) ?? [];
      dataToExport.forEach((item: any) => {
        if (item._ts) {
          item["created_date"] = formatDate(item._ts);
        }
      });

      excelExport(dataToExport);
    } else {
      try {
        setSpinner(true);
        if (!props.hasTotalData) excelExport(props.exportData ? props.exportData : props.data);
        // else excelExport((await props.GetTotalData?.(false)) ?? []);
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(() => {
          setSpinner(false);
        }, 1000);
      }
    }
  };




  return (
    <>
      <ExcelModal open={open} setOpen={setOpen} dataExcelExport={() => dataExcelExport(true)} />

      {spinner && <AnimationLogo />}

      <section className=" CatalogTableMain relative">
        <div className="w-full relative z-10 flex gap-5 md:gap-6 lg:gap-7 !items-end self-stretch !p-0 flex-col ">
          <div className="flex p-0 flex-col justify-center items-end gap-5 self-stretch md:flex-row lg:justify-between lg:gap-6">
            <h2 className="text-left w-full lg:w-max text-white font-decimal text-[2.25rem] font-medium leading-[2.75rem] md:text-[2.75rem] md:leading-[3.25rem] lg:text-[3.25rem] lg:leading-[4.5rem]">{props.title}</h2>
            <div className='self-stretch flex-1 hidden lg:flex' style={{
              backgroundImage: `url(${WSGDots.default})`,
            }}>
            </div>
          </div>
          <hr className="flex h-0 justify-center items-center self-stretch relative z-10" />
          <div className="flex w-full lg:mb-[1.75rem]">
            <CurrentPage prevPage="Home" currentPage={``} />
          </div>
        </div>
        <TabManager
          officesTabs={props.firmOffices}
          selectedTabIndex={0}
          selectedTab={props.selectedTab}
        />
        <div className="flex flex-col md:flex-row h-auto w-full">
          <CounterTotalCatalog title={`${props.catalogName} (${props.totalCount} Total)`} className=" bg-accent-Default-dark text-neutral first-letter:uppercase"></CounterTotalCatalog>

          <div className="flex lg:flex-row-reverse">

            <div className="flex w-full">
              {!props.noExcelExport && (
                <div className="flex-1">
                  <NewButton
                    text={viewportWidth < 1024 ? `Export` : `Export to Excel`}
                    color="excel"
                    size="large"
                    content="textIcon"
                    onClick={() => setOpen(true)}
                    icon={<Excel />}
                  />
                </div>
              )}
              {!props.noNewCatalog && (
                <Link
                  className="flex-1"
                  to={
                    props.linkToAdd
                      ? (props.linkToAdd as string)
                      : `/Staff/Catalogs/${props.route}/Create`
                  }
                  state={props.currentState && props.areasOfPractice ? {
                    ...props.currentState, currentInfo:
                    {

                      areasOfPractice: props.areasOfPractice ? props.areasOfPractice : {},
                      firmOffices: props.firmOffices ? JSON.stringify(props.firmOffices) : {},
                      selectedTab: props.selectedTab === 0 ? props.selectedTab : props.selectedTab.toString()
                    }

                  } : null}
                >
                  <NewButton
                    text={viewportWidth < 1024 ? `Update` : `Update AOPS`}
                    color="accent"
                    size={"large"}
                    content="textIcon"
                    icon={<BackTime />}
                  />
                </Link>
              )}
            </div>
            {!props.isfiltered && !props.noRedBox && props.resetSignal && (
              <VisibleColumns
                resetSignal={props.resetSignal}
                className={"right-0"}
                pageHeaders={props.headers}
              ></VisibleColumns>
            )}
          </div>
        </div>

        <div className="CatalogFormNTable ">
          {
            Children.map(props.children, (child) => (
              <> {child} </>)
            )
          }
        </div>
      </section>
    </>
  );
};

export default AopManagementTable;
