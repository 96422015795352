import React, { forwardRef, ForwardedRef } from 'react';
import '../../../assets/css/buttons/newButton.css';

interface FirmContactButtonProps {
  text?: string;
  icon?: JSX.Element;
  style?: 'filled' | 'ghost' | 'outlined';
  color?: 'primary' | 'accent' | 'error' | 'neutral' | 'delete' | 'excel';
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | 'screenadaptive';
  content?: 'text' | 'textIcon' | 'icon' | 'iconSquare';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: () => void;
}

const FirmContactButton: React.ForwardRefRenderFunction<HTMLDivElement, FirmContactButtonProps> = (
  {
    text,
    icon,
    style = 'filled',
    color = 'primary',
    disabled = false,
    size = 'small',
    content = 'text',
    className = '',
    onClick,
  },
  ref
) => {
  return (
    <div
      ref={ref}
      onClick={onClick}
      className={`${content} ${size} ${color} ${style} ${
        disabled ? 'disabled' : ''
      }  uppercase font-decimal cursor-pointer fill-current ${className}`}
    >
      {content === 'text' && text && <span>{text}</span>}
      {(content === 'icon' || content === 'iconSquare') && <span>{icon}</span>}
      {content === 'textIcon' && (
        <>
          {text && (
            <span className="flex-1 justify-center items-center text-center">
              {text.toUpperCase()}
            </span>
          )}
          {icon && <span>{icon}</span>}
        </>
      )}
    </div>
  );
};

export default forwardRef(FirmContactButton);
