import React from "react";

function Video() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 42 34"
    >
      <path 
        d="M.293 33.707V.265h33.442V14.75l8-8v20.5l-8-8v14.457H.293z"
      ></path>
    </svg>
  );
}

export default Video;
