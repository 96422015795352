import { createSlice } from "@reduxjs/toolkit"; 
import { RegionEmpty } from "../emptyStates/Region";
 
export const regionSlice = createSlice({
  name: "state",
  initialState: RegionEmpty,
  reducers: {
    ModifyRegion: (state, action) =>  ({...state, ...action.payload}),
    NewRegion: (_, action) => action.payload, 
    ResetRegion: () => RegionEmpty, 
  },
});

export const { NewRegion, ModifyRegion, ResetRegion} = regionSlice.actions;
