import React, { useEffect, useState } from "react";
import Linkedin from "../Atomos/Icons/ViewMode/Linkedin";
import Facebook from "../Atomos/Icons/ViewMode/Facebook";
import Instagram from "../Atomos/Icons/ViewMode/Instagram";
import Whatsapp from "../Atomos/Icons/ViewMode/Whatsapp";
import WeChat from "../Atomos/Icons/ViewMode/WeChat";
import Youtube from "../Atomos/Icons/ViewMode/Youtube";
import XIcon from "../Atomos/Icons/ViewMode/XDark";
import PencilEdit from "../Atomos/Icons/PencilEdit";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Context";
import VCard from "../Atomos/Icons/ViewMode/VCard";
import { IPerson } from "../../interfaces/models/IPerson";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import Axios from "../../utils/axios";
const axios = new Axios();

interface ISocialLinks {
  organization?: boolean;
  user?: any;
  organizationObj?: any;
  isEditable?: boolean;
  isPublicPreview?: boolean;
  isDataMissing?: (fields: string[]) => void;
}

const SocialLinks: React.FC<ISocialLinks> = ({
  organization,
  user,
  organizationObj,
  isEditable,
  isPublicPreview,
  isDataMissing,
}) => {
  const [currentUser, setCurrentUser] = useState<IPerson | null>(null);
  const [personFirm, setPersonFirm] = useState<any>();
  const [personMainOffice, setPersonMainOffice] = useState<any>();
  const { firmData } = UseFirmList();

  useEffect(() => {
    setCurrentUser(user || null);
    getUserMainOffice();
    getUserFirm();
  }, [user]);

  async function getUserMainOffice() {
    try {
      if (user) {
        const response = await axios.Get(`/office`, user.mainOffice);
        if (response && response.data) {
          setPersonMainOffice(response.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getUserFirm = () => {
    const userFirm = firmData.find((firm: any) => firm.id === currentUser?.firm);
    setPersonFirm(userFirm);
  };

  const { mode } = useAppContext();

  const socialNetworks = [
    { name: "linkedin", userProp: "linkedin", orgProp: "linkedinUrl", Component: Linkedin },
    { name: "x", userProp: "x", orgProp: "xUrl", Component: XIcon },
    { name: "facebook", userProp: "facebook", orgProp: "facebookUrl", Component: Facebook },
    { name: "instagram", userProp: "instagram", orgProp: "instagramUrl", Component: Instagram },
    { name: "whatsapp", userProp: "whatsapp", orgProp: "", Component: Whatsapp },
    { name: "wechat", userProp: "wechat", orgProp: "", Component: WeChat },
    { name: "youtube", userProp: "youtube", orgProp: "youtubeUrl", Component: Youtube },
  ];

  const filteredSocialNetworks = organization
    ? socialNetworks.filter(({ orgProp }) => orgProp)
    : socialNetworks.filter(({ name }) => name !== "youtube");

  const findSocialNetworkUrl = (name: string) => {
    if (organization) {
      const network = socialNetworks.find((network) => network.name === name);
      return organizationObj ? organizationObj[network ? network?.orgProp : ""] : "";
    } else {
      const networks = currentUser?.socialNetworks;
      const network = networks?.find((network: any) => network.name.toLowerCase() === name);
      return network ? network.url : "";
    }
  };

  const formatUrl = (url: string) => {
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const editLinkPath =
    mode === "staff"
      ? `/staff/profile/edit/attorney/${user?.id}/${user?.generatedId}`
      : `/profile/edit/attorney/${user?.id}/${user?.generatedId}`;

  const isValidVCardData = () => {
    const missingFields = [];
    if (!user?.name) missingFields.push("Name");
    if (!user?.lastName) missingFields.push("Last Name");
    if (!personMainOffice && !personMainOffice?.phoneNumber) missingFields.push("Phone Number");
    if (!user?.firmEmail) missingFields.push("Email");
    if (!user?.mainOffice) missingFields.push("Main Office");

    if (missingFields.length > 0) {
      console.log(`Faltan los siguientes campos para el usuario ${user?.name} ${user?.lastName}:`, missingFields);
    }

    return missingFields;
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    return `+${cleaned}`;
  };

  const generateVCard = () => {
    const missingFields = isValidVCardData();
    if (missingFields.length > 0) {
      isDataMissing && isDataMissing(missingFields);
      return;
    }
    if (user) {
      const formattedPhoneNumber = formatPhoneNumber(personMainOffice?.phoneNumber || '');

      const vCardData = `
BEGIN:VCARD
VERSION:3.0
N:${user.lastName};${user.name};${user.middleInitial ?? ""};${user.honorary || ""};${user.suffix || ""}
ORG:${personFirm && personFirm?.firmName ? personFirm.firmName : ''}
TITLE:${user.title || ""}
FN:${user.displayName || `${user.name} ${user.middleInitial || ""} ${user.lastName}`}
TEL;TYPE=WORK,VOICE:${formattedPhoneNumber ? formattedPhoneNumber : ''}
EMAIL:${user.firmEmail || ""}
ADR;TYPE=WORK:;;${personMainOffice?.address || ""};${personMainOffice?.city || ''};${personMainOffice?.state || ''};;${personMainOffice?.country || ''}
END:VCARD
      `.trim();

      console.log("vCardData:", vCardData);

      const blob = new Blob([vCardData], { type: "text/vcard" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${user.displayName || user.name}.vcf`;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <React.Fragment>
      <div className="flex gap-[.625rem] justify-center items-center">
        {filteredSocialNetworks?.map(({ name, Component }) => {
          const url = findSocialNetworkUrl(name);
          const formattedUrl = formatUrl(url);
          const iconClass = url ? "hoverFill" : "socialInactive opacity-50";
          return (
            <div className={`w-[1.25rem] h-[1.25rem] flex items-center justify-center ${iconClass}`} key={name}>
              <a href={formattedUrl || undefined} target="_blank" rel="noopener noreferrer" className="w-full h-full flex relative">
                <Component />
              </a>
            </div>
          );
        })}
        {(isPublicPreview || !isEditable) && !organization && (
          <div
            className={`w-[1.25rem] h-[1.25rem] flex items-center justify-center cursor-pointer`}
            onClick={generateVCard}
          >
            <VCard />
          </div>
        )}
        {isEditable && !organization && (
          <Link to={editLinkPath} state={{ ...user, isOnlySocials: true }}>
            <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
              <PencilEdit className="w-full h-full pencilRed" />
            </div>
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

export default SocialLinks;
