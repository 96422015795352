import React, { createContext, useContext, useState } from "react";

interface ModeContextType {
  isViewMode: boolean;
  isEditMode: boolean;
  toggleMode: () => void;
  deactivateViewMode: () => void;
  activateViewMode: () => void;
  toggleEditmode: () => void;
}

const ModeContext = createContext<ModeContextType | undefined>(undefined);

export function useModeContext() {
  const context = useContext(ModeContext);
  if (!context) {
    throw new Error("useModeContext must be used within a ModeProvider");
  }
  return context;
}


export function ModeProvider({ children }: { children: React.ReactNode }) {
  const [isViewMode, setIsViewMode] = useState(true);
  const [ isEditMode, setIsEditMode] = useState(true);

  const toggleMode = () => {
    setIsViewMode((prev) => !prev);
  };

  const deactivateViewMode = () => {
    setIsViewMode(false)
  }

  const activateViewMode = () => {
    setIsViewMode(true)
  }

  const toggleEditmode = () => {
    setIsEditMode(true)
  }


  return (
    <ModeContext.Provider value={{ isViewMode, toggleMode, deactivateViewMode, activateViewMode, isEditMode, toggleEditmode }}>
      {children}
    </ModeContext.Provider>
  );
}
