

import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';


export default function ConfirmLoginPage({...props}) {


    const [token,setToken] = useState("");
    useEffect(() => {
        sessionStorage.setItem("token",window.location.href.substring(window.location.href.indexOf("#id_token=")+10))
        setToken(window.location.href.substring(window.location.href.indexOf("#id_token=")+10))
    }, []);
    useEffect(() => {
        
        if(token){

          const decodeToken = async () => {
            let claims = await jwtDecode(token) as any;
            sessionStorage.setItem("token_decoded",JSON.stringify(claims));
            return true;
          }
          decodeToken().catch();
          window.location.href = '/mywsg'
        }
    },[token])
  return (
    <main
      className={`flex min-h-screen flex-col items-center p-0`}
    >
    </main>
  )
}