import React from "react";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import RedPencil from "../../Atomos/Icons/RedPencil";
import PencilEdit from "../../Atomos/Icons/PencilEdit";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../Context";

export interface DisclosureProps {
  title: string;
  isEditable?: boolean;
  isCardOpen: boolean;
  onCardOpen: (open: boolean) => void;
  children?: React.ReactNode;
  color: 'primary' | 'accent' | 'secondary' | 'default' | 'accent-Default-dark';
  personInfo?: any;
  isOnlyProfessionalCareer?: boolean;
  isOnlyAops?: boolean;
  isLinkDisabled?: boolean;
  viewAll?: boolean;
}

const Disclosure: React.FC<DisclosureProps> = ({
  title,
  isEditable,
  isCardOpen,
  onCardOpen,
  children,
  color,
  personInfo,
  isOnlyProfessionalCareer = false,
  isOnlyAops = false,
  isLinkDisabled,
}) => {
  const { mode } = useAppContext();

  const editLinkPath = mode === 'staff'
    ? `/staff/profile/edit/attorney/${personInfo?.id}/${personInfo?.generatedId}`
    : `/profile/edit/attorney/${personInfo?.id}/${personInfo?.generatedId}`;

  return (
    <div className="flex h-auto flex-col items-end self-stretch bg-white relative z-10">
      <div className="flex h-[3.5rem] items-start shrink-0 self-stretch">
        <div className={`flex py-4 px-6 items-center gap-0 self-stretch flex-1 justify-between w-full bg-${color}`}>
          <span className="text-white font-decimal text-[1.125rem] font-medium leading-5 md:text-[1.25rem] uppercase lg:text-[1.5rem] lg:leading-[1.75rem]">{title}</span>
          {(isEditable && !isCardOpen && personInfo) && (
            <div className="w-8 h-8 pl-1 pt-1 pr-[.17rem] pb-[.17rem]">
              <Link 
                state={{...personInfo, isOnlyAops, isOnlyProfessionalCareer, isOnlySocials: false}}
                to={isLinkDisabled ? '' : editLinkPath}
                className="w-full h-full pl-1 pt-1 pr-[.17rem] pb-[.17rem] flex items-center justify-center fill-neutral">
                <PencilEdit className='w-full h-full pencilWhite' />
              </Link>
            </div>
          )}
        </div>
        <div className={`flex p-4 justify-center items-center gap-0 self-stretch ${color === 'primary' ? 'bg-disclosure-primary-dark' : 'bg-accent-Default-dark'}`}>
          <div
            className={`w-8 h-8 duration-75 cursor-pointer fill-white ${isCardOpen ? 'rotate-180' : ''}`}
            onClick={() => onCardOpen(!isCardOpen)}
          >
            <ChevronDown />
          </div>
        </div>
      </div>
      <div className={`${isCardOpen ? 'flex' : 'hidden'} flex-col self-stretch duration-300`}>
        {children}
      </div>
    </div>
  );
};

export default Disclosure;