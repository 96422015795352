function IndeedIcon(props: any) {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 50 50"
      fill="#474F56"
    >
      <path d="M12.6753 18.3975V43.5445H4.30819V18.3975H12.6753ZM13.221 10.6215C13.221 11.8644 12.7815 12.8951 11.9023 13.7137C11.0231 14.5322 9.8863 14.9415 8.49177 14.9415H8.4463C7.05177 14.9415 5.93009 14.5322 5.08125 13.7137C4.2324 12.8951 3.80798 11.8644 3.80798 10.6215C3.80798 9.3785 4.24756 8.34777 5.12672 7.52924C6.00588 6.71072 7.14272 6.30145 8.53724 6.30145C9.93177 6.30145 11.0535 6.71072 11.9023 7.52924C12.7511 8.34777 13.1907 9.3785 13.221 10.6215ZM42.7789 29.1293V43.5445H34.4572V30.0843C34.4572 28.2956 34.1086 26.901 33.4113 25.9006C32.714 24.9002 31.6378 24.4 30.1827 24.4C29.1216 24.4 28.2425 24.688 27.5452 25.264C26.848 25.84 26.3023 26.5676 25.9082 27.4468C25.7263 27.9622 25.6353 28.6443 25.6353 29.4931V43.5445H17.2682C17.2985 36.7841 17.3137 31.3121 17.3137 27.1285V19.6252L17.2682 18.3975H25.6353V22.0353H25.5899C25.9233 21.4897 26.272 21.0198 26.6358 20.6257C26.9996 20.2316 27.4695 19.792 28.0455 19.3069C28.6214 18.8219 29.3641 18.4581 30.2736 18.2156C31.1831 17.973 32.1532 17.8366 33.1839 17.8063C36.0639 17.8063 38.3831 18.7612 40.1414 20.6711C41.8997 22.581 42.7789 25.4004 42.7789 29.1293Z" />
    </svg>
  );
}

export default IndeedIcon;
