import React from "react";
import {
  ReactEditor,
  useReadOnly,
  useSlate,
  useSlateStatic,
} from "slate-react";
import { Editor, Range, Transforms, Element as SlateElement } from "slate";

interface ButtonInreface {
  active?: boolean;
  onMouseDown: (event: React.MouseEvent) => void;
  icon: React.SVGAttributes<SVGPathElement>;
  className?: string;
}
interface IconProps {
  children: any;
  className?: string;
}
interface ToolbarProps {
  children: React.ReactNode;
}

interface ButtonProps {
  format: string;
  icon: React.SVGAttributes<SVGPathElement>;
}

interface LinkButtonProps {
  icon: React.SVGAttributes<SVGPathElement>;
}

interface CheckListItemElementProps {
  attributes: any;
  children: React.ReactNode;
  element: CustomElement & { checked: boolean };
}

type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  [key: string]: any;
};
type CustomElement = {
  type: any;
  url?: string;
  checked?: boolean;
  children: CustomText[];
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export const Button: React.FC<ButtonInreface> = ({
  active,
  onMouseDown,
  icon,
  className,
}) => {
  return (
    <span
      onMouseDown={onMouseDown}
      style={{
        cursor: "pointer",
        color: active ? "black" : "#aaa",
      }}
    >
      <Icon className={className}>{icon}</Icon>
    </span>
  );
};

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

const insertLink = (editor: Editor, url: string) => {
  if (!url) return;

  if (isLinkActive(editor)) {
    unwrapLink(editor);
  } else {
    if (!isValidUrl(url)) {
      url = `http://${url}`;
    }

    const { selection } = editor;
    if (selection) {
      const isCollapsed = Range.isCollapsed(selection);
      const link: CustomElement = {
        type: "link",
        url,
        children: isCollapsed ? [{ text: url }] : [],
      };
      if (isCollapsed) {
        Transforms.insertNodes(editor, link);
      } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
      }
    }
  }
};
const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor) as Record<string, boolean> | null;
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);
  const isTextAlign = TEXT_ALIGN_TYPES.includes(format);

  if (!TEXT_ALIGN_TYPES.includes(format)) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
  }

  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }

  Transforms.setNodes(editor, newProperties);

  if ((!isActive && isList) || isTextAlign) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block, { split: true });
  }
};

const isBlockActive = (editor: Editor, format: string, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        return (
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n as any)[blockType] === format
        );
      },
    })
  );

  return !!match;
};

export const Toolbar: React.FC<ToolbarProps> = ({ children }) => {
  return (
    <div className="flex w-fit py-2 rounded-t-md border-x border-t bg-white">
      {children}
    </div>
  );
};

export const MarkButton: React.FC<ButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleMark(editor, format);
  };

  return <Button active={isActive} onMouseDown={onMouseDown} icon={icon} />;
};

export const LinkButton: React.FC<LinkButtonProps> = ({ icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        } else {
          const url = window.prompt("Enter the URL of the link:");
          if (url) {
            insertLink(editor, url);
          }
        }
      }}
      icon={icon}
    />
  );
};

export const BlockButton: React.FC<ButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = false;

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleBlock(editor, format);
  };

  return <Button active={isActive} onMouseDown={onMouseDown} icon={icon} />;
};

export const CheckListItemElement: React.FC<CheckListItemElementProps> = ({
  attributes,
  children,
  element,
}) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element;
  return (
    <div {...attributes}>
      <span contentEditable={false} className="mr-2">
        <input
          type="checkbox"
          checked={checked}
          onChange={(event) => {
            const path = ReactEditor.findPath(editor, element);
            const newProperties: Partial<SlateElement> = {
              checked: event.target.checked,
            };
            Transforms.setNodes(editor, newProperties, { at: path });
          }}
        />
      </span>
      <span contentEditable={!readOnly} suppressContentEditableWarning>
        {children}
      </span>
    </div>
  );
};

export const Icon: React.FC<IconProps> = ({ className, children }) => {
  return (
    <span>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        className={`mx-2 w-5 h-5 cursor-pointer text-black ${className}`}
      >
        {children}
      </svg>
    </span>
  );
};
