import { useNavigate } from "react-router-dom";
import useViewport from "../../hooks/useViewPort";
import NewButton from "../Atomos/Buttons/newButton";
import ArrowBack from "../Atomos/Icons/ArrowBack";

type GoBackProps = { 
    goBackTo?: string;
    className?: string;  // Add a className prop
}

const GoBack: React.FC<GoBackProps> = ({ goBackTo, className }) => {
    const { viewportWidth } = useViewport();
    const navigate = useNavigate();  
    return (
      <NewButton 
        icon={<ArrowBack />} 
        text='Go back' 
        content={viewportWidth < 640 ? "icon": "textIcon"} 
        color="neutral" 
        link 
        style="ghost" 
        size="medium" 
        className={className}
        onClick={() => { goBackTo ? navigate(goBackTo) : navigate(-1) }} 
      /> 
    );
}

export default GoBack;
