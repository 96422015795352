import React from "react";

function OutfillStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M16 36l8-6.1 8 6.1-3.2-9.85 8-5.65h-9.75L24 10.35 20.9 20.5h-9.75l8 5.65L16 36zm8.004 8.707c-2.85 0-5.533-.54-8.05-1.62-2.518-1.08-4.718-2.56-6.6-4.442-1.88-1.881-3.361-4.08-4.44-6.595-1.08-2.515-1.62-5.197-1.62-8.046 0-2.882.54-5.582 1.62-8.1 1.079-2.518 2.558-4.71 4.436-6.576 1.879-1.865 4.076-3.342 6.593-4.43 2.517-1.089 5.2-1.633 8.05-1.633 2.885 0 5.587.544 8.107 1.63 2.52 1.087 4.712 2.562 6.576 4.425 1.864 1.863 3.34 4.054 4.428 6.573 1.087 2.518 1.63 5.22 1.63 8.107 0 2.852-.543 5.536-1.632 8.054-1.088 2.517-2.565 4.716-4.43 6.595-1.866 1.879-4.056 3.358-6.572 4.438-2.515 1.08-5.214 1.62-8.096 1.62z"
      ></path>
    </svg>
  );
}

export default OutfillStar;
