import React from "react";

function Question() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M21.21 31.387c.034-2.683.347-4.659.939-5.928.592-1.27 1.633-2.508 3.123-3.713 1.343-1.135 2.352-2.222 3.027-3.26a6.073 6.073 0 001.012-3.373c0-1.387-.458-2.538-1.373-3.453s-2.179-1.373-3.79-1.373c-1.62 0-2.907.459-3.859 1.376a8.472 8.472 0 00-2.106 3.23l-5.218-2.274a12.39 12.39 0 014.079-5.59c1.89-1.477 4.257-2.216 7.097-2.216 3.507 0 6.208.988 8.102 2.963 1.895 1.975 2.842 4.364 2.842 7.165 0 1.826-.347 3.45-1.04 4.873-.692 1.423-1.798 2.822-3.317 4.199-1.577 1.435-2.541 2.574-2.893 3.416-.352.843-.536 2.162-.55 3.958H21.21zm2.932 13.404c-1.151 0-2.13-.403-2.936-1.208-.805-.806-1.208-1.782-1.208-2.927 0-1.146.402-2.122 1.206-2.928.805-.806 1.785-1.209 2.942-1.209 1.156 0 2.137.404 2.943 1.21.806.805 1.209 1.781 1.209 2.927 0 1.145-.405 2.12-1.215 2.927-.81.805-1.79 1.208-2.941 1.208z"
      ></path>
    </svg>
  );
}

export default Question;
