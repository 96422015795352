import React from 'react';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';


interface IFirmContactFilterProps {
  firmData: any[]
  selectedFirm: Option[];
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;
  search: () => void;
  resetFilters: () => void;
  headers: string[];
  tableInstance:any;
}
interface FilterProps {
  id: string;
  name: string;
  search: () => void;
}

const FirmContactFilter: React.FC<IFirmContactFilterProps> = (props) => {

  return (

    <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:flex-1 lg:flex lg:flex-row
      border border-gray-100'>
      <div className='w-full'>
        <DDMultiple title="By Firm:" objects={props.firmData} selectedObjst={props.selectedFirm} seter={props.setSelectedFirm} />
      </div>
      <div className='flex flex-row gap-2 w-full md:justify-end lg:w-max md:col-span-2 md:max-w-[17rem] md:ml-auto'>
        <CatalogFilterButtons tableInstance={props.tableInstance} onClickSearch={props.search} handleFormReset={props.resetFilters} headers={props.headers} />
      </div>
    </div>
  );
};

export default FirmContactFilter;


