import NewButton from "../Atomos/Buttons/newButton";
import ChevronRight from "../Atomos/Icons/ChevronRight";
const bgCta = require('../../assets/images&icons/bgCta.jpeg')

const ChevronIcon = () => {
  return (
    <div className="w-[1.125rem] h-[1.125rem] flex md:w-8 md:h-8 lg:w-12 lg:h-12 fill-white">
      <ChevronRight />
    </div>
  )
}

const abstactInfo = {
  title: 'Meet our Firms and Professionals',
  textContent: 'WSG’s member firms include legal, investment banking and accounting experts across industries and on a global scale. We invite you to meet our member firms and professionals.'
}

const ProfileBanner = ({
}) => {
  return (
    <>
      <div className=" flex py-12 px-4 flex-col items-end gap-[1.5rem] self-stretch relative z-10 md:py-14 md:px-7 lg:py-15 lg:px-15 lg:flex-row lg:gap-[7.25rem] lg:items-center">
        <img
          src={bgCta}
          alt="background"
          className="w-full h-full object-cover absolute top-0 left-0 z-0"
        />
        <div className="flex flex-col items-start gap-2 self-stretch relative z-10
          lg:gap-2 lg:flex-1">
          <span className="text-white text-center font-decimal text-[1.5rem] font-bold leading-[2.25rem] self-stretch md:text-[1.75rem] md:leading-[2.25rem]
            lg:text-[2rem] lg:leading-[2.25rem] lg:text-left">
            {abstactInfo.title}
          </span>
          <span className="text-white text-center font-decimal text-base leading-[1.125rem] self-stretch font-medium md:text-[1.125rem] md:leading-[1.25rem]
            lg:text-[1.25rem] lg:leading-[1.5rem] lg:text-left">
            {abstactInfo.textContent}
          </span>
        </div>
          <button
            className={`flex h-[2.25rem] imn-h-[1.75rem] py-2 pl-3 pr-2 justify-center items-center gap-1 self-stretch border border-[#0070CD] border-solid bg-[#0070CD] w-full
            md:h-[3.75rem] md:gap-[.625rem] md:max-w-[23.125rem] md:mx-auto
            lg:my-auto lg:max-w-[18.5625rem] lg:min-h-[4.625rem]`}
            onClick={() => ''}>
              <span className="text-white text-center font-decimal tex-base leading-[1.125rem] font-medium lg:text-left lg:text-[1.25rem] lg:leading-[1.5rem]">VIEW OUR MEMBER FIRMS</span>
                <ChevronIcon />
            </button>
        </div>
    </>
  )
}
export default ProfileBanner;