function ArrowDropDown(props: any) {
    return (
        <div className={props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.65 12.15L5 7.5H14.3L9.65 12.15Z" />
            </svg>

        </div>);
}

export default ArrowDropDown;