import "../../assets/css/AddCatalogs.css";
import React, { useEffect, useMemo, useState } from "react";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmPracticeHeadsBackground from "../../assets/images&icons/bg-manageFirmPracticeHeads.png";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import { useLocation, useNavigate } from "react-router-dom";
import { useTableSettings } from "../../hooks/Table/UseTableSettings";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import NewButton from "../../components/Atomos/Buttons/newButton";
import CatalogSubTitle from "../../components/Moleculas/Catalog Text/CatalogSubTitle";
import FiltersHomepage from "./HomePage/FIltersHomepage";
import { UseCountryList } from "../../hooks/Catalogs";
import { firmType, UseFirmList } from "../../hooks/Catalogs/useFirm";
import { UseRegionList } from "../../hooks/Catalogs/useRegionListOptions";
import { UsePracticeIndustryList } from "../../hooks/Catalogs/usePracticeIndustry";
import SortArrow from "../../components/Atomos/Icons/SortArrow";
import Vector from "../../components/Atomos/Icons/Vector";
import PaginationComponent from "../../components/Atomos/Pagination/PaginationComponent";
import Axios from "../../utils/axios";
import ArticlesCard from "./HomePage/ArticleCard";
import SelectedFilters from "./HomePage/SelectedFilters";
import { UseJurisdictionList } from "../../hooks/Catalogs/useJurisdiction";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/loaderActions";

const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const MemberArticlesHomePage: React.FC = () => {
  const { state } = useLocation();

  const axios = new Axios();
  const navigate = useNavigate();

  const { currentPage, setCurrentPage, recordsPerPage, setRecordsPerPage } =
    useTableSettings({ defaultPageSize: 20 });
  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setRecordsPerPage(pageSize);
  };
  const [title, setTitle] = useState<string>("");
  const [selectedFirm, setSelectedFirm] = useState<any[]>([]);
  const [selectedPracticeIndustry, setSelectedPracticeIndustry] = useState<
    any[]
  >([]);
  const dispatch = useDispatch();
  const [dataHasFilter, setDataHasFilter] = useState(false);
  const [filteredArticles, setFilteredArticles] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any[]>([]);
  const [selectedGlobalCrisis, setSelectedGlobalCrisis] = useState<any[]>([]);
  const [selectedGeneralTopic, setSelectedGeneralTopic] = useState<any[]>([]);
  const { countryData } = UseCountryList();
  const { firmData } = UseFirmList(firmType.member);
  const { practiceIndustryData } = UsePracticeIndustryList();
  const { regionData } = UseRegionList();
  const { jurisdictionData } = UseJurisdictionList();
  const [articles, setArticles] = useState<any[]>([]);
  const [globalCrisisData, setGlobalCrisisData] = useState<any[]>([]);
  const [generalTopicData, setGeneralTopicData] = useState<any[]>([]);
  const [sortOrderDate, setSortOrderDate] = useState<"asc" | "desc">("asc");
  const [sortOrderTitle, setSortOrderTitle] = useState<"asc" | "desc">("asc");

  const clearSelections = () => {
    setDataHasFilter(false);
    setTitle("");
    setSelectedFirm([]);
    setSelectedPracticeIndustry([]);
    setSelectedRegion([]);
    setSelectedCountry([]);
    setSelectedGeneralTopic([]);
    setSelectedGlobalCrisis([]);
  };

  const getArticles = async () => {
    try {
      const response = await axios.Get("/Content");
      if (response && response.data) {
        setArticles(
          response.data.filter(
            (article: any) => article?.contentType === "article"
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGlobalCrisis = async () => {
    try {
      const response = await axios.Get("/globalCrisis");
      if (response && response.data) {
        setGlobalCrisisData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGeneralTopic = async () => {
    try {
      const response = await axios.Get("/contentGeneralTopic");
      if (response && response.data) {
        setGeneralTopicData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(showLoader());
      try {
        await Promise.all([getArticles(), getGlobalCrisis(), getGeneralTopic()]);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        dispatch(hideLoader());
      }
    };
  
    loadData();
  }, [dispatch]);
  

  function filterArticleData(articles: any) {
    setFilteredArticles([]);
    setDataHasFilter(true);
    setCurrentPage(1);
    let articlesWithFilters: any[] = articles;

    if (title !== "") {
      articlesWithFilters = articlesWithFilters.filter((article: any) =>
        article.title?.toLowerCase().includes(title.toLowerCase())
      );
    }

    if (selectedFirm.length > 0) {
      const selectedFirmIds = selectedFirm.map((firm) => firm.id);
      articlesWithFilters = articlesWithFilters.filter((article: any) =>
        article.memberFirms.some((firmId: any) =>
          selectedFirmIds.includes(firmId)
        )
      );
    }

    if (selectedRegion.length > 0) {
      const selectedRegionIds = selectedRegion.map((region) => region.id);

      const countriesApply = countryData.filter(
        (c) =>
          Array.isArray(c.regions) &&
          c.regions.some((region: any) => selectedRegionIds.includes(region.id))
      );

      const countriesApplyIds = countriesApply.map((country) => country.id);

      const jurisdictionsApply = jurisdictionData
        .filter((j) => countriesApplyIds.includes(j.country))
        .map((j) => j.id);

      articlesWithFilters = articlesWithFilters.filter(
        (article: any) =>
          Array.isArray(article.jurisdictions) &&
          article.jurisdictions.some((jurisdictionId: any) =>
            jurisdictionsApply.includes(jurisdictionId)
          )
      );
    }

    if (selectedCountry.length > 0) {
      const selectedCountryIds = selectedCountry?.map((country) => country.id);

      const jurisdictionsApply = jurisdictionData
        .filter((j) => selectedCountryIds.includes(j.country))
        .map((j) => j.id);

      articlesWithFilters = articlesWithFilters.filter(
        (article: any) =>
          Array.isArray(article.jurisdictions) &&
          article.jurisdictions.some((jurisdictionId: any) =>
            jurisdictionsApply.includes(jurisdictionId)
          )
      );
    }

    if (selectedPracticeIndustry.length > 0) {
      const selectedPracticeIndustryIds = selectedPracticeIndustry?.map(
        (industry) => industry.id
      );
      articlesWithFilters = articlesWithFilters.filter((article: any) =>
        article?.practiceIndustries?.some((industryId: any) =>
          selectedPracticeIndustryIds?.includes(industryId)
        )
      );
    }

    if (selectedGeneralTopic.length > 0) {
      const selectedGeneralTopicIds = selectedGeneralTopic?.map(
        (topic) => topic.id
      );
      articlesWithFilters = articlesWithFilters.filter((article: any) =>
        article?.generalTopics?.some((topicId: any) =>
          selectedGeneralTopicIds?.includes(topicId)
        )
      );
    }

    if (selectedGlobalCrisis.length > 0) {
      const selectedGlobalCrisisIds = selectedGlobalCrisis?.map(
        (crisis) => crisis.id
      );
      articlesWithFilters = articlesWithFilters.filter((article: any) =>
        article?.globalCrisis?.some((crisisId: any) =>
          selectedGlobalCrisisIds?.includes(crisisId)
        )
      );
    }

    setFilteredArticles(articlesWithFilters);
    return articlesWithFilters;
  }

  function handleSearch(): void {
    filterArticleData(articles);
  }

  function handleSort(field: "title" | "date"): void {
    let articlesWithFilters: any[] = dataHasFilter
      ? filteredArticles
      : articles;

    if (field === "title") {
      const sordOrderCheckTitle = sortOrderTitle;
      setSortOrderTitle(sortOrderTitle === "asc" ? "desc" : "asc");
      articlesWithFilters = articlesWithFilters.sort((a: any, b: any) => {
        const titleA = a.title || "";
        const titleB = b.title || "";

        if (titleA === "" && titleB !== "")
          return sordOrderCheckTitle === "asc" ? 1 : -1;
        if (titleA !== "" && titleB === "")
          return sordOrderCheckTitle === "asc" ? -1 : 1;

        if (sordOrderCheckTitle === "asc") {
          return titleA.localeCompare(titleB);
        } else {
          return titleB.localeCompare(titleA);
        }
      });
    } else if (field === "date") {
      const sordOrderCheckDate = sortOrderDate;
      setSortOrderDate(sortOrderDate === "asc" ? "desc" : "asc");
      articlesWithFilters = articlesWithFilters.sort((a: any, b: any) => {
        const dateA = new Date(a.writtenDate).getTime();
        const dateB = new Date(b.writtenDate).getTime();

        if (!a.writtenDate) return 1;
        if (!b.writtenDate) return -1;

        if (sordOrderCheckDate === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    }
  }

  return (
    <>
      <BackgroundComponent image={ManageFirmPracticeHeadsBackground}/>

      <div className="mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex md:min-h-[50px] gap-4 justify-between">
            <CatalogTitle
              title={`Member Articles`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Home > Publications" currentPage={`New Article`} />
          </div>
        </div>
        <div className="flex flex-col justify-end ">
          <article className="articleSection relative">
            <section className="flex w-full flex-col gap-10">
              <div className="grid w-full gap-5 items-stretch ">
                <div className="flex flex-col md:flex-row w-full gap-3 justify-between">
                  <CatalogSubTitle
                    title={"Article Search"}
                    className="text-primary lg:text-[38px] md:text-[28px] text-[26px] not-italic md:font-bold lg:font-medium leading-7 md:leading-8 lg:leading-[44px]"
                  />
                  <div className=" w-full md:w-min  ">
                    <NewButton
                      text={`Manage Articles`}
                      color="primary"
                      type="button"
                      style="filled"
                      size="large"
                      content="text"
                      onClick={() => navigate(`/Articles/Manage`)}
                    />
                  </div>
                </div>
                <FiltersHomepage
                  title={title}
                  setTitle={setTitle}
                  firmData={firmData}
                  selectedFirm={selectedFirm}
                  setSelectedFirm={setSelectedFirm}
                  practiceIndustryData={practiceIndustryData}
                  selectedPracticeIndustry={selectedPracticeIndustry}
                  setSelectedPracticeIndustry={setSelectedPracticeIndustry}
                  countryData={countryData}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  globalCrisisData={globalCrisisData}
                  selectedGlobalCrisis={selectedGlobalCrisis}
                  setSelectedGlobalCrisis={setSelectedGlobalCrisis}
                  generalTopicData={generalTopicData}
                  selectedGeneralTopic={selectedGeneralTopic}
                  setSelectedGeneralTopic={setSelectedGeneralTopic}
                  regionData={regionData}
                  selectedRegion={selectedRegion}
                  setSelectedRegion={setSelectedRegion}
                  clearSelections={clearSelections}
                  Search={handleSearch}
                />
              </div>
              <div className="flex flex-col gap-7">
                <SelectedFilters
                  selectedFirm={selectedFirm}
                  selectedPracticeIndustry={selectedPracticeIndustry}
                  selectedRegion={selectedRegion}
                  selectedCountry={selectedCountry}
                  selectedGeneralTopic={selectedGeneralTopic}
                  selectedGlobalCrisis={selectedGlobalCrisis}
                />
                <div className="flex flex-1 ">
                  <Vector className="w-full h-0 stroke-gray-50 stroke-1" />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-wrap md:flex-nowrap flex-row gap-3 items-center justify-start">
                    <div>
                      <span className="text-primary not-italic font-semibold lg:leading-5 lg:text-lg text-base leading-5 whitespace-nowrap">
                        Sort By
                      </span>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="20"
                        viewBox="0 0 2 20"
                        fill="none"
                      >
                        <path d="M1 0V20" stroke="#EBEBEB" />
                      </svg>
                    </div>
                    <div className="flex items-center gap-3 cursor-pointer">
                      <div className="flex" onClick={() => handleSort("title")}>
                        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                          Title
                        </span>
                        <SortArrow
                          className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrderTitle === "desc" && "rotate-180"}`}
                        />
                      </div>

                      <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-sm text-base leading-5  ">
                        •
                      </span>
                      <div className="flex" onClick={() => handleSort("date")}>
                        <span className="text-gray-font not-italic lg:leading-5 font-normal lg:text-lg text-base leading-[18px]  ">
                          Date
                        </span>
                        <SortArrow
                          className={`flex w-6 h-6 items-center justify-center fill-gray-font ${sortOrderDate === "desc" && "rotate-180"}`}
                        />
                      </div>
                    </div>
                    <PaginationComponent
                      recordsPerPage={recordsPerPage}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      totalCount={
                        dataHasFilter
                          ? filteredArticles.length
                          : articles.length
                      }
                    />
                  </div>
                  <ArticlesCard
                    articles={dataHasFilter ? filteredArticles : articles}
                    firms={firmData}
                    recordsPerPage={recordsPerPage}
                    currentPage={currentPage}
                  />
                </div>
                <PaginationComponent
                  recordsPerPage={recordsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  totalCount={
                    dataHasFilter ? filteredArticles.length : articles.length
                  }
                />
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default MemberArticlesHomePage;
