import React from "react";

function Drafts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M3.293 42.904v-29.18L24 1.35l20.735 12.374v29.18H3.293zM24 25.244l16.348-9.596L24 5.802 7.652 15.648 24 25.244z"
      ></path>
    </svg>
  );
}

export default Drafts;
