import React from "react";

interface ContactFrameProps {
  name?: string;
  date?: string;
  note?: string;
}

const NoteFrame: React.FC<ContactFrameProps> = ({ name, date, note }) => {
  return (
    <div className="grid col-span-6 gap-5">
      <div className="grid gap-1">
        <div className="not-italic font-bold lg:text-[22px] md:text-[20px] text-lg lg:leading-7 leading-normal text-primary">
          {name}
        </div>
        <div className="not-italic font-normal text-sm lg:text-base leading-5 lg:leading-6 text-gray-font">
          {date}
        </div>
      </div>
      <div className="not-italic font-normal text-base lg:text-lg leading-6 lg:leading-8 text-gray-font">
        {note}
      </div>
    </div>
  );
};

export default NoteFrame;
