import React, { useEffect, useState } from "react";
import ChevronRight from "../Atomos/Icons/ChevronRight";
import { Link } from "react-router-dom";

interface FirmCardProps {
  personFirm: any;
  personJurisdictionGroups?: any;
  viewMoreActive?: boolean;
  primaryLocations?: boolean;
  isWSGFirm: boolean;
  wsgOffice?: any;

}
const FirmCard: React.FC<FirmCardProps> = ({
  personFirm,
  personJurisdictionGroups,
  viewMoreActive,
  primaryLocations,
  isWSGFirm,
  wsgOffice
}) => {

  const [isViewMoreVisible, setIsViewMoreVisible] = useState(false)

  useEffect(() => {
    if (personJurisdictionGroups) {
      if (personJurisdictionGroups.length > 4) {
        setIsViewMoreVisible(true)
      }
    }
  }, [personJurisdictionGroups])

  return (
    <>
      <div className="flex flex-col justify-center items-end gap-6 flex-1 lg:flex-0 lg:self-baseline self-stretch">
        <div className={`flex flex-col gap-1 w-full lg:items-start lg:gap-1 `}>
          <div className="mb-[.375rem] md:justify-center flex">
            <Link to={`/profile/firm/${personFirm.id}`} className="flex text-[#004578] text-center font-decimal text-[1.375rem] font-bold leading-[1.75rem] md:text-[1.5rem] lg:text-[1.625rem] lg:leading-[1.75rem] md:text-center mx-auto self-stretch">{personFirm.name}</Link>
          </div>
          {isWSGFirm && wsgOffice ?
            <React.Fragment>
              <h4 className="text-center text-[#474F56] text-[1rem] font-normal leading-[1.5rem]">{wsgOffice.city}, {wsgOffice.country}</h4>
            </React.Fragment>
            :
            <React.Fragment>
              <span className="text-[#474F56] text-center lg:text-left w-full text-[1.125rem] md:text-[1.25rem] leading-[1.5rem] font-semibold lg:text-[1.375rem] lg:leading-[1.5rem]">Membership Location(s):</span>
              {(personJurisdictionGroups && !primaryLocations) && personJurisdictionGroups.slice(0, 4).map((jurisdictionGroup: any) =>
                <span key={jurisdictionGroup.id} className="text-[#474F56] text-center lg:text-left w-full text-[1rem] leading-[1.5rem] font-normal lg:text-[1.125rem] lg:leading-[2rem]">{jurisdictionGroup.substate ? `${jurisdictionGroup.substate}` : null} {jurisdictionGroup.state ? `${jurisdictionGroup.state},` : null} {jurisdictionGroup.country}</span>
              )}
              {primaryLocations &&
                <span className="text-[#474F56] text-center lg:text-left w-full text-[1rem] leading-[1.5rem] font-normal lg:text-[1.125rem] lg:leading-[2rem]">{personFirm.jurisdiction}</span>
              }
            </React.Fragment>
          }
          {
            isViewMoreVisible &&
            <div className="flex w-full p-0 justify-end items-center gap-3 self-stretch lg:gap-[.625rem]">
              <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">View More</span>
              <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                <div className="w-3 h-3">
                  <ChevronRight />
                </div>
              </div>
            </div>
          }
          {
            isViewMoreVisible &&
            <div className="flex w-full p-0 justify-end items-center gap-3 self-stretch lg:gap-[.625rem]">
              <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">View More</span>
              <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                <div className="w-3 h-3">
                  <ChevronRight />
                </div>
              </div>
            </div>
          }
        </div>
      </div>

    </>
  )
}

export default FirmCard;