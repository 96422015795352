function Plus2(props: any) {
    return (
        <div className={props.className}>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
            <path d="M11.25 12.75H5.5V11.25H11.25V5.5H12.7499V11.25H18.5V12.75H12.7499V18.5H11.25V12.75Z" />
            </svg>

        </div>);
}

export default Plus2;