function MessageIcon(props: any) {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="#474F56"
    >
      <path d="M0 18.5384V0H19V15H3.53845L0 18.5384ZM2.9 13.5H17.5V1.49998H1.49998V14.8846L2.9 13.5ZM3.75 11.25H11.25V9.75H3.75V11.25ZM3.75 8.24995H15.25V6.75H3.75V8.24995ZM3.75 5.24995H15.25V3.75H3.75V5.24995Z" />
    </svg>
  );
}

export default MessageIcon;
