import React from "react";
import IOffice from "../../../interfaces/models/IOffice";

interface OverviewCardProps {
  office: IOffice,
}

const OverviewCard: React.FC<OverviewCardProps> = ({office}) => {
  return (
    <div className="flex lg:py-11 lg:px-12 lg:flex-col lg:items-end lg:gap-[.625rem] self-stretch">
      <span className="text-gray-font lg:text-lg lg:leading-8 self-stretch">
        {office?.overview}
      </span>
      <hr className="flex h-0 lg:justify-center lg:items-center self-stretch"></hr>
      <div className="flex lg:flex-col lg:items-start lg:gap-4 self-stretch">
        <span className="text-gray-font lg:text-[1.375rem] font-bold lg:leading-6 self-stretch">
          Notable
        </span>
        <div className="flex lg:items-start lg:gap-3 self-stretch">
        </div>
      </div>
    </div>
  );
}

export default OverviewCard;