import React from 'react';
import plus from '../../../../assets/images&icons/next-arrow-blue.svg'; 
import EventCard from '../../../Layout/Card/EventCard';

const ProfileEvents: React.FC = () => {
  return (
    <div className={`lg:w-profileContainer w-11/12 mx-auto md:flex gap-4 my-8`}>
      <EventCard title="REFERRALS & COLLABORATIONS" buttonText="VIEW ALL" buttonImage={plus} buttonSecondText='ADD A REFERRAL OR COLLABORATION'/>
      <EventCard title="EVENTS" buttonText="VIEW ALL" buttonImage={plus} buttonSecondText='SUBMIT AN EVENT' buttonThirdText='ALL UPCOMING EVENTS'/>
    </div>
  );
};

export default ProfileEvents;
