import React from 'react';
import Drag_pan from '../../../components/Atomos/Icons/Drag_pan';
import { IAOPRanking } from '../../../interfaces/models/IAOPRanking';

interface RankingItemProps {
  rank: string;
  index: number;
  ranking: IAOPRanking;
  isMobile: boolean;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, position: number) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>, newPosition: number) => void;
  onChange: (value: any) => void;
}

const RankingItem: React.FC<RankingItemProps> = ({ rank, index, ranking, isMobile, onDragStart, onDrop, onChange }) => {
  const rankData = ranking.ranks[index];

  return (
    <div className='flex items-center gap-4 self-stretch md:gap-5'>
      <div className="p-2 flex-col justify-center items-center gap-0 self-stretch bg-gray-50">
        <span className="text-xl leading-6 not-italic font-decimal font-medium">{index + 1}.</span>
      </div>
      <div
        draggable
        onDragStart={(e) => onDragStart(e, index)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => onDrop(e, index)}
        className="cursor-move w-full flex justify-between"
      >
        <div className="flex gap-[.625rem] bg-gray-50 p-2 items-center self-stretch w-full">
          <div className="h-5 w-5">
            <Drag_pan />
          </div>
          <span className="text-gray-font font-normal text-normal leading-[1.125rem]">{rankData?.name}</span>
        </div>
      </div>
      <div className="flex p-2 flex-col justify-center items-center gap-0 self-stretch bg-gray-50">
        <span className='whitespace-nowrap'>{rankData?.matches ? rankData.matches : 0}
          {isMobile ? '' : ' matches'}
        </span>
      </div>
    </div>
  );
};

export default RankingItem;
