import React from "react";

interface CurrentPageProps {
  prevPage: string;
  currentPage: string;
}


const CurrentPage: React.FC<CurrentPageProps> = ({prevPage, currentPage}) => {
  return ( 
    <>
      <div className="self-stretch relative z-10 ">
        <span className="leading-[1.125rem] font-normal text-[1rem] text-white lg:text-[1.125rem]"> {prevPage} &gt; <span className="font-semibold">{currentPage}</span> </span>
        
      </div>
    </>
   );
}
 
export default CurrentPage;