import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ProfileViewModeBg from '../../../assets/images&icons/profileViewMode.jpeg'
import HeaderWithDots from "../../../components/Moleculas/HeaderWithDots";
import DDMultiple, { Option } from "../../../components/Atomos/DropDown/DDMultiple";
import { createAop, getAllAops, updateAop } from "../../../Services/AreaOfPractice";
import SwitchToogle from "../../../components/Atomos/Switch-Toogle/Switch";
import RemoveX from "../../../components/Atomos/Icons/RemoveX";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Input from "../../../components/Atomos/Inputs/Input";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import Plus from "../../../components/Atomos/Icons/EditMode/Plus";
import { IAreaOfPractice } from "../../../interfaces/models/IAreaOfPractice";
import { useDispatch } from "react-redux";
import { getOffice } from "../../../Services/Office";
import IOffice from "../../../interfaces/models/IOffice";
import { getOfficesByFirmId } from "../../../Services/Firm";
import IFirm from "../../../interfaces/models/IFirm";
import MenuGT from "../GeneralTab/Menu";
import Cancel from "../../../components/Atomos/Icons/Close";
import SaveChanges from "../../../components/Atomos/Icons/EditMode/SaveChanges";
import { hideLoader, showLoader } from "../../../redux/loaderActions";

const UpdateAops = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [aops, setAops] = useState<Option[]>([]);
  const [allAops, setAllAops] = useState<IAreaOfPractice[]>([]);
  const [offices, setOffices] = useState<Option[]>([]);
  const [selectedAops, setSelectedAops] = useState<Option[]>(state?.currentInfo?.areasOfPractice ?? []);
  const [inputValue, setInputValue] = useState('');
  const [isMapped, setIsMapped] = useState(false);
  const [isIgnored, setIsIgnored] = useState(false);
  const [isTargeted, setIsTargeted] = useState(false);
  const [checkCreateAop, setCheckCreateAop] = useState(false);
  const [useOfficeMainCheck, setOfficeMainCheck] = useState(false);
  const [firmOffices, setFirmOffices] = useState<IOffice[]>([]);
  const [mainOfficeAops, setMainOfficeAops] = useState<IAreaOfPractice[]>([]);
  const [originalSelectedAops, setOriginalSelectedAops] = useState<Option[]>([]);
  const [isFirmWideTab, setIsFirmWideTab] = useState(state?.currentInfo?.selectedTab === 0);
  const [isToggleLocked, setIsToggleLocked] = useState(false); // Nuevo estado para bloquear el toggle

  const inputOnchange = (e: any) => {
    setInputValue(e.target.value);
  };

  async function getOffices(): Promise<void> {
    try {
      const allOffices = await getOffice();
      if (allOffices && allOffices.length > 0) {
        setOffices(allOffices);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAops(): Promise<void> {
    try {
      const allAops = await getAllAops();
      if (allAops && allAops.length > 0) {
        setAops(allAops);
        setAllAops(allAops);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getOfficesTypes(currentFirmId: string): Promise<void> {
    try {
      const res: any = await getOfficesByFirmId(currentFirmId);
      if (res && res.length > 0) {
        setFirmOffices(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function filterAopsByMainOffices(): Promise<void> {
    if ((offices?.length > 0 && allAops?.length > 0 && firmOffices.length > 0) && state) {
      const firmOfficeIds: any = firmOffices.filter(office => office && office.officeType === 'Main');

      if (firmOfficeIds && firmOfficeIds.length > 0) {
        const filteredAops = allAops.filter(area =>
          area.offices?.some(office =>
            firmOfficeIds?.some((toFind: any) =>
              office.id === toFind.id
            )
          )
        );
        setMainOfficeAops(filteredAops);
      }
    }
  }

  useEffect(() => {
    getAops();
    getOffices();
  }, []);

  useEffect(() => {
    if (state) {
      getOfficesTypes(state.id);
      filterAopsByMainOffices();
      dispatch(hideLoader())
    }
    dispatch(showLoader())
  }, [allAops, offices, state]);

  const handleSelectionChange: React.Dispatch<React.SetStateAction<Option[]>> = (selected) => {
    setSelectedAops(selected);
  };

  const handleRemove = (option: Option) => {
    setSelectedAops((prevSelected) => prevSelected.filter(item => item.id !== option.id));
  };

  const handleSaveAop = async () => {
    const newAopState = {} as IAreaOfPractice;
    const { currentInfo, ...restState } = state;
    const saveNewAop = {
      ...newAopState,
      name: inputValue,
      isIgnored: false,
      isTargeted: false,
      isMapped: false,
      firms: [{ ...restState }],
      offices: JSON.parse(state?.currentInfo?.firmOffices).filter((of: any) => of.id !== 0 && of.id === state?.currentInfo?.selectedTab)
    };
    const res = await createAop(saveNewAop);
    if (res) {
      setInputValue('');
      setIsMapped(false);
      setIsIgnored(false);
      setIsTargeted(false);
      await getAops();
      setInputValue('');
    }
  };

  const handleSave = async () => {
    if (!state || selectedAops.length === 0) return;

    const { currentInfo, ...restState } = state;
    const newFirm: IFirm = restState as IFirm;

    if (currentInfo.selectedTab === 0) {
      await handleTabZero(newFirm);
    } else {
      await handleOtherTabs(newFirm, currentInfo.selectedTab);
    }
  };

  const handleTabZero = async (newFirm: IFirm) => {
    const updatedAops = allAops.map(aop => updateAopForTabZero(aop, newFirm));
    await updateAops(updatedAops);
  };

  const updateAopForTabZero = (aop: IAreaOfPractice, newFirm: IFirm): IAreaOfPractice => {
    const firmExists = aop.firms?.some(firm => firm.id === newFirm.id);

    if (selectedAops.some(selected => selected.id === aop.id)) {
      if (!firmExists) {
        aop.firms?.push(newFirm);
      }
      aop.offices = firmOffices;
    } else {
      if (firmExists) {
        aop.firms = aop.firms?.filter(firm => firm.id !== newFirm.id);
        aop.offices = [];
      }
    }

    return aop;
  };

  const handleOtherTabs = async (newFirm: IFirm, selectedTab: any) => {
    const newOffice = firmOffices.find(of => of.id === selectedTab);
    if (!newOffice) {
      console.error("Office not found");
      return;
    }

    const selectedAopIds = new Set<any>(selectedAops.map(aop => aop.id));
    const updatedAops = allAops.map(aop => updateAopForOtherTabs(aop, newFirm, newOffice, selectedAopIds));

    await updateAops(updatedAops);
  };

  const updateAopForOtherTabs = (aop: IAreaOfPractice, newFirm: IFirm, newOffice: IOffice, selectedAopIds: Set<string>): IAreaOfPractice => {
    const firmExists = aop.firms?.some(firm => firm.id === newFirm.id);
    const officeExists = aop.offices?.some(office => office.id === newOffice.id);

    if (selectedAopIds.has(aop.id)) {
      if (!firmExists) {
        aop.firms?.push(newFirm);
      }
      if (!officeExists) {
        aop.offices?.push(newOffice);
      }
    } else {
      if (officeExists) {
        aop.offices = aop.offices?.filter(office => office.id !== newOffice.id);
      }
    }

    return aop;
  };

  const updateAops = async (updatedAops: IAreaOfPractice[]) => {
    try {
      const updatePromises = updatedAops.map(aop => updateAop(aop));
      await Promise.all(updatePromises);
      console.log('AOPs have been updated');
      navigate(-1);
    } catch (error) {
      console.error('Error updating AOPs:', error);
    }
  };

  useEffect(() => {
    if (mainOfficeAops.length > 0 && selectedAops.length > 0) {
      const areAllAopsSelected = mainOfficeAops.every(mainAop =>
        selectedAops.some(selected => selected.id === mainAop.id)
      );
      setIsToggleLocked(true); // Bloquear el toggle temporalmente
      setOfficeMainCheck(areAllAopsSelected);
      setIsToggleLocked(false); // Desbloquear el toggle
      dispatch(hideLoader())
    }
  }, [selectedAops, mainOfficeAops]);

  const handleOfficeMainCheckChange = async () => {
    try {
      if (isToggleLocked) return; // Prevenir cambios si el toggle está bloqueado

      // Guardar el estado actual
      const currentCheck = useOfficeMainCheck;

      // Deshabilitar temporalmente el toggle para evitar múltiples clics rápidos
      setOfficeMainCheck(!currentCheck);

      if (!currentCheck) {
        // Guardar las AOPs originales
        setOriginalSelectedAops(selectedAops);
        await filterAopsByMainOffices();
        setSelectedAops(mainOfficeAops);
      } else {
        // Restaurar las AOPs originales
        setSelectedAops(originalSelectedAops);
      }

      // Actualizar el estado del toggle después de que las operaciones asíncronas se completen
      setOfficeMainCheck(!currentCheck);

    } catch (error) {

    }
  };

  const filteredAops = aops.filter(aop => !selectedAops?.some(selected => selected.id === aop.id));

  return (
    <React.Fragment>
      <MenuGT tabSelected={'AopManagement'} />

      <BackgroundComponent image={ProfileViewModeBg} />
      <HeaderWithDots title={state.name} currentpage="AOP Management" />
      <main className="mainSection">
        <div className="flex flex-col py-[1.25rem] px-[1rem] lg:py-[2.5rem] lg:px-[2.5rem] lg:gap-[2.5rem] self-stretch bg-white relative items-start gap-[1.5rem]">
          <div className="flex w-full flex-col items-start gap-6 relative">
            <div className="flex items-center gap-[7.5rem] self-stretch">
              <span className="text-Default font-decimal  text-[1.625rem] leading-normal md:text-[2.125rem] md:leading-[3rem] lg:text-[2.625rem] font-medium lg:leading-[2.75rem]">
                Update AOP
              </span>
            </div>
            <div className="flex flex-col items-start gap-5 lg:gap-[1.75rem] self-stretch">
              <div className="flex flex-col justify-end items-center gap-5 self-stretch w-full md:flex-row md:justify-center">
                <div className="flex flex-col items-end justify-end gap-[.625rem] flex-1 self-stretch">
                  <div className="w-full self-stretch flex">
                    <DDMultiple
                      seter={handleSelectionChange}
                      objects={filteredAops}
                      selectedObjst={selectedAops}
                      title={isFirmWideTab ? "Select the Firm’s AOPs available." : "Select the Office’s AOPs available."}
                      error={false}
                      required={false}
                      hideCheckboxes
                      disabled={useOfficeMainCheck && !isFirmWideTab} // Deshabilitar dropdown cuando el toggle está activado y no está en la pestaña Firm Wide
                    />
                  </div>
                  {
                    useOfficeMainCheck &&
                    <div className="w-full">
                      <span className="text-primary text-base leading-[1.125rem] lg:text-[1.125rem] lg:leading-5 self-stretch">
                        This Office is using the Firm’s AOP. Please make any updates from the
                        “Firm Wide tab. If specific AOPs need to be set up for the office, please turn off toggle and set up Office AOPs.
                      </span>
                    </div>
                  }
                </div>
                <div className="flex h-[4.75rem] justify-center lg:justify-end items-center gap-[2rem] self-stretch w-full md:w-auto">
                  <SwitchToogle
                    checked={useOfficeMainCheck}
                    seter={handleOfficeMainCheckChange}
                    centered
                    className="text-[1.125rem] whitespace-nowrap"
                    title="Use Main Office’s Areas of Practice"
                    small
                    disabled={isFirmWideTab}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1  md:grid-cols-3 items-start gap-5 self-stretch">
                {selectedAops.map((selected) => (
                  <div key={selected.id} className="flex h-9 py-2 pl-3 pr-4 items-center gap-[.625rem] flex-1 bg-[#F6F6F6] text-[1.125rem] font-normal">
                    <button
                      onClick={() => handleRemove(selected)}
                      className=""
                    >
                      <div className="w-5 h-5 flex" onClick={() => handleRemove(selected)}>
                        <RemoveX />
                      </div>
                    </button>
                    {selected.name}
                  </div>
                ))}
              </div>
              <div className="flex items-end gap-2 md:py-4 lg:py-0">
                <SwitchToogle
                  checked={checkCreateAop}
                  seter={() => setCheckCreateAop(!checkCreateAop)}
                  centered
                  className="text-[1.125rem] whitespace-nowrap"
                  title="Create new AOP"
                  small
                />
              </div>

              {checkCreateAop &&
                <>
                  <section className="formsectionNTitle">
                    <CatalogSubTitle className="text-accent-Default-dark" title="Create New AOP" />
                  </section>
                  <div className='flex flex-col items-start gap-5 self-stretch md:flex-col'>
                    <div className='flex items-start gap-5 self-stretch w-full lg:min-w-[40rem]'>
                      <Input
                        isWfull={true}
                        value={inputValue}
                        onChange={inputOnchange}
                        name="Name"
                        title="Name"
                        error={false}
                      />
                    </div>
                    <div className='flex justify-center items-start gap-5 md:justify-start flex-col self-stretch w-full md:flex-row md:pb-4 lg:pb-0'>
                      <div className='flex flex-col items-center justify-center gap-[.625rem] mx-auto md:mx-0'>
                        <label className='text-base leading-[1.125rem] text-center text-[#474F56]'>Is Mapped?</label>
                        <SwitchToogle disabled seter={() => setIsMapped(!isMapped)} checked={isMapped} small centered />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-[.625rem] mx-auto md:mx-0'>
                        <label className='text-base leading-[1.125rem] text-center text-[#474F56]'>Is Ignored?</label>
                        <SwitchToogle disabled seter={() => setIsIgnored(!isIgnored)} checked={isIgnored} small centered />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-[.625rem] mx-auto md:mx-0'>
                        <label className='text-base leading-[1.125rem] text-center text-[#474F56]'>Is Targeted?</label>
                        <SwitchToogle disabled seter={() => setIsTargeted(!isTargeted)} checked={isTargeted} small centered />
                      </div>
                      <NewButton color="accent" size="large" content="textIcon" text="CREATE AOP" className="whitespace-nowrap !h-12 w-full !py-3 md:max-w-[6rem]  lg:!min-w-[11.8rem] md:ml-auto" icon={<Plus />} onClick={handleSaveAop} />
                    </div>
                  </div>
                </>
              }

              <div className="flex items-start justify-end w-full self-stretch gap-4 ">
                <NewButton style="outlined" color="neutral" size="medium" text="Cancel" content="textIcon" icon={<Cancel />} onClick={() => navigate(-1)} className="md:max-w-[7.5rem]" />
                <NewButton style="filled" color="accent" size="medium" text="Save" content="textIcon" icon={<SaveChanges />} onClick={handleSave} className="md:max-w-[7.5rem]" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default UpdateAops;
