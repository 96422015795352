import React, { useMemo, useCallback, useState } from "react";
import { createEditor, Descendant, BaseEditor } from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";
import { CheckListItemElement } from "./Components";

interface EditorProps {
  showMessage: boolean;
  value?: any;
  className?: string;
}

type CustomElement = {
  type: any;
  url?: string;
  checked?: boolean;
  children: CustomText[];
};
type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  [key: string]: any;
};

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const init: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const PreviewComponente: React.FC<EditorProps> = (props) => {
  const [initialValue] = useState<Descendant[]>(
    props.value ? props.value : init
  );
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case "link":
        return (
          <a
            className="text-Default underline"
            {...props.attributes}
            href={props.element.url}
          >
            {props.children}
          </a>
        );
      case "heading-one":
        return <h1 {...props.attributes}>{props.children}</h1>;
      case "heading-two":
        return <h2 {...props.attributes}>{props.children}</h2>;
      case "heading-three":
        return <h3 {...props.attributes}>{props.children}</h3>;
      case "bulleted-list":
        return (
          <ul className="ml-5 list-disc" {...props.attributes}>
            {props.children}
          </ul>
        );
      case "numbered-list":
        return (
          <ol className="ml-5 list-decimal" {...props.attributes}>
            {props.children}
          </ol>
        );
      case "list-item":
        return <li {...props.attributes}>{props.children}</li>;
      case "check-list-item":
        return <CheckListItemElement {...props} />;
      case "left":
        return (
          <div style={{ textAlign: "left" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "center":
        return (
          <div style={{ textAlign: "center" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "right":
        return (
          <div style={{ textAlign: "right" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "justify":
        return (
          <div style={{ textAlign: "justify" }} {...props.attributes}>
            {props.children}
          </div>
        );
      case "paragraph":
      default:
        return <p {...props.attributes}>{props.children}</p>;
    }
  }, []);

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  return (
    <Slate editor={editor} initialValue={initialValue}>
      <Editable
        readOnly
        className={`${props.className} h-auto w-auto overflow-y-auto bg-white focus:outline-none focus:ring-0 focus:border-transparent`}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

export default PreviewComponente;
