import { Option } from "../../components/Atomos/DropDown/DDMultiple";
import { IContent } from "../../interfaces";

export const contentEmptyState = {
  title: "",
  contentType: "article",
  writtenDate: undefined,
  reviewedDate: "pending",
  reviewedBy: undefined,
  publishedDate: "pending",
  unpublishedDate: "pending",
  unpublishedBy: undefined,
  notifySubscribers: undefined,
  publishStrategy: false,
  totalComments: 0,
  isApproved: "pending",
  shortId: "",
  authors: [],
  submissionType: "manualInput",
  footnotes: "",
  link: "",
  memberFirms: [],
  nonMemberFirms: [],
  jurisdictions: [],
  tags: [],
  articleContent: "",
  generalTopics: [],
  globalCrisisHub: [],
  practiceIndustries: [],
  favorite: false,
} as IContent;

export type contentContext = {
  content: IContent | null;
  contentErrors: { field: string; message: string }[];
  contentOptions: Option[];
  modified: boolean;
} | null;
