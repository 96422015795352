import { createSlice } from "@reduxjs/toolkit";
import { AOPRankingEmpty } from "../emptyStates/AOPRanking";

export const aopRankingSlice = createSlice({
  name: "aopRanking",
  initialState: AOPRankingEmpty,
  reducers: {
    modifyAOPRanking: (state, action) => ({ ...state, ...action.payload }),
    newAOPRanking: (_, action) => action.payload,
    resetAOPRanking: () => AOPRankingEmpty,
  },
});

export const { modifyAOPRanking, newAOPRanking, resetAOPRanking } = aopRankingSlice.actions;
