import React from "react";
const ChevronDownCard: React.FC  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48"  >
      <path d="M10.972 19l2.828-2.828 9.9 9.9 9.9-9.9L36.428 19 23.7 31.728 10.972 19z" ></path>
    </svg>
  );
};

export default ChevronDownCard;
