import axios, { AxiosInstance, } from "axios";

var baseURL: string;
var axiosInstance: AxiosInstance;
export default class AxiosPublic {
    constructor() {
        baseURL = (process.env.REACT_APP_ROOT_API_PUBLIC as string)
        axiosInstance = axios.create(
            {
                baseURL: baseURL,
                timeout: 200000
            }
        )
    }

    async Get(path: string, id?: string) {
        if(id){
            return await axiosInstance.get(`${path}?id=${id}`)
        }
        return await axiosInstance.get(path)

    }

    async Post(path: string, data: any) {
        return await axiosInstance.post(path, data)
    }

    async Put(path: string, data: any) {
        return await axiosInstance.put(`${path}?id=${data.id}`, data)
    }

    async Delete(path: string, id: string) {
        return await axiosInstance.delete(`${path}?id=${id}`)
    }

    async Patch(path: string, id: string) {
        return await axiosInstance.patch(`${path}?id=${id}`)
    }


}