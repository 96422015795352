import axios, { AxiosInstance } from "axios";

var baseURL: string;
var axiosInstance: AxiosInstance;

const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export default class Axios {
  constructor() {
    baseURL = (process.env.REACT_APP_ROOT_API as string)
    axiosInstance = axios.create(
      {
        baseURL: baseURL,
        timeout: 400000
      }
    )
    axiosInstance.interceptors.request.use(function (config: any) {
      if (typeof window !== 'undefined' && !config.url.includes('/password-reset')) {

        let token: string | null = sessionStorage.getItem('token');
        let decoded: string | null = sessionStorage.getItem('token_decoded');
        if (!token) {
          token = getCookie('token');
        }
        if (token) {
          config.headers.Authorization = 'Bearer ' + token;
        }

        if (!decoded) {
          decoded = getCookie('token_decoded');
        }

        if (decoded) {
          const userRole: string = JSON.parse(decoded)?.extension_WSG_Permissions as string;
          if (userRole) {
            config.headers['user-role'] = userRole;
          }
        }

        const currentPath = window.location.pathname;
        config.headers['x-frontend-route'] = currentPath;
      }
      return config;
    }, function (error: any) {
      console.error(error);
      return Promise.reject(error);
    });
  }


  async Get(path: string, id?: string) {
    if (id) {
      return await axiosInstance.get(`${path}?id=${id}`)
    }
    return await axiosInstance.get(path)
  }

  async Post(path: string, data: any) {
    return await axiosInstance.post(path, data)
  }

  async Put(path: string, data: any) {
    return await axiosInstance.put(`${path}?id=${data.id}`, data);
  }

  async PutDataIntoModel(path: string, data: any) {
    return await axiosInstance.put(path, data)
  }

  async Delete(path: string, id: string) {
    return await axiosInstance.delete(`${path}?id=${id}`)
  }

  async Patch(path: string, data: any) {
    return await axiosInstance.patch(`${path}?id=${data.id}`, data)
  }
}
