import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const statesSlice = createSlice({
  name: "states",
  initialState: Empty,
  reducers: {
    SetStates: (_, action) =>  action.payload,
  },
});

export const { SetStates } = statesSlice.actions;
