import React from "react";
import Phone from "../../Atomos/Icons/ViewMode/Phone";
import Email from "../../Atomos/Icons/Email";

interface ContactFrameProps {
  type: string;
  name?: string;
  phone?: string;
  email?: string;
}

const ContactFrame: React.FC<ContactFrameProps> = ({
  type,
  name,
  phone,
  email,
}) => {
  return (
    <div className="grid text-sm col-span-1 lg:col-span-2 gap-2">
      <div className="not-italic font-bold text-xl leading-7 text-gray-font">
        {type}
      </div>
      <div className="italic font-normal text-lg leading-5 text-gray-font">
        {name}
      </div>
      <div className="flex flex-row gap-2">
        <div className="fill-gray-font h-5 w-5">
          <Phone />
        </div>
        <div className="not-italic  font-normal text-lg leading-5 text-gray-font">
          {phone}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="fill-gray-font h-5 w-5">
          <Email />
        </div>
        <div className="not-italic font-normal text-lg leading-5 text-gray-font">
          {email}
        </div>
      </div>
    </div>
  );
};

export default ContactFrame;
