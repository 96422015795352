import React from "react";

function IconPlaceHolder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 18 17"
    >
      <path 
        d="M.372 16.794v-5.629h6.774v1.25h3.75v-1.25h6.744v5.63H.372zm8.024-5.629v-1.25h1.25v1.25h-1.25zM.372 9.915v-6.08h5.024V.102h7.208v3.733h5.036v6.08h-6.744v-1.25h-3.75v1.25H.372zm6.674-6.08h3.908V1.752H7.046v2.083z"
      ></path>
    </svg>
  );
}

export default IconPlaceHolder;
