import Axios from "./axios";
const axios = new Axios();

export const uploadImage = async (photo: File, userId: string, path: string): Promise<string | undefined> => {
  try {
    if (photo) {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}${photo?.name}`;
      const extension = fileName.split('.').pop();
      const fileNameWithoutExtension = fileName.slice(0, -(extension ? extension.length + 1 : 'croppedImage'));

      const reader = new FileReader();
      return new Promise<string | undefined>((resolve, reject) => {
        reader.onload = async () => {
          if (reader.result && typeof reader.result === 'string') {
            const base64FileData = reader.result.split(",")[1];
            const cleanedFileName = fileNameWithoutExtension.replace(/[\s.]/g, '').replace(/\s/g, '');
            try {
              const file = {
                fileData: base64FileData,
                fileName: (cleanedFileName).trim(),
                extension: extension,
                path: `${path}${userId}`
              };
              const response = await axios.Post("/upload-files", file);

              if (response.status === 200) {
                const link = response.data.image as string;
                console.log("Image uploaded successfully. Link:", link);
                resolve(link);
              } else {
                console.error("Error uploading file. Status:", response.status, "Data:", response.data);
                resolve(undefined);
              }
            } catch (error) {
              console.error("Error uploading image:", error);
              resolve(undefined);
            }
          }
        };
        reader.readAsDataURL(photo);
      });
    }
  } catch (error) {
    console.error("Error handling cropped image upload:", error);
    return undefined;
  }
};
