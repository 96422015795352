import React from "react";

function FileInputIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 33"
    >
      
        <path 
          d="M8.37 27.638c-2.04 0-3.796-.731-5.265-2.193C1.635 23.984.9 22.237.9 20.205c0-1.81.556-3.422 1.669-4.838 1.112-1.417 2.567-2.27 4.363-2.563.494-2.193 1.59-3.98 3.287-5.358 1.697-1.38 3.648-2.07 5.853-2.07 2.558 0 4.718.913 6.48 2.736 1.764 1.824 2.695 4.021 2.796 6.592v.8c1.637.069 3.006.675 4.104 1.818 1.099 1.143 1.648 2.56 1.648 4.249 0 1.67-.603 3.1-1.808 4.286-1.205 1.187-2.638 1.78-4.296 1.78h-7.45c-.721 0-1.345-.262-1.87-.788-.526-.525-.79-1.142-.79-1.852v-7.733l-2.86 2.88-1.584-1.566L16 13.002l5.577 5.576-1.603 1.566-2.861-2.88v7.733h7.89c.932 0 1.741-.342 2.427-1.026.686-.685 1.03-1.497 1.03-2.438 0-.95-.343-1.764-1.027-2.444-.685-.68-1.497-1.02-2.437-1.02h-2.27v-3.007c0-1.92-.651-3.571-1.953-4.953-1.303-1.382-2.913-2.073-4.831-2.073-1.946 0-3.572.714-4.877 2.143-1.305 1.428-1.958 3.125-1.958 5.09h-.803c-1.33 0-2.457.467-3.38 1.4-.922.933-1.384 2.086-1.384 3.46 0 1.33.473 2.474 1.418 3.432.945.957 2.086 1.436 3.422 1.436h4.507v2.64H8.37z"
        ></path> 
    </svg>
  );
}

export default FileInputIcon;
