import React, { useEffect, useState } from "react";
import Profilepreferences from "../Atomos/Icons/ProfilePreferences";
import IconPlaceHolder from "../Atomos/Icons/IconPlaceHolder";
import ChevronRight from "../Atomos/Icons/ChevronRight";
import PencilEdit from "../Atomos/Icons/PencilEdit";
import SvgIcon from "./IconComponent";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../Context";

interface ExpertiseCardProps {
  isEditable?: boolean;
  personAops?: any;
  practiceIndustries?: any;
  person?: any;
  onBooleanChange?: (value: boolean) => void;
}
const ExpertiseCard: React.FC<ExpertiseCardProps> = ({
  isEditable,
  personAops,
  practiceIndustries,
  person,
  onBooleanChange,
}) => {
  const [isViewMoreVisible, setIsViewMoreVisible] = useState(false)
  const [isViewLessVisible, setIsViewLessVisible] = useState(false)
  const [piLength, setPiLength] = useState(4)
  const [userAOPS, setUserAOPS] = useState<any>()


  const { mode } = useAppContext()


  useEffect(() => {
    if (person && person.areasOfPractice) {
      const sortedFilteredAops = person.areasOfPractice
        .filter((aop: any) => aop.expertise)
        .sort((a: any, b: any) => a.order - b.order);
      setUserAOPS(sortedFilteredAops);
    }
  }, [person]);

  useEffect(() => {
    if (userAOPS && personAops) {
      const sortedMatchingAops = personAops
        .filter((aop: any) => userAOPS.some((userAop: any) => userAop.id === aop.id))
        .sort((a: any, b: any) => a.order - b.order);
      setUserAOPS((prevAOPS: any) => sortedMatchingAops.length !== prevAOPS.length ? sortedMatchingAops : prevAOPS);
    }
  }, [personAops, userAOPS]);


  useEffect(() => {
    if (practiceIndustries) {
      if (practiceIndustries.length > piLength) {
        setIsViewMoreVisible(true)
      }
    }
  }, [practiceIndustries])

  const togglePiLen = async () => {
    if (practiceIndustries) {
      setPiLength(practiceIndustries.length)
      setIsViewMoreVisible(false)
      setIsViewLessVisible(true)
      onBooleanChange?.(true);

    }
  }

  const toggleDefaultPi = async () => {
    setPiLength(4)
    setIsViewLessVisible(false)
    setIsViewMoreVisible(true)
    onBooleanChange?.(false);
  }


  const editLinkPath = mode === 'staff'
    ? `/staff/profile/edit/attorney/${person?.id}/${person?.generatedId}`
    : `/profile/edit/attorney/${person?.id}/${person?.generatedId}`;



  return (
    <>
      <div className={"flex flex-col items-start gap-5 self-stretch lg:flex-1 "}>
        <div className="flex min-h-[2.75rem] justify-center items-center gap-0 ">
          {isEditable &&
            <React.Fragment>
              <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-[1.125rem] uppercase lg:text-[1.125rem]">
                Profile Preferences
              </span>
              <div className="w-10 h-10 flex items-center justify-center fill-[#004578]">
                <Profilepreferences />
              </div>
            </React.Fragment>
          }
        </div>
        <div className="flex flex-col items-start gap-5 self-stretch md:flex-row md:gap-7 lg:flex-row lg:items-start lg:gap-7 ">
          <div className="flex flex-col items-center gap-5 self-stretch md:w-full">
            <div className="flex items-end gap-[.625rem] self-stretch">
              <span className="text-[#BC3323] font-decimal text-[1rem] lg:text-[1.125rem] lg:leading-[1.25rem] font-bold leading-5">
                {isEditable ? 'Your Expertise' : 'Expertise'}
              </span>
              {isEditable &&
                <Link to={editLinkPath} state={{ ...person, isOnlyAops: true }}>

                  <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-accentDefault">
                    <PencilEdit className='w-full h-full pencilRed' />
                  </div>
                </Link>
              }
            </div>
            <ul className="flex flex-col items-start gap-[.625rem] self-stretch">
              {userAOPS && userAOPS.slice(0, 4).map((aop: any) =>
                <li key={aop.id} className="text-[#474F56] text-[1rem] leading-6 lg:text-lg lg:leading-8">&bull; {aop.name}</li>
              )

              }
            </ul>
          </div>
          <div className="flex flex-col items-end gap-[.625rem] self-stretch md:w-full ">
            <div className="flex flex-col items-center gap-5 self-stretch">
              <div className="flex items-end gap-[.625rem] self-stretch">
                <span className="text-[#BC3323] font-decimal text-[1rem] lg:text-[1.125rem] lg:leading-[1.25rem] font-bold leading-5">
                  WSG Practice Industries
                </span>
                {isEditable &&
                  <Link to={editLinkPath} state={{ ...person, isOnlyAops: true }}>
                    <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-accentDefault">
                      <PencilEdit className='w-full h-full pencilRed' />
                    </div>
                  </Link>
                }
              </div>
              <div className="flex items-start gap-2 flex-col self-stretch">
                {practiceIndustries && practiceIndustries.slice(0, piLength).map((pi: any) =>
                  <div className="flex items-center gap-2 self-stretch" key={pi.id}>
                    <div className="w-5 h-5 p-[.125rem]">
                      <SvgIcon practiceId={pi.id} iconUrl={pi?.icon} />
                    </div>
                    <span className="text-[#474F56] text-[1rem] leading-6 lg:text-lg lg:leading-8">{pi.name}</span>
                  </div>
                )
                }
              </div>
            </div>
            {isViewMoreVisible &&
              <div className="flex w-full p-0 justify-end items-center self-stretch cursor-pointer gap-[.625rem]" onClick={togglePiLen}>
                <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">View More</span>
                <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                  <div className="w-3 h-3 fill-white">
                    <ChevronRight />
                  </div>
                </div>
              </div>
            }
            {isViewLessVisible &&
              <div className="flex w-full p-0 justify-end items-center self-stretch cursor-pointer gap-[.625rem]" onClick={toggleDefaultPi}>
                <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">View Less</span>
                <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                  <div className="w-3 h-3 rotate-180 fill-white">
                    <ChevronRight />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="flex flex-col items-start gap-5 self-stretch md:flex-row md:gap-7 lg:flex-row lg:items-start lg:gap-7 ">
          <div className="flex flex-col items-end gap-4 self-stretch md:w-full">
            <div className="flex flex-col items-center gap-5 self-stretch">
              <div className="flex items-end gap-[.625rem] self-stretch">
                <span className="text-[#BC3323] font-decimal text-[1rem] lg:text-[1.125rem] lg:leading-[1.25rem] font-bold leading-5">
                  Areas Of Practice
                </span>
                {isEditable &&
                  <Link to={editLinkPath} state={{ ...person, isOnlyAops: true }}>

                    <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-accentDefault">
                      <PencilEdit className='w-full h-full pencilRed' />
                    </div>
                  </Link>
                }
              </div>
              <ul className="flex items-start gap-[.625rem] flex-col self-stretch">
                {personAops?.slice(0, 4).map((aop: any) =>
                  <li key={aop.id} className="text-[#474F56] text-[1rem] leading-6 lg:text-lg lg:leading-8">&bull; {aop.name}</li>
                )}
              </ul>
            </div>
          </div>
          <div className="flex flex-col self-stretch gap-[.625rem] md:w-full">
            <div className="flex flex-col items-center gap-5 self-stretch">
              <div className="flex items-end gap-[.625rem] self-stretch">
                <span className="text-[#BC3323] font-decimal text-[1rem] lg:text-[1.125rem] lg:leading-[1.25rem] font-bold leading-5">
                  WSG Groups
                </span>
                {isEditable &&
                  <div className="w-6 h-6 flex pt-[0.1875rem] pl-[0.1875rem] pr-[0.125rem] pb-[0.125rem] shrink-0 items-center fill-accentDefault">
                    <PencilEdit className='w-full h-full pencilRed' />
                  </div>
                }
              </div>
              <div className="flex flex-col items-start gap-[.625rem] self-stretch w-full">
                <div className="flex justify-between items-center self-stretch w-full">
                  <span className="text-[#474F56] text-[1rem] leading-6 lg:text-lg lg:leading-8">Fintech Law</span>
                  <span className="text-[#707070] text-[1rem] leading-6">Member</span>
                </div>
                <div className="flex justify-between items-center self-stretch w-full">
                  <span className="text-[#474F56] text-[1rem] leading-6 lg:text-lg lg:leading-8">Trade and Investment</span>
                  <span className="text-[#707070] text-[1rem] leading-6">Member</span>
                </div>
              </div>
            </div>
            {/* <div className="flex w-full p-0 justify-end items-center gap-3 self-stretch">
              <span className="text-[#004578] text-center font-decimal text-[1rem] font-bold leading-6 lg:text-[1.125rem] lg:leading-[1.5rem]">View More</span>
              <div className="flex p-1 justify-center items-center gap-[.625rem] rounded-full bg-[#0070CD]">
                <div className="w-3 h-3">
                  <ChevronRight />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpertiseCard;