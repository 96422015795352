function CustomShuffle() {
    return (
        <div style={{width: '16px', height: '16px'}}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6279 16.0445L18.5673 14.9838L20.777 12.7741L14.2893 12.7918L14.2716 11.2892L20.8123 11.2539L18.5673 9.00879L19.6279 7.94813L23.6761 11.9963L19.6279 16.0445ZM12.7513 22.9211L11.2487 22.9034L11.2487 3.17516L9.00365 5.42022L7.94299 4.35956L11.9912 0.311377L16.0394 4.35956L14.9787 5.42022L12.7513 3.19284L12.7513 22.9211ZM9.72843 12.7211L1.08405 12.7388V11.2185L9.72843 11.2008L9.72843 12.7211Z"/>
                <path d="M4.37207 7.93047L5.43273 8.99113L3.22302 11.2008L9.71073 11.1832L9.72841 12.6858L3.18767 12.7211L5.43273 14.9662L4.37207 16.0268L0.323886 11.9787L4.37207 7.93047ZM11.2487 1.05386L12.7513 1.07154L12.7513 20.7998L14.9963 18.5548L16.057 19.6154L12.0088 23.6636L7.96064 19.6154L9.0213 18.5548L11.2487 20.7821L11.2487 1.05386ZM14.2716 11.2539L22.9159 11.2362L22.9159 12.7565L14.2716 12.7742L14.2716 11.2539Z"/>
            </svg>
        </div>
    );
}

export default CustomShuffle;