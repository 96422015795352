import { useParams } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import Input from "../../../components/Atomos/Inputs/Input";
import "../../../assets/css/AddCatalogs.css";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import CloseModal from "../../../components/Atomos/Icons/EditMode/CloseModal";
import TextArea from "../../../components/Atomos/Inputs/TextArea";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import RadioButton from "../../../components/Atomos/Radio/RadioButton";
import SwitchToggle from "../../../components/Atomos/Switch-Toogle/Switch";
import InputImage from "../../../components/Atomos/InputImage/InputImage";
import { PhoneNumber } from "../../../components/Atomos/PhoneNumber/PhoneNumber";
import DDMultiple from "../../../components/Atomos/DropDown/DDMultiple";
import Plus2 from "../../../components/Atomos/Icons/Plus";
import InputImageMinimal from "../../../components/Atomos/InputImage/InputImageMinimal";
import useViewport from "../../../hooks/useViewPort";
import Remove from "../../../components/Atomos/Icons/Remove";
import NewTooltip from "../../../components/Atomos/Tooltip/NewTooltip";
import { getStates, getStatesListOptions } from "../../../Services/State";
import SaveOverride from "../../../components/Catalogs/SaveOverride";
import { getAllCities, getCitiesListOptions } from "../../../Services/City";
import { UseFirmList } from "../../../hooks/Catalogs/useFirm";
import { UseCountryList } from "../../../hooks/Catalogs";
import { UseJurisdictionGroupList } from "../../../hooks/Catalogs/useJurisdictionGroup";
import { UseJurisdictionList } from "../../../hooks/Catalogs/useJurisdiction";
import { uploadImage } from "../../../utils/UploadImage";
import { useLogging } from "../../../Context/LoggingContext";
import { useDispatch, useSelector } from "react-redux";
import { Appstore, contentContext, contentsSlice } from "../../../redux";
import { useStatesList } from "../../../hooks/Catalogs/useStates";
import { useCityList } from "../../../hooks/Catalogs/useCityList";

interface iCatalogProps {
  mode: "add" | "edit"; 
  isMember?: boolean;
  isMemeberDisabled?: boolean | undefined; 
}

const moment = require("moment");

let now = moment().format("DD/MM/YYYY");
interface ActiveErrors {
  address: boolean;
  city: boolean;
  country: boolean;
  firmOverview: boolean;
  inputAcceptedEmailDomain: boolean;
  inputFirmName: boolean;
  inputJurisdiction: boolean;
  inputJurisdictionGroup: boolean;
  inputMembershipType: boolean;
  inputName: boolean;
  inputOtherServiceType: boolean;
  inputPhoneNumber: boolean;
  inputSecondaryJurisdiction: boolean;
  inputServiceType: boolean;
  inputWebsiteUrl: boolean;
  logo: boolean;
  notable1: boolean;
  notable2: boolean;
  notable3: boolean;
  notable4: boolean;
  smallLogo: boolean;
  state: boolean;
}

const initialActiveErrors: ActiveErrors = {
  inputName: false,
  inputFirmName: false,
  inputServiceType: false,
  inputMembershipType: false,
  inputJurisdiction: false,
  inputJurisdictionGroup: false,
  inputOtherServiceType: false,
  inputPhoneNumber: false,
  inputWebsiteUrl: false,
  inputAcceptedEmailDomain: false,
  inputSecondaryJurisdiction: false,
  country: false,
  state: false,
  city: false,
  logo: false,
  smallLogo: false,
  firmOverview: false,
  address: false,
  notable1: false,
  notable2: false,
  notable3: false,
  notable4: false,
};

const ActiveErrorsMessage = {
  inputName: "The Name is required.",
  inputFirmName: "The Firm Legal Name is required.",
  inputServiceType: "The Service Type is required.",
  inputMembershipType: "The Membership Type is required.",
  inputJurisdiction: "The Jurisdiction is required.",
  inputJurisdictionGroup: "The Jurisdiction Group is required.",
  inputOtherServiceType: "The Service Type is required.",
  inputPhoneNumber: "The Phone Number is required.",
  inputWebsiteUrl: "The Website Url is required.",
  inputAcceptedEmailDomain: "The Accepted Email Domain is required.",
  inputSecondaryJurisdiction: "The Secondary Jurisdiction is required.",
  country: "Country is required",
  state: "State is required",
  city: "City is required",
  address: "Address is required",
  notable: "Notable URL is required",
  logo: "Logo is required",
  smallLogo: "Small Logo is required",
  firmOverview: "Firm Overview is required",
};

const initialFormData = {
  name: "",
  firmName: "",
  shortId: "",
  hasSecondary: false,
  otherServiceType: "",
  phoneNumber: "",
  websiteUrl: "",
  dateCreated: now,
  dateJoined: "",
  dateWithdrawn: "",
  firmOverview: "",
  logo: "",
  smallLogo: "",
  professionalsInPrimary: "",
  professionalsWorldWide: "",
  totalProfessionals: "",
  acceptedEmailDomain: "",
  practiceIndustry: "",
  areaOfPractice: "",
  linkedinUrl: "",
  xUrl: "",
  facebookUrl: "",
  instagramUrl: "",
  youtubeUrl: "",
  otherSocialMedia: "",
  notable1: "",
  notable2: "",
  notable3: "",
  notable4: "",
};

const AddFirm: React.FC<iCatalogProps> = (props) => {
  const { state } = useLocation();
  const axios = new Axios();
  const navigate = useNavigate();
  const { id } = useParams();
  const firmId = id ?? ""; // Provide a default value for id

  const [buttonClicked, setButtonClicked] = useState("");
  const [checked, setChecked] = useState(false);
  const location = useLocation()
  const [spinner, setSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logo, setLogo] = useState<string | File | null>();
  const [smallLogo, setSmallLogo] = useState<string | File | null>();
  const [notableImage1, setNotableImage1] = useState<string | File | null>();
  const [notableImage2, setNotableImage2] = useState<string | File | null>();
  const [notableImage3, setNotableImage3] = useState<string | File | null>();
  const [notableImage4, setNotableImage4] = useState<string | File | null>();
  const [imageUrl, setImageUrl] = useState("");
  const [hasPhoto, setHasPhoto] = useState(false);

  const [filteredJurisdictions, setFilteredJurisdictions] = useState<any[]>([]);

  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [country, setCountry] = useState("");
  const [groupJurisdictions, setGroupJurisdictions] = useState<any[]>([]);
  const [groupCountry, setGroupCountry] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const [groupRegions, setGroupRegions] = useState<any[]>([]);
  const [listingCategory, setListingCategory] = useState<any[]>([]);
  const [acceptedEmailDomain, setAcceptedEmailDomain] = useState<any[]>([]);

  const [activeErrors, setActiveErrors] =
    useState<ActiveErrors>(initialActiveErrors);
  const [activeErrorsMessage, setActiveErrorsMessage] =
    useState(ActiveErrorsMessage);
  const [canSave, setCanSave] = useState(true);

  const [formData, setFormData] = useState(initialFormData);

  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedMembershipType, setSelectedMembershipType] = useState("");
  const [selectedMembershipStatus, setSelectedMembershipStatus] =
    useState("Onboarding");
  const [selectedJurisdiction, setSelectedJurisdiction] = useState({
    id: "",
    name: "",
  });
  const [selectedJurisdictionGroup, setSelectedJurisdictionGroup] = useState({
    id: "",
    name: "",
  });
  const [selectedSecondaryJurisdiction, setSelectedSecondaryJurisdiction] =
    useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name: "" });
  const [selectedState, setSelectedState] = useState({ id: "", name: "" });
  const [selectedCity, setSelectedCity] = useState({ id: "", name: "" });
  const [selectedAddress, setSelectedAddress] = useState({ id: "", name: "" });
  const [contactStructure, setContactStructure] = useState("firmWide");
  const [directPhoneCode, setDirectPhoneCode] = useState<{
    code: string;
    phone: string;
    basecode: string;
  }>({ code: "(+1)", phone: "", basecode: "USA" });
  const [isContentRedirect] = useState(!!state?.contentRedirectUrl ?? false);

  const [isMember, setIsMember] = useState(state?.isMember ?? true);
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const { logActivity } = useLogging();

  const dispatch = useDispatch();
  const currentContent: contentContext = useSelector(
    (store: Appstore) => store.content
  );
  const { refetchFirms, firmData: firms } = UseFirmList();

  const { countryData: countries } = UseCountryList();
  const { jurisdictionGroupData: jurisdictionGroups } =
    UseJurisdictionGroupList();
  const { jurisdictionData: jurisdictions } = UseJurisdictionList();
  const { statesData } = useStatesList();
  const { cityData } = useCityList();

  useEffect(() => {
    if (
      !countries.length || 
      !firms.length || 
      !jurisdictionGroups.length || 
      !jurisdictions.length ||
      !cityData.length ||
      !statesData.length
    ) return;
    setData();
  }, [
    state, 
    countries, 
    firms, 
    jurisdictionGroups, 
    jurisdictions,
    cityData,
    statesData
  ]);

  const setData = () => {
    if (state) {
      setFormData(state)
      setLogo(state?.logo?.length ? state.logo : null)
      setSmallLogo(state?.smallLogo?.length ? state.smallLogo : null)
      setSelectedServiceType(state?.serviceType ?? '')
      setSelectedMembershipStatus(state?.membershipStatus ?? '')
      setSelectedMembershipType(state?.membershipType ?? '')
      if (state.isMember) {
        const firmJurisdiction = jurisdictions.find((j) => j.id === state?.jurisdiction)
        const firmJurisdictionGroup = jurisdictionGroups.find((j) => j.id === state?.jurisdictionGroup)
        setSelectedJurisdiction(firmJurisdiction)
        setSelectedJurisdictionGroup(firmJurisdictionGroup)
        setSelectedSecondaryJurisdiction(state?.secondaryJurisdiction ?? '')
  
        const firmCity = cityData.find((c) => c.id === state?.city)
        const firmState = statesData.find((s) => s.id === firmCity?.state)
        const firmCountry = countries.find((c) => c.id === firmState?.country)
        
        if (!firmCountry?.length) setSelectedCountry(firmCountry)
        if (!firmState?.length) setSelectedState(firmState);
        setSelectedCity({id:firmCity?.id ?? '', name:firmCity?.name ?? ''});
        setDirectPhoneCode(state.phoneNumber);
      } else {
        const firmCountry = countries.find((c) => c.id === state?.country)
        const firmState = statesData.find((s) => s.id === state?.state)
        if (!firmCountry.length) setSelectedCountry(firmCountry)
        if (!firmState?.length) setSelectedState(firmState);
      }
    }
  }

  useEffect(() => {
    const completeJurisdiction: any = jurisdictions.filter(
      (jurisdiction: any) => jurisdiction?.id === selectedJurisdiction?.id
    );
    setCountry(completeJurisdiction[0]?.country);
  }, [selectedJurisdiction]);

  useEffect(() => {
    const completeCountry: any = countries.filter(
      (countryExample: any) => countryExample.name === country
    );
    setRegions(completeCountry[0]?.regions);
  }, [country]);

  useEffect(() => {
    if (!selectedCountry?.id) return;
    getStatesListOptions({ countries: [selectedCountry] }).then((data) =>
      setStates(data)
    );
  }, [selectedCountry]);

  useEffect(() => {
    if (!selectedState?.id) return;
    getCitiesListOptions({ states: [selectedState] }).then((data) =>
      setCities(data)
    );
  }, [selectedState]);

  useEffect(() => {
    if (!selectedCity.id) return;
    fetchAddresses();
  }, [selectedCity]);

  const fetchAddresses = async () => {
    try {
      const cityAux = cities
        .filter((c) => c.name === selectedCity.name)
        .map((c) => c.id);
      const response = await axios.Get(
        "./address?cities=" + JSON.stringify(cityAux)
      );
      const data = response.data.map((a: any) => {
        a.name = a.address1;
        return a;
      });
      if (state.address) {
        const firmAddres = data.find((a: any) => a.id === state.address);
        setSelectedAddress(firmAddres);
      }
      if (data != null) {
        const newAddresses = data.filter((item: any) => item.city === selectedCity.id);
        setAddresses(newAddresses);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const uniqueRegions = new Set();
    groupCountry.forEach((countryInGroup) => {
      const completeCountry = countries.find(
        (countryExample) => countryExample.name === countryInGroup
      );

      if (completeCountry) {
        completeCountry.regions.forEach((region: any) => {
          uniqueRegions.add(region.id);
        });
      }
    });

    const regionsObject = Array.from(uniqueRegions).map((region) => ({
      id: region,
    }));
    setGroupRegions(regionsObject);
  }, [groupCountry]);

  useEffect(() => {
    if (!selectedJurisdictionGroup) return
    const countryArray = groupJurisdictions.map(
      (jurisdiction: any) => jurisdiction.country
    );
    setGroupCountry(countryArray);
  }, [groupJurisdictions]);

  useEffect(() => {
    setGroupRegions([]);
    setGroupCountry([]);
    const completeJurisdictionGroup: any = jurisdictions.filter(
      (jurisdiction: any) =>
        jurisdiction.jurisdictionGroupId === selectedJurisdictionGroup?.id
    );
    setGroupJurisdictions(completeJurisdictionGroup);
  }, [selectedJurisdictionGroup]);

  useEffect(() => {
    if (selectedMembershipType === "Primary") {
      if (selectedServiceType !== "Other") {
        let filteredFirms: any[] = [];

        filteredFirms = firms.filter(
          (firm: any) => firm.serviceType === selectedServiceType
        );
        let filteredJurisdictionsForServiceType: any[] = [];
        const usedJurisdictionIds = filteredFirms.map(
          (firm: any) => firm.jurisdictionId
        );
        filteredJurisdictionsForServiceType = jurisdictions.filter(
          (jurisdiction: any) => !usedJurisdictionIds.includes(jurisdiction.id)
        );
        setFilteredJurisdictions(filteredJurisdictionsForServiceType);
      } else {
        setFilteredJurisdictions(jurisdictions);
      }
    }
  }, [selectedServiceType, selectedMembershipType]);

  const isSomeError = (activeErrors: ActiveErrors): boolean => {
    for (const key in activeErrors) {
      if (activeErrors[key as keyof ActiveErrors] === true) {
        return true;
      }
    }
    return false;
  };

  const photoHasLoaded = async () => {
    setHasPhoto(true);
  };

  const handleSpinner = async () => {
    setSpinner(true);
  };

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/firm`, id);

    if (response.status === 200) {
      logActivity('DELETE_FIRM', location.pathname, JSON.stringify(state ? state : {}));
      navigate("/Staff/Catalogs/firm");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checked) setChecked(true);
      if (buttonClicked !== "saveOverride") {
        if (validateForm()) return;
      }

      if (state?.logo && state.logo === logo) {
        handleSave(logo as string);
      } else {
        try {
          await uploadImage(logo as File, firmId, "firm/logos/")
            .then((response) => {
              if (response) {
                setLogo(response as string);
                handleSave(response as string);
              } else {
                console.error("No response received from uploadImage.");
              }
            })
            .catch((error) => {
              console.error("Error during image upload:", error);
            })
        } catch (error) {
          // Handle unexpected errors
          console.error("Unexpected error during image upload:", error);
        }
      }
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDropDownCountry = (obj: any) => {
    setSelectedCountry({ id: obj.id, name: obj.name });
    setSelectedState({ id: "", name: "" });
    setSelectedCity({ id: "", name: "" });
    setSelectedAddress({ id: "", name: "" });
  };
  const handleDropDownState = (obj: any) => {
    setSelectedState({ id: obj.id, name: obj.name });
    setSelectedCity({ id: "", name: "" });
    setSelectedAddress({ id: "", name: "" });
  };
  const handleDropDownCity = (obj: any) => {
    setSelectedCity({ id: obj.id, name: obj.name });
    setSelectedAddress({ id: "", name: "" });
  };
  const handleDropDownAddress = (obj: any) => {
    setSelectedAddress({ id: obj.id, name: obj.name });
  };

  const handleCheckBoxChange = (name: string, checked: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleCheckBoxHasSecondary = (checked: boolean) => {
    handleCheckBoxChange("hasSecondary", checked);
  };

  useEffect(() => {
    if (checked) {
      validateForm();
    }
  }, [
    formData,
    logo,
    smallLogo,
    directPhoneCode,
    selectedJurisdiction,
    selectedMembershipStatus,
    selectedServiceType,
    selectedSecondaryJurisdiction,
    selectedMembershipType,
    selectedServiceType,
    selectedJurisdictionGroup,
    selectedSecondaryJurisdiction,
    selectedCountry,
    selectedState,
    selectedCity,
    selectedAddress,
  ]);

  const validateForm = () => {
    let newActiveErrors;
    if (isMember) {
      const jurisdictionGroupInput =
        (selectedMembershipType === "Regional" ||
          selectedMembershipType === "Multiple") &&
        selectedJurisdictionGroup?.name === "" &&
        selectedServiceType !== "";
      const jurisdictionInput =
        selectedJurisdiction.name === "" &&
        selectedMembershipType === "Primary" &&
        selectedServiceType !== "";
      const nameInput = formData.name === "";
      const firmNameInput = formData.firmName === "";
      const serviceTypeInput = selectedServiceType === "";
      const membershipTypeInput = selectedMembershipType === "";
      const otherServiceTypeInput =
        selectedServiceType === "Other" && formData.otherServiceType === "";
      const phoneNumberInput = directPhoneCode.phone === "";
      const websiteUrlInput = formData.websiteUrl === "";
      const secondaryJurisdictionInput =
        selectedSecondaryJurisdiction.length === 0 &&
        formData.hasSecondary === true;

      newActiveErrors = {
        inputName: nameInput,
        inputFirmName: firmNameInput,
        inputServiceType: serviceTypeInput,
        inputMembershipType: membershipTypeInput,
        inputJurisdiction: jurisdictionInput,
        inputJurisdictionGroup: jurisdictionGroupInput,
        inputOtherServiceType: otherServiceTypeInput,
        inputPhoneNumber: phoneNumberInput,
        inputWebsiteUrl: websiteUrlInput,
        inputAcceptedEmailDomain: formData.acceptedEmailDomain.length <= 0,
        inputSecondaryJurisdiction: secondaryJurisdictionInput,
        country: selectedCountry?.id === "",
        state: selectedState?.id === "",
        city: selectedCity.id === "",
        address: selectedAddress.id === "",
        logo: logo === null,
        smallLogo: smallLogo === null,
        firmOverview: formData.firmOverview === "",
        notable1:
          !!(notableImage1 && formData.notable1),
        notable2:
          !!(notableImage2 && formData.notable2),
        notable3:
          !!(notableImage3 && formData.notable3),
        notable4:
          !!(notableImage4 && formData.notable4),
      };
    } else {
      const nameInput = formData.name === "";
      const firmNameInput = formData.firmName === "";

      newActiveErrors = {
        inputName: nameInput,
        inputFirmName: firmNameInput,
        inputServiceType: false,
        inputMembershipType: false,
        inputJurisdiction: false,
        inputJurisdictionGroup: false,
        inputOtherServiceType: false,
        inputPhoneNumber: false,
        inputWebsiteUrl: false,
        inputAcceptedEmailDomain: false,
        inputSecondaryJurisdiction: false,
        country: selectedCountry?.id === "",
        state: false,
        city: false,
        address: false,
        notable1: false,
        notable2: false,
        notable3: false,
        notable4: false,
        logo: logo === null,
        smallLogo: false,
        firmOverview: false,
      };
    }
    setActiveErrors(newActiveErrors);
    return isSomeError(newActiveErrors);
  };

  const handleDropDownMembershipType = (name: string) => {
    setSelectedMembershipType(name);
    if (name === "Primary") {
      setContactStructure("firmWide");
    }
    setSelectedJurisdiction({ id: "", name: "" });
    setSelectedJurisdictionGroup({ id: "", name: "" });
  };

  const handleDropDownMembershipStatus = (name: string) => {
    setSelectedMembershipStatus(name);
  };

  const handleDropDownServiceType = (name: string) => {
    setSelectedServiceType(name);
    if (name !== "Other") {
      formData.otherServiceType = "";
    }
  };

  const handleDropDownChangeJurisdictionGroup = (obj: any) => {
    const newJurisdictionGroupData = jurisdictionGroups.map(
      (jurisdictionGroup) =>
        jurisdictionGroup.name === obj.name
          ? { ...jurisdictionGroup, checked: !jurisdictionGroup.checked }
          : jurisdictionGroup
    );
    setSelectedJurisdictionGroup({ id: obj.id, name: obj.name });
  };

  const handleDropDownChangeJurisdiction = (obj: any) => {
    const newJurisdictionGroupData = jurisdictionGroups.map(
      (jurisdictionGroup) =>
        jurisdictionGroup.name === obj.name
          ? { ...jurisdictionGroup, checked: !jurisdictionGroup.checked }
          : jurisdictionGroup
    );
    setSelectedJurisdiction({ id: obj.id, name: obj.name });
  };

  const handleChangeListingCategory = (id: number, value: any) => {
    setListingCategory(listingCategory.splice(id, 0, value.target.value));
  };

  const deleteEmailDomain = (name: string) => {
    setAcceptedEmailDomain(
      acceptedEmailDomain.filter((email) => email != name)
    );
  };

  const handleSave = async (logo: string) => {
    try {
      const firm = buildFirmObject(logo);
      let response
      if (props.mode === "add") response = await saveFirm(firm);
      else response = await updateFirm({...firm, id: state.id});
      
      if (response) {
        refetchFirms()
        const responseId = response.data.id
        const postSaveSuccessful = await handlePost(responseId);
        if (postSaveSuccessful) {
          if (!!state?.contentRedirectUrl) {
            logActivity(`${props.mode === 'add' ? 'CREATE_FIRM' : 'UPDATE_FIRM'}`, location.pathname, JSON.stringify(response.data));

            let updatedContent = { ...currentContent?.content };
            updatedContent.nonMemberFirms = currentContent?.content?.nonMemberFirms.concat(responseId);
            dispatch(
              contentsSlice.actions.ModifyContent({
                ...currentContent,
                content: updatedContent,
              })
            );
            
            navigateAfterDelay(state.contentRedirectUrl, 500);
          } else {
            navigateAfterDelay("/Staff/Catalogs/Firm", 500);
          }
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const buildFirmObject = (logo?: string) => {
    const commonFields = {
      isMember,
      name: formData.name,
      firmName: formData.firmName,
      shortId: formData.shortId,
      serviceType: selectedServiceType,
      linkedinUrl: formData.linkedinUrl,
      xUrl: formData.xUrl,
      instagramUrl: formData.instagramUrl,
      facebookUrl: formData.facebookUrl,
      youtubeUrl: formData.youtubeUrl,
      otherSocialMedia: formData.otherSocialMedia,
      logo:logo
    };

    return isMember
      ? {
          ...commonFields,
          firmName: formData.firmName,
          shortId: formData.shortId,
          serviceType: selectedServiceType,
          otherServiceType: formData.otherServiceType,
          membershipStatus: selectedMembershipStatus,
          membershipType: selectedMembershipType,
          jurisdiction: selectedJurisdiction.id,
          jurisdictionGroup: selectedJurisdictionGroup?.id,
          hasSecondary: formData.hasSecondary,
          secondaryJurisdiction: selectedSecondaryJurisdiction.map((j) => j.id),
          phoneNumber: directPhoneCode,
          websiteUrl: formData.websiteUrl,
          acceptedEmailDomain: formData.acceptedEmailDomain,
          firmOverview: formData.firmOverview,
          professionalsInPrimary: formData.professionalsInPrimary,
          professionalsWorldWide: formData.professionalsWorldWide,
          totalProfessionals: formData.totalProfessionals,
          dateCreated: formData.dateCreated,
          dateWithdrawn: formData.dateWithdrawn,
          city: selectedCity.id,
          address: selectedAddress.id,
          smallLogo: smallLogo,
        }
      : {
          ...commonFields,
          country: selectedCountry?.id,
          state: selectedState?.id,
          phoneNumber: false,
        };
  };

  const updateFirm = async (firm: any) => {
    try {
      const response = await axios.Put("/Firm", firm);
      if (response.status !== 200 || response.data.error) {
        handleSaveErrors(response);
        return null;
      }
      return response;
    } catch (error: any) {
      console.error("Error updating firm:", error);
      return null;
    }
  };

  const saveFirm = async (firm: any) => {
    try {
      const response = await axios.Post("/Firm", firm);
      if (response.status !== 200 || response.data.error) {
        handleSaveErrors(response);
        return null;
      }
      return response;
    } catch (error: any) {
      console.error("Error saving firm:", error);
      return null;
    }
  };

  const handleSaveErrors = (response: any) => {
    if (response.data.error) {
      const hasDuplicateName = response.data.error[0].includes("shortId");
      if (hasDuplicateName) {
        setActiveErrorsMessage((prevState) => ({
          ...prevState,
          inputShortId: "This Number ID is duplicated.",
        }));
        setActiveErrors((prevState) => ({
          ...prevState,
          inputShortId: true,
        }));
      }
    }
  };

  const handlePost = async (firmId: string): Promise<boolean> => {
    try {
      const numberOfJurisdictionsInJG = jurisdictions.filter(
        (jurisdiction: any) =>
          jurisdiction.partOfMulti === true &&
          jurisdiction.jurisdictionGroup === selectedJurisdictionGroup?.id
      );

      const jurisdictionIds = numberOfJurisdictionsInJG.map((j) => j.id);

      const jurisdictionsWithEmptyContacts = jurisdictionIds.map((jId) => ({
        jurisdiction: jId,
        contacts: [],
      }));

      if (props.mode === "add") {
        await Promise.all([
          axios.Post("/FirmContact", {
            firm: firmId,
            level: selectedMembershipType === "Primary",
            contacts: [],
            jurisdictions: jurisdictionsWithEmptyContacts,
          }),
          axios.Post("/OfficeContact", {
            firm: firmId,
            createdAt: new Date(),
          }),
        ]);
      } else {
        await Promise.all([
          axios.Put("/FirmContact", {
            firm: firmId,
            level: selectedMembershipType === "Primary",
            contacts: [],
            jurisdictions: jurisdictionsWithEmptyContacts,
          }),
          axios.Put("/OfficeContact", {
            firm: firmId,
            createdAt: new Date(),
          }),
        ]);
      }
      return true;
    } catch (error: any) {
      console.error("Error saving contact information", error);
      return false;
    }
  };
  
  const navigateAfterDelay = (path: string, delay: number, props:any = {}) => {
    setTimeout(() => {
      navigate(path, props);
    }, delay);
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  useEffect(() => {
    const errorsToSet = Object.keys(activeErrors).filter(
      (clave) => activeErrors[clave as keyof typeof activeErrors]
    );
    setErrorFields(errorsToSet);
    setSaveOverrideOpen(errorsToSet.length > 0);

    setCanSave(!isSomeError(activeErrors));
  }, [activeErrors]);

  const { viewportWidth } = useViewport();

  return (
    <>
      {/* AQUI EMPIEZA EL MODAL */}
      {isModalOpen && (
        <div className="modal w-full h-screen fixed flex justify-center items-center z-20">
          <div
            className="w-full h-full absolute bg-black opacity-80"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className="flex flex-col justify-center items-center text-3xl text-white z-30 relative">
            <div className="modal-content bg-white p-[1.5rem] lg:w-[40rem] w-full max-h-[70vh] overflow-y-auto">
              <div className="flex items-center justify-between pb-[1.5rem]">
                <h1 className="text-primary text-left text-[2rem] font-bold">
                  Upload Profile Image
                </h1>
                <div
                  className="w-max cursor-pointer"
                  onClick={() => setIsModalOpen(false)}
                >
                  <CloseModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*  */}
      <main
        className={`mainSection ${saveOverrideOpen ? "pt-7 md:pt-9 " : ""}`}
      >
        <form onSubmit={handleSubmit}>
          <SaveOverride
            fields={errorFields}
            handleButtonClick={handleButtonClick}
            open={saveOverrideOpen}
            close={handleCloseSaveOverride}
          />
          <CatalogTitle
            title={`${
              props.mode === "add"
                ? "New Firm"
                : "Edit " + (state ? state[0]?.name || "Edit none" : "Loading...")
            }`}
            className="text-Default"
          >
            <GoBack />
          </CatalogTitle>
          <article className="articleSection">
            {!isMember && (
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Primary Information"
                  className="text-accent-Default-dark"
                />
                <div className="flex flex-col w-full formsection">
                  <div className="flex flex-col md:flex-row formsection w-full">
                    <span className="text-[1rem] lg:text-[1.375rem] lg:font-bold leading-[1.125rem] lg:leading-6 not-italic text-gray-font">
                      {" "}
                      Firm Type:{" "}
                    </span>
                    <div className="flex flex-row formsection">
                      <RadioButton
                        index={0}
                        text={"Member"}
                        selected={isMember}
                        handleRadioButtonChange={() => setIsMember(true)}
                        name="typeSelection"
                        disabled={isContentRedirect}
                      />
                      <RadioButton
                        index={1}
                        text={"Non-Member"}
                        selected={!isMember}
                        handleRadioButtonChange={() => setIsMember(false)}
                        name="typeSelection"
                      />
                    </div>
                  </div>
                  <div className="flex w-full formsection md:pb-5 flex-wrap lg:flex-nowrap">
                    <div className="flex flex-col md:flex-row w-full formsection lg:w-4/5">
                      <div className="flex flex-col w-full md:w-1/2 lg:w-1/2">
                        <Input
                          title="Firm Legal Name"
                          name="firmName"
                          errorMsj={activeErrorsMessage.inputFirmName}
                          error={activeErrors.inputFirmName}
                          value={formData?.firmName}
                          onChange={handleInputChange}
                          required={!isMember}
                        />
                      </div>
                      <div className="flex flex-col w-full md:w-1/2 lg:w-1/2 ">
                        <Input
                          title="Name"
                          name="name"
                          errorMsj={activeErrorsMessage.inputName}
                          error={activeErrors.inputName}
                          value={formData?.name}
                          onChange={handleInputChange}
                          required={!isMember}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full formsection lg:w-1/5 ">
                      <div className="flex flex-col w-full md:w-1/2 lg:w-full">
                        <Input
                          title="Number ID"
                          name="shortId"
                          errorMsj={""}
                          error={false}
                          value={formData?.shortId}
                          required={false}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row formsection w-full">
                    <div
                      className={`w-full  md:w-[8rem] lg:mr-4 lg:w-40 xl:w-[15rem] `}
                    >
                      <InputImage
                        required={!isMember}
                        setImage={setLogo}
                        photo={logo as string}
                        maxsize={25}
                        width={300}
                        height={300}
                        title="Logo"
                        error={activeErrors.logo}
                        errorMsg={activeErrorsMessage.logo}
                      />
                    </div>
                    <div className="grid formsection w-full grid-cols-6 lg:h-[50%]">
                        <div className="flex flex-col w-full col-span-6 lg:col-span-2">
                          <DropDownList
                            title="ServiceType"
                            selectedObjst={selectedServiceType}
                            seter={handleDropDownServiceType}
                            data={[
                              { id: "Legal", name: "Legal" },
                              { id: "Accounting", name: "Accounting" },
                              {
                                id: "InvestmentBanking",
                                name: "Investment Banking",
                              },
                              { id: "Other", name: "Other" },
                            ]}
                            error={activeErrors.inputServiceType}
                            errormsj={activeErrorsMessage.inputServiceType}
                            required={isMember}
                          />
                        </div>
                        <div className="flex flex-col w-full col-span-6 lg:col-span-2">
                          <DropDownList
                            title="Country"
                            selectedObjst={selectedCountry?.name}
                            seter={handleDropDownCountry}
                            data={countries}
                            error={activeErrors.country}
                            errormsj={activeErrorsMessage.country}
                            required={!isMember}
                            getid
                          />
                        </div>
                        <div className="flex flex-col w-full col-span-6 lg:col-span-2">
                          <DropDownList
                            title="State"
                            disabled={selectedCountry?.id === ""}
                            selectedObjst={selectedState?.name}
                            seter={handleDropDownState}
                            data={states}
                            error={activeErrors.state}
                            errormsj={activeErrorsMessage.state}
                            getid
                          />
                        </div>
                        <div className="lg:col-span-3 col-span-6">
                        <PhoneNumber
                          title={"Phone Number"}
                          Obj={directPhoneCode}
                          seter={setDirectPhoneCode}
                          error={activeErrors.inputPhoneNumber}
                          errormsj={activeErrorsMessage.inputPhoneNumber}
                          required={isMember}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {isMember && (
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Firm Primary Information"
                  className="text-accent-Default-dark"
                />
                <div className=" w-full flex flex-col formsection">
                  <div className="flex flex-col md:flex-row formsection">
                    <span className="text-[1rem] lg:text-[1.375rem] lg:font-bold leading-[1.125rem] lg:leading-6 not-italic text-gray-font ">
                      {" "}
                      Firm Type:{" "}
                    </span>
                    <div className="flex flex-row formsection">
                      <RadioButton
                        index={0}
                        text={"Member"}
                        selected={isMember}
                        handleRadioButtonChange={() => setIsMember(true)}
                        name="typeSelection"
                      />
                      <RadioButton
                        index={1}
                        text={"Non-Member"}
                        selected={!isMember}
                        handleRadioButtonChange={() => setIsMember(false)}
                        name="typeSelection"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-12 formsection">
                    <div className="flex flex-col w-full col-span-12 md:col-span-6 lg:col-span-5">
                      <Input
                        title="Firm Legal Name"
                        name="firmName"
                        errorMsj={activeErrorsMessage.inputFirmName}
                        error={activeErrors.inputFirmName}
                        value={formData?.firmName}
                        onChange={handleInputChange}
                        required={isMember}
                      />
                    </div>
                    <div className="flex flex-col w-full col-span-12 md:col-span-6 lg:col-span-5 ">
                      <Input
                        title="Name"
                        name="name"
                        errorMsj={activeErrorsMessage.inputName}
                        error={activeErrors.inputName}
                        value={formData?.name}
                        onChange={handleInputChange}
                        required={isMember}
                      />
                    </div>
                    <div className="flex flex-col w-full col-span-12 md:col-span-6 lg:col-span-2">
                      <Input
                        title="Number ID"
                        name="shortId"
                        errorMsj={""}
                        error={false}
                        value={formData?.shortId}
                        required={isMember}
                        disabled
                      />
                    </div>

                    <div className="flex flex-col col-span-12 md:col-span-6 lg:col-span-4">
                      <DropDownList
                        title="ServiceType"
                        selectedObjst={selectedServiceType}
                        seter={handleDropDownServiceType}
                        data={[
                          { id: "Legal", name: "Legal" },
                          { id: "Accounting", name: "Accounting" },
                          {
                            id: "InvestmentBanking",
                            name: "Investment Banking",
                          },
                          { id: "Other", name: "Other" },
                        ]}
                        error={activeErrors.inputServiceType}
                        errormsj={activeErrorsMessage.inputServiceType}
                        required={isMember}
                      />
                    </div>
                    <div className="flex flex-col col-span-12 md:col-span-6 lg:col-span-4">
                      <Input
                        title="Other Service Type"
                        name="otherServiceType"
                        required={isMember && selectedServiceType === "Other"}
                        disabled={selectedServiceType !== "Other"}
                        errorMsj={activeErrorsMessage.inputOtherServiceType}
                        error={activeErrors.inputOtherServiceType}
                        value={formData?.otherServiceType}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-col col-span-12 md:col-span-6 lg:col-span-4">
                      <DropDownList
                        title="Membership Status"
                        selectedObjst={selectedMembershipStatus}
                        seter={handleDropDownMembershipStatus}
                        data={[{ id: "Onboarding", name: "Onboarding" }]}
                        error={false}
                        errormsj={activeErrorsMessage.inputName}
                        disabled={true}
                        required={isMember}
                      />
                    </div>

                    <div className="flex flex-col col-span-12 md:col-span-6 lg:col-span-4">
                      <DropDownList
                        title="Membership Type"
                        selectedObjst={selectedMembershipType}
                        seter={handleDropDownMembershipType}
                        data={[
                          { id: "Primary", name: "Primary" },
                          { id: "Regional", name: "Regional" },
                          { id: "Multiple", name: "Multiple Primary" },
                        ]}
                        error={activeErrors.inputMembershipType}
                        errormsj={activeErrorsMessage.inputMembershipType}
                        required={true}
                      />
                    </div>
                    <div className="flex flex-col col-span-12 md:col-span-6 lg:col-span-4">
                      <DropDownList
                        title="Jurisdiction"
                        disabled={
                          selectedMembershipType !== "Primary" ||
                          selectedServiceType === ""
                        }
                        selectedObjst={selectedJurisdiction?.name}
                        seter={handleDropDownChangeJurisdiction}
                        data={
                          filteredJurisdictions
                            ? filteredJurisdictions
                            : jurisdictions
                        }
                        error={activeErrors.inputJurisdiction}
                        errormsj={activeErrorsMessage.inputJurisdiction}
                        required={
                          selectedMembershipType === "Primary" &&
                          selectedServiceType !== ""
                        }
                        tooltip="Please select a Membership Type."
                        getid
                      />
                    </div>
                    <div className="flex flex-col col-span-12 md:col-span-6 lg:col-span-4">
                      <DropDownList
                        title="Jurisdiction Group"
                        disabled={
                          selectedMembershipType === "" ||
                          selectedServiceType === ""
                        }
                        selectedObjst={selectedJurisdictionGroup?.name}
                        seter={handleDropDownChangeJurisdictionGroup}
                        data={jurisdictionGroups}
                        error={activeErrors.inputJurisdictionGroup}
                        errormsj={activeErrorsMessage.inputJurisdictionGroup}
                        required={
                          (selectedMembershipType === "Regional" ||
                            selectedMembershipType === "Multiple Primary") &&
                          selectedServiceType !== ""
                        }
                        getid
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
            {isMember && (
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Firm Legal Address"
                  className="text-accent-Default-dark"
                />
                <div className="grid formsection w-full lg:grid-cols-4 md:grid-cols-2">
                  <div className="flex flex-col w-full md:1/2">
                    <DropDownList
                      title="Country"
                      selectedObjst={selectedCountry?.name}
                      seter={handleDropDownCountry}
                      data={countries}
                      error={activeErrors.country}
                      errormsj={activeErrorsMessage.country}
                      required={isMember}
                      getid
                    />
                  </div>
                  <div className="flex flex-col w-full md:1/2">
                    <DropDownList
                      title="State"
                      disabled={selectedCountry?.id === ""}
                      selectedObjst={selectedState?.name}
                      seter={handleDropDownState}
                      data={states}
                      error={activeErrors.state}
                      errormsj={activeErrorsMessage.state}
                      required={selectedCountry?.id !== ""}
                      tooltip="Please select a Country."
                      getid
                    />
                  </div>
                  <div className="flex flex-col w-full md:1/2">
                    <DropDownList
                      title="City"
                      disabled={
                        selectedCountry?.id === "" || selectedState?.id === ""
                      }
                      selectedObjst={selectedCity.name}
                      seter={handleDropDownCity}
                      data={cities}
                      error={activeErrors.city}
                      errormsj={activeErrorsMessage.city}
                      required={
                        selectedCountry?.id !== "" && selectedState?.id !== ""
                      }
                      tooltip="Please select a State."
                      getid
                    />
                  </div>
                  <div className="flex flex-col w-full md:1/2">
                    <DropDownList
                      title="Address"
                      disabled={
                        selectedCountry?.id === "" ||
                        selectedState?.id === "" ||
                        selectedCity.id === ""
                      }
                      selectedObjst={selectedAddress.name}
                      seter={handleDropDownAddress}
                      data={addresses}
                      error={activeErrors.address}
                      errormsj={activeErrorsMessage.address}
                      required={
                        selectedCountry?.id !== "" &&
                        selectedState?.id !== "" &&
                        selectedCity.id !== ""
                      }
                      tooltip="Please select a City."
                      getid
                    />
                  </div>
                </div>
              </section>
            )}
            {isMember && (
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Firm Additional Information"
                  className="text-accent-Default-dark"
                />
                <div className="w-full flex flex-col formsection">
                  <div className="flex formsection w-full flex-col lg:flex-row">
                    <div className="flex formsection items-center md:justify-around w-full flex-col md:flex-row">
                      <div
                        className={`w-full  md:w-[8rem] lg:mr-4 lg:w-40 xl:w-[15rem] `}
                      >
                        <InputImage
                          required={isMember}
                          setImage={setLogo}
                          photo={logo as string}
                          maxsize={25}
                          width={300}
                          height={300}
                          title="Logo"
                          error={activeErrors.logo}
                          errorMsg={activeErrorsMessage.logo}
                        />
                      </div>

                      <div
                        className={`w-full md:w-[8rem] lg:mr-4 lg:w-40 xl:w-[15rem]`}
                      >
                        <InputImage
                          required={isMember}
                          setImage={setSmallLogo}
                          photo={smallLogo as string}
                          maxsize={25}
                          width={300}
                          height={300}
                          title="Small Logo"
                          error={activeErrors.smallLogo}
                          errorMsg={activeErrorsMessage.smallLogo}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-2 formsection w-full">
                      <div>
                        <PhoneNumber
                          title={"Phone Number"}
                          Obj={directPhoneCode}
                          seter={setDirectPhoneCode}
                          error={activeErrors.inputPhoneNumber}
                          errormsj={activeErrorsMessage.inputPhoneNumber}
                          required={isMember}
                        />
                      </div>
                      <div>
                        <Input
                          title="Website Url"
                          name="websiteUrl"
                          errorMsj={activeErrorsMessage.inputWebsiteUrl}
                          error={activeErrors.inputWebsiteUrl}
                          value={formData?.websiteUrl}
                          onChange={handleInputChange}
                          required={isMember}
                        />
                      </div>
                      <div className="flex formsection flex-col md:col-span-2 lg:col-span-1">
                        <div className="flex formsection">
                          <Input
                            title="Accepted Email Domain"
                            name="acceptedEmailDomain"
                            errorMsj={
                              activeErrorsMessage.inputAcceptedEmailDomain
                            }
                            error={activeErrors.inputAcceptedEmailDomain}
                            value={formData?.acceptedEmailDomain}
                            onChange={handleInputChange}
                            required={
                              isMember && acceptedEmailDomain.length <= 0
                            }
                          />

                          {viewportWidth > 768 && (
                            <div className="flex flex-col gap-2.5">
                              <span className="h-[1.2rem]"></span>
                              <div className="flex h-[3rem] ">
                                <NewButton
                                  text="Add"
                                  color="accent"
                                  onClick={() => {
                                    setAcceptedEmailDomain([
                                      ...acceptedEmailDomain,
                                      formData.acceptedEmailDomain,
                                    ]);
                                    setFormData((prevState) => ({
                                      ...prevState,
                                      acceptedEmailDomain: "",
                                    }));
                                  }}
                                  content="textIcon"
                                  size="small"
                                  style="filled"
                                  icon={<Plus2 />}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {acceptedEmailDomain.map((domain) => {
                          return (
                            <div className="flex formsection items-end">
                              <Input
                                title=""
                                name="acceptedEmailDomain"
                                error={false}
                                value={domain}
                              />
                              <div className="flex h-[3rem]">
                                <NewButton
                                  text={viewportWidth > 768 ? "Remove" : ""}
                                  color="delete"
                                  onClick={() => deleteEmailDomain(domain)}
                                  content={
                                    viewportWidth > 768
                                      ? "textIcon"
                                      : "iconSquare"
                                  }
                                  size="large"
                                  style="outlined"
                                  icon={<Remove />}
                                />
                              </div>
                            </div>
                          );
                        })}
                        {viewportWidth < 768 && (
                          <div className="flex  w-full">
                            <NewButton
                              text="Add"
                              color="accent"
                              onClick={() => {
                                setAcceptedEmailDomain([
                                  ...acceptedEmailDomain,
                                  formData.acceptedEmailDomain,
                                ]);
                                setFormData((prevState) => ({
                                  ...prevState,
                                  acceptedEmailDomain: "",
                                }));
                              }}
                              content="textIcon"
                              size="large"
                              style="filled"
                              icon={<Plus2 />}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <TextArea
                      title="Firm Overview"
                      name="firmOverview"
                      error={activeErrors.firmOverview}
                      errorMsj={activeErrorsMessage.firmOverview}
                      value={formData.firmOverview}
                      onChange={handleInputChange}
                      maxChars={3500}
                      characterCount={true}
                      required={isMember}
                    />
                  </div>

                  <div className="flex flex-col lg:flex-row formsection">
                    <span className="text-[1.125rem] leading-5 lg:leading-8 text-4 lg:text-[1.125rem]  not-italic text-accentDefault">
                      Firm Contact Structure:
                    </span>
                    <div className="flex flex-col md:flex-row formsection">
                      <RadioButton
                        index={0}
                        text={"Contacts defined Firm Wide"}
                        selected={contactStructure === "firmWide"}
                        handleRadioButtonChange={() =>
                          setContactStructure("firmWide")
                        }
                        name="typeSelection"
                        disabled={selectedMembershipType === "Primary"}
                      />
                      <RadioButton
                        index={1}
                        text={"Contacts defined by Membership Locations"}
                        selected={contactStructure === "membershipLocation"}
                        handleRadioButtonChange={() =>
                          setContactStructure("membershipLocation")
                        }
                        name="typeSelection"
                        disabled={selectedMembershipType === "Primary"}
                      />
                    </div>
                  </div>
                  <div className=" formsection">
                    <div className="pb-[.625rem]">
                      <span className="leading-[1.125rem] lg:leading-5 text-gray-font text-[1.125rem]">
                        Listing Category
                      </span>
                    </div>
                    <p className="text-gray-font leading-6 lg:leading-8">
                      Professionals representing your firm will be displayed
                      with 4 distinct titles in your firm's profile. Default
                      labels are "Partners", "Associates", "Of Counsel" and
                      "Other". To better fit the structure of your firm. Fill in
                      the desired titles in the boxes below which will be
                      available when "Other" is selected.
                    </p>
                    <div className="grid grid-cols-1  grid-rows-2 md:grid-cols-2 formsection">
                      <TextArea
                        title=""
                        maxChars={50}
                        height="h-[50px]"
                        characterCount={true}
                        name="listingCategory1"
                        error={false}
                        value={listingCategory[0]}
                        onChange={(value) =>
                          handleChangeListingCategory(0, value)
                        }
                        required={false}
                      />
                      <TextArea
                        title=""
                        maxChars={50}
                        height="h-[50px]"
                        characterCount={true}
                        name="listingCategory2"
                        error={false}
                        value={listingCategory[1]}
                        onChange={(value) =>
                          handleChangeListingCategory(1, value)
                        }
                        required={false}
                      />
                      <TextArea
                        title=""
                        maxChars={50}
                        height="h-[50px]"
                        characterCount={true}
                        name="listingCategory3"
                        error={false}
                        value={listingCategory[2]}
                        onChange={(value) =>
                          handleChangeListingCategory(2, value)
                        }
                        required={false}
                      />
                      <TextArea
                        title=""
                        maxChars={50}
                        height="h-[50px]"
                        characterCount={true}
                        name="listingCategory4"
                        error={false}
                        value={listingCategory[3]}
                        onChange={(value) =>
                          handleChangeListingCategory(3, value)
                        }
                        required={false}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
            {isMember && (
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Notables"
                  className="text-accent-Default-dark"
                />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  w-full formsection flex-wrap md:flex-nowrap">
                  <div className="flex flex-col formsection w-full ">
                    <InputImageMinimal
                      setImage={setNotableImage1}
                      photo={notableImage1 as string}
                      title="Notable Image"
                    />

                    <Input
                      title="Notable URL"
                      name="notable1"
                      errorMsj={activeErrorsMessage.notable}
                      error={activeErrors.notable1}
                      value={formData?.notable1}
                      onChange={handleInputChange}
                      required={!!notableImage1}
                    />
                  </div>
                  <div className=" flex flex-col formsection w-full ">
                    <InputImageMinimal
                      setImage={setNotableImage2}
                      photo={notableImage2 as string}
                      title="Notable Image"
                    />

                    <Input
                      title="Notable URL"
                      name="notable2"
                      errorMsj={activeErrorsMessage.notable}
                      error={activeErrors.notable2}
                      value={formData?.notable2}
                      onChange={handleInputChange}
                      required={!!notableImage2}
                    />
                  </div>
                  <div className=" flex flex-col formsection w-full ">
                    <InputImageMinimal
                      setImage={setNotableImage3}
                      photo={notableImage3 as string}
                      title="Notable Image"
                    />
                    <Input
                      title="Notable URL"
                      name="notable3"
                      errorMsj={activeErrorsMessage.notable}
                      error={activeErrors.notable3}
                      value={formData?.notable3}
                      onChange={handleInputChange}
                      required={!!notableImage3}
                    />
                  </div>
                  <div className=" flex flex-col formsection w-full ">
                    <InputImageMinimal
                      setImage={setNotableImage4}
                      photo={notableImage4 as string}
                      title="Notable Image"
                    />
                    <Input
                      title="Notable URL"
                      name="notable4"
                      errorMsj={activeErrorsMessage.notable}
                      error={activeErrors.notable4}
                      value={formData?.notable4}
                      onChange={handleInputChange}
                      required={!!notableImage4}
                    />
                  </div>
                </div>
              </section>
            )}
            {isMember && (
              <section className="formsectionNTitle">
                <div className=" flex gap-2.5 w-full items-center">
                  <CatalogSubTitle
                    title="Firm Data"
                    className="text-accent-Default-dark"
                  />
                  <div className="flex items-center justify-center">
                    <NewTooltip text="This section reads data from the Database. It cannot be edited." />
                  </div>
                </div>
                <div className="flex flex-col formsection w-full">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 formsection">
                    <Input
                      title="Professionals in Primary"
                      name="professionalsInPrimary"
                      placeholder="0"
                      errorMsj={activeErrorsMessage.inputName}
                      error={false}
                      value={formData?.professionalsInPrimary}
                      onChange={handleInputChange}
                    />
                    <Input
                      title="Professionals WorldWide"
                      name="professionalsWorldWide"
                      placeholder="0"
                      errorMsj={activeErrorsMessage.inputName}
                      error={false}
                      value={formData?.professionalsWorldWide}
                      onChange={handleInputChange}
                    />
                    <Input
                      title="Total Professionals"
                      name="totalProfessionals"
                      placeholder="0"
                      errorMsj={activeErrorsMessage.inputName}
                      error={false}
                      value={formData?.totalProfessionals}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 formsection">
                    <Input
                      title="Created Date"
                      name="dateCreated"
                      disabled={true}
                      errorMsj={""}
                      error={false}
                      value={formData?.dateCreated}
                      onChange={handleInputChange}
                    />
                    <Input
                      title="Joined Date"
                      name="dateJoined"
                      disabled={true}
                      placeholder={"DD/MM/YYYY"}
                      errorMsj={""}
                      error={false}
                      value={formData?.dateJoined}
                      onChange={handleInputChange}
                    />

                    <Input
                      title="Withdrawn Date"
                      name="dateWithdrawn"
                      disabled={true}
                      placeholder={"DD/MM/YYYY"}
                      errorMsj={""}
                      error={false}
                      value={formData?.dateWithdrawn}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </section>
            )}
            {!isContentRedirect && <section className="formsectionNTitle">
              <CatalogSubTitle
                title="Social Media"
                className="text-accent-Default-dark"
              />
              <div className="w-full flex flex-col formsection">
                <div className="flex flex-col md:flex-row w-full formsection">
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/2">
                    <Input
                      title="LinkedIn Public Profile URL"
                      name="linkedinUrl"
                      errorMsj={""}
                      error={false}
                      value={formData?.linkedinUrl}
                      onChange={handleInputChange}
                      required={false}
                      placeholder="ex: https://www.linkedin.com/in/username"
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/2 ">
                    <Input
                      title="X URL"
                      name="xUrl"
                      errorMsj={""}
                      error={false}
                      value={formData?.xUrl}
                      onChange={handleInputChange}
                      required={false}
                      placeholder="ex: https://www.twitter.com/username"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full formsection">
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/2">
                    <Input
                      title="Facebook Profile URL"
                      name="facebookUrl"
                      errorMsj={""}
                      error={false}
                      value={formData?.facebookUrl}
                      onChange={handleInputChange}
                      required={false}
                      placeholder="ex: https://www.facebook.com/yourname"
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/2 ">
                    <Input
                      title="Instagram Address URL"
                      name="instagramUrl"
                      errorMsj={""}
                      error={false}
                      value={formData?.instagramUrl}
                      onChange={handleInputChange}
                      required={false}
                      placeholder="ex: https://www.instagram.com/username"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full formsection">
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/2">
                    <Input
                      title="Youtube Profile URL"
                      name="youtubeUrl"
                      errorMsj={""}
                      error={false}
                      value={formData?.youtubeUrl}
                      onChange={handleInputChange}
                      required={false}
                      placeholder="ex: https://www.youtube.com/watch?v=VIDEO_ID"
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 lg:w-1/2 ">
                    <Input
                      title="Other Social Media"
                      name="otherSocialMedia"
                      errorMsj={""}
                      error={false}
                      value={formData?.otherSocialMedia}
                      onChange={handleInputChange}
                      required={false}
                    />
                  </div>
                </div>
              </div>
            </section>}
            {isMember && (
              <section className="formsectionNTitle">
                <CatalogSubTitle
                  title="Secondary Jurisdictions"
                  className="text-accent-Default-dark"
                />
                <div className="flex formsection flex-col w-full md:flex-row">
                  <div className="flex flex-col items-center  gap-2 md:w-1/6">
                    <span className="inputLabel !self-center text-neutral-dark">
                      Has Secondary?
                    </span>
                    <SwitchToggle
                      centered={true}
                      seter={handleCheckBoxHasSecondary}
                      checked={formData.hasSecondary}
                    />
                  </div>
                  <div className="flex w-full flex-col gap-1">
                    <DDMultiple
                      title="Secondary Jurisdiction"
                      disabled={formData.hasSecondary === false}
                      selectedObjst={selectedSecondaryJurisdiction}
                      seter={setSelectedSecondaryJurisdiction}
                      objects={jurisdictions}
                      error={activeErrors.inputSecondaryJurisdiction}
                      errorMessage={
                        activeErrorsMessage.inputSecondaryJurisdiction
                      }
                      required={formData.hasSecondary === true}
                    />
                  </div>
                </div>
              </section>
            )}
            <ButtonsCrud
              Catalog="Firm"
              mode={props.mode}
              id={id ? id : ""}
              disabled={!canSave}
              actionButton={handleButtonClick}
              onDelete={handleDelete}
            />
          </article>
        </form>
      </main>
    </>
  );
};
export default AddFirm;
