
import react from 'react';
import { useAppContext } from '../../../Context';

type titleprops = {
    title: string;
}

 

const HeaderTitle: React.FC<titleprops> = ({ title }) => {
    const {mode} = useAppContext(); 

  return (
  <h3 className={`${mode === 'member'?' text-primary':' text-Default' } font-decimal text-[2rem] font-medium flex-2`}>{title}</h3>
  );
};

export default HeaderTitle;