// components/TableWithPagination.tsx
import React from 'react';
import PaginationComponent from '../../../Atomos/Pagination/PaginationComponent';
import TableV2 from '../../../Organismos/Table/TableTransitionToTanSack';

interface TableWithPaginationProps {
  data: any[];
  columns: any[];
  totalCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  recordsPerPage: number;
  setRecordsPerPage: (size: number) => void;
  setTableInstance: (instance: any) => void;
  sort: any;
  setSort: (sort: any) => void;
}

const TableWithPagination: React.FC<TableWithPaginationProps> = ({
  data,
  columns,
  totalCount,
  currentPage,
  setCurrentPage,
  recordsPerPage,
  setRecordsPerPage,
  setTableInstance,
  sort,
  setSort,
}) => {
  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setRecordsPerPage(pageSize);
  };

  return (
    <>
      <TableV2
        data={data}
        columnsDef={columns}
        setTableInstance={setTableInstance}
        sorting={sort}
        totalCount={totalCount}
        setSorting={setSort}
      />
    </>
  );
};

export default TableWithPagination;
