import { useLocation, useParams } from "react-router-dom"; 
import Axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import ButtonsCrud from "../../../components/Catalogs/ButtonsCrud";
import "../../../assets/css/AddCatalogs.css";
import Input from "../../../components/Atomos/Inputs/Input";
import SaveOverride from "../../../components/Catalogs/SaveOverride"; 
import { uploadImage } from "../../../utils/UploadImage";
import InputImage from "../../../components/Atomos/InputImage/InputImage";
import GoBack from "../../../components/Moleculas/GoBack";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Image from "../../../components/Atomos/Icons/Image";

interface DataItem {
  id: string;
  name?: string;
  shortName?: string;
  logo?: string;
}

interface iCatalogProps {
  mode: "add" | "edit";
}

type Errors = {
  name: { error: boolean; message: string };
  shortName: { error: boolean; message: string };
};

const AddWSG: React.FC<iCatalogProps> = (props) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const [regularPhoto, setRegularPhoto] = useState<String | File | null>(null);
  const { id } = useParams();
  const { state } = useLocation();
  const [errors, setErrors] = useState<Errors>({
    name: { error: false, message: "" },
    shortName: { error: false, message: "" },
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [region, setRegion] = useState<DataItem>({
    id: state?.id ?? "",
    name: state?.name ?? "",
    shortName: state?.shortName ?? "",
  });
  const [buttonClicked, setButtonClicked] = useState("");
  const [saveOverrideOpen, setSaveOverrideOpen] = useState(false);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (region.name?.trim() !== "") {
      setErrors({ ...errors, name: { error: false, message: "" } });
    }
    if (region.shortName?.trim() !== "") {
      setErrors({ ...errors, shortName: { error: false, message: "" } });
    }
  }, [region]);

  const validation = async () => {
    const name =
      region.name?.trim() === ""
        ? { error: true, message: "Name is required" }
        : { error: false, message: "" };
    const shortName =
      region.shortName?.trim() === ""
        ? { error: true, message: "Short Name is required" }
        : { error: false, message: "" };

    setErrors({ name: name, shortName: shortName });
    setError(name.error || shortName.error);
    return name.error || shortName.error;
  };

  const handleDelete = async (id: string) => {
    const response = await axios.Delete(`/WSG`, id);
    if (response.status === 200) {
      navigate("/Staff/Catalogs/WSG");
    } else {
      console.error("Error al eliminar el elemento:", response.data);
    }
  };

  const handleButtonClick = (buttonName: string) => {
    setButtonClicked(buttonName);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let WSGWithLogo : DataItem = {...region };

      await uploadImage(regularPhoto as File, region.id, "wsg/logos/")
        .then((response) => {
          if (response) {
            setRegion((prevRegion) => ({
              ...prevRegion,
              logo: response as string
            }));
            WSGWithLogo = { ...region, logo: response as string };
          } else {
            console.error("No response received from uploadImage.");
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error during image upload:", error);
        }).finally(() => {

        handleUpdate (e, WSGWithLogo);
        }
        );
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error during image upload:", error);
    }
  };


  
  useEffect(() => {
    console.log(errors.shortName.error || errors.name.error);
    if (errors.shortName.error || errors.name.error) setSaveOverrideOpen(true);
    else {
      setSaveOverrideOpen(false);
      if (error) setError(false);
    }
  }, [errors]);

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>, WSG:DataItem) => {
    let newerror = false;

    if (buttonClicked !== "saveOverride") {
      newerror = await validation();
    }

    if (region?.id && !newerror) {
      const updatedData: DataItem = WSG;
      try {
        const response = await axios.Put("WSG", updatedData);
        if (response.data.error) {
          return
        } else navigate("/Staff/Catalogs/WSG");
      } catch (error: any) {
        console.error("Error al actualizar el elemento:", error);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (error) validation();
    setRegion({ ...region, [e.target.name]: e.target.value });
  };

  const handleCloseSaveOverride = () => {
    setSaveOverrideOpen(!saveOverrideOpen);
  };

  useEffect(() => {
    const errorsToSet = Object.keys(errors).filter(
      (clave) => errors[clave as keyof typeof errors].error
    );
    console.log(errorsToSet);
    setErrorFields(errorsToSet);
  }, [errors]);

  return (
    <>
      <main className="mainSection">
        <form onSubmit={handleSubmit}>
          <div>
            <SaveOverride
              fields={errorFields}
              handleButtonClick={handleButtonClick}
              open={saveOverrideOpen}
              close={handleCloseSaveOverride}
            />
          </div> 
            <CatalogTitle title={`${ props.mode === "add" ? "New WSG" : "Edit WSG" }`}  className='text-Default'>
              <GoBack/>
            </CatalogTitle>

          <div className="articleSection">
            <div className="formsectionNTitle">
              <CatalogSubTitle title="WSG Information" className=" text-accent-Default-dark"/>
              <div className="formsection flex items-start w-full flex-col ">
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className=" w-full md:w-3/4">
                    <Input
                      name="name"
                      title="Name"
                      value={region.name}
                      required={true}
                      error={errors.name.error}
                      errorMsj={errors.name.message}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className=" w-full md:w-1/4">
                    <Input
                      name="shortName"
                      value={region.shortName}
                      title="Short Name"
                      required={true}
                      error={errors.shortName.error}
                      errorMsj={errors.shortName.message}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2.5 w-full md:w-auto">
                  <span className=" inputLabel text-accentDefault">Logo</span>
                  <InputImage
                    title={"Upload & Edit Image"} 
                    setImage={setRegularPhoto}
                    photo={state?.logo ?? ""}
                    maxsize={25}
                    width={300}
                    height={300}
                    icon={<Image />}
                  />
                </div>
              </div>

              <ButtonsCrud
                Catalog="WSG"
                hiddenDelete
                mode={props.mode}
                id={id ? id : ""}
                disabled={error}
                actionButton={handleButtonClick}
                onDelete={handleDelete}
              />
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddWSG;
