function FacebookIcon(props: any) {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 50 50"
      fill="#474F56"
    >
      <path d="M33.291 2.84505V9.52968H29.3348C27.8797 9.52968 26.8944 9.83283 26.379 10.4391C25.8637 11.0455 25.606 11.9549 25.606 13.1676V17.9878H33.0637L32.0633 25.4909H25.606V44.7718H17.83V25.4909H11.3727V17.9878H17.83V12.44C17.83 9.28715 18.7092 6.84673 20.4675 5.11873C22.2258 3.39074 24.5753 2.52673 27.5159 2.52673C30.0018 2.52673 31.9268 2.63284 33.291 2.84505Z" />
    </svg>
  );
}

export default FacebookIcon;
