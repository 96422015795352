import { useEffect, useRef, useState } from "react";
import StaffHomePageComponent from "../../components/StaffHomePageComponent/StaffHomePageComponent";


const StaffHomePage = (props:any) => {
    return ( 
        <main>
            <StaffHomePageComponent />
        </main>
     );
}
 
export default StaffHomePage;