import React from "react";

function Account() {
  return (
    <svg
      xmlns="http://www.w3.org/22c2c2c/svg" 
      viewBox="0 0 48 48" 
    > 
          <path  d="M11.841 33.776a20.788 20.788 0 015.644-2.962c2.062-.712 4.236-1.068 6.521-1.068s4.471.37 6.558 1.11c2.086.741 3.951 1.724 5.595 2.948a16.451 16.451 0 002.584-4.522c.612-1.63.918-3.39.918-5.282 0-4.32-1.53-8.01-4.59-11.07C32.01 9.87 28.32 8.34 24 8.34c-4.321 0-8.011 1.53-11.072 4.59C9.87 15.99 8.34 19.68 8.34 24c0 1.873.302 3.624.905 5.253a15.798 15.798 0 002.597 4.523zm12.16-7.522c-2.043 0-3.758-.689-5.145-2.067-1.388-1.378-2.082-3.088-2.082-5.13s.694-3.757 2.082-5.145c1.387-1.388 3.102-2.082 5.144-2.082 2.042 0 3.757.694 5.145 2.082 1.387 1.388 2.081 3.103 2.081 5.145 0 2.042-.694 3.752-2.081 5.13-1.388 1.378-3.103 2.067-5.145 2.067zm-.003 18.707c-2.897 0-5.619-.548-8.165-1.646-2.547-1.097-4.767-2.592-6.661-4.487-1.894-1.894-3.39-4.115-4.487-6.663s-1.646-5.27-1.646-8.167c0-2.897.549-5.619 1.646-8.165 1.097-2.547 2.593-4.767 4.487-6.661 1.894-1.894 4.115-3.39 6.663-4.487s5.27-1.646 8.167-1.646c2.898 0 5.62.549 8.165 1.646 2.547 1.097 4.767 2.593 6.661 4.487 1.895 1.894 3.39 4.115 4.487 6.663 1.098 2.548 1.646 5.27 1.646 8.167 0 2.898-.548 5.62-1.646 8.165-1.097 2.547-2.592 4.767-4.487 6.661-1.894 1.895-4.115 3.39-6.663 4.487-2.548 1.098-5.27 1.646-8.167 1.646zm.002-5.3c1.672 0 3.236-.235 4.69-.704 1.453-.47 2.83-1.15 4.13-2.042-1.338-.91-2.71-1.595-4.117-2.055-1.406-.46-2.974-.69-4.703-.69s-3.292.23-4.689.69c-1.397.46-2.764 1.145-4.102 2.055 1.3.892 2.672 1.572 4.116 2.042 1.444.47 3.003.704 4.675.704zm0-17.83c.791 0 1.452-.265 1.98-.794.53-.529.794-1.19.794-1.98 0-.792-.265-1.457-.793-1.995-.53-.538-1.19-.808-1.98-.808-.792 0-1.452.27-1.981.808-.53.538-.794 1.203-.794 1.995 0 .79.265 1.451.794 1.98.529.53 1.189.794 1.98.794z"></path>
    </svg>
  );
}

export default Account;
