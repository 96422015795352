

import { axios } from "..";

export const getOffice = async () => {
    try {
        const offices = await axios.Get('./office');
        const data = offices.data;
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    } 
  };