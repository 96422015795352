import React from "react";

function RotateAntiClockwise(props: any) {
  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.062 18.722a8.978 8.978 0 01-2.084-.539 6.954 6.954 0 01-1.848-1.076l1.202-1.2c.437.334.883.59 1.34.77.456.18.92.307 1.39.383v1.662zm1.875 0V17.06c1.505-.3 2.748-1.009 3.732-2.127.983-1.118 1.475-2.474 1.475-4.068 0-1.677-.562-3.117-1.684-4.318-1.123-1.202-2.528-1.802-4.213-1.802H9.89l1.422 1.422-1.199 1.199-3.47-3.47 3.47-3.47 1.2 1.176-1.47 1.48h.381c1.058 0 2.047.207 2.969.62a7.679 7.679 0 012.409 1.68 7.9 7.9 0 011.616 2.474c.393.94.589 1.944.589 3.01 0 2.022-.648 3.762-1.943 5.22-1.294 1.458-2.937 2.337-4.926 2.636zm-7.12-2.916a7.676 7.676 0 01-1.033-1.848 8.6 8.6 0 01-.537-2.155h1.66c.069.52.195 1.013.379 1.48.184.466.431.903.743 1.312l-1.211 1.211zm-1.57-5.878c.082-.718.255-1.411.52-2.078a8.594 8.594 0 011.05-1.893L5.042 7.11a7.02 7.02 0 00-.743 1.394c-.184.47-.314.944-.391 1.423h-1.66z"></path>
      </svg>
    </div>
  );
}

export default RotateAntiClockwise;
