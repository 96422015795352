import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect } from "react";
import NewButton from "../../../../components/Atomos/Buttons/newButton";
import Modal from "../../../../components/Atomos/Modals/Modal";
import Cancel from "../../../../components/Atomos/Icons/Close";
import Plus2 from "../../../../components/Atomos/Icons/Plus";
import Success from "../../../../components/Atomos/Icons/Success";

interface IMainBoxUpdateMembershipProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmation: any;
}

const ModalConfirmation: React.FC<IMainBoxUpdateMembershipProps> = ({
    openModal,
    setOpenModal,
    setConfirmation,
}) => {

  return (
    <>
      <Modal
        isModalOpen={openModal}
        toggleModal={() => setOpenModal(!openModal)}
        title="Membership Status"
      >
        <div className="flex flex-col formModal formsection">
            <div className="flex w-full items-start"> 
                <span className=" text-gray-font text-base font-normal leading-6 not-italic lg:text-lg">Please confirm that you intend to withdraw/temporary withdraw this firm.</span>
            </div>
          <div className="flex formsection flex-col md:flex-row">
            <NewButton
              text="CANCEL"
              icon={<Cancel />}
              style={"outlined"}
              size="large"
              content="textIcon"
              onClick={() => {setConfirmation(false); setOpenModal(!openModal)}}
              color="neutral"
            />
            <NewButton
              text="Confirm"
              icon={<Success />}
              style={"filled"}
              size="large"
              content="textIcon"
              onClick={() => {setConfirmation(true); setOpenModal(!openModal)}}
              color="accent"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalConfirmation;
