import React from "react";
import Phone from "../../../components/Atomos/Icons/Phone";
import Email from "../../../components/Atomos/Icons/Email";

interface ContactFrameProps {
  type?: string;
  name?: string;
  phone?: string;
  email?: string;
}

const ContactFrame: React.FC<ContactFrameProps> = ({
  type,
  name,
  phone,
  email,
}) => {
  return (
    <div className="grid text-sm col-span-6 md:col-span-3 lg:col-span-2 gap-2">
      <div className="not-italic font-bold lg:text-[22px] md:leading-normal md:text-xl text-lg text-gray-font">
        {type}
      </div>
      <div className="italic font-normal lg:text-lg lg:leading-8 text-base leading-6 text-accent-Default-dark">
        {name}
      </div>
      <div className="flex flex-row gap-2">
        <div className="fill-gray-font h-5 w-5">
          <Phone />
        </div>
        <div className="not-italic  font-normal lg:text-[20px] text-base leading-6 lg:leading-7 text-gray-font">
          {phone}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="fill-gray-font h-5 w-5">
          <Email />
        </div>
        <div className="not-italic  font-normal lg:text-[20px] text-base leading-6 lg:leading-7 text-gray-font">
          {email}
        </div>
      </div>
    </div>
  );
};

export default ContactFrame;
