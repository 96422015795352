import { useNavigate } from 'react-router-dom';
import { useStaffMode } from '../../../hooks/StaffModeProvider';
import css from './StaffModal.module.css'
import React from 'react'; 
import NewButton from '../../Atomos/Buttons/newButton';


interface StaffModalProps {
  isWsg: boolean;
  text?: string;
}


const StaffModal: React.FC<StaffModalProps> = ({isWsg, text}) => {
  const {toggleModal, setStaffMode} = useStaffMode()
  const navigate = useNavigate()
  const goStaffMode = () => {
    toggleModal()
    setStaffMode()
    navigate('/staff')
  }
  const stayHere = () => {
    toggleModal()
  }

  return ( 
    <div className={`w-full h-screen z-30 top-0 absolute flex justify-center items-center roboto_font text-left`}>
      <div className='h-screen w-full bg-neutral-dark opacity-80 flex justify-center top-0 fixed items-center'></div>
      <div className='bg-white   flex relative flex-col py-4 px-2'>
        <div className='flex flex-col p-4 w-full'>
          <h1 className='w-full text-primary text-[2.25rem] font-medium'>Sorry...</h1>
          <p className='text-[.875rem] lg:text-[.9rem] xl:text-[.95rem]'> 
            <br />
            {isWsg ? 
              <span className='text-[.875rem] md:text-[.9rem] xl:text-[.95rem]'>{text} <br /></span>  
            : <span className='text-[.875rem] md:text-[.9rem] xl:text-[.95rem]'>{text}  <br /></span>
            }
          <span>You have been redirected to your WSG page.</span>
          </p>
        </div>
       {isWsg ? 
       <div className='w-full flex justify-between items-center mt-8 gap-4'> 
        <NewButton color='accent' text='Go to Staff Home Page' onClick={goStaffMode}/> 
        <NewButton color='accent' text='Close & Stay in My Wsg' onClick={stayHere}/>  
        </div>
        :
        <div className='w-6/12 mx-auto flex gap-[2rem] justify-center items-center mt-8'>
          <NewButton color='accent' text='Close' onClick={stayHere}/>	 
        </div>
        }
      </div>
    </div>
   );
}
 
export default StaffModal;