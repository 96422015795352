import React, { useEffect, useRef, useState } from "react";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CatalogFilterButtons from "../CatalogFilterButtons";
import Input from '../../Atomos/Inputs/Input';


interface IFirmNoteFilterProps {
  firmData: Option[];
  filters: {
    name: string;
    firms: Option[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      name: string;
      firms: Option[];
    }>
  >;
  clearSelections: () => void;
  headers: string[];
  tableInstance: any;
  Search: () => void;
} 

const FirmNoteFilter: React.FC<IFirmNoteFilterProps> = ({
  firmData,
  filters,
  Search,
  setFilters,
  headers,
  clearSelections,
  tableInstance
}) => {
  
  const handleSelectFirm = (selected: Option[]) => {  
    setFilters({ ...filters, firms: selected });
  }

  const handlerText = (name:string,value: string) => { 
    setFilters({ ...filters, [name]: value });
  }

  return (
    <div className='flex formsection flex-col lg:flex-row w-full md:flex-wrap lg:flex-nowrap filtersbox'> 
    
    <div className="flex w-full flex-wrap md:flex-nowrap gap-5">
        <div className='w-full'>
            <Input name="name" title="By Name:" value={filters.name} onChange={(e)=>handlerText(e.target.name,e.target.value)} error={false} required={false} />
        </div>
        <div className='w-full'>
            <DDMultiple title="By Firm:" objects={firmData} selectedObjst={filters.firms} seter={(e:any)=>handleSelectFirm(e)}/>
        </div>
    </div>


    <div className="flex items-end justify-end lg:col-start-4  md:col-start-2">
        <div className="w-full md:w-auto">
          <CatalogFilterButtons
            onClickSearch={Search}
            handleFormReset={clearSelections}
            headers={headers}
            tableInstance={tableInstance}
          />
        </div> 
        </div>

</div>
    
  );
};

export default FirmNoteFilter;
