import React, { useEffect, useState } from "react";
import "../../../assets/css/AddCatalogs.css";
import Drag_pan from "../../Atomos/Icons/Drag_pan";
import Axios from "../../../utils/axios";
import SwitchToogle from "../../Atomos/Switch-Toogle/Switch";
import NewButton from "../../Atomos/Buttons/newButton";
import {
  PersonAOPProps,
  PersonAreasOfPracticeProps,
} from "../../../interfaces/models/IPerson";
import WSGCheckbox from "../../Atomos/Checkbox/WSGCheckBox";
import Input from "../../Atomos/Inputs/Input";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import { Search } from "../../Atomos/Icons/Search";
import Sync from "../../Atomos/Icons/Sync";
import CatalogSubTitle from "../Catalog Text/CatalogSubTitle";
import { MemberType } from "../../../Enums/PersonEnums";

import '../../../assets/css/AddCatalogs.css';
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../Context";

const axios = new Axios();

type PersonAOPPropsEdit = {
  handlerAreasOfPractice: (obj: PersonAreasOfPracticeProps[]) => void;
  memberType: number;
  isAttorney: boolean;
  firm: string;
  Errors: any;
  mainOffice: string;
  additionalOffices: string[];
  mode?:string;
} & PersonAOPProps;

const PersonAOPEdit: React.FC<PersonAOPPropsEdit> = (
  props: PersonAOPPropsEdit
) => {
  const [AOP, setAOP] = React.useState<{ id: string; name: string }[]>([]);
  const [expertiseItems, setExpertiseItems] = React.useState<{ name: string, order: number }[]>([]);
  const [selectedAOP, setSelectedAOP] = React.useState<PersonAreasOfPracticeProps[]>([]);
  const [hasPracticeChair, setHasPracticeChair] =
    React.useState<boolean>(false);
  const [filteredAOP, setFilteredAOP] = React.useState<Option[]>([]);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const { mode } = useAppContext()

  const [urlType, setUrlType] = useState('')

  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('/staff/')) {
      setUrlType('staff')
    } else {
      setUrlType('member')
    }
  }, [location])

  const handlerselectedAOP = (obj: any) => {
    const fullobj: PersonAreasOfPracticeProps = {
      id: obj.id,
      name: obj.name,
      expertise: false,
      practiceChair: false,
      order: 0,
      isTargeted: obj.isMapped ? false : true,
    };
    if (!Array.isArray(selectedAOP) || !selectedAOP?.length) {
      setSelectedAOP([fullobj]);
    } else if (selectedAOP?.length > 0 && selectedAOP?.some((item) => item.id === obj.id)) {
      setSelectedAOP(selectedAOP.filter((item) => item.id !== obj.id));
    } else {
      setSelectedAOP([...selectedAOP, fullobj]);
    }
  };

  const handlePracticeChair = (obj: any) => {
    const newItems = [...selectedAOP];

    newItems.forEach((item, index) => {
      if (obj.some((selected: any) => selected.id === item.id)) {
        item.practiceChair = true;
      } else {
        item.practiceChair = false;
      }
    });

    setSelectedAOP(newItems);
  };


  const getAOP = async () => {
    // Si es Practicing Attorney, obtener todas las AOP sin filtros
    if (props.isAttorney) {
      const response = await axios.Get("./areaOfPractice");
      if (!response) return;

      const AOPsToShow = response.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      setAOP(AOPsToShow.length ? AOPsToShow : []);
      setFilteredAOP(AOPsToShow.length ? AOPsToShow : []);
      setHasPracticeChair(props.areasOfPractice?.some((item: any) => item.practiceChair) ?? false);
      return;
    }

    // Evitar petición si no hay firma seleccionada en modo 'add'
    if (props?.mode === 'add' && props?.firm === '' && props?.firm !== undefined) {
      return; 
    }

    let queryString = "./areaOfPractice?";
    let filters: any = {};

    if (props.firm) {
      filters.firms = { value: [props.firm] }; // Filtrar por la firma seleccionada
    }

    if (props.memberType === MemberType.member && props.isAttorney) {
      if (props.additionalOffices && Array.isArray(props.additionalOffices)) {
        const officesList = [props.mainOffice, ...props.additionalOffices].filter(Boolean);
        filters.offices = { value: officesList };
      }
    }

    if (Object.keys(filters).length > 0) {
      queryString += `filter=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    const response = await axios.Get(queryString);
    if (!response) {
      return;
    }

    const AOPsToShow = response.data.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
      };
    });

    setAOP(AOPsToShow.length ? AOPsToShow : []);
    setFilteredAOP(AOPsToShow.length ? AOPsToShow : []);
    setHasPracticeChair(props.areasOfPractice?.some((item: any) => item.practiceChair) ?? false);
  };

  useEffect(() => {
    if (!initialized) {
      if (props.mode === 'edit' && props.areasOfPractice?.length > 0) {
        setExpertiseItems(
          props.areasOfPractice
            .sort((a, b) => a.order - b.order)
            .filter((item) => item.expertise)
            .map((item) => ({
              name: item.name,
              order: item.order,
            }))
        );
        setSelectedAOP(props.areasOfPractice);
        getAOP(); // Solo se llama a getAOP si estamos en modo 'edit'
      }
      setInitialized(true);
    } else {
      // Evitar llamada a getAOP si estamos en modo 'add' y la firma está vacía
      if (props.mode === 'add' && (props.firm === '' || props.firm === undefined)) {
        setAOP([]);
        setFilteredAOP([]);
        props.handlerAreasOfPractice([]); 
        return;
      }
  
      if (props.mode === 'edit' || (props.mode === 'add' && props.firm !== '')) {
        getAOP();
      }
  
      // Resetear AOP y firma cuando memberType cambia a 0 o 1
      if (props.memberType === MemberType.nonMember || props.memberType === MemberType.member) {
        setAOP([]);
        setFilteredAOP([]);
        props.handlerAreasOfPractice([]); 
      }
    }
  }, [props.firm, props.memberType, props.isAttorney, props.mainOffice, props.additionalOffices]);
  
  useEffect(() => {
    props.handlerAreasOfPractice(selectedAOP);
  }, [selectedAOP]);


  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  const handleDragSort = (e: React.DragEvent<HTMLDivElement>) => {
    let _expertiseItems = [...expertiseItems];

    const draggedItemContent = _expertiseItems.splice(dragItem.current, 1)[0];
    _expertiseItems.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setExpertiseItems(_expertiseItems);

    const newItems = [...selectedAOP];
    //   if (_expertiseItems.indexOf(item.name) !== -1) {
    //     item.order = _expertiseItems.indexOf(item.name) + 1;
    //   }
    // });
    _expertiseItems.forEach((item, index) => {
      newItems.forEach((item2, index2) => {
        if (item.name === item2.name) {
          item2.order = index + 1;
        }
      });
    });

    setSelectedAOP(newItems);
  };

  const handleChangeExpertise = (index: number) => {
    const newItems = [...selectedAOP];
    if (
      newItems.filter((item) => item.expertise)?.length === 4 &&
      !newItems[index].expertise
    ) {
      return;
    }
    newItems[index].expertise = !newItems[index].expertise;
    if (expertiseItems?.length + 1 <= 4)
      newItems[index].order = expertiseItems?.length + 1;
    else newItems[index].order = 0;

    if (newItems[index].expertise) {
      // setExpertiseItems([...expertiseItems, newItems[index].name]);
      setExpertiseItems([
        ...expertiseItems.sort(
          (a, b) => a.order - b.order
        ),
        { name: newItems[index].name, order: newItems[index].order },
      ]);
    } else {
      const newExpertiseItems = [...expertiseItems];
      newExpertiseItems.splice(
        // newExpertiseItems.indexOf(newItems[index].name),
        newExpertiseItems.findIndex((item) => item.name === newItems[index].name),
        1
      );
      setExpertiseItems(newExpertiseItems);
    }
    setSelectedAOP(newItems);
  };

  return (
    <article className="formsectionNTitle w-full">
      <CatalogSubTitle title={"Areas of Practice"} className={`${mode === 'staff' ? "text-Default" : 'text-accentDefault'}`} />
      <section className=" grid grid-cols-12 gap-5 w-full">
        <div className=" col-span-12 flex-col flex gap-5 ">
          <div className="flex flex-col w-full items-start gap-2.5">
            <div className="flex flex-col md:flex-row w-full items-center gap-5">
              <div className="flex-1 w-full">
                <Input
                  title="Search Firm's and Office's Areas of Practice"
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.currentTarget.value)}
                  error={props.Errors.aop.error}
                />
              </div>
              <div className="flex h-full items-end md:w-auto w-full">
                <div className="flex gap-2 justify-center  items-center">
                  <NewButton
                    onClick={() => {
                      setFilteredAOP(
                        AOP.filter((obj) =>
                          searchInput
                            ? obj.name
                              ?.toLocaleLowerCase()
                              .includes(searchInput.toLocaleLowerCase())
                            : true
                        )
                      );
                    }}
                    text="Search"
                    className="flex-1 !min-h-[3rem]"
                    content="textIcon"
                    icon={<Search />}
                    color={urlType === 'staff' ? 'accent' : 'primary'}
                    size="large"
                  />
                  <NewButton
                    onClick={() => {
                      setFilteredAOP(AOP);
                      setSearchInput("");
                    }}
                    text="Reset"
                    className="[&>span]:fill-Default !self-center [&>span]:hover:fill-accent-Default-dark md:max-w-none max-w-min"
                    content="iconSquare"
                    icon={<Sync />}
                    style="ghost"
                    color="neutral"
                    size="large"
                  />
                </div>
              </div>
            </div>
            {props.isAttorney && selectedAOP?.length == 0 && (
              <h5
                className={`text-sm lg:text-base lg:leading-[1.125rem] leading-4 ${props.Errors.aop.error ? " text-feedback-error" : "text-accentDefault"}`}
              >
                At least one Area of Practice must be selected.
              </h5>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 max-h-[62.5rem] md:max-h-[63.75rem] lg:max-h-[48.375rem] gap-5 overflow-scroll no-scrollbar">
            {filteredAOP
              .sort((a, b) => a.name?.localeCompare(b.name))
              .map((item, index) => {
                return (
                  <WSGCheckbox
                    index={index}
                    key={index}
                    text={item.name}
                    checked={selectedAOP?.some(
                      (selected) => selected.id === item.id
                    )}
                    handleCheckboxChange={() => handlerselectedAOP(item)}
                    className="!p-0"
                  />
                );
              })}
          </div>
        </div>
      </section>
      <section className="grid grid-cols-12 gap-5 w-full">
        <div className="col-span-6 flex flex-col gap-5">
          <SwitchToogle
            title="Practice Chair"
            checked={hasPracticeChair}
            seter={setHasPracticeChair}
          />
          <DDMultiple
            title="Select AOPs"
            disabled={!hasPracticeChair}
            required
            objects={selectedAOP}
            selectedObjst={
              selectedAOP?.length > 0
                ? selectedAOP.filter((item) => item.practiceChair)
                : []
            }
            seter={handlePracticeChair}
          />
        </div>
      </section>
      <section className="flex flex-col gap-6 w-full">
        <span className="  text-gray-font font-bold text-left text-[1.375rem] leading-6 ont-bold relative self-stretch">
          Chosen AOPs
        </span>
        <div className=" flex flex-col gap-[0.625rem] w-full ">
          <span className=" text-gray-font text-left text-[1.125rem] leading-5 ont-bold relative self-stretch">
            Mark as Expertise (Up to 4)
          </span>
          <div className=" grid grid-cols-1 md:grid-cols-2 w-full gap-5 ">
            {selectedAOP?.map((item, index) => {
              return (
                <WSGCheckbox
                  index={index}
                  text={item.name}
                  checked={item.expertise}
                  handleCheckboxChange={handleChangeExpertise}
                  className="!p-0"
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="grid grid-cols-12 gap-5 w-full">
        <div className="col-span-12">
          <span className="text-gray-font font-bold text-left text-[1.375rem] leading-6 ont-bold relative self-stretch">
            Practice Expertise Order
          </span>
        </div>
        <div className=" col-span-2">
          <span className=" text-lg leading-5 not-italic font-normal text-gray-font">
            Order{" "}
          </span>
        </div>
        <div className=" col-span-10  text-center">
          <span className=" text-lg leading-5 not-italic font-normal text-gray-font">
            Expertise{" "}
          </span>
        </div>
        <div className="col-span-12 flex gap-5">
          <div className="  flex flex-col gap-5">
            {expertiseItems.map((item, index) => {
              return (
                <div
                  className=" w-[2.75rem] h-[2.5rem] min-w-10 min-h-10 p-2 bg-gray-50 "
                  key={index}
                >
                  <span className=" text-xl leading-6 not-italic font-decimal font-medium ">
                    {index + 1}.
                  </span>
                </div>
              );
            })}
          </div>
          <div
            className=" flex-1 flex gap-5 flex-col  list-container"
            onDrop={() => { }}
            onDrag={() => { }}
          >
            {/* //sort by order */}
            {expertiseItems.map((item, index) => {
              return (
                <div
                  className="  cursor-move "
                  key={index}
                  draggable
                  onDragStart={(e) => (dragItem.current = index)}
                  onDragEnter={(e) => (dragOverItem.current = index)}
                  onDragEnd={handleDragSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <div className=" flex gap-2 h-[2.5rem] bg-gray-50  p-2.5  items-center">
                    <div className="h-5 w-5">
                      <Drag_pan />
                    </div>
                    <span className=" text-gray-font">{item.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </article>
  );
};
export default PersonAOPEdit;
