import { Table, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import '../../../assets/css/table/Pagination.css';
import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import DraggableTableHeader from "./THTransitionToTanSack";
import DragAlongCell from "./TDTransitionToTanSack";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import { ISorting } from "../../../interfaces/Components/ITable";

type tablebase = {
  data: any;
  columnsDef: any;
  setTableInstance?: (table: Table<unknown>) => void;
  sorting?: ISorting;
  setSorting?: Dispatch<SetStateAction<ISorting>>;
  totalCount: number;
};

const TableV2: React.FC<tablebase> = ({
  data,
  columnsDef,
  setTableInstance,
  sorting,
  setSorting,
  totalCount,
}) => {
  const [columnOrder, setColumnOrder] = React.useState<string[]>(
    columnsDef.map((column: any) => column.accessorKey)
  );

  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const table = useReactTable({
    columns: columnsDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnOrder,
      columnVisibility,
      pagination,
    },
    pageCount: Math.ceil(totalCount / pagination.pageSize),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange",
  });

  useEffect(() => {
    if (setTableInstance && table) {
      setTableInstance(table);
    }
  }, [columnVisibility]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const totalPageCount = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex + 1;

  const generatePageNumbers = () => {
    const pages = [];
    if (totalPageCount <= 5) {
      for (let i = 1; i <= totalPageCount; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1, 2, 3);

      if (currentPage > 3) {
        pages.push('...');
      }

      if (currentPage > 3 && currentPage < totalPageCount - 2) {
        pages.push(currentPage);
      }

      if (currentPage < totalPageCount - 2) {
        pages.push('...');
      }

      pages.push(totalPageCount - 2, totalPageCount - 1, totalPageCount);
    }

    return pages;
  };

  const getVisiblePages = (currentPage: number, totalPages: number) => {
    const pages = [];
    const delta = 1;

    const left = Math.max(currentPage - delta, 1);
    const right = Math.min(currentPage + delta, totalPages);

    for (let i = left; i <= right; i++) {
      pages.push(i);
    }

    if (left > 1) {
      pages.unshift(1);
    }

    if (right < totalPages) {
      pages.push(totalPages);
    }

    return pages;
  };


  const visiblePages = getVisiblePages(table.getState().pagination.pageIndex + 1, table.getPageCount());


  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="pagination">
        <label htmlFor="records-per-page" className="records-per-page">Rows per Page</label>
        <div className="relative flex justify-center items-center bg-gray-25">
          <select
            id="records-per-page"
            name="records-per-page"
            className="selectorPageSize"
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
          >
            {[10, 20, 50, 100, 150, 200, 300].map(pageSize => (
              <option key={pageSize} value={pageSize} className="whitespace-nowrap">
                {pageSize}
              </option>
            ))}
          </select>
          <div>
            <div className="absolute h-6 w-6 right-2 top-2 fill-gray-font pointer-events-none">
              <ChevronDown />
            </div>
          </div>
        </div>

        <button className="arrow" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          &lt;
        </button>

        {visiblePages.map((page, index) => (
          <button
            key={index}
            className={`paginationButton ${page === table.getState().pagination.pageIndex + 1 ? 'currentButton bg-Default' : ''}`}
            onClick={() => table.setPageIndex(page - 1)}
          >
            {page}
          </button>
        ))}

        <button className="arrow" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          &gt;
        </button>
      </div>
      <div className="table-responsive tabledata no-scrollbar overflow-x-scroll w-full">
        <table className="table min-w-full w-max">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <SortableContext
                  items={columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  {headerGroup.headers.map((header) => (
                    <DraggableTableHeader
                      key={header.id}
                      header={header}
                      sorting={header.column.columnDef.enableSorting !== false ? sorting : undefined}
                      setSorting={header.column.columnDef.enableSorting !== false ? setSorting : undefined}
                    />
                  ))}
                </SortableContext>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="even:bg-gray-100">
                {row.getVisibleCells().map((cell) => (
                  <SortableContext
                    key={cell.id}
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DragAlongCell key={cell.id} cell={cell} />
                  </SortableContext>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div className="pagination">
        <label htmlFor="records-per-page" className="records-per-page">Rows per Page</label>
        <div className="relative flex justify-center items-center bg-gray-25">
          <select
            id="records-per-page"
            name="records-per-page"
            className="selectorPageSize"
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
          >
            {[10, 20, 50, 100, 150, 200, 300].map(pageSize => (
              <option key={pageSize} value={pageSize} className="whitespace-nowrap">
                {pageSize}
              </option>
            ))}
          </select>
          <div>
            <div className="absolute h-6 w-6 right-2 top-2 fill-gray-font pointer-events-none">
              <ChevronDown />
            </div>
          </div>
        </div>

        <button className="arrow" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          &lt;
        </button>

        {visiblePages.map((page, index) => (
          <button
            key={index}
            className={`paginationButton ${page === table.getState().pagination.pageIndex + 1 ? 'currentButton bg-Default' : ''}`}
            onClick={() => table.setPageIndex(page - 1)}
          >
            {page}
          </button>
        ))}

        <button className="arrow" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          &gt;
        </button>
      </div>
    </DndContext>
  );
};

export default TableV2;
