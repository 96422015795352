import "../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import BackgroundComponent from "../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmPracticeHeadsBackground from "../../../assets/images&icons/bg-manageFirmPracticeHeads.png";
import CurrentPage from "../../../components/Layout/CurrentPageTab/CurrentPage";
import { useLocation, useNavigate } from "react-router-dom";
import MenuGT from ".././GeneralTab/Menu";
import CatalogTitle from "../../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../../components/Moleculas/Catalog Text/CatalogSubTitle";
import DropDownList from "../../../components/Atomos/DropDown/Dropdown";
import Input from "../../../components/Atomos/Inputs/Input";
import { getPersons } from "../../../Services/Person";
import NewButton from "../../../components/Atomos/Buttons/newButton";
import RemoveSign from "../../../components/Atomos/Icons/RemoveSign";
import Plus2 from "../../../components/Atomos/Icons/Plus";
import Close from "../../../components/Atomos/Icons/Close";
import SaveChanges from "../../../components/Atomos/Icons/EditMode/SaveChanges";
import { updateAop } from "../../../Services/AreaOfPractice";
import Axios from "../../../utils/axios";
import useViewport from "../../../hooks/useViewPort";

const WSGDots = require("../../../assets/images&icons/WSGDots.svg");
const axios = new Axios();

const ManageFirmPracticeHeadsEditTab: React.FC = () => {
  const { viewportWidth } = useViewport();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [professionals, setProfessionals] = useState<any[]>([]);
  const [practiceHeads, setPracticeHeads] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    if (state) {
      getPersonsFirm(state.firmId);
      setPracticeHeads(
        state.practiceHead?.map((id: string) => ({ id, name: "" })) || [
          { id: "", name: "" },
        ]
      );
    }
  }, [state]);

  useEffect(() => {
    if (practiceHeads.length > 0 && professionals.length > 0) {
      const updatedPracticeHeads = practiceHeads.map((practiceHead) => {
        const professional = professionals.find(
          (person) => person.id === practiceHead.id
        );
        return {
          id: practiceHead.id,
          name: professional ? professional.name : "",
        };
      });
      setPracticeHeads(updatedPracticeHeads);
    }
  }, [professionals, practiceHeads.length]);

  const getAllPersons = async () => {
    const res = await axios.Get("/person");
    return res.data;
  };

  const getPersonsFirm = async (firmId: string) => {
    const persons = await getAllPersons();
    console.log(state);
    const filteredPersons = persons.filter(
      (person: any) => person.firm === firmId
    );
    setProfessionals(filteredPersons);
  };

  const handleSetSelectedPerson = (index: number, obj: any) => {
    const newPracticeHeads = [...practiceHeads];
    newPracticeHeads[index] = {
      id: obj.id,
      name: obj.name,
    };
    setPracticeHeads(newPracticeHeads);
  };

  const handleAddNewContact = () => {
    setPracticeHeads([...practiceHeads, { id: "", name: "" }]);
  };

  const handleRemove = (index: number) => {
    const newPracticeHeads = practiceHeads.slice();
    newPracticeHeads.splice(index, 1);
    setPracticeHeads(newPracticeHeads);
  };

  const handleUpdate = () => {
    const practiceHeadIds = practiceHeads.map(
      (practiceHead) => practiceHead.id
    );
    updateAop({
      ...state,
      practiceHead: practiceHeadIds,
    });
    navigate(-1);
  };

  return (
    <>
      <MenuGT state={state} />
      <BackgroundComponent image={ManageFirmPracticeHeadsBackground} />

      <div className="mainSection">
        <div className="flex flex-col pt-7 gap-6 lg:gap-10 md:pt-8 lg:pt-10 pb-5">
          <div className="flex lg:min-h-[70px] gap-4 justify-between">
            <CatalogTitle
              title={`Practice Heads`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Manage Firm > Practice Heads"
              currentPage={`Assign Head`}
            />
          </div>
        </div>

        <form>
          <article className="articleSection relative">
            <section className="formsectionNTitle ">
              <CatalogSubTitle title="Assign Head" className="text-Default" />
              <div className="formsection grid grid-cols-1 gap-5 w-full">
                <div className="">
                  <Input
                    title="AOP Name"
                    required={true}
                    disabled
                    value={state.name}
                    error={false}
                  />
                </div>
                {practiceHeads.map((practiceHead, index) => (
                  <div key={index} className="flex flex-nowrap w-full gap-5 !self-end">
                    <div className="flex w-full md:w-1/2">
                      <DropDownList
                        title="Professional"
                        data={professionals.map((professional) => ({
                          id: professional.id,
                          name: professional.name,
                        }))}
                        selectedObjst={practiceHead.name}
                        seter={(value) => handleSetSelectedPerson(index, value)}
                        error={false}
                        getid
                      />
                    </div>
                      <NewButton
                        className="!min-h-[45px] !max-w-fit flex-shrink !self-end "
                        text="REMOVE"
                        color="delete"
                        style="outlined"
                        size="large"
                        content={
                          viewportWidth > 767 ? "textIcon" : "iconSquare"
                        }
                        icon={<RemoveSign />}
                        onClick={() => handleRemove(index)}
                      />
                  </div>
                ))}

                <div className="md:w-min w-full ">
                  <NewButton
                    text={`ADD`}
                    color="accent"
                    type="button"
                    style="filled"
                    size="large"
                    content="textIcon"
                    onClick={handleAddNewContact}
                    icon={
                      <Plus2 className="w-5 h-5 shrink-0 relative overflow-visible" />
                    }
                  />
                </div>
              </div>

              <div className="flex justify-end self-stretch">
                <div className="flex md:w-min w-full flex-shrink gap-4">
                  <NewButton
                    text={`CANCEL`}
                    color="neutral"
                    content="textIcon"
                    type="button"
                    style="outlined"
                    size="medium"
                    className=""
                    icon={<Close />}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <div className="md:min-w-[8.75rem] w-full">
                    <NewButton
                      text={`SAVE`}
                      color="accent"
                      style="filled"
                      size="medium"
                      content="textIcon"
                      className=" "
                      icon={<SaveChanges />}
                      onClick={handleUpdate}
                    />
                  </div>
                </div>
              </div>
            </section>
          </article>
        </form>
      </div>
    </>
  );
};

export default ManageFirmPracticeHeadsEditTab;
