import React from "react";
import "../../../assets/css/buttons/newButton.css";

export interface InewButton {
  text?: string;
  icon?: JSX.Element;
  style?: "filled" | "ghost" | "outlined";
  color?: "primary" | "accent" | "error" | "neutral" | "delete" | "excel";
  disabled?: boolean;
  size?:
    | "small"
    | "medium"
    | "large"
    | "medium-no-icon"
    | "dropdown"
    | "filter";
  content?: "text" | "textIcon" | "icon" | "iconSquare";
  type?: "button" | "submit" | "reset";
  onClick?: () => void; 
  className?: string;
  link?: boolean; 
}

const NewButton: React.FC<InewButton> = ({
  size = "small",
  style = "filled",
  color = "primary",
  disabled = false,
  link = false,
  content = "text",
  text,
  icon,
  className = "",
  ...props
}: InewButton): JSX.Element => {
  return (
    <div
      onClick={!disabled ? props.onClick : () => {}}
      className={`${content} ${size} ${color} ${style} ${disabled ? "disabled text-light-gray" : "cursor-pointer"} ${link && "anchorbutton"} items-center uppercase font-decimal fill-current ${className}`}
    >
      {content === "text" && text && (
        <span className="text-center">{text}</span>
      )}
      {(content === "icon" || content === "iconSquare") && <span>{icon}</span>}
      {content === "textIcon" && (
        <>
          {text && (
            <span className=" text-center justify-center items-center ">
              {text.toUpperCase()}
            </span>
          )}
          {icon && <span>{icon}</span>}
        </>
      )}
    </div>
  );
};

export default NewButton;
