import React, { useState, useEffect, SetStateAction } from 'react';
import css from './ImageCropper.module.css';
 
import Cropper from 'react-easy-crop';  
import { ProfileData } from '../../../interfaces/Hooks/IProfileData';
import Axios from '../../../utils/axios';
import { useAppContext } from '../../../Context';
import RotateClockwise from '../../Atomos/Icons/EditMode/RotateClockwise';
import RotateAntiClockwise from '../../Atomos/Icons/EditMode/RotateAntiClockwise';
import Folder from '../../Atomos/Icons/EditMode/Folder';
import NewButton from '../../Atomos/Buttons/newButton';
import Plus2 from '../../Atomos/Icons/Plus';
import Pending from '../../Atomos/Icons/Pending';
import Warning from '../../Atomos/Icons/Warning';
import Success from '../../Atomos/Icons/Success';

interface Props {
  imageSrc?: string | null; // Renombrar la propiedad a "imageSrc"
  onCrop?: (croppedImage: any) => void; // ESTE SE VA A ELIMINAR
  onHandleUpdated?: (spinner: boolean) => void;
  user?: ProfileData | null;
  onImageUpload: (url: string) => void;
  draggedPhoto?: File | string | null;
  onHandleUpload: () => void; 
  setPhoto:(photo:File | null)=>void;
  shape: "round" | "rect";
}


const ImageCropper: React.FC<Props> = ({ imageSrc, onCrop, user,onHandleUpdated ,
  setPhoto, onImageUpload, draggedPhoto, onHandleUpload, shape}) => {
  const [photoSrc, setPhotoSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const axios = new Axios()
  const [errorMesssage, setErrorMessage] = useState<string | null>(null);
  const MAX_IMAGE_SIZE = 25 * 1024 * 1024; // 20 MB en bytes
  const [ showSpinner, setShowSpinner ] = useState(false)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(false)
  const {mode} = useAppContext();
  const [status, setStatus] = useState<string>('')
  
const isImageSizeValid = (file: File) => {
  return file.size <= MAX_IMAGE_SIZE;
};

useEffect(() => {
  if (draggedPhoto) {
    if (typeof draggedPhoto === 'string') {
      setPhotoSrc(draggedPhoto);
    } else if (draggedPhoto instanceof File) {
      const loadImage = async () => {
        const file = draggedPhoto;
        const reader = new FileReader();

        reader.onload = async (e) => {
          if (typeof e.target?.result === 'string') {
            setPhotoSrc(e.target.result);
          }
        };

        reader.readAsDataURL(file);
      };

      loadImage();
    }
  }
}, [draggedPhoto]);

useEffect(() => {

  if(photoSrc === "" && errorMesssage === null){
    setStatus("")
  }else if( errorMesssage !== ""){
    setStatus("Warning")
  }else{
    setStatus("Success")
  }
}, [photoSrc, errorMesssage]);

  const [originalImage, setOriginalImage] = useState('');

  const checkImageType = (fileExtension: string | undefined) => {
    const acceptedFileTypes = [".jpg", ".jpeg", ".png", ".gif", ".webp", '.heif', '.bmp', 'tiff', '.dng', 'raw'];
    return acceptedFileTypes.includes(`.${fileExtension?.toLowerCase()}`);
  };
  
  const checkImageSize = (file: File) => {
    return file.size <= MAX_IMAGE_SIZE;
  };
 
  const handleLoadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
  
    const file = event.target.files?.[0];
  
    if (file) {
      setShowSpinner(true);
      try {
        const fileExtension = file.name.split('.').pop();
        setOriginalImage(file.name.toLowerCase());
  
        if (checkImageType(fileExtension) && checkImageSize(file)) {
          const reader = new FileReader();
  
          reader.onload = async () => {
            if (typeof reader.result === 'string') {
              const lowerResImage = await loadImage(reader.result, 800, 800);
              const resizedImageUrl = await resizeImage(lowerResImage, 800, 800);
  
              setPhotoSrc(resizedImageUrl);
            }
          };
  
          reader.readAsDataURL(file);
          setErrorMessage('');
        } else {
          setErrorMessage(checkImageType(fileExtension) ? 'Image size exceeds the 25 MB limit.' : 'File type not supported. Accepted file types are: .jpg, .jpeg, .png, .gif, .webp');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        onHandleUpload();
        setTimeout(() => {
          setShowSpinner(false);
        }, 500)
      }
    }
  };

// Helper functions
const loadImage = (src: string, maxWidth: number, maxHeight: number) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      resolve(img);
    };
  });
};

const resizeImage = (image: HTMLImageElement, maxWidth: number, maxHeight: number) => {
  return new Promise<string>((resolve) => {
    // Resize logic here...

    // Example: Resize the image to fit within maxWidth x maxHeight
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx) {
      const newWidth = Math.min(image.width, maxWidth);
      const newHeight = (newWidth / image.width) * image.height;

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      const resizedImageUrl = canvas.toDataURL('image/jpeg');
      resolve(resizedImageUrl);
    }
  });
};
  


// Función para convertir una URI de datos en un Blob
const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}




const [rotation, setRotation] = useState<number>(0);

const handleRotation = (direction:string) => {
  if(direction === 'clockwise') {
    setRotation(rotation + 90)
  }
  else if(direction === 'anticlockwise') {
    setRotation(rotation - 90)
  }
}
 
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  
  const handleOnSave = ()=>
  { 
    setPhoto(croppedImage);
    onImageUpload(croppedImage?.name as string);
  } 

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
    if (ctx && croppedAreaPixels.width && croppedAreaPixels.height) {
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
  
      const image = new Image();
      image.src = photoSrc;
  
      image.onload = () => {
        // Aplicar la rotación a la imagen original
        const rotatedCanvas = document.createElement("canvas");
        const rotatedCtx = rotatedCanvas.getContext("2d");
  
        const rotationInRadians = (rotation * Math.PI) / 180;
  
        rotatedCanvas.width = image.width;
        rotatedCanvas.height = image.height;
        rotatedCtx?.translate(image.width / 2, image.height / 2);
        rotatedCtx?.rotate(rotationInRadians);
        rotatedCtx?.drawImage(image, -image.width / 2, -image.height / 2);
  
        // Recortar la imagen rotada
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        ctx.drawImage(
          rotatedCanvas,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
          
        // Convierte el lienzo a una URL de datos
        const croppedImageDataURL = canvas.toDataURL("image");
       const croppedImageBlob = dataURItoBlob(croppedImageDataURL);
        const fileName = originalImage; // Puedes ajustar el nombre de archivo según tus necesidades
        const file = new File([croppedImageBlob], fileName, { type: croppedImageBlob.type });
  
        // Asigna el objeto File a tu estado
        setCroppedImage(file);  
      };
    }

 
  };

  const [base64Image, setBase64Image] = useState('');
 useEffect(() => {
    // Supongamos que tienes la ruta relativa
    const rutaRelativa = '/assets/images&icons/DefaultImageCropper.png';

    // Obtén la URL actual
    const urlActual = window.location.origin;

    // Combina la URL actual con la ruta relativa para obtener la URL completa
    const urlCompleta = `${urlActual}${rutaRelativa}`;

    // Cargar la imagen y convertirla a base64
    fetch(urlCompleta)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64data = reader.result;
          setBase64Image(base64data as string);
        };
        reader.readAsDataURL(blob);
      });
      console.log(base64Image);
  }, []);

  return (
    <div className={`z-30 relative flex flex-col gap-4 md:gap-6 `}>
      <div className="bg-gray-font md:px-16">
        <div className=" w-[15rem] min-h-[300px] min-w-[320px] md:min-h-[400px] md:min-w-[400px] m-auto  z-20 relative">
          <Cropper
            image={photoSrc ? photoSrc : ","}
            crop={crop}
            zoom={zoom}
            aspect={1}
            showGrid={false}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            classes={{ containerClassName: css.CropArea }}
            onCropComplete={onCropComplete}
            rotation={rotation}
            objectFit="horizontal-cover"
            cropShape={shape}
          />
        </div>
      </div>
      <div className={`${css.photoOptions}`}>
        <div className={`${css.optionButtons}`}></div>
        <fieldset className="flex justify-between items-center gap-6 relative">
          <div className="w-full relative flex flex-col gap-2.5">
            <legend className=" text-xl lg:text-[1.375rem] not-italic font-bold leading-6 text-gray-font">
              Crop
            </legend>
            <div className={`flex w-full flex-col md:flex-row gap-3 justify-center md:justify-between items-center`}>
              <div className="w-full flex flex-col gap-1">
                <p className=" text-base lg:text-lg not-italic font-normal text-gray-font leading-8">
                  Zoom
                </p>
                <div className="flex gap-2 w-full ">
                  <span className="text-neutral-dark text-[.75rem]">-</span>
                  <input
                    type="range"
                    id={``}
                    min="1"
                    max="3"
                    step={0.1}
                    aria-spanledby="Zoom"
                    defaultValue="1"
                    onChange={(e: any) => {
                      setZoom(e.target.value);
                    }}
                    value={zoom}
                    className={`${
                      mode === "member" ? css.scaleSlider : css.scaleSliderStaff
                    } w-8/12 custom-range appearance-none  h-2 bg-gray-300 rounded-md`}
                  />
                  <span className="text-neutral-dark text-[.75rem]">+</span>
                </div>
              </div>
              <div>
                <div
                  className={`  ${css.optionButtons} lg:justify-end justify-center lg:mt-3 md:mt-0 flex lg:gap-2 items-center text-neutral-dark`}
                >
                  <button
                    id={`${css.rotateClockwise}`}
                    onClick={() => handleRotation("clockwise")}
                    type="button"
                  >
                    <RotateClockwise className="w-5 h-5 fill-gray-font"/>
                  </button>
                  <button
                    id={`${css.rotateCounterClockwise}`}
                    onClick={() => handleRotation("anticlockwise")}
                    type="button"
                  >
                    <RotateAntiClockwise className="w-5 h-5 fill-gray-font"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        accept="*"
        onChange={handleLoadImage}
        className="z-20 relative"
      />
      {errorMesssage && (
        <div className="text-feedback-error text-base lg:text-lg text-left relative bottom-3">
          {errorMesssage}
        </div>
      )}
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-col">
          <span
            className={`text-base lg:text-lg font-normal leading-6 text-left text-gray-font `}
          >
            Accepted file types are: .jpg, .jpeg, .png .gif and .webP.
          </span>
          <span
            className={`text-base lg:text-lg font-normal leading-6 text-left text-gray-font`}
          >
            Up to 25 MB.
          </span>
        </div>
        
        <div className="flex gap-4 md:justify-between">
          <label
            className={` flex items-center py-2 lg:py-3 pr-2 pl-3 gap-2 lg:gap-3 border border-s border-gray-font text-gray-font text-base lg:text-lg font-decimal cursor-pointer`}
            htmlFor="fileInput"
            id="customFileInput"
          >
            SELECT FILE
            <Folder className="w-5 h-5 lg:w-6 lg:h-6 fill-gray-font"/>
          </label>
          
          <div className='!min-w-[120px]'>
            <NewButton
              onClick={handleOnSave}
              text={"ADD"}
              icon={<Plus2 />}
              color={mode === "member" ? "primary" : "accent"}
              content="textIcon"
              size="medium"
              style="filled"
            />
          </div>
        </div>

          {status === "" &&
            <div className='flex items-center gap-2.5'>
              <Pending className="w-5 h-5 lg:w-6 lg:h-6  fill-gray-font"/>
              <span className="text-base lg:text-lg leading-8 font-normal not-italic text-gray-font">
                Please select a file.
              </span>
            </div>
          }

          {status === "Warning" &&
            <div className='flex items-center gap-2.5'>
              <Warning className="w-5 h-5 lg:w-6 lg:h-6 fill-accentDefault"/>
              <span className="text-base lg:text-lg leading-8 font-normal not-italic text-accentDefault">
                Upload Failed.
              </span>
            </div>
          }

          {status === "Success" &&
            <div className='flex items-center gap-2.5'>
              <Success className="w-5 h-5 lg:w-6 lg:h-6 fill-feedback-success-Default"/>
              <span className="text-base lg:text-lg leading-8 font-normal not-italic text-feedback-success-Default">
                Upload Successful.
              </span>
            </div>
          }

      </div>
    </div>
  );  
};

export default ImageCropper;