
  enum Suffix {
    AdE = "Ad. E",
    Ad = "Ad.",
    CFE = "CFE",
    CPA = "CPA",  
    CPA_CFE = "CPA, CFE",
    CPA_JD = "CPA, J.D.",
    Esq = "Esq.",
    II = "II",
    II_Esq = "II, Esq.",
    III = "III",
    JD = "J.D.",
    JD_CFE = "J.D., CFE",
    Jr = "Jr.",
    Jr_Esq = "Jr., Esq.",
    PhD = "Ph.D.",
    QC = "Q.C.",
    QC_AdE = "Q.C., Ad.E.",
    Sr = "Sr.",
    Sr_Esq = "Sr., Esq."
  }

  enum Honorary {
    Miss = "Miss",
    Mr = "Mr.",
    Mrs = "Mrs.",
  }

  enum Title {
  //   Chairman = "Chairman",
  //   CEO ="Chief Executive Officer",
  //  COO = "Chief Operating Officer",
  //   CFO ="Chief Financial Officer",
  //   ED = "Executive Director",
  //   EVP ="Executive Vice President",
  //   GC = "General Counsel",
  //   SC = "Senior Counsel",
  //   P= "President",
  //   VP = "Vice President",
  //   Parter = "Parter",
  //   SA = "Senior Associate",
  //   A = "Associate",
  //   AP= "Advisory Partner"
  EX="Executive",
  Parter="Partners",
  ASS="Associates",
  OC="Of Counsel"
  }
  
  enum ListingCategory {
    Executive_Management = "Executive Management",
    Professionals = "Professionals" ,
    Other = "Other"
  }

  enum MemberType {
    member = 0,
    nonMember = 1,
    staff = 2,
  }

  enum externalType {
    Author= "Author",
    Guest = "Guest",
  }

//   export enums
export { Suffix, Honorary, Title, ListingCategory ,MemberType ,externalType};
