import React, { Children, FormEvent, useState } from "react";
import ExcelModal from "../Atomos/Modals/ExcelModal";
import Plus2 from "../Atomos/Icons/Plus";
import Axios from "../../utils/axios";
import Excel from "../Atomos/Icons/Excel";
import NewButton from "../Atomos/Buttons/newButton";
import { Link } from "react-router-dom";
import "../../assets/css/table/Table.css";
import VisibleColumns from "../Organismos/Table/VisibleColumns";
import useViewport from "../../hooks/useViewPort";
import "../../assets/css/CatalogTable.css";
import CatalogTitle from "../Moleculas/Catalog Text/CatalogTitle";
import CounterTotalCatalog from "../Moleculas/Catalog Text/CounterTotalCatalog";
import { IPracticeIndustry } from "../../interfaces/models/IPracticeIndustry";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/loaderActions";

interface Region {
  name: string;
  id: string;
}

interface ICatalogTableProps {
  newItemAction?: () => void;
  title?: string;
  catalogName?: string;
  headers: string[];
  route: string;
  totalCount: number | undefined;
  isfiltered: boolean;
  data: any[];
  totalData?: any[];
  HandleFilter?: (field: string, value: string) => void;
  onsubmit?: (e: FormEvent<HTMLFormElement>) => void;
  clearSelections?: () => void;
  shouldFormat?: boolean;
  hasTotalData?: boolean;
  GetTotalData?: (all: boolean) => Promise<any[]>;
  linkToAdd?: string;
  noNewCatalog?: boolean;
  noExcelExport?: boolean;
  showAddButton?: boolean;
  selectedTab?: number;
  catalogShortName?: string;
  noRedBox?: boolean;
  children?: React.ReactNode;
  practiceIndustries?: IPracticeIndustry[];
  resetSignal?: number;
  personFromDto?: boolean;
  exportData?: any;
  regions?: any[];
  firmData?: any;
  firmId?: string;
}

const CatalogTableC: React.FC<ICatalogTableProps> = (
  props: ICatalogTableProps
) => {
  const { viewportWidth } = useViewport();
  const dispatch = useDispatch();

  function formatDate(value: string) {
    const formattedDate = new Date(Number(value) * 1000).toLocaleDateString(
      "en-US",
      {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }
    );

    return formattedDate;
  }

  const formatDateValue = (value: string) => {
    return new Date(Number(value) * 1000).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const axios = new Axios();

  const [open, setOpen] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<any[]>([]);

  const GetTotalData = async () => {
    let datapassed: any[] = [];
    let queryString = `/${props.route}`;

    if (props.selectedTab === 0) {
      queryString += "?resourceId=mappingPage&countOnly=true";
    } else if (props.selectedTab === 1) {
      queryString += "?resourceId=mappingPage&isMapped=true&countOnly=true";
    }
    const fetchTotalCount = async () => {
      try {
        if (props.selectedTab && props.selectedTab === 0) {
          let newString = "&resourceId=mappingPage&countOnly=true";
          queryString += newString;
        }
        const response = await axios.Get(queryString);
        const data = response.data;
        if (data != null) {
          setTotalData(data);
          datapassed = data;
        }
      } catch (error) {
        console.error("Error al obtener la información de la columna:", error);
      }
    };
    await fetchTotalCount();
    return datapassed;
  };

  const excelExport = async (jsonData: any[]) => {
    dispatch(showLoader());
    try {
      setOpen(false);
      const XLSX = require("xlsx");
      console.log("Exported Data", props.exportData);

      const resolvedData = jsonData.map((item) => {
        const rowData: { [key: string]: any } = {};
        for (const header of props.headers) {
          const key = header.replace(/ /g, "_");
          let value = item[key] !== undefined ? item[key] : "";

          if (key === "_ts") {
            value = formatDateValue(value);
          } else if (Array.isArray(value)) {
            value = value.join(", ");
          }

          const formattedHeader =
            header === "_ts" || header === "dateCreated"
              ? "Created Date"
              : header;
          rowData[formattedHeader] = value;
        }
        return rowData;
      });

      console.log("Resolved Data for Export:", resolvedData);

      // Genera la hoja de cálculo
      const ws = XLSX.utils.json_to_sheet(resolvedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      const currentDate = new Date();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const year = currentDate.getFullYear();

      const formattedDate = `${month}_${day}_${year}`;
      const outputFile = `${props.route}_${formattedDate}.xlsx`;

      XLSX.writeFile(wb, outputFile, { bookSST: true, type: "binary" });

      console.log("File successfully written:", outputFile);
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setTimeout(() => {
        dispatch(hideLoader());
      }, 500);
    }
  };

  const dataExcelExport = async (current: boolean) => {
    if (!current) {
      let dataToExport: any[] = [];

      if (!props.hasTotalData) dataToExport = await GetTotalData();
      else dataToExport = (await props.GetTotalData?.(true)) ?? [];
      dataToExport.forEach((item: any) => {
        if (item._ts) {
          item["created_date"] = formatDate(item._ts);
        }
      });

      excelExport(dataToExport);
    } else {
      try {
        dispatch(showLoader());
        if (!props.hasTotalData)
          excelExport(props.exportData ? props.exportData : props.data);
        else excelExport((await props.GetTotalData?.(false)) ?? []);
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(() => {
          dispatch(hideLoader());
        }, 1000);
      }
    }
  };

  type ContentTypeEnum = {
    [key: number]: string;
  };
  
  const ContentType:ContentTypeEnum = {
    0: 'article',
    1: 'press',
    2: 'guide',
    3: 'successStory',
    4: 'sharedNews',
    5: 'memberReports'
  }

  return (
    <>
      <ExcelModal
        open={open}
        setOpen={setOpen}
        dataExcelExport={() => dataExcelExport(true)}
      />
      <section className=" CatalogTableMain relative">
        {props.title && (
          <CatalogTitle title={props.title} className="text-Default" />
        )}

        <div className="flex flex-col md:flex-row h-auto w-full">
          <CounterTotalCatalog
            title={`${props.catalogName} (${props.totalCount} Total)`}
            className=" bg-accent-Default-dark text-neutral"
          ></CounterTotalCatalog>

          <div className="flex lg:flex-row-reverse">
            <div className="flex w-full">
              {!props.noExcelExport && (
                <div className="flex-1">
                  <NewButton
                    text={viewportWidth < 1024 ? `Export` : `Export to Excel`}
                    color="excel"
                    size="large"
                    content="textIcon"
                    onClick={() => setOpen(true)}
                    icon={<Excel />}
                  />
                </div>
              )}
              {!props.noNewCatalog && (
                <Link
                  className="flex-1"
                  to={
                    props.linkToAdd
                      ? (props.linkToAdd as string)
                      : `/Staff/Catalogs/${props.route}/Create`
                  }
                  state={{
                    contentType: ContentType[props?.selectedTab ?? 0],
                    memberFirms: props.firmId ? [props.firmId] : []
                  }}
                >
                  <NewButton
                    text={
                      viewportWidth < 1024
                        ? `New`
                        : `New ${props.catalogShortName ? `${props.catalogShortName}` : `${props.catalogName}`}`
                    }
                    color="accent"
                    size={"large"}
                    onClick={() => props.newItemAction && props.newItemAction()}
                    content="textIcon"
                    icon={<Plus2 />}
                  />
                </Link>
              )}
            </div>
            {!props.isfiltered && !props.noRedBox && props.resetSignal && (
              <VisibleColumns
                resetSignal={props.resetSignal}
                className={"right-0"}
                pageHeaders={props.headers}
              ></VisibleColumns>
            )}
          </div>
        </div>

        <div className="CatalogFormNTable ">
          {Children.map(props.children, (child) => (
            <> {child} </>
          ))}
        </div>
      </section>
    </>
  );
};

export default CatalogTableC;
