import { createSlice } from "@reduxjs/toolkit";
import { Empty } from "../../emptyStates/Any";

export const regionsSlice = createSlice({
  name: "countries",
  initialState: Empty,
  reducers: {
    SetRegions: (_, action) =>  action.payload,
  },
});

export const { SetRegions } = regionsSlice.actions;
