import { useEffect, useRef, useState } from "react"   
import { useParams } from "react-router-dom"
import Axios from "../../utils/axios"
import { DataItem, Firm, User } from "../../interfaces"

const axios = new Axios()
const route = '/person'

const titleOptions = [
    { label: 'Chairman' },
    { label: 'Chief Executive Officer (CEO)' },
    { label: 'Chief Financial Officer (CFO)' },
    { label: 'Executive Director' },
    { label: 'Executive Vice President' },
    { label: 'General Counsel' },
    { label: 'Senior Counsel' },
    { label: 'President' },
    { label: 'Vice President' },
    // ... add more options as needed
  ];

  const contactOptions = [
    { label: 'Primary' },
    { label: 'Secondary' },
    { label: 'Marketing' },
    { label: 'IT' },
    { label: 'Accounting' },
    { label: 'Social Media' },
    { label: 'HR' },
    // ... add more options as needed
  ];

  const honoraryOptions = [
    { label: 'Dr.' },
    { label: 'Dr. Jur.' },
    { label: 'Dra.' },
    { label: 'Hon.' },
    { label: 'Honorable' },
    { label: 'JUDr' },
    { label: 'Mag.' },
    { label: 'Miss' },
    { label: 'Mr.' },
    { label: 'Mrs.' },
    { label: 'Ms.' },
    { label: 'Prof.' },
    { label: 'Rev.' },
    // ... add more options as needed
  ];

  const suffixOptions = [
    { label: 'Ad.E' },
    { label: 'Adv.' },
    { label: 'CFE' },
    { label: 'CPA' },
    { label: 'CPA, CFE' },
    { label: 'CPA, J.D.' },
    { label: 'Esq.' },
    { label: 'II' },
    { label: 'II, Esq.' },
    { label: 'III' },
    { label: 'J.D.' },
    { label: 'J.D., CFE' },
    { label: 'Jr.' },
    { label: 'Jr., Esq.' },
    { label: 'Ph. D.' },
    { label: 'Q.C.' },
    { label: 'Q.C., Ad.E' },
    { label: 'Sr.' },
    { label: 'Sr., Esq.' },
    // ... add more options as needed
  ];



const useReadPerson = () => {
    const [data, setData] = useState<DataItem[]>([]);
    const [users, setUsers] = useState<User[]>([]); // Estado para almacenar los datos de los usuarios
  
    useEffect(() => {
      fetchData();
      fetchUsers();
    }, [route]);
  
    const getPersons = async () => {
        const response = await axios.Get(route)
        .then(response => {
            const responseData = response.data;
            setData(responseData);
        });
    };

    const fetchData = async () => {
        try {
          const response = await axios.Get('/person');
          const responseData = response.data;
          setData(responseData);
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
    
      const fetchUsers = async () => {
        try {
          const response = await axios.Get('/user');
          const responseData = response.data;
          await setUsers(responseData);
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
    
      const handleDelete = async (id: string) => {
        try {
            await sessionStorage.removeItem("profileImageUrl")
          await axios.Delete(route, id);
    
          fetchData();
        } catch (error) {
          console.error('Error al eliminar el elemento:', error);
        }
      };

    return {data, users, getPersons, fetchData, fetchUsers, handleDelete};
}


const useCreatePerson = () => {

    const nameRef = useRef<HTMLInputElement>(null);
    const middleNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const phoneNumberRef = useRef<HTMLInputElement>(null);
    const xRef = useRef<HTMLInputElement>(null);
    const instagramRef = useRef<HTMLInputElement>(null);
    const facebookRef = useRef<HTMLInputElement>(null);
    const linkedInRef = useRef<HTMLInputElement>(null);
    const otherSocialMediaRef = useRef<HTMLInputElement>(null);

    const profileUrlRef = useRef<HTMLInputElement>(null);
    const bioRef = useRef<HTMLInputElement>(null);
    const whatsappUrlRef = useRef<HTMLInputElement>(null);
    const wechatUrlRef = useRef<HTMLInputElement>(null);
    const significantAccomplishmentsRef = useRef<HTMLInputElement>(null);
    const industryOrganizationAffiliationRef = useRef<HTMLInputElement>(null);
    const professionalActivitiesAndPreviousExperienceRef = useRef<HTMLInputElement>(null);
    const seminarPresentationRef = useRef<HTMLInputElement>(null);
    const additionalInformationRef = useRef<HTMLInputElement>(null);

    const [otherTitle, setOtherTitle ] = useState(false)
    const [listingCategory, setListingCategory ] = useState("")
    const otherTitleRef = useRef<HTMLInputElement>(null);

    const [assistant, setAssistant ] = useState(false)
    const assistantNameRef = useRef<HTMLInputElement>(null);
    const assistantEmailRef = useRef<HTMLInputElement>(null);// Estado para almacenar los datos de los usuarios
    const [firms, setFirms] = useState<Firm[]>([]); // Estado para almacenar los datos de los usuarios

    const [selectedFirm, setSelectedFirm] = useState(""); // State to store the selected firm

    const [ success, setSuccess ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const [users, setUsers] = useState<User[]>([]); // Estado para almacenar los datos de los usuarios
    const [selectedUser, setSelectedUser] = useState(""); // Estado para almacenar el usuario seleccionado
    const [selectedMembership, setSelectedMembership] = useState(""); // State to store the selected membership
    const [selectedIsSearchable, setSelectedIsSearchable] = useState(""); // State to store the selected membership
    const [selectedIsAttorney, setSelectedIsAttorney] = useState(""); // State to store the selected membership
    const [selectedTitle, setSelectedTitle] = useState(""); // State to store the selected title
    const [selectedHonorary, setSelectedHonorary] = useState(""); // State to store the selected honorary
    const [selectedSuffix, setSelectedSuffix] = useState(""); // State to store the selected suffix
    const [selectedContact, setSelectedContact] = useState(""); // State to store the selected contact
    const [imageUrl, setImageUrl] = useState("");

    const handleImageUpload = (url:string) => {
      setImageUrl(url);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.Get('/user');
      const responseData = response.data;
      await setUsers(responseData);
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
    }
  };

  const fetchFirms = async () => {
    try {
      const response = await axios.Get('/firm');
      const responseData = response.data;
      await setFirms(responseData);
    } catch (error) {
      console.error('Error al obtener las firmas:', error);
    }
  };

  useEffect(() => {
    fetchFirms();
    fetchUsers();
  }, [])

  useEffect(() => {
    if (!otherTitle) {
      if (otherTitleRef.current) {
        otherTitleRef.current.value = "";
      }
      setListingCategory(""); 
    }
  }, [otherTitle]);

  useEffect(() => {
    if (!assistant) {
      if (assistantNameRef.current) {
        assistantNameRef.current.value = "";
      }
      if (assistantEmailRef.current) {
        assistantEmailRef.current.value = "";
      }
    }
  }, [assistant])

  const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
      try {
        const response = await axios.Post(route, {
          name: nameRef.current?.value,
          middleName: middleNameRef.current?.value,
          lastName: lastNameRef.current?.value,
          phoneNumber: phoneNumberRef.current?.value,
          x: xRef.current?.value ? xRef.current?.value : null,
          instagram: instagramRef.current?.value ? instagramRef.current?.value : null ,
          linkedIn: linkedInRef.current?.value ? linkedInRef.current?.value : null,
          facebook: facebookRef.current?.value ? facebookRef.current?.value : null,
          otherSocialMedia: otherSocialMediaRef.current?.value ? otherSocialMediaRef.current?.value : null,
          title: selectedTitle,
          honorary: selectedHonorary,
          contact: selectedContact,
          suffix: selectedSuffix,
          membership: selectedMembership,
          photo: imageUrl,
          userId: selectedUser ? selectedUser : null,
          isSearchable: selectedIsSearchable === "yes" ? true : false,
          isAttorney: selectedIsAttorney === 'yes' ? true : false,
          profileUrl: profileUrlRef.current?.value ? profileUrlRef.current?.value : null,
          otherTitleFlag: otherTitle,
          otherTitle: otherTitleRef.current?.value ? otherTitleRef.current?.value : null,
          listingCategory: listingCategory ? listingCategory : null,
          bio: bioRef.current?.value ? bioRef.current?.value : null,
          assistantFlag: assistant,
          assistantName: assistantNameRef.current?.value ? assistantNameRef.current?.value : null,
          assistantEmail: assistantEmailRef.current?.value ? assistantEmailRef.current?.value : null,
          whatsappUrl: whatsappUrlRef.current?.value ? whatsappUrlRef.current?.value : null,
          wechatUrl: wechatUrlRef.current?.value ? wechatUrlRef.current?.value : null,
          significantAccomplishments: significantAccomplishmentsRef.current?.value ? significantAccomplishmentsRef.current?.value : null,
          industryOrganizationAffiliation: industryOrganizationAffiliationRef.current?.value ? industryOrganizationAffiliationRef.current?.value : null,
          professionalActivitiesAndPreviousExperience: professionalActivitiesAndPreviousExperienceRef.current?.value ? professionalActivitiesAndPreviousExperienceRef.current?.value : null,
          seminarPresentation: seminarPresentationRef.current?.value ? seminarPresentationRef.current?.value : null,
          additionalInformation: additionalInformationRef.current?.value ? additionalInformationRef.current?.value : null,
          firmId: selectedFirm ? selectedFirm : null,
        });
        
    
        if (response.status === 200) {
          // La petición se realizó correctamente
          setSuccess(true)
          setTimeout(() => {
            window.location.reload();
          }, 500); 
        } else {
          // La petición no fue exitosa
          console.error('Error al crear el elemento:', response.data);
        }
      } catch (error:any) {
          setError(true)
          console.error('Error al crear el elemento:', error);
          setErrorMessage(error.message)
      }
    };

      const selectedUserEmail = users.find((user) => user.Id === selectedUser)?.Email;

      return {
        nameRef,
        middleNameRef,
        lastNameRef,
        phoneNumberRef,
        xRef,
        instagramRef,
        facebookRef,
        linkedInRef,
        otherSocialMediaRef,
        success,
        error,
        errorMessage,
        users,
        selectedUser,
        selectedMembership,
        selectedIsSearchable,
        selectedIsAttorney,
        selectedTitle,
        selectedHonorary,
        selectedSuffix,
        selectedContact,
        imageUrl,
        handleImageUpload,
        handleCreate,
        selectedUserEmail,
        setSelectedUser,
        setSelectedMembership,
        setSelectedIsSearchable,
        setSelectedIsAttorney,
        setSelectedTitle,
        setSelectedHonorary,
        setSelectedSuffix,
        setSelectedContact,
        profileUrlRef,
        otherTitle,
        setOtherTitle,
        otherTitleRef,
        listingCategory,
        setListingCategory,
        bioRef,
        assistant,
        setAssistant,
        assistantNameRef,
        assistantEmailRef,
        whatsappUrlRef,
        wechatUrlRef,
        significantAccomplishmentsRef,
        industryOrganizationAffiliationRef,
        professionalActivitiesAndPreviousExperienceRef,
        seminarPresentationRef,
        additionalInformationRef,
        selectedFirm,
        setSelectedFirm,
        firms,
      };
}

const usePersonUpdate = () => {


  const { id } = useParams();
  const [columnInfo, setColumnInfo] = useState(null);
  const [data, setData] = useState<DataItem | null>(null);
  const [users, setUsers] = useState<User[]>([]);

  const nameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const twitterRef = useRef<HTMLInputElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);
  const facebookRef = useRef<HTMLInputElement>(null);
  const linkedInRef = useRef<HTMLInputElement>(null);
  const otherSocialMediaRef = useRef<HTMLInputElement>(null);
  const selectedIsSearchableRef = useRef<HTMLSelectElement>(null);
  const selectedIsAttorneyRef = useRef<HTMLSelectElement>(null);
  const photoRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLSelectElement>(null);
  const membershipRef = useRef<HTMLSelectElement>(null);
  const honoraryRef = useRef<HTMLSelectElement>(null);
  const suffixRef = useRef<HTMLSelectElement>(null);
  const contactRef = useRef<HTMLSelectElement>(null);
  const [membership, setMembership] = useState("");
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);

  

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const fileUrl = sessionStorage.getItem('profileImageUrl');
    if (fileUrl) {
      setUploadedFileUrl(fileUrl);
    }
  }, []);


  const handleUpdate = async (id: string, updatedData: DataItem) => {
    try {
      await axios.Put('/person', updatedData);
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      console.error('Error al actualizar el elemento:', error);
      setError(error.message);
    }
  };

  const handleImageUpload = (url: string) => {
    setImageUrl(url);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.Get('/person', id);
        const data = response.data;
        if (data != null) {
          setData(data);
          setMembership(data.membership);
          setSelectedUser(data.userId || null);
        }
        setColumnInfo(data);
      } catch (error) {
        console.error('Error al obtener la información de la columna:', error);
      }
    };

    fetchData();
  }, [id]);

  const selectedUserEmail = users.find((user) => user.Id === selectedUser)?.Email;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.Get('/user');
        const responseData = response.data;
        setUsers(responseData);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };
  
    fetchUsers();
  }, []);
  

  useEffect(() => {
    if (data) {
      if (nameRef.current && nameRef.current.value === '') {
        nameRef.current.value = data.name || '';
      }
      if (middleNameRef.current && middleNameRef.current.value === '') {
        middleNameRef.current.value = data.middleName || '';
      }
      if (lastNameRef.current && lastNameRef.current.value === '') {
        lastNameRef.current.value = data.lastName || '';
      }
      if (titleRef.current && titleRef.current.value === '') {
        titleRef.current.value = data.title || '';
      }
      if (honoraryRef.current && honoraryRef.current.value === '') {
        honoraryRef.current.value = data.honorary || '';
      }
      if (photoRef.current && photoRef.current.value === '') {
        photoRef.current.value = data.photo|| '';
      }
      if (suffixRef.current && suffixRef.current.value === '') {
        suffixRef.current.value = data.suffix || '';
      }
      if (membershipRef.current && membershipRef.current.value === '') {
        membershipRef.current.value = data.membership || '';
      }
      if (contactRef.current && contactRef.current.value === '') {
        contactRef.current.value = data.contact || '';
      }
    }
  }, [data]);


    return {
        id,
        data,
        twitterRef,
        instagramRef,
        facebookRef,
        linkedInRef,
        otherSocialMediaRef,
        phoneNumberRef,
        selectedIsSearchableRef,
        selectedIsAttorneyRef,
        membership,
        uploadedFileUrl,
        setError,
        setSuccess,
        setImageUrl,
        handleUpdate,
        handleImageUpload,
        selectedUserEmail,
        error,
        success,
        imageUrl,
        nameRef,
        middleNameRef,
        lastNameRef,
        users,
        selectedUser,
        setSelectedUser,
        titleRef,
        honoraryRef,
        suffixRef,
        contactRef,
        setMembership,
    }
}


const usePerson = { useCreatePerson, useReadPerson, usePersonUpdate, titleOptions, contactOptions, honoraryOptions, suffixOptions }

export default usePerson