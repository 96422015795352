

import React from 'react';
import Input from "../../Atomos/Inputs/Input";
import { useAppContext } from '../../../Context';
import HeaderTitle from './HeaderTitle';
import CatalogSubTitle from '../Catalog Text/CatalogSubTitle';



type PersonSocialEditProps = {
  socialNetworks: { name: string; url: string; }[];

  handleAddSosialNetwork: (socialNetworks: { name: string; url: string; }[]) => void;
};

const PersonSocialEdit: React.FC<PersonSocialEditProps> = (props: PersonSocialEditProps) => {
  const [socialNetworks, setSocialNetworks] = React.useState<{ name: string; url: string; }[]>(props.socialNetworks || [] as { name: string; url: string; }[]);

  const handleSosialNetwork = async (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let newSocialNetworks = Array.isArray(socialNetworks) ? [...socialNetworks] : [];

    const existingNetworkIndex = newSocialNetworks.findIndex(network => network.name === event.target.name);

    if (existingNetworkIndex !== -1) {
      newSocialNetworks[existingNetworkIndex] = { name: event.target.name, url: event.target.value };
    } else {
      newSocialNetworks.push({ name: event.target.name, url: event.target.value });
    }
    props.handleAddSosialNetwork(newSocialNetworks);
    setSocialNetworks(newSocialNetworks)
  }

  const { mode } = useAppContext();

  return (
    <section className=' formsectionNTitle w-full'>
      <CatalogSubTitle title={'Social Media'} className={`${mode === 'staff' ? 'text-Default'  : 'text-accentDefault'}`} />
      <div className=" grid grid-cols-2 gap-5 w-full">
        <div className=' col-span-2 md:col-span-1'>
          <Input placeholder='ex: https://www.linkedin.com/in/username' title="LinkedIn Public Profile URL" name="linkedIn" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "linkedIn") || {}).url || ''} onChange={handleSosialNetwork} required={false} />
        </div>
        <div className=' col-span-2 md:col-span-1'>
          <Input placeholder='ex: https://www.twitter.com/username' title="X URL" name="x" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "x") || {}).url || ''} onChange={handleSosialNetwork} required={false} />
        </div>
        <div className=' col-span-2 md:col-span-1'>
          <Input placeholder='ex: https://www.facebook.com/yourname' title="Facebook Profile URL" name="facebook" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "facebook") || {}).url || ''} onChange={handleSosialNetwork} required={false} />
        </div>
        <div className=' col-span-2 md:col-span-1'>
          <Input placeholder='ex: https://www.instagram.com/username' title="Instagram Address URL" name="instagram" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "instagram") || {}).url || ''} onChange={handleSosialNetwork} required={false} />
        </div>
        <div className=' col-span-2 md:col-span-1'>
          <Input placeholder='ex: https://wa.me/usernumber' title="WhatsApp Profile UR" name="whatsapp" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "whatsapp") || {}).url || ''} onChange={handleSosialNetwork} required={false} />
        </div>
        <div className=' col-span-2 md:col-span-1'>
          <Input placeholder='ex: weixin://dl/chat?userid' title="WeChat Profile URL" name="weChat" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "weChat") || {}).url || ''} onChange={handleSosialNetwork} required={false} />
        </div>
        {/* <Input placeholder='' title="Other Social Media" name="otherSocialMedia" errorMsj={""} error={false} value={(socialNetworks?.find(item => item?.name === "otherSocialMedia") || {}).url || ''} onChange={handleSosialNetwork} required={false} /> */}
      </div>
    </section>)
};

export default PersonSocialEdit;