import { PaginationState } from "@tanstack/react-table";
import React, { createContext, useContext, useEffect, useState } from "react";

interface PaginationContextType {
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  prevCatalog: string;
  setPrevCatalog: React.Dispatch<React.SetStateAction<string>>;

  getCatalogFromEndpoint: (endpoint: string) => string;
  restoreScrollPosition: () => void;
}

const PaginationContext = createContext<PaginationContextType | undefined>(
  undefined
);

export const PaginationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [prevCatalog, setPrevCatalog] = useState("");

  const getCatalogFromEndpoint = (endpoint: string) => {
    const parts = endpoint.split('/');
    return parts[1];
  };

  const restoreScrollPosition = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  };

  return (
    <PaginationContext.Provider
      value={{ pagination, setPagination, getCatalogFromEndpoint, prevCatalog, setPrevCatalog, restoreScrollPosition }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error("usePagination must be used within a PaginationProvider");
  }
  return context;
};
