import React, { useState } from "react";
import Input from "../../Atomos/Inputs/Input";
import CatalogFilterButtons from "../CatalogFilterButtons";
import DDMultiple, { Option } from "../../Atomos/DropDown/DDMultiple";
import CalendarInput from "../../Atomos/Inputs/CalendarInput";
import { Table } from "@tanstack/react-table";
import { UseFirmList } from "../../../hooks/Catalogs/useFirm";
import { UseJurisdictionList } from "../../../hooks/Catalogs/useJurisdiction";
import { UsePracticeIndustryList } from "../../../hooks/Catalogs/usePracticeIndustry";
import { UseRegionList } from "../../../hooks/Catalogs/useRegionListOptions";
import { IContentFilters } from "../../../interfaces";

interface IContentProps {
  tableInstance: Table<unknown>;
  filters: IContentFilters;
  headers: string[]
  setFilters: React.Dispatch<React.SetStateAction<IContentFilters>>;
  disabledFirmFilter: boolean;
  clearSelections: () => void;
  Search: () => void;
}
const ContentFilter: React.FC<IContentProps> = ({
  filters,
  Search,
  setFilters,
  headers,
  tableInstance,
  clearSelections,
  disabledFirmFilter
}) => {

  const { firmData } = UseFirmList();
  const { jurisdictionData } = UseJurisdictionList();
  const { practiceIndustryData } = UsePracticeIndustryList();
  const { regionData } = UseRegionList();

  const [statusData] = useState<any[]>([
    { id: "pending", name: "Pending" },
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" },
  ]);

  const handleFilters = (field: string, value:any) => {
    setFilters({...filters, [field]: value})
  }

  return (
    <div className="flex formsection flex-col  w-full">
      <div className="grid grid-cols-4 formsection">
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <Input
            title="By Title:"
            name="inputName"
            value={filters.title}
            onChange={(e: any) => handleFilters('title', e.target.value)}
            error={false}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Firm:"
            objects={firmData}
            selectedObjst={filters.firms}
            seter={(e) => handleFilters('firms', e)}
            disabled={disabledFirmFilter}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Status:"
            objects={statusData}
            selectedObjst={filters.isApproved}
            seter={(e) => handleFilters('isApproved', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Practice Industry:"
            objects={practiceIndustryData}
            selectedObjst={filters.practiceIndustries}
            seter={(e) => handleFilters('practiceIndustries', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Region:"
            objects={regionData}
            selectedObjst={filters.regions}
            seter={(e) => handleFilters('regions', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <DDMultiple
            title="By Jurisdiction:"
            objects={jurisdictionData}
            selectedObjst={filters.jurisdictions}
            seter={(e) => handleFilters('jurisdictions', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <CalendarInput
            title={"By Written Date:"}
            error={false}
            type={"range"}
            value={filters.writtenDate}
            onChange={(e: any) => handleFilters('writtenDate', e)}
          />
        </div>
        <div className="col-span-4 md:col-span-2 lg:col-span-1">
          <CalendarInput
            title={"By Published Date:"}
            error={false}
            type={"range"}
            value={filters.publishedDate}
            onChange={(e: any) => handleFilters('publishedDate', e)}
          />
        </div>
      </div>

      <div className="flex items-end justify-end">
        <CatalogFilterButtons
          onClickSearch={Search}
          handleFormReset={clearSelections}
          headers={headers}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};

export default ContentFilter;
