import React from "react";

function Trash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 12 12"
    >
      
        <path 
          d="M3.206 10.677a.967.967 0 01-.708-.288.953.953 0 01-.29-.703V2.681h-.632v-.99H4.23V1.19h3.533v.502h2.661v.99h-.633v7.005a.95.95 0 01-.294.698.963.963 0 01-.703.293H3.206zM8.794 2.68H3.206v7.005h5.588V2.681zM4.496 8.668h.87V3.68h-.87v4.988zm2.138 0h.878V3.68h-.878v4.988z"
        ></path>  
    </svg>
  );
}

export default Trash;
