const contentTypeEnum = {
  article: "Article",
  press: "Press",
  guide: "Guide",
  successStory: "Success Story",
  sharedNews: "Shared News",
  memberReports: "Member Report",
}

const submissionTypeEnum = {
  manualInput: "Manual Input",
  pdfLink: "PDF Link",
  uploadDocument: "Upload Document",
}

const contentStatusEnum = {
  pending: "Pending"
}

const isApprovedEnum = {
  pending: "Pending",
  yes: "Yes",
  no: "No",
}
export {contentTypeEnum, submissionTypeEnum, contentStatusEnum, isApprovedEnum}