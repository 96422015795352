// ToggleSwitch.js
import React, { useEffect, useState } from 'react';
import css from './HeaderExtra.module.css'
import { useStaffMode } from '../../../hooks/StaffModeProvider';
import { useNavigate } from 'react-router-dom';
interface ToggleSwitchProps {
  IsStaffNavigate?: boolean;
  onToggle: () => void;


}


const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ IsStaffNavigate, onToggle }) => {
  const navigationClassName = (!IsStaffNavigate ? `bg-primary-dark` : `bg-secondary-dark`);
  const { isStaffMode, setStaffMode, toggleStaffMode } = useStaffMode()
  const navigate = useNavigate()

  const handleToggleStaffMode = () => {
    toggleStaffMode()
    onToggle()
  }
  useEffect(() => {
    if (isStaffMode) {
      setStaffMode();
    } else if (IsStaffNavigate) {
      navigate('/');
    }
  }, [isStaffMode, IsStaffNavigate, navigate]);
  

  return (
    <div className="md:order-3">
      <label htmlFor="toggleA" className="flex cursor-pointer md:py-0 py-4">
        <div className="relative">
          <input type="checkbox" id="toggleA" className="sr-only" checked={IsStaffNavigate} onChange={handleToggleStaffMode} />
          <div className={`block ${navigationClassName} w-10 h-6 rounded-full`}></div>
          <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
        </div>
        <div className={`ml-4 text-white roboto_font ${css.buttonToggle}`}>
          Staff Mode
        </div>
      </label>
      <hr className="border border-white md:hidden" />
    </div>
  );
};

export default ToggleSwitch;