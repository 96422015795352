import { createSlice } from "@reduxjs/toolkit"; 
import { SubstateEmpty } from "../emptyStates";

export const substateSlice = createSlice({
  name: "substate",
  initialState: SubstateEmpty,
  reducers: {
    ModifySubstate: (state, action) =>  ({...state, ...action.payload}),
    NewSubstate: (_, action) => action.payload, 
    ResetSubstate: () => SubstateEmpty, 
  },
});

export const { ModifySubstate, NewSubstate, ResetSubstate } = substateSlice.actions;
