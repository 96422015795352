import React, { useState } from "react";
import NewButton from "../../../../components/Atomos/Buttons/newButton";

interface ContactFrameProps {
  name?: string;
  date?: string;
  note?: string;
}

const NoteFrame: React.FC<ContactFrameProps> = ({ name, date, note }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleNote = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="grid col-span-6 gap-5">
      <div className="grid gap-1">
        <div className="not-italic font-bold lg:text-[22px] md:text-[20px] text-lg lg:leading-7 leading-normal text-primary">
          {name}
        </div>
        <div className="not-italic font-normal text-sm lg:text-base leading-5 lg:leading-6 text-gray-font">
          {date}
        </div>
      </div>
      <div className="not-italic font-normal text-base lg:text-lg leading-6 lg:leading-8 text-gray-font">
        <p
          className={`whitespace-pre-line ${
            isExpanded ? "" : "line-clamp-3"
          }`}
        >
          {note}
        </p>
      </div>
      <div className="flex flex-col md:flex-row justify-end gap-5">
        <div className="w-full md:w-min">
          <NewButton
            text={isExpanded ? "HIDE NOTE" : "VIEW NOTE"}
            color="accent"
            type="button"
            style="outlined"
            size="large"
            content="text"
            onClick={toggleNote}
          />
        </div>
        <div className="w-full md:w-min">
          <NewButton
            text={`EDIT NOTE`}
            color="accent"
            type="button"
            style="filled"
            size="large"
            content="text"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default NoteFrame;
