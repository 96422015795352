import React from 'react';
import Input from '../../Atomos/Inputs/Input';
import DDMultiple, { Option } from '../../Atomos/DropDown/DDMultiple';
import CatalogFilterButtons from '../CatalogFilterButtons';
import DropDownList from '../../Atomos/DropDown/Dropdown';

interface IRepresentativeClientProps {
  nameFilter: string;
  onNameFilterChange: (newNameFilter: string) => void;
  search: () => void;
  resetFilters: () => void;
  headers: any;
  firmData: any;
  setSelectedFirm: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedFirm: Option[];
  officeData: any;
  setSelectedOffce: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedOffice: any;
  tableInstance: any;
}

const RepresentativeClientFilter: React.FC<IRepresentativeClientProps> = ({ nameFilter, onNameFilterChange, firmData, setSelectedFirm, selectedFirm, search, resetFilters, headers, officeData, selectedOffice, setSelectedOffce, tableInstance }) => {
  return (
    <>
      <div className='flex flex-col items-center self-stretch formsection justify-end p-4
     md:grid md:grid-cols-2 md:items-end  md:justify-center
      lg:flex-1 lg:flex lg:flex-row
      border border-gray-100'>
        <div className='w-full'>
          <Input
            type="text"
            value={nameFilter}
            onChange={(e) => onNameFilterChange(e.target.value)}
            error={false}
            title='By Client'
            isWfull
          />
        </div>
        <div className='w-full'>
          <DDMultiple
            title="By Firm:"
            objects={firmData}
            selectedObjst={selectedFirm}
            seter={setSelectedFirm}
          />
        </div>
        <div className='w-full'>
          <DropDownList
            title='By Office'
            data={officeData}
            selectedObjst={selectedOffice?.name}
            error={false}
            seter={setSelectedOffce}
            getid={true}
            disableInput

          />
        </div>
        <div className='flex flex-row gap-2 w-full md:justify-end lg:w-max md:col-span-2 md:max-w-[17rem] md:ml-auto'>
          <CatalogFilterButtons
            tableInstance={tableInstance}
            onClickSearch={search} handleFormReset={resetFilters} headers={headers} />
        </div>
      </div>
    </>
  );
};

export default RepresentativeClientFilter;
