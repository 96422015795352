import "../../../../assets/css/AddCatalogs.css";
import React, { useEffect, useState } from "react";
import BackgroundComponent from "../../../../components/Layout/BackgroundProfile/BackgroundProfile";
import ManageFirmBackground from "../../../../assets/images&icons/bg-manageFirm.png";
import CurrentPage from "../../../../components/Layout/CurrentPageTab/CurrentPage";
import CatalogTitle from "../../../../components/Moleculas/Catalog Text/CatalogTitle";
import GoBack from "../../../../components/Moleculas/GoBack";
import { useLocation } from "react-router-dom";
import MenuGT from "../../GeneralTab/Menu";
import NotesList from "./NotesList";
const WSGDots = require("../../../../assets/images&icons/WSGDots.svg");

const ViewNotes: React.FC = () => {
  const {state} = useLocation();
  console.log(state);
  return (
    <>
      <MenuGT state={state} />
      <BackgroundComponent image={ManageFirmBackground} />

      <div className=" mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex gap-4 justify-between">
            <CatalogTitle
              title={`Firm Notes`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] min-h[4.5rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
            <div className="text-white relative">
              <GoBack className="!text-white !fill-white" />
            </div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage prevPage="Manage Firm > General" currentPage={`Firm Notes`} />
          </div>
        </div>
        <div className="flex flex-col gap-10 justify-end">
          <NotesList name={state?.name} noteList={state?.firmNotes ?? []} />
        </div>
      </div>
    </>
  );
};

export default ViewNotes;
