import React from "react";

function Mail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 48 48"
    >
      <path 
        d="M3.293 40.707V7.265h41.442v33.442H3.293zM24 25.805l16.746-11.15v-3.4L24 22.154l-16.746-10.9v3.4L24 25.805z"
      ></path>
    </svg>
  );
}

export default Mail;
