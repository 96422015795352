import { useEffect, useState } from "react"; 
import { Combobox } from "@headlessui/react";
import ChevronDown from "../../Atomos/Icons/ChevronDown";
import { countryCodes } from "../../../pages/ProfileEditMode/CountryCodes"; 
import { useAppContext } from "../../../Context"; 
import '../../../assets/css/Text.css';

type IPhoneNumberProps = {
  className?: string;
  title?: string;
} & InputProps;

type InputProps = {
    title: string;
    Obj: { code: string; phone: string,basecode:string };
    seter: ({ code, phone,basecode }: { code: string; phone: string,basecode:string }) => void;
    required?: boolean;
    error: boolean;
    errormsj?: string;
    tooltip?: string;
    disabled?: boolean; 
    getid?: boolean;
    type?: string;
}
 

export const PhoneNumber = ({
  className,title,Obj,seter,error,errormsj,tooltip,disabled,getid,required,
  ...props
}: IPhoneNumberProps): JSX.Element => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const regex = /^[0-9]+$/;
    const [selectedCode, setSelectedCode] = useState('');
    const [countryCodesToShow, setCountryCodesToShow] = useState<{ id: number; name: string; countryName: string; dial:string ; code:string}[]>([]); // countryCodesToShow
    let handlePhoneNumberChange =  (    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement  >
        ) => { 
    
          const inputPhoneNumber = event.target.value.replace(/\D/g, ''); // Elimina caracteres no numéricos
          let formattedPhoneNumber = '';

          if (inputPhoneNumber.length <= 10) {
            formattedPhoneNumber = inputPhoneNumber.replace(/(\d{0,3})(\d{0,3})?(\d{0,4})?/, '$1 $2 $3');
          } else {
            formattedPhoneNumber = inputPhoneNumber.slice(0, 10).replace(/(\d{0,3})(\d{0,3})?(\d{0,4})?/, '$1 $2 $3');
          }
      
          setPhoneNumber(formattedPhoneNumber);
    } 
    
    useEffect(() => {
      const newcountryCodesToShow = countryCodes.map((object,index) => { 
        return {
            id: index,
            name: (object.code + ' ' + object.dial_code), 
            countryName: object.name,
            dial: object.dial_code,
            code: object.code
        };
    });


      setCountryCodesToShow(newcountryCodesToShow); 


    }, []);

    useEffect(() => {
      const code = countryCodesToShow.find((object) => object.dial === Obj.code && object.code === Obj.basecode)?.name;
      setSelectedCode(code || '');
    }, [countryCodesToShow]);

    useEffect(() => {  
      const code = countryCodesToShow.find((object) => object.dial === Obj.code && object.code === Obj.basecode)?.name;
      setSelectedCode(code || '');
      setPhoneNumber(Obj.phone);
    }, [Obj]);
    
  

    useEffect(() => {
      const foundCountry = countryCodesToShow.find((object) => object.name === selectedCode);
      const phonetosave = {code:foundCountry?.dial as string,phone:phoneNumber,basecode:foundCountry?.code as string};
     
      seter(phonetosave);

    }, [phoneNumber,selectedCode]);

    const [dataQuery, setDataQuery] = useState('');
    const filteredData =
        dataQuery === ''
            ? countryCodesToShow
            : countryCodesToShow?.filter((object) => { 

                return object.name.toLowerCase().includes(dataQuery.toLowerCase()) || object.countryName.toLowerCase().includes(dataQuery.toLowerCase());
            })
            
            const {mode} = useAppContext(); 
            
  return (
    <div
      className={
        "flex flex-col gap-2.5 items-start justify-start flex-1 relative " +
        className
      }
    >
      {mode === 'member'?
      <div className={`${disabled?' text-gray-200': 'text-gray-font'}  inputLabel`}>
        <label>
        {title ? title : "Phone Number"} <span className={`${required ? disabled ? ' text-gray-200' :'text-primary' :'hidden'}`}>*</span>
        </label>
      </div>
      :    
      <div className={`${disabled?' text-gray-200': required? ' text-primary' : 'text-gray-font'}  inputLabel`}>
      {title ? title : "Phone Number"} 
      </div>
      }
      <div className={`flex flex-row gap-0 w-full h-full items-start justify-start self-stretch shrink-0 relative ${disabled ? 'border border-spacing-1 border-solid border-gray-300' : ''}`}>
      
        <div className={`flex flex-col gap-0 items-start justify-start shrink-0 w-32 h-12 text-left  min-h-12 max-h-12 relative `}>
        
        <Combobox value={selectedCode} onChange={setSelectedCode} disabled={disabled}>
            {({ open }) => (
                <div className="relative ">
                    <div className={`    ${error && 'outline outline-1 outline-feedback-error'}`}>
                        <Combobox.Input
                            className={`${disabled ?' placeholder-gray-300':'placeholder-gray-500'}  text-gray overflow-hidden pr-4 bg-transparent border-none w-full text-sm font-medium   focus:outline-none`}
                            onChange={(event) => setDataQuery(event.currentTarget.value)} 
                            placeholder={"(+ )"}/>
                            
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                           
                                <div id="chevron-up" className={`w-6 h-6  ${disabled && 'fill-gray-400'} ${open && 'rotate-180 duration-75'} `} >
                                    <ChevronDown />
                                </div> 
                        </Combobox.Button>
                    </div>
                    <Combobox.Options className={`absolute top-14 w-full h-48 z-30 no-scrollbar overflow-scroll  ${!open && 'hidden'} `} >
                        {filteredData?.map((object: any) => (
                            <Combobox.Option key={object.id} value={getid?object:object.name} className={' p-3  text-base leading-[19px] text-neutral-dark bg-white hover:bg-gray-50'}>
                                {object.name}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </div>
            )}
        </Combobox>
        </div> 
          <div className={`text-gray-font text-left text-lg leading-5 font-normal not-italic relative self-stretch w-full ${error && 'outline outline-1 outline-feedback-error'}`}>
            <input name={title} disabled={disabled}  type="text" placeholder="000 000 0000" value={phoneNumber} onChange={handlePhoneNumberChange}   className="
             "></input>
          </div>

      </div>
      {error && (<span className=" false text-sm font-medium text-feedback-error ">{errormsj?errormsj:required?"This Phone Number is Required" : ""}</span>)}

    </div>
  );
};
