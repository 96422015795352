import { createSlice } from "@reduxjs/toolkit";
import { CountryEmpty } from "../emptyStates"; 

export const countrySlice = createSlice({
  name: "country",
  initialState: CountryEmpty,
  reducers: {
    ModifyCountry: (state, action) =>  ({...state, ...action.payload}),
    NewCountry: (_, action) => action.payload, 
    ResetCountry: () => CountryEmpty, 
  },
});

export const { NewCountry,ModifyCountry, ResetCountry } = countrySlice.actions;
