import "../../assets/css/AddCatalogs.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import BackgroundComponent from "../../components/Layout/BackgroundProfile/BackgroundProfile";
import MemberArticlesManageBackground from "../../assets/images&icons/bg-memberArticlesManage.png";
import CurrentPage from "../../components/Layout/CurrentPageTab/CurrentPage";
import { useTableSettings } from "../../hooks/Table/UseTableSettings";
import CatalogTitle from "../../components/Moleculas/Catalog Text/CatalogTitle";
import CatalogSubTitle from "../../components/Moleculas/Catalog Text/CatalogSubTitle";
import Axios from "../../utils/axios";
import { Link } from "react-router-dom";
import ShapeEdit from "../../components/Atomos/Icons/ShapeEdit";
import { useColumnConfig } from "../../hooks/Table/UseColumnSettings";
import { ColumnConfig } from "../../interfaces/Catalogs/ColumnExport.tsx/ColumnExport";
import ManageFilters from "./ManagePage/ManageFilters";
import CatalogTableC from "./ManagePage/CatalogTableManageArticle";
import TableWithPagination from "./ManagePage/TableManageArticles";
import AuthContext from "../../store/AuthContext";
import { parse, isValid } from "date-fns";
import CommentAndDots from "./ManagePage/CommentAndDots";
import { UseFirmList } from "../../hooks/Catalogs/useFirm";
import { useDispatch } from "react-redux";
import { contentsSlice } from "../../redux";
const WSGDots = require("../../assets/images&icons/WSGDots.svg");

const MemberArticlesManage: React.FC = () => {
  const dispatch = useDispatch();
  const axios = new Axios();
  const Headers: string[] = ["title", "publishedDate", "_ts"];
  const {
    sort,
    setSort,
    currentPage,
    setCurrentPage,
    recordsPerPage,
    setRecordsPerPage,
  } = useTableSettings({ defaultPageSize: 50 });
  const { generateColumns } = useColumnConfig(Headers);
  const [title, setTitle] = useState<string>("");
  const [selectedPublishedDate, setSelectedPublishedDate] =
    useState<string>("");
  const [dataHasFilter, setDataHasFilter] = useState(false);
  const [filteredArticles, setFilteredArticles] = useState<any[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const [tableInstance, setTableInstance] = useState<any>();
  const { tokenDecoded } = useContext(AuthContext);
  const [currentPerson, setCurrentPerson] = useState<any>();
  const [currentFirm, setCurrentFirm] = useState<any>();
  const { firmData } = UseFirmList();

  const clearSelections = () => {
    setDataHasFilter(false);
    setTitle("");
    setSelectedPublishedDate("");
  };

  const getArticles = async () => {
    try {
      const response = await axios.Get("/Content");
      if (response && response.data) {
        setArticles(
          response.data.filter(
            (article: any) =>
              article?.contentType === "article" &&
              article.createdBy === currentPerson?.id
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPerson = async () => {
    const res: any = await axios.Get("/person");
    res.data.forEach((person: any) => {
      if (person.userId === tokenDecoded?.oid) {
        setCurrentPerson(person);
      }
    });
  };

  const parseDate = (dateString: any) => {
    let date = new Date(dateString);
    if (isValid(date)) {
      return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }

    date = parse(dateString, "EEE MM dd yyyy", new Date());
    if (isValid(date)) {
      return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }

    return dateString;
  };

  useEffect(() => {
    fetchPerson();
    dispatch(contentsSlice.actions.InitializeContent());
  }, []);

  useEffect(() => {
    if (currentPerson) {
      getArticles();
    }
  }, [currentPerson]);

  useEffect(() => {
    setCurrentFirm(currentPerson?.firm);
  }, [currentPerson]);

  function filterArticleData(articles: any) {
    setFilteredArticles([]);
    setDataHasFilter(true);
    setCurrentPage(1);
    let articlesWithFilters: any[] = articles;

    if (title !== "") {
      articlesWithFilters = articlesWithFilters.filter((article: any) =>
        article.title?.toLowerCase().includes(title.toLowerCase())
      );
    }

    if (selectedPublishedDate !== "") {
      const dateRangePattern = /from: (.+) to: (.+)/;
      const match = selectedPublishedDate.match(dateRangePattern);
      if (match) {
        const fromDate = new Date(match[1]);
        const toDate = new Date(match[2]);

        if (
          fromDate.toString() !== "Invalid Date" &&
          toDate.toString() !== "Invalid Date"
        ) {
          articlesWithFilters = articlesWithFilters.filter((article) => {
            const articleDate = new Date(article.publishedDate);
            return articleDate >= fromDate && articleDate <= toDate;
          });
        }
      }
    }

    setFilteredArticles(articlesWithFilters);
    return articlesWithFilters;
  }

  function handleSearch(): void {
    filterArticleData(articles);
  }

  const manageArticlesColumnConfigs: ColumnConfig[] = [
    { id: "count", header: "#", enableSorting: false },
    { id: "title", header: "Title" },
    {
      id: "publishedDate",
      header: "Published Date",
      cell: (props: any) => {
        const value = props.getValue();
        return parseDate(value);
      },
    },
    {
      id: "_ts",
      header: "Created Date",
      cell: (props: any) => {
        const value = props.getValue();
        return value
          ? new Date(Number(value) * 1000).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            })
          : "";
      },
    },
    {
      id: "edit",
      header: "Edit",
      cell: (props: any) => (
        <Link
          className="edit"
          to={`Edit/${props.row.original.id}`}
          state={{ content: props.row.original }}
        >
          Edit
          <div className="editsvg">
            <ShapeEdit />
          </div>
        </Link>
      ),
      enableSorting: false,
    },
  ];

  const columns = generateColumns(
    manageArticlesColumnConfigs.map((column) => ({
      ...column,
      cell:
        column.cell ||
        ((props: any) => {
          switch (column.id) {
            case "count":
              return (
                <p>
                  {props.row.index + 1 + (currentPage - 1) * recordsPerPage}
                </p>
              );
            case "_ts":
              const value = props.getValue();
              return (
                <p>
                  {value
                    ? new Date(Number(value) * 1000).toLocaleDateString(
                        "en-US",
                        {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        }
                      )
                    : ""}
                </p>
              );
            default:
              return <p>{props.getValue()}</p>;
          }
        }),
    }))
  );

  return (
    <>
      <BackgroundComponent image={MemberArticlesManageBackground} />

      <div className="mainSection">
        <div className="flex flex-col pt-7 gap-5 md:gap-6 lg:gap-7 md:pt-8 lg:pt-10 pb-5">
          <div className="flex md:min-h-[50px] gap-4 justify-between">
            <CatalogTitle
              title={`Manage Article`}
              className="text-white !p-0 lg:text-[52px] md:text-[44px] text-[42px]"
            />
            <div
              className="md:min-w-[20rem] overflow-hidden flex-1 hidden relative md:flex"
              style={{
                backgroundImage: `url(${WSGDots.default})`,
              }}
            ></div>
          </div>
          <hr className="flex h-0 justify-end items-center self-stretch relative z-10" />
          <div className="flex w-full">
            <CurrentPage
              prevPage="Home > Member Articles"
              currentPage={`Manage Article`}
            />
          </div>
        </div>
        <div className="flex flex-col justify-end ">
          <article className="articleSection relative">
            <section className="flex w-full flex-col gap-10">
              <div className="grid w-full gap-6 items-stretch ">
                <div className="flex flex-col md:flex-row w-full gap-3 justify-between">
                  <CatalogSubTitle
                    title={"Manage Your Articles"}
                    className="text-primary lg:text-[38px] md:text-[28px] text-[26px] not-italic md:font-bold lg:font-medium leading-7 md:leading-8 lg:leading-[44px]"
                  />
                </div>
                <div className="flex flex-col text-base leading-6 lg:text-lg lg:leading-8">
                  <span>
                    Member Professionals can post articles, that can be viewed
                    by members, clients and potential customers. The extensive
                    articles database can be searched by keywords, jurisdiction
                    or practice industry.
                  </span>
                  <span>
                    We encourage all articles to be cut and pasted from a Word
                    document to optimize our system's search capabilities and
                    ultimately increase 'hits' to your articles. Newsletters are
                    not considered articles however we promote the posting of
                    individual pieces from your newsletters. All articles
                    submitted by your firm can be updated through the Member
                    articles menu.
                  </span>
                </div>
              </div>
              <CatalogTableC
                catalogName={"Submitted Articles"}
                route={"Content"}
                isfiltered={true}
                data={dataHasFilter ? filteredArticles : articles}
                headers={Headers}
                totalCount={
                  dataHasFilter ? filteredArticles.length : articles.length
                }
                exportData={articles}
                currentFirm={currentFirm}
                currentPerson={currentPerson?.id}
              >
                <ManageFilters
                  key={0}
                  title={title}
                  setTitle={setTitle}
                  publishedDate={selectedPublishedDate}
                  setPublishedDate={setSelectedPublishedDate}
                  Search={handleSearch}
                  clearSelections={clearSelections}
                />
                <TableWithPagination
                  data={dataHasFilter ? filteredArticles : articles}
                  columns={columns}
                  totalCount={
                    dataHasFilter ? filteredArticles.length : articles.length
                  }
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  recordsPerPage={recordsPerPage}
                  setRecordsPerPage={setRecordsPerPage}
                  setTableInstance={setTableInstance}
                  sort={sort}
                  setSort={setSort}
                />
              </CatalogTableC>
              <CommentAndDots />
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default MemberArticlesManage;
