import NewButton from "../Atomos/Buttons/newButton";
import VisibleColumns from "../Organismos/Table/VisibleColumns";
import Sync from "../Atomos/Icons/Sync";
import SearchIcon from "../Atomos/Icons/Search";
import { ColumnVisibilityControl } from "../Organismos/ColumnVisibilityControl/ColumnVisibilityControl";

type FilterButtonProps = {
  handleFormReset: () => void;
  headers: string[];
  onClickSearch: () => void;
  tableInstance?: any;
  noColumns?: boolean; // Changed type to boolean
  member?: boolean;
};

const CatalogFilterButtons: React.FC<FilterButtonProps> = ({
  handleFormReset,
  headers,
  onClickSearch,
  tableInstance,
  noColumns,
  member
}) => {
  return (
    <div className="flex items-end justify-end gap-2 flex-1 md:min-h-9 md:flex-none w-fit">
      <NewButton
        onClick={onClickSearch}
        text="Search"
        className="text-base [&>span]:leading-[1.125rem]  lg:[&>span]:text-lg lg:[&>span]:leading-5 h-full lg:!py-[.81rem] lg:!pr-[1rem] lg:!pl-[1.25rem]"
        content="textIcon"
        icon={<SearchIcon />}
        color={member ? "primary":"accent"}
        size="filter"
      />
      <div className="h-full">
        <NewButton
          onClick={handleFormReset}
          text="Reset"
          className="[&>span]:fill-Default [&>span]:hover:fill-accent-Default-dark"
          content="iconSquare"
          icon={<Sync />}
          style="ghost"
          color="neutral"
          size="large"
        />
      </div>
      {tableInstance ? (
        <ColumnVisibilityControl table={tableInstance} />
      ) : !noColumns ? ( // Conditionally render VisibleColumns if noColumns is not true
        <div className="h-full">
          <VisibleColumns pageHeaders={headers} className="right-0" />
        </div>
      ) : null}
    </div>
  );
};

export default CatalogFilterButtons;
